const numberToString = (number) => {
    return typeof number === 'number'
        ? number.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
        : number
};

const stringToNumber = (string) => {
    typeof string === 'number'
        ? string.replaceAll(".", "")
        : string;
};

export { numberToString, stringToNumber };