<template>
  <div id="FAQS">
    <div class="banner flex">
      <h1 class="titulo m-0">Error</h1>
    </div>
    <div class="container p-4 p-sm-5" role="tablist">
      <h1 class="azul ml-lg-5">El sitio que buscas no existe o no está disponible en este momento :(</h1>

     <div class="d-flex align-align-items-center justify-content-center py-5">
         <router-link to="/"><button class="btn">Volver a home</button></router-link>
     </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.banner {
  background: #3d99ff;
  padding: 30px;

  .titulo {
    color: white;
    font-size: 30px;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 25px;
      width: 160px;
    }
  }
}

h4 {
  font-size: 20px;

  @media screen and (max-width: 524px) {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 88%;
    }
  }
}

.azul {
  color: #3d99ff;
  font-size: 22px;
  font-weight: bold;
}

p {
  color: #868686;
  font-size: 16px;
  line-height: 35px;

  @media screen and (max-width: 524px) {
    font-size: 13px;
    line-height: 25px;
  }
}
.btn{
    border: 1px solid #3d99ff;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Error",
  metaInfo() {
    return {
      title: 'Página no disponible',
    };
  },
  computed: {
    ...mapGetters("FAQ", ["faq"]),
  },
};
</script>
