import { db, storage } from "../../firebase.service";
import Vue from "vue";
import emailjs from "emailjs-com";
import moment from 'moment';

// Data para EmailJS: (Agregar a .env)
const serviceID = "default_service";
const userID = "user_phHSRepn7EuELwmpwDkNi";

const viajes = db.collection("viajes");

const state = {
    viajes: [],
};

const getters = {};

const mutations = {
    setViajes(state, payload) {
        state.viajes = payload;
    },
    setViajesPorConfirmar(state, payload) {
        state.viajesPorConfirmar = payload;
    },
    RechazarReserva(state, payload) {
        const reservas = state.viajes;
        const id = payload;
        const finder = reservas.find(el => el.id === id);
        finder.estado = "Rechazado";
    },
    ReservaEliminada(state, id) {
        const reservasInmediatas = state.viajes;
        const esInmediata = reservasInmediatas.find(r => r.id === id);

        if (esInmediata) {
            state.viajes = state.viajes.filter(r => r.id != id)
        }
    },
    ReservaEditada(state, payload) {
        const { reserva, esInmediata } = payload;

        if (esInmediata) {
            state.viajes.map(r => {
                if (r.id === reserva.id) {
                    r = reserva
                }
                return r
            })

        }
    }
};

const actions = {
    async getViajes({ commit }) {
        // commit("setAnimation", null, { root: true });

        // const queryViajes = await db.collection("reservas").get();
        viajes.onSnapshot(snapshot => {
            const dataArray = [];
            snapshot.forEach(doc => {
                dataArray.push({...doc.data(), id: doc.id });
            });
            console.log(dataArray)
            commit("setViajes", dataArray);
        });
    },
    async eliminarReservacion({ state, commit }, id) {
        const reservasInmediatas = await state.viajes;
        const esInmediata = reservasInmediatas.find(r => r.id === id);

        try {
            if (esInmediata) await viajes.doc(id).delete();

            console.log('reserva eliminada', esInmediata)
            commit('ReservaEliminada', id)
        } catch (error) {
            console.log(error)
        }
    },
    async editarReserva({ commit, state }, reserva) {
        const reservasInmediatas = await state.viajes;
        const id = reserva.id;
        const esInmediata = reservasInmediatas.find(r => r.id === id);

        try {
            if (esInmediata) await viajes.doc(id).update(reserva);

            await commit('ReservaEditada', { reserva, esInmediata });
            return true;
        } catch (error) {
            console.log('Error al editar reserva: ', error)
            return false;
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};