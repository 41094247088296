<template>
  <div id="Destinos" v-if="destino">
    <Breadcrumbs
      razon="destino"
      :destinoID="id"
      :destino="destino.titulo"
      :zona="getZonaByDestino"
    />
    <div class="hero-section">
      <div class="hero-section__text">
        <h1 class="hero-section__heading">
          {{ language === "es" ? destino.titulo : destino.ingles.titulo }}
        </h1>
        <p class="text-white hero-section__paragraph" style="font-weight: 500;">
          {{ language === "es" ? destino.subtitulo : destino.ingles.subtitulo }}
        </p>
      </div>
      <b-carousel
        v-if="getHeroContent && getHeroContent.length > 0"
        :controls="getHeroContent.length > 1"
        :indicators="getHeroContent.length > 1"
      >
        <b-carousel-slide
          v-for="(content, index) in getHeroContent"
          :key="index"
          
        >
          <template v-if="content.type !== 'mp4'" v-slot:img>
          <div style="background-color: black; height: 100%;">
            <img
              class="hero-section__images"
              :style="{
                display: 'block',
                background: `url(${content.url}) center center / cover no-repeat`,
                height: '100%',
                opacity: 0.7
              }"
            />
          </div>
            
          </template>
          <template v-else v-slot:img>
            <video
              muted
              controls
              loop
              class="hero-section__images hero-section__images--video"
            >
              <source :src="content.url" type="video/mp4" />
            </video>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </div>
    <b-row class="opciones">
      <b-col cols="6" class="p-0">
        <router-link :to="{ name: 'Experiencias' }">
          <h1>{{ experienciasNombre }}</h1>
        </router-link>
      </b-col>
      <b-col cols="6" class="p-0">
        <router-link :to="{ name: 'ConoceDestino' }">
          <h1>{{ conoce }}</h1>
        </router-link>
      </b-col>
    </b-row>
    <transition name="fade" mode="out-in">
      <router-view :routeProps="routeProps"></router-view>
    </transition>
    <NuestrasExperiencias :experiencias="experiencias" />
  </div>
</template>

<script>
/* eslint-disable */
import NuestrasExperiencias from "@/components/NuestrasExperiencias";
import { mapGetters, mapActions } from "vuex";
import { storage } from "../firebase.service";
import Breadcrumbs from "./../components/Breadcrumbs";

export default {
  name: "Destino",
  metaInfo() {
    return {
      title: this.destino.titulo,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.destino.resumen
        },
      ],
       link: [
        {
          rel: 'canonical',
          href: `https://surreal.travel/destinos/${this.id}/experiencias`
        }
      ]
    };
  },
  data() {
    return {
      id: null,
    };
  },
  components: {
    NuestrasExperiencias,
    Breadcrumbs,
  },
  computed: {
    ...mapGetters(["getDestinos", "getExperiencias", "language", "getZonas"]),
    ...mapGetters("Destinos", ["experienciasNombre", "conoce"]),
    getHeroContent() {
      if (this.destino && this.destino.heroImgUrls) {
        if (this.destino.heroImgUrls.length > 0) {
          const heroContentRef = this.destino.heroImgUrls.map((url) => {
            const contentTypes = [
              ".jpg",
              ".JPG",
              ".jpeg",
              ".JPEG",
              ".png",
              ".PNG",
            ];
            const selectedType = contentTypes.find((type) =>
              url.includes(type)
            );
            const urlParts = url.split(selectedType);

            let thumnailType = "_thumb_1200";

            const vw = window.innerWidth;
            // Las imagenes se ven borrosas en el hero mobile
            // if (vw <= 640) {
            //   thumnailType = "_thumb_640";
            //   // if (vw <= 480) {
            //   //   thumnailType = "_thumb_480";
            //   // }
            // }

            let newURL = `${urlParts[0]}${thumnailType}${selectedType}${urlParts[1]}`;

            const { name } = storage.refFromURL(url);
            const nameParts = name.split(".");
            return {
              type: nameParts[1],
              url: newURL,
            };
          });
          return heroContentRef;
        }
      }
      return null;
    },
    destino() {
      return this.getDestinos.find((destino) => destino.id === this.id);
    },
    routeProps() {
      if (this.$route.name === "Experiencias") {
        return this.language === "es"
          ? this.destino.resumen
          : this.destino.ingles.resumen;
      }
      if (this.$route.name === "ConoceDestino") {
        const info = {
          ...this.destino.info,
          localidadImgUrl: this.destino.localidadImgUrl,
          contenidoCultura: this.destino.contenidoCultura,
        };
        const infoIngles = {
          ...this.destino.ingles.info,
          localidadImgUrl: this.destino.localidadImgUrl,
          contenidoCultura: this.destino.contenidoCultura,
        };

        return this.language === "es" ? info : infoIngles;
      }
      return null;
    },
    experiencias() {
      if (this.getExperiencias && this.getExperiencias.length > 0) {
        if (this.destino) {
          const experiencias = this.getExperiencias.filter((experiencia) => {
            let match = false;
            this.destino.experiencias.forEach((destinoExperiencia) => {
              if (experiencia.id === destinoExperiencia) {
                match = true;
              }
            });
            return match;
          });
          return experiencias;
        }
      }
      return null;
    },
    getZonaByDestino() {
      let Zona = "";
      this.getZonas.forEach((zona) => {
        zona.destinos.forEach((destino) => {
          destino.includes(this.id) ? (Zona = zona.titulo) : false;
        });
      });
      return Zona;
    },
  },
  methods: {
    ...mapActions(["setDestinoId"]),
  },
  created() {
    const id = this.$route.params.id;
    if (id) {
      this.id = id;
    }
  },
  updated() {
    const id = this.$route.params.id;
    if (id) {
      this.id = id;
    }
  },
};
</script>

<style lang="scss">
#Destinos {
  .carousel-indicators {
    display: none;

    @media screen and (max-width: 576px) {
      display: flex;

      li {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0);
        border: 2px solid white;
      }

      li.active {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }

  .carousel-item,
  .carousel-item-next {
    height: 450px;
  }

  overflow-x: hidden;

  .hero-section {
    width: 100%;
    height: 450px;
    overflow: hidden;
    color: white;
    text-align: center;
    position: relative;

    &__images {
      width: 100%;
      filter: brightness(65%);

      &--video {
        object-fit: fill;
      }
    }

    .carousel-control-prev-icon {
      background: url("../assets/img/iconos/left_light.svg");
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    .carousel-control-next-icon {
      background: url("../assets/img/iconos/right_light.svg");
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    &__text {
      width: 80%;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }

    &__heading {
      font-size: 50px;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 35px;
        line-height: 43px;
        margin-bottom: 20px;
      }
    }

    &__paragraph {
      font-size: 22px;
      font-weight: lighter;
      width: 70%;
      margin: 0 auto;
      text-align: center;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        width: 100%;
        line-height: 25px;
      }
    }
  }

  .opciones {
    div {
      border-right: groove 1px #00000056 !important;

      @media screen and (max-width: 524px) {
        background-color: #f6f6f6;
        color: #353535;
      }
    }

    h1 {
      font-size: 19px;
      font-weight: lighter;
      padding: 12px 0px;
      margin-bottom: 0;
      text-align: center;

      @media screen and (max-width: 524px) {
        font-size: 8px;
        margin-bottom: 0;
        height: 100%;
      }
    }

    a {
      color: black;
      height: 100%;

      &:hover {
        text-decoration-line: none;
      }
    }
  }

  .router-link-active {
    background: #3791f3;
    color: white !important;
    font-weight: bold;
    display: block;
  }
}
</style>
