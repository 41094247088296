/* eslint-disable */
export default {
  namespaced: true,

  state: {
    ToursESP: {
      tours: "Tours e Itinerarios",
      inspired: "Inspírate con los viajes que otras viajeros han amado",
      getstarted: "Empezar"
    },
    ToursENG: {
      tours: "Tours & Itineraries",
      inspired: "Get inspired by trips other travelers have loved",
      getstarted: "Get Started"
    }
  },
  getters: {
    tour(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ToursESP.tours;
      } else if (rootState.language == "en") {
        return state.ToursENG.tours;
      }
    },
    inspiredTitle(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ToursESP.inspired;
      } else if (rootState.language == "en") {
        return state.ToursENG.inspired;
      }
    },
    started(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ToursESP.getstarted;
      } else if (rootState.language == "en") {
        return state.ToursENG.getstarted;
      }
    }
  },
  mutations: {},
  actions: {}
};
