<template>
  <div id="NuestrosDestinos">
    <h1>
      {{ nuestros }}
      <span>{{ destinosNombre }}</span>
    </h1>

    <!-- Versión Escritorio -->
    <b-carousel class="d-none d-sm-block" id="carousel" :interval="6000" controls>
      <b-carousel-slide
        v-for="(contenido, index) in slideDestinos"
        :key="index"
        style="height: 280px;"
      >
        <b-row>
          <b-col
            v-for="(destino, index) in contenido"
            :key="index"
            cols="2"
            :offset="index === 0 ? 1 : 0"
          >
            <router-link :to="`/destinos/${destino.id}/experiencias`">
              <img :src="getThumbnail({contentUrl: destino.carouselImgUrl, size: 480})" />
              <h3>{{ destino.titulo }}</h3>
            </router-link>
          </b-col>
        </b-row>
      </b-carousel-slide>
    </b-carousel>

    <!-- Versión Móvil -->
    <b-carousel class="d-block d-sm-none" id="carousel" :interval="6000" controls>
      <b-carousel-slide
        v-for="(contenido, index) in slideDestinosMobile"
        :key="index"
        class="slideC"
      >
        <b-row>
          <b-col
            v-for="(destino, index) in contenido"
            :key="index"
            cols="3"
            sm="2"
            :offset-sm="index === 0 ? 1 : 0"
          >
            <router-link :to="`/destinos/${destino.id}/experiencias`">
              <img :src="destino.carouselImgUrl" />
              <h3>{{ destino.titulo }}</h3>
            </router-link>
          </b-col>
        </b-row>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  props: ["destinos"],
  computed: {
    ...mapGetters("NuestrosDestinos", ["nuestros", "destinosNombre"]),
    ...mapGetters("media", ["getThumbnail"]),
    slideDestinos() {
      if (this.destinos) {
        if (this.destinos && this.destinos.length > 0) {
          const contenidoArray = [];
          const loops = Math.ceil(this.destinos.length / 5);
          for (let i = 0; i < loops; i++) {
            contenidoArray.push(this.destinos.slice(i * 5, i * 5 + 5));
          }
          return contenidoArray;
        }
      }
      return null;
    },
    slideDestinosMobile() {
      if (this.destinos) {
        if (this.destinos && this.destinos.length > 0) {
          const contenidoArray = [];
          const loops = Math.ceil(this.destinos.length / 3);
          for (let i = 0; i < loops; i++) {
            contenidoArray.push(this.destinos.slice(i * 3, i * 3 + 3));
          }
          return contenidoArray;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
#NuestrosDestinos {
  color: black;
  text-align: center;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  padding: 33.5px 0px;
  @media screen and (max-width: 524px) {
    padding: 20px 0px;
    height: 283px;
  }
  h1 {
    color: #343434;
    font-size: 48px;
    font-weight: bold;
    margin-bottom: 0px;
    @media screen and (max-width: 524px) {
      font-size: 25px;
      margin: 0 100px;
    }
    span {
      color: #ffaa01;
    }
  }
  .carousel-control-next-icon {
    background: url("../assets/img/iconos/right.svg") !important;
    background-size: 100% 120% !important;
    height: 45px !important;
    width: 45px !important;
    position: relative;
    top: -20px;
  }
  .carousel-control-prev-icon {
    background: url("../assets/img/iconos/left.svg") !important;
    background-size: 100% 120% !important;
    height: 45px !important;
    width: 45px !important;
    position: relative;
    top: -20px;
  }
  .carousel-caption {
    width: 80%;
    margin-left: -5%;
    padding-bottom: 0;
    @media screen and (max-width: 524px) {
      bottom: 1;
      margin-left: 0;
    }
  }
  #carousel {
    h3 {
      color: black;
      font-size: 18px;
      font-weight: lighter;
      transition: transform 1s;
      @media screen and (max-width: 524px) {
        font-size: 10px;
        line-height: 13px;
        margin-left: 10px;
        margin-right: -45px;
      }
    }
    img {
      width: 150px;
      height: 150px;
      border-radius: 50%;
      transition: all 1s;
      margin-bottom: 20px;
      @media screen and (max-width: 524px) {
        width: 106px;
        height: 106px;
        margin-bottom: 10px;
      }
    }
    .slideC {
      height: 360px;
      @media screen and (max-width: 524px) {
        height: 195px;
      }
      .col-3 {
        &:nth-child(2) {
          @media screen and (max-width: 524px) {
            z-index: 2;
          }
          h3 {
            @media screen and (max-width: 524px) {
              font-size: 12px;
              font-weight: 700;
              line-height: 15px;
            }
          }
        }
        &:nth-child(3) {
          @media screen and (max-width: 524px) {
            z-index: 1;
          }
        }
      }
    }
    .carousel-control-prev,
    .carousel-control-next {
      @media screen and (max-width: 524px) {
        top: -20px;
        z-index: 11;
      }
    }
    .col-2 {
      position: relative;
      &:nth-child(1) {
        z-index: 1;
        transform: scale(1.1);
      }
      &:nth-child(2) {
        z-index: 2;
        transform: scale(1.1);
      }
      &:nth-child(3) {
        z-index: 3;
        transform: scale(1.1);
      }
      &:nth-child(4) {
        z-index: 2;
        transform: scale(1.1);
      }
      &:nth-child(5) {
        z-index: 1;
        transform: scale(1.1);
      }
      &:hover {
        cursor: pointer;
        z-index: 4;
        font-weight: bold;
        h3 {
          transform: scale(1.1);
        }
        img {
          position: relative;
          transform: scale(1.1);
        }
      }
    }
  }
}
</style>
