<template>
  <div id="MapaZona">
    <l-map ref="myMap" class="mapazona" :zoom="zoom" :center="center">
      <l-tile-layer :url="url"></l-tile-layer>
      <template v-if="mapaDestinos.length > 0">
        <l-marker
          v-for="(destino, index) in mapaDestinos"
          :key="index"
          :lat-lng="destino.coordenadas">
        <l-tooltip>{{destino.name}}</l-tooltip>
      </l-marker>
      </template>
    </l-map>
  </div>
</template>

<script>
/* eslint-disable */
import { LMap, LTileLayer, LMarker, LTooltip } from "vue2-leaflet";

export default {
  props: ['routeProps'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
  computed: {
    mapaDestinos () {
      const destinos = this.routeProps.map((destino) => {
        const { latitud, longitud } = destino.localizacion
        const newDestino = {
          name: destino.titulo,
          coordenadas: [latitud, longitud]
        }
        return newDestino
      })
      if (destinos && destinos.length > 0) {
        return destinos
      }
      return []
    }
  },
  data () {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 5.4,
      center: [30.6281968, 30.4747693],
      markerLatLng: [-23.6281968, -70.4747693]
    }
  }
};
</script>

<style lang="scss" scoped>
#MapaZona {
  position: relative;
  z-index: 1 !important;
  .mapazona {
    height: 450px;
    @media screen and (max-width: 524px) {
      height: 539px;
    }
  }
}
</style>

<style>
.vue2leaflet-map {
  z-index: 1 !important;
}
</style>
