<template>
  <div id="Menu">
    <MenuTop class="d-none d-sm-block" />
    <MainMenu />
  </div>
</template>

<script>
/* eslint-disable */
import MenuTop from "@/components/MenuTop";
import MainMenu from "@/components/MainMenu";

export default {
  components: {
    MenuTop,
    MainMenu,
  },
};
</script>

<style lang="scss">
#Menu {
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 20;
  box-shadow: 0px 0.1px 5px 0.2px;
}
</style>
