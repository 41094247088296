<template>
  <div class="container-fluid">
    <div class="mt-3 mb1 px-4">
      <b-button :to="{ name: 'AdminHome' }" variant="success">
        Volver a home de admin
      </b-button>
    </div>
    <b-row class="mt-5 mb-1 px-4">
      <b-col class="text-center text-white" cols="12" md="9">
        <h1>Lista de Solicitudes</h1>
      </b-col>
      <b-col cols="12" md="3">
        <b-button :to="{ name: 'CrearReservaViaje' }" variant="success">
          Crear Reserva
        </b-button>
      </b-col>
    </b-row>
    <b-row class="p-4">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="my-2">
            <b-form-group label="Filtrar por palabra" label-cols-sm="6" label-align-sm="right" label-size="md"
              label-for="filterInput" class="mb-1 text-white">
              <b-input-group size="md">
                <b-form-input v-model="filter" type="search" id="filterInput" class="w-100"
                  placeholder="Ingresa un término..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="tabla">
          <b-table show-empty :fields="reservasTitulos" :items="loadDataTable" :current-page="currentPage"
            :per-page="perPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection"
            :filter="filter" empty-text="Aun no existen solicitudes."
            empty-filtered-text="Sin registros que coincidan con la búsqueda..." head-variant="dark"
            table-variant="light" striped hover @filtered="onFiltered">
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(fecha)="data">
              {{ data.item.fechaActual }}
            </template>
            <template v-slot:cell(see)="data">
              <b-icon v-if="data.item.revisado == 'no'" class="iconHover" icon="eye-slash-fill" variant="danger"
                font-scale="1.5" />
              <b-icon v-if="data.item.revisado == 'si'" class="iconHover" icon="eye-fill" variant="primary"
                font-scale="1.5" />
            </template>
            <template v-slot:cell(acciones)="data">
              <div class="container-fluid">
                <b-row>
                  <b-col cols="12">
                    <b-dropdown id="dropdownAcciones" split variant="primary" split-variant="outline-light"
                      @click="data.toggleDetails">
                      <template #button-content>
                        <b-icon @click="handleMarkAsSeen(data.item.id, data.item.revisado)" class="iconHover"
                          icon="card-text" variant="primary" font-scale="1.5" />
                      </template>
                      <b-dropdown-item-button variant="danger" @click="handleDelete(data.item.id)">
                        <b-icon class="iconHover" icon="trash" variant="danger" font-scale="1.5" />
                        <span>Eliminar</span>
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-slot:row-details="data">
              <b-card class="ml-2 pl-4 pt-2">
                <ul style="line-height: 2">
                  <li>
                    <strong>Procedencia:</strong> {{ data.item.pais }}, {{ data.item.ciudad }}
                  </li>
                  <li>
                    <strong>Como Viajara:</strong> {{ data.item.tipoViaje }}
                  </li>
                  <li>
                    <strong>N° de Viajeros:</strong> {{ data.item.viajeros }}
                  </li>
                  <li v-if="data.item.detalleViajeros">
                    <strong>Detalle viajeros:</strong>
                    <ul class="ml-4">
                      <li>
                        <strong class="pr-2">Adulto:</strong>{{ data.item.detalleViajeros.adultos.cantidad }}
                      </li>
                      <template v-if="data.item.detalleViajeros.niños">
                        <li v-for="niño in data.item.detalleViajeros.niños" :key="niño.name">
                          <strong class="pr-2"> {{ niño.name }} </strong> Edad: {{ niño.edad }}
                        </li>
                      </template>
                    </ul>
                  </li>
                  <li><strong>Tipo Fecha:</strong> {{ data.item.tipoFechaDescripcion }}</li>
                  <div v-if="data.item.tipoFecha == 1">
                    <li>
                      <strong>Fecha de Inicio:</strong> {{ data.item.fechaInicio }}
                    </li>
                    <li v-if="data.item.fechaFin">
                      <strong>Fecha de Término:</strong> {{ data.item.fechaFin }}
                    </li>
                  </div>

                  <div v-if="data.item.tipoFecha == 2">
                    <li>
                      <strong>Mes:</strong> {{ data.item.mesAproximado }}
                    </li>
                    <li>
                      <strong>Días:</strong> {{ data.item.diasAproximados }}
                    </li>
                  </div>
                  <div v-if="data.item.tipoFecha == 3">
                    <li>
                      <strong>Días:</strong> {{ data.item.diasAproximados }}
                    </li>
                  </div>

                  <li><strong>Presupuesto:</strong>
                    {{ data.item.budget == 'si' ? "Presupuesto Aproximado" : "Sin Presupuesto" }}
                  </li>

                  <li v-if="data.item.budget == 'si'">
                    <strong>Presupuesto Monto Aproximado:</strong> {{ data.item.presupuesto }} {{ data.item.moneda }}
                  </li>

                  <li v-if="data.item.budget == 'si'"><strong>Moneda:</strong> {{ data.item.moneda }}</li>

                  <li><strong>Idioma:</strong> {{ data.item.idioma }}</li>

                  <li>
                    <strong>Titulo Viaje:</strong> {{ data.item.experiencia }}
                  </li>
                  <li>
                    <strong>Descripcion Viaje:</strong> {{ data.item.comentarios }}
                  </li>
                  <li>
                    <strong>¿Vegano o Vegetariano?:</strong>
                    {{ data.item.vegano }}
                  </li>
                  <li>
                    <strong>¿Quiere recibir promociones?:</strong>
                    {{ data.item.promociones }}
                  </li>
                  <li>
                    <strong>¿Es Referido?:</strong> {{ data.item.referencia }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col cols="12" class="my-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="md"
              class="my-0"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import Vue from "vue";
export default {
  name: "ListSolicitude",
  metaInfo: {
    title: "Lista de Solicitudes",
  },
  data() {
    return {
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 7,
      sortBy: "fecha",
      sortDesc: true,
      sortDirection: "desc",
      reservasTitulos: [
        { key: "index", label: "N°" },
        {
          key: "fecha",
          label: "Fecha Solicitud",
          sortable: true,
          class: "text-center"
        },
        {
          key: "nombre",
          label: "Nombre",
          sortable: false,
          class: "text-center"
        },
        {
          key: "apellido",
          label: "Apellido",
          sortable: false,
          class: "text-center"
        },
        {
          key: "correo",
          label: "Correo",
          sortable: false,
          class: "text-center"
        },
        {
          key: "telefono",
          label: "Telefono",
          sortable: false,
          class: "text-center"
        },
        {
          key: "pais",
          label: "Pais",
          sortable: true,
          class: "text-center"
        },
        {
          key: "ciudad",
          label: "Ciudad",
          sortable: false,
          class: "text-center"
        },
        {
          key: "see",
          label: "Revisado",
          class: "text-center"
        },
        // Columna Acciones
        {
          key: "acciones",
          label: "Acción",
          class: "text-center"
        }
      ]
    };
  },
  created() {
    this.loadSolicitudes();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.getSolicitudes.length;
  },
  computed: {
    //...mapState("solicitudes", ["solicitudes"])
    ...mapGetters("solicitudes", ["getSolicitudes"]),
    loadDataTable() {
      return this.getSolicitudes.map(e => { return { ...e, fecha: Vue.filter("fechaToISOString")(e.fechaActual)} } );
    }
  },
  methods: {
    ...mapActions("solicitudes", [
      "loadSolicitudes",
      "eliminarSolicitud",
      "markSeen"
    ]),
    handleMarkAsSeen(id, seen) {

      if(seen == "no") {
        this.markSeen(id);
      }
    },
    handleDelete(id) {
      const confirmarEliminacion = confirm("¿Seguro que desea eliminar la solicitud de viaje personalizado?");
      if (!confirmarEliminacion) return;
      const reConfirmarEliminacion = confirm(
        "Esta opción es irreversible, ¿Estás seguro que deseas eliminar este registro?"
      );
      if (!reConfirmarEliminacion) return;

      this.eliminarSolicitud(id);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style lang="scss" scoped>
.tabla {
  overflow-x: scroll;
}
</style>

