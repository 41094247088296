<template>
  <div></div>
</template>

<script>
export default {
  name: "PagoFallido",
  metaInfo() {
    return {
      title: "Pago fallido",
    };
  },
};
</script>
