<template>
  <div id="NuestrasExperiencias">
    <h1>
      <span class="ExpTitulo">{{ experienciasNombre }}</span>

      <span style="color: #ffaa01" class="ImpTitulo">{{ imperdibles }}</span>
    </h1>
    <div class="experiencias">
      <b-row class="m-auto">
        <b-col
          v-for="(experiencia, index) in misExperiencias"
          :key="index"
          cols="12"
          sm="4"
          class="mb-4"
        >
          <b-card
            :style="[
              precioOferta(experiencia.id)
                ? { border: '1px solid #D43B32' }
                : false,
            ]"
          >
            <b-card-text>
              <div
                class="imagen"
                :style="{
                  'background-image': `url('${getThumbnail({
                    contentUrl: experiencia.carouselImgUrl,
                    size: 480,
                  })}')`,
                }"
              >
                <div class="capaOscura" style="position: relative">
                  <li style="position: absolute; right: 0; left: 0; bottom: 0">
                    <router-link
                      :to="`/experiencias/${experiencia.id}`"
                      class="
                        nuestras-experiencias__link
                        nuestras-experiencias__link--ver-mas
                      "
                      >{{__get('seeDetails')}}</router-link
                    >
                  </li>

                  <!-- router link reemplazado por router programatico -->

                  <!-- <router-link
                        :to="`/experiencias/${experiencia.id}`"
                        tag="li"
                      >
                      </router-link>-->

                  <!--  -->

                  <div
                    class="calificaciones px-3"
                    :class="{
                      'pt-1':
                        evaluacionesByExperiencia(experiencia.id).length > 0,
                      'pt-3':
                        evaluacionesByExperiencia(experiencia.id).length == 0,
                    }"
                  >
                    <div
                      class="
                        stars
                        d-flex
                        justify-content-between
                        align-items-center
                      "
                    >
                      <div>
                        <p
                          class="m-0 text-white font-weight-bold"
                          v-if="duracion(experiencia.id).descripcion"
                        >
                          <span
                            style="
                              background: white;
                              color: black;
                              padding: 1px 8px;
                              border-radius: 6px;
                            "
                            >{{ duracion(experiencia.id).horas }}</span
                          >
                          {{
                            duracion(experiencia.id).descripcion +
                            duracion(experiencia.id).duracionMinutos
                          }}
                        </p>
                      </div>
                      <div
                        class="card_rating d-flex align-items-center"
                        v-if="
                          evaluacionesByExperiencia(experiencia.id).length > 0
                        "
                      >
                        <b-form-rating
                          :value="promedioRates(experiencia.id)"
                          no-border
                          readonly
                          inline
                          variant="warning"
                          class="rating p-0"
                        ></b-form-rating>
                        <p class="m-0 text-white font-weight-bold">
                          ({{
                            evaluacionesByExperiencia(experiencia.id).length
                          }})
                        </p>
                      </div>
                    </div>
                    <div
                      class="oferta-logo"
                      v-if="precioOferta(experiencia.id)"
                    >
                      {{
                        Math.round(
                          ((precioMinimo(experiencia.id) -
                            precioOfertaMinimo(experiencia.id)) /
                            precioMinimo(experiencia.id)) *
                            100
                        )
                      }}%
                    </div>
                    <span style="color: white" class="d-none">
                      {{ experiencia.visitas }}
                      {{
                        language === "es"
                          ? !(experiencia.visitas > 1)
                            ? "visita"
                            : "visitas"
                          : " visits"
                      }}
                    </span>
                  </div>
                  <img
                    src="@/assets/img/iconos/share.svg"
                    class="share d-none"
                  />
                </div>
              </div>
              <p
                style="
                  color: #868686;
                  text-align: left;
                  padding: 0px 10px;
                  margin: 0;
                  font-size: 13px;
                "
              >
                <b-icon-geo-alt class="mt-4"></b-icon-geo-alt>
                {{
                  language === "es"
                    ? experiencia.tituloDestino
                      ? experiencia.tituloDestino
                      : destinoByExperiencia(experiencia.id).titulo
                    : experiencia.ingles.tituloDestino
                    ? experiencia.ingles.tituloDestino
                    : destinoByExperiencia(experiencia.id).ingles.titulo
                }}
              </p>
              <h6
                class="text-left px-3 font-weight-bold"
                style="color: #353535"
              >
                {{
                  language === "es"
                    ? experiencia.titulo
                    : experiencia.ingles.titulo
                }}
              </h6>
            </b-card-text>
            <template v-slot:footer>
              <small class="text-muted" v-if="precioOferta(experiencia.id)">
                <p
                  class="oferta-text"
                  style="text-align: left; margin-left: 10px"
                >
                {{__get('now')}} {{__get('from')}} CLP ${{
                    precioOfertaMinimo(experiencia.id)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") || "... "
                  }}
                  {{__get('perPerson')}}
                </p>
              </small>
              <small class="text-muted">
                <p
                  :class="{ 'oferta-notext': precioOferta(experiencia.id) }"
                  style="font-size: 14px; text-align: left; margin-left: 10px"
                >
                {{__get('from')}} CLP ${{
                    precioMinimo(experiencia.id)
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") || "... "
                  }}
                  {{__get('perPerson2')}}
                </p>
              </small>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <!-- Sólo para vista móvil: Cantidad de experiencias -->
      <!-- <b-row class="m-0 d-none">
        <b-col cols="12">
          <div class="text-cargar">
            {{ misExperiencias.length }} de
            {{ experienciasLongitud }} experiencias
          </div>
        </b-col>
      </b-row> -->
      <!-- Sólo para vista móvil: Botón para cargar más experiencias -->
      <!-- <b-row class="m-0 d-none">
        <b-col cols="12">
          <div
            v-if="misExperiencias.length < experienciasLongitud"
            class="btn-cargar mb-3"
            @click="cargarMas()"
          >
            Cargar más
          </div>
        </b-col>
      </b-row> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  name: "NuestrasExperiencias",
  props: ["experiencias"],
  // data() {
  //   return {
  //     num: 6,
  //   };
  // },
  computed: {
    ...mapGetters([
      "getDestinos",
      "language",
      "reserva",
      "precioOferta",
      "precioMinimo",
      "precioOfertaMinimo",
    ]),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapGetters("NuestrasExperiencias", [
      "imperdibles",
      "experienciasNombre",
    ]),
    ...mapGetters("Evaluaciones", [
      "promedioRates",
      "evaluacionesByExperiencia",
    ]),
    ...mapGetters("i18n", ["__get"]),
    experienciasLongitud() {
      return this.experiencias.length;
    },
    misExperiencias() {
      let experiencias =
        this.experiencias &&
        this.experiencias.sort((a, b) => {
          return a.tituloDestino.localeCompare(b.tituloDestino);
        });
      // .sort((a, b) => {
      // let n = a.tituloDestino.toLocaleLowerCase().localeCompare(b.tituloDestino.toLocaleLowerCase());
      //   return n === 0 && a !== b ? b.localeCompare(a) : n;
      // });

      // El cliente al final quiso que se expusieran todas las experiencias también en mobile
      // if (window.innerWidth > 524) {
      //   return this.experiencias;
      // } else {
      //   return experiencias.filter((x, i) => i < this.num);
      // }
      return experiencias ? experiencias : [];
    },
  },
  methods: {
    // Navegacion programatica para experiencias
    navigateToExperiencia(event, experiencia) {
      event.preventDefault();
      this.$router.push({
        path: `/experiencias/${experiencia.id}`,
      });
    },
    destinoByExperiencia(id) {
      return this.getDestinos.find((destino) =>
        destino.experiencias.includes(id)
      );
    },
    duracion(experienciaId) {
      const experiencia = this.experiencias.find(
        (experiencia) => experiencia.id === experienciaId
      );
      if (!experiencia) {
        return {
          horas: null,
          descripcion: null,
        };
      }
      let duracion;

      let start;
      let end;

      if (experiencia.horaInicio && experiencia.horaFin) {
        const { horaInicio, horaFin } = experiencia;
        start = horaInicio;
        end = horaFin;
      } else if (
        experiencia.rangosHoras &&
        experiencia.rangosHoras.length > 0
      ) {
        start = experiencia.rangosHoras[0].start;
        end = experiencia.rangosHoras[0].end;
      }

      if (!start || !end) {
        return {
          horas: null,
          descripcion: null,
        };
      }

      const startDate = new Date("01/01/2020 " + start + ":00");
      let endDate = new Date("01/01/2020 " + end + ":00");

      const startTime = startDate.getTime();
      let endTime = endDate.getTime();

      if (endTime - startTime < 0) {
        endDate = new Date("01/02/2020 " + end + ":00");
        endTime = endDate.getTime();
      }

      // Diferencia de tiempo en minutos
      const timeDifference = Math.abs(endTime - startTime) / (1000 * 60);
      const horasDifference = (timeDifference - (timeDifference % 60)) / 60;
      const minutosDifference = timeDifference % 60;

      // La letra que une horas y minutos (si el idioma es espanol seria 'y', de lo contrario entonces es 'and')
      const horasMinutosAnd = `${this.language === "es" ? "y" : "and"}`;

      const duracionMinutos = `${
        minutosDifference > 0
          ? ` ${horasMinutosAnd} ${minutosDifference} ${
              this.language === "es" ? "minutos" : "minutes"
            }`
          : ""
      }`;

      if (!(experiencia.cantidadDias > 1)) {
        // Variables con contenido textual
        const horasDescripcion =
          horasDifference > 0
            ? horasDifference > 1
              ? this.language === "es"
                ? `Horas`
                : `Hours`
              : this.language === "es"
              ? `Hora`
              : `Hour`
            : "";

        if (horasDifference === 0) {
          return {
            horas: 24,
            descripcion: `${this.language === "es" ? "Horas" : "Hours"}`,
            duracionMinutos,
          };
        }

        return {
          horas: horasDifference,
          descripcion: horasDescripcion,
          duracionMinutos,
        };
      } else {
        duracion = `${this.language === "es" ? " dias" : " days"}`;
        return {
          horas: experiencia.cantidadDias,
          descripcion: duracion,
          duracionMinutos: "",
        };
      }
    },
    // cargarMas() {
    //   this.num += 6;
    // }
  },
};
</script>

<style lang="scss">
.nuestras-experiencias {
  &__link {
    &--ver-mas,
    &--ver-mas:visited,
    &--ver-mas:active,
    &--ver-mas:hover {
      background: #ffaa01;
      width: 100px;
      font-weight: 500;
      border-radius: 17px;
      color: #353535;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      margin: auto;

      @media screen and (max-width: 524px) {
        font-size: 11px;
        font-weight: 700;
        padding: 6px 0;
      }
    }
  }
}

#NuestrasExperiencias {
  color: transparent;
  text-align: center;
  padding: 33.5px;

  @media screen and (max-width: 524px) {
    padding: 20px 36px;
  }

  .btn-cargar {
    @media screen and (max-width: 524px) {
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 40px;
      color: #fff;
      border-radius: 24px;
      background-color: #3d99ff;
      width: 129px;
      height: 42px;
      margin: auto;
    }
  }

  .text-cargar {
    @media screen and (max-width: 524px) {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #868686;
      margin-bottom: 23px;
    }
  }

  .experiencias .row {
    width: 85%;

    @media screen and (max-width: 524px) {
      width: 100%;
    }

    .col-12 {
      @media screen and (max-width: 524px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .experiencias .text-muted p {
    color: #ffaa01;
    margin: 0;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 16px;
    }
  }

  h1 {
    color: #343434;
    font-size: 48px;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 25px;
      margin: 0 20px 20px;
    }

    p {
      color: #ffaa01;
    }
  }

  .calificaciones {
    // padding: 10px;
    text-align: right;
    font-weight: lighter;

    .stars {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      div > p {
        font-size: 12px;

        @media screen and (max-width: 524px) {
          font-size: 10px;
        }
      }
    }

    > span {
      @media screen and (max-width: 524px) {
        font-size: 14px;
      }
    }
  }

  .share {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .imagen {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 270px;

    @media screen and (max-width: 524px) {
      border-radius: 10px 10px 0 0;
    }
  }

  .capaOscura {
    background: rgba(0, 0, 0, 0.25);
    height: 270px;
    position: relative;
    @media screen and (max-width: 524px) {
      border-radius: 10px 10px 0 0;
    }

    h6 {
      background: #ffaa01;
      width: 100px;
      border-radius: 17px;
      padding: 5px;
      color: #353535;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      margin: auto;

      @media screen and (max-width: 524px) {
        font-size: 11px;
        font-weight: 700;
      }
    }
  }

  // Para eliminar

  // .row .col-4:nth-child(1) .imagen {
  //   background-image: url("../assets/img/NuestrasExperiencias/1.png");
  // }
  // .row .col-4:nth-child(2) .imagen {
  //   background-image: url("../assets/img/NuestrasExperiencias/2.png");
  // }
  // .row .col-4:nth-child(3) .imagen {
  //   background-image: url("../assets/img/NuestrasExperiencias/3.png");
  // }
  // .row .col-4:nth-child(4) .imagen {
  //   background-image: url("../assets/img/NuestrasExperiencias/4.png");
  // }
  // .row .col-4:nth-child(5) .imagen {
  //   background-image: url("../assets/img/NuestrasExperiencias/5.png");
  // }
  // .row .col-4:nth-child(6) .imagen {
  //   background-image: url("../assets/img/NuestrasExperiencias/6.png");
  // }
}

.card-body {
  padding: 0 !important;
  min-height: 360px !important;
  @media screen and (max-width: 524px) {
    min-height: 1px;
  }
}

.card {
  margin: 5px;
  border-radius: none;
  position: relative;
  @media screen and (max-width: 524px) {
    border-radius: 10px !important;
    margin: 0 0 35px;
  }

  .card-text > p {
    color: #868686;
    text-align: left;
    padding: 0px 10px;
    margin: 0;

    @media screen and (max-width: 524px) {
      font-size: 11px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .card-text h6 {
    @media screen and (max-width: 524px) {
      font-size: 18px;
      margin-top: 11px;
      margin-bottom: 8px;
    }
  }
}
.card-footer {
  background: transparent !important;
  padding: 5px 1px !important;
}
.capaOscura .oferta-logo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
  bottom: 12%;
  right: 10px;
  width: 4rem;
  height: 4rem;
  background: linear-gradient(to bottom, #d3362d 0%, #e57368 100%);
  filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
  transform: translate3d(0, 0, 0);
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 2rem solid transparent;
    border-left: 2rem solid transparent;
    border-top: 1rem solid #e57368;
    position: absolute;
    top: 4rem;
    left: 0;
  }
}
.oferta-text {
  color: #d43b32 !important;
  font-size: 1.2em;
}
.oferta-notext {
  color: #868686 !important;
  text-decoration: line-through;
}

.card_rating {
  .rating,
  .form-control {
    color: #fff;
    background: transparent;
    &:focus,
    &:active {
      color: #fff;
      box-shadow: 0;
      background: transparent;
    }
  }
}
</style>
