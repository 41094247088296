<template>
  <div>
    <div class="d-flex justify-content-between align-items-center">
      <h4 class="text-white">Hoy es: {{ new Date() | toReadableDate }}</h4>
      <b-button @click="cargarReservas">
        <b-icon icon="arrow-clockwise"> </b-icon>Actualizar reservas
      </b-button>
    </div>
    <!-- MODAL -->
    <b-modal
      v-for="reserva of reservasAMostrar"
      centered
      :key="reserva.id"
      :ref="reserva.id"
      hide-footer
      :title="`${reserva.nombre} | ${reserva.id}`"
      size="lg"
    >
      <b-card v-if="reservaModal" class="ml-2 pl-4 pt-2">
        <h4>{{ reservaModal.experiencia }}</h4>
        <hr />
        <ul style="line-height: 2">
          <li>
            <strong>Estado:</strong>
            {{ reservaModal.estado }}
          </li>
          <li>
            <strong>Procedencia:</strong>
            {{ reservaModal.procedencia }}
          </li>
          <li>
            <strong>N° de Viajeros:</strong>
            {{ reservaModal.viajeros }}
          </li>
          <li v-if="reservaModal.detalleViajeros">
            <strong>Detalle viajeros:</strong>
            <ul class="ml-4">
              <li>
                <strong class="pr-2">Adulto:</strong
                >{{ reservaModal.detalleViajeros.adultos.cantidad }}
              </li>
              <template v-if="reservaModal.detalleViajeros.niños">
                <li
                  v-for="niño in reservaModal.detalleViajeros.niños"
                  :key="niño.nombre"
                >
                  <strong class="pr-2">{{ niño.nombre }}:</strong>
                  {{ niño.cantidad }}
                </li>
              </template>
            </ul>
          </li>
          <li v-if="reservaModal.permiteAbono">
            <strong>Abona con:</strong>
            {{ reservaModal.abono.porcentajeAbono }}%
          </li>
          <li v-if="reservaModal.permiteAbono">
            <strong>Primera cuota:</strong> ${{
              reservaModal.abono.primeraCuota
            }}
          </li>
          <li v-if="reservaModal.permiteAbono">
            <strong>Segunda cuota:</strong> ${{
              reservaModal.abono.segundaCuota
            }}
          </li>
          <li><strong>Valor Total:</strong> {{ reservaModal.total }}</li>
          <li v-if="reservaModal.temporadas">
            <strong>Temporadas en reserva:</strong>
            <span
              v-for="(temporada, i) of reservaModal.temporadas"
              :key="temporada"
            >
              {{ temporada }}
              <span
                v-if="
                  reservaModal.temporadas.length > 1 &&
                  i < reservaModal.temporadas.length - 1
                "
                >,</span
              >
            </span>
          </li>
          <li><strong>Idioma:</strong> {{ reservaModal.idioma }}</li>
          <li>
            <strong>Fecha de Inicio:</strong>
            {{ reservaModal.fecha }} a las {{ reservaModal.horaInicio }}hrs.
          </li>
          <!-- <li>
              <strong>Hora de Inicio:</strong>
              {{ reservaModal.horaInicio }}
            </li> -->
          <li v-if="reservaModal.fechaFin">
            <strong>Fecha de Término:</strong>
            {{ reservaModal.fechaFin }} a las {{ reservaModal.horaFin }}hrs.
          </li>
          <li v-else>
            <strong>Hora de Término:</strong>
            {{
              fechaFin({
                expName: reservaModal.experiencia,
                expID: reservaModal.experienciaId,
                fechaInicio: reservaModal.fecha,
                horaFin: reservaModal.horaFin,
              })
            }}
          </li>
          <li>
            <strong>Comentario:</strong>
            {{ reservaModal.comentarios }}
          </li>
          <li>
            <strong>¿Vegano o Vegetariano?:</strong>
            {{ reservaModal.vegano }}
          </li>
          <!-- <li>
              <strong>¿Quiere recibir promociones?:</strong>
              {{ reservaModal.promociones }}
            </li> -->
          <!-- <li>
              <strong>¿Es Referido?:</strong>
              {{ reservaModal.referencia }}
            </li> -->
        </ul>
      </b-card>
      <div>
        <b-button class="mt-3" block @click="cerrarModal(reserva.id)"
          >Cerrar</b-button
        >
      </div>
    </b-modal>

    <div class="calendar__wrapper">
      <FullCalendar :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { mapState } from "vuex";

import "@fullcalendar/core/vdom"; // solves problem with Vite
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";

export default {
  components: { FullCalendar },
  data() {
    return {
      reservasAMostrar: [],
      reservaModal: null,
    };
  },
  computed: {
    ...mapState("reservaciones", [
      "reservaciones",
      "reservacionesPorConfirmar",
    ]),
    ...mapState(["experiencias"]),
    calendarOptions() {
      return {
        plugins: [dayGridPlugin, interactionPlugin],
        initialView: "dayGridMonth",
        events: this.calendarEvents,
        eventClick: (info) => this.verInfo(info.event.id),
        displayEventTime: false,
        locale: "es",
        firstDay: 1,
        headerToolbar: {
          left: "prev",
          center: "title",
          right: "next",
        },
        titleFormat: { year: "numeric", month: "short" },
        fixedWeekCount: false,
      };
    },
    calendarEvents() {
      const reservas = this.reservasAMostrar;

      const events = reservas.map((r) => {
        const color = this.randomColor();
        return {
          id: r.id,
          start: moment(r.fecha)
            .hour(r.horaInicio.split(":")[0])
            .minutes(r.horaInicio.split(":")[1])
            .format(),
          end: r.fechaFin
            ? moment(
                Vue.filter("fechaToISOString")(r.fechaFin, r.horaFin)
              ).format()
            : this.fechaFin({
                expName: r.experiencia,
                expID: r.experienciaId,
                fechaInicio: r.fecha,
                horaFin: r.horaFin,
              }),
          title: `${this.nombreCliente(r.nombre)} - [${r.estado}] - ${r.experiencia}`,
          className: [color, "text-white"],
          borderColor: "#000",
        };
      });

      return events;
    },
  },
  methods: {
    randomColor() {
      const colores = [
        "bg-primary",
        "bg-purple",
        "bg-warning",
        "bg-danger",
        "bg-info",
        "bg-dark",
        "bg-success",
        "bg-orange",
        "bg-pink",
        "bg-yellowgreen",
        "bg-gray",
        "bg-secondary",
      ];

      const random = Math.round(Math.random() * (colores.length - 1 - 0));
      return colores[random];
    },
    cargarReservas() {
      const reservas = JSON.parse(
        JSON.stringify(this.reservacionesPorConfirmar)
      );

      const filtro = reservas
        .filter((r) => {
          return (
            //Sacar experiencias no confirmadas
            (r.estado === "Pendiente de segundo pago" ||
              r.estado === "Pagado") &&
            // Post ingreso de fecha con año
            moment(Vue.filter("fechaToISOString")(r.fecha)).isAfter(
              moment("2021-07-01T04:00:00.000Z")
            )
            // &&
            // moment(moment(Vue.filter("fechaToISOString")(r.fecha)))
            //   .add(3, "d")
            //   .isSameOrAfter(moment(), "day")
          );
        })
        .map((r) => {
          if (r.fecha) {
            r.fecha = Vue.filter("fechaToISOString")(r.fecha, r.horaInicio);
          }
          return r;
        });

      this.reservasAMostrar = filtro;
    },
    verInfo(id) {
      const reservas = this.reservaciones.concat(
        this.reservacionesPorConfirmar
      );
      const infoReserva = reservas.find((r) => r.id == id);
      this.reservaModal = infoReserva;
      this.$refs[id][0].show();
    },
    cerrarModal(id) {
      const reservas = this.reservaciones.concat(
        this.reservacionesPorConfirmar
      );
      const infoReserva = reservas.find((r) => r.id == id);
      this.reservaModal = infoReserva;
      this.$refs[id][0].hide();
    },
    fechaFin({ expName, expID, fechaInicio, horaFin }) {
      const experiencia = this.experiencias.find((exp) => {
        if (expID) {
          return expID == exp.id;
        }

        return exp.titulo.includes(expName);
      });

      const cantidadDias = experiencia ? experiencia.cantidadDias : 1;
      const fechaVuelta =
        cantidadDias > 1
          ? moment(`${moment(fechaInicio).add(cantidadDias - 1, "d")}`)
              .hour(horaFin.split(":")[0])
              .minutes(horaFin.split(":")[1])
              .format()
          : fechaInicio;
      return fechaVuelta;
    },
    nombreCliente(nombre) {
      const arrayNombre = nombre.split(" ").filter((n) => n.trim() != "");
      const primerNombre = arrayNombre[0];
      const primeraLetraDeSegundaPalabra = arrayNombre[1].slice(0, 1);

      return `${primerNombre} ${primeraLetraDeSegundaPalabra}`;
    },
  },
  async created() {
    await this.cargarReservas();
  },
};
</script>

<style lang="scss">
@keyframes panToRight {
}

.bg-purple {
  background-color: purple !important;
}
.bg-gray {
  background-color: gray !important;
}
.bg-orange {
  background-color: orange !important;
}
.bg-yellowgreen {
  background-color: yellowgreen !important;
}
.bg-pink {
  background-color: pink !important;
}

.calendar {
  &__wrapper {
    background: #fff;
    .fc {
      min-height: 60vh;
      &-event {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>