<template>
<div id="Zonas">
  <Breadcrumbs :titulo="language === 'es' ? zona.titulo : zona.ingles.titulo" razon="zonas" :zonaID="zona.id" :zona="zona.titulo" />
  <div class="hero-section">
    <div class="hero-section__text">
      <h1 class="hero-section__heading">{{ language === "es" ? zona.titulo : zona.ingles.titulo }}</h1>
      <p class="text-white hero-section__paragraph">{{ language === "es" ? zona.subtitulo : zona.ingles.subtitulo }}</p>
    </div>
    <!-- Carousel de hero-section -->
    <b-carousel v-if="getHeroContent && getHeroContent.length > 0" id="carousel" indicators :interval="6000" controls>
      <b-carousel-slide v-for="(contenidoArray, index) in getHeroContent" :key="index">
        <template v-slot:img>
          <b-row>
            <b-col v-for="(contenido, index) in contenidoArray" :key="index">
              <template v-if="contenido.type !== 'mp4'">
                <div class="hero-section__images" :style="{
                      background: `linear-gradient( rgba(0, 0, 0, 0.25), rgba(0, 0, 0, 0.25) ), url(${contenido.url}) no-repeat center center / cover`
                    }"></div>
              </template>
              <template v-else>
                <video muted autoplay loop class="hero-section__images hero-section__images--video">
                  <source :src="contenido.url" type="video/mp4" />
                </video>
              </template>
            </b-col>
          </b-row>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
  <b-row class="opciones">
    <b-col cols="6 overview" class="p-0">
      <router-link :to="{ name: 'Overview' }">
        <h1 class="m-0">{{ resumen }}</h1>
      </router-link>
    </b-col>
    <!-- <b-col cols="4" class="p-0">
      <router-link :to="{ name: 'Mapa' }">
        <h1 class="m-0">{{ mapa }}</h1>
      </router-link>
    </b-col> -->
    <b-col cols="6" class="p-0">
      <router-link :to="{ name: 'ConoceZona' }">
        <h1 class="m-0">{{ conoce }}</h1>
      </router-link>
    </b-col>
  </b-row>
  <transition name="fade" mode="out-in">
    <router-view :routeProps="routeProps"></router-view>
  </transition>
  <!-- <NuestrosDestinos :destinos="siteDestinosByZona({ id: zona.id })" /> -->
  <NuestrasExperiencias :experiencias="experiencias" />
</div>
</template>

<script>
/* eslint-disable */
// Vuex imports
import {
  mapGetters,
  mapActions
} from "vuex";
import NuestrosDestinos from "@/components/NuestrosDestinos";
import NuestrasExperiencias from "@/components/NuestrasExperiencias";
import {
  storage
} from "../firebase.service";
import Breadcrumbs from "./../components/Breadcrumbs";

export default {
  name: "Zonas",
  metaInfo() {
    return {
      title: `Zona ${this.zona.titulo}`,
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.routeProps
        },
      ],
    };
  },
  data() {
    return {
      id: null,
    };
  },
  components: {
    NuestrosDestinos,
    NuestrasExperiencias,
    Breadcrumbs,
  },
  computed: {
    ...mapGetters([
      "getZonas",
      "language",
      "siteDestinosByZona",
      "getExperiencias",
    ]),
    ...mapGetters("Zonas", ["resumen", "mapa", "conoce"]),
    zona() {
      if (this.id && this.id.length > 0 && this.getZonas.length > 0) {
        if (this.id === "norte") {
          const zona = this.getZonas.find((zona) =>
            zona.titulo.toLowerCase().includes("norte")
          );
          return zona;
        }
        if (this.id === "centro") {
          const zona = this.getZonas.find((zona) =>
            zona.titulo.toLowerCase().includes("centro")
          );
          return zona;
        }
        if (this.id === "sur") {
          const zona = this.getZonas.find((zona) =>
            zona.titulo.toLowerCase().includes("sur")
          );
          return zona;
        }
        if (this.id === "patagonia") {
          const zona = this.getZonas.find((zona) =>
            zona.titulo.toLowerCase().includes("patagonia")
          );
          return zona;
        }
        const zona = this.getZonas.find((zona) => zona.id === this.id);
        if (zona) {
          return zona;
        }
      }
      return null;
    },
    getHeroContent() {
      if (this.zona && this.zona.heroImgUrls) {
        if (this.zona.heroImgUrls.length > 0) {
          const heroContentRef = this.zona.heroImgUrls.map((url) => {
            const contentTypes = [
              ".jpg",
              ".JPG",
              ".jpeg",
              ".JPEG",
              ".png",
              ".PNG",
            ];
            const selectedType = contentTypes.find((type) =>
              url.includes(type)
            );
            const urlParts = url.split(selectedType);

            let thumnailType = "_thumb_1200";

            const vw = window.innerWidth;
            // Las imagenes se ven borrosas en el hero mobile
            // if (vw <= 640) {
            //   thumnailType = "_thumb_640";
            //   // if (vw <= 480) {
            //   //   thumnailType = "_thumb_480";
            //   // }
            // }
            let newURL = `${urlParts[0]}${thumnailType}${selectedType}${urlParts[1]}`;

            const {
              name
            } = storage.refFromURL(url);
            const nameParts = name.split(".");
            return {
              type: nameParts[1],
              url: newURL,
            };
          });
          const contenidoArray = [];

          // Cambiar la cantidad de elementos ingresados en array dependiendiendo del viewport width
          const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          );
          let elements = 1;

          if (vw >= 576) {
            elements = 2;
          }

          const loops = Math.ceil(heroContentRef.length / elements);

          for (let i = 0; i < loops; i++) {
            contenidoArray.push(
              heroContentRef.slice(i * elements, i * elements + elements)
            );
          }
          return contenidoArray;
        }
      }
      return null;
    },
    experiencias() {
      const destinos = this.siteDestinosByZona({
        id: this.zona.id,
      });
      if (destinos && destinos.length > 0) {
        if (this.getExperiencias && this.getExperiencias.length > 0) {
          let zonaExperiencias = [];
          destinos.forEach((destino) => {
            zonaExperiencias = zonaExperiencias.concat(destino.experiencias);
          });
          const experiencias = this.getExperiencias.filter((experiencia) =>
            zonaExperiencias.includes(experiencia.id)
          );
          return experiencias;
        }
      }
      return null;
    },
    // Props dinamicos para cada sub-ruta ('Mapa', 'Conoce sobre esta zona')
    routeProps() {
      if (this.$route.name === "Overview") {
        return this.language === "es" ?
          this.zona.resumen :
          this.zona.ingles.resumen;
      }
      if (this.$route.name === "Mapa") {
        return this.siteDestinosByZona({
          id: this.zona.id,
        });
      }
      if (this.$route.name === "ConoceZona") {
        // Objeto para informacion en ConoceZona con el idioma que corresponda
        const info = {
          ...this.zona.info,
          localidadImgUrl: this.zona.localidadImgUrl,
          contenidoCultura: this.zona.contenidoCultura,
        };

        const infoIngles = {
          ...this.zona.ingles.info,
          localidadImgUrl: this.zona.localidadImgUrl,
          contenidoCultura: this.zona.contenidoCultura,
        };
        return this.language === "es" ? info : infoIngles;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["setZonaId"]),
  },
  created() {
    this.id = this.$route.params.id;
  },
  mounted() {
    if (this.getHeroContent.length <= 1) {
      document.getElementsByClassName(
        "carousel-control-next"
      )[0].style.display = "none";
      document.getElementsByClassName(
        "carousel-control-prev"
      )[0].style.display = "none";
    }
  },
  updated() {
    this.id = this.$route.params.id;
  },
};
</script>

<style lang="scss">
#Zonas {
  .carousel-indicators {
    display: none;

    @media screen and (max-width: 576px) {
      display: flex;

      li {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0);
        border: 2px solid white;
      }

      li.active {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }

  .carousel-item,
  .carousel-item-next {
    height: 450px;
  }

  overflow-x: hidden;

  .hero-section {
    color: white;
    text-align: center;
    position: relative;

    .carousel-control-prev-icon {
      background: url("../assets/img/iconos/left_light.svg") !important;
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    .carousel-control-next-icon {
      background: url("../assets/img/iconos/right_light.svg") !important;
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    &__images {
      position: absolute;
      width: 100%;
      height: 450px;
      filter: brightness(65%);

      &:first-child {
        border-right: 8px solid #333;
      }

      &--video {
        object-fit: fill;
      }
    }

    &__text {
      width: 90%;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: stretch;

      @media screen and (max-width: 524px) {
        width: 75%;
      }
    }

    &__heading {
      font-size: 50px;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 35px;
        margin-bottom: 22px;
      }
    }

    &__paragraph {
      font-size: 24px;
      font-weight: lighter;
      width: 70%;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: medium;
        line-height: 25px;
        width: 100%;
      }

      margin: 0 auto;
    }
  }

  text-align: center;

  .opciones {
    background-color: #f6f6f6;

    div {
      border-right: groove 1px #00000056 !important;
    }

    h1 {
      font-size: 19px;
      font-weight: lighter;
      padding: 12px 0px;

      @media screen and (max-width: 524px) {
        font-size: 9px;
        font-weight: medium;
        line-height: 10px;
      }
    }

    a {
      color: black;
      height: 100%;

      &:hover {
        text-decoration-line: none;
      }
    }
  }

  .router-link-active {
    background: #3791f3;
    color: white !important;
    font-weight: bold;

    display: block;
  }
}
</style>
