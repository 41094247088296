/* eslint-disable */
export default {
  namespaced: true,

  state: {
    ConoceZonaESP: {
      icon1: "Cultura y gastronomía",
      icon2: "Qué ver y hacer",
      icon3: "Cuándo visitar",
      icon4: "Dónde quedarse",
      icon5: "Ubicación",
      icon6: "Consejos útiles"
    },
    ConoceZonaENG: {
      icon1: "Culture and gastronomy",
      icon2: "The best thing to do",
      icon3: "Best time to visit",
      icon4: "Where should I stay?",
      icon5: "Location",
      icon6: "Tips"
    }
  },
  getters: {
    icon1(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ConoceZonaESP.icon1;
      } else if (rootState.language == "en") {
        return state.ConoceZonaENG.icon1;
      }
    },
    icon2(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ConoceZonaESP.icon2;
      } else if (rootState.language == "en") {
        return state.ConoceZonaENG.icon2;
      }
    },
    icon3(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ConoceZonaESP.icon3;
      } else if (rootState.language == "en") {
        return state.ConoceZonaENG.icon3;
      }
    },
    icon4(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ConoceZonaESP.icon4;
      } else if (rootState.language == "en") {
        return state.ConoceZonaENG.icon4;
      }
    },
    icon5(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ConoceZonaESP.icon5;
      } else if (rootState.language == "en") {
        return state.ConoceZonaENG.icon5;
      }
    },
    icon6(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ConoceZonaESP.icon6;
      } else if (rootState.language == "en") {
        return state.ConoceZonaENG.icon6;
      }
    }
  },
  mutations: {},
  actions: {}
};
