/* eslint-disable */

export default {
  namespaced: true,

  state: {
    BuscadorESP: {
      destinos: "Destinos",
      placeholder: "Busca tu destino o experiencia"
    },
    BuscadorENG: {
      destinos: "Destinations",
      placeholder: "Search your destination or experience"
    }
  },
  getters: {
    BuscadorDestinos(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.BuscadorESP.destinos;
      } else if (rootState.language == "en") {
        return state.BuscadorENG.destinos;
      }
    },
    BuscadorPlaceholder(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.BuscadorESP.placeholder;
      } else if (rootState.language == "en") {
        return state.BuscadorENG.placeholder;
      }
    }
  }
};
