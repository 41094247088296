<template>
  <div class="container-fluid">
    <div class="mt-3 mb1 px-4">
      <b-button :to="{ name: 'AdminHome' }" variant="success">
        Volver a home de admin
      </b-button>
    </div>
    <b-row class="mt-5 mb-1 px-4">
      <b-col class="text-center text-white" cols="12" md="9">
        <h1>Reservaciones inmediatas</h1>
      </b-col>
      <b-col cols="12" md="3">
        <b-button :to="{ name: 'CalendarioReservas' }" variant="success">
          Ver calendario
        </b-button>
      </b-col>
    </b-row>
    <b-row class="p-4">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="my-2">
            <b-form-group
              label="Filtrar por palabra"
              label-cols-sm="6"
              label-align-sm="right"
              label-size="md"
              label-for="filterInput"
              class="mb-1 text-white"
            >
              <b-input-group size="md">
                <b-form-input
                  v-model="filter"
                  type="search"
                  id="filterInput"
                  class="w-100"
                  placeholder="Ingresa un término..."
                ></b-form-input>
                <!-- <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Limpiar</b-button
                      >
                    </b-input-group-append> -->
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table
          show-empty
          :fields="reservasTitulos"
          :items="reservaciones"
          :current-page="currentPage"
          :per-page="perPage"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          empty-text="Aun no existen reservaciones."
          empty-filtered-text="Sin registros que coincidan con la búsqueda..."
          head-variant="dark"
          table-variant="light"
          striped
          hover
          @filtered="onFiltered"
        >
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(acciones)="data">
            <div class="container-fluid">
              <b-row v-if="data.item.estado === 'Pendiente'">
                <b-col class="text-center">
                  <b-icon
                    @click="aceptarReservacion(data.item.id)"
                    class="iconHover"
                    icon="check-circle-fill"
                    variant="success"
                    font-scale="1.5"
                  />
                </b-col>
                <b-col class="text-center">
                  <b-icon
                    @click="denegarReservacion(data.item.id)"
                    class="iconHover"
                    icon="x-circle-fill"
                    variant="danger"
                    font-scale="1.5"
                  />
                </b-col>
                <b-col class="text-center">
                  <b-icon
                    class="iconHover"
                    icon="card-text"
                    variant="primary"
                    font-scale="1.5"
                    @click="data.toggleDetails"
                  />
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col class="text-center">
                  <b-icon
                    class="iconHover"
                    icon="card-text"
                    variant="primary"
                    font-scale="1.5"
                    @click="data.toggleDetails"
                  />
                </b-col>
              </b-row>
            </div>
          </template>
          <template v-slot:row-details="data">
            <b-card class="ml-2 pl-4 pt-2">
              <ul style="line-height: 2">
                <li>
                  <strong>Procedencia:</strong> {{ data.item.procedencia }}
                </li>
                <li>
                  <strong>N° de Viajeros:</strong> {{ data.item.viajeros }}
                </li>
                <li><strong>Valor Total:</strong> {{ data.item.total }}</li>
                <li><strong>Idioma:</strong> {{ data.item.idioma }}</li>
                <li><strong>Fecha de Inicio:</strong> {{ data.item.fecha }}</li>
                <li>
                  <strong>Hora de Inicio:</strong>
                  {{ data.item.horaInicio }}
                </li>
                <li>
                  <strong>Hora de Término:</strong> {{ data.item.horaFin }}
                </li>
                <li>
                  <strong>Comentario:</strong> {{ data.item.comentarios }}
                </li>
                <li>
                  <strong>¿Vegano o Vegetariano?:</strong>
                  {{ data.item.vegano }}
                </li>
                <li>
                  <strong>¿Quiere recibir promociones?:</strong>
                  {{ data.item.promociones }}
                </li>
                <li>
                  <strong>¿Es Referido?:</strong> {{ data.item.referencia }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
        <b-row>
          <b-col cols="12" class="my-0">
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              per-page="5"
              align="fill"
              size="md"
              class="my-0"
            ></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ReservasInmediatas",
  metaInfo: {
    title: "Reservas inmediatas",
  },
  data() {
    return {
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 7,
      sortBy: "fechaActual",
      sortDesc: true,
      sortDirection: "desc",
      reservasTitulos: [
        { key: "index", label: "N°" },
        {
          key: "fechaActual",
          label: "Fecha de Reserva",
          sortable: true,
          class: "text-center"
        },
        {
          key: "idUnico",
          label: "ID de Reserva",
          sortable: true,
          class: "text-center"
        },
        {
          key: "experiencia",
          label: "Experiencia",
          sortable: true,
          class: "text-center"
        },
        {
          key: "nombre",
          label: "Nombre",
          sortable: true,
          class: "text-center"
        },
        {
          key: "correo",
          label: "Correo Electrónico",
          sortable: true,
          class: "text-center"
        },
        { key: "telefono", label: "Teléfono", class: "text-center" },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
          class: "text-center"
        },
        // Columna Acciones
        { key: "acciones", label: "Acción", class: "text-center" }
      ]
    };
  },
  created() {
    this.getReservas();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.reservaciones.length;
  },
  computed: {
    ...mapState("reservaciones", ["reservaciones"])
  },
  methods: {
    ...mapActions("reservaciones", [
      "getReservas",
      "aceptarReserva",
      "rechazarReserva"
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    aceptarReservacion(index) {
      this.aceptarReserva(index);
    },
    denegarReservacion(index) {
      this.rechazarReserva(index);
    }
  }
};
</script>

<style></style>
