<template>
  <div :class="{ navLang: langMobile, 'd-none': !langMobile }">
    <a
      href="#"
      @click="
        langFalse();
        setLocaleEsp();
      "
    >
      <img
        width="23"
        height="23"
        src="./../assets/img/iconos/esp.svg"
        alt="usd"
      />
      <span>español</span>
    </a>
    <a
      href="#"
      @click="
        langFalse();
        setLocaleEng();
      "
    >
      <img
        width="23"
        height="23"
        src="./../assets/img/iconos/eng.png"
        alt="usd"
      />
      <span>english</span>
    </a>
    <div class="invisible-buscador" @click="langFalse()"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapState, mapMutations } from "vuex";

export default {
  name: "LangMobile",
  computed: {
    ...mapState(["langMobile"])
  },
  methods: {
    ...mapMutations(["langToggle", "langFalse", "setLocaleEsp", "setLocaleEng"])
  }
};
</script>

<style lang="scss">
.navLang {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 60px;
  left: 0;
  right: 100%;
  bottom: 0;
  background-color: #353535;
  opacity: 0.9;
  z-index: 16;
  animation: menu 150ms;
  @keyframes menu {
    0% {
      left: -100%;
    }
    50% {
      left: -50%;
    }
    100% {
      left: 0;
    }
  }
  padding: 30px 55px;
  a {
    display: block;
    margin-bottom: 23px;
    img {
      margin-right: 10px;
    }
    span {
      font-size: 20px;
      font-weight: bold;
      color: #ffffff;
      text-transform: uppercase;
    }
  }
}
</style>
