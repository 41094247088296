import Vue from "vue";
import VueRouter from "vue-router";

// Views
import Home from "../views/Home.vue";
import Landing from "../views/Landing.vue";
import Nosotros from "../views/Nosotros.vue";
import ReservaPersonalizada from "../views/ReservaPersonalizada.vue";
import ViajesPersonalizados from "../views/ViajesPersonalizados.vue";
import Zonas from "../views/Zonas.vue";
import Destinos from "../views/Destinos.vue";
import Busqueda from "../views/Busqueda.vue";
import Experiencia from "../views/Experiencia.vue";
import Tour from "../views/Tour.vue";
import Checkout from "../views/Checkout.vue";
import CheckoutResPorConfirmar from "../views/CheckoutResPorConfirmar.vue";
import PagoExitoso from "../views/PagoExitoso.vue";
import PagoFallido from "../views/PagoFallido.vue";
import Terminos from "../views/Terminos.vue";
import FAQS from "../views/FAQS.vue";
import Privacidad from "../views/Privacidad.vue";
import Admin from "../views/Admin.vue";
import User from "../views/User.vue";
import store from "../store";
import Error from "../views/Error.vue";
import Evaluacion from "../views/Evaluacion.vue";

// DondeViajar
import DondeViajar from "../views/donde-viajar";

// Admin Views
import AdminHome from "../views/admin/Home.vue";
import EditZona from "../views/admin/EditZona.vue";
import EditViaje from "../views/admin/EditViaje.vue";
import EditDestino from "../views/admin/EditDestino.vue";
import EditExperiencia from "../views/admin/EditExperiencia.vue";
import Cupones from "../views/admin/Cupones.vue";
import ReservasInmediatas from "../views/admin/reservasInmediatas.vue";

import ReservasPorConfirmar from "../views/admin/reservasPorConfirmar.vue";
import CalendarioReservas from "../views/admin/CalendarioReservas.vue";
import Evaluaciones from "../views/admin/Evaluaciones.vue";
import NewReserva from "../views/admin/NewReserva.vue";

import ListPersonalizedTrip from "../views/admin/PersonalizedTrip/List.vue";
import FormPersonalizedTrip from "../views/admin/PersonalizedTrip/Form.vue";
import ListUsuarios from "../views/admin/listUsuarios.vue";
import ListSolicitudes from "../views/admin/listSolicitudes.vue";
import CrearReservaViaje from "../views/admin/CrearReservaViaje.vue";

// Componentes
import Overview from "../components/Overview.vue";
import ConoceZona from "../components/ConoceZona.vue";
import MapaZona from "../components/Zonas/MapaZona.vue";
import Experiencias from "../components/Destinos/Experiencias.vue";
import ConoceDestino from "../components/Destinos/ConoceDestino.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: User,
    children: [
      {
        path: "",
        name: "Home",
        component: Home,
      },
      {
        path: "/Landing",
        name: "Landing",
        component: Landing,
      },
      {
        path: "/Nosotros",
        name: "Nosotros",
        component: Nosotros,
      },
      {
        path: "/ViajesPersonalizados",
        name: "ViajesPersonalizados",
        component: ViajesPersonalizados,
      },
      {
        path: "/ReservaPersonalizada",
        name: "ReservaPersonalizada",
        component: ReservaPersonalizada,
      },
      {
        path: "/zonas/:id",
        name: "Zonas",
        component: Zonas,
        children: [
          {
            path: "overview",
            name: "Overview",
            component: Overview,
          },
          {
            path: "mapa",
            name: "Mapa",
            component: MapaZona,
          },
          {
            path: "conoce-zona",
            name: "ConoceZona",
            component: ConoceZona,
          },
        ],
      },
      {
        path: "/destinos/:id",
        name: "Destinos",
        component: Destinos,
        children: [
          {
            path: "experiencias",
            name: "Experiencias",
            component: Experiencias,
          },
          {
            path: "conoce-destino",
            name: "ConoceDestino",
            component: ConoceDestino,
          },
        ],
      },
      {
        path: "/busqueda/:search",
        name: "Busqueda",
        component: Busqueda,
      },
      {
        path: "/experiencias/:id",
        name: "ExperienciaDetalle",
        component: Experiencia,
      },
      {
        path: "/tours/:id",
        name: "TourDetalle",
        component: Tour,
      },
      {
        path: "/checkout",
        name: "Checkout",
        component: Checkout,
      },
      {
        path: "/checkout2/:id",
        name: "CheckoutResPorConfirmar",
        component: CheckoutResPorConfirmar,
      },
      {
        path: "/pago-exitoso/:id",
        name: "PagoExitoso",
        component: PagoExitoso,
      },
      {
        path: "/pago-fallido",
        name: "PagoFallido",
        component: PagoFallido,
      },
      {
        path: "/Terminos",
        name: "Terminos",
        component: Terminos,
      },
      {
        path: "/FAQ",
        name: "FAQS",
        component: FAQS,
      },
      {
        path: "/Privacidad",
        name: "Privacidad",
        component: Privacidad,
      },
      {
        path: "/evaluacion/:id",
        name: "Evaluacion",
        component: Evaluacion,
      },
    ],
  },
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "",
        name: "AdminHome",
        component: AdminHome,
      },
      {
        path: "zonas/registrar-zona",
        name: "RegistrarZona",
        component: EditZona,
      },
      {
        path: "lista-viajes",
        name: "ListPersonalizedTrip",
        component: ListPersonalizedTrip,
      },
      {
        path: "lista-viajes/registrar",
        name: "FormPersonalizedTrip",
        component: FormPersonalizedTrip,
      },
      {
        path: "lista-viajes/editar/:id",
        name: "FormEditPersonalizedTrip",
        component: FormPersonalizedTrip,
      },
      {
        path: "lista-usuarios",
        name: "ListUsuarios",
        component: ListUsuarios,
      },
      {
        path: "lista-solicitudes",
        name: "ListSolicitudes",
        component: ListSolicitudes,
      },
      {
        path: "lista-solicitudes/crear-reserva",
        name: "CrearReservaViaje",
        component: CrearReservaViaje,
      },
      {
        path: "zonas/:id",
        name: "EditZona",
        component: EditZona,
      },
      {
        path: "viajes/:id",
        name: "EditViaje",
        component: EditViaje,
      },
      {
        path: "zonas/:id/destinos/registrar-destino",
        name: "RegistrarDestino",
        component: EditDestino,
      },
      {
        path: "zonas/:zonaId/destinos/:id",
        name: "EditDestino",
        component: EditDestino,
      },
      {
        path: "zonas/:zonaId/destinos/:id/experiencias/registrar-experiencia",
        name: "RegistrarExperiencia",
        component: EditExperiencia,
      },
      {
        path: "zonas/:zonaId/destinos/:destinoId/experiencias/:id",
        name: "EditExperiencia",
        component: EditExperiencia,
      },
      {
        path: "cupones",
        name: "Cupones",
        component: Cupones,
      },
      {
        path: "reservas-inmediatas",
        name: "ReservasInmediatas",
        component: ReservasInmediatas,
      },
      {
        path: "reservas-por-confirmar",
        name: "ReservasPorConfirmar",
        component: ReservasPorConfirmar,
      },
      {
        path: "calendario-reservas",
        name: "CalendarioReservas",
        component: CalendarioReservas,
      },
      {
        path: "evaluaciones",
        name: "Evaluaciones",
        component: Evaluaciones,
      },
      {
        path: "nueva-reserva",
        name: "NewReserva",
        component: NewReserva,
      },
    ],
  },
  {
    path: "*",
    name: "Error",
    component: Error,
  },

  {
    path: "/donde-puedo-viajar",
    name: "DondeViajar",
    component: DondeViajar,
  },
  {
    path: "/donde-viajar",
    redirect: "/donde-puedo-viajar",
  },
];

const router = new VueRouter({
  mode: "history",
  routes
});
router.beforeEach(function (to, from, next) {
  store.commit("closeDropDownZonas");
  next();
  if (to == from) return;
  else if (to.fullPath.includes('#')) return;
  else {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  }
});
export default router;
