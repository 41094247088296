<template>
  <div class="edit-zona">
    <div class="header">
      <div class="container container--flex-nav">
        <button
          type="button"
          class="edit-zona__btn edit-zona__btn--nav"
          @click="navigateToHome"><i class="fas fa-arrow-alt-circle-left"></i>Pagina principal</button>
        <div class="buttons">
          <!-- Switch para ingles/castellano -->
          <button
            v-if="language === 'es'"
            type="button"
            class="edit-zona__btn edit-zona__btn--primary"
            style="margin-right: 50px;"
            @click="switchLanguage">Agregar contenido en ingles</button>
          <button
            v-if="language === 'en'"
            type="button"
            class="edit-zona__btn edit-zona__btn--success"
            style="margin-right: 50px;"
            @click="switchLanguage">Volver al contenido en español</button>
          <!--  -->
          <div class="edit-zona__loading-button">
            <button
              v-if="isZonaUpdate"
              type="button"
              class="edit-zona__btn edit-zona__btn--warning"
              @click="deleteRequest({ id })"
              :disabled="isLoading">Eliminar Zona</button>
            <div v-if="isLoading" class="lds-dual-ring"></div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="heading heading--1 heading--center">Gestion de zona {{(language === 'en') ? '(Ingles)': ''}}</h1>
    <h3 v-if="zona.titulo" class="heading heading--3 heading--center">{{zona.titulo}}</h3>
    <form
      v-if="zona"
      @submit.enter.prevent="(isZonaUpdate) ? submitZona('update') : submitZona('create')"
      class="edit-zona__form-container">
      <div v-if="language === 'es'" class="edit-zona__container">
        <div v-if="id" class="edit-zona__destinos">
          <h3 class="heading heading--3 heading--center">Destinos</h3>
          <ul class="edit-zona__destinos-list">
            <router-link
              v-for="(destino, index) in getDestinosByZona"
              :key="index"
              tag="li"
              :to="`${id}/destinos/${destino.id}`"
              class="edit-zona__destino"><button type="button" class="edit-zona__btn edit-zona__btn--link">{{ destino.titulo }}</button></router-link>
              <router-link
              tag="button"
              :to="`${id}/destinos/registrar-destino`"
              class="edit-zona__destino edit-zona__destino--agregar">
              <button type="button" class="edit-zona__btn edit-zona__btn--success edit-zona__btn--link"><i class="fas fa-plus-circle"></i>Registrar destino</button>
            </router-link>
          </ul>
        </div>
      </div>
      <div class="edit-zona__informacion edit-zona__informacion--1">
        <h3 class="heading heading--3 heading--center">Informacion principal</h3>
        <div class="edit-zona__container edit-zona__container--flex">
          <div class="edit-zona__side edit-zona__side--left">
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Titulo</label>
              <input
                class="edit-zona__input"
                type="text"
                name="titulo"
                id="titulo"
                :value="(language === 'es') ? zona.titulo : zona.ingles.titulo"
                @input="handleChange">
            </div>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Subtitulo</label>
              <input
                class="edit-zona__input"
                type="text"
                name="subtitulo"
                id="subtitulo"
                :value="(language === 'es') ? zona.subtitulo : zona.ingles.subtitulo"
                @input="handleChange">
            </div>
            <div class="edit-zona__input-group">
              <label
                class="edit-zona__label">Resumen</label>
              <textarea
                class="edit-zona__textarea"
                name="resumen"
                id="resumen"
                cols="30"
                rows="10"
                :value="(language === 'es') ? zona.resumen : zona.ingles.resumen"
                @input="handleChange"></textarea>
            </div>
          </div>
          <div v-if="language === 'es'" class="edit-zona__side edit-zona__side--right">
            <!-- Informacion principal | Imagenes para el slideshow -->
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Imagen de previsualizacion</label>
              <input type="file" name="carousel-img" accept="image/*" @change="addImg($event, 'carousel')">
              <a
                href=""
                v-if="(zona.carouselImgUrl || zonaImgsToSave.carouselImgUrl)"
                @click.prevent="(zona.carouselImgUrl) ?
                  deleteImg({
                    type: 'carousel',
                    level: 'zonas',
                    url: zona.carouselImgUrl,
                    id: id
                  })
                  :
                  deleteUnsavedImg({ type: 'carousel' })"
                class="edit-zona__img-link">
                <img
                :src="(zona.carouselImgUrl) ? zona.carouselImgUrl : zonaImgsToSave.carouselImgUrl"
                alt="heading-img"
                class="edit-zona__img">
              </a>
            </div>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Imagenes para el hero</label>
              <input type="file" accept="image/*,.mp4" name="hero-imgs" @change="addHeroImg">
              <div class="edit-zona__img-container">
                <a
                  href=""
                  class="edit-zona__img-container"
                  v-for="(content, index) in getHeroContentBorrador"
                  :key="index"
                  @click.prevent="deleteUnsavedImg({ type: 'hero', index })">
                  <template v-if="content.type !== 'video'">
                    <img
                      :src="content.url"
                      alt="hero-images"
                      class="edit-zona__img">
                  </template>
                  <template v-else>
                    <video autoplay muted loop class="edit-zona__img edit-zona__img--1">
                      <source :src="content.url">
                    </video>
                  </template>
                </a>
              </div>
              <div class="edit-zona__img-container">
                <a
                  href=""
                  class="edit-zona__img-container"
                  v-for="(content, index) in getHeroContent"
                  :key="index"
                  @click.prevent="deleteImg({
                    type: 'hero',
                    level: 'zonas',
                    url: content.url,
                    id: id
                  })">
                  <template v-if="content.type !== 'mp4'">
                    <img
                      :src="content.url"
                      alt="hero-images"
                      class="edit-zona__img">
                  </template>
                  <template v-else>
                    <video autoplay muted loop class="edit-zona__img edit-zona__img--1">
                      <source :src="content.url">
                    </video>
                  </template>
                </a>
              </div>
            </div>

            <!-- Informacion principal | Contenido de youtube (Omitido por ahora (v-if="false"))-->
            <div class="edit-zona__input-group" v-if="false">
              <h5 class="heading heading--5">Contenido de Youtube</h5>
              <div
                class="edit-zona__container--fluid"
                v-if="(zona.youtubeMedia.length > 0)">
                <ul class="edit-zona__list edit-zona__list--1">
                  <li
                    class="edit-zona__list-item"
                    v-for="(item, index) in zona.youtubeMedia"
                    :key="index">
                    <div class="edit-zona__list-content">
                      <div class="edit-zona__container--fluid">
                        <iframe class="edit-zona__youtube-iframe" :src="item" frameborder="0"></iframe>
                      </div>
                      <div class="edit-zona__buttons edit-zona__buttons--right">
                        <button
                          type="button"
                          @click="deleteYoutubeMedia({ type: 'zona', borrador: false, index })"
                          class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Gestion de informacion importante | items de la seccion youtubeMedia agregados al borrador -->
              <div class="edit-zona__container--fluid" v-if="(zona.borrador.youtubeMedia.length > 0)">

                <label class="edit-experiencia__label">Contenido por agregar</label>

                <ul class="edit-zona__list edit-zona__list--1">
                  <li
                    class="edit-zona__list-item"
                    v-for="(item, index) in zona.borrador.youtubeMedia"
                    :key="index">
                    <div class="edit-zona__list-content">
                      <a :href="item">Ir al video</a>
                      <div class="edit-zona__container">
                        <iframe class="edit-zona__youtube-iframe" :src="item" frameborder="0"></iframe>
                      </div>
                      <div class="edit-zona__buttons edit-zona__buttons--right">
                        <button
                          type="button"
                          @click="deleteYoutubeMedia({ type: 'zona', borrador: true, index })"
                          class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                      </div>
                    </div>
                  </li>
                </ul>

              </div>

              <!-- Gestion de informacion importante | input que se mostrara si se desea agregar un item en 'Incluye'-->
              <div class="edit-zona__input-group" v-if="youtubeMediaToAdd">
                <label class="edit-zona__label">Item</label>
                <input v-model="youtubeMedia" type="text" class="edit-zona__input">
              </div>
              <!--  -->
              <button
                v-if="!youtubeMediaToAdd"
                type="button"
                name="youtubeMedia"
                @click="addItem"
                class="edit-zona__btn edit-zona__btn--primary">Registrar nuevo item</button>
              <button
                v-else
                type="button"
                name="youtubeMedia"
                @click="addItem"
                class="edit-zona__btn edit-zona__btn--success">Agregar</button>

            </div>

            <!--  -->
          </div>
        </div>
      </div>
      <div class="edit-zona__informacion edit-zona__informacion--2">
        <h3 class="heading heading--3 heading--center">Informacion adicional</h3>
        <div class="edit-zona__container">
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Cultura y Gastronomia</label>
            <textarea
              class="edit-zona__textarea"
              name="culturaGastronomia"
              id="cultura-gastronomia"
              cols="30"
              rows="10"
              :value="(language === 'es') ? zona.info.culturaGastronomia : zona.ingles.info.culturaGastronomia"
              @input="handleChange($event, 'info')"></textarea>
          </div>

          <div class="edit-zona__gastronomia-content">
            <h3 class="heading heading--3 heading--center">Carousel de cultura y gastronomia</h3>

            <!-- Gestion de imagenes en cultura y agronomia | Imagenes ya agregadas en base de datos -->
            <div class="edit-zona__contenido-cultura" v-if="zona.contenidoCultura && zona.contenidoCultura.length > 0">
              <h5 class="heading heading--3">Contenido ya agregado</h5>
              <div class="edit-zona__gestion-cultura edit-zona__gestion-cultura--agregar">

                <div
                  v-for="(contenido, index) in zona.contenidoCultura"
                  @click="deleteCulturaContent({
                    type: 'guardado',
                    index
                  })"
                  :key="index"
                  class="edit-zona__agregar-contenido">

                  <a href="" @click.prevent="" v-if="contenido.imgUrl" class="edit-zona__img-container edit-zona__img-container--cultura">
                    <img class="edit-zona__img edit-zona__img--cultura" :src="contenido.imgUrl" alt="contenido-cultura">
                  </a>

                  <div class="edit-zona__input-group">
                    <label class="edit-zona__label">Titulo</label>
                    <input class="edit-zona__input" :value="(language === 'es') ? contenido.titulo : contenido.tituloIngles" type="text" disabled>
                  </div>

                </div>
              </div>
            </div>

            <!-- Gestion de imagenes en cultura y agronomia | Imagenes para agregar -->
            <div class="edit-zona__contenido-cultura" v-if="contenidoCulturaBorrador.length > 0">
              <h5 class="heading heading--3">Contenido para agregar</h5>
              <div class="edit-zona__gestion-cultura edit-zona__gestion-cultura--agregar">

                <div
                  v-for="(contenido, index) in contenidoCulturaBorrador"
                  @click="deleteCulturaContent({
                    type: 'borrador',
                    index
                  })"
                  :key="index"
                  class="edit-zona__agregar-contenido">

                  <a
                    href=""
                    @click.prevent=""
                    v-if="contenido.imgUrl"
                    disabled
                    class="edit-zona__img-container edit-zona__img-container--cultura">

                    <img
                      class="edit-zona__img edit-zona__img--cultura"
                      :src="contenido.imgUrl"
                      alt="contenido-cultura">

                  </a>

                  <div class="edit-zona__input-group">
                    <label class="edit-zona__label">Titulo</label>
                    <input class="edit-zona__input" :value="contenido.titulo" type="text" disabled>
                  </div>

                </div>
              </div>
            </div>

            <!-- Gestion de imagenes en cultura y gastronomia | Formulario para agregar -->
            <div v-if="openEdit" class="edit-zona__culturaIMG edit-zona__culturaIMG--agregar">
              <div class="edit-zona__input-group">
                <label class="edit-zona__label">Titulo de imagen</label>
                <input style="width: 50%;" class="edit-zona__input" type="text" v-model="contenidoTitulo">
              </div>
              <div class="edit-zona__input-group">
                <label class="edit-zona__label">Titulo de imagen (Ingles)</label>
                <input style="width: 50%;" class="edit-zona__input" type="text" v-model="contenidoTituloIngles">
              </div>
              <div class="edit-zona__input-group">
                <label class="edit-zona__label">Imagen</label>
                <input type="file" accept="image/*" @change="addContenidoImg">
              </div>
              <a href="" v-if="contenidoImgUrl" @click.prevent="deleteContenidoImg">
                <img class="edit-zona__img edit-zona__img--cultura" :src="contenidoImgUrl" alt="agregar-cultura-contenido">
              </a>
            </div>
            <!-- Gestion de imagenes en cultura y gastronomia | boton para activar el formulario -->
            <button
              class="edit-zona__btn edit-zona__btn--primary"
              type="button"
              @click="openContenido"
              v-if="language === 'es' && !openEdit">Agregar nueva imagen</button>
            <button
              class="edit-zona__btn edit-zona__btn--success"
              type="button"
              @click="openContenido"
              v-if="language === 'es' && openEdit">Agregar</button>

          </div>

          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Ver y hacer</label>
            <textarea
              class="edit-zona__textarea"
              name="verHacer"
              id="ver-hacer"
              cols="30"
              rows="10"
              :value="(language === 'es') ? zona.info.verHacer : zona.ingles.info.verHacer"
              @input="handleChange($event, 'info')"></textarea>
          </div>
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Cuando visitar</label>
            <textarea
              class="edit-zona__textarea"
              name="cuandoVisitar"
              id="cuando-visitar"
              cols="30"
              rows="10"
              :value="(language === 'es') ? zona.info.cuandoVisitar : zona.ingles.info.cuandoVisitar"
              @input="handleChange($event, 'info')"></textarea>
          </div>
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Donde quedarse</label>
            <textarea
              class="edit-zona__textarea"
              name="dondeQuedarse"
              id="donde-quedarse"
              cols="30"
              rows="10"
              :value="(language === 'es') ? zona.info.dondeQuedarse : zona.ingles.info.dondeQuedarse"
              @input="handleChange($event, 'info')"></textarea>
          </div>
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Como llegar</label>
            <textarea
              class="edit-zona__textarea"
              name="comoLlegar"
              id="como-llegar"
              cols="30"
              rows="10"
              :value="(language === 'es') ? zona.info.comoLlegar : zona.ingles.info.comoLlegar"
              @input="handleChange($event, 'info')"></textarea>
          </div>

          <div v-if="language === 'es'" class="edit-zona__localizacion-img">
            <!-- Informacion principal | Imagen para la localidad -->
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Imagen de localizacion</label>
              <input type="file" name="carousel-img" accept="image/*" @change="addImg($event, 'localidad')">
              <a
                href=""
                v-if="(zona.localidadImgUrl || zonaImgsToSave.localidadImgUrl)"
                @click.prevent="(zona.localidadImgUrl) ?
                  deleteImg({
                    type: 'localidad',
                    level: 'zonas',
                    url: zona.localidadImgUrl,
                    id: id
                  })
                  :
                  deleteUnsavedImg({ type: 'localidad' })"
                class="edit-zona__img-link">
                <img
                :src="(zona.localidadImgUrl) ? zona.localidadImgUrl : zonaImgsToSave.localidadImgUrl"
                alt="heading-img"
                class="edit-zona__img">
              </a>
            </div>
          </div>

          <h3 class="heading heading--3 heading--">Consejos</h3>
          <div class="edit-zona__consejos" v-if="(language === 'es' && zona.info.consejos.length > 0) || (language === 'en' && zona.ingles.info.consejos.length > 0)">
            <label class="edit-zona__label">Consejos ya guardados</label>
            <ul class="edit-zona__list edit-zona__list--1">
              <li
              class="edit-zona__list-item edit-zona__list-item--block"
              v-for="(consejo, index) in (language === 'es') ? zona.info.consejos : zona.ingles.info.consejos"
              :key="index">
                <label class="edit-zona__label" v-if="language === 'es'">Consejo {{(zona.info.consejos.length > 1) ? index + 1 : ''}}</label>
                <label class="edit-zona__label" v-else>Consejo {{(zona.ingles.info.consejos.length > 1) ? index + 1 : ''}}</label>
                <div class="edit-zona__list-content">
                  <p class="paragraph">{{ consejo }}</p>
                  <div class="edit-zona__buttons edit-zona__buttons--right">
                    <button
                      type="button"
                      @click="borrarConsejo({ index, level: 'zona', type: 'guardados'})"
                      class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div
            class="edit-zona__consejos"
            v-if="(language === 'es' && consejosToSave.length > 0) || (language === 'en' && zona.ingles.info.consejosToSave.length > 0)">
            <label class="edit-zona__label">Consejos por agregar</label>

            <ul class="edit-zona__list edit-zona__list--1">
              <li
              class="edit-zona__list-item edit-zona__list-item--block"
              v-for="(consejo, index) in (language === 'es') ? consejosToSave : zona.ingles.info.consejosToSave"
              :key="index">
                <label class="edit-zona__label" v-if="language === 'es'">Consejo {{ (consejosToSave.length > 1) ? index + 1 : ''}}</label>
                <label class="edit-zona__label" v-else>Consejo {{ (zona.ingles.info.consejosToSave.length > 1) ? index + 1 : ''}}</label>
                <div class="edit-zona__list-content">
                  <p class="paragraph">{{ consejo }}</p>
                  <div class="edit-zona__buttons edit-zona__buttons--right">
                    <button
                      type="button"
                      @click="borrarConsejo({ index, type: 'borrador', level: 'zona'})"
                      class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div v-if="addConsejo" class="edit-zona__input-group">
            <label class="edit-zona__label">Consejo</label>
            <input
              class="edit-zona__input"
              type="text"
              v-model="consejo">
          </div>

          <div>
            <button
              type="button"
              class="edit-zona__btn edit-zona__btn--primary"
              @click="activateConsejo">Agregar Consejo</button>
          </div>
        </div>
      </div>
      <div class="edit-zona__buttons">
        <div class="edit-zona__loading-button">
          <button
          class="edit-zona__btn edit-zona__btn--primary"
          :disabled="(isLoading)">Guardar</button>
          <div v-if="isLoading" class="lds-dual-ring"></div>
        </div>
        <button
          @click="cancelarZona"
          class="edit-zona__btn edit-zona__btn--secondary"
          style="margin: 50px;"
          type="button"
        :disabled="(isLoading)">Cancelar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { storage } from '../../firebase.service'

export default {
  metaInfo: {
    title: "Editar zona",
  },
  data () {
    return {
      id: null,
      addConsejo: false,
      consejo: null,
      isZonaUpdate: false,
      contenidoTitulo: null,
      contenidoTituloIngles: null,
      contenidoImg: null,
      contenidoImgUrl: null,
      openEdit: false,
      youtubeMediaToAdd: false,
      youtubeMedia: null
    }
  },
  created () {
    this.resetLanguage()
    this.resetBorrador()
    this.id = this.$route.params.id
    if (this.id) {
      this.setZonaId(this.$route.params.id)
      this.isZonaUpdate = true
    }
  },
  beforeDestroy () {
    this.resetZona({ level: 'zona' })
  },
  computed: {
    ...mapGetters([
      'zona',
      'zonaImgsToSave',
      'consejosToSave',
      'getDestinosByZona',
      'contenidoCulturaBorrador',
      'language',
      'isLoading'
    ]),
    getHeroContent () {
      if (this.zona && this.zona.heroImgUrls) {
        if (this.zona.heroImgUrls.length > 0) {
          const heroContentRef = this.zona.heroImgUrls.map((url) => {
            const { name } = storage.refFromURL(url)
            const nameParts = name.split('.')
            return {
              type: nameParts[1],
              url
            }
          })
          return heroContentRef
        }
      }
      return null
    },
    getHeroContentBorrador () {
      if (this.zonaImgsToSave.heroImgUrls && this.zonaImgsToSave.heroImgUrls.length > 0) {
        const heroContentRef = this.zonaImgsToSave.heroImgUrls.map((url) => {
          const extension = url.split(';')[0].split(':')[1].split('/')[0]
          return {
            type: extension,
            url
          }
        })
        return heroContentRef
      }
      return null
    }
  },
  methods: {
    ...mapActions([
      'setZonaId',
      'addZonaImg',
      'addZonaHeroImg',
      'updateZona',
      'deleteImg',
      'updateValue',
      'deleteUnsavedImg',
      'submitConsejo',
      'borrarConsejo',
      'resetZona',
      'deleteFromCulturaSaved',
      'deleteZona',
      'switchLanguage'
    ]),
    ...mapMutations([
      'addToCulturaBorrador',
      'deleteFromCulturaBorrador',
      'resetBorrador',
      'resetLanguage',
      'switchLoading',
      'switchDelete',
      'addYoutubeMedia',
      'deleteYoutubeMedia'
    ]),
    async submitZona (value) {
      try {
        this.switchLoading(true)
        await this.updateZona(value)
        this.switchLoading(false)
      } catch (error) {
        this.switchLoading(false)
        console.log(error)
      }
    },
    // Funcion para gestionar videos de youtube dentro de zonas
    //
    // addItem (event) {
    //   const { name } = event.target
    //   switch (name) {
    //     case 'youtubeMedia':
    //       if (name === 'youtubeMedia' && this.youtubeMediaToAdd) {
    //         if (this.youtubeMedia && this.youtubeMedia.length > 0) {
    //           const youtubePattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
    //           if (this.youtubeMedia.match(youtubePattern)) {
    //             const formattedLink = this.youtubeMedia.replace('watch?v=', 'embed/')
    //             this.addYoutubeMedia({ type: 'zona', value: formattedLink })
    //             this.youtubeMedia = null
    //             this.youtubeMediaToAdd = false
    //           } else {
    //             alert('Ingrese un link de youtube valido')
    //           }
    //         }
    //         return
    //       }
    //       this.youtubeMediaToAdd = true
    //       break
    //     default:
    //       break
    //   }
    // },
    async deleteRequest (value) {
      try {
        this.switchLoading(true)
        this.switchDelete(true)
        await this.deleteZona(value)
        this.switchDelete(false)
        this.switchLoading(false)
      } catch (error) {
        this.switchDelete(false)
        this.switchLoading(false)
        console.log(error)
      }
    },
    cancelarZona () {
      this.$router.push({ path: '/admin' })
    },
    activateConsejo () {
      if (this.addConsejo) {
        if (this.consejo && this.consejo.length > 0) {
          this.submitConsejo({ consejo: this.consejo, level: 'zona' })
          this.consejo = null
        }
        return
      }
      this.addConsejo = true
    },
    navigateToHome () {
      this.$router.push({ path: '/admin' })
    },
    openContenido () {
      const isFilled = (this.contenidoImgUrl && this.contenidoImg && this.contenidoTitulo && this.contenidoTitulo.length > 0 && this.contenidoTituloIngles && this.contenidoTituloIngles.length > 0)
      if (isFilled) {
        const data = {
          titulo: this.contenidoTitulo,
          tituloIngles: this.contenidoTituloIngles,
          imgUrl: this.contenidoImgUrl,
          img: this.contenidoImg
        }
        this.addToCulturaBorrador(data)
        this.contenidoTitulo = null
        this.contenidoTituloIngles = null
        this.contenidoImg = null
        this.contenidoImgUrl = null
        this.openEdit = false
        return
      }

      if (!(this.contenidoCulturaBorrador.length > 0) || !this.openEdit) {
        this.openEdit = true
      }
    },
    deleteCulturaContent (request) {
      if (request.type === 'borrador') {
        this.deleteFromCulturaBorrador({ level: 'zona', index: request.index })
      } else if (request.type === 'guardado') {
        this.deleteFromCulturaSaved({ level: 'zona', index: request.index })
      }
    },
    deleteContenidoImg () {
      this.contenidoImg = null
      this.contenidoImgUrl = null
    },
    addContenidoImg (event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.contenidoImgUrl = fileReader.result
        this.contenidoImg = files[0]
        event.target.value = ''
      })
      fileReader.readAsDataURL(files[0])
    },
    addImg (event, type) {
      if (type === 'carousel' && this.zona.carouselImgUrl) {
        alert('Debe eliminar la imagen ya registrada antes de registrar una nueva')
        return
      }
      if (type === 'localidad' && this.zona.localidadImgUrl) {
        alert('Debe eliminar la imagen ya registrada antes de registrar una nueva')
        return
      }
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        const imgUrl = fileReader.result
        const imgFile = files[0]
        this.addZonaImg({ imgUrl, imgFile, type })
        event.target.value = ''
      })
      fileReader.readAsDataURL(files[0])
    },
    addHeroImg ({ target }) {
      const file = target.files[0]
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        const imgUrl = fileReader.result
        const imgFile = file
        this.addZonaHeroImg({ imgUrl, imgFile })
        target.value = ''
      })
      fileReader.readAsDataURL(file)
    },
    handleChange (event, type = 'normal') {
      const { name, value } = event.target
      const level = 'zona'
      this.updateValue({ name, value, level, type })
    }
  }
}
</script>

<style lang="scss">
.container {
  &--flex-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.paragraph {
  color: inherit;
}
.fa-plus-circle {
  font-size: 1.6rem;
  margin-right: 10px;
}
.fa-arrow-alt-circle-left {
  font-size: 1.5rem;
  margin-right: 10px;
}
.header {
  background: #333;
  margin-bottom: 50px;
}
.edit-zona {
  color: white;
  &__input-text {
    cursor: inherit;
  }
  &__container {
    &--fluid {
      width: 100%;
    }
  }
  &__list {
    list-style-type: none;
    &--1 {
      padding-top: 20px;
    }
  }
  &__buttons {
    width: 100%;
    text-align: center;
    &--right {
      text-align: right;
    }
  }
  &__youtube-iframe {
    display: block;
    margin: 10px auto;
    width: 100%;
    height: 200px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px;
  }
  &__loading-button {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    button {
      margin: 0;
    }
  }
  &__list-content {
    margin: 15px 0;
    background: white;
    color: black;
    font-weight: 500;
    border-top: 1px solid white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;
    &:last-child {
      border-bottom: 1px solid white;
    }
  }
  &__list-item {
    &--block {
      display: block;
      width: 100%;
    }
  }
  &__btn {
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px;
    font-weight: 500;
    box-shadow: 1px 1px 3px black;
    border: none;
    &--warning {
      background: rgb(206, 64, 64);
      color: white;
    }
    &--success {
      color: white;
      background: rgb(12, 128, 12);
    }
    &--primary {
      color: white;
      background: rgb(55, 127, 221);
    }
    &--consejos {
      margin: 0 20px 0 0;
    }
    &--link {
      margin: 0;
      border: none;
      font-size: 1.3rem;
      font-weight: 500;
      padding: 10px 25px;
      transition: transform .25s ease;
      &:hover {
        transform: scale(1.03);
      }
    }
    &--nav,
    &--nav:active,
    &--nav:focus {
      background: none;
      border: none;
      box-shadow: none;
      color: white;
      font-size: 1.5rem;
    }
  }
  &__gastronomia-content {
    padding: 30px 0px;
  }
  &__textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;
    width: 100%;
  }
  &__input {
    padding: 15px;
    border: none;
    box-shadow: 1px 1px 5px;
    width: 100%
  }
  &__gestion-cultura {
    &--agregar {
      display: grid;
      grid-template-columns: 23% 23% 23% 23%;
      grid-gap: 20px;
    }
  }
  &__agregar-contenido {
    cursor: pointer;
  }
  &__img--cultura {
    margin: auto;
  }
  &__container {
    width: 90%;
    margin: auto;
  }
  &__form-container {
    padding-top: 35px;
  }
  &__label {
    margin: 0 0 10px 0;
    font-size: 1.3rem;
    cursor: inherit;
  }
  &__container--flex {
    display: flex;
    justify-content: space-between;
  }
  &__side {
    width: 45%
  }
  &__informacion {
    padding: 50px 0;
  }
  &__img {
    height: 150px;
    width: 180px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;
    &--1 {
      background: rgb(26, 26, 26);
    }
  }
  &__img-container {
    display: flex;
    flex-wrap: wrap;
  }
  &__img-container--cultura{
    justify-content: center;
    cursor: inherit;
  }
  &__destino {
    color: #333;
    box-shadow: 1px 1px 5px black;
    margin: 20px;
    border-radius: 5px;
    background: white;
    border: 1px solid black;
    display: inline-block;
    border: none;
  }
}

.edit-zona__input-group {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: flex-start;
}

.heading {
  &--center {
    text-align: center;
  }
}

</style>
