<template>
<div :class="{ 'nav-buscador': buscadorMobile, 'd-none': !buscadorMobile }">
  <Buscador />
  <div class="invisible-buscador" @click="buscadorToggle"></div>
</div>
</template>

<script>
/* eslint-disable */
import Buscador from "@/components/Buscador.vue";
import {
  mapState,
  mapMutations
} from "vuex";

export default {
  name: "BuscadorNav",
  computed: {
    ...mapState(["buscadorMobile"]),
  },
  methods: {
    ...mapMutations(["buscadorToggle"]),
  },
  components: {
    Buscador,
  },
};
</script>

<style lang="scss">
.nav-buscador {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 60px;
  left: 0;
  right: 100%;
  bottom: 0;
  background-color: rgba(53, 53, 53, 0.9);
  z-index: 20;
  animation: menuBuscador 150ms;

  @keyframes menuBuscador {
    0% {
      left: -100%;
    }

    50% {
      left: -50%;
    }

    100% {
      left: 0;
    }
  }

  padding: 31px 37px;

  // #Buscador {
  //   .dropdownDestinos {
  //     h5 {
  //       margin-left: 15px !important;
  //     }
  //   }
  // }
  .invisible-buscador {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 60px;
    left: 0;
    right: 100%;
    bottom: 0;
  }
}
</style>
