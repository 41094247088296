<template>
  <table class="text-center tabla-pasajeros">
    <tr>
      <th>Categoría</th>
      <th>Precio unitario</th>
      <th>Cantidad</th>
      <th>Total</th>
    </tr>
    <tr>
      <td>Adulto</td>
      <td>${{ numToStr(precioAdulto) }}</td>
      <td>{{ cantidadAdultos }}</td>
      <td>${{ numToStr(precioAdulto * cantidadAdultos) }}</td>
    </tr>
    <template v-if="reserva.cantidadNiños && cantidadNiñosSumados">
      <tr v-for="(niño, i) in reserva.cantidadNiños" :key="i">
        <template v-if="niño.cantidad">
          <td>{{ niño.nombre }}</td>
          <td>${{ numToStr(niño.precio) }}</td>
          <td>{{ niño.cantidad }}</td>
          <td>${{ numToStr(niño.precio * niño.cantidad) }}</td>
        </template>
      </tr>
    </template>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
import { numberToString, stringToNumber } from "@/utils/formatNumber";

export default {
  name: "TablaPasajeros",
  props: {
    precioAdulto: {
      required: true,
      default: 0,
    },
    cantidadAdultos: {
      required: true,
      default: 0,
    },
  },
  computed: {
    ...mapGetters(["reserva", "cantidadNiñosSumados"]),
    reservaActual() {
      return this.$route.name == "Checkout"
        ? this.reserva.reservas[0]
        : this.reserva;
    },
    niñosData() {
      const { niños } = this.reservaActual.detalleViajeros;

      const niñosExp = this.$route.name === 'ExperienciaDetalle'
        ? this.reservaActual.cantidadNiños
        : niños;

      return niñosExp;
    },
  },
  methods: {
    numToStr(num) {
      return numberToString(num);
    },
    strToNum(str) {
      return stringToNumber(str);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabla-pasajeros {
  overflow: scroll;
  font-size: 0.85em;
  width: 100%;
  td,
  th {
    border: 1px solid #ddd;
  }
}
</style>