<template>
  <div id="Landing">
    <div class="hero-section">
      <div class="hero-section__text">
        <h1 class="hero-section__heading" v-html="getLandingTitle"> </h1>
        <button class="btn">{{ started }}</button>

      </div>
        <div style="background-color: black; height: 100%;">
          <img class="hero-section__images" :style="{
            display: 'block',
            background: `url('https://firebasestorage.googleapis.com/v0/b/sur-real.appspot.com/o/zonas%2FxhOAY7UtoD5LDuvOvim4%2Fdestinos%2FCX6msz0PnxfvtDhATu0g%2Fhero%2F99463767_thumb_1200.jpg?alt=media&token=7cfe462a-8881-4c03-954d-a7dabdf13c70') center center / cover no-repeat`,
            height: '100%',
            opacity: 0.7
          }" />
        </div>
    </div>
    <section class="section section--how mt-2">
      <div class="container text-center text-sm-left">
        <div class="row justify-content-center">

          <div class="col-8 offset-xs-2 col-sm-12 offset-sm-0">

            <div class="row">
              <div class="col-12 col-lg-2 justify-content-center text-center m-auto">
                <h5 class="small-caps">{{ __get('comoFunciona') }}</h5>
              </div>
              <div class="col-12 col-lg-10">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="step">
                      <i class="fa fa-user"></i>
                      <span> {{__get('step')}} 1</span>
                    </div>
                    <h6>{{ __get('cuentaViaje') }}</h6>
                    <div class="text-muted">{{ __get('completaCuestionario') }}</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="step">
                      <i class="fa fa-comments"></i>
                      <span>{{__get('step')}} 2</span>
                    </div>
                    <h6>{{ __get('chatEspecialista') }}</h6>
                    <div class="text-muted">{{ __get('resolverDetalles') }}</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="step">
                      <i class="fa fa-credit-card"></i>
                      <span>{{__get('step')}} 3</span>
                    </div>
                    <h6>{{ __get('reservaSegura') }}</h6>
                    <div class="text-muted">{{ __get('garantiaSast') }}</div>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    </section>
    <Tours :tours="tours" />
  </div>
</template>

<script>
/* eslint-disable */
import Tours from "@/components/Tours";
import { mapGetters, mapActions, mapState } from "vuex";
import { storage } from "../firebase.service";
import Breadcrumbs from "./../components/Breadcrumbs";

export default {
  name: "Landing",
  metaInfo() {
    return {
      title: "Landing",
      metaInfo: {
        title: "Descubre el sur de Chile",
      },
    };
  },
  data() {
    return {
      id: 'CX6msz0PnxfvtDhATu0g',
    };
  },
  components: {
    Tours,
    Breadcrumbs,
  },
  computed: {
    ...mapGetters(["language"]),
    ...mapState("trip", ["viajes"]),
    ...mapGetters("Tours", ["started"]),
    ...mapGetters("i18n", ["getLandingTitle"]),
    ...mapGetters("i18n", ["__get"]),
    destino() {
      return this.getDestinos.find((destino) => destino.id === this.id);
    },
    tours() {
      return this.viajes;
    },
  },
  methods: {
    ...mapActions("trip", [
      "getViajes",
    ]),
  },
  created() {
    this.getViajes();
  },
};
</script>

<style lang="scss">
#Landing {
  .carousel-indicators {
    display: none;

    @media screen and (max-width: 576px) {
      display: flex;

      li {
        width: 13px;
        height: 13px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0);
        border: 2px solid white;
      }

      li.active {
        background-color: rgba(255, 255, 255, 1);
      }
    }
  }

  .step{
    color: rgb(255, 170, 1);
  }
  
  .carousel-item,
  .carousel-item-next {
    height: 450px;
  }

  overflow-x: hidden;

  .hero-section {
    width: 100%;
    height: 450px;
    overflow: hidden;
    color: white;
    text-align: center;
    position: relative;

    &__images {
      width: 100%;
      filter: brightness(65%);

      &--video {
        object-fit: fill;
      }
    }

    .carousel-control-prev-icon {
      background: url("../assets/img/iconos/left_light.svg");
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    .carousel-control-next-icon {
      background: url("../assets/img/iconos/right_light.svg");
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    &__text {
      width: 80%;
      position: absolute;
      z-index: 10;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: stretch;
    }

    &__heading {
      font-size: 50px;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 35px;
        line-height: 43px;
        margin-bottom: 20px;
      }
    }

    &__paragraph {
      font-size: 22px;
      font-weight: lighter;
      width: 70%;
      margin: 0 auto;
      text-align: center;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        width: 100%;
        line-height: 25px;
      }
    }
  }

  .opciones {
    div {
      border-right: groove 1px #00000056 !important;

      @media screen and (max-width: 524px) {
        background-color: #f6f6f6;
        color: #353535;
      }
    }

    h1 {
      font-size: 19px;
      font-weight: lighter;
      padding: 12px 0px;
      margin-bottom: 0;
      text-align: center;

      @media screen and (max-width: 524px) {
        font-size: 8px;
        margin-bottom: 0;
        height: 100%;
      }
    }

    a {
      color: black;
      height: 100%;

      &:hover {
        text-decoration-line: none;
      }
    }
  }

  .router-link-active {
    background: #3791f3;
    color: white !important;
    font-weight: bold;
    display: block;
  }
}
</style>
