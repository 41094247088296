/* eslint-disable */
export default {
  namespaced: true,

  state: {
    ContactanosESP: {
      titulo: "Contáctanos",
      nombre: "Nombre",
      enviar: "Enviar",
      placeholder_text: "Escribe tu mensaje",
      placeholder_asunto: "Asunto",
      parrafo:
        "Estaremos felices de poder ayudarte a encontrar las mejores experiencias según tus intereses, darte consejos para armar tu ruta y todo lo que necesites saber. Queremos que vivas esta maravillosa tierra."
    },
    ContactanosENG: {
      titulo: "Contact us",
      nombre: "Name",
      enviar: "Send",
      placeholder_text: "Enter your message",
      placeholder_asunto: "Subject",
      parrafo:
        "We will be happy to help you find the best experiences according to your interests, give you advice to put together your route and everything you need to know. We want you to live this wonderful land."
    }
  },
  getters: {
    Titulo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ContactanosESP.titulo;
      } else if (rootState.language == "en") {
        return state.ContactanosENG.titulo;
      }
    },
    Nombre(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ContactanosESP.nombre;
      } else if (rootState.language == "en") {
        return state.ContactanosENG.nombre;
      }
    },
    Parrafo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ContactanosESP.parrafo;
      } else if (rootState.language == "en") {
        return state.ContactanosENG.parrafo;
      }
    },
    PlaceholderText(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ContactanosESP.placeholder_text;
      } else if (rootState.language == "en") {
        return state.ContactanosENG.placeholder_text;
      }
    },
    PlaceholderAsunto(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ContactanosESP.placeholder_asunto;
      } else if (rootState.language == "en") {
        return state.ContactanosENG.placeholder_asunto;
      }
    },
    Enviar(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ContactanosESP.enviar;
      } else if (rootState.language == "en") {
        return state.ContactanosENG.enviar;
      }
    }
  },
  mutations: {},
  actions: {}
};
