<template>
  <div id="MenuTop">
    <!-- MENU TOP -->
    <b-row class="menu-top">
      <b-container>
        <ul class="menu-top__list">
          <li
            :class="{
              'menu-top__list-item': true,
              'menu-top__list-item--active': (language === 'es')
            }"
            @click="(language === 'es') ? null : switchLanguage()"
          >
            <img src="@/assets/img/iconos/esp.svg" alt="ESP" class="mr-2" /> ES
          </li>

          <li
            :class="{
              'menu-top__list-item': true,
              'menu-top__list-item--active': (language === 'en')
            }"
            @click="(language === 'en') ? null : switchLanguage()"
          >
            <img src="@/assets/img/iconos/eng.png" alt="ENG" class="mr-2" /> EN
          </li>

          <li class="menu-top__list-item">
            CLP
            <svg class="menu-top__svg" viewBox="0 0 24 24">
              <path
                fill="currentColor"
                d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"
              />
            </svg>
          </li>

          <li class="menu-top__list-item menu-top__list-item--2">
            <router-link :to="{ name: 'Checkout' }">
              <img src="@/assets/img/iconos/carrito.svg" alt style="cursor: pointer" />
            </router-link>
          </li>

          <li class="menu-top__list-item menu-top__list-item--2">0</li>
        </ul>
      </b-container>
    </b-row>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "MenuTop",
  computed: {
    ...mapGetters(["language"]),
    // Clase dinámica de idioma activo
    localeActivoEsp() {
      return {
        activoLocale:
          this.$store.state.language == "es" &&
          this.$store.state.language !== "en",
      };
    },
    // Clase dinámica de idioma activo
    localeActivoEng() {
      return {
        activoLocale:
          this.$store.state.language == "en" &&
          this.$store.state.language !== "es",
      };
    },
  },
  methods: {
    ...mapActions(["switchLanguage"]),
    // Cambio de idioma activo
    ...mapMutations(["setLocaleEsp", "setLocaleEng"]),
  },
};
</script>

<style lang="scss" scoped>
.menu-top {
  background: #353535 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 40px;
  color: white;
  margin: 0;
  position: relative;
  z-index: 20;

  &__list {
    padding: 0;
    margin-left: auto;
    height: 40px;
    width: 440px;
    list-style: none;
    display: flex;
    align-items: center;
  }

  &__list-item {
    cursor: pointer;
    align-items: center;
    display: flex;
    width: 20%;
    justify-content: flex-end;
    font-weight: bold;
    font-size: 18px;
    color: white !important;

    &--active {
      color: #ffaa01 !important;
    }

    &--2 {
      cursor: default;
    }
  }

  &__svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
}
.activoLocale {
  color: #ffaa01 !important;
}

.container {
  margin: auto !important;
}
</style>

