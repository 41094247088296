<template>
  <b-container v-if="experiencia.temporadas">
    <b-row>
      <b-col cols="12" lg="6">
        <!-- Formulario agregar temporada -->
        <b-form v-if="showAddTemporada" @submit="addSeason">
          <label for="nombreTemporada">Nombre Temporada</label>
          <b-form-input
            name="nombreTemporada"
            id="nombreTemporada"
            v-model="temporada.nombre"
          />
          <label for="colorTemporada">Color temporada</label>
          <b-form-select
            name="colorTemporada"
            id="colorTemporada"
            v-model="temporada.color"
            :options="colors"
          />
          <div class="mt-3">
            <b-btn
              variant="danger"
              @click="showAddTemporada = false"
              class="mr-2"
              >Cancelar</b-btn
            >
            <b-btn variant="success" type="submit">Guardar</b-btn>
          </div>
        </b-form>

        <!-- Agregar varias fechas -->
        <div v-else-if="showAddVariasFechas">
          <div>
            <h5>Dias de la semana:</h5>
            <b-form-checkbox
              id="checkbox-AllDays"
              name="Lunes"
              :value="true"
              :unchecked-value="false"
              @change="toggleTodoslosDias"
            >
              Todos los días
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-Lunes"
              v-model="diasRango.Lunes"
              name="Lunes"
              :value="true"
              :unchecked-value="false"
            >
              Lunes
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-Martes"
              v-model="diasRango.Martes"
              name="Martes"
              :value="true"
              :unchecked-value="false"
            >
              Martes
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-Miercoles"
              v-model="diasRango.Miercoles"
              name="Miercoles"
              :value="true"
              :unchecked-value="false"
            >
              Miercoles
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-Jueves"
              v-model="diasRango.Jueves"
              name="Jueves"
              :value="true"
              :unchecked-value="false"
            >
              Jueves
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-Viernes"
              v-model="diasRango.Viernes"
              name="Viernes"
              :value="true"
              :unchecked-value="false"
            >
              Viernes
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-Sabado"
              v-model="diasRango.Sabado"
              name="Sabado"
              :value="true"
              :unchecked-value="false"
            >
              Sabado
            </b-form-checkbox>

            <b-form-checkbox
              id="checkbox-Domingo"
              v-model="diasRango.Domingo"
              name="Domingo"
              :value="true"
              :unchecked-value="false"
            >
              Domingo
            </b-form-checkbox>
          </div>

          <h5>En rango:</h5>
          <vc-date-picker v-model="range" mode="range" is-range>
            <template v-slot="{ inputEvents }">
              <div class="flex justify-center items-center mb-2">
                <div>
                  <p class="m-0">Desde:</p>
                  <input
                    :value="range.start"
                    v-on="inputEvents.start"
                    class="
                      border
                      px-2
                      py-1
                      w-32
                      rounded
                      focus:outline-none
                      focus:border-indigo-300
                    "
                  />
                </div>
                <svg
                  class="w-4 h-4 mx-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M14 5l7 7m0 0l-7 7m7-7H3"
                  />
                </svg>
                <div>
                  <p class="m-0">Hasta:</p>
                  <input
                    :value="range.end"
                    v-on="inputEvents.end"
                    class="
                      border
                      px-2
                      py-1
                      w-32
                      rounded
                      focus:outline-none
                      focus:border-indigo-300
                    "
                  />
                </div>
              </div>
            </template>
          </vc-date-picker>

          <b-btn @click="agregarRango">Agregar</b-btn>
        </div>

        <!-- Calendar -->

        <vc-calendar
          v-else
          :attributes="attributes"
          @dayclick="onDayClick"
          :min="new Date()"
        ></vc-calendar>
      </b-col>

      <!--  -->

      <b-col cols="12" lg="6">
        <div>
          <b-btn
            @click="editar = !editar"
            :variant="editar ? 'success' : 'primary'"
            class="mt-2"
          >
            {{ editar ? "Congelar calendario" : "Editar" }}
          </b-btn>

          <b-btn v-if="editar" @click="toggleAddTemporada" class="ml-2 mt-2"
            >Agregar temporada</b-btn
          >

          <b-btn
            v-if="editar && temporadaSelected"
            class="ml-2 mt-2"
            @click="agregarVariasFechas"
            >Agregar varias fechas</b-btn
          >
        </div>

        <div v-if="experiencia.temporadas.temporadas.length">
          <h5>Temporadas:</h5>
          <h6 v-if="temporadaSelected">
            Temporada seleccionada:
            <span
              class="temporadaSeleccionada"
              :style="{ backgroundColor: temporadaSelected.color }"
              >Temporada {{ temporadaSelected.nombre }}</span
            >
          </h6>
          <h6>Haz click sobre una temporada para seleccionarla</h6>
          <b-dropdown
            split
            v-for="temporada in experiencia.temporadas.temporadas"
            :key="temporada.color"
            :style="{ backgroundColor: `${temporada.color} !important`}"
            class="mr-2 btnTemporadas"
            @click="temporadaSelected = temporada"
            :text="temporada.nombre"
            split-class="main-btn"
            split-variant=" "
            
          >
            <b-dropdown-item @click="eliminarTemporada(temporada)"
              >Eliminar temporada</b-dropdown-item
            >
          </b-dropdown>

          <!-- <b-btn
            v-for="temporada in experiencia.temporadas.temporadas"
            :key="temporada.color"
            :style="{ backgroundColor: temporada.color }"
            class="mr-2 btnTemporadas"
            @click="temporadaSelected = temporada"
          >
            <span>Temporada {{ temporada.nombre }}</span>
          </b-btn> -->
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import moment from "moment";
export default {
  name: "CalendarioTemporadas",
  props: {
    experienciaID: {
      type: String,
      required: true,
    },
  },
  created() {
    const coloresTemporadasEnUso = this.experiencia.temporadas.temporadas.map(
      (t) => t.color
    );

    coloresTemporadasEnUso.forEach((color, i) => {
      let colores = this.colors.map((color) => color.value);
      const idxColor = colores.indexOf(color);
      this.colors.splice(idxColor, 1);
    });
  },
  data() {
    return {
      colors: [
        { value: "gray", text: "Grís" },
        { value: "red", text: "Rojo" },
        { value: "orange", text: "Naranjo" },
        { value: "yellow", text: "Amarillo" },
        { value: "green", text: "Verde" },
        { value: "teal", text: "Turquesa" },
        { value: "blue", text: "Azul" },
        { value: "indigo", text: "Indigo" },
        { value: "purple", text: "Morado" },
        { value: "pink", text: "Rosado" },
      ],
      editar: false,
      showAddTemporada: false,
      temporadaSelected: null,
      showAddVariasFechas: false,
      temporada: {
        nombre: "",
        color: "#FFFFFF",
      },
      //variasFechas
      range: {
        start: new Date(),
        end: new Date(),
      },
      diasRango: {
        Lunes: false,
        Martes: false,
        Miercoles: false,
        Jueves: false,
        Viernes: false,
        Sabado: false,
        Domingo: false,
      },
    };
  },
  computed: {
    ...mapGetters(["experiencia", "experienciaBorrador"]),
    attributes() {
      //   return this.experiencia.temporadas;
      return this.experiencia.temporadas.fechas.map(({ date, color, id , temporada }) => ({
        highlight:{
          color,
          fillMode: 'solid'
        },
        dates: date,
        key: id,
        popover:{
          label:`Temporada ${temporada}`,
        }
      }));
    },
  },
  methods: {
    ...mapMutations(["submitTemporadas", "deleteTemporada"]),
    onDayClick(day) {
      if (!this.editar) return;
      if (
        !this.temporadaSelected &&
        !this.experiencia.temporadas.temporadas.length
      ) {
        confirm("Debes crear al menos una temporada") &&
          (this.showAddTemporada = true);
        return;
      }

      this.submitTemporadas({ day, temporadaSelected: this.temporadaSelected });
    },
    addSeason(e) {
      e.preventDefault();
      const { nombre, color } = this.temporada;
      const hayOtraConElMismoNombre =
        this.experiencia.temporadas.temporadas.find(
          (t) => t.nombre.toLowerCase().trim() === nombre.toLowerCase().trim()
        );
      if (hayOtraConElMismoNombre) {
        alert("Ya hay una temporada con ese nombre, prueba con otro");
        return;
      }
      if (nombre.includes(" ")) {
        alert(
          "El nombre de la temporada no puede incluir espacios.\nIntenta que sea solo una palabra"
        );
        return;
      }

      const newTemporada = { nombre: nombre.trim(), color };

      this.submitTemporadas({ newTemporada });
      this.colors = this.colors.filter((c) => c.value != color);

      this.temporadaSelected = newTemporada;
      this.temporada = { nombre: "", color: null };
      this.showAddTemporada = false;
    },
    eliminarTemporada(temporada) {
      console.log("eliminar temporada method");
      this.deleteTemporada(temporada.nombre);
    },
    agregarVariasFechas() {
      this.showAddTemporada = false;
      this.showAddVariasFechas = true;
    },
    toggleAddTemporada() {
      this.showAddTemporada = true;
      this.showAddVariasFechas = false;
    },
    toggleTodoslosDias(value) {
      if (!value) {
        this.diasRango.Lunes = false;
        this.diasRango.Martes = false;
        this.diasRango.Miercoles = false;
        this.diasRango.Jueves = false;
        this.diasRango.Viernes = false;
        this.diasRango.Sabado = false;
        this.diasRango.Domingo = false;
      } else {
        this.diasRango.Lunes = true;
        this.diasRango.Martes = true;
        this.diasRango.Miercoles = true;
        this.diasRango.Jueves = true;
        this.diasRango.Viernes = true;
        this.diasRango.Sabado = true;
        this.diasRango.Domingo = true;
      }
    },
    agregarRango() {
      const { Domingo, Lunes, Martes, Miercoles, Jueves, Viernes, Sabado } =
        this.diasRango;

      const dias = [Domingo, Lunes, Martes, Miercoles, Jueves, Viernes, Sabado];
      let { end: fechaFin, start: fechaInicio } = this.range;
      fechaInicio = moment(fechaInicio);
      fechaFin = moment(fechaFin);

      for (let f = fechaInicio; f.isSameOrBefore(fechaFin); f.add(1, "d")) {
        if (dias[f.day()]) {
          const day = { id: f.format("YYYY-MM-DD"), date: new Date(f) };
          this.onDayClick(day);
        }
      }
      this.showAddTemporada = false;
      this.showAddVariasFechas = false;
      this.toggleTodoslosDias(false)
    },
  },
};
</script>

<style lang="scss" scoped>
.temporadaSeleccionada {
  text-shadow: 0 0 5px #000, 0 0 1px #000;
}

.btnTemporadas {
  .main-btn,
  .btn {
    color: #fff !important;
  }
}

.temporadaSeleccionada {
  padding: 0.25rem;
  transition: all 0.5s ease-in-out;
}
</style>