<template>
<div id="MainMenu">
  <b-container class="p-0">
    <div class="d-sm-none d-inline-block ml-sm-3">
      <svg @click="
            hamburguerToggle();
            buscadorFalse();
            langFalse();
          " class="svg-hamburguer" viewBox="0 0 100 80" width="40" height="20">
        <rect width="100" height="10" />
        <rect y="30" width="100" height="10" />
        <rect y="60" width="100" height="10" />
      </svg>
    </div>
    <div id="logo">
      <router-link to="/">
        <img @click="
              buscadorFalse();
              langFalse();
            " src="@/assets/img/logo.png" alt="SurRealLogo" width="250" />
      </router-link>
    </div>
    <!-- Destinos -->
    <b-dropdown v-show="isMobile()" :text="__get('destSingleTitle')" variant="outline" size="md" class="lupaNav" toggle-class="font-weight-bold" >
      <b-dropdown-item @click="sendDest(dest.id)" v-for="(dest, index) in getDestinos.filter(d => d.heroImgUrls && d.heroImgUrls.length > 0)
          .map(des => {
            const order =
              des.localizacion &&
              des.localizacion.latitud &&
              parseInt(des.localizacion.latitud)
                ? parseInt(des.localizacion.latitud)
                : 999;
            return { ...des, order };
          })
          .sort((des1, des2) =>
            des1.order > des2.order ? 1 : des1.order < des2.order ? -1 : 0
          )" :key="index">{{language === "en" && dest.ingles.titulo
                  ? dest.ingles.titulo
                  : dest.titulo}}</b-dropdown-item>
    </b-dropdown>
    <!-- Idioma -->
    <!-- <div @click="
          langToggle();
          buscadorFalse();
        " class="lang d-sm-none d-inline-block">
      <img src="./../assets/img/iconos/esp.svg" alt="usd" :class="{'d-none': language === 'en'}" width="30" height="23" />
      <span :class="{'d-none': language === 'en'}" class="font-weight-bold">ES</span>
      <img :class="{'d-none': language === 'es'}" src="@/assets/img/iconos/eng.png" alt="eng" />
      <span :class="{'d-none': language === 'es'}" class="font-weight-bold">EN</span>
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" fill="#353535" viewBox="0 0 24 24">
        <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
      </svg>
    </div> -->
    <!-- Lupa Búsqueda -->
    <!-- <div @click="
          buscadorToggle();
          langFalse();
        " class="lupaNav d-sm-none d-inline-block">
      <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23.699" viewBox="0 0 25 25.699">
        <path class="alupa" d="M1476.359-518.506a1.184,1.184,0,0,1-.342.853l-1.367,1.407a1.12,1.12,0,0,1-.83.351,1.115,1.115,0,0,1-.83-.351l-4.884-5.02a1.179,1.179,0,0,1-.342-.853v-.8a9.666,9.666,0,0,1-6.25,2.208,9.657,9.657,0,0,1-5.1-1.4,10.405,10.405,0,0,1-3.687-3.79,10.357,10.357,0,0,1-1.367-5.246,10.355,10.355,0,0,1,1.367-5.244,10.405,10.405,0,0,1,3.687-3.79,9.656,9.656,0,0,1,5.1-1.4,9.656,9.656,0,0,1,5.1,1.4,10.406,10.406,0,0,1,3.687,3.79,10.357,10.357,0,0,1,1.368,5.244,10.287,10.287,0,0,1-2.15,6.425h.782a1.12,1.12,0,0,1,.83.351l4.883,5.02A1.184,1.184,0,0,1,1476.359-518.506Zm-11.695-7.078a6.183,6.183,0,0,0,2.271-2.333,6.507,6.507,0,0,0,.83-3.238,6.505,6.505,0,0,0-.83-3.236,6.2,6.2,0,0,0-2.271-2.335,6.066,6.066,0,0,0-3.148-.853,6.067,6.067,0,0,0-3.15.853,6.185,6.185,0,0,0-2.27,2.335,6.508,6.508,0,0,0-.83,3.236,6.51,6.51,0,0,0,.83,3.238,6.167,6.167,0,0,0,2.27,2.333,6.051,6.051,0,0,0,3.15.853A6.051,6.051,0,0,0,1464.664-525.584Z" transform="translate(-1451.359 541.594)" />
      </svg>
    </div> -->

    <div class="opciones d-sm-inline-block d-none">
      <ul>

        <li class="buscadorli" style="visibility: hidden;">
          <div class="buscador">
            <div style="position: relative;" class="w-100">
              <div class="dropdownDestinos">
                <h5>
                  Destinos
                  <img src="@/assets/img/iconos/down.svg" alt />
                </h5>
                <div class="dropdownDestinosList" v-if="getSortedZonas && getSortedZonas.length > 0">
                  <ul>
                    <router-link tag="li" :to="`/zonas/${nameAsId({ id: zona.id })}/overview`" v-for="(zona, index) in getSortedZonas" :key="index" class="text-center" :class="{
                          opcionesZonas: true,
                          Centro: zona.titulo.toLowerCase().includes('centro'),
                          Lagos: zona.titulo.toLowerCase().includes('lagos'),
                          Patagonia: zona.titulo
                            .toLowerCase()
                            .includes('patagonia')
                        }" v-show="index == 2 || index == 3">
                      {{ zona.titulo }}
                      <div :class="{
                            subNorte: zona.titulo
                              .toLowerCase()
                              .includes('norte'),
                            subCentro: zona.titulo
                              .toLowerCase()
                              .includes('centro'),
                            subLagos: zona.titulo
                              .toLowerCase()
                              .includes('lagos'),
                            subPatagonia: zona.titulo
                              .toLowerCase()
                              .includes('patagonia')
                          }">
                        <ul class="destinosEnZona">
                          <router-link tag="li" :to="`/destinos/${destino.id}/experiencias`" v-for="(destino, index) in siteDestinosByZona({
                                id: zona.id
                              })" :key="index">
                              {{ destino.titulo }}
                              </router-link>
                        </ul>
                      </div>
                    </router-link>
                  </ul>
                </div>
              </div>

              <b-form-input list="my-list-id" v-model="busqueda" @keyup.enter="buscar"></b-form-input>

              <b-input-group-text class="lupa" style="height:40px" @click="buscar">
                <img src="@/assets/img/iconos/lupaBlanca.svg" alt />
              </b-input-group-text>
            </div>
          </div>
        </li>
        <!-- <li class="mayus" style="color: black;">
          <router-link :to="{name: 'ViajesPersonalizados'}">{{ MainMenuViaje }}</router-link>
        </li> -->
        <li class="mayus">
          <b-dropdown :text="__get('destSingleTitle')" variant="outline" size="md" toggle-class="font-weight-bold">
            <b-dropdown-item  @click="sendDest(dest.id)" v-for="(dest, index) in getDestinos.filter(d => d.heroImgUrls && d.heroImgUrls.length > 0)
                .map(des => {
                  const order =
                    des.localizacion &&
                    des.localizacion.latitud &&
                    parseInt(des.localizacion.latitud)
                      ? parseInt(des.localizacion.latitud)
                      : 999;
                  return { ...des, order };
                })
                .sort((des1, des2) =>
                  des1.order > des2.order ? 1 : des1.order < des2.order ? -1 : 0
                )" :key="index">{{language === "en" && dest.ingles.titulo
                  ? dest.ingles.titulo
                  : dest.titulo}}</b-dropdown-item>
          </b-dropdown>         
        </li>
        <li class="mayus" style="color: black;">
          <router-link :to="{name: 'Nosotros'}">{{ MainMenuNosotros }}</router-link>
        </li>
        <li class="mayus">
          <a href="#Contactanos" style="color: black;">{{ MainMenuContacto }}</a>
        </li>
      </ul>
    </div>
  </b-container>
</div>
</template>

<script>
/* eslint-disable */
import {
  mapGetters,
  mapMutations,
  mapState
} from "vuex";

export default {
  data() {
    return {
      busqueda: null,
    };
  },
  // created(){
  //   this.__get('destSingleTitle')

  // },
  computed: {
    ...mapGetters(["siteDestinosByZona", "getSortedZonas", "getDestinos"]),
    ...mapGetters("MainMenu", ["MainMenuNosotros", "MainMenuContacto","MainMenuViaje"]),
    ...mapState(["language"]),
    ...mapGetters("i18n", ["__get"]),
  },
  methods: {
    sendDest(destId){
      this.$router.push(`/destinos/${destId}/experiencias`)
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    nameAsId({
      id
    }) {
      if (this.getSortedZonas && this.getSortedZonas.length > 0) {
        const zona = this.getSortedZonas.find((zona) => zona.id === id);
        if (zona.titulo.toLowerCase().includes("norte")) {
          return "norte";
        }
        if (zona.titulo.toLowerCase().includes("centro")) {
          return "centro";
        }
        if (zona.titulo.toLowerCase().includes("sur")) {
          return "sur";
        }
        if (zona.titulo.toLowerCase().includes("patagonia")) {
          return "patagonia";
        }
        return id;
      }
    },
    Nosotros() {
      this.$router.push("/Nosotros");
    },
    buscar() {
      if (this.busqueda && this.busqueda.length > 0) {
        this.$router.push({
          path: `/busqueda/${this.busqueda}`,
        });
      }
    },
    ...mapMutations([
      "buscadorFalse",
      "hamburguerToggle",
      "langToggle",
      "langFalse",
      "buscadorToggle",
      "destinationToggle"
    ]),
  },
};
</script>


<style scope>
.dropdown-menu{
  padding: initial !important;
}
</style>

<style lang="scss" scoped>

#MainMenu {
  background: #f6f6f6 0% 0% no-repeat padding-box;
  height: 80px;

  @media screen and (max-width: 524px) {
    height: 60px;
  }

  >.container {
    max-width: 1260px !important;
  }

  div {
    display: flex;
    align-items: center;
    height: 80px;
    justify-content: space-between;

    @media screen and (max-width: 524px) {
      display: inline-block;
    }

    #logo {
      img {
        @media screen and (max-width: 524px) {
          height: 100%;
          width: 146px;
        }
      }
    }

    @media screen and (max-width: 524px) {
      height: 60px;
    }

    .opciones {
      width: 75%;

      ul {
        li:first-child {
          border-top: groove 1px;
        }
      }

      ul li,
      li a {
        color: #000000;
        font-size: 15px;
        text-align: center;
        font-weight: bold;
      }

      .opcionesZonas {
        border-bottom: groove 1px;
        font-weight: lighter;
        color: #495057;
        padding: 5px 0px;
        width: 100%;
        display: flex;
        justify-content: center;

        &:hover {
          background: #ffaa01;
          color: white;
          font-weight: bold;

          div ul li {
            text-shadow: none;
          }
        }

        div {
          border-left: groove 1px;
        }

        div ul li {
          font-weight: lighter;
          width: 100%;
          display: flex;
          justify-content: center;
          border-bottom: groove 1px;
          padding: 5px 0px;

          &:hover {
            background: #ffaa01;
            color: white;
            font-weight: bold;
          }
        }
      }

      .buscador {
        width: 100%;

        input {
          height: 40px;
          border-radius: 0px 25px 25px 0px;
          width: 65%;
          margin-left: 35%;

          &:focus {
            position: relative;
            z-index: 1;
            box-shadow: none;
            border: 1px solid #ced4da;
          }
        }

        .lupa {
          background: #38a1ff 0% 0% no-repeat padding-box;
          margin-left: -110px;
          position: relative;
          z-index: 2;
          border-radius: 0px 25px 25px 0px;
          cursor: pointer;
        }

        .dropdownDestinos {
          position: absolute;
          z-index: 3;
          width: 35%;
          height: 40px;
          background: white;
          border-right: groove rgba(183, 183, 183, 0.3) 0.5px;
          border-radius: 25px 0px 0px 25px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          color: #495057;
          font-weight: lighter;

          h5 {
            margin: 0;
            font-size: 16px;
            color: #495057;
            font-weight: 400;
          }

          &:hover {
            border-radius: 25px 0px 0px 0px;
            background: #38a1ff;

            h5 {
              color: white;
              font-weight: 500;
              font-size: 16px;

              img {
                display: none;
              }
            }

            .dropdownDestinosList {
              display: block;

              ul {
                border-radius: 0px 0px 0px 25px;
                overflow: hidden;
                flex-direction: column;

                li:nth-child(1):hover {
                  .subNorte {
                    display: block;
                    height: auto;

                    ul {
                      flex-direction: column;
                      height: auto;
                    }
                  }
                }

                li:nth-child(2):hover {
                  .subCentro {
                    display: block;
                    height: auto;

                    ul {
                      flex-direction: column;
                      height: auto;
                    }
                  }
                }

                li:nth-child(3):hover {
                  .subLagos {
                    display: block;
                    height: auto;

                    ul {
                      flex-direction: column;
                      height: auto;
                    }
                  }
                }

                li:nth-child(4):hover {
                  .subPatagonia {
                    display: block;
                    height: auto;

                    ul {
                      flex-direction: column;
                      height: auto;
                    }
                  }
                }

                li div {
                  display: none;
                }

                li:hover {
                  color: rgb(54, 54, 54);
                }
              }
            }
          }

          .dropdownDestinosList {
            display: none;
            position: absolute;
            top: 40px;
            width: 100%;
            transition: all 300ms;

            ul {
              list-style: none;
              border-radius: 25px;
              background: white;

              li {
                background: white;
                color: gray;
                cursor: pointer;
                padding: 3px;
                font-size: 16px;

                &:hover {
                  color: white !important;
                }
              }

              .opcionesZonas {
                border-bottom: groove 1px;
                font-weight: lighter;
                color: #495057;
                padding: 5px 0px;
                width: 100%;
                display: flex;
                justify-content: center;

                &:hover {
                  background: #38a1ff;
                  color: white;
                  font-weight: 500;

                  div ul li {
                    text-shadow: none;
                  }
                }

                div {
                  border-left: groove 1px;
                }

                div ul li {
                  font-weight: lighter;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  border-bottom: groove 1px;
                  padding: 5px 0px;

                  &:hover {
                    background: #38a1ff;
                    color: white;
                    font-weight: 500;
                  }
                }

                .subMenu,
                .subNorte,
                .subCentro,
                .subLagos,
                .subPatagonia {
                  position: absolute;
                  top: 0;
                  left: 100%;
                  width: 125%;
                  border-radius: 0px 0px 25px 25px;
                  overflow: hidden;

                  li {
                    padding: 5px;
                  }
                }
              }
            }
          }
        }
      }
    }

    ul {
      margin: 0px 0px 0px auto;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      list-style: none;
      font-weight: bold;

      .buscadorli {
        width: 60%;
        border: none;
        border: none !important;
      }

      li {
        display: flex;
        align-items: center;
        cursor: pointer;
      }

      svg {
        cursor: pointer;
      }
    }
  }
}

.alupa {
  fill: #353535;
}

.lang {
  position: absolute;
  top: 17px;
  right: 44px;
  cursor: pointer;
}

.lupaNav {
  position: absolute;
  top: 12px;
  right: 8px;
  cursor: pointer;

  .mayus {
    text-transform: uppercase;
  }

  .container {
    padding: 0;
  }

  .svg-hamburguer {
    cursor: pointer;
  }
}

.a {
  fill: none;
}

.b {
  fill: #353535;
  font-size: 17px;
  font-family: Montserrat-Bold, Montserrat;
  font-weight: 700;
}

.c {
  clip-path: url(#a);
}

.d {
  clip-path: url(#b);
}

.e {
  fill: #fff;
}

.f {
  fill: #2530a9;
}

.g {
  fill: #c22623;
}
.destinosEnZona{
  &:first-child{
    padding: 30px 0;
  }
}
</style>
