<template>
  <b-container id="Evaluacion">
    <h1 class="pt-3">Evalúa tu experiencia</h1>

    <main class="formulario py-3 mb-4" v-if="reserva">
      <label class="pt-2" for="Experiencia">Experiencia: </label
      ><b-form-input
        id="Experiencia"
        class="mb-2"
        v-model="reserva.experiencia"
        placeholder="Experiencia"
        disabled
      ></b-form-input>
      <label class="pt-2" for="Nombre">Nombre: </label
      ><b-form-input
        id="Nombre"
        class="mb-2"
        v-model="reserva.nombre"
        placeholder="Nombre cliente"
        disabled
      ></b-form-input>
      <hr />
      <label for="Rating">De 1 a 5 ¿Qué nota le das a la experiencia? *</label>
      <b-form-rating
        id="Rating"
        class="mb-2"
        v-model="rating"
        variant="warning"
        show-value
        show-value-max
        size="lg"
        no-border
      />

      <label class="pt-2" for="TituloComentario">Titulo comentario: *</label
      ><b-form-input
        id="TituloComentario"
        class="mb-2"
        v-model="tituloComentario"
        placeholder="Título comentario"
        required
      ></b-form-input>
      <label class="pt-2" for="Comentario">Escribe tu opinión. Cuéntale a l@s demás acerca de tu experiencia: *</label
      ><b-form-textarea
        id="Comentario"
        class="mb-2"
        v-model="comentario"
        placeholder="Comentario"
        required
      ></b-form-textarea>
      <hr />
      <label class="pt-2" for="Sugerencias">Sugerencias de mejora:</label
      ><b-form-textarea
        id="Sugerencias"
        class="mb-2"
        v-model="sugerencias"
        placeholder="Sugerencias de mejora"
      ></b-form-textarea>

      <b-button
        size="lg"
        block
        class="btnEvaluacion"
        @click="sendEvaluation"
        :disabled="disabledBtn"
        >Enviar evaluación</b-button
      >
    </main>
    <main v-else-if="loading">
      <h2 class="text-center">Cargando formulario...</h2>
    </main>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import moment from "moment";
import emailjs from "emailjs-com";
import firebase from "firebase";

const db = firebase.firestore();

export default {
  name: "Evaluacion",
  created() {},
  data() {
    return {
      //Fomulario
      rating: 0,
      tituloComentario: "",
      comentario: "",
      sugerencias: "",
      //Meta
      reserva: null,
      reservaInmediata: true,
      experiencia: null,
      //Loading o v-if
      disabledBtn: false,
      loading: false,
    };
  },
  computed: {
    ...mapState(["experiencias"]),
    ...mapState("reservaciones", ["reservacionesPorConfirmar"]),
  },
  methods: {
    ...mapActions("Evaluaciones", ["crearEvaluacion"]),
    async getReservaData() {
      this.loading = true;
      const id = this.$route.params.id;
      //Get reserva
      try {
        const query = await db
          .collection("reservas")
          .where("idUnico", "==", id)
          .get();
        let reserva = [];

        query.forEach((doc) => {
          reserva.push({ data: doc.data(), id: doc.id });
        });

        if (reserva.length === 0) {
          const query = await db
            .collection("reservasPorConfirmar")
            .where("idUnico", "==", id)
            .get();

          query.forEach((doc) => {
            reserva.push({ data: doc.data(), id: doc.id });
          });

          this.reservaInmediata = false;
        }

        reserva = reserva[0];
        reserva.data.id = reserva.id;

        this.reserva = reserva.data;
      } catch (error) {
        console.log(error);
      }

      if (!this.reserva) {
        this.$bvToast.toast(`No hay nada que evaluar`, {
          title: `Error`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });

        setTimeout(() => {
          this.$router.push("/");
        }, 5000);
      }

      if (this.reserva.evaluada) {
        this.disabledBtn = true;
        this.$bvToast.toast(`Esta reserva ya fue evaluada.`, {
          title: `Evaluación ya fue realizada.`,
          variant: "info",
          solid: true,
          toaster: "b-toaster-top-center",
        });

        setTimeout(() => {
          this.$router.push("/");
        }, 5000);
      }

      if (this.reserva) this.loading = false;
    },
    getExperienciaId() {
      if (this.reserva.experienciaId) {
        return this.reserva.experienciaId;
      } else {
        const exp = this.experiencias.find((exp) =>
          exp.titulo.includes(this.reserva.experiencia)
        );
        return exp ? exp.id : "Id no encontrado";
      }
    },
    async sendEvaluation() {
      this.disabledBtn = true;

      const evaluacion = {
        experiencia: this.reserva.experiencia,
        nombre: this.reserva.nombre,
        rating: +this.rating,
        tituloComentario: this.tituloComentario.trim(),
        comentario: this.comentario.trim(),
        sugerencias: this.sugerencias.trim(),
        fecha: moment().format(),
        reservaID: this.reserva.id,
        experienciaID: this.getExperienciaId(),
        estado: "Pendiente",
      };

      //Validacion
      if (
        evaluacion.rating === 0 ||
        evaluacion.tituloComentario === "" ||
        evaluacion.comentario === ""
      ) {
        this.$bvToast.toast(
          `Debes valorar de 1 a 5, poner un titulo a tu comentario y darnos tu opinión`,
          {
            title: `Error: `,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          }
        );

        this.disabledBtn = false;
        return;
      }

      this.reservaInmediata ? (evaluacion.reservaInmediata = true) : false;

      //Submit Evaluacion
      const crearEvaluacion = await this.crearEvaluacion(evaluacion);
      if (crearEvaluacion.success) {
        this.$bvToast.toast("Muchas gracias por tu valiosa opinión.", {
          title: `Evaluación registrada de manera exitosa`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-top-center",
        });

        setTimeout(() => {
          this.$router.push("/");
        }, 5000);
      } else {
        this.$bvToast.toast(`${crearEvaluacion.error}`, {
          title: `Hubo un error: `,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });

        this.disabledBtn = false;
      }
    },
  },
  async created() {
    await this.getReservaData();
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
#Evaluacion {
  main {
    min-height: 70vh;
  }
  .btnEvaluacion {
    background-color: #3d99ff;
    border: 0;
  }
}
</style>