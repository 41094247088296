<template>
  <div id="app">
    <Hero-Section />
    <ExperienciasImperdibles :experiencias="experienciasImperdibles" />
    <PorqueViajar />
    <!-- <Mapa /> -->
  </div>
</template>

<script>
import HeroSection from "@/components/Hero-Section";
import ExperienciasImperdibles from "@/components/ExperienciasImperdibles";
import PorqueViajar from "@/components/PorqueViajar";
import Mapa from "@/components/Mapa";
import { mapGetters } from "vuex";

export default {
  name: "Home",
  components: {
    HeroSection,
    ExperienciasImperdibles,
    PorqueViajar,
    Mapa,
  },
  computed: {
    ...mapGetters(["experienciasImperdibles"]),
  },
  metaInfo: {
    title: "Descubre el sur de Chile",
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,500i,700&display=swap");

body {
  font-family: "Montserrat", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
</style>
