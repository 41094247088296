<template>
  <div id="Hero-Section">
    <div class="capaOscura">
      <div class="contain" style="margin-top: 70px">
        <!-- style="margin-top: -100px" -->
        <div class="topText">
          <p
            v-if="isMobile()"
            style="font-size: 28px; line-height: 30px; font-weight: 400;"
            v-html="getHomeTitle"
          ></p>
          <div v-else style="padding: 6em;">
            <p
              style="padding: 8em; font-size: 45px; line-height: 30px; font-weight: 400; letter-spacing: 8px; line-height: initial;"
              v-html="getHomeTitle"
            ></p>
          </div>

          <!-- <p class="text-white m-auto pb-3" style="font-weight: 500">
            {{ HeroSectionSubTitulo }}
          </p> -->
        </div>
        <!-- <Buscador /> -->
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#Hero-Section {
  margin: 0;
  background-image: url("../assets/img/logo_hero_new.jpg");
  background-position: center;
  // background-size: cover;
  height: 40vh;
  transition: background 0.5s ease-in-out;
  // background-attachment: fixed;
  // background-size: 120% 50vh;
  @media (min-width: 300px) {
    background-size: 120% 50vh;
  }
  @media (min-width: 525px) {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 600px;
    background-repeat: no-repeat;
    background-position: 50% 30%;
  }
  @media (min-width: 960px) {
    background-size: cover;
    background-attachment: fixed;
    background-attachment: initial;
    background-image: url("../assets/img/logo_hero_new.jpg");
    background-position: center 50%;
  }

  .capaOscura {
    width: 100vw;
    height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.3);

    @media screen and (max-width: 524px) {
      height: 100%;
    }

    .contain {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: center;
      margin-top: -55px;

      @media screen and (max-width: 524px) {
        padding: 0 37px;
        margin-top: 0;
      }

      .topText {
        color: white;
        margin-top: -100px;

        @media screen and (max-width: 524px) {
          padding: 0 20px;
        }

        h1 {
          font-size: 60px;
          font-weight: bold;

          @media screen and (max-width: 524px) {
            font-size: 35px;
            line-height: 43px;
          }
        }

        p {
          font-size: 22px;
          font-weight: lighter;
          font-weight: 500;

          @media screen and (max-width: 524px) {
            font-size: 14px;
            font-weight: medium;
            line-height: 25px;
          }

          @media screen and (min-width: 524px) {
            // width: 70%;
            text-align: center;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
</style>

<script>
/* eslint-disable */
import Buscador from "@/components/Buscador.vue";
import { mapGetters, mapState } from "vuex";

export default {
  name: "Hero-Section",
  mounted() {
    document.getElementsByClassName("buscadorli")[0].style.zIndex = "-1";
  },
  beforeDestroy() {
    document.getElementsByClassName("buscadorli")[0].style.zIndex = "1";
  },
  components: {
    Buscador
  },
  computed: {
    ...mapGetters("HeroSection", ["HeroSectionTitulo", "HeroSectionSubTitulo"]),
    ...mapGetters("i18n", ["getHomeTitle"])
    // ...mapGetters("getHomeTitle"),
  },
  methods: {
    // getHomeTitle() {
    //   this.$store.dispatch("i18n/getHomeTitle");
    // },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>
