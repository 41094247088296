<template>
  <div class="container-fluid">
    <div class="mt-3 mb1 px-4">
      <b-button :to="{ name: 'AdminHome' }" variant="success">
        Volver a home de admin
      </b-button>
    </div>
    <b-row class="mt-5 mb-1 px-4">
      <b-col class="text-center text-white" cols="12" md="9">
        <h1>Viajes Personalizados</h1>
      </b-col>
      <b-col cols="12" md="3">
        <b-button :to="{ name: 'FormPersonalizedTrip' }" variant="success">
          Agregar viaje
        </b-button>
      </b-col>
    </b-row>
    <b-row class="p-4">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="my-2">
            <b-form-group label="Filtrar por palabra" label-cols-sm="6" label-align-sm="right" label-size="md"
              label-for="filterInput" class="mb-1 text-white">
              <b-input-group size="md">
                <b-form-input v-model="filter" type="search" id="filterInput" class="w-100"
                  placeholder="Ingresa un término..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table show-empty :fields="reservasTitulos" :items="data" :current-page="currentPage" :per-page="perPage"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
          empty-text="Aun no existen viajes." empty-filtered-text="Sin registros que coincidan con la búsqueda..."
          head-variant="dark" table-variant="light" striped hover @filtered="onFiltered">
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
          <template v-slot:cell(acciones)="data">
            <div class="container-fluid">


              <b-row>
                <b-col cols="12" class="
                  text-center
                  d-flex
                  align-items-center
                  justify-content-around
                  pb-3
                ">
                 <router-link :to="`/admin/lista-viajes/editar/${data.item.id}`">
                    <b-icon class="iconHover" icon="pencil"
                      variant="info" font-scale="1.5" />
                  </router-link>
                    <b-icon @click="handleDeleteTrip(data.item.id)" class="iconHover" icon="trash"
                      variant="danger" font-scale="1.5" />
                </b-col>
              </b-row>
            </div>
          </template>
        </b-table>
        <b-row>
          <b-col cols="12" class="my-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" per-page="5" align="fill" size="md"
              class="my-0"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "ListViajes",
  metaInfo: {
    title: "Lista de Viajes",
  },
  data() {
    return {
      data : [],
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 7,
      sortBy: "fechaActual",
      sortDesc: true,
      sortDirection: "desc",
      reservasTitulos: [
        { key: "index", label: "N°" },
        {
          key: "id",
          label: "ID",
          sortable: true,
          class: "text-center"
        },
        {
          key: "title",
          label: "Titulo",
          sortable: true,
          class: "text-center"
        },
        {
          key: "description_basic",
          label: "Resumen",
          sortable: true,
          class: "text-center"
        },
        // Columna Acciones
        { key: "acciones", label: "Acción", class: "text-center" }
      ]
    };
  },
  created() {
    this.getTrips();
  },
  mounted() {
    // Set the initial number of items
    this.loadTrips();
    this.totalRows = this.trips.length;
  },
  computed: {
    ...mapState("ForeignTrip", [
      "trips"
    ])
  },
  methods: {
    ...mapActions("ForeignTrip", [
      "getTrips",
      "deleteTrip"
    ]),
    handleDeleteTrip(id) {
      const confirmarEliminacion = confirm("¿Seguro que desea eliminar el viaje personalizado?");
      if (!confirmarEliminacion) return;
      const reConfirmarEliminacion = confirm(
        "Esta opción es irreversible, ¿Estás seguro que deseas eliminar este registro?"
      );
      if (!reConfirmarEliminacion) return;

      this.deleteTrip(id);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    loadTrips () {
      this.data = [];
      const trips = this.trips;
      const lang = this.$store.state.language;

      trips.forEach(elem => {
        const trip = elem[lang];
        this.data.push({
          id: elem.id,
          title: trip.title,
          description_basic: trip.description_basic,
        });
      });
    }
  },
  watch: {
    '$store.state.language': function() {
      this.loadTrips();
    },
    "trips": function() {
      this.loadTrips();
    }
  }
};
</script>

<style></style>
