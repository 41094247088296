<template>
  <div class="edit-zona">
    <div class="header">
      <div class="container container--flex-nav">
        <button type="button" class="edit-zona__btn edit-zona__btn--nav" @click="navigateToHome"><i
            class="fas fa-arrow-alt-circle-left"></i>Pagina principal</button>
        <div class="buttons">
          <!-- Switch para ingles/castellano -->
          <button v-if="language === 'es'" type="button" class="edit-zona__btn edit-zona__btn--primary"
            style="margin-right: 50px;" @click="switchLanguage">Agregar contenido en ingles</button>
          <button v-if="language === 'en'" type="button" class="edit-zona__btn edit-zona__btn--success"
            style="margin-right: 50px;" @click="switchLanguage">Volver al contenido en español</button>
          <!--  -->
          <div class="edit-zona__loading-button">
            <button v-if="isViajeUpdate" type="button" class="edit-zona__btn edit-zona__btn--warning"
              @click="deleteRequest({ id })" :disabled="isLoading">Eliminar Viaje</button>
            <div v-if="isLoading" class="lds-dual-ring"></div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="heading heading--1 heading--center">Gestion de viaje {{ (language === 'en') ? '(Ingles)' : '' }}</h1>
    <h3 v-if="viaje.titulo" class="heading heading--3 heading--center">{{ viaje.titulo }}</h3>
    <form v-if="viaje" @submit.enter.prevent="(isViajeUpdate) ? submitViaje('update') : submitViaje('create')"
      class="edit-zona__form-container">
      <div class="edit-zona__informacion edit-zona__informacion--1">
        <h3 class="heading heading--3 heading--center">Informacion principal</h3>
        <div class="edit-zona__container edit-zona__container--flex">
          <div class="edit-zona__side edit-zona__side--left">
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Titulo</label>
              <input class="edit-zona__input" type="text" name="titulo" id="titulo"
                :value="(language === 'es') ? viaje.titulo : viaje.ingles.titulo" @input="handleChange">
            </div>

            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Resumen</label>
              <textarea class="edit-zona__textarea" name="resumen" id="resumen" cols="30" rows="10"
                :value="(language === 'es') ? viaje.resumen : viaje.ingles.resumen" @input="handleChange"></textarea>
            </div>
          </div>
          <div v-if="language === 'es'" class="edit-zona__side edit-zona__side--right">
            <!-- Informacion principal | Imagenes para el slideshow -->
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Imagen de previsualizacion</label>
              <input type="file" name="carousel-img" accept="image/*" @change="addImg($event, 'carousel')">
              <a href="" v-if="(viaje.carouselImgUrl || viajeImgsToSave.carouselImgUrl)" @click.prevent="(viaje.carouselImgUrl) ?
                deleteImg({
                  type: 'carousel',
                  level: 'trip',
                  url: viaje.carouselImgUrl,
                  id: id
                })
                :
                deleteUnsavedImg({ type: 'carousel' })" class="edit-zona__img-link">
                <img :src="(viaje.carouselImgUrl) ? viaje.carouselImgUrl : viajeImgsToSave.carouselImgUrl"
                  alt="heading-img" class="edit-zona__img">
              </a>
            </div>
          </div>
        </div>
      </div>

      <div
        class="edit-zona__informacion edit-zona__informacion--2"
      >
        <h3 class="heading heading--3 heading--center">Destacados</h3>

        <div class="edit-zona__container">
          <div class="edit-zona__destacado">
            <!-- Gestion de destacados, aqui se muestran los que ya estan en base de datos -->
            <div
              class="edit-zona__input-group"
              v-for="(dia, index) in language === 'es'
                ?viaje.destacado
                :viaje.ingles.destacado"
              :key="index"
            >
              <label class="edit-zona__label"
                >Destacado Nro {{ index + 1 }}</label
              >
              <textarea
                class="edit-zona__textarea"
                name="destacado"
                id="destacado"
                cols="30"
                rows="3"
                :value="dia"
                disabled
              ></textarea>

              <div class="edit-zona__buttons edit-zona__buttons--right">
                <button
                  class="edit-zona__btn edit-zona__btn--warning"
                  type="button"
                  @click="
                    borrarItem({
                      borrador: false,
                      name: 'destacado',
                      index,
                    })
                  "
                >
                  Eliminar
                </button>
              </div>
            </div>
            <!-- Gestion de destacados, aqui se muestran los que estan agregados en borrador -->
            <div
              v-if="
                (language === 'es' &&
                  experienciaBorrador.destacado.length > 0) ||
                (language === 'en' &&
                 viaje.ingles.experienciaBorrador.destacado.length > 0)
              "
            >
              <h4 class="heading heading--4 heading--center">
                Destacados por agregar
              </h4>

              <div
                class="edit-zona__input-group"
                v-for="(destacado, index) in language === 'es'
                  ? experienciaBorrador.destacado
                  :viaje.ingles.experienciaBorrador.destacado"
                :key="index"
              >
                <label class="edit-zona__label"
                  >Destacado Nro {{ index + 1 }}</label
                >
                <textarea
                  class="edit-zona__textarea"
                  name="destacado"
                  id="destacado"
                  cols="30"
                  rows="3"
                  :value="destacado"
                  disabled
                ></textarea>

                <div class="edit-zona__buttons edit-zona__buttons--right">
                  <button
                    class="edit-zona__btn edit-zona__btn--warning"
                    type="button"
                    @click="
                      borrarItem({
                        borrador: true,
                        name: 'destacado',
                        index,
                      })
                    "
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
            <!-- Gestion de destacados, Boton e input para agregar un nuevo destacado a borrador -->
            <div v-if="addDestacado" class="edit-zona__input-group">
              <label class="edit-zona__label">Nuevo destacado</label>
              <textarea
                class="edit-zona__textarea"
                name="destacado"
                id="destacado"
                cols="30"
                rows="3"
                v-model="destacado"
              ></textarea>
            </div>

            <button
              v-if="!addDestacado"
              type="button"
              name="destacado"
              @click="addItem"
              class="edit-zona__btn edit-zona__btn--primary"
            >
              Agregar nuevo destacado
            </button>

            <button
              v-else
              type="button"
              name="destacado"
              @click="addItem"
              class="edit-zona__btn edit-zona__btn--success"
            >
              Agregar
            </button>
          </div>
          <!-- Fin de destacado -->
        </div>
      </div>



      <div class="edit-zona__informacion edit-zona__informacion--2">
        <h3 class="heading heading--3 heading--center">Itinerarios</h3>
        <div class="edit-zona__container">

          <div class="edit-zona__gastronomia-content">
             <!-- Gestion de imagenes en cultura y agronomia | Imagenes ya agregadas en base de datos -->
             <div class="edit-zona__contenido-cultura" v-if="viaje.itinerariosDias && viaje.itinerariosDias.length > 0">
              <h5 class="heading heading--3">Contenido ya agregado</h5>
              <div class="edit-zona__gestion-cultura edit-zona__gestion-cultura--agregar">

                <div
                  v-for="(contenido, index) in viaje.itinerariosDias"
                  @click="deleteCulturaContent({
                    type: 'guardado',
                    index
                  })"
                  :key="index"
                  class="edit-zona__agregar-contenido">

                  <a href="" @click.prevent="" v-if="contenido.imgUrl" class="edit-zona__img-container edit-zona__img-container--cultura">
                    <img class="edit-zona__img edit-zona__img--cultura" :src="contenido.imgUrl" alt="contenido-cultura">
                  </a>

                  <div class="edit-zona__input-group">
                    <label class="edit-zona__label">Titulo</label>
                    <input class="edit-zona__input" :value="(language === 'es') ? contenido.titulo : contenido.tituloIngles" type="text" disabled>
                  </div>

                </div>
              </div>
            </div>
            <!-- Gestion de imagenes en cultura y agronomia | Imagenes ya agregadas en base de datos -->
            <div class="edit-zona__contenido-cultura" v-if="viaje.itinerarioDiaSaved && viaje.itinerarioDiaSaved.length > 0">
              <h5 class="heading heading--3">Contenido ya agregado</h5>
              <div class="edit-zona__gestion-cultura edit-zona__gestion-cultura--agregar">
                <div v-for="(contenido, index) in viaje.itinerarioDiaSaved"
                  @click="deleteCulturaContent({ type: 'guardado', index })" :key="index"
                  class="edit-zona__agregar-contenido">
                  <a href="" @click.prevent="" v-if="contenido.imgUrl"
                    class="edit-zona__img-container edit-zona__img-container--cultura">
                    <img class="edit-zona__img edit-zona__img--cultura" :src="contenido.imgUrl" alt="contenido-cultura">
                  </a>
                  <div class="edit-zona__input-group">
                    <label class="edit-zona__label">Titulo</label>
                    <input class="edit-zona__input"
                      :value="(language === 'es') ? contenido.titulo : contenido.tituloIngles" type="text" disabled>
                  </div>
                </div>
              </div>
            </div>

            <!-- Gestion de imagenes en cultura y gastronomia | Formulario para agregar -->
            <div v-if="openEdit" class="edit-zona__container edit-zona__container--flex">
              <div class="edit-zona__side edit-zona__side--left">
                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Titulo de imagen</label>
                  <input class="edit-zona__input" type="text" v-model="contenidoTitulo">
                </div>

                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Noche</label>
                  <input class="edit-zona__input" type="text" v-model="contenidoNoche">
                </div>
                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Descripcion</label>
                  <textarea class="edit-zona__textarea" name="descripcion" id="descripcion" cols="50"
                    v-model="contenidoDescripcion" rows="5" @input="handleChange"></textarea>
                </div>
                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Imagen</label>
                  <input type="file" accept="image/*" @change="addContenidoImg">
                </div>
              </div>
              <div class="edit-zona__side edit-zona__side--right">
                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Titulo de imagen (Ingles)</label>
                  <input class="edit-zona__input" type="text" v-model="contenidoTituloIngles">
                </div>

                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Overnight</label>
                  <input class="edit-zona__input" type="text" v-model="contenidoOvernigth">
                </div>
                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Description</label>
                  <textarea class="edit-zona__textarea" name="description" id="description" cols="50"
                    v-model="contenidoDescription" rows="5" @input="handleChange"></textarea>
                </div>
              </div>
              <a href="" v-if="contenidoImgUrl" @click.prevent="deleteContenidoImg">
                <img class="edit-zona__img edit-zona__img--cultura" :src="contenidoImgUrl"
                  alt="agregar-cultura-contenido">
              </a>
            </div>

            <!-- Gestion de imagenes en cultura y gastronomia | boton para activar el formulario -->
            <button class="edit-zona__btn edit-zona__btn--primary" type="button" @click="openContenido"
              v-if="language === 'es' && !openEdit">Agregar nuevo día</button>
            <button class="edit-zona__btn edit-zona__btn--success" type="button" @click="openContenido"
              v-if="language === 'es' && openEdit">Agregar</button>
          </div>
        </div>
      </div>
      <div class="edit-zona__buttons">
        <div class="edit-zona__loading-button">
          <button class="edit-zona__btn edit-zona__btn--primary" :disabled="(isLoading)">Guardar</button>
          <div v-if="isLoading" class="lds-dual-ring"></div>
        </div>
        <button @click="cancelarViaje" class="edit-zona__btn edit-zona__btn--secondary" style="margin: 50px;" type="button"
          :disabled="(isLoading)">Cancelar</button>
      </div>

      
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { storage } from '../../firebase.service'

export default {
  metaInfo: {
    title: "Editar viaje",
  },
  data() {
    return {
      id: null,
      isViajeUpdate: false,
      contenidoTitulo: null,
      contenidoTituloIngles: null,
      contenidoImg: null,
      contenidoDescripcion: null,
      contenidoImgUrl: null,
      openEdit: false,
      addDestacado: false,
      destacado: null,
      contenidoNoche: null,
      contenidoOvernigth: null,
      contenidoDescription: null,
    }
  },
  created() {
    this.resetLanguage()
    this.resetItinerarioDiaBorrador()
    this.id = this.$route.params.id
    if (this.id) {
      this.setViajeId(this.$route.params.id)
      this.isViajeUpdate = true
    }
  },
  beforeDestroy() {
    this.resetViaje({ level: 'trip' })
  },
  computed: {
    ...mapGetters([
      'language',
      'isLoading'
    ]),
    ...mapGetters("trip",[
      'viaje',
      'viajeImgsToSave',
      "experienciaBorrador",
      'getDestinosByViaje',
      'itinerariosDias',
    ]),
    getHeroContent() {
      if (this.viaje && this.viaje.heroImgUrls) {
        if (this.viaje.heroImgUrls.length > 0) {
          const heroContentRef = this.viaje.heroImgUrls.map((url) => {
            const { name } = storage.refFromURL(url)
            const nameParts = name.split('.')
            return {
              type: nameParts[1],
              url
            }
          })
          return heroContentRef
        }
      }
      return null
    },
    getHeroContentBorrador() {
      if (this.viajeImgsToSave.heroImgUrls && this.viajeImgsToSave.heroImgUrls.length > 0) {
        const heroContentRef = this.viajeImgsToSave.heroImgUrls.map((url) => {
          const extension = url.split(';')[0].split(':')[1].split('/')[0]
          return {
            type: extension,
            url
          }
        })
        return heroContentRef
      }
      return null
    }
  },
  methods: {
    ...mapActions([
      'deleteImg',
      'updateValue',
      'switchLanguage'
    ]),
    ...mapActions("trip",[
      'setViajeId',
      'addViajeImg',
      'updateViaje',
      'deleteImg',
      'resetViaje',
      'deleteFromItinerarioSaved',
      'deleteViaje',
      "borrarItem",
      "submitItem"
    ]),
    ...mapMutations([
      'resetLanguage',
      'switchLoading',
      'switchDelete',
    ]),
    ...mapMutations("trip",[
      'addToItinerariosDias',
      'resetItinerarioDiaBorrador',
    ]),
    async submitViaje(value) {
      try {
        this.switchLoading(true)
        await this.updateViaje(value)
        this.switchLoading(false)
      } catch (error) {
        this.switchLoading(false)
        console.log(error)
      }
    },
    async deleteRequest(value) {
      try {
        this.switchLoading(true)
        this.switchDelete(true)
        await this.deleteViaje(value)
        this.switchDelete(false)
        this.switchLoading(false)
      } catch (error) {
        this.switchDelete(false)
        this.switchLoading(false)
        console.log(error)
      }
    },
    cancelarViaje() {
      this.$router.push({ path: '/admin' })
    },
    navigateToHome() {
      this.$router.push({ path: '/admin' })
    },
    openContenido() {
      const isFilled = (this.contenidoImgUrl && this.contenidoImg && this.contenidoTitulo && this.contenidoTitulo.length > 0 && this.contenidoTituloIngles && this.contenidoTituloIngles.length > 0)
      if (isFilled) {
        const data = {
          titulo: this.contenidoTitulo,
          tituloIngles: this.contenidoTituloIngles,
          descripcion: this.contenidoDescripcion,
          noche: this.contenidoNoche,
          overnigth: this.contenidoOvernigth,
          description: this.contenidoDescription,
          imgUrl: this.contenidoImgUrl,
          img: this.contenidoImg
        }
        this.addToItinerariosDias(data)
        this.contenidoTitulo = null
        this.contenidoTituloIngles = null
        this.contenidoImg = null
        this.contenidoImgUrl = null
        this.contenidoDescripcion = '';
        this.contenidoNoche= null;
        this.contenidoOvernigth= null;
        this.contenidoDescription= null;
        this.openEdit = false
        return
      }

      if (!(this.itinerariosDias.length > 0) || !this.openEdit) {
        this.openEdit = true
      }
    },
    deleteCulturaContent(request) {
      if (request.type === 'borrador') {
        this.deleteFromCulturaBorrador({ level: 'trip', index: request.index })
      } else if (request.type === 'guardado') {
        this.deleteFromItinerarioSaved({ level: 'trip', index: request.index })
      }
    },
    deleteContenidoImg() {
      this.contenidoImg = null
      this.contenidoImgUrl = null
    },
    addContenidoImg(event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.contenidoImgUrl = fileReader.result
        this.contenidoImg = files[0]
        event.target.value = ''
      })
      fileReader.readAsDataURL(files[0])
    },
    addImg(event, type) {
      if (type === 'carousel' && this.viaje.carouselImgUrl) {
        alert('Debe eliminar la imagen ya registrada antes de registrar una nueva')
        return
      }
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        const imgUrl = fileReader.result
        const imgFile = files[0]
        this.addViajeImg({ imgUrl, imgFile, type })
        event.target.value = ''
      })
      fileReader.readAsDataURL(files[0])
    },
    addItem(event) {
      const { name } = event.target;
      switch (name) {
        case "destacado":
          if (name === "destacado" && this.addDestacado) {
            if (this.destacado && this.destacado.length > 0) {
              this.submitItem({
                name,
                value: this.destacado,
              });
              this.destacado = null;
              this.addDestacado = false;
            }
            return;
          }
          this.addDestacado = true;
          break;
       
        default:
          break;
      }
    },
    handleChange(event, type = 'normal') {
      const { name, value } = event.target
      const level = 'trip'
      this.updateValue({ name, value, level, type })
    }
  }
}
</script>

<style lang="scss">
.container {
  &--flex-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.paragraph {
  color: inherit;
}

.fa-plus-circle {
  font-size: 1.6rem;
  margin-right: 10px;
}

.fa-arrow-alt-circle-left {
  font-size: 1.5rem;
  margin-right: 10px;
}

.header {
  background: #333;
  margin-bottom: 50px;
}

.edit-zona {
  color: white;

  &__input-text {
    cursor: inherit;
  }

  &__container {
    &--fluid {
      width: 100%;
    }
  }

  &__list {
    list-style-type: none;

    &--1 {
      padding-top: 20px;
    }
  }

  &__buttons {
    width: 100%;
    text-align: center;

    &--right {
      text-align: right;
    }
  }

  &__youtube-iframe {
    display: block;
    margin: 10px auto;
    width: 100%;
    height: 200px;
    border-radius: 5px;
    box-shadow: 1px 1px 1px;
  }

  &__loading-button {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    button {
      margin: 0;
    }
  }

  &__list-content {
    margin: 15px 0;
    background: white;
    color: black;
    font-weight: 500;
    border-top: 1px solid white;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;

    &:last-child {
      border-bottom: 1px solid white;
    }
  }

  &__list-item {
    &--block {
      display: block;
      width: 100%;
    }
  }

  &__btn {
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px;
    font-weight: 500;
    box-shadow: 1px 1px 3px black;
    border: none;

    &--warning {
      background: rgb(206, 64, 64);
      color: white;
    }

    &--success {
      color: white;
      background: rgb(12, 128, 12);
    }

    &--primary {
      color: white;
      background: rgb(55, 127, 221);
    }

    &--consejos {
      margin: 0 20px 0 0;
    }

    &--link {
      margin: 0;
      border: none;
      font-size: 1.3rem;
      font-weight: 500;
      padding: 10px 25px;
      transition: transform .25s ease;

      &:hover {
        transform: scale(1.03);
      }
    }

    &--nav,
    &--nav:active,
    &--nav:focus {
      background: none;
      border: none;
      box-shadow: none;
      color: white;
      font-size: 1.5rem;
    }
  }

  &__gastronomia-content {
    padding: 30px 0px;
  }

  &__textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;
    width: 100%;
  }

  &__input {
    padding: 15px;
    border: none;
    box-shadow: 1px 1px 5px;
    width: 100%
  }

  &__gestion-cultura {
    &--agregar {
      display: grid;
      grid-template-columns: 23% 23% 23% 23%;
      grid-gap: 20px;
    }
  }

  &__agregar-contenido {
    cursor: pointer;
  }

  &__img--cultura {
    margin: auto;
  }

  &__container {
    width: 90%;
    margin: auto;
  }

  &__form-container {
    padding-top: 35px;
  }

  &__label {
    margin: 0 0 10px 0;
    font-size: 1.3rem;
    cursor: inherit;
  }

  &__container--flex {
    display: flex;
    justify-content: space-between;
  }

  &__side {
    width: 45%
  }

  &__informacion {
    padding: 50px 0;
  }

  &__img {
    height: 150px;
    width: 180px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;

    &--1 {
      background: rgb(26, 26, 26);
    }
  }

  &__img-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__img-container--cultura {
    justify-content: center;
    cursor: inherit;
  }

  &__destino {
    color: #333;
    box-shadow: 1px 1px 5px black;
    margin: 20px;
    border-radius: 5px;
    background: white;
    border: 1px solid black;
    display: inline-block;
    border: none;
  }
}

.edit-zona__input-group {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  align-items: flex-start;
}

.heading {
  &--center {
    text-align: center;
  }
}
</style>
