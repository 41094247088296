/* eslint-disable */
export default {
  namespaced: true,

  state: {
    ExpImperdiblesESP: {
      exp: "Experiencias",
      imp: "Imperdibles"
    },
    ExpImperdiblesENG: {
      exp: "Experiences!",
      imp: "Unmissable"
    }
  },
  getters: {
    ExpImperdiblesExp(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ExpImperdiblesESP.exp;
      } else if (rootState.language == "en") {
        return state.ExpImperdiblesENG.imp;
      }
    },
    ExpImperdiblesImp(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ExpImperdiblesESP.imp;
      } else if (rootState.language == "en") {
        return state.ExpImperdiblesENG.exp;
      }
    }
  },
  mutations: {},
  actions: {}
};
