<template>
  <b-container class="py-4">
    <b-button :to="{ name: 'AdminHome' }" variant="success">
      Volver a home de admin
    </b-button>
    <h1 class="my-5 text-white">Próximas reservas</h1>
    <ReservasCalendario />
  </b-container>
</template>

<script>
import ReservasCalendario from "@/components/ReservasCalendario";
export default {
  components: {
    ReservasCalendario,
  },
};
</script>

<style>
</style>