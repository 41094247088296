import router from "../../router/index";

import firebase from "firebase";
import "firebase/storage";
import "firebase/firestore";
import moment from 'moment'

import { db, storage } from "../../firebase.service";
import storageHandler from "../utilities/storageHandler";
import storageURLs from "../utilities/downloadURLs";

const experiencias = db.collection("experiencias");
const destinosCollection = db.collection("destinos");

const getDefaultState = () => ({
  // Url para iframe en google maps
  googleMapsUrl: null,
  // Contenido de la experiencia en ingles
  ingles: {
    titulo: null,
    seo_titulo: null,
    tituloDestino: null,
    subtitulo: null,
    resumen: null,
    destacado: [],
    descripcion: {
      resumen: null,
      dias: [],
    },
    informacionImportante: {
      incluye: [],
      noIncluye: [],
      debesTraer: [],
      resumen: null,
    },
    // Borrador para nuevas entradas (en ingles)
    experienciaBorrador: {
      destacado: [],
      dias: [],
      informacionImportante: {
        incluye: [],
        noIncluye: [],
        debesTraer: [],
      },
      tags: [],
    },
    mensajePrecioMasBajo: "",

    tags: [],
  },
  // Comienzo del contenido en el idioma espanol
  id: null,
  titulo: null,
  slug: null,
  seo_titulo: null,
  tituloDestino: null,
  subtitulo: null,
  reserva_inmediata: false,
  carouselImgUrl: null,
  heroImgUrls: [],
  youtubeMedia: [],
  resumen: null,
  destacado: [],
  cantidadDias: 1,
  diasAnticipacion: 0,
  horaInicio: null,
  horaFin: null,
  rangosHoras: [],
  modalidadHoras: "unica",
  modalidadPrecio: null,
  preciosConfig: [],
  modalidadViaje: null,
  descripcion: {
    resumen: null,
    dias: []
  },
  informacionImportante: {
    incluye: [],
    noIncluye: [],
    debesTraer: [],
    resumen: null
  },
  idiomas: [],
  stock: 0,
  disponibilidad: [],
  disponibilidades: [],
  fechaExclusiva: null,
  modalidad: null,
  visitas: 0,
  puntaje: 0.0,
  oferta: false,
  precio: 0.0,
  precioOferta: 0.0,
  precioFijoTemporadas: [],
  precioDiferenciadoNiños: false,
  precioNiños: [],
  capacidad: 1,
  capacidadMinima: 1,
  permiteAbono: false,
  porcentajeAbono: 0,
  temporadas: {
    temporadas: [],
    fechas: [],
  },
  precioMasBajo: "",
  mensajePrecioMasBajo: "",
  tags: [],
  // Borrador para nuevas entradas
  experienciaBorrador: {
    youtubeMedia: [],
    preciosConfig: [],
    destacado: [],
    dias: [],
    rangosHoras: [],
    informacionImportante: {
      incluye: [],
      noIncluye: [],
      debesTraer: [],
    },
    disponibilidades: [],
    disponibilidad: [],
    precioNiños: [],
    tags: [],
  },
});

const state = getDefaultState();

const getters = {
  experiencia(state) {
    return state;
  },
  experienciaBorrador(state) {
    return state.experienciaBorrador;
  },
  precioOferta(state, getters, rootState) {
    // console.log(rootState);
    return function (experienciaId) {
      const experiencia = rootState.experiencias.find(
        experiencia => experiencia.id === experienciaId
      );
      if (experiencia.oferta) return true;
      return false;
    };
  },
  precioOfertaMinimo(state, getters, rootState) {
    // console.log(rootState);
    return function (experienciaId) {
      const experiencia = rootState.experiencias.find(
        experiencia => experiencia.id === experienciaId
      );
      let precio;

      if (experiencia.preciosConfig && experiencia.preciosConfig.length > 0) {
        experiencia.preciosConfig.forEach(config => {
          const newConfig = { ...config };
          delete newConfig.cantidadPersonas;
          // Incluir idiomas oferta a eliminar
          delete newConfig.Inglés;
          delete newConfig.Español;
          for (const name in newConfig) {
            // Excluyo los precios oferta
            if (!precio && name.includes("Oferta") === true) {
              precio = Number(newConfig[name]);
            }
            // Excluyo los precios oferta
            if (
              Number(newConfig[name]) < Number(precio) &&
              Number(newConfig[name]) > 0 &&
              name.includes("Oferta") === true
            ) {
              precio = Number(newConfig[name]);
            }
          }
        });
      }
      if (!experiencia.oferta) return precio;
      // Está dando problemas con los precios
      return precio || experiencia.precioOferta;
    };
  },
  precioMinimo(state, getters, rootState) {
    // console.log(rootState);
    return function (experienciaId) {
      const experiencia = rootState.experiencias.find(
        experiencia => experiencia.id === experienciaId
      );
      let precio;

      if (experiencia.preciosConfig && experiencia.preciosConfig.length > 0) {
        experiencia.preciosConfig.forEach(config => {
          const newConfig = { ...config };
          delete newConfig.cantidadPersonas;
          // Incluir idiomas oferta a eliminar
          delete newConfig.InglésOferta;
          delete newConfig.EspañolOferta;
          for (const name in newConfig) {
            // Excluyo los precios oferta
            if (!precio && name.includes("Oferta") === false) {
              precio = Number(newConfig[name]);
            }
            // Excluyo los precios oferta
            if (
              Number(newConfig[name]) < Number(precio) &&
              Number(newConfig[name]) > 0 &&
              name.includes("Oferta") === false
            ) {
              precio = Number(newConfig[name]);
            }
          }
        });
      }
      // Está dando problemas con los precios
      return precio || experiencia.precio;
    };
  }
};

const mutations = {
  resetExperiencia(state) {
    Object.assign(state, getDefaultState());
  },
  resetFechaExclusiva(state) {
    state.fechaExclusiva = null;
  },
  experienciaHandleChange(state, payload) {
    const { name, value, type, language } = payload;

    if (type === "time") {
      const timeToDate = new Date();
      const parts = value.split(":");
      timeToDate.setHours(parts[0]);
      timeToDate.setMinutes(parts[1]);
      state[name] = timeToDate;
    }

    if (type === "number" && value.length > 0) {
      if (name === "cantidadDias") {
        if (Number(value) === 0) {
          alert("Debe existir al menos un dia");
          const currentValue = state[name];
          state[name] = value;
          state[name] = currentValue;
          return;
        }
      }
      if (isNaN(Number(value))) {
        alert("Solo debe contener caracteres numericos");
        const currentValue = state[name];
        state[name] = value;
        state[name] = currentValue;
        return;
      }
      if (Number(value) < 0) {
        alert("Solo debe contener valores positivos");
        const currentValue = state[name];
        state[name] = value;
        state[name] = currentValue;
        return;
      }
    }
    if (name === "modalidad" || name === "cantidadDias") {
      if (state[name] !== value) {
        const confirmarCambio = confirm(
          "Si modifica este campo se restauraran todas las disponibilidades, desea continuar?"
        );
        if (!confirmarCambio) {
          const currentValue = state[name];
          state[name] = value;
          state[name] = currentValue;
          return;
        }
        if (
          name === "modalidad" &&
          (value === "fechaExclusiva" || value === "diasSemana")
        ) {
          state.cantidadDias = 1;
        }
        state.disponibilidad = [];
        state.disponibilidades = [];
        state.experienciaBorrador.disponibilidad = [];
        state.experienciaBorrador.disponibilidades = [];
        state.fechaExclusiva = null;
        state.rangosHoras = [];
        state.experienciaBorrador.rangosHoras = [];
        state.horaInicio = null;
        state.horaFin = null;
        state.descripcion.dias = [];
        state.experienciaBorrador.dias = [];
        state.ingles.descripcion.dias = [];
        state.ingles.experienciaBorrador.dias = [];
      }
    }

    if (name === "modalidadHoras") {
      if (
        state.horaInicio ||
        state.horaFin ||
        state.rangosHoras.length > 0 ||
        state.experienciaBorrador.rangosHoras.length > 0
      ) {
        const confirmar = confirm(
          "Se reiniciaran las horas registradas, desea continuar?"
        );
        if (!confirmar) {
          const currentValue = state[name];
          state[name] = value;
          state[name] = currentValue;
          return;
        }
        state.horaInicio = null;
        state.horaFin = null;
        state.rangosHoras = [];
        state.experienciaBorrador.rangosHoras = [];
      }
    }

    if (name === "capacidad" || name === "capacidadMinima") {
      if (!(value > 0)) {
        alert("La capacidad de viajeros no debe ser menor de cero");
        const currentValue = state[name];
        state[name] = value;
        state[name] = currentValue;
        return;
      }

      if (name === "capacidad" && value < state.capacidadMinima) {
        alert("La capacidad maxima no debe ser menor que la capacidad minima");
        const currentValue = state[name];
        state[name] = value;
        state[name] = currentValue;
        return;
      }

      state[name] = value;
    }

    if (language === "en") {
      if (name.includes(".")) {
        const propertyNames = name.split(".");
        state.ingles[propertyNames[0]][propertyNames[1]] = value;
        return;
      }
      state.ingles[name] = value;
    } else if (language === "es") {
      if (name.includes(".")) {
        const propertyNames = name.split(".");
        state[propertyNames[0]][propertyNames[1]] = value;
        return;
      }
      state[name] = value;
    }
    if (name.includes('precioFijoTemporadas')) {
      const temporada = name.split('precioFijoTemporadas')[1]
      state.precioFijoTemporadas.map(p => {
        if (p.temporada === temporada) {
          p.precio = +value
          return p
        } else return p
      })
      console.table(state.precioFijoTemporadas);
    }
  },
  submitItem(state, payload) {
    //console.log('PAYLOAD:', payload);
    const { level, name, value, language } = payload;
    if (language === "en") {
      if (payload.level) {
        state.ingles.experienciaBorrador[level][name].push(value);
        return;
      }
      state.ingles.experienciaBorrador[name].push(value);
      return;
    }

    if (payload.level) {
      state.experienciaBorrador[level][name].push(value);
      return;
    }
    if (name === "diasSemana") {
      state.experienciaBorrador.disponibilidad = value;
      return;
    }
    if (name === 'precioNiños') {
      value.edadMinima = +value.edadMinima;
      value.edadMaxima = +value.edadMaxima;
      value.precio = +value.precio;
      value.nombre = value.nombre.trim();
    }
    state.experienciaBorrador[name].push(value);
  },
  borrarItem(state, payload) {
    const check = confirm("Esta seguro de borrar el elemento?");
    if (!check) {
      return;
    }
    // En caso de ser el lenguaje ingles
    if (payload.language === "en") {
      if (payload.borrador) {
        if (payload.level) {
          state.ingles.experienciaBorrador[payload.level][payload.name].splice(
            payload.index,
            1
          );
          return;
        }
        state.ingles.experienciaBorrador[payload.name].splice(payload.index, 1);
        return;
      }
      if (payload.level) {
        state.ingles[payload.level][payload.name].splice(payload.index, 1);
        return;
      }
      state.ingles[payload.name].splice(payload.index, 1);

      return;
    }
    // En caso de ser el lenguaje castellano
    if (payload.borrador) {
      if (payload.level) {
        state.experienciaBorrador[payload.level][payload.name].splice(
          payload.index,
          1
        );
        return;
      }
      state.experienciaBorrador[payload.name].splice(payload.index, 1);
      return;
    }
    if (payload.level) {
      state[payload.level][payload.name].splice(payload.index, 1);
      return;
    }
    state[payload.name].splice(payload.index, 1);
  },
  updateIdiomas(state, payload) {
    state.idiomas = payload;
  },
  updateDiasSemana(state, payload) {
    state.disponibilidad = payload;
  },
  updateReservaInmediata(state, payload) {
    state.reserva_inmediata = payload;
  },
  submitTemporadas(state, { day, temporadaSelected, newTemporada }) {
    // Inclusiónde nueva temporada
    if (newTemporada) {
      state.temporadas.temporadas.push(newTemporada)
      // Creando campos para precios
      state.precioFijoTemporadas.push({ temporada: newTemporada.nombre, precio: 0 })
    }
    // Agregando fechas a temporada existente
    else {

      const dateObj = state.temporadas.fechas.find((d) => d.id === day.id);
      const idx = state.temporadas.fechas.findIndex((d) => d.id === day.id);


      if (idx >= 0) {
        state.temporadas.fechas.splice(idx, 1);
        if(dateObj.temporada != temporadaSelected.nombre){
          console.log('temporada distinta');
          state.temporadas.fechas.push({
            id: day.id,
            date: moment(day.date).format(),
            temporada: temporadaSelected.nombre,
            color: temporadaSelected.color,
          });
        }
        else console.log('temporada igual');
      } 
      else {
        state.temporadas.fechas.push({
          id: day.id,
          date: moment(day.date).format(),
          temporada: temporadaSelected.nombre,
          color: temporadaSelected.color,
        });
      }
    }
  },
  deleteTemporada(state, temporada) {
    // eliminar temporada
    state.temporadas.temporadas = state.temporadas.temporadas.filter(t => t.nombre != temporada)
    // eliminar fechas de temporada
    state.temporadas.fechas = state.temporadas.fechas.filter(f => f.temporada != temporada)

    // eliminar precios de temporada    
    // Precio fijo
    state.precioFijoTemporadas = state.precioFijoTemporadas.filter(pf => pf.temporada != temporada)
    // PreciosConfig
    if (state.preciosConfig && state.preciosConfig, length) {
      let newPreciosConfig = []

      state.preciosConfig.forEach(precioConfig => {
        const keys = Object.keys(precioConfig);
        const newKeys = keys.filter(k => !k.includes(temporada));
        let newPrecioConfig = {};
        newKeys.forEach(nk => {
          if (precioConfig[nk]) newPrecioConfig[nk] = precioConfig[nk];
        })
        newPreciosConfig.push(newPrecioConfig)
      })
      state.preciosConfig = newPreciosConfig;
    }
  }
};

const actions = {
  borrarItem({ rootState, commit }, payload) {
    payload.language = rootState.language;
    commit("borrarItem", payload);
  },
  async deleteExperiencia({ state, commit }, payload) {
    const confirmar = confirm(
      "Esta seguro que desea eliminar la experiencia? "
    );

    if (!confirmar) {
      return;
    }

    const images = [];

    if (state.heroImgUrls) {
      images.push(...state.heroImgUrls);
    }

    if (state.carouselImgUrl) {
      images.push(state.carouselImgUrl);
    }

    try {
      if (images.length > 0) {
        const imagesToDelete = images.map(image => {
          return storage.refFromURL(image);
        });
        await Promise.all(imagesToDelete.map(image => image.delete()));
      }

      await experiencias.doc(payload.id).delete();
      await destinosCollection.doc(payload.destinoId).update({
        experiencias: firebase.firestore.FieldValue.arrayRemove(payload.id)
      });
      commit("resetBorrador", null, { root: true });
      commit("resetExperiencia");
      alert("Experiencia eliminada exitosamente");
      router.push({ path: "/admin" });
    } catch (error) {
      console.log(error);
    }
  },
  async submitExperiencia({ state, commit, rootState }, payload) {
    const zona = rootState.zonas.find(zona => zona.id === payload.zonaId);
    if (!zona) {
      alert(
        "No existe la zona sobre la cual se desea actualizar la experiencia"
      );
      return;
    }

    const destino = rootState.destinos.find(
      destino => destino.id === payload.destinoId
    );
    if (!destino) {
      alert(
        "El destino sobre el cual se desea actualizar la experiencia no existe"
      );
      return;
    }

    const destinoId = zona.destinos.find(
      destinoId => destinoId === payload.destinoId
    );
    if (!destinoId) {
      alert(
        "El destino correspondiente a la experiencia no existe dentro de zona"
      );
      return;
    }

    if (payload.action === "update") {
      const experiencia = rootState.experiencias.find(
        experiencia => experiencia.id === payload.id
      );
      if (!experiencia) {
        alert("La experiencia que intenta actualizar no existe");
        return;
      }

      const experienciaId = destino.experiencias.find(
        experienciaId => experienciaId === payload.id
      );
      if (!experienciaId) {
        alert(
          "La experiencia que intenta actualizar no existe dentro del destino"
        );
        return;
      }
    }

    const {
      titulo,
      slug,
      seo_titulo,
      tituloDestino,
      reserva_inmediata,
      googleMapsUrl,
      youtubeMedia,
      horaInicio,
      horaFin,
      rangosHoras,
      preciosConfig,
      modalidadHoras,
      modalidadPrecio,
      subtitulo,
      resumen,
      destacado,
      idiomas,
      stock,
      disponibilidades,
      disponibilidad,
      cantidadDias,
      diasAnticipacion,
      modalidad,
      modalidadViaje,
      visitas,
      puntaje,
      oferta,
      precio,
      precioFijoTemporadas,
      precioOferta,
      permiteAbono,
      porcentajeAbono,
      precioDiferenciadoNiños,
      precioNiños,
      capacidad,
      capacidadMinima,
      fechaExclusiva,
      temporadas,
      precioMasBajo,
      mensajePrecioMasBajo,
      tags

    } = state;

    const { dias } = state.descripcion;

    const { incluye, noIncluye, debesTraer } = state.informacionImportante;

    const disponibilidadesMap = disponibilidades.map(disponibilidad => {
      const disponibilidadMap = {};
      disponibilidadMap.start = new Date(disponibilidad.start).toString();
      disponibilidadMap.end = new Date(disponibilidad.end).toString();
      return disponibilidadMap;
    });

    const disponibilidadesBorradorMap = state.experienciaBorrador.disponibilidades.map(
      disponibilidad => {
        const disponibilidadMap = {};
        disponibilidadMap.start = new Date(disponibilidad.start).toString();
        disponibilidadMap.end = new Date(disponibilidad.end).toString();
        return disponibilidadMap;
      }
    );

    const disponibilidadesUpdates = [
      ...disponibilidadesMap,
      ...disponibilidadesBorradorMap
    ];

    const updates = {
      titulo,
      slug,
      seo_titulo,
      tituloDestino,
      subtitulo,
      reserva_inmediata,
      googleMapsUrl,
      resumen,
      modalidad,
      modalidadViaje,
      modalidadHoras,
      modalidadPrecio,
      cantidadDias,
      diasAnticipacion,
      horaInicio,
      horaFin,
      rangosHoras: [...rangosHoras, ...state.experienciaBorrador.rangosHoras],
      preciosConfig: [
        ...preciosConfig,
        ...state.experienciaBorrador.preciosConfig
      ],
      destacado: [...destacado, ...state.experienciaBorrador.destacado],
      youtubeMedia: [
        ...youtubeMedia,
        ...state.experienciaBorrador.youtubeMedia
      ],
      tags: [...tags, ...state.experienciaBorrador.tags],
      idiomas,
      stock,
      disponibilidades: disponibilidadesUpdates,
      disponibilidad,
      fechaExclusiva,
      visitas,
      puntaje,
      oferta,
      precio,
      precioFijoTemporadas,
      precioOferta,
      permiteAbono,
      porcentajeAbono,
      precioDiferenciadoNiños: !!precioDiferenciadoNiños,
      precioNiños: [...precioNiños, ...state.experienciaBorrador.precioNiños],
      mensajePrecioMasBajo,
      precioMasBajo: +precioMasBajo ,
      capacidad,
      capacidadMinima,
      descripcion: {
        resumen: state.descripcion.resumen,
        dias: [...dias, ...state.experienciaBorrador.dias]
      },
      informacionImportante: {
        incluye: [
          ...incluye,
          ...state.experienciaBorrador.informacionImportante.incluye
        ],
        noIncluye: [
          ...noIncluye,
          ...state.experienciaBorrador.informacionImportante.noIncluye
        ],
        debesTraer: [
          ...debesTraer,
          ...state.experienciaBorrador.informacionImportante.debesTraer
        ],
        resumen: state.informacionImportante.resumen
      },
      temporadas,
      ingles: {
        titulo: state.ingles.titulo,
        seo_titulo: state.ingles.seo_titulo,
        tituloDestino: state.ingles.tituloDestino,
        subtitulo: state.ingles.subtitulo,
        resumen: state.ingles.resumen,
        tags: [...state.ingles.tags, ...state.ingles.experienciaBorrador.tags],
        destacado: [
          ...state.ingles.destacado,
          ...state.ingles.experienciaBorrador.destacado
        ],
        descripcion: {
          resumen: state.ingles.descripcion.resumen,
          dias: [
            ...state.ingles.descripcion.dias,
            ...state.ingles.experienciaBorrador.dias
          ]
        },
        informacionImportante: {
          incluye: [
            ...state.ingles.informacionImportante.incluye,
            ...state.ingles.experienciaBorrador.informacionImportante.incluye
          ],
          noIncluye: [
            ...state.ingles.informacionImportante.noIncluye,
            ...state.ingles.experienciaBorrador.informacionImportante.noIncluye
          ],
          debesTraer: [
            ...state.ingles.informacionImportante.debesTraer,
            ...state.ingles.experienciaBorrador.informacionImportante.debesTraer
          ],
          resumen: state.ingles.informacionImportante.resumen
        },
        mensajePrecioMasBajo: state.ingles.mensajePrecioMasBajo
      }
    };

    // Control de dias en castellano
    if (state.cantidadDias > 1) {
      if (updates.descripcion.dias.length < Number(state.cantidadDias)) {
        const confirmar = confirm(
          "Aun faltan dias por describir en el itinerario, desea continuar de todas formas?"
        );
        if (!confirmar) {
          return;
        }
      } else if (updates.descripcion.dias.length > Number(state.cantidadDias)) {
        alert(
          "La cantidad de dias agregados es mayor a la cantidad de dias asignada, necesita eliminar dias"
        );
        return;
      } else if (
        updates.descripcion.dias.length !== Number(state.cantidadDias)
      ) {
        alert(
          "La cantidad de dias agregados no coincide con la cantidad de dias asignada"
        );
        return;
      }

      // Control de dias en ingles
      if (updates.ingles.descripcion.dias.length < Number(state.cantidadDias)) {
        const confirmar = confirm(
          "Aun faltan dias por describir en el itinerario en ingles, desea continuar de todas formas?"
        );
        if (!confirmar) {
          return;
        }
      } else if (
        updates.ingles.descripcion.dias.length > Number(state.cantidadDias)
      ) {
        alert("Cantidad de dias excedente en ingles");
        return;
      } else if (
        updates.ingles.descripcion.dias.length !== Number(state.cantidadDias)
      ) {
        alert("Debe registrar la misma cantidad de dias");
        return;
      }
    }

    let key;
    if (payload.action === "create") {
      const createTask = await experiencias.add(updates);
      key = createTask.id;
    } else {
      key = payload.id;
    }

    const storageUpdateConfig = storageHandler(
      storage,
      rootState,
      {
        zonaId: payload.zonaId,
        destinoId: payload.destinoId,
        experienciaId: key
      },
      "experiencia"
    );
    const storageDownloadURLs = await storageURLs(storageUpdateConfig);
    const { storageUpdateAvailable, storageUpdates } = storageDownloadURLs;

    if (storageUpdateAvailable) {
      if (payload.action === "create") {
        await experiencias.doc(key).update(storageUpdates);
        await destinosCollection.doc(payload.destinoId).update({
          experiencias: firebase.firestore.FieldValue.arrayUnion(key)
        });
      } else if (payload.action === "update") {
        await experiencias.doc(key).update(updates);
        await experiencias.doc(key).update(storageUpdates);
      }
      router.push({ path: "/admin" });
      alert(`Operacion en experiencia ${state.titulo} realizada exitosamente`);
      commit("resetBorrador", null, { root: true });
      commit("resetExperiencia");
    } else {
      if (payload.action === "create") {
        await experiencias.doc(key).update(updates);
        await destinosCollection.doc(payload.destinoId).update({
          experiencias: firebase.firestore.FieldValue.arrayUnion(key)
        });
      } else if (payload.action === "update") {
        await experiencias.doc(key).update(updates);
        await experiencias.doc(key).update(storageUpdates);
      }
      router.push({ path: "/admin" });
      alert(`Operacion en experiencia ${state.titulo} realizada exitosamente`);
      commit("resetBorrador", null, { root: true });
      commit("resetExperiencia");
    }
  },
  experienciaTypeChange({ rootState, commit }, payload) {
    payload.language = rootState.language;
    commit("experienciaHandleChange", payload);
  },
  submitItem({ commit, rootState }, payload) {
    payload.language = rootState.language;
    commit("submitItem", payload);
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
