// Modulo para gestionar los contenidos graficos (mas que todo thumbnails)
// OJO!: Codigo realizado acorde al ESLinter del proyecto

const state = {

}

const mutations = {

}

const getters = {
  getThumbnail (state) {
    return function (payload) {
      const { contentUrl, size } = payload
      if (contentUrl) {
        const contentTypes = ['.jpg', '.JPG', '.jpeg', '.JPEG', '.png', '.PNG']

        const selectedType = contentTypes.find((type) => contentUrl.includes(type))
        const urlParts = contentUrl.split(selectedType)

        const thumnailType = `_thumb_${size}`
        return `${urlParts[0]}${thumnailType}${selectedType}${urlParts[1]}`
      } else {
        return ''
      }
    }
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
