<template>
  <div class="admin-home">
    <div class="admin-home__section">
      <h1 class="heading heading--1 heading--center">Administración</h1>
      <div class="container">
        <b-row class="p-2" align-h="center">
          <b-col cols="6" md="3" class="text-center">
            <b-button :to="{ name: 'Evaluaciones' }" variant="success" class="p-3"><strong>Ver
                evaluaciones</strong></b-button>
          </b-col>
          <b-col cols="6" md="2" class="text-center">
            <b-button :to="{ name: 'CalendarioReservas' }" variant="success" class="p-3 my-2 my-md-0">
              <strong>Ver calendario</strong>
            </b-button>
          </b-col>
          <b-col cols="6" md="4" class="text-center">
            <b-button :to="{ name: 'NewReserva' }" variant="success" class="p-3 my-2 my-md-0">
              <strong>Nueva reserva personalizada</strong>
            </b-button>
          </b-col>
          <!-- <b-col cols="12" md="2" class="text-center">
              <b-button
                :to="{ name: 'ListUsuarios' }"
                variant="success"
                class="p-3 my-2 my-md-0"
              >
                <strong>Usuarios</strong>
              </b-button>
            </b-col>
          <b-col cols="12" md="1" class="text-center">
              <b-button
                :to="{ name: 'ListViajes' }"
                variant="success"
                class="p-3 my-2 my-md-0"
              >
                <strong>Viajes</strong>
              </b-button>
            </b-col> -->
        </b-row>
      </div>
    </div>
    <div class="admin-home__section">
      <h1 class="heading heading--1 heading--center">Administración Viajes Personalizados</h1>
      <div class="container">
        <b-row align-h="center" class="p-2">
          <b-col cols="6" md="2" class="text-center">
            <b-button :to="{ name: 'ListPersonalizedTrip' }" variant="success" class="p-3">
              <strong>Viajes</strong>
            </b-button>
          </b-col>
          <b-col cols="6" md="2" class="text-center">
            <b-button :to="{ name: 'ListSolicitudes' }" variant="success" class="p-3 my-2 my-md-0">
              <strong>Solicitudes</strong>
            </b-button>
          </b-col>
          <!-- <b-col cols="6" md="2" class="text-center">
            <b-button :to="{ name: 'ListUsuarios' }" variant="success" class="p-3 my-2 my-md-0">
              <strong>Usuarios</strong>
            </b-button>
          </b-col> -->
        </b-row>
      </div>
    </div>
    <div class="admin-home__section">
      <h1 class="heading heading--1 heading--center">Reservaciones</h1>
      <div class="container">
        <b-row class="p-4">
          <b-col cols="6" md="6" class="text-center">
            <b-button :to="{ name: 'ReservasInmediatas' }" variant="success" class="p-3"><strong>Reservas
                Inmediatas</strong></b-button>
          </b-col>
          <b-col cols="6" md="6" class="text-center">
            <b-button :to="{ name: 'ReservasPorConfirmar' }" variant="success" class="p-3"><strong>Reservas por
                Confirmar</strong></b-button>
          </b-col>

        </b-row>
      </div>
    </div>
    <div class="admin-home__section">
      <h1 class="heading heading--1 heading--center">Zonas</h1>
      <div class="container-fluid">
        <b-row>
          <b-col cols="12" md="6" lg="3" v-for="(zona, index) in getZonas" :key="index">
            <router-link :to="'/admin/zonas/' + zona.id">
              <div class="admin-zona">
                <div>
                  <div class="admin-zona__content">
                    <h4 class="heading heading--4">Titulo: {{ zona.titulo }}</h4>
                    <p class="paragraph">Subtitulo: {{ zona.subtitulo }}</p>
                    <p class="paragraph">Destinos: {{ zona.destinos.length }}</p>
                  </div>
                </div>
              </div>
            </router-link>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-3">
            <router-link tag="div" :to="'admin/zonas/registrar-zona'" class="admin-zona__agregar">
              <a class="admin-zona__add-link">Agregar nueva zona</a>
            </router-link>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="admin-home__section">
      <h1 class="heading heading--1 heading--center">
        Experiencias Imperdibles
      </h1>
      <div class="admin-home__experiencias-imperdibles">
        <b-carousel v-if="slideExperiencias && slideExperiencias.length > 0" id="carousel" :interval="6000" controls>
          <b-carousel-slide v-for="(contenidoArray, index) in slideExperiencias" :key="index" style="height: 300px">
            <b-row>
              <b-col v-for="(experiencia, index) in contenidoArray" :key="index" class="col-12 col-sm-6 col-md-4">
                <div class="expImg" :style="{
                    background: `url(${experiencia.carouselImgUrl}) no-repeat center center / cover`,
                  }">
                  <div class="capaOscura">
                    <h3>
                      {{
                      language === "es"
                      ? experiencia.titulo
                      : experiencia.tituloIngles
                      }}
                    </h3>
                  </div>
                  <div class="buttons">
                    <button class="admin__btn admin__btn--warning" @click="
                        deleteFromExperienciasImperdibles({
                          id: experiencia.id,
                        })
                      ">
                      Eliminar
                    </button>
                  </div>
                </div>
              </b-col>
            </b-row>
          </b-carousel-slide>
        </b-carousel>
      </div>
      <h3 style="text-align: center">Agregar nueva experiencia</h3>
      <div class="admin__input-group">
        <label class="edit-zona__label">Zona:</label>
        <select class="admin__select" name="zona" v-model="zonaId" @change="handleChange">
          <option value>Seleccione una zona</option>
          <option v-for="(zona, index) in getZonas" :key="index" :value="zona.id">
            {{ zona.titulo }}
          </option>
        </select>
      </div>
      <div v-if="zonaId && zonaId.length > 0" class="admin__input-group">
        <label class="edit-zona__label">Destino:</label>
        <select class="admin__select" v-model="destinoId" @change="handleChange">
          <option value>Seleccione un destino</option>
          <option v-for="(destino, index) in siteDestinosByZona({ id: zonaId })" :key="index" :value="destino.id">
            {{ destino.titulo }}
          </option>
        </select>
      </div>
      <div v-if="destinoId && destinoId.length > 0" class="admin__experiencias">
        <div v-for="(experiencia, index) in siteExperienciasByDestino({
            id: destinoId,
          })" :key="index" class="checkbox">
          <label>
            <input type="checkbox" :value="experiencia.id" :checked="experienciasImperdibles.includes(experiencia.id)"
              :disabled="experienciasImperdibles.includes(experiencia.id)" @change="submitRequest" />
            {{ experiencia.titulo }}
          </label>
        </div>
      </div>
      <div class="admin__buttons admin__buttons--center">
        <div class="edit-zona__loading-button edit-zona__loading-button--center">
          <button @click="submitExperiencias" class="edit-zona__btn edit-zona__btn--success" :disabled="isLoading"
            v-if="!(experiencias.length === cantidadExperienciasImperdibles)">
            Guardar Experiencias
          </button>
          <div v-if="isLoading" class="lds-dual-ring"></div>
        </div>
      </div>
    </div>
    <hr />
    <router-link to="/admin/cupones">
      <button class="btn btn-info d-block m-auto">
        Generar un nuevo cupón
      </button>
    </router-link>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  data() {
    return {
      zonaId: "",
      destinos: [],
      destinoId: "",
      filter: null,
      totalRows: 1,
      currentPage: 1,
      reservasTitulos: [
        { key: "index", label: "N°" },
        {
          key: "fechaActual",
          label: "Fecha de Reserva",
          sortable: true,
          class: "text-center",
        },
        {
          key: "idUnico",
          label: "ID de Reserva",
          sortable: true,
          class: "text-center",
        },
        {
          key: "experiencia",
          label: "Experiencia",
          sortable: true,
          class: "text-center",
        },
        {
          key: "nombre",
          label: "Nombre",
          sortable: true,
          class: "text-center",
        },
        {
          key: "correo",
          label: "Correo Electrónico",
          sortable: true,
          class: "text-center",
        },
        { key: "telefono", label: "Teléfono", class: "text-center" },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
          class: "text-center",
        },
        // Columna Acciones
        { key: "acciones", label: "Acción", class: "text-center" },
      ],
    };
  },
  computed: {
    ...mapGetters([
      "isLoading",
      "language",
      "getZonas",
      "experienciasImperdibles",
      "cantidadExperienciasImperdibles",
      "siteDestinosByZona",
      "siteExperienciasByDestino",
      "getExperiencias",
      "reserva",
    ]),
    ...mapState("reservaciones", ["reservaciones"]),
    experiencias() {
      if (this.experienciasImperdibles?.length > 0) {
        const experiencias = this.getExperiencias.filter((experiencia) =>
          this.experienciasImperdibles.includes(experiencia.id)
        );
        return experiencias;
      }
      return [];
    },
    slideExperiencias() {
      if (
        this.experienciasImperdibles &&
        this.experienciasImperdibles.length > 0
      ) {
        const experiencias = this.getExperiencias.filter((experiencia) =>
          this.experienciasImperdibles.includes(experiencia.id)
        );
        const contenidoArray = [];

        // Cambiar la cantidad de elementos ingresados en array dependiendiendo del viewport width
        const vw = Math.max(
          document.documentElement.clientWidth || 0,
          window.innerWidth || 0
        );
        let elements = 1;

        if (vw >= 768) {
          elements = 3;
        } else if (vw >= 576) {
          elements = 2;
        }

        const loops = Math.ceil(experiencias.length / elements);

        for (let i = 0; i < loops; i++) {
          contenidoArray.push(
            experiencias.slice(i * elements, i * elements + elements)
          );
        }
        return contenidoArray;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["submitExperienciaImperdible"]),
    ...mapActions("reservaciones", [
      "getReservas",
      "aceptarReserva",
      "rechazarReserva",
    ]),
    ...mapMutations([
      "addToExperienciasImperdibles",
      "deleteFromExperienciasImperdibles",
      "switchLoading",
    ]),
    ...mapActions("ForeignTrip", [
      "getTrips",
    ]),
    async submitExperiencias() {
      try {
        this.switchLoading(true);
        await this.submitExperienciaImperdible();
        this.switchLoading(false);
      } catch (error) {
        this.switchLoading(false);
        console.log(error);
      }
    },
    handleChange(event) {
      const { value } = event.target;
      if (value && value.length > 0) {
        const destinos = this.siteDestinosByZona({
          id: value,
        });
        this.destinos = destinos;
      }
    },
    submitRequest(event) {
      if (!this.experienciasImperdibles.includes(event.target.value)) {
        this.addToExperienciasImperdibles({
          id: event.target.value,
        });
      }
    },
    aceptarReservacion(index) {
      this.aceptarReserva(index);
    },
    denegarReservacion(index) {
      this.rechazarReserva(index);
    },
  },
  created() {
    this.getReservas();
    this.getTrips();
  },
};
</script>

<style lang="scss">
.iconHover:hover {
  cursor: pointer;
  // transform: scale(0.95);
}
.edit-zona {
  &__loading-button {
    &--center {
      margin: auto;
    }
  }
}

.expImg {
  margin: 0 10px 0 10px;
  border-radius: 10px;
  height: 250px;
}

.admin-home {
  color: white;

  &__experiencias-imperdibles {
    padding: 40px 0;
  }
}

.admin-zona__img {
  width: 100%;
  height: 200px;
}

.admin {
  &__list {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    justify-content: center;
    margin: 0;
    padding: 40px 0;
  }

  &__list-item {
    display: flex;
    margin: 15px;
    flex-direction: column;
    justify-content: space-between;
    border: none;
    border-radius: 1px 1px 5px black;
    background: white;
    color: black;
    width: 320px;
  }

  &__input-group,
  &__experiencias {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px 0;
  }

  &__btn {
    padding: 15px;
    border: none;
    box-shadow: 1px 1px 3px black;

    &--warning {
      background: rgb(231, 49, 49);
      color: white;
      font-weight: 500;
    }
  }

  &__select {
    padding: 10px;
    font-size: 1.2rem;
  }

  &__img {
    &--1 {
      display: block;
      width: 100%;
      height: 200px;
    }
  }

  &__paragraph {
    &--1 {
      font-size: 1.4rem;
      padding-top: 5px;
      text-align: center;
      font-weight: 400;
    }
  }

  &__buttons {
    &--center {
      display: flex;
      justify-content: center;
      padding: 20px 0;
    }
  }
}

.admin-zona {
  color: #333;
  box-shadow: 1px 1px 5px black;
  margin: 20px;
  border-radius: 5px;
  background: white;
  cursor: pointer;

  &__agregar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__add-link,
  &__add-link:active,
  &__add-link:visited {
    font-weight: 600;
    font-size: 1.6rem;
    color: white;
    letter-spacing: 1px;
  }
}

.heading--center {
  text-align: center;
}

.admin-home {
  padding: 30px 0;
}

.admin-zona__content,
.admin-zona__buttons {
  padding: 10px 20px;
}

.admin-zona__buttons {
  display: flex;
  justify-content: space-around;
}

.btn--admin-zona {
  border: 1px solid black;
  border-radius: 3px;
  padding: 5px;
}
</style>
