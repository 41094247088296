import router from "../../router/index";

const state = {
  experienciaId: null,
  cantidadAdultos: null,
  cantidadNiños: null,
  idioma: null,
  disponibilidad: null,
  horaInicio: null,
  horaFin: null,
  rangoHorasIndex: null,
  reservas: []
};

const getters = {
  reserva(state) {
    return state;
  },
  cantidadNiñosSumados(state, getters) {
    let niños = 0;
    const arrayNiños = state.cantidadNiños ? [...state.cantidadNiños] : []
    if (arrayNiños.length) {
      let cantidades = arrayNiños.map(precio => +precio.cantidad)
      niños = cantidades.reduce((a, b) => a + b)
    }
    return niños
  }
};

const mutations = {
  reservaHandleChange(state, { name, value }) {
    if (name === "cantidadAdultos") {
      state.cantidadAdultos = Number(value);
      return;
    } else if (name === 'cantidadNiños') {
      const preciosNiños = [...value].map(p => {
        if (!p.cantidad) p.cantidad = 0;
        return p;
      });
      state.cantidadNiños = preciosNiños;
      return;
    }
    state[name] = value;
  },
  resetReserva(state) {
    state.experienciaId = null;
    state.cantidadAdultos = null;
    state.cantidadNiños = null
    state.idioma = null;
    state.disponibilidad = null;
    state.horaInicio = null;
    state.horaFin = null;
    state.rangoHorasIndex = null;
  },
  async addToReservas(state, payload) {
    //Agregado para permitir solo una reserva en checkout
    state.reservas = []
    //
    await state.reservas.push({ ...payload });
    router.push({ path: "/checkout" });
  },
  DeleteReserva(state, payload) {
    // state.reservas = state.reservas.filter(
    //   (reserva, index) => index !== payload.index
    // );
    state.reservas.splice(payload, 1);
  },
};

const actions = {
  async submitReserva({ state, commit, rootState, getters }, {toast , precioAdulto , total , temporadas , detalleViajeros}) {
    const isValid =
      state.experienciaId &&
      state.cantidadAdultos &&
      state.idioma &&
      state.disponibilidad &&
      ((state.horaInicio && state.horaFin) || state.rangoHorasIndex !== null);

    if (!isValid) {
      toast.toast(`Debe ingresar fecha, hora, cantidad de viajeros e idioma`, {
        title: `Ups... `,
        toaster: "b-toaster-top-center",
        solid: false,
        variant: "danger"
      });
      return;
    }

    const experiencia = rootState.experiencias.find(
      experiencia => experiencia.id === state.experienciaId
    );

    if (!experiencia) {
      alert("La experiencia a la que intenta acceder no existe");
      return;
    }

    let reserva;

    let cantidadNiños =  state.cantidadNiños ? state.cantidadNiños.filter(n => n.cantidad) : [];


    if (state.horaInicio && state.horaFin) {
      reserva = {
        experienciaId: state.experienciaId,
        cantidadViajeros: state.cantidadAdultos + getters.cantidadNiñosSumados,
        detalleViajeros,
        idioma: state.idioma,
        disponibilidad: state.disponibilidad,
        horaInicio: state.horaInicio,
        horaFin: state.horaFin,
        // precioAdulto,
        total,
        temporadas
      };
    } else if (
      state.rangoHorasIndex !== null &&
      state.rangoHorasIndex !== undefined
    ) {
      const horaInicio = experiencia.rangosHoras[state.rangoHorasIndex].start;
      const horaFin = experiencia.rangosHoras[state.rangoHorasIndex].end;
      reserva = {
        experienciaId: state.experienciaId,
        cantidadViajeros: state.cantidadAdultos + getters.cantidadNiñosSumados,
        detalleViajeros,
        idioma: state.idioma,
        disponibilidad: state.disponibilidad,
        horaInicio,
        horaFin,
        // precioAdulto,
        total,
        temporadas
      };
    }
    console.log("reserva", state);
    if (reserva) {
      await commit("addToReservas", reserva);
      await commit("resetReserva");
      // router.push({ path: "/checkout" });
    }
  },
  reservaHandleChange({ state, commit }, payload) {
    commit("reservaHandleChange", payload);
  },
  deleteReserva({ commit }, payload) {
    const confirmar = confirm("Esta seguro de eliminar esta reserva?");
    if (confirmar) commit("DeleteReserva", payload);
    return false;
  }
};

export default {
  state,
  getters,
  mutations,
  actions
};
