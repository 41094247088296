/* eslint-disable */
// Vue imports
import Vue from "vue";
import Vuex from "vuex";

// Firebase imports
import { db, storage } from "../firebase.service";
import firebase from "firebase";
import "firebase/firestore";

// Utilities imports
import storageHandler from "./utilities/storageHandler";
import storageURLs from "./utilities/downloadURLs";

// Router
import router from "../router/index";

// Modulos de Vuex
import zona from "./modules/zona";
import trip from "./modules/trip";
import usuarios from "./modules/usuarios";
import solicitudes from "./modules/solicitudes";
import viajess from "./modules/viajes";
import media from "./modules/media";
import experiencia from "./modules/experiencia";
import reserva from "./modules/reserva";
import reservaciones from "./modules/reservaciones";
import MainMenu from "./modules/MainMenu";
import HeroSection from "./modules/HeroSection";
import Buscador from "./modules/Buscador";
import ExpImperdibles from "./modules/ExpImperdibles";
import PorqueViajar from "./modules/PorqueViajar";
import Mapa from "./modules/Mapa";
import Contactanos from "./modules/Contactanos";
import Footer from "./modules/Footer";
import Nosotros from "./modules/Nosotros";
import Zonas from "./modules/Zonas";
import NuestrosDestinos from "./modules/NuestrosDestinos";
import NuestrasExperiencias from "./modules/NuestrasExperiencias";
import Tours from "./modules/Tours";
import ConoceZona from "./modules/ConoceZona";
import ConoceDestinos from "./modules/ConoceDestinos";
import Destinos from "./modules/Destinos";
import ExperienciaLang from "./modules/ExperienciaLang";
import Checkout from "./modules/Checkout";
import Terminos from "./modules/Terminos";
import Privacidad from "./modules/Privacidad";
import FAQ from "./modules/FAQ";
import Evaluaciones from "./modules/Evaluaciones";
import createPersistedState from "vuex-persistedstate";
import i18n from './modules/i18n'
import ForeignTrip from "./modules/ForeignTrip";

Vue.use(Vuex);

// Ref en base de datos de firebase
const zonas = db.collection("zonas");
const destinosCollection = db.collection("destinos");
const experiencias = db.collection("experiencias");
const experienciasImperdibles = db.collection("experienciasImperdibles");

export default new Vuex.Store({
    plugins: [createPersistedState({
        paths: [
            'zona',
            'trip',
            'usuarios',
            'solicitudes',
            'viajess',
            'media',
            'experiencia',
            'reserva',
            // 'reservaciones',
            'MainMenu',
            'HeroSection',
            'Buscador',
            'ExpImperdibles',
            'PorqueViajar',
            'Mapa',
            // 'Contactanos',
            'Footer',
            'Nosotros',
            'Zonas',
            'NuestrosDestinos',
            'NuestrasExperiencias',
            'ConoceZona',
            'ConoceDestinos',
            'Destinos',
            'Checkout',
            'Terminos',
            'Privacidad',
            'FAQ',
            'Tours',
            //i18n
            'i18n',
            'ForeignTrip'
        ]
    }), ],
    strict: true,
    modules: {
        zona,
        trip,
        usuarios,
        solicitudes,
        viajess,
        media,
        experiencia,
        reserva,
        reservaciones,
        MainMenu,
        HeroSection,
        Buscador,
        ExpImperdibles,
        PorqueViajar,
        Mapa,
        Contactanos,
        Footer,
        Nosotros,
        Zonas,
        NuestrosDestinos,
        NuestrasExperiencias,
        Tours,
        ConoceZona,
        ConoceDestinos,
        Destinos,
        ExperienciaLang,
        Checkout,
        Terminos,
        Privacidad,
        Evaluaciones,
        FAQ,
        i18n,
        ForeignTrip
    },
    state: {
        dropdownZonas: false,
        hamburguer: false,
        langMobile: false,
        destinosMobile: false,
        buscadorMobile: false,
        language: "es",
        loading: true,
        isLoading: false,
        delete: false,
        // Global zona state
        zonas: [],
        zonaId: null,
        // Global experiencia state
        experiencias: [],
        experienciaId: null,
        // Experiencias imperdibles
        experienciasImperdibles: {
            id: null,
            experiencias: []
        },
        cantidadExperienciasImperdibles: null,
        // Shared state
        zonaImgsToSave: {
            carouselImg: null,
            carouselImgUrl: null,
            localidadImg: null,
            localidadImgUrl: null,
            heroImgs: [],
            heroImgUrls: []
        },
        // Borrador para imagenes de Cultura y Gastronomia
        contenidoCulturaBorrador: [],
        consejosToSave: [],
        // Destino state
        destinos: [],
        destinoId: null,
        destino: {
            id: null,
            titulo: null,
            seo_titulo: null,
            slug: null,
            subtitulo: null,
            carouselImgUrl: null,
            localidadImrUrl: null,
            heroImgUrls: [],
            youtubeMedia: [],
            resumen: null,
            experiencias: [],
            contenidoCultura: [],
            itinerarioDiaSaved: [],
            info: {
                culturaGastronomia: null,
                verHacer: null,
                cuandoVisitar: null,
                dondeQuedarse: null,
                comoLlegar: null,
                consejos: []
            },
            localizacion: {
                latitud: null,
                longitud: null
            },
            ingles: {
                titulo: null,
                seo_titulo: null,
                subtitulo: null,
                resumen: null,
                info: {
                    culturaGastronomia: null,
                    verHacer: null,
                    cuandoVisitar: null,
                    dondeQuedarse: null,
                    comoLlegar: null,
                    consejos: [],
                    consejosToSave: []
                }
            },
            borrador: {
                youtubeMedia: []
            }
        }
    },
    getters: {
        isLoading(state) {
            return state.isLoading;
        },
        language(state) {
            return state.language;
        },
        experienciasImperdibles(state) {
            return state.experienciasImperdibles.experiencias;
        },
        cantidadExperienciasImperdibles(state) {
            return state.cantidadExperienciasImperdibles;
        },
        delete(state) {
            return state.delete;
        },
        getZonas(state) {
            return state.zonas;
        },
        // Obtener las zonas con el orden acorde al mapa
        getSortedZonas(state) {
            if (!(state.zonas.length > 0)) {
                return state.zonas;
            }
            const sortedZonas = [];
            state.zonas.forEach(zona => {
                if (zona.titulo.toLowerCase().includes("norte")) {
                    sortedZonas[0] = zona;
                }
                if (zona.titulo.toLowerCase().includes("centro")) {
                    sortedZonas[1] = zona;
                }
                if (zona.titulo.toLowerCase().includes("lagos")) {
                    sortedZonas[2] = zona;
                }
                if (zona.titulo.toLowerCase().includes("patagonia")) {
                    sortedZonas[3] = zona;
                }
            });

            return sortedZonas;
        },
        getDestinos(state) {
            return state.destinos;
        },
        destino(state) {
            return state.destino;
        },
        getDestinosByZona(state, payload) {
            const destinos = state.destinos.filter(destino => {
                let isMatch = false;
                const zona = state.zonas.find(zona => zona.id === state.zona.id);
                if (zona) {
                    for (const zonaDestino of zona.destinos) {
                        if (destino.id === zonaDestino) {
                            isMatch = true;
                            break;
                        }
                    }
                }
                return isMatch;
            });
            return destinos;
        },
        // Destinos por zona dentro de site
        siteDestinosByZona(state) {
            return function(payload) {
                const destinos = state.destinos.filter(destino => {
                    let isMatch = false;
                    const zona = state.zonas.find(zona => zona.id === payload.id);
                    if (zona) {
                        for (const zonaDestino of zona.destinos) {
                            if (destino.id === zonaDestino) {
                                isMatch = true;
                                break;
                            }
                        }
                    }
                    return isMatch;
                });
                return destinos;
            };
        },
        getExperienciasByDestino(state) {
            const experiencias = state.experiencias.filter(experiencia => {
                let isMatch = false;
                const destino = state.destinos.find(
                    destino => destino.id === state.destino.id
                );
                if (destino) {
                    for (const destinoExperiencia of destino.experiencias) {
                        if (experiencia.id === destinoExperiencia) {
                            isMatch = true;
                            break;
                        }
                    }
                }
                return isMatch;
            });
            return experiencias;
        },
        siteExperienciasByDestino(state) {
            return function(payload) {
                const experiencias = state.experiencias.filter(experiencia => {
                    let isMatch = false;
                    const destino = state.destinos.find(
                        destino => destino.id === payload.id
                    );
                    if (destino) {
                        for (const destinoExperiencia of destino.experiencias) {
                            if (experiencia.id === destinoExperiencia) {
                                isMatch = true;
                                break;
                            }
                        }
                    }
                    return isMatch;
                });
                return experiencias;
            };
        },
        getExperiencias(state) {
            return state.experiencias;
        },
        zonaImgsToSave(state) {
            return state.zonaImgsToSave;
        },
        consejosToSave(state) {
            return state.consejosToSave;
        },
        contenidoCulturaBorrador(state) {
            return state.contenidoCulturaBorrador;
        },

    },
    mutations: {
        dropDownZonas(state) {
            state.dropdownZonas = !state.dropdownZonas;
        },
        closeDropDownZonas(state) {
            state.dropdownZonas = false;
        },
        setLocaleEsp(state) {
            state.language = "es";
        },
        setLocaleEng(state) {
            state.language = "en";
        },
        setAnimation(state) {
            state.loading = false;
        },
        hamburguerToggle(state) {
            state.hamburguer = !state.hamburguer;
        },
        langToggle(state) {
            state.langMobile = !state.langMobile;
        },
        destinationToggle(state) {
            state.destinosMobile = !state.destinosMobile;
        },
        langFalse(state) {
            state.langMobile = false;
        },
        buscadorToggle(state) {
            state.buscadorMobile = !state.buscadorMobile;
            state.dropdownZonas = false;
        },
        buscadorFalse(state) {
            state.buscadorMobile = false;
        },
        switchLoading(state, payload) {
            state.isLoading = payload;
        },
        switchDelete(state, payload) {
            state.delete = payload;
        },
        switchLanguage(state) {
            if (state.language === "es") {
                state.language = "en";
                return;
            }
            if (state.language === "en") {
                state.language = "es";
            }
        },
        resetLanguage(state) {
            state.language = "es";
        },
        resetZona(state, payload) {
            // Reset para el estado de Vuex
            const { level } = payload;
            state[`${level}Id`] = null;
            state.zonaImgsToSave.carouselImg = null;
            state.zonaImgsToSave.carouselImgUrl = null;
            state.zonaImgsToSave.localidadImg = null;
            state.zonaImgsToSave.localidadImgUrl = null;
            state.zonaImgsToSave.heroImgUrls = [];
            state.zonaImgsToSave.heroImgs = [];
            state.consejosToSave = [];
            state[level].id = null;
            state[level].titulo = null;
            state[level].subtitulo = null;
            state[level].carouselImgUrl = null;
            state[level].localidadImgUrl = null;
            state[level].heroImgUrls = [];
            state[level].resumen = null;
            state[level].destinos = [];
            state[level].info.culturaGastronomia = null;
            state[level].info.verHacer = null;
            state[level].info.cuandoVisitar = null;
            state[level].info.dondeQuedarse = null;
            state[level].info.comoLlegar = null;
            state[level].info.consejos = [];

            // Resets para idioma ingles
            state[level].ingles.titulo = null;
            state[level].ingles.subtitulo = null;
            state[level].ingles.resumen = null;
            state[level].ingles.info.culturaGastronomia = null;
            state[level].ingles.info.verHacer = null;
            state[level].ingles.info.cuandoVisitar = null;
            state[level].ingles.info.dondeQuedarse = null;
            state[level].ingles.info.comoLlegar = null;
            state[level].ingles.info.consejos = [];

            if (state[level].contenidoCultura) {
                state[level].contenidoCultura = [];
            }

            if (level === "destino") {
                state[level].experiencias = [];
                state[level].localizacion.latitud = 0;
                state[level].localizacion.longitud = 0;
            }
        },

        resetBorrador(state) {
            // Reset para el estado de las imagenes y consejos que quieran guardarse
            state.zonaImgsToSave.carouselImg = null;
            state.zonaImgsToSave.carouselImgUrl = null;
            state.zonaImgsToSave.localidadImg = null;
            state.zonaImgsToSave.localidadImgUrl = null;
            state.zonaImgsToSave.heroImgUrls = [];
            state.zonaImgsToSave.heroImgs = [];
            state.consejosToSave = [];
            state.contenidoCulturaBorrador = [];
            state.zona.ingles.info.consejosToSave = [];
            state.destino.ingles.info.consejosToSave = [];

            // Reset para media de youtube
            state.zona.borrador.youtubeMedia = [];
            state.destino.borrador.youtubeMedia = [];

            // Reset para borrador en experiencias
            state.experiencia.experienciaBorrador.preciosConfig = [];
            state.experiencia.experienciaBorrador.destacado = [];
            state.experiencia.experienciaBorrador.dias = [];
            state.experiencia.experienciaBorrador.rangosHoras = [];
            state.experiencia.experienciaBorrador.disponibilidades = [];
            state.experiencia.experienciaBorrador.disponibilidad = [];
            state.experiencia.experienciaBorrador.youtubeMedia = [];
            state.experiencia.experienciaBorrador.informacionImportante.incluye = [];
            state.experiencia.experienciaBorrador.informacionImportante.noIncluye = [];
            state.experiencia.experienciaBorrador.informacionImportante.debesTraer = [];
        },
        addToCulturaBorrador(state, payload) {
            state.contenidoCulturaBorrador.push(payload);
        },
        deleteFromCulturaBorrador(state, payload) {
            state.contenidoCulturaBorrador.splice(payload, 1);
        },
        // Las dos funciones a continuacion son solo accesibles
        // por las zonas y los destinos (excluyendo las experiencias)
        addYoutubeMedia(state, payload) {
            const { type, value } = payload;
            state[type].borrador.youtubeMedia.push(value);
        },
        deleteYoutubeMedia(state, payload) {
            const confirmar = confirm("Desea borrar el video?");
            if (!confirmar) {
                return;
            }
            const { type, borrador, index } = payload;
            if (borrador) {
                state[type].borrador.youtubeMedia.splice(index, 1);
            } else {
                state[type].youtubeMedia.splice(index, 1);
            }
        },
        changeZonas(state, payload) {
            state.zonas = payload;
        },
        updateDestinoById(state) {
            const { params } = router.history.current;
            const zonaId = state.zona.id || params.zonaId;

            if (zonaId && state.destinoId) {
                if (state.zonas.length > 0) {
                    const zona = state.zonas.find(zona => zona.id === zonaId);
                    if (!zona) {
                        alert("La zona en la que intenta ver el destino no existe");
                        return;
                    }
                    const destino = state.destinos.find(
                        destino => destino.id === state.destinoId
                    );
                    if (!destino) {
                        if (!state.delete) {
                            alert("El destino al que intenta acceder no existe");
                        }
                        return;
                    }

                    const {
                        id,
                        titulo,
                        seo_titulo,
                        slug,
                        subtitulo,
                        carouselImgUrl,
                        localidadImgUrl,
                        heroImgUrls,
                        contenidoCultura,
                        resumen,
                        experiencias
                    } = destino;

                    const {
                        culturaGastronomia,
                        verHacer,
                        cuandoVisitar,
                        dondeQuedarse,
                        comoLlegar,
                        consejos
                    } = destino.info;

                    const { latitud, longitud } = destino.localizacion;

                    state.destino.id = id;
                    state.destino.titulo = titulo;
                    state.destino.seo_titulo = seo_titulo;
                    state.destino.slug = slug;
                    state.destino.subtitulo = subtitulo;
                    state.destino.carouselImgUrl = carouselImgUrl;
                    state.destino.localidadImgUrl = localidadImgUrl;
                    state.destino.heroImgUrls = heroImgUrls;
                    state.destino.contenidoCultura = contenidoCultura;
                    state.destino.resumen = resumen;
                    state.destino.experiencias = experiencias;
                    state.destino.info.culturaGastronomia = culturaGastronomia;
                    state.destino.info.verHacer = verHacer;
                    state.destino.info.cuandoVisitar = cuandoVisitar;
                    state.destino.info.dondeQuedarse = dondeQuedarse;
                    state.destino.info.comoLlegar = comoLlegar;
                    state.destino.info.consejos = consejos;
                    state.destino.localizacion.latitud = latitud;
                    state.destino.localizacion.longitud = longitud;

                    // Reset de contenido en ingles

                    state.destino.ingles.titulo = destino.ingles.titulo;
                    state.destino.ingles.subtitulo = destino.ingles.subtitulo;
                    state.destino.ingles.resumen = destino.ingles.resumen;
                    state.destino.ingles.info.culturaGastronomia =
                        destino.ingles.info.culturaGastronomia;
                    state.destino.ingles.info.verHacer = destino.ingles.info.verHacer;
                    state.destino.ingles.info.cuandoVisitar =
                        destino.ingles.info.cuandoVisitar;
                    state.destino.ingles.info.dondeQuedarse =
                        destino.ingles.info.dondeQuedarse;
                    state.destino.ingles.info.comoLlegar = destino.ingles.info.comoLlegar;
                    state.destino.ingles.info.consejos = destino.ingles.info.consejos;

                    // Reset de youtube media
                    state.destino.youtubeMedia = destino.youtubeMedia || [];
                }
            }
        },
        updateExperienciaById(state) {
            if (state.zona.id && state.destinoId && state.experienciaId) {
                if (state.zonas.length > 0) {
                    const zona = state.zonas.find(zona => zona.id === state.zona.id);
                    if (!zona) {
                        alert("La zona en la que intenta ver la experiencia no existe");
                        return;
                    }
                    const destino = state.destinos.find(
                        destino => destino.id === state.destinoId
                    );
                    if (!destino) {
                        alert("El destino en el que intenta ver la experiencia no existe");
                        return;
                    }
                    const experiencia = state.experiencias.find(
                        experiencia => experiencia.id === state.experienciaId
                    );
                    if (!experiencia) {
                        if (!state.delete) {
                            alert("La experiencia que desea abrir no existe");
                        }
                        return;
                    }

                    Object.keys(experiencia).forEach(property => {
                        if (property === "informacionImportante") {
                            Object.keys(experiencia[property]).forEach(infoProperty => {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        state.experiencia.informacionImportante,
                                        infoProperty
                                    )
                                ) {
                                    state.experiencia.informacionImportante[infoProperty] =
                                        experiencia.informacionImportante[infoProperty];
                                }
                            });
                            return;
                        }
                        if (property === "descripcion") {
                            Object.keys(experiencia[property]).forEach(descProperty => {
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        state.experiencia.descripcion,
                                        descProperty
                                    )
                                ) {
                                    state.experiencia.descripcion[descProperty] =
                                        experiencia.descripcion[descProperty];
                                }
                            });
                            return;
                        }
                        if (property === "ingles") {
                            Object.keys(experiencia[property]).forEach(inglesProperty => {
                                if (inglesProperty === "informacionImportante") {
                                    Object.keys(experiencia.ingles[inglesProperty]).forEach(
                                        infoProperty => {
                                            if (
                                                Object.prototype.hasOwnProperty.call(
                                                    state.experiencia.ingles.informacionImportante,
                                                    infoProperty
                                                )
                                            ) {
                                                state.experiencia.ingles.informacionImportante[
                                                        infoProperty
                                                    ] =
                                                    experiencia.ingles.informacionImportante[
                                                        infoProperty
                                                    ];
                                            }
                                        }
                                    );
                                    return;
                                }
                                if (inglesProperty === "descripcion") {
                                    Object.keys(experiencia.ingles[inglesProperty]).forEach(
                                        descripcionProperty => {
                                            if (
                                                Object.prototype.hasOwnProperty.call(
                                                    state.experiencia.ingles.descripcion,
                                                    descripcionProperty
                                                )
                                            ) {
                                                state.experiencia.ingles.descripcion[
                                                    descripcionProperty
                                                ] = experiencia.ingles.descripcion[descripcionProperty];
                                            }
                                        }
                                    );
                                    return;
                                }
                                if (
                                    Object.prototype.hasOwnProperty.call(
                                        state.experiencia.ingles,
                                        inglesProperty
                                    )
                                ) {
                                    state.experiencia.ingles[inglesProperty] =
                                        experiencia.ingles[inglesProperty];
                                }
                            });
                            return;
                        }
                        if (
                            Object.prototype.hasOwnProperty.call(state.experiencia, property)
                        ) {
                            state.experiencia[property] = experiencia[property];
                        }
                    });
                }
            }
        },
        updateValue(state, { name, value, level, type }) {
            if (state.language === "es") {
                if (type !== "normal") {
                    state[level][type][name] = value;
                    return;
                }
                state[level][name] = value;
            }

            if (state.language === "en") {
                if (type !== "normal") {
                    state[level].ingles[type][name] = value;
                    return;
                }
                state[level].ingles[name] = value;
            }
        },
        changeDestinos(state, payload) {
            state.destinos = payload;
        },
        changeExperiencias(state, payload) {
            state.experiencias = payload;
        },
        setDestinoId(state, payload) {
            state.destinoId = payload;
        },
        setExperienciaId(state, payload) {
            state.experienciaId = payload;
        },
        setZonaImg(state, payload) {
            if (payload.type === "carousel") {
                state.zonaImgsToSave.carouselImg = payload.imgFile;
                state.zonaImgsToSave.carouselImgUrl = payload.imgUrl;
            } else if (payload.type === "localidad") {
                state.zonaImgsToSave.localidadImg = payload.imgFile;
                state.zonaImgsToSave.localidadImgUrl = payload.imgUrl;
            }
        },
        setZonaHeroImg(state, payload) {
            state.zonaImgsToSave.heroImgs.push(payload.imgFile);
            state.zonaImgsToSave.heroImgUrls.push(payload.imgUrl);
        },
        deleteUnsavedImg(state, payload) {
            if (payload.type === "hero") {
                state.zonaImgsToSave.heroImgUrls.splice(payload.index, 1);
                state.zonaImgsToSave.heroImgs.splice(payload.index, 1);
            } else if (payload.type === "carousel") {
                state.zonaImgsToSave.carouselImg = null;
                state.zonaImgsToSave.carouselImgUrl = null;
            } else if (payload.type === "localidad") {
                state.zonaImgsToSave.localidadImg = null;
                state.zonaImgsToSave.localidadImgUrl = null;
            }
        },
        addConsejo(state, payload) {
            if (state.language === "es") {
                state.consejosToSave.push(payload.consejo);
                return;
            }
            if (state.language === "en") {
                state[payload.level].ingles.info.consejosToSave.push(payload.consejo);
            }
        },
        borrarConsejo(state, payload) {
            if (payload.type === "borrador") {
                if (state.language === "en") {
                    const consejosArray = state[payload.level].ingles.info.consejosToSave;
                    const newArray = consejosArray.filter(
                        (consejo, index) => index !== Number(payload.index)
                    );
                    Vue.set(state[payload.level].ingles.info, "consejosToSave", newArray);
                    return;
                }
                state.consejosToSave.splice(payload.index, 1);
            } else if (payload.type === "guardados") {
                if (state.language === "en") {
                    state[payload.level].ingles.info.consejos.splice(payload.index, 1);
                    return;
                }
                state[payload.level].info.consejos.splice(payload.index, 1);
            }
        },
        addToExperienciasImperdibles(state, payload) {
            if (!state.experienciasImperdibles.experiencias.includes(payload.id)) {
                state.experienciasImperdibles.experiencias.push(payload.id);
            }
        },
        deleteFromExperienciasImperdibles(state, payload) {
            if (state.experienciasImperdibles.experiencias.includes(payload.id)) {
                const newExperiencias = state.experienciasImperdibles.experiencias.filter(
                    experienciaId => experienciaId !== payload.id
                );
                state.experienciasImperdibles.experiencias = [...newExperiencias];
            }
        },
        changeExperienciasImperdibles(state, payload) {
			state.experienciasImperdibles.id = payload.id;
			state.experienciasImperdibles.experiencias = payload.experiencias;
			state.cantidadExperienciasImperdibles = payload.experiencias.length;
        }
    },
    actions: {
        getAnimation({ state, commit }) {
            const { zonas, destinos, experiencias, experienciasImperdibles } = state;
            // Quitar animación de carga.
            /* if (
                zonas.length > 0 &&
                destinos.length > 0 &&
                experiencias.length > 0 &&
                experienciasImperdibles.experiencias.length > 0
            ) {
                commit("setAnimation");
            } */
			commit("setAnimation");
        },
        zonasQuery({ commit, state, dispatch }) {
            zonas.onSnapshot(snapshot => {
                const dataArray = [];
                snapshot.forEach(doc => {  
                    dataArray.push({...doc.data(), id: doc.id });
                });
                commit("changeZonas", dataArray);
                commit("setZonaById", {
                    id: state.zona.id,
                    zonas: state.zonas,
                    delete: state.delete
                });
                dispatch("getAnimation");
            });
        },
        destinosQuery({ commit, dispatch }) {
            destinosCollection.onSnapshot(snapshot => {
                const dataArray = [];
                snapshot.forEach(doc => {
                    dataArray.push({...doc.data(), id: doc.id });
                });
                commit("changeDestinos", dataArray);
                commit("updateDestinoById");
                dispatch("getAnimation");
            });
        },
        experienciasQuery({ commit, dispatch }) {

            experiencias.onSnapshot(snapshot => {
                const dataArray = [];
                snapshot.forEach(doc => {
                    dataArray.push({...doc.data(), id: doc.id });
                });
                commit("changeExperiencias", dataArray);
                commit("updateExperienciaById");
                dispatch("getAnimation");
            });
        },
        experienciasImperdiblesQuery({ commit, dispatch }) {
            experienciasImperdibles.onSnapshot(snapshot => {
                const dataArray = [];
                snapshot.forEach((doc, index) => {
                    dataArray.push({...doc.data(), id: doc.id });
                });
                commit("changeExperienciasImperdibles", dataArray[0]);
                dispatch("getAnimation");
            });
        },
        switchLanguage({ commit }) {
            commit("switchLanguage");
            commit("resetBorrador");
        },
        async submitExperienciaImperdible({ state, commit }) {
            try {
                if (state.experienciasImperdibles.id) {
                    const key = state.experienciasImperdibles.id;
                    await experienciasImperdibles.doc(key).update({
                        experiencias: [...state.experienciasImperdibles.experiencias]
                    });
                } else {
                    await experienciasImperdibles.add({
                        experiencias: [...state.experienciasImperdibles.experiencias]
                    });
                }
                alert("Experiencias Imperdibles almacenadas exitosamente");
            } catch (error) {
                console.log(error);
            }
        },
        async deleteFromCulturaSaved({ state }, payload) {
            const imgUrl =
                state[payload.level].contenidoCultura[payload.index].imgUrl;
            // Funcion para eliminar imagenes previamente almacenadas en storage
            if (!confirm("Estas seguro que deseas eliminar esta imagen?")) {
                return;
            }
            let reference;

            switch (payload.level) {
                case "zona":
                    reference = zonas;
                    break;
                case "destino":
                    reference = destinosCollection;
                    break;
                default:
                    break;
            }

            const imgRef = storage.refFromURL(imgUrl);

            try {
                await imgRef.delete();
                const newArray = state[payload.level].contenidoCultura.filter(
                    (contenido, index) => index !== payload.index
                );
                await reference
                    .doc(state[payload.level].id)
                    .update({ contenidoCultura: newArray });
                alert("Contenido eliminado exitosamente");
            } catch (error) {
                console.log(
                    "Error inesperado en borrar contenido de cultura y gastronomia: ",
                    error
                );
            }
        },
        setDestinoId({ commit }, payload) {
            commit("setDestinoId", payload);
            commit("updateDestinoById");
        },
        setExperienciaId({ commit }, payload) {
            commit("setExperienciaId", payload);
            commit("updateExperienciaById");
        },
        addZonaImg({ commit }, payload) {
            commit("setZonaImg", payload);
        },
        addZonaHeroImg({ commit }, payload) {
            commit("setZonaHeroImg", payload);
        },
        resetZona({ commit }, payload) {
            commit("resetZona", payload);
        },
        addImg({ state, commit }, payload) {
            const { event, level, type } = payload;
            if (state[level].carouselImgUrl) {
                alert(
                    "Debe eliminar la imagen ya registrada antes de registrar una nueva"
                );
                return;
            }
            const files = event.target.files;
            const fileReader = new FileReader();
            fileReader.addEventListener("load", () => {
                const imgUrl = fileReader.result;
                const imgFile = files[0];
                commit("setZonaImg", { imgUrl, imgFile, type });
                event.target.value = "";
            });
            fileReader.readAsDataURL(files[0]);
        },
        addHeroImg({ commit }, payload) {
            const { target } = payload;
            const file = target.files[0];
            const fileReader = new FileReader();
            fileReader.addEventListener("load", () => {
                const imgUrl = fileReader.result;
                const imgFile = file;
                commit("setZonaHeroImg", { imgUrl, imgFile });
                target.value = "";
            });
            fileReader.readAsDataURL(file);
        },
        // Funcion para eliminar las imagenes y sus thumbnails
        async deleteContentAndThumbnails(state, payload) {
            const filename = payload.name.split(".")[0]; // Obtener el nombre del archivo
            const parentFiles = await payload.parent.listAll(); // Obtener los archivos en la misma carpeta
            const thumbNailsReferences = parentFiles.items.filter(ref => {
                // Filtrar por nombre de archivo
                const isItem = ref.name.includes(filename);
                return isItem;
            });
            await Promise.all(thumbNailsReferences.map(ref => ref.delete())); // Eliminar los archivos
        },
        async deleteImg({ state, dispatch }, payload) {
            // Funcion para eliminar imagenes previamente almacenadas en storage
            if (!confirm("Estas seguro que deseas eliminar esta imagen?")) {
                return;
            }
            let reference;

            switch (payload.level) {
                case "zonas":
                    reference = zonas;
                    break;
                case "destinos":
                    reference = destinosCollection;
                    break;
                case "experiencias":
                    reference = experiencias;
                    break;
                default:
                    break;
            }

            try {
                if (payload.type === "carousel") {
                    const imgRef = storage.refFromURL(payload.url);
                    await dispatch("deleteContentAndThumbnails", imgRef);
                    await reference.doc(payload.id).update({ carouselImgUrl: null });
                    alert("Imagen eliminada exitosamente");
                } else if (payload.type === "localidad") {
                    const imgRef = storage.refFromURL(payload.url);
                    await dispatch("deleteContentAndThumbnails", imgRef);
                    await reference.doc(payload.id).update({ localidadImgUrl: null });
                    alert("Imagen eliminada exitosamente");
                } else if (payload.type === "hero") {
                    const imgRef = storage.refFromURL(payload.url);
                    await dispatch("deleteContentAndThumbnails", imgRef);
                    await reference.doc(payload.id).update({
                        heroImgUrls: firebase.firestore.FieldValue.arrayRemove(payload.url)
                    });
                    alert("Imagen eliminada exitosamente");
                }
            } catch (error) {
                console.log("Error al eliminar imagen: ", error);
            }
        },
        deleteUnsavedImg({ commit }, payload) {
            // Funcion para eliminar imagenes que aun no han sido almacenadas en storage
            commit("deleteUnsavedImg", payload);
        },
        updateValue({ commit }, { name, value, level, type }) {
            commit("updateValue", { name, value, level, type });
        },
        submitConsejo({ commit }, payload) {
            commit("addConsejo", payload);
        },
        borrarConsejo({ commit }, payload) {
            const deleteConfirm = confirm("Esta seguro que desea borrar el consejo?");
            if (deleteConfirm) {
                commit("borrarConsejo", payload);
            }
        },
        async deleteDestino({ state, commit }, payload) {
            const confirmar = confirm(
                "Esta seguro que deseas eliminar el destino y sus experiencias?"
            );
            if (!confirmar) {
                return;
            }

            const destinoElement = state.destinos.find(
                destino => destino.id === payload.id
            );
            if (!destinoElement) {
                alert("El destino que desea eliminar no existe");
                return;
            }
            const images = [];

            if (destinoElement.carouselImgUrl) {
                images.push(destinoElement.carouselImgUrl);
            }

            if (destinoElement.heroImgUrls && destinoElement.heroImgUrls.length > 0) {
                images.push(...destinoElement.heroImgUrls);
            }

            if (destinoElement.localidadImgUrl) {
                images.push(destinoElement.localidadImgUrl);
            }

            if (
                destinoElement.contenidoCultura &&
                destinoElement.contenidoCultura.length > 0
            ) {
                const culturaImages = destinoElement.contenidoCultura.map(
                    contenido => contenido.imgUrl
                );
                images.push(...culturaImages);
            }

            const experienciasArray = state.experiencias.filter(experiencia =>
                destinoElement.experiencias.includes(experiencia.id)
            );

            experienciasArray.forEach(experiencia => {
                if (experiencia.carouselImgUrl) {
                    images.push(experiencia.carouselImgUrl);
                }

                if (experiencia.heroImgUrls && experiencia.heroImgUrls.length > 0) {
                    images.push(...experiencia.heroImgUrls);
                }

                if (experiencia.localidadImgUrl) {
                    images.push(experiencia.localidadImgUrl);
                }
            });

            try {
                if (images && images.length > 0) {
                    const imagesToDelete = images.map(imgUrl =>
                        storage.refFromURL(imgUrl)
                    );
                    await Promise.all(imagesToDelete.map(imgRef => imgRef.delete()));
                }

                await Promise.all(
                    destinoElement.experiencias.map(experienciaId =>
                        experiencias.doc(experienciaId).delete()
                    )
                );
                await destinosCollection.doc(payload.id).delete();
                await zonas.doc(payload.zonaId).update({
                    destinos: firebase.firestore.FieldValue.arrayRemove(payload.id)
                });
                router.push({ path: "/admin" });
                commit("resetBorrador");
                alert("Destino eliminado exitosamente");
            } catch (error) {
                commit("resetBorrador");
                console.log("Error al borrar destino: ", error);
            }
        },
        async submitDestino({ state, commit }, payload) {
            const zona = state.zonas.find(zona => zona.id === payload.zonaId);
            if (!zona) {
                alert("La zona que intenta actualizar no existe");
                router.push({ path: "/admin" });
                return;
            }

            const destino = zona.destinos.find(
                (destino, index) => index === Number(payload.destinoId)
            );

            if (payload.action === "update" && payload.destinoId) {
                if (!destino) {
                    alert("El destino que intenta actualizar no existe");
                    return;
                }
            }

            // Deconstructing del estado para preparar el update/create
            const {
                titulo,
                seo_titulo,
                slug,
                subtitulo,
                resumen,
                experiencias,
                contenidoCultura,
                youtubeMedia
            } = state.destino;

            const {
                culturaGastronomia,
                verHacer,
                cuandoVisitar,
                dondeQuedarse,
                comoLlegar
            } = state.destino.info;

            const { latitud, longitud } = state.destino.localizacion;

            // Preparacion de update
            const updates = {
                titulo,
                seo_titulo,
                slug,
                subtitulo,
                resumen,
                experiencias,
                contenidoCultura,
                youtubeMedia: [...youtubeMedia, ...state.destino.borrador.youtubeMedia],
                localizacion: {
                    latitud,
                    longitud
                },
                info: {
                    culturaGastronomia,
                    verHacer,
                    cuandoVisitar,
                    dondeQuedarse,
                    comoLlegar,
                    consejos: [...state.destino.info.consejos, ...state.consejosToSave]
                },
                ingles: {
                    titulo: state.destino.ingles.titulo,
                    seo_titulo: state.destino.ingles.seo_titulo,
                    subtitulo: state.destino.ingles.subtitulo,
                    resumen: state.destino.ingles.resumen,
                    info: {
                        culturaGastronomia: state.destino.ingles.info.culturaGastronomia,
                        verHacer: state.destino.ingles.info.verHacer,
                        cuandoVisitar: state.destino.ingles.info.cuandoVisitar,
                        dondeQuedarse: state.destino.ingles.info.dondeQuedarse,
                        comoLlegar: state.destino.ingles.info.comoLlegar,
                        consejos: [
                            ...state.destino.ingles.info.consejos,
                            ...state.destino.ingles.info.consejosToSave
                        ]
                    }
                }
            };

            let key;

            if (payload.action === "create") {
                const createTask = await destinosCollection.add(updates);
                key = createTask.id;
            } else {
                key = payload.id;
            }

            const storageUpdateConfig = storageHandler(
                storage,
                state, { zonaId: payload.zonaId, destinoId: key },
                "destino"
            );
            const storageDownloadURLs = await storageURLs(
                storageUpdateConfig,
                state.contenidoCulturaBorrador
            );
            const { storageUpdateAvailable, storageUpdates } = storageDownloadURLs;
            if (storageUpdateAvailable) {
                if (payload.action === "create") {
                    await destinosCollection.doc(key).update(storageUpdates);
                    await zonas.doc(payload.zonaId).update({
                        destinos: firebase.firestore.FieldValue.arrayUnion(key)
                    });
                } else if (payload.action === "update") {
                    await destinosCollection.doc(key).update(updates);
                    await destinosCollection.doc(key).update(storageUpdates);
                }
                router.push({ path: "/admin" });
                alert(
                    `Operacion de destino ${state.destino.titulo} realizada exitosamente`
                );
                commit("resetBorrador");
            } else {
                if (payload.action === "create") {
                    await destinosCollection.doc(key).update(updates);
                    await zonas.doc(payload.zonaId).update({
                        destinos: firebase.firestore.FieldValue.arrayUnion(key)
                    });
                } else if (payload.action === "update") {
                    await destinosCollection.doc(key).update(updates);
                    await destinosCollection.doc(key).update(storageUpdates);
                }
                router.push({ path: "/admin" });
                alert(
                    `Operacion de destino ${state.destino.titulo} realizada exitosamente`
                );
                commit("resetBorrador");
            }
        }
    }
});