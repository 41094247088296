<template>
  <div class="edit-experiencia" v-if="experiencia">
    <div class="header">
      <div class="container container--flex-nav">
        <button
          class="edit-zona__btn edit-zona__btn--nav"
          @click="navigateFromExperiencia"
        >
          <i class="fas fa-arrow-alt-circle-left"></i>Ir a destino
        </button>
        <div class="buttons">
          <!-- Switch para ingles / castellano -->
          <button
            v-if="language === 'es'"
            type="button"
            class="edit-zona__btn edit-zona__btn--primary"
            style="margin-right: 50px"
            @click="switchLanguage"
          >
            Agregar contenido en ingles
          </button>
          <button
            v-else
            type="button"
            class="edit-zona__btn edit-zona__btn--success"
            style="margin-right: 50px"
            @click="switchLanguage"
          >
            Volver al contenido en español
          </button>
          <!--  -->
          <div class="edit-zona__loading-button">
            <button
              v-if="id"
              type="button"
              class="edit-zona__btn edit-zona__btn--warning"
              @click="deleteRequest({ id, destinoId })"
              :disabled="isLoading"
            >
              Eliminar experiencia
            </button>
            <div v-if="isLoading" class="lds-dual-ring"></div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="heading heading--1 heading--center">
      Gestion de experiencia {{ language === "en" ? "(Ingles)" : "" }}
    </h1>
    <h3 v-if="experiencia.titulo" class="heading heading--1 heading--center">
      {{ experiencia.titulo }}
    </h3>
    <form
      class="edit-experiencia__form-container"
      @submit.enter.prevent="
        id
          ? submitRequest({ action: 'update', zonaId, destinoId, id })
          : submitRequest({ action: 'create', zonaId, destinoId })
      "
    >
      <div
        class="edit-experiencia__informacion edit-experiencia__informacion--1"
      >
        <h3 class="heading heading--3 heading--center">
          Informacion principal
        </h3>
        <div
          class="edit-experiencia__container edit-experiencia__container--flex"
        >
          <div class="edit-experiencia__side edit-experiencia__side--left">
            <!-- Informacion principal | titulo -->
            <div class="edit-zona__input-group">
              <label class="edit-experiencia__label">Titulo</label>
              <input
                class="edit-zona__input"
                type="text"
                name="titulo"
                :value="
                  language === 'es'
                    ? experiencia.titulo
                    : experiencia.ingles.titulo
                "
                @input="handleChange"
              />
            </div>

            <!-- Informacion principal | titulo de destino -->
            <div class="edit-zona__input-group">
              <label class="edit-experiencia__label">Titulo de destino</label>
              <input
                class="edit-zona__input"
                type="text"
                name="tituloDestino"
                :value="
                  language === 'es'
                    ? experiencia.tituloDestino
                    : experiencia.ingles.tituloDestino
                "
                @input="handleChange"
              />
            </div>

            <div class="edit-zona__input-group">
              <label class="edit-experiencia__label">Resumen principal</label>
              <textarea
                class="edit-zona__textarea"
                name="resumen"
                id="resumen"
                cols="30"
                rows="10"
                :value="
                  language === 'es'
                    ? experiencia.resumen
                    : experiencia.ingles.resumen
                "
                @input="handleChange"
              ></textarea>
            </div>

            <hr>

            <h3 class="heading heading--3 heading--center mt-4">Configuración SEO</h3>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Titulo:</label>
              <input
                class="edit-zona__input"
                type="text"
                name="seo_titulo"
                id="seo_titulo"
                :value="(language === 'es') ? experiencia.seo_titulo : experiencia.ingles.seo_titulo"
                @input="handleChange">
            </div>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Slug</label>
              <input
                class="edit-zona__input"
                type="text"
                name="slug"
                id="slug"
                :value="experiencia.slug"
                @input="handleChange">
            </div>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Etiquetas</label>
              <input
                class="edit-zona__input"
                type="text"
                name="tag"
                id="tag"
                v-model="tag"
                >
                <button class="edit-zona__btn edit-zona__btn--primary" type="button" @click="addTag()">Agregar etiqueta</button>
            </div>
            <table class="table table-striped">
              <thead style="background-color: #fff;">
                <tr>
                  <th scope="col">Etiqueta</th>
                  <th scope="col">Acciones</th>
                </tr>
              </thead>
              <tbody style="background-color: #fff;">
                <tr v-for="(tag, index) in language === 'es'
                  ? experiencia.tags
                  : experiencia.ingles.tags" :key="index">
                  <td>{{ tag }}</td>
                  <td>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="borrarItem({
                        borrador: false,
                        name: 'tags',
                        index,
                      })"
                    >
                      X
                    </button>
                  </td>
                </tr>
                <tr v-for="(tag, index) in language === 'es'
                  ? experiencia.experienciaBorrador.tags
                  : experiencia.ingles.experienciaBorrador.tags" :key="index">
                  <td>{{ tag }}</td>
                  <td>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="borrarItem({
                        borrador: true,
                        name: 'tags',
                        index,
                      })"
                    >
                      X
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            {{experiencia.tags}}
            {{experiencia.ingles.tags}}
            {{experiencia.experienciaBorrador.tags}}
          </div>
          <div
            v-if="language === 'es'"
            class="edit-experiencia__side edit-experiencia__side--right"
          >
            <div class="edit-zona__input-group">
              <!-- Informacion principal | Imagen de slideshow -->
              <label class="edit-experiencia__label"
                >Imagen de previsualizacion</label
              >

              <input
                type="file"
                name="carousel-img"
                accept="image/*"
                @change="
                  addImg({
                    event: $event,
                    type: 'carousel',
                    level: 'experiencia',
                  })
                "
              />

              <a
                href
                v-if="
                  experiencia.carouselImgUrl || zonaImgsToSave.carouselImgUrl
                "
                @click.prevent="
                  experiencia.carouselImgUrl
                    ? deleteImg({
                        type: 'carousel',
                        level: 'experiencias',
                        url: experiencia.carouselImgUrl,
                        id: id,
                      })
                    : deleteUnsavedImg({ type: 'carousel' })
                "
                class="edit-experiencia__img-link"
              >
                <img
                  :src="
                    experiencia.carouselImgUrl
                      ? experiencia.carouselImgUrl
                      : zonaImgsToSave.carouselImgUrl
                  "
                  alt="heading-img"
                  class="edit-experiencia__img"
                />
              </a>
            </div>

            <div class="edit-zona__input-group">
              <!-- Informacion principal | imagenes de hero -->
              <label class="edit-experiencia__label"
                >Imagenes para el hero</label
              >
              <input
                type="file"
                accept="image/*, .mp4"
                name="hero-imgs"
                @change="addHeroImg($event)"
              />

              <div class="edit-experiencia__img-container">
                <a
                  href
                  class="edit-experiencia__img-container"
                  v-for="(item, index) in getHeroContentBorrador"
                  :key="index"
                  @click.prevent="deleteUnsavedImg({ type: 'hero', index })"
                >
                  <template v-if="item.type === 'image'">
                    <img
                      :src="item.contentUrl"
                      alt="hero-images"
                      class="edit-experiencia__img"
                    />
                  </template>
                  <template v-else>
                    <video
                      autoplay
                      muted
                      loop
                      class="edit-experiencia__img edit-experiencia__img--1"
                    >
                      <source :src="item.contentUrl" />
                    </video>
                  </template>
                </a>
              </div>

              <div class="edit-experiencia__img-container">
                <a
                  href
                  class="edit-experiencia__img-container"
                  v-for="(item, index) in getHeroContent"
                  :key="index"
                  @click.prevent="
                    deleteImg({
                      type: 'hero',
                      level: 'experiencias',
                      url: item.contentUrl,
                      id: id,
                    })
                  "
                >
                  <template v-if="item.type !== 'mp4'">
                    <img
                      :src="item.contentUrl"
                      alt="hero-images"
                      class="edit-experiencia__img"
                    />
                  </template>
                  <template v-else>
                    <video
                      autoplay
                      muted
                      loop
                      class="edit-experiencia__img edit-experiencia__img--1"
                    >
                      <source :src="item.contentUrl" />
                    </video>
                  </template>
                </a>
              </div>
            </div>

            <!-- Informacion principal | Contenido de youtube -->
            <div class="edit-experiencia__informacion-importante">
              <h5 class="heading heading--5">Contenido de Youtube</h5>
              <div
                class="edit-zona__container--fluid"
                v-if="experiencia.youtubeMedia.length > 0"
              >
                <ul class="edit-zona__list edit-zona__list--1">
                  <li
                    class="edit-zona__list-item"
                    v-for="(item, index) in experiencia.youtubeMedia"
                    :key="index"
                  >
                    <div class="edit-zona__list-content">
                      <div class="edit-zona__container--fluid">
                        <iframe
                          class="edit-zona__youtube-iframe"
                          :src="item"
                          frameborder="0"
                        ></iframe>
                      </div>
                      <div class="edit-zona__buttons edit-zona__buttons--right">
                        <button
                          type="button"
                          @click="
                            borrarItem({
                              borrador: false,
                              name: 'youtubeMedia',
                              index,
                            })
                          "
                          class="
                            edit-zona__btn
                            edit-zona__btn--warning
                            edit-zona__btn--consejos
                          "
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Gestion de informacion importante | items de la seccion youtubeMedia agregados al borrador -->
              <div
                class="edit-zona__container--fluid"
                v-if="experiencia.experienciaBorrador.youtubeMedia.length > 0"
              >
                <label class="edit-experiencia__label"
                  >Contenido por agregar</label
                >

                <ul class="edit-zona__list edit-zona__list--1">
                  <li
                    class="edit-zona__list-item"
                    v-for="(item, index) in experiencia.experienciaBorrador
                      .youtubeMedia"
                    :key="index"
                  >
                    <div class="edit-zona__list-content">
                      <a :href="item">Ir al video</a>
                      <div class="edit-zona__container">
                        <iframe
                          class="edit-zona__youtube-iframe"
                          :src="item"
                          frameborder="0"
                        ></iframe>
                      </div>
                      <div class="edit-zona__buttons edit-zona__buttons--right">
                        <button
                          type="button"
                          @click="
                            borrarItem({
                              borrador: true,
                              name: 'youtubeMedia',
                              index,
                            })
                          "
                          class="
                            edit-zona__btn
                            edit-zona__btn--warning
                            edit-zona__btn--consejos
                          "
                        >
                          Eliminar
                        </button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Gestion de informacion importante | input que se mostrara si se desea agregar un item en 'Incluye'-->
              <div class="edit-zona__input-group" v-if="addYoutubeMedia">
                <label class="edit-zona__label">Item</label>
                <input
                  v-model="youtubeMedia"
                  type="text"
                  class="edit-zona__input"
                />
              </div>
              <!--  -->
              <button
                v-if="!addYoutubeMedia"
                type="button"
                name="youtubeMedia"
                @click="addItem"
                class="edit-experiencia__btn edit-experiencia__btn--primary"
              >
                Registrar nuevo item
              </button>
              <button
                v-else
                type="button"
                name="youtubeMedia"
                @click="addItem"
                class="edit-experiencia__btn edit-experiencia__btn--success"
              >
                Agregar
              </button>
            </div>

            <!--  -->
          </div>
        </div>
      </div>
      <div
        class="edit-experiencia__informacion edit-experiencia__informacion--2"
      >
        <h3 class="heading heading--3 heading--center">Experiencia</h3>

        <div class="edit-experiencia__container">
          <div class="edit-experiencia__destacado">
            <!-- Gestion de destacados, aqui se muestran los que ya estan en base de datos -->
            <div
              class="edit-zona__input-group"
              v-for="(dia, index) in language === 'es'
                ? experiencia.destacado
                : experiencia.ingles.destacado"
              :key="index"
            >
              <label class="edit-experiencia__label"
                >Destacado Nro {{ index + 1 }}</label
              >
              <textarea
                class="edit-zona__textarea"
                name="destacado"
                id="destacado"
                cols="30"
                rows="3"
                :value="dia"
                disabled
              ></textarea>

              <div class="edit-zona__buttons edit-zona__buttons--right">
                <button
                  class="edit-zona__btn edit-zona__btn--warning"
                  type="button"
                  @click="
                    borrarItem({
                      borrador: false,
                      name: 'destacado',
                      index,
                    })
                  "
                >
                  Eliminar
                </button>
              </div>
            </div>
            <!-- Gestion de destacados, aqui se muestran los que estan agregados en borrador -->
            <div
              v-if="
                (language === 'es' &&
                  experienciaBorrador.destacado.length > 0) ||
                (language === 'en' &&
                  experiencia.ingles.experienciaBorrador.destacado.length > 0)
              "
            >
              <h4 class="heading heading--4 heading--center">
                Destacados por agregar
              </h4>

              <div
                class="edit-zona__input-group"
                v-for="(destacado, index) in language === 'es'
                  ? experienciaBorrador.destacado
                  : experiencia.ingles.experienciaBorrador.destacado"
                :key="index"
              >
                <label class="edit-experiencia__label"
                  >Destacado Nro {{ index + 1 }}</label
                >
                <textarea
                  class="edit-zona__textarea"
                  name="destacado"
                  id="destacado"
                  cols="30"
                  rows="3"
                  :value="destacado"
                  disabled
                ></textarea>

                <div class="edit-zona__buttons edit-zona__buttons--right">
                  <button
                    class="edit-zona__btn edit-zona__btn--warning"
                    type="button"
                    @click="
                      borrarItem({
                        borrador: true,
                        name: 'destacado',
                        index,
                      })
                    "
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </div>
            <!-- Gestion de destacados, Boton e input para agregar un nuevo destacado a borrador -->
            <div v-if="addDestacado" class="edit-zona__input-group">
              <label class="edit-experiencia__label">Nuevo destacado</label>
              <textarea
                class="edit-zona__textarea"
                name="destacado"
                id="destacado"
                cols="30"
                rows="3"
                v-model="destacado"
              ></textarea>
            </div>

            <button
              v-if="!addDestacado"
              type="button"
              name="destacado"
              @click="addItem"
              class="edit-experiencia__btn edit-experiencia__btn--primary"
            >
              Agregar nuevo destacado
            </button>

            <button
              v-else
              type="button"
              name="destacado"
              @click="addItem"
              class="edit-experiencia__btn edit-experiencia__btn--success"
            >
              Agregar
            </button>
          </div>
          <!-- Fin de destacado -->
        </div>
      </div>

      <div
        v-if="language === 'es'"
        class="edit-experiencia__informacion edit-experiencia__informacion--5"
      >
        <h3 class="heading heading--3 heading--center">Disponibilidades</h3>

        <div class="edit-experiencia__container">
          <div class="edit-experiencia__disponibilidad">
            <div
              class="
                edit-experiencia__container edit-experiencia__container--flex
              "
            >
              <div class="edit-experiencia__side edit-experiencia__side--left">
                <!-- Gestion de disponibilidades | Cantidad de dias -->
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label"
                    >Cantidad de dias</label
                  >
                  <input
                    class="edit-zona__input"
                    type="number"
                    name="cantidadDias"
                    :value="experiencia.cantidadDias"
                    :disabled="experiencia.modalidad === 'fechaExclusiva'"
                    @change="handleChange"
                  />
                </div>

                <!-- Gestion de disponibilidades | Dias de anticipacion -->
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label"
                    >Dias de anticipacion</label
                  >
                  <input
                    class="edit-zona__input"
                    type="number"
                    name="diasAnticipacion"
                    :value="experiencia.diasAnticipacion"
                    @change="handleChange"
                  />
                </div>

                <!-- Gestion de disponibilidades | Seleccionar el modo de registro de disponibilidades -->
                <div class="edit-zona__input-group">
                  <label class="edit-zona__label">Registro de horas</label>
                  <select
                    :value="experiencia.modalidadHoras"
                    class="edit-experiencia__select"
                    name="modalidadHoras"
                    @change="handleChange"
                  >
                    <option value="unica">Hora de inicio unica</option>
                    <option value="multiple">Multiples horas de inicio</option>
                  </select>
                </div>

                <!-- Gestion de disponibilidades | Hora de inicio -->
                <template v-if="experiencia.modalidadHoras === 'unica'">
                  <div class="edit-experiencia__horas-unicas">
                    <div class="edit-zona__hora-registro" style="width: 45%">
                      <label class="edit-experiencia__label"
                        >Hora de inicio</label
                      >
                      <input
                        class="edit-zona__input"
                        type="time"
                        name="horaInicio"
                        :value="experiencia.horaInicio"
                        @change="handleChange"
                      />
                    </div>

                    <div class="edit-zona__hora-registro" style="width: 45%">
                      <label class="edit-experiencia__label">Hora de fin</label>
                      <input
                        class="edit-zona__input"
                        type="time"
                        name="horaFin"
                        :value="experiencia.horaFin"
                        @change="handleChange"
                      />
                    </div>
                  </div>
                </template>

                <!-- Gestion de disponibilidades | Multiples horas de inicio -->
                <template v-if="experiencia.modalidadHoras === 'multiple'">
                  <template
                    v-if="
                      experiencia.rangosHoras &&
                      experiencia.rangosHoras.length > 0
                    "
                  >
                    <h5>Horas almacenadas</h5>
                    <div
                      v-for="(hora, index) in experiencia.rangosHoras"
                      :key="index"
                    >
                      <div class="edit-experiencia__horas-unicas">
                        <div
                          class="edit-experiencia__hora-registro"
                          style="width: 45%"
                        >
                          <label class="edit-experiencia__label"
                            >Hora de inicio</label
                          >
                          <input
                            class="edit-zona__input"
                            type="time"
                            name="rangosHoras"
                            :value="hora.start"
                            disabled
                            @change="handleChange"
                          />
                        </div>

                        <div
                          class="edit-experiencia__hora-registro"
                          style="width: 45%"
                        >
                          <label class="edit-experiencia__label"
                            >Hora de fin</label
                          >
                          <input
                            class="edit-zona__input"
                            type="time"
                            name="rangosHoras"
                            :value="hora.end"
                            disabled
                            @change="handleChange"
                          />
                        </div>
                      </div>

                      <button
                        type="button"
                        class="edit-zona__btn edit-zona__btn--warning"
                        @click="
                          borrarItem({
                            borrador: false,
                            name: 'rangosHoras',
                            index,
                          })
                        "
                      >
                        Eliminar
                      </button>
                    </div>
                  </template>

                  <template
                    v-if="
                      experiencia.experienciaBorrador.rangosHoras &&
                      experiencia.experienciaBorrador.rangosHoras.length > 0
                    "
                  >
                    <!-- Horas de inicio | Horas en borrador -->
                    <h5>Horas por almacenar</h5>
                    <div
                      v-for="(hora, index) in experiencia.experienciaBorrador
                        .rangosHoras"
                      :key="`borrador` + index"
                    >
                      <div class="edit-experiencia__horas-unicas">
                        <div class="edit-zona__hora-registro">
                          <label class="edit-experiencia__label"
                            >Hora de inicio</label
                          >
                          <input
                            class="edit-zona__input"
                            type="time"
                            name="rangosHoras"
                            :value="hora.start"
                            disabled
                            @change="handleChange"
                          />
                        </div>
                        <div class="edit-zona__hora-registro">
                          <label class="edit-experiencia__label"
                            >Hora de fin</label
                          >
                          <input
                            class="edit-zona__input"
                            type="time"
                            name="rangosHoras"
                            :value="hora.end"
                            disabled
                            @change="handleChange"
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        class="edit-zona__btn edit-zona__btn--warning"
                        @click="
                          borrarItem({
                            borrador: true,
                            name: 'rangosHoras',
                            index,
                          })
                        "
                      >
                        Eliminar
                      </button>
                    </div>
                  </template>

                  <!-- Horas de inicio | introducir nuevas horas -->
                  <div class="edit-experiencia__horas-unicas">
                    <div
                      class="edit-experiencia__hora-registro"
                      style="width: 45%"
                    >
                      <label class="edit-experiencia__label"
                        >Hora de inicio</label
                      >
                      <input
                        class="edit-zona__input"
                        type="time"
                        name="rangosHoras"
                        v-model="rangoHorasItem.start"
                      />
                    </div>
                    <div
                      class="edit-experiencia__hora-registro"
                      style="width: 45%"
                    >
                      <label class="edit-experiencia__label">Hora de fin</label>
                      <input
                        class="edit-zona__input"
                        type="time"
                        name="rangosHoras"
                        v-model="rangoHorasItem.end"
                      />
                    </div>
                  </div>

                  <button
                    type="button"
                    class="edit-zona__btn edit-zona__btn--primary"
                    name="rangosHoras"
                    @click="addItem"
                  >
                    Agregar hora
                  </button>
                </template>

                <!-- Gestion de disponibilidades | Cantidad de horas -->
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label"
                    >Cantidad de horas</label
                  >
                  <input
                    class="edit-zona__input"
                    type="text"
                    :value="duracion"
                    :disabled="experiencia.cantidadDias > 1"
                    @change="handleChange"
                  />
                  <p class="paragraph" v-if="!(experiencia.cantidadDias > 1)">
                    Si la cantidad es cero la experiencia tendra una duracion de
                    24 horas
                  </p>
                </div>

                <!-- Gestion de disponibilidades | Seleccionar el modo de registro de disponibilidades -->
                <div class="edit-zona__input-group">
                  <label>Modalidad de la experiencia:</label>
                  <select
                    :value="experiencia.modalidadViaje"
                    class="edit-experiencia__select"
                    name="modalidadViaje"
                    @change="handleChange"
                  >
                    <option disabled value>Seleccione un elemento</option>
                    <option value="individual">Individual</option>
                    <option value="small">Grupo pequeno</option>
                    <option value="medium">Grupo mediano</option>
                    <option value="big">Grupo grande</option>
                  </select>
                </div>

                <!-- Gestion de disponibilidades | Seleccionar el modo de registro de disponibilidades -->
                <div class="edit-zona__input-group">
                  <label>Gestionar disponibilidad por:</label>
                  <select
                    :value="experiencia.modalidad"
                    class="edit-experiencia__select"
                    name="modalidad"
                    @change="handleChange"
                  >
                    <option disabled value>Seleccione un elemento</option>
                    <option value="fechaExclusiva">Fecha Exclusiva</option>
                    <option value="rangos">Rangos de fechas</option>
                    <option value="rangosTemporada">Rangos de temporada</option>
                    <option value="diasSemana">Dias de semana</option>
                  </select>
                </div>

                <div class="edit-zona__registro-dias">
                  <!-- Gestion de disponinilidades | Opcion de varios rangos -->
                  <div
                    v-if="
                      experiencia.modalidad === 'rangos' ||
                      experiencia.modalidad === 'rangosTemporada' ||
                      experiencia.modalidad === 'diasSemana'
                    "
                    class="
                      edit-experiencia__section
                      edit-experiencia__section--rangos
                    "
                  >
                    <div
                      class="edit-zona__container--fluid"
                      v-if="experiencia.disponibilidades.length > 0"
                    >
                      <label class="edit-zona__label"
                        >Disponibilidades almacenadas</label
                      >

                      <div
                        class="edit-experiencia__card"
                        v-for="(disponibilidadItem, index) in disponibilidades"
                        :key="index"
                      >
                        <label>Fechas</label>
                        <ul
                          class="
                            edit-experiencia__list edit-experiencia__list--1
                          "
                        >
                          <li class="edit-experiencia__list-item" type="button">
                            Desde:
                            {{ disponibilidadItem.start | toReadableDate }}
                          </li>
                          <li class="edit-experiencia__list-item" type="button">
                            Hasta :
                            {{ disponibilidadItem.end | toReadableDate }}
                          </li>
                        </ul>

                        <button
                          class="
                            edit-experiencia__btn edit-experiencia__btn--warning
                          "
                          type="button"
                          @click="
                            borrarItem({
                              borrador: false,
                              name: 'disponibilidades',
                              index,
                            })
                          "
                        >
                          Eliminar disponibilidad
                        </button>
                      </div>
                    </div>

                    <!-- Gestion de disponibilidades | Fechas de disponibilidad almacenadas en borrador -->
                    <div
                      class="edit-zona__container--fluid"
                      v-if="disponibilidadesBorrador.length > 0"
                    >
                      <label class="edit-zona__label"
                        >Disponibilidades a agregar</label
                      >
                      <div
                        class="edit-experiencia__card"
                        v-for="(
                          disponibilidadItem, index
                        ) in disponibilidadesBorrador"
                        :key="index"
                      >
                        <div
                          class="edit-zona__container--fluid"
                          v-if="disponibilidadItem"
                        >
                          <label class="edit-experiencia__label"
                            >Disponibilidad {{ index + 1 }}</label
                          >
                          <ul
                            class="
                              edit-experiencia__list edit-experiencia__list--1
                            "
                          >
                            <li class="edit-experiencia__list-item">
                              Desde:
                              {{ disponibilidadItem.start | toReadableDate }}
                            </li>
                          </ul>
                          <ul
                            class="
                              edit-experiencia__list edit-experiencia__list--1
                            "
                          >
                            <li class="edit-experiencia__list-item">
                              Hasta:
                              {{ disponibilidadItem.end | toReadableDate }}
                            </li>
                          </ul>
                        </div>
                        <button
                          class="
                            edit-experiencia__btn edit-experiencia__btn--warning
                          "
                          type="button"
                          @click="
                            borrarItem({
                              borrador: true,
                              name: 'disponibilidades',
                              index,
                            })
                          "
                        >
                          Eliminar disponibilidad
                        </button>
                      </div>
                    </div>

                    <div class="edit-experiencia__disponibilidades">
                      <div class="edit-zona__container--fluid">
                        <label class="edit-experiencia__label"
                          >Fechas a agregar</label
                        >
                        <ul
                          class="
                            edit-experiencia__list edit-experiencia__list--1
                          "
                        >
                          <li
                            v-if="!range || !range.start || !range.end"
                            class="edit-experiencia__list-item"
                          >
                            <p class="edit-experiencia__paragraph">
                              No hay fecha registrada
                            </p>
                          </li>
                          <li
                            v-if="range && range.start && range.end"
                            class="edit-experiencia__list-item"
                            type="button"
                          >
                            Desde: {{ range.start | toReadableDate }}
                          </li>
                          <li
                            v-if="range && range.start && range.end"
                            class="edit-experiencia__list-item"
                            type="button"
                          >
                            Hasta: {{ range.end | toReadableDate }}
                          </li>
                        </ul>
                      </div>

                      <div class="edit-zona__input-group">
                        <label class="edit-experiencia__label">Fecha</label>
                        <vc-date-picker
                          mode="range"
                          :value="range"
                          :min-date="new Date()"
                          is-inline
                          is-range
                          @input="handleDate"
                        ></vc-date-picker>
                      </div>
                      <button
                        class="
                          edit-experiencia__btn edit-experiencia__btn--primary
                        "
                        name="disponibilidades"
                        type="button"
                        style="width: 100%"
                        :disabled="!range || !range.start || !range.end"
                        @click="addItem"
                      >
                        Guardar disponibilidad
                      </button>
                    </div>
                  </div>

                  <!-- Gestion de disponibilidades | inputs checkbox para dias soportados -->
                  <div
                    v-if="experiencia.modalidad === 'diasSemana'"
                    class="edit-zona__input-group"
                  >
                    <h5 class="heading heading--5">
                      Dias de semana disponibles
                    </h5>
                    <ul class="edit-experiencia__idioma-options">
                      <li class="idioma-option">
                        <input
                          type="checkbox"
                          class="edit-experiencia__checkbox"
                          :value="1"
                          v-model="diasSemana"
                        />Lunes
                      </li>

                      <li class="idioma-option">
                        <input
                          type="checkbox"
                          class="edit-experiencia__checkbox"
                          :value="2"
                          v-model="diasSemana"
                        />Martes
                      </li>

                      <li class="idioma-option">
                        <input
                          type="checkbox"
                          class="edit-experiencia__checkbox"
                          :value="3"
                          v-model="diasSemana"
                        />Miercoles
                      </li>

                      <li class="idioma-option">
                        <input
                          type="checkbox"
                          class="edit-experiencia__checkbox"
                          :value="4"
                          v-model="diasSemana"
                        />Jueves
                      </li>

                      <li class="idioma-option">
                        <input
                          type="checkbox"
                          class="edit-experiencia__checkbox"
                          :value="5"
                          v-model="diasSemana"
                        />Viernes
                      </li>

                      <li class="idioma-option">
                        <input
                          type="checkbox"
                          class="edit-experiencia__checkbox"
                          :value="6"
                          v-model="diasSemana"
                        />Sabado
                      </li>
                      <li class="idioma-option">
                        <input
                          type="checkbox"
                          class="edit-experiencia__checkbox"
                          :value="0"
                          v-model="diasSemana"
                        />Domingo
                      </li>
                    </ul>
                  </div>

                  <!-- Gestion de disponibilidades | Modalidad Fecha Exclusiva -->
                  <div
                    v-if="experiencia.modalidad === 'fechaExclusiva'"
                    class="edit-zona__input-group edit-zona__fecha-exclusiva"
                  >
                    <h3 class="heading heading--3">Fecha Exclusiva</h3>
                    <!-- Gestion de disponibilidades | gestionador de fechas -->
                    <div class="edit-zona__input-group">
                      <input
                        name="fechaExclusiva"
                        :value="experiencia.fechaExclusiva"
                        type="datetime-local"
                        @change="handleChange"
                      />
                    </div>
                    <div
                      v-if="experiencia.fechaExclusiva"
                      class="edit-zona__input-group"
                    >
                      <h5>{{ experiencia.fechaExclusiva | toReadableDate }}</h5>
                      <button type="button" @click="resetFechaExclusiva">
                        Eliminar
                      </button>
                    </div>
                  </div>
                </div>
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label"
                    >Url para google maps</label
                  >
                  <input
                    class="edit-zona__input"
                    type="text"
                    name="googleMapsUrl"
                    :value="experiencia.googleMapsUrl"
                    @input="handleChange"
                  />
                </div>
              </div>
              <div class="edit-experiencia__side edit-experiencia__side--right">
                <!-- Gestion de disponibilidades | input para Stock -->
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label">Prioridad de experiencia destacadas</label>
                  <input
                    class="edit-zona__input"
                    type="number"
                    name="stock"
                    id="titulo"
                    :value="experiencia.stock"
                    @change="handleChange"
                  />
                </div>
                <!-- Gestion de disponibilidades | input para cantidad de visitas -->
                <!-- <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label">Visitas</label>
                  <input
                    class="edit-zona__input"
                    type="number"
                    name="visitas"
                    id="subtitulo"
                    :value="experiencia.visitas"
                    @change="handleChange"
                  />
                </div> -->
                <!-- Gestion de disponibilidades | input para puntaje -->
                <!-- <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label">Puntaje</label>
                  <input
                    class="edit-zona__input"
                    type="number"
                    name="puntaje"
                    :value="experiencia.puntaje"
                    @change="handleChange"
                  />
                </div> -->

                <!-- Gestion de disponibilidades | input para capacidad -->
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label"
                    >Capacidad minima de viajeros</label
                  >
                  <input
                    class="edit-zona__input"
                    type="number"
                    name="capacidadMinima"
                    :value="experiencia.capacidadMinima"
                    @change="handleChange"
                  />
                </div>
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label"
                    >Capacidad maxima de viajeros</label
                  >
                  <input
                    class="edit-zona__input"
                    type="number"
                    name="capacidad"
                    :value="experiencia.capacidad"
                    @change="handleChange"
                  />
                </div>
                <!-- Gestion de disponibilidades | inputs checkbox para idiomas soportados -->
                <div class="edit-zona__input-group">
                  <h5 class="heading heading--5">Idiomas disponibles</h5>
                  <ul class="edit-experiencia__idioma-options">
                    <li class="idioma-option">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="Inglés"
                        v-model="idiomas"
                      />Inglés
                    </li>
                    <li class="idioma-option oferta" v-if="experiencia.oferta">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="InglésOferta"
                        v-model="idiomas"
                      />Inglés Oferta
                    </li>
                    <li class="idioma-option">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="Español"
                        v-model="idiomas"
                      />Español
                    </li>
                    <li class="idioma-option oferta" v-if="experiencia.oferta">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="EspañolOferta"
                        v-model="idiomas"
                      />Español Oferta
                    </li>
                    <li class="idioma-option">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="Alemán"
                        v-model="idiomas"
                      />Alemán
                    </li>
                    <li class="idioma-option oferta" v-if="experiencia.oferta">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="AlemánOferta"
                        v-model="idiomas"
                      />Alemán Oferta
                    </li>
                    <li class="idioma-option">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="Francés"
                        v-model="idiomas"
                      />Francés
                    </li>
                    <li class="idioma-option oferta" v-if="experiencia.oferta">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="FrancésOferta"
                        v-model="idiomas"
                      />Francés Oferta
                    </li>
                    <li class="idioma-option">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="Portugués"
                        v-model="idiomas"
                      />Portugués
                    </li>
                    <li class="idioma-option oferta" v-if="experiencia.oferta">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="PortuguésOferta"
                        v-model="idiomas"
                      />Portugués Oferta
                    </li>
                    <li class="idioma-option">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="Mapudungún"
                        v-model="idiomas"
                      />Mapudungún
                    </li>
                    <li class="idioma-option oferta" v-if="experiencia.oferta">
                      <input
                        type="checkbox"
                        class="edit-experiencia__checkbox"
                        value="MapudungúnOferta"
                        v-model="idiomas"
                      />Mapudungún Oferta
                    </li>
                  </ul>
                </div>
                <!-- Checkbox para Tipo de Reserva -->
                <div class="edit-zona__input-group">
                  <label class="edit-experiencia__label"
                    >¿Reserva Inmediata?</label
                  >
                  <input
                    type="checkbox"
                    class="edit-experiencia__checkbox my-2"
                    value="true"
                    v-model="reservaInmediata"
                  />
                  <p>
                    <em
                      >Si esta casilla está desmarcada, por defecto la
                      experiencia será de tipo Reserva Tardía o Por
                      Confirmar</em
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Calendario set temporadas -->
      <div v-if="language === 'es'">
        <h3 class="heading heading--3 heading--center">Temporadas</h3>
        <CalendarioTemporadas :experienciaID="$route.params.id" />
      </div>

      <div v-if="language === 'es'">
        <!-- Ajuste de precios -->
        <h3 class="heading heading--3 heading--center mt-3">
          Ajuste de precios
        </h3>

        <div v-if="language === 'es'" class="edit-zona__input-group container">
                <label class="edit-experiencia__label" for="oferta"
                  >¿Está en oferta?</label
                >
                <select
                  :value="experiencia.oferta"
                  @change="handleChange"
                  name="oferta"
                  class="form-control"
                >
                  <option :value="true">Si</option>
                  <option value="">No</option>
                </select>
        </div>

        <b-tabs
          class="tabs-temporadas container"
          content-class="tab-temporada"
          align="center"
          fill
        >
          <b-tab title="Precio fijo">
            <div class="edit-experiencia__side edit-experiencia__side--right">
              <div class="edit-zona__input-group">
                <label class="edit-experiencia__label">Precio base</label>
                <input
                  class="edit-zona__input"
                  :value="experiencia.precio"
                  type="number"
                  name="precio"
                  @input="handleChange"
                />

                <div
                  v-for="precioTemporada in experiencia.precioFijoTemporadas"
                  :key="`PrecioFijoTemporada${precioTemporada.temporada}`"
                  class="mt-2"
                >
                  <h5>Temporada {{precioTemporada.temporada}}</h5>
                  <input
                    class="edit-zona__input"
                    :value="precioTemporada.precio"
                    type="number"
                    :name="`precioFijoTemporadas${precioTemporada.temporada}`"
                    @input="handleChange"
                  />
                </div>
              </div>

              <!-- Oferta precio fijo -->
              
              <div
                v-if="language === 'es' && experiencia.oferta"
                class="edit-zona__input-group"
              >
                <label class="edit-experiencia__label">Precio Oferta</label>
                <input
                  class="edit-zona__input"
                  :value="experiencia.precioOferta"
                  type="number"
                  name="precioOferta"
                  @input="handleChange"
                />
              </div>
            </div>
          </b-tab>
          <b-tab title="Precio según pasajeros">
            <!-- //pRECIOS CONFIG -->
            <template
              v-if="
                experiencia.preciosConfig &&
                experiencia.preciosConfig.length > 0
              "
            >
              <h5 class="container">Filas almacenadas</h5>
              <div
                v-for="(precioConfig, index) in experiencia.preciosConfig"
                :key="index"
                class="container edit-experiencia__precios-config"
              >
                <ul class="edit-experiencia__list edit-experiencia__list--flex">
                  <li
                    v-for="(propiedad, index) in sortedPropertiesPrecioConfig(
                      precioConfig
                    )"
                    :key="index"
                    class="
                      edit-experiencia__list-item edit-experiencia__list-item--1
                    "
                  >
                    <div
                      class="edit-experiencia__top edit-experiencia__top--list"
                    >
                      <p class="paragraph">{{ toReadable(propiedad) }}:</p>
                    </div>
                    <p class="paragraph">
                      {{
                        propiedad !== "cantidadPersonas"
                          ? `${precioConfig[propiedad]} CLP`
                          : `${precioConfig[propiedad]}`
                      }}
                    </p>
                  </li>
                </ul>
                <div class="edit-zona__buttons edit-zona__buttons--right">
                  <button
                    type="button"
                    class="edit-zona__btn edit-zona__btn--warning"
                    @click="
                      borrarItem({
                        borrador: false,
                        name: 'preciosConfig',
                        index,
                      })
                    "
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </template>

            <template
              v-if="
                experiencia.experienciaBorrador.preciosConfig &&
                experiencia.experienciaBorrador.preciosConfig.length > 0
              "
            >
              <h5>Filas por almacenar</h5>
              <div
                v-for="(precioConfig, index) in experiencia.experienciaBorrador
                  .preciosConfig"
                :key="index"
                class="edit-experiencia__precios-config"
              >
                <ul
                  class="
                    edit-experiencia__list
                    edit-experiencia__list--flex
                    edit-experiencia__list--overflow
                  "
                >
                  <li
                    v-for="(propiedad, index) in sortedPropertiesPrecioConfig(
                      precioConfig
                    )"
                    :key="index"
                    class="
                      edit-experiencia__list-item edit-experiencia__list-item--1
                    "
                  >
                    <div
                      class="edit-experiencia__top edit-experiencia__top--list"
                    >
                      <p class="paragraph">{{ toReadable(propiedad) }}:</p>
                    </div>
                    <p class="paragraph">
                      {{
                        propiedad !== "cantidadPersonas"
                          ? `${precioConfig[propiedad]} CLP`
                          : `${precioConfig[propiedad]}`
                      }}
                    </p>
                  </li>
                </ul>
                <div class="edit-zona__buttons edit-zona__buttons--right">
                  <button
                    type="button"
                    class="edit-zona__btn edit-zona__btn--warning"
                    @click="
                      borrarItem({
                        borrador: true,
                        name: 'preciosConfig',
                        index,
                      })
                    "
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            </template>

            <template v-if="addPrecioConfig">
              <h5 class="container">Nueva fila</h5>
              <div class="container">
                <ul
                  class="
                    m-auto
                    edit-experiencia__list
                    edit-experiencia__list--flex
                    edit-experiencia__list--overflow
                  "
                  :style="{maxWidth: '100%' , overflowX: 'scroll' }"
                >
                  <li
                    class="
                      edit-experiencia__list-item edit-experiencia__list-item--1
                    "
                  >
                    <div
                      class="edit-experiencia__top edit-experiencia__top--list"
                    >
                      <p class="paragraph">Numero de pasajeros:</p>
                    </div>
                    <input
                      v-model="precioConfig.cantidadPersonas"
                      type="number"
                      class="edit-experiencia__input"
                      placeholder="Ingrese numero de pasajeros"
                    />
                  </li>
                  <template
                    v-if="getIdiomasInputs && getIdiomasInputs.length > 0"
                  >
                    <li
                      v-for="(idioma, index) in getIdiomasInputs"
                      :key="index"
                      class="
                        edit-experiencia__list-item edit-experiencia__list-item--1
                      "
                    >
                      <div
                        class="edit-experiencia__top edit-experiencia__top--list"
                      >
                        <p class="paragraph">Con idioma {{ idioma }}:</p>
                      </div>
                      <p class="mb-0 ">Temporada normal</p>
                      <input
                        v-model="precioConfig[idioma]"
                        type="number"
                        class="edit-experiencia__input"
                        placeholder="Ingrese precio"
                      />
                      <div 
                      v-for="(temporada, index) in experiencia.temporadas.temporadas"
                      :key="`${temporada}${index}`"
                      >
                      <p class="mb-0 mt-2">Precio temporada {{temporada.nombre}}</p>
                      <input
                        
                        v-model="precioConfig[`${idioma}_${temporada.nombre}`]"
                        type="number"
                        class="edit-experiencia__input"
                        :placeholder="`Temporada ${temporada.nombre}`"
                      />
                
                      </div>
                    </li>
                  </template>
                </ul>
              </div>
            </template>
            <div class="buttons container">
              <button
                v-if="!addPrecioConfig"
                @click="addItem"
                name="preciosConfig"
                type="button"
                class="edit-zona__btn edit-zona__btn--primary"
              >
                Agregar fila
              </button>
              <button
                v-else
                @click="addItem"
                name="preciosConfig"
                type="button"
                class="edit-zona__btn edit-zona__btn--success"
              >
                Agregar
              </button>
            </div>
          </b-tab>

          <b-tab title="Precio niñ@s">
            <!-- PRECIO NIÑ@S -->
            <div class="container mx-auto edit-experiencia__container">
              <div class="d-flex justify-content-center">
                <template>
                  <label class="edit-experiencia__label" for="oferta"
                    >¿Precio diferenciado por edad?</label
                  >
                  <select
                    :value="experiencia.precioDiferenciadoNiños"
                    @change="handleChange"
                    name="precioDiferenciadoNiños"
                    class="w-25"
                  >
                    <option :value="true">Si</option>
                    <option value="">No</option>
                  </select>
                </template>
              </div>

              <div v-if="experiencia.precioDiferenciadoNiños">
                <template
                  v-if="
                    experiencia.precioDiferenciadoNiños &&
                    experiencia.precioNiños.length > 0
                  "
                >
                  <h5 class="container">Filas almacenadas</h5>
                  <div
                    v-for="(precioNiño, index) in experiencia.precioNiños"
                    :key="index"
                    class="container edit-experiencia__precios-config"
                  >
                    <ul
                      class="
                        edit-experiencia__list edit-experiencia__list--flex
                      "
                    >
                      <li
                        v-for="(propiedad, index) in sortedPropertiesPrecioNiño(
                          precioNiño
                        )"
                        :key="index"
                        class="
                          edit-experiencia__list-item
                          edit-experiencia__list-item--1
                        "
                      >
                        <div
                          class="
                            edit-experiencia__top edit-experiencia__top--list
                          "
                        >
                          <p class="paragraph">{{ propiedad }}:</p>
                        </div>
                        <p class="paragraph">
                          {{
                            propiedad == "precio"
                              ? `${precioNiño[propiedad]} CLP`
                              : `${precioNiño[propiedad]}`
                          }}
                        </p>
                      </li>
                    </ul>
                    <div class="edit-zona__buttons edit-zona__buttons--right">
                      <button
                        type="button"
                        class="edit-zona__btn edit-zona__btn--warning"
                        @click="
                          borrarItem({
                            borrador: false,
                            name: 'precioNiños',
                            index,
                          })
                        "
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </template>

                <template
                  v-if="
                    experiencia.experienciaBorrador.precioNiños &&
                    experiencia.experienciaBorrador.precioNiños.length > 0
                  "
                >
                  <h5>Filas por almacenar</h5>
                  <div
                    v-for="(precioNiño, index) in experiencia
                      .experienciaBorrador.precioNiños"
                    :key="index"
                    class="edit-experiencia__precios-config"
                  >
                    <ul
                      class="
                        edit-experiencia__list
                        edit-experiencia__list--flex
                        edit-experiencia__list--overflow
                      "
                    >
                      <li
                        v-for="(propiedad, index) in sortedPropertiesPrecioNiño(
                          precioNiño
                        )"
                        :key="index"
                        class="
                          edit-experiencia__list-item
                          edit-experiencia__list-item--1
                        "
                      >
                        <div
                          class="
                            edit-experiencia__top edit-experiencia__top--list
                          "
                        >
                          <p class="paragraph">{{ propiedad }}:</p>
                        </div>
                        <p class="paragraph">
                          {{
                            propiedad == "precio"
                              ? `${precioNiño[propiedad]} CLP`
                              : `${precioNiño[propiedad]}`
                          }}
                        </p>
                      </li>
                    </ul>
                    <div class="edit-zona__buttons edit-zona__buttons--right">
                      <button
                        type="button"
                        class="edit-zona__btn edit-zona__btn--warning"
                        @click="
                          borrarItem({
                            borrador: true,
                            name: 'precioNiños',
                            index,
                          })
                        "
                      >
                        Eliminar
                      </button>
                    </div>
                  </div>
                </template>

                <template v-if="addPrecioNiño">
                  <h5 class="container">Nueva fila</h5>
                  <ul
                    class="
                      container
                      m-auto
                      edit-experiencia__list
                      edit-experiencia__list--flex
                      edit-experiencia__list--overflow
                    "
                  >
                    <li
                      class="
                        edit-experiencia__list-item
                        edit-experiencia__list-item--1
                      "
                    >
                      <div
                        class="
                          edit-experiencia__top edit-experiencia__top--list
                        "
                      >
                        <p class="paragraph">Nombre:</p>
                      </div>
                      <input
                        v-model="precioNiño.nombre"
                        type="text"
                        class="edit-experiencia__input"
                        placeholder="Ej: (infante, niño)"
                      />
                    </li>
                    <li
                      class="
                        edit-experiencia__list-item
                        edit-experiencia__list-item--1
                      "
                    >
                      <div
                        class="
                          edit-experiencia__top edit-experiencia__top--list
                        "
                      >
                        <p class="paragraph">Edad mínima:</p>
                      </div>
                      <input
                        v-model="precioNiño.edadMinima"
                        type="number"
                        class="edit-experiencia__input"
                        placeholder="Edad mínima"
                      />
                    </li>
                    <li
                      class="
                        edit-experiencia__list-item
                        edit-experiencia__list-item--1
                      "
                    >
                      <div
                        class="
                          edit-experiencia__top edit-experiencia__top--list
                        "
                      >
                        <p class="paragraph">Edad máxima:</p>
                      </div>
                      <input
                        v-model="precioNiño.edadMaxima"
                        type="number"
                        class="edit-experiencia__input"
                        placeholder="Edad máxima"
                      />
                    </li>
                    <li
                      class="
                        edit-experiencia__list-item
                        edit-experiencia__list-item--1
                      "
                    >
                      <div
                        class="
                          edit-experiencia__top edit-experiencia__top--list
                        "
                      >
                        <p class="paragraph">Precio:</p>
                      </div>
                      <input
                        v-model="precioNiño.precio"
                        type="number"
                        class="edit-experiencia__input"
                        placeholder="Precio"
                      />
                    </li>
                  </ul>
                </template>
                <div class="buttons container">
                  <button
                    v-if="!addPrecioNiño"
                    @click="addItem"
                    name="precioNiños"
                    type="button"
                    class="edit-zona__btn edit-zona__btn--primary"
                  >
                    Agregar fila
                  </button>
                  <button
                    v-else
                    @click="addItem"
                    name="precioNiños"
                    type="button"
                    class="edit-zona__btn edit-zona__btn--success"
                  >
                    Agregar
                  </button>
                </div>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>

      <!-- ¿ Permite abono ? -->
      <div class="container mt-4">
        <h3 class="text-center">Abono</h3>
        <div v-if="language === 'es'" class="edit-zona__input-group">
          <label class="edit-experiencia__label" for="oferta"
            >¿Permite abono?</label
          >
          <select
            :value="experiencia.permiteAbono"
            @change="handleChange"
            name="permiteAbono"
            class="form-control"
          >
            <option :value="true">Si</option>
            <option value="">No</option>
          </select>
        </div>
        <div
          v-if="language === 'es' && experiencia.permiteAbono"
          class="edit-zona__input-group"
        >
          <label class="edit-experiencia__label">Porcentaje de abono</label>
          <input
            class="edit-zona__input"
            :value="experiencia.porcentajeAbono"
            type="number"
            name="porcentajeAbono"
            @input="handleChange"
          />
          <span>%</span>
        </div>
      </div>

      <!--MENSAJE PERSONALIZADO + PRECIO MAS BAJO MANUAL  -->
      <div class="container mt-4">
        <h3 class="text-center">Precio más bajo </h3>
       
        <div
          class="edit-zona__input-group"
        >
          <label class="edit-experiencia__label">Precio más bajo</label>
          <h6>Si no vas a usar este campo no olvides dejarlo en blanco.</h6>
          <input
            class="edit-zona__input mb-2"
            :value="experiencia.precioMasBajo"
            type="number"
            name="precioMasBajo"
            @input="handleChange"
          />

          <label class="edit-experiencia__label">Mensaje precio bajo</label>
          <textarea
            class="edit-zona__input"
            :value="language === 'es'
                    ? experiencia.mensajePrecioMasBajo
                    : experiencia.ingles.mensajePrecioMasBajo"
            type="text"
            name="mensajePrecioMasBajo"
            @input="handleChange"
          />
        </div>
      </div>


      <!--  -->
      <div
        class="edit-experiencia__informacion edit-experiencia__informacion--3"
      >
        <h3 class="heading heading--3 heading--center">Itinerario</h3>

        <div class="edit-experiencia__container">
          <div class="edit-experiencia__itinerario">
            <!-- Gestion de itinerario, input para el resumen -->
            <div class="edit-zona__input-group">
              <label class="edit-experiencia__label"
                >Resumen de itinerario</label
              >

              <textarea
                class="edit-zona__textarea"
                name="descripcion.resumen"
                cols="30"
                rows="10"
                :value="
                  language === 'es'
                    ? experiencia.descripcion.resumen
                    : experiencia.ingles.descripcion.resumen
                "
                @input="handleChange"
              ></textarea>
            </div>

            <div
              v-if="
                (language === 'es' &&
                  experiencia.descripcion.dias.length > 0) ||
                (language === 'en' &&
                  experiencia.ingles.descripcion.dias.length > 0 &&
                  experiencia.cantidadDias > 1)
              "
              class="edit-experiencia__dias"
            >
              <h4 class="heading heading--4 heading--center">
                Dias registrados
              </h4>
              <!-- Gestion de dias en itinerario | dias que ya esten guardados en base de datos -->
              <div
                class="edit-zona__input-group"
                v-for="(dia, index) in language === 'es'
                  ? experiencia.descripcion.dias
                  : experiencia.ingles.descripcion.dias"
                :key="index"
              >
                <label v-if="language === 'es'" class="edit-experiencia__label"
                  >Dia
                  {{
                    experiencia.descripcion.dias.length > 1 ? index + 1 : ""
                  }}</label
                >
                <label v-else class="edit-experiencia__label"
                  >Dia
                  {{
                    experiencia.ingles.descripcion.dias.length > 1
                      ? index + 1
                      : ""
                  }}</label
                >

                <textarea
                  class="edit-zona__textarea"
                  name="destacado"
                  id="destacado"
                  cols="30"
                  rows="10"
                  :value="dia"
                  disabled
                ></textarea>
                <div class="edit-zona__buttons edit-zona__buttons--right">
                  <button
                    class="edit-zona__btn edit-zona__btn--warning"
                    type="button"
                    @click="
                      borrarItem({
                        level: 'descripcion',
                        borrador: false,
                        name: 'dias',
                        index,
                      })
                    "
                  >
                    Eliminar dia
                  </button>
                </div>
              </div>
            </div>
            <!-- Gestion de itinerario | mostrar dias que se agreguen a borrador -->
            <div
              v-if="
                (language === 'es' && experienciaBorrador.dias.length > 0) ||
                (language === 'en' &&
                  experiencia.ingles.experienciaBorrador.dias.length > 0 &&
                  experiencia.cantidadDias > 1)
              "
              class="edit-experiencia__dias"
            >
              <h4 class="heading heading--4 heading--center">
                Dias a registrarse
              </h4>
              <div
                class="edit-zona__input-group"
                v-for="(dia, index) in language === 'es'
                  ? experienciaBorrador.dias
                  : experiencia.ingles.experienciaBorrador.dias"
                :key="index"
              >
                <label v-if="language === 'es'" class="edit-experiencia__label"
                  >Dia
                  {{
                    experienciaBorrador.dias.length > 1 ? index + 1 : ""
                  }}</label
                >
                <label v-else class="edit-experiencia__label"
                  >Dia
                  {{
                    experiencia.ingles.experienciaBorrador.dias.length > 1
                      ? index + 1
                      : ""
                  }}</label
                >

                <textarea
                  class="edit-zona__textarea"
                  name="destacado"
                  cols="30"
                  rows="10"
                  :value="dia"
                  disabled
                ></textarea>

                <div class="edit-zona__buttons edit-zona__buttons--right">
                  <button
                    class="edit-zona__btn edit-zona__btn--warning"
                    type="button"
                    @click="
                      borrarItem({
                        borrador: true,
                        name: 'dias',
                        index,
                      })
                    "
                  >
                    Eliminar dia
                  </button>
                </div>
              </div>
            </div>
            <!-- Gestion de itinerario | boton e input para ingresar dias -->
            <div class="edit-zona__input-group" v-if="addDia">
              <label class="edit-experiencia__label">Nuevo dia</label>
              <textarea
                class="edit-zona__textarea"
                cols="30"
                rows="10"
                v-model="dia"
              ></textarea>
            </div>
            <!-- Template de buttons para lenguage en espanol -->
            <template
              v-if="
                language === 'es' &&
                experienciaBorrador.dias.length +
                  experiencia.descripcion.dias.length <
                  experiencia.cantidadDias &&
                experiencia.cantidadDias > 1
              "
            >
              <button
                v-if="!addDia"
                type="button"
                name="dias"
                @click="addItem"
                class="edit-experiencia__btn edit-experiencia__btn--primary"
              >
                Registrar nuevo dia
              </button>
              <button
                v-else
                type="button"
                name="dias"
                @click="addItem"
                class="edit-experiencia__btn edit-experiencia__btn--success"
              >
                Agregar
              </button>
            </template>
            <!-- Template para lenguage ingles -->
            <template
              v-if="
                language === 'en' &&
                experiencia.ingles.experienciaBorrador.dias.length +
                  experiencia.ingles.descripcion.dias.length <
                  experiencia.cantidadDias &&
                experiencia.cantidadDias > 1
              "
            >
              <button
                v-if="!addDia"
                type="button"
                name="dias"
                @click="addItem"
                class="edit-experiencia__btn edit-experiencia__btn--primary"
              >
                Registrar nuevo dia
              </button>
              <button
                v-if="addDia"
                type="button"
                name="dias"
                @click="addItem"
                class="edit-experiencia__btn edit-experiencia__btn--success"
              >
                Agregar
              </button>
            </template>
          </div>
        </div>
      </div>
      <div
        class="edit-experiencia__informacion edit-experiencia__informacion--4"
      >
        <h3 class="heading heading--3 heading--center">
          Informacion importante
        </h3>

        <div class="edit-experiencia__container">
          <div class="edit-experiencia__informacion-importante">
            <div class="edit-experiencia__reglas">
              <!-- Gestion de informacion importante, gestion de normas -->
              <div
                class="
                  edit-experiencia__informacion-importante
                  edit-experiencia__informacion-importante--1
                "
              >
                <h4 class="heading heading--5">Incluye:</h4>
                <!-- Gestion de informacion importante | items de la seccion 'Incluye' guardados en base de datos -->
                <div
                  class="edit-zona__container--fluid"
                  v-if="
                    (language === 'es' &&
                      experiencia.informacionImportante.incluye.length > 0) ||
                    (language === 'en' &&
                      experiencia.ingles.informacionImportante.incluye.length >
                        0)
                  "
                >
                  <label class="edit-experiencia__label">Incluye</label>
                  <ul class="edit-zona__list edit-zona__list--1">
                    <li
                      class="edit-zona__list-item"
                      v-for="(item, index) in language === 'es'
                        ? experiencia.informacionImportante.incluye
                        : experiencia.ingles.informacionImportante.incluye"
                      :key="index"
                    >
                      <label class="edit-zona__label" v-if="language === 'es'"
                        >Item
                        {{
                          experiencia.informacionImportante.incluye.length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <label class="edit-zona__label" v-else
                        >Item
                        {{
                          experiencia.ingles.informacionImportante.incluye
                            .length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <div class="edit-zona__list-content">
                        <p class="paragraph">{{ item }}</p>
                        <div
                          class="edit-zona__buttons edit-zona__buttons--right"
                        >
                          <button
                            type="button"
                            @click="
                              borrarItem({
                                level: 'informacionImportante',
                                borrador: false,
                                name: 'incluye',
                                index,
                              })
                            "
                            class="
                              edit-zona__btn
                              edit-zona__btn--warning
                              edit-zona__btn--consejos
                            "
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- Gestion de informacion importante | items de la seccion incluye agregados al borrador -->
                <div
                  class="edit-zona__container--fluid"
                  v-if="
                    (language === 'es' &&
                      experienciaBorrador.informacionImportante.incluye.length >
                        0) ||
                    (language === 'en' &&
                      experiencia.ingles.experienciaBorrador
                        .informacionImportante.incluye.length > 0)
                  "
                >
                  <label class="edit-experiencia__label"
                    >Items por agregar</label
                  >

                  <ul class="edit-zona__list edit-zona__list--1">
                    <li
                      class="edit-zona__list-item"
                      v-for="(item, index) in language === 'es'
                        ? experienciaBorrador.informacionImportante.incluye
                        : experiencia.ingles.experienciaBorrador
                            .informacionImportante.incluye"
                      :key="index"
                    >
                      <label class="edit-zona__label" v-if="language === 'es'"
                        >Item
                        {{
                          experienciaBorrador.informacionImportante.incluye
                            .length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <label class="edit-zona__label" v-else
                        >Item
                        {{
                          experiencia.ingles.experienciaBorrador
                            .informacionImportante.incluye.length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <div class="edit-zona__list-content">
                        <p class="paragraph">{{ item }}</p>
                        <div
                          class="edit-zona__buttons edit-zona__buttons--right"
                        >
                          <button
                            type="button"
                            @click="
                              borrarItem({
                                level: 'informacionImportante',
                                borrador: true,
                                name: 'incluye',
                                index,
                              })
                            "
                            class="
                              edit-zona__btn
                              edit-zona__btn--warning
                              edit-zona__btn--consejos
                            "
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <!-- Gestion de informacion importante | input que se mostrara si se desea agregar un item en 'Incluye'-->
                <div class="edit-zona__input-group" v-if="addIncluyeItem">
                  <label class="edit-zona__label">Item</label>
                  <input
                    v-model="incluyeItem"
                    type="text"
                    class="edit-zona__input"
                  />
                </div>
                <!--  -->
                <button
                  v-if="!addIncluyeItem"
                  type="button"
                  name="incluye"
                  @click="addItem"
                  class="edit-experiencia__btn edit-experiencia__btn--primary"
                >
                  Registrar nuevo item
                </button>
                <button
                  v-else
                  type="button"
                  name="incluye"
                  @click="addItem"
                  class="edit-experiencia__btn edit-experiencia__btn--success"
                >
                  Agregar
                </button>
              </div>

              <!--  -->

              <div
                class="
                  edit-experiencia__informacion-importante
                  edit-experiencia__informacion-importante--2
                "
              >
                <h4 class="heading heading--3">No incluye:</h4>
                <!-- Gestion de informacion importante | items en "no incluye" que ya esten almacenados en base de datos -->
                <div
                  v-if="
                    (language === 'es' &&
                      experiencia.informacionImportante.noIncluye.length > 0) ||
                    (language === 'en' &&
                      experiencia.ingles.informacionImportante.noIncluye
                        .length > 0)
                  "
                  class="edit-zona__container--fluid"
                >
                  <label class="edit-experiencia__label"
                    >Items almacenados</label
                  >
                  <ul class="edit-zona__list edit-zona__list--1">
                    <li
                      class="edit-zona__list-item"
                      v-for="(item, index) in language === 'es'
                        ? experiencia.informacionImportante.noIncluye
                        : experiencia.ingles.informacionImportante.noIncluye"
                      :key="index"
                    >
                      <label class="edit-zona__label" v-if="language === 'es'"
                        >Item
                        {{
                          experiencia.informacionImportante.noIncluye.length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <label class="edit-zona__label" v-else
                        >Item
                        {{
                          experiencia.ingles.informacionImportante.noIncluye
                            .length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <div class="edit-zona__list-content">
                        <p class="paragraph">{{ item }}</p>
                        <div
                          class="edit-zona__buttons edit-zona__buttons--right"
                        >
                          <button
                            type="button"
                            @click="
                              borrarItem({
                                level: 'informacionImportante',
                                borrador: false,
                                name: 'noIncluye',
                                index,
                              })
                            "
                            class="
                              edit-zona__btn
                              edit-zona__btn--warning
                              edit-zona__btn--consejos
                            "
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Gestion de informacion importante | items en "no incluye" que esten almacenados en borrador -->
                <div
                  class="edit-zona__container--fluid"
                  v-if="
                    (language === 'es' &&
                      experiencia.experienciaBorrador.informacionImportante
                        .noIncluye.length > 0) ||
                    (language === 'en' &&
                      experiencia.ingles.experienciaBorrador
                        .informacionImportante.noIncluye.length > 0)
                  "
                >
                  <label class="edit-experiencia__label"
                    >Items por agregar</label
                  >
                  <ul class="edit-zona__list edit-zona__list--1">
                    <li
                      class="edit-zona__list-item"
                      v-for="(item, index) in language === 'es'
                        ? experiencia.experienciaBorrador.informacionImportante
                            .noIncluye
                        : experiencia.ingles.experienciaBorrador
                            .informacionImportante.noIncluye"
                      :key="index"
                    >
                      <label class="edit-zona__label" v-if="language === 'es'"
                        >Item
                        {{
                          experiencia.experienciaBorrador.informacionImportante
                            .noIncluye.length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <label class="edit-zona__label" v-else
                        >Item
                        {{
                          experiencia.ingles.experienciaBorrador
                            .informacionImportante.noIncluye.length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <div class="edit-zona__list-content">
                        <p class="paragraph">{{ item }}</p>
                        <div
                          class="edit-zona__buttons edit-zona__buttons--right"
                        >
                          <button
                            type="button"
                            @click="
                              borrarItem({
                                level: 'informacionImportante',
                                borrador: true,
                                name: 'noIncluye',
                                index,
                              })
                            "
                            class="
                              edit-zona__btn
                              edit-zona__btn--warning
                              edit-zona__btn--consejos
                            "
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Gestion de informacion importante | switch para agregar elementos a "no incluye" -->
                <div v-if="addNoIncluyeItem" class="edit-zona__input-group">
                  <label class="edit-zona__label">Item</label>
                  <input
                    v-model="noIncluyeItem"
                    type="text"
                    class="edit-zona__input"
                  />
                </div>
                <button
                  v-if="!addNoIncluyeItem"
                  type="button"
                  name="noIncluye"
                  @click="addItem"
                  class="edit-experiencia__btn edit-experiencia__btn--primary"
                >
                  Registrar nuevo item
                </button>
                <button
                  v-else
                  type="button"
                  name="noIncluye"
                  @click="addItem"
                  class="edit-experiencia__btn edit-experiencia__btn--success"
                >
                  Agregar
                </button>
                <!--  -->
              </div>
            </div>

            <div class="edit-experiencia__reglas">
              <div
                class="
                  edit-experiencia__informacion-importante
                  edit-experiencia__informacion-importante--3
                "
              >
                <h4 class="heading heading--4">Debes traer:</h4>
                <!-- Gestion de informacion importante | elementos en "debes traer" ya almacenados en base de datos -->
                <div
                  v-if="
                    (language === 'es' &&
                      experiencia.informacionImportante.debesTraer.length >
                        0) ||
                    (language === 'en' &&
                      experiencia.ingles.informacionImportante.debesTraer
                        .length > 0)
                  "
                  class="edit-zona__container--fluid"
                >
                  <label class="edit-experiencia__label"
                    >Items almacenados</label
                  >
                  <ul class="edit-zona__list edit-zona__list--1">
                    <li
                      class="edit-zona__list-item"
                      v-for="(item, index) in language === 'es'
                        ? experiencia.informacionImportante.debesTraer
                        : experiencia.ingles.informacionImportante.debesTraer"
                      :key="index"
                    >
                      <label class="edit-zona__label" v-if="language === 'es'"
                        >Item
                        {{
                          experiencia.informacionImportante.debesTraer.length >
                          1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <label class="edit-zona__label" v-else
                        >Item
                        {{
                          experiencia.ingles.informacionImportante.debesTraer
                            .length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <div class="edit-zona__list-content">
                        <p class="paragraph">{{ item }}</p>
                        <div
                          class="edit-zona__buttons edit-zona__buttons--right"
                        >
                          <button
                            type="button"
                            @click="
                              borrarItem({
                                level: 'informacionImportante',
                                borrador: false,
                                name: 'debesTraer',
                                index,
                              })
                            "
                            class="
                              edit-zona__btn
                              edit-zona__btn--warning
                              edit-zona__btn--consejos
                            "
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Gestion de informacion importante | elementos de "debes traer ya agregados a borrador" -->
                <div
                  class="edit-zona__container--fluid"
                  v-if="
                    (language === 'es' &&
                      experiencia.experienciaBorrador.informacionImportante
                        .debesTraer.length > 0) ||
                    (language === 'en' &&
                      experiencia.ingles.experienciaBorrador
                        .informacionImportante.debesTraer.length > 0)
                  "
                >
                  <label class="edit-experiencia__label"
                    >Items por agregar</label
                  >
                  <ul class="edit-zona__list edit-zona__list--1">
                    <li
                      class="edit-zona__list-item"
                      v-for="(item, index) in language === 'es'
                        ? experiencia.experienciaBorrador.informacionImportante
                            .debesTraer
                        : experiencia.ingles.experienciaBorrador
                            .informacionImportante.debesTraer"
                      :key="index"
                    >
                      <label class="edit-zona__label" v-if="language === 'es'"
                        >Item
                        {{
                          experiencia.experienciaBorrador.informacionImportante
                            .debesTraer.length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <label class="edit-zona__label" v-else
                        >Item
                        {{
                          experiencia.ingles.experienciaBorrador
                            .informacionImportante.debesTraer.length > 1
                            ? index + 1
                            : ""
                        }}</label
                      >
                      <div class="edit-zona__list-content">
                        <p class="paragraph">{{ item }}</p>
                        <div
                          class="edit-zona__buttons edit-zona__buttons--right"
                        >
                          <button
                            type="button"
                            @click="
                              borrarItem({
                                level: 'informacionImportante',
                                borrador: true,
                                name: 'debesTraer',
                                index,
                              })
                            "
                            class="
                              edit-zona__btn
                              edit-zona__btn--warning
                              edit-zona__btn--consejos
                            "
                          >
                            Eliminar
                          </button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- Gestion de informacion importante | agregas mas elementos a "debes traer" -->
                <div v-if="addDebesTraerItem" class="edit-zona__input-group">
                  <label class="edit-zona__label">Item</label>
                  <input
                    v-model="debesTraerItem"
                    type="text"
                    class="edit-zona__input"
                  />
                </div>
                <button
                  v-if="!addDebesTraerItem"
                  type="button"
                  name="debesTraer"
                  @click="addItem"
                  class="edit-experiencia__btn edit-experiencia__btn--primary"
                >
                  Registrar nuevo item
                </button>
                <button
                  v-else
                  type="button"
                  name="debesTraer"
                  @click="addItem"
                  class="edit-experiencia__btn edit-experiencia__btn--success"
                >
                  Agregar
                </button>
                <!--  -->
              </div>

              <!-- Gestion de informacion importante | input para resumen -->
              <div class="edit-experiencia__informacion-importante--4">
                <label class="edit-experiencia__label"
                  >Resumen de informacion importante</label
                >
                <textarea
                  class="edit-zona__textarea"
                  cols="30"
                  rows="10"
                  type="text"
                  name="informacionImportante.resumen"
                  :value="
                    language === 'es'
                      ? experiencia.informacionImportante.resumen
                      : experiencia.ingles.informacionImportante.resumen
                  "
                  @input="handleChange"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="edit-zona__buttons">
        <div class="edit-zona__loading-button">
          <button
            class="edit-experiencia__btn edit-experiencia__btn--primary"
            :disabled="isLoading"
          >
            Guardar
          </button>
          <div v-if="isLoading" class="lds-dual-ring"></div>
        </div>
        <button
          @click="cancelExperiencia"
          class="edit-experiencia__btn edit-experiencia__btn--secondary"
          style="margin: 50px"
          type="button"
          :disabled="isLoading"
        >
          Cancelar
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import CalendarioTemporadas from "@/components/Admin/CalendarioTemporadas";

import { storage } from "../../firebase.service";

export default {
  metaInfo: {
    title: "Editar experiencia",
  },
  data() {
    return {
      id: null,
      trueBoolean: true,
      falseBoolean: false,
      destinoId: null,
      zonaId: null,
      addYoutubeMedia: false,
      youtubeMedia: null,
      addIncluyeItem: false,
      incluyeItem: null,
      addNoIncluyeItem: false,
      noIncluyeItem: null,
      addDebesTraerItem: false,
      debesTraerItem: null,
      addDestacado: false,
      destacado: null,
      addDia: false,
      dia: null,
      disponibilidad: [],
      fecha: null,
      addFecha: false,
      tipoGestion: "",
      range: null,
      lastRange: null,
      rangoHorasItem: {
        start: null,
        end: null,
      },
      pdescuento: null,
      addPrecioConfig: false,
      precioConfig: {
        cantidadPersonas: 0,
        temporadas: {},
      },
      precioNiño: {
        nombre: "",
        edadMinima: 0,
        edadMaxima: 0,
        precio: 0,
      },
      addPrecioNiño: false,
      ranguito: null,
      tag: ""
    };
  },
  components: { CalendarioTemporadas },
  created() {
    const { name, params } = this.$route;
    if (name === "RegistrarExperiencia") {
      this.resetExperiencia();
      this.zonaId = params.zonaId;
      this.destinoId = params.id;
      this.setZonaId(this.zonaId);
      this.setDestinoId(this.destinoId);
      this.setExperienciaId(null);
    } else if (name === "EditExperiencia") {
      this.zonaId = params.zonaId;
      this.destinoId = params.destinoId;
      this.id = params.id;
      this.setZonaId(this.zonaId);
      this.setDestinoId(this.destinoId);
      this.setExperienciaId(this.id);
    }
  },
  destroyed() {
    this.resetExperiencia();
  },
  watch: {
    "experiencia.idiomas"(value) {
      if (
        this.experiencia &&
        this.experiencia.idiomas &&
        this.experiencia.idiomas.length > 0
      ) {
        this.precioConfig = Object.assign(
          {},
          {
            cantidadPersonas: 0,
          }
        );
      }
    },
  },
  computed: {
    ...mapGetters([
      "experiencia",
      "zonaImgsToSave",
      "experienciaBorrador",
      "language",
      "isLoading",
    ]),
    idiomas: {
      get() {
        // Algoritmo para actualizar los idiomas que fueron colocados sin tilde
        const idiomas = [
          "Inglés",
          "Español",
          "Alemán",
          "Francés",
          "Portugués",
          "Mapudungún",
        ];
        if (this.$store.state.experiencia.idiomas.length > 0) {
          const idiomasFilter = idiomas.filter((idioma) =>
            this.$store.state.experiencia.idiomas.includes(idioma)
          );
          if (!(idiomasFilter.length > 0)) {
            this.$store.commit("updateIdiomas", []);
          }
        }
        return this.$store.state.experiencia.idiomas;
      },
      set(value) {
        this.$store.commit("updateIdiomas", value);
      },
    },
    reservaInmediata: {
      get() {
        return this.$store.state.experiencia.reserva_inmediata;
      },
      set(value) {
        this.$store.commit("updateReservaInmediata", value);
      },
    },
    diasSemana: {
      get() {
        return this.$store.state.experiencia.disponibilidad;
      },
      set(value) {
        this.$store.commit("updateDiasSemana", value);
      },
    },
    disponibilidades: {
      get() {
        return this.$store.state.experiencia.disponibilidades;
      },
    },
    disponibilidadesBorrador: {
      get() {
        return this.$store.state.experiencia.experienciaBorrador
          .disponibilidades;
      },
    },
    getIdiomasInputs() {
      if (this.experiencia.idiomas && this.experiencia.idiomas.length > 0) {
        this.experiencia.idiomas.forEach((idioma) => {
          this.$set(this.precioConfig, idioma, 0);
        });
        return this.experiencia.idiomas;
      }
      return null;
    },
    getHeroContent() {
      if (this.experiencia && this.experiencia.heroImgUrls) {
        if (this.experiencia.heroImgUrls.length > 0) {
          const heroContentRef = this.experiencia.heroImgUrls.map(
            (contentUrl) => {
              const { name } = storage.refFromURL(contentUrl);
              const nameParts = name.split(".");
              return {
                type: nameParts[1],
                contentUrl,
              };
            }
          );
          return heroContentRef;
        }
      }
      return null;
    },
    getHeroContentBorrador() {
      if (
        this.zonaImgsToSave.heroImgUrls &&
        this.zonaImgsToSave.heroImgUrls.length > 0
      ) {
        const heroContentRef = this.zonaImgsToSave.heroImgUrls.map(
          (contentUrl) => {
            const extension = contentUrl
              .split(";")[0]
              .split(":")[1]
              .split("/")[0];
            return {
              type: extension,
              contentUrl,
            };
          }
        );
        return heroContentRef;
      }
      return null;
    },
    duracion() {
      if (
        !(
          (this.experiencia.horaInicio && this.experiencia.horaFin) ||
          (this.rangoHorasItem.start && this.rangoHorasItem.end)
        )
      ) {
        return "Registre hora de inicio y fin";
      }

      let start;
      let end;

      if (this.experiencia.horaInicio && this.experiencia.horaFin) {
        const { horaInicio, horaFin } = this.experiencia;
        start = horaInicio;
        end = horaFin;
      } else {
        start = this.rangoHorasItem.start;
        end = this.rangoHorasItem.end;
      }

      const startDate = new Date("01/01/2020 " + start + ":00");
      let endDate = new Date("01/01/2020 " + end + ":00");

      const startTime = startDate.getTime();
      let endTime = endDate.getTime();

      if (endTime - startTime < 0) {
        endDate = new Date("01/02/2020 " + end + ":00");
        endTime = endDate.getTime();
      }

      // Diferencia de tiempo en minutos
      const timeDifference = Math.abs(endTime - startTime) / (1000 * 60);
      const horasDifference = (timeDifference - (timeDifference % 60)) / 60;
      const minutosDifference = timeDifference % 60;

      // Variables con contenido textual
      const horas =
        horasDifference > 0
          ? horasDifference > 1
            ? `${horasDifference} Horas`
            : `${horasDifference} Hora`
          : "";
      const minutos =
        minutosDifference > 0
          ? minutosDifference > 1
            ? ` ${minutosDifference} Minutos`
            : ` ${minutosDifference} Minuto`
          : "";
      return horas + minutos;
    },
  },
  methods: {
    ...mapActions([
      "experienciaTypeChange",
      "addHeroImg",
      "submitExperiencia",
      "submitItem",
      "addImg",
      "deleteUnsavedImg",
      "deleteImg",
      "setZonaId",
      "setDestinoId",
      "setExperienciaId",
      "deleteExperiencia",
      "switchLanguage",
      "borrarItem",
    ]),
    ...mapMutations([
      "resetExperiencia",
      "resetFechaExclusiva",
      "switchLoading",
      "switchDelete",
    ]),
    addTag() {
      this.submitItem({ name: 'tags', value: this.tag, language:this.language })
      this.tag = ''
    },
    sortedPropertiesPrecioConfig(precioConfig) {
      const sortedArray = ["cantidadPersonas"];
      const properties = Object.keys(precioConfig);
      const idiomas = properties.filter((property) => {
        return property !== "cantidadPersonas";
      });
      if (idiomas && idiomas.length > 0) {
        sortedArray.push(...idiomas);
        return sortedArray;
      }
      return null;
    },
    sortedPropertiesPrecioNiño(precioNiño) {
      const sortedArray = ["nombre"];
      const properties = Object.keys(precioNiño);
      const idiomas = properties.filter((property) => {
        return property !== "nombre";
      });
      if (idiomas && idiomas.length > 0) {
        sortedArray.push(...idiomas);
        return sortedArray;
      }
      return null;
    },
    toReadable(value) {
      if (value === "cantidadPersonas") {
        return "N° Personas";
      }
      if (value !== "cantidadPersonas") {
        return `${value}`;
      }
    },
    cancelExperiencia() {
      this.$router.push({
        path: "/admin",
      });
    },
    async submitRequest(value) {
      try {
        this.switchLoading(true);
        await this.submitExperiencia(value);
        this.switchLoading(false);
      } catch (error) {
        console.log("Error: ", error);
        this.switchLoading(false);
      }
    },
    async deleteRequest(value) {
      try {
        this.switchDelete(true);
        this.switchLoading(true);
        await this.deleteExperiencia(value);
        this.switchDelete(false);
        this.switchLoading(false);
      } catch (error) {
        this.switchDelete(false);
        this.switchLoading(false);
        console.log(error);
      }
    },
    handleDate(value) {
      const { start, end } = value;
      const min = new Date(start).getTime();
      let limit;
      let limitRange;
      if (this.experiencia.modalidad === "rangos") {
        const dias = this.experiencia.cantidadDias - 1;
        limit = min + dias * (1000 * 60 * 60 * 24);
        limitRange = {
          start,
          end: new Date(limit),
        };
      }
      if (
        this.experiencia.modalidad === "rangosTemporada" ||
        this.experiencia.modalidad === "diasSemana"
      ) {
        limitRange = {
          start,
          end,
        };
      }
      this.range = limitRange;
    },
    navigateFromExperiencia() {
      this.$router.push({
        path: `/admin/zonas/${this.zonaId}/destinos/${this.destinoId}`,
      });
    },
    handleChange(event) {
      const { name, value, type } = event.target;
      this.experienciaTypeChange({ type, name, value });
    },
    addItem(event) {
      const { name } = event.target;
      console.log('entra aqui', event);
      switch (name) {
        case "youtubeMedia":
          if (name === "youtubeMedia" && this.addYoutubeMedia) {
            if (this.youtubeMedia && this.youtubeMedia.length > 0) {
              const youtubePattern =
                /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm;
              if (this.youtubeMedia.match(youtubePattern)) {
                const formattedLink = this.youtubeMedia.replace(
                  "watch?v=",
                  "embed/"
                );
                this.submitItem({
                  name,
                  value: formattedLink,
                });
                this.youtubeMedia = null;
                this.addYoutubeMedia = false;
              } else {
                alert("Ingrese un link de youtube valido");
              }
            }
            return;
          }
          this.addYoutubeMedia = true;
          break;
        case "incluye":
          if (name === "incluye" && this.addIncluyeItem) {
            if (this.incluyeItem && this.incluyeItem.length > 0) {
              this.submitItem({
                level: "informacionImportante",
                name,
                value: this.incluyeItem,
              });
              this.incluyeItem = null;
              this.addIncluyeItem = false;
            }
            return;
          }
          this.addIncluyeItem = true;
          break;
        case "noIncluye":
          if (name === "noIncluye" && this.addNoIncluyeItem) {
            if (this.noIncluyeItem && this.noIncluyeItem.length > 0) {
              this.submitItem({
                level: "informacionImportante",
                name,
                value: this.noIncluyeItem,
              });
              this.noIncluyeItem = null;
              this.addNoIncluyeItem = false;
            }
            return;
          }
          this.addNoIncluyeItem = true;
          break;
        case "debesTraer":
          if (name === "debesTraer" && this.addDebesTraerItem) {
            if (this.debesTraerItem && this.debesTraerItem.length > 0) {
              this.submitItem({
                level: "informacionImportante",
                name,
                value: this.debesTraerItem,
              });
              this.debesTraerItem = null;
              this.addDebesTraerItem = false;
            }
            return;
          }
          this.addDebesTraerItem = true;
          break;
        case "destacado":
          if (name === "destacado" && this.addDestacado) {
            if (this.destacado && this.destacado.length > 0) {
              this.submitItem({
                name,
                value: this.destacado,
              });
              this.destacado = null;
              this.addDestacado = false;
            }
            return;
          }
          this.addDestacado = true;
          break;
        case "dias":
          if (name === "dias" && this.addDia) {
            if (this.dia && this.dia.length > 0) {
              this.submitItem({
                name,
                value: this.dia,
              });
              this.dia = null;
              this.addDia = false;
            }
            return;
          }
          this.addDia = true;
          break;
        case "disponibilidades":
          if (name === "disponibilidades") {
            if (this.range) {
              this.submitItem({
                name,
                value: this.range,
              });
              this.range = {
                start: null,
                end: null,
              };
            }
          }
          break;
        case "fecha":
          if (this.addFecha) {
            if (this.fecha && this.fecha.length > 0) {
              this.disponibilidad.push(this.fecha);
              this.addFecha = false;
              this.fecha = null;
              return;
            }
          }
          this.addFecha = true;
          break;
        case "rangosHoras":
          if (
            this.rangoHorasItem &&
            this.rangoHorasItem.start &&
            this.rangoHorasItem.end
          ) {
            this.submitItem({
              name,
              value: this.rangoHorasItem,
            });
            this.rangoHorasItem = Object.assign(
              {},
              {
                start: null,
                end: null,
              }
            );
          }
          break;
        case "preciosConfig":
          if (this.addPrecioConfig) {
            this.submitItem({
              name,
              value: this.precioConfig,
            });
            this.precioConfig = Object.assign({}, this.precioConfig, {
              cantidadPersonas: 0,
            });
            this.addPrecioConfig = false;
            return;
          }
          this.addPrecioConfig = true;

          //Agregar variables segun idioma y temporada
          this.experiencia.idiomas.forEach(idioma => {
            this.precioConfig[idioma] = 0

            this.experiencia.temporadas.temporadas.forEach(temporada =>{
              this.precioConfig[`${idioma}_${temporada.nombre}`] = 0
            })
          })
          break;
        /* case "tags":
          console.log('entra en tag', this.tag);
          if (this.tag.length > 0) {
            this.submitItem({
              name,
              value: this.tag,
            });
            this.tag = "";
            this.addTag = false;
            return;
          }
          this.addTag = true;
          break; */
        case "precioNiños":
          if (this.addPrecioNiño) {
            this.submitItem({
              name,
              value: this.precioNiño,
            });
            this.precioNiño = Object.assign({}, this.precioNiño, {
              nombre: "",
              edadMinima: 0,
              edadMaxima: 0,
              precio: 0,
            });
            this.addPrecioNiño = false;
            return;
          }
          this.addPrecioNiño = true;
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.oferta {
  color: #d43b32 !important;
  font-weight: 700;
}
.edit-experiencia {
  &__horas-unicas {
    display: flex;
    justify-content: space-between;
  }

  &__hora-registro {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding: 20px 0;
    align-items: stretch;
  }

  color: white;

  &__disponibilidades {
    box-shadow: 1px 1px 3px black;
    background: white;
    padding: 20px;
    border-radius: 5px;

    label {
      color: black;
    }
  }

  &__input {
    padding: 10px;
    display: block;
    margin: auto;
  }

  &__reglas {
    display: flex;
    border-bottom: 1px solid white;
    justify-content: space-between;
    padding: 50px 0;
  }

  &__informacion-importante {
    &--1,
    &--2,
    &--3,
    &--4 {
      width: 45%;
    }

    &--4 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__select {
    padding: 15px;
  }

  &__paragraph {
    margin: 0;
  }

  &__card {
    background: white;
    padding: 20px;
    color: black;
    border-radius: 5px;
    box-shadow: 1px 1px 3px;
    margin-bottom: 30px;
  }

  &__idioma-options {
    list-style-type: none;
  }

  &__precios-config {
    padding: 20px 0;
  }

  &__list {
    list-style-type: none;
    margin: 0;

    &--1 {
      padding-top: 20px;
    }

    &--flex {
      display: flex;
      background: white;
      border-radius: 3px;
      box-shadow: 1px 1px 5px black;
    }

    &--overflow {
      // overflow-x: scroll;  
    }
  }

  &__list-item {
    background: #fff;
    border-top: 1px solid grey;
    color: black;
    font-weight: 500;
    padding: 15px 0;

    &:last-child {
      border-bottom: 1px solid grey;
    }

    &--1 {
      text-align: center;
      border-top: none;
      border-right: 1px solid grey;
      flex: 1 1 0px;
      padding: 0;
      width: fit-content;
      &:last-child {
        border: none;
      }
    }
  }

  &__top {
    &--list {
      width: 100%;
      background: darkslategray;
      color: white;
      box-shadow: 1px 1px 3px black;
    }
  }

  &__container {
    width: 90%;
    margin: auto;
  }

  &__label {
    margin: 0;
    font-size: 1.3rem;
  }

  &__container--flex {
    display: flex;
    justify-content: space-between;
  }

  &__side {
    width: 45%;
  }

  &__informacion {
    padding: 50px 0;
  }

  &__buttons {
    text-align: center;
  }

  &__btn {
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px;
    font-weight: 500;
    box-shadow: 1px 1px 3px black;
    border: none;

    &--warning {
      background: rgb(206, 64, 64);
      color: white;
    }

    &--success {
      color: white;
      background: rgb(12, 128, 12);
    }

    &--primary {
      color: white;
      background: rgb(55, 127, 221);
    }
  }

  &__img {
    height: 150px;
    width: 180px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;

    &--1 {
      background: rgb(26, 26, 26);
    }
  }

  &__img-container {
    display: flex;
    flex-wrap: wrap;
  }

  &__destino {
    border: 1px solid black;
    display: inline-block;
  }

  &__checkbox {
    margin-right: 10px;
  }
}

.heading {
  &--center {
    text-align: center;
  }
}
</style>

<style lang="scss">
.tabs-temporadas {
  .tab-temporada,
  .tab-content {
    height: fit-content !important;
    border: 1px solid #fff !important;
    color: #fff !important;
  }
}
</style>
