var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container pt-sm-5",attrs:{"id":"Busqueda"}},[_c('div',{staticClass:"buscador mobile"},[_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticClass:"dropdownDestinos"},[_c('h5',{staticClass:"d-none d-sm-block"},[_vm._v(" "+_vm._s(_vm.BuscadorDestinos)+" "),_c('img',{attrs:{"src":require("@/assets/img/iconos/down.svg"),"alt":"flechaAbajo"}})]),_c('h5',{staticClass:"d-block d-sm-none"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.busqueda),expression:"busqueda"}],staticStyle:{"width":"90%"},attrs:{"placeholder":_vm.BuscadorDestinos,"name":"","id":""},domProps:{"value":(_vm.busqueda)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscar.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.busqueda=$event.target.value}}}),_c('img',{attrs:{"src":require("@/assets/img/iconos/down.svg"),"alt":""}})]),_c('div',{staticClass:"dropdownDestinosList"},[_c('ul',_vm._l((_vm.getSortedZonas),function(zona,index){return _c('router-link',{directives:[{name:"show",rawName:"v-show",value:(index == 2),expression:"index == 2"}],key:index,staticClass:"text-center",class:{
                opcionesZonas: true,
                Centro: zona.titulo.toLowerCase().includes('centro'),
                Lagos: zona.titulo.toLowerCase().includes('lagos'),
                Patagonia: zona.titulo.toLowerCase().includes('patagonia'),
              },attrs:{"tag":"li","to":`/zonas/${_vm.nameAsId({ id: zona.id })}/overview`}},[_vm._v(" "+_vm._s(zona.titulo)+" "),_c('div',{class:{
                  subNorte: zona.titulo.toLowerCase().includes('norte'),
                  subCentro: zona.titulo.toLowerCase().includes('centro'),
                  subLagos: zona.titulo.toLowerCase().includes('lagos'),
                  subPatagonia: zona.titulo
                    .toLowerCase()
                    .includes('patagonia'),
                }},[_c('ul',_vm._l((_vm.siteDestinosByZona({
                      id: zona.id,
                    })),function(destino,index){return _c('router-link',{key:index,attrs:{"tag":"li","to":`/destinos/${destino.id}/experiencias`}},[_vm._v(_vm._s(destino.titulo))])}),1)])])}),1)])]),_c('b-form-input',{staticClass:"d-none d-sm-block",attrs:{"list":"my-list-id","placeholder":_vm.BuscadorPlaceholder},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.buscar.apply(null, arguments)}},model:{value:(_vm.busqueda),callback:function ($$v) {_vm.busqueda=$$v},expression:"busqueda"}}),_c('b-input-group-text',{staticClass:"lupa",staticStyle:{"position":"absolute","top":"0","right":"0","height":"50px"},on:{"click":function($event){_vm.buscar();
          _vm.buscadorFalse();}}},[_c('img',{attrs:{"src":require("@/assets/img/iconos/lupaBlanca.svg"),"alt":""}})])],1)]),_c('h5',{staticClass:"titulo mt-sm-4"},[_vm._v("Resultados")]),_c('div',{staticClass:"resultados"},_vm._l((_vm.showByZona ? _vm.resultsByZona : _vm.searchResult),function(item,index){return _c('router-link',{key:index,staticClass:"pb-4",staticStyle:{"cursor":"pointer"},attrs:{"tag":"b-row","to":item.hasOwnProperty('precio')
          ? `/experiencias/${item.id}`
          : `/destinos/${item.id}/experiencias`}},[_c('b-col',{attrs:{"cols":"4"}},[_c('div',{staticClass:"imagen",style:({
            background: `url('${_vm.getThumbnail({
              contentUrl: item.carouselImgUrl,
              size: 480,
            })}') no-repeat center center / cover`,
          })})]),_c('b-col',{attrs:{"cols":"12","sm":"8"}},[_c('div',{staticClass:"descripcion"},[_c('span',[_vm._v(_vm._s(item.hasOwnProperty("precio") ? "Experiencia" : "Destino"))]),_c('h3',[_vm._v(_vm._s(item.titulo))]),_c('p',{staticClass:"busqueda__paragraph"},[_vm._v(_vm._s(item.resumen))])])])],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }