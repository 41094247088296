<template>
  <div>
    <b-container>
      <b-row>
        <b-col cols="12">
          <h5>Datos de cliente:</h5>
        </b-col>
        <b-col cols="12">
          <label for="ReservaNombre">Nombre cliente:</label>
          <b-form-input
            id="ReservaNombre"
            :disabled="disabledDatosPersonales"
            v-model="nombreCliente"
          />
        </b-col>

        <b-col cols="12">
          <label for="ReservaCorreo">Email:</label>
          <b-form-input
            id="ReservaCorreo"
            :disabled="disabledDatosPersonales"
            v-model="email"
          />
        </b-col>

        <b-col cols="12">
          <label for="ReservaTelefono">Teléfono:</label>
          <b-form-input
            id="ReservaTelefono"
            :disabled="disabledDatosPersonales"
            v-model="telefono"
          />
        </b-col>

        <b-col cols="6" class="mt-2">
          <label for="ReservaFechaEnd"> Fecha Inicio: </label>
          <b-form-input readonly :value="reserva.fecha"></b-form-input>
        </b-col>
        <b-col cols="6" class="mt-2">
          <label for="ReservaFechaEnd"> Fecha Fin: </label>
          <b-form-input readonly :value="reserva.fechaFin"></b-form-input>
        </b-col>

        <b-col cols="12" class="mt-3">
          <h5>Datos de reserva:</h5>
        </b-col>

        <b-col cols="12">
          <label for="ReservaCalendario">Fecha reserva:</label>

          <vc-date-picker
            ref="calendar"
            mode="range"
            is-range
            v-model="fechaSeleccionada"
          />
        </b-col>

        <b-col cols="12" v-if="reserva.detalleViajeros">
          <label for="ReservaViajeros">Total viajeros: </label>
          <b-form-input
            id="ReservaViajeros"
            :disabled="true"
            :value="viajerosAuto"
          />
          <div class="pl-4">
            <b-row>
              <b-col cols="4">
                <label for="ReservaViajerosAdultos">Adultos: </label>
                <b-form-input
                  id="ReservaViajerosAdultos"
                  :disabled="disabledViajeros"
                  v-model="detalleViajeros.adultos.cantidad"
                  type="number"
                  min="0"
                  @change="calculoTotal"
                  @keyup="calculoTotal"
                />
              </b-col>
              <b-col cols="4">
                <label for="ReservaViajerosAdultosPrecio">Precio: </label>
                <b-form-input
                  id="ReservaViajerosAdultosPrecio"
                  :disabled="disabledViajeros"
                  v-model="detalleViajeros.adultos.precio"
                  type="number"
                  min="0"
                  @change="calculoTotal"
                  @keyup="calculoTotal"
                />
              </b-col>
              <b-col cols="4">
                <label for="ReservaViajerosAdultosTotal">Total: </label>
                <b-form-input
                  id="ReservaViajerosAdultosTotal"
                  :disabled="true"
                  v-model="detalleViajeros.adultos.total"
                  type="number"
                  min="0"
                />
              </b-col>
            </b-row>
            <template v-if="reserva.detalleViajeros.niños">
              <b-row
                v-for="(niño, i) in detalleViajeros.niños"
                :key="`${niño.nombre}${i}`"
              >
                <b-col cols="4">
                  <label :for="`ReservaViajeros${niño.nombre}${i}`"
                    >{{ niño.nombre }} ({{ niño.edadMinima }} -
                    {{ niño.edadMaxima }}):
                  </label>
                  <b-form-input
                    :id="`ReservaViajeros${niño.nombre}${i}`"
                    :disabled="disabledViajeros"
                    v-model="detalleViajeros.niños[i].cantidad"
                    type="number"
                    min="0"
                    @change="calculoTotal"
                    @keyup="calculoTotal"
                  />
                </b-col>
                <b-col cols="4">
                  <label :for="`ReservaViajeros${niño.nombre}${i}Precio`">
                    Precio:
                  </label>
                  <b-form-input
                    :id="`ReservaViajeros${niño.nombre}${i}Precio`"
                    :disabled="disabledViajeros"
                    v-model="detalleViajeros.niños[i].precio"
                    type="number"
                    min="0"
                    @change="calculoTotal"
                    @keyup="calculoTotal"
                  />
                </b-col>
                <b-col cols="4">
                  <label :for="`ReservaViajeros${niño.nombre}${i}Total`">
                    Total:
                  </label>
                  <b-form-input
                    :id="`ReservaViajeros${niño.nombre}${i}Total`"
                    :disabled="true"
                    v-model="detalleViajeros.niños[i].total"
                    type="number"
                    min="0"
                  />
                </b-col>
              </b-row>
            </template>
          </div>
        </b-col>

        <b-col cols="12" v-else>
          <label for="ReservaViajeros">Viajeros: </label>
          <b-form-input
            id="ReservaViajeros"
            :disabled="disabledViajeros"
            v-model="viajeros"
            min="0"
            type="number"
          />
        </b-col>

        <b-col
          cols="12"
          v-if="reserva.permiteAbono && reserva.abono.porcentajeAbono"
        >
          <label for="ReservaPorcentajeAbono">Porcentaje abono:</label>
          <b-input-group append="%">
            <b-form-input
              id="ReservaPorcentajeAbono"
              v-model="porcentajeAbono"
              :disabled="disabledPorcentajeAbono"
              :state="porcentajeAbono <= 100"
              @change="calculoPorcentajeAbono"
              @keyup="calculoPorcentajeAbono"
              type="number"
            />
          </b-input-group>
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="reserva.permiteAbono && reserva.abono.primeraCuota"
        >
          <label for="ReservaPrimeraCuota">Primera cuota:</label>
          <b-form-input
            id="ReservaPrimeraCuota"
            v-model="primeraCuota"
            :disabled="disabledPrimeraCuota"
            type="number"
            :state="primeraCuota > 0"
          />
        </b-col>
        <b-col
          cols="12"
          md="6"
          v-if="reserva.permiteAbono && reserva.abono.segundaCuota"
        >
          <label for="ReservaTotalSegundaCuota">Segunda cuota:</label>
          <b-form-input
            id="ReservaTotalSegundaCuota"
            v-model="segundaCuota"
            :disabled="disabledSegundaCuota"
            type="number"
            :state="segundaCuota >= 0"
          />
        </b-col>
        <b-col cols="12" class="mb-3">
          <label for="ReservaTotal">Total:</label>
          <b-form-input
            id="ReservaTotal"
            v-model="total"
            :disabled="disabledTotal"
            type="number"
            @keyup="calculoPorcentajeAbono"
          />
        </b-col>
        <b-col
          cols="12"
          class="my-3"
          v-if="
            reserva.estado === 'Pendiente' ||
            reserva.estado === 'Pendiente de Pago' ||
            reserva.estado === 'Pendiente de primer pago'
          "
        >
          <p v-if="cuponAplicado">Cupón '{{ cuponInput }}' aplicado</p>

          <div v-else>
            <label v-if="!formCupon" for="ReservaCupon">
              <b-button @click="formCupon = true">Ingresar cupón</b-button>
            </label>
            <b-input-group v-else>
              <b-form-input
                placeholder="Código de cupón"
                v-model="cuponInput"
                id="ReservaCupon"
              />
              <b-input-group-append>
                <b-button @click="aplicarCupon">Agregar cupón</b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col cols="12" class="mb-4">
          <b-row>
            <b-col cols="12" md="6" class="mt-2">
              <b-button
                variant="primary"
                block
                @click="confirmarCambios"
                v-if="
                  reserva.estado != 'Pagado' || reserva.estado != 'Rechazado'
                "
                >Confirmar cambios</b-button
              >
            </b-col>
            <b-col cols="12" md="6" class="mt-2">
              <b-button variant="danger" block @click="cancelarCambios"
                >Cancelar</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import firebase from "firebase";
import { mapActions, mapState } from "vuex";
export default {
  name: "EditPopUp",
  data() {
    return {
      //Datos personales
      nombreCliente: "",
      email: "",
      telefono: "",
      //Fecha
      fechaSeleccionada: null,
      //Viajeros
      viajeros: 0,
      detalleViajeros: null,
      //Money
      porcentajeAbono: 100,
      primeraCuota: 0,
      segundaCuota: 0,
      total: 0,
      //Deshabilitando campos
      disabledDatosPersonales: false,
      disabledFecha: false,
      disabledViajeros: false,
      disabledPorcentajeAbono: false,
      disabledPrimeraCuota: false,
      disabledSegundaCuota: false,
      disabledTotal: false,
      //Cupon
      formCupon: false,
      cuponInput: "",
      cuponDoc: null,
      cuponAplicado: false,
    };
  },
  props: {
    reserva: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapState(["experiencias"]),
    getFechas() {
      let fechaFin = this.reserva.fechaFin
        ? moment(Vue.filter("fechaToISOString")(this.reserva.fechaFin))
        : false;

      //Calculo de fecha de vuelta en caso de que no exista fechaFin
      if (!fechaFin) {
        const experiencia = this.experiencias.find((exp) =>
          exp.titulo.includes(this.reserva.experiencia)
        );
        const cantidadDias = experiencia ? experiencia.cantidadDias : 1;

        fechaFin =
          cantidadDias > 1
            ? moment(
                `${moment(
                  moment(Vue.filter("fechaToISOString")(this.reserva.fecha))
                ).add(cantidadDias - 1, "d")}`
              )
            : moment(
                moment(Vue.filter("fechaToISOString")(this.reserva.fecha))
              );
      }
      let start = moment(Vue.filter("fechaToISOString")(this.reserva.fecha));
      let end = fechaFin;

      return {
        start: new Date(start.year(), start.month(), start.date()),
        end: new Date(end.year(), end.month(), end.date()),
      };
    },
    viajerosAuto() {
      let total = 0;
      if (this.detalleViajeros) {
        total = +this.detalleViajeros.adultos.cantidad;
        if (this.detalleViajeros.niños) {
          this.detalleViajeros.niños.forEach((niño) => {
            total += +niño.cantidad;
          });
        }
      }
      return total ? total : +this.viajeros;
    },
  },
  methods: {
    ...mapActions("reservaciones", ["editarReserva"]),
    autocompleteData() {
      this.nombreCliente = this.reserva.nombre;
      this.email = this.reserva.correo;
      this.telefono = this.reserva.telefono;
      this.fechaSeleccionada = this.getFechas;
      this.total = this.reserva.total.replaceAll(".", "");
      this.viajeros = +this.reserva.viajeros;
      if (this.reserva.detalleViajeros) {
        this.detalleViajeros = this.reserva.detalleViajeros;
      }
      //Reservas con abono
      if (this.reserva.permiteAbono) {
        this.porcentajeAbono = this.reserva.abono.porcentajeAbono;
        this.primeraCuota = this.reserva.abono.primeraCuota.replaceAll(".", "");
        this.segundaCuota = this.reserva.abono.segundaCuota.replaceAll(".", "");
      }

      //Desactivación de inputs segun estado de reserva
      if (this.reserva.estado == "Pendiente de segundo pago") {
        this.disabledPorcentajeAbono = true;
        this.disabledPrimeraCuota = true;
        this.disabledSegundaCuota = true;
      } else if (
        this.reserva.estado == "Pagado" ||
        this.reserva.estado == "Rechazado"
      ) {
        this.disabledPorcentajeAbono = true;
        this.disabledPrimeraCuota = true;
        this.disabledSegundaCuota = true;
        this.disabledTotal = true;
        this.disabledDatosPersonales = true;
        this.disabledFecha = true;
        this.disabledViajeros = true;
      }

      //Desactivación de total si existen niños
      if (this.reserva.detalleViajeros) {
        this.disabledTotal = true;
        this.disabledPrimeraCuota = true;
        this.disabledSegundaCuota = true;
      }
    },
    async confirmarCambios() {
      let reservaAntigua = { ...this.reserva };

      reservaAntigua.nombre = this.nombreCliente.trim();
      reservaAntigua.nom = this.nombreCliente.trim().split(" ")[0];
      reservaAntigua.correo = this.email;
      reservaAntigua.telefono = this.telefono;
      reservaAntigua.fecha = Vue.filter("toReadableDate")(
        moment(this.fechaSeleccionada.start).format()
      );
      reservaAntigua.fechaFin = Vue.filter("toReadableDate")(
        moment(this.fechaSeleccionada.end).format()
      );
      reservaAntigua.total = this.total
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      reservaAntigua.viajeros = +this.viajerosAuto;
      if (reservaAntigua.permiteAbono) {
        reservaAntigua.abono = {
          porcentajeAbono: +this.porcentajeAbono,
          primeraCuota: this.primeraCuota
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
          segundaCuota: this.segundaCuota
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
        };
      }
      if (reservaAntigua.detalleViajeros) {
        reservaAntigua.detalleViajeros.adultos = {
          cantidad: +this.detalleViajeros.adultos.cantidad,
          precio: +this.detalleViajeros.adultos.precio,
          total: +this.detalleViajeros.adultos.total,
        };
        if (reservaAntigua.detalleViajeros.niños) {
          reservaAntigua.detalleViajeros.niños.forEach((niño, i) => {
            niño.cantidad = +this.detalleViajeros.niños[i].cantidad;
            niño.precio = +this.detalleViajeros.niños[i].precio;
            niño.total = +this.detalleViajeros.niños[i].total;
          });
        }
      }
      const editar = await this.editarReserva(reservaAntigua);
      if (!editar) return;
      alert("Reserva editada con éxito");
      // await this.$bvToast.toast("Reserva editada con éxito", {
      //   title: `Alerta`,
      //   variant: "success",
      //   solid: true,
      //   toaster: "b-toaster-top-center",
      // });
      this.$bvModal.hide("EditarReserva");
    },
    cancelarCambios() {
      this.$bvModal.hide("EditarReserva");
    },
    calculoPorcentajeAbono() {
      if (
        this.reserva.estado == "Pendiente" ||
        this.reserva.estado == "Pendiente de primer pago"
      ) {
        this.primeraCuota = Math.round(
          (+this.total / 100) * this.porcentajeAbono
        );
        this.segundaCuota = this.total - this.primeraCuota;
      } else if (this.reserva.estado == "Pendiente de segundo pago") {
        this.porcentajeAbono = Math.round(
          (this.primeraCuota * 100) / this.total
        );
        this.segundaCuota = this.total - this.primeraCuota;
      }
    },
    async aplicarCupon() {
      await firebase
        .firestore()
        .collection("cupones")
        .doc(this.cuponInput.trim() || " ")
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.$bvToast.toast(
              "Este cupón no existe o dejó de estar vigente",
              {
                title: `Alerta`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              }
            );
          } else {
            this.cuponAplicado = true;
            this.$bvToast.toast(
              "Debes confirmar los cambios para que se aplique el cupón",
              {
                title: `Cupón ingresado con éxito`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
              }
            );
            this.cuponDoc = doc.data();

            let cupon = this.cuponAplicado;
            if (cupon) {
              let tipo_Descuento = this.cuponDoc.tipo_Descuento;
              let cant = Number(this.cuponDoc.cant);
              tipo_Descuento == "pesos"
                ? (this.total -= cant)
                : tipo_Descuento == "porcentual"
                ? (this.total = this.total * Number(`0.${100 - cant}`))
                : false;
            }

            this.calculoPorcentajeAbono();
          }
        })
        .catch((err) => {
          console.log("Error getting document", err);
        });
    },
    calculoTotal() {
      let { adultos, niños } = this.detalleViajeros;

      let total = 0;

      adultos.total = +adultos.cantidad * +adultos.precio;
      total += adultos.total;

      if (niños) {
        let totalNiños = 0;
        niños.forEach((niño) => {
          niño.total = +niño.precio * +niño.cantidad;
          totalNiños += niño.total;
        });

        total += totalNiños;
      }

      this.total = total;
      this.calculoPorcentajeAbono();
    },
  },
  created() {
    this.autocompleteData();
  },
};
</script>

<style lang="scss" scoped>
</style>