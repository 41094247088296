/* eslint-disable */
export default {
  namespaced: true,

  state: {
    faqESP: {
      title: "Preguntas Frecuentes",
    },
    faqENG: {
      title: "FAQ",
    }
  },
  getters: {
    faq(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.faqESP.title;
      } else if (rootState.language == "en") {
        return state.faqENG.title;
      }
    },
  }
};
