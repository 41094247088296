<template>
	<div class="edit-zona" style="padding-bottom: 30px;">
		<div class="header">
			<b-container fluid>
				<button type="button" class="edit-zona__btn edit-zona__btn--nav" @click="navigateToHome"><i
						class="fas fa-arrow-alt-circle-left"></i>Pagina principal</button>
			</b-container>
		</div>
		<b-container fluid>
			<b-row>
				<b-col cols="6" class="text-center">
					<h4>Español</h4>
				</b-col>
				<b-col cols="6" class="text-center">
					<h4>Ingles</h4>
				</b-col>
			</b-row>
			<b-form>
				<b-row>
					<b-col cols="6">
						<b-form-group id="input-group-1" label="Nombre del viaje" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="Ingrese el titulo del viaje" required
								v-model="form.es.title" @input="sanitizeTitle('es')"></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Slug" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="" required
								v-model="form.es.slug"></b-form-input>
						</b-form-group>

						<b-form-group id="input-group-1" label="Descripcion Basica" label-for="input-1">
							<b-form-textarea rows="8" v-model="form.es.description_basic"></b-form-textarea>
						</b-form-group>

						<b-form-group id="input-group-1" label="Descripcion General" label-for="input-1">
							<b-form-textarea rows="8" v-model="form.es.description_general"></b-form-textarea>
						</b-form-group>
						<div v-if="typeSubmit == 'update'">
							<b-form-group id="input-group-1" label="Seleccione una imagen de cabecera" label-for="input-1"
								class="">
								<input class="form-control" accept="image/*,.mp4" name="img_hero" type="file" placeholder="Seleccione una imagen" @change="addCabeceraImg">
							</b-form-group>
							<div class="edit-zona__img-container">
								<a
									v-if="form.image.length > 0"
									href="javascript:(void)"
									@click.prevent="deleteImg(form.image, 'cabecera')"						
									class="edit-zona__img-link">
									<img
									:src="form.image"
									alt="heading-img"
									class="edit-zona__img">
								</a>
							</div>
						</div>
						<div v-else>
							<span>Primero guarde el viaje y luego, se agregan las imagenes, esto por motivos de consistencia de datos</span>
						</div>
					</b-col>
					<b-col cols="6">
						<b-form-group id="input-group-1" label="Nombre del viaje" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="Ingrese el titulo del viaje" required
								v-model="form.en.title" @input="sanitizeTitle('en')"></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Slug" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="" required
								v-model="form.en.slug"></b-form-input>
						</b-form-group>

						<b-form-group id="input-group-1" labe label="Descripcion Basica" label-for="input-1">
							<b-form-textarea rows="8" v-model="form.en.description_basic"></b-form-textarea>
						</b-form-group>

						<b-form-group id="input-group-1" label="Descripcion General" label-for="input-1">
							<b-form-textarea rows="8" v-model="form.en.description_general"></b-form-textarea>
						</b-form-group>

						<b-form-group id="input-group-1" label="Seleccione una imagenes carousel" label-for="input-1"
								class="">
								<input class="form-control" accept="image/*,.mp4" name="" type="file" placeholder="Seleccione una imagen" @change="addImg($event,'carousel')">
							</b-form-group>
							<div class="edit-zona__img-container" v-if="typeSubmit == 'update'" >
								<div style="display: flex; flex-wrap: wrap;">
									<a v-for="(img, index) in form.carousel" :key="index"
										href="javascript:(void)"
										@click.prevent="deleteImg(img.url, 'carousel')"	
										class="edit-zona__img-link">
										<img
										:src="img.url"
										alt="heading-img"
										class="edit-zona__img">
									</a>
								</div>
							</div>
							<div v-else>
								<span>Primero guarde el viaje y luego, se agregan las imagenes, esto por motivos de consistencia de datos</span>
							</div>
					</b-col>
				</b-row>
				<hr>
				<h4 style="align-content: center;" class="justify-content-center">Especial </h4>
				<b-container fluid>
					<b-row>
						<b-col cols="5">
							<b-form-group label="Descripcion Español">
								<b-form-input placeholder="..." v-model="highlight.description_es"></b-form-input>
							</b-form-group>
							<b-form-group label="Descripcion Ingles">
								<b-form-input placeholder="..." v-model="highlight.description_en"></b-form-input>
							</b-form-group>
							<b-form-group id="input-group-1" label-for="input-1" class="">
								<b-button variant="primary" class="float-right" @click="handleAddHighlight()">{{
									!highlight.token ? '+ Agregar' : 'Editar' }} </b-button>
							</b-form-group>
						</b-col>
						<b-col cols="7" style="background-color: white;">
							<b-table responsive show-empty ref="table2" striped hover :fields="tableOptions2.fields"
								:items="form.highlights" empty-filtered-text empty-text="...">
								<template #cell(action)="data">
									<div class="text-center">
										<b-button variant="primary" class="btn-sm mx-1" @click="editHighlight(data.item)"><i
												class="fas fa-edit"></i></b-button>
										<b-button variant="danger" class="btn-sm mx-1"
											@click="handleDeleteHighlight(data.item.token)"><i
												class="fas fa-times"></i></b-button>
									</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</b-container>
				<hr>
				<h4 style="align-content: center;" class="justify-content-center">Itinerarios {{ form.itineraries.length }}
				</h4>
				<b-row class="float-right">
					<b-col cols="12">
						<b-form-group id="input-group-1" label-for="input-1" class="">
							<b-button variant="primary" class="float-right" @click="openModalItinerary('add')">+ Agregar
								Itinerario </b-button>
						</b-form-group>
					</b-col>
				</b-row>
				<br>
				<br>
				<b-container fluid>
					<b-row class="mt-5">
						<b-col cols="12" style="background-color: white;">
							<b-table show-empty ref="table2" striped hover :fields="tableOptions.fields"
								:items="form.itineraries" empty-filtered-text empty-text="...">
								<template #cell(image)="data">
									<div class="mb-1">
										<b-avatar variant="info" :src="data.item.image"></b-avatar>
									</div>
								</template>
								<template #cell(action)="data">
									<div class="text-center">
										<b-button variant="primary" class="btn-sm mx-1" @click="editItinerary(data.item)"><i
												class="fas fa-edit"></i></b-button>
										<b-button variant="danger" class="btn-sm mx-1"
											@click="handleDeleteItinerary(data.item.token)"><i
												class="fas fa-times"></i></b-button>
									</div>
								</template>
							</b-table>
						</b-col>
					</b-row>
				</b-container>

				<b-row class="mt-5">
					<b-col cols="4">
						<b-button class="mt-2" variant="danger" @click="navigateToHome" block>Cancelar</b-button>
					</b-col>
					<b-col cols="4">
					</b-col>
					<b-col cols="4" v-show="typeSubmit == 'save'">
						<b-button class="mt-2" variant="success" block :disabled="isLoading"
							@click="handleSubmitForm()"> <b-spinner v-show="isLoading" small label="Small Spinner"></b-spinner> Guardar</b-button>
					</b-col>
					<b-col cols="4" v-show="typeSubmit == 'update'">
						<b-button class="mt-2" variant="success" block :disabled="isLoading"
							@click="handleUpdateSubmitForm()"><b-spinner v-show="isLoading" small label="Small Spinner"></b-spinner> Actualizar</b-button>
					</b-col>
					
				</b-row>
			</b-form>
		</b-container>

		<form-modal-itinerary ref="formItinerary" :tripId="tripId" :typeForm="type" :formEdit.sync="dataEditItinerary"
			v-on:handle-add-itinerary="handleAddItinerary" v-on:handle-edit-itinerary="handleEditItinerary"
			v-on:handle-clean-form="cleanDataToEdit">
		</form-modal-itinerary>
	</div>
	
</template>

<script>

import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { storage } from "../../../firebase.service";
import FormModalItinerary from "./partials/FormModalItinerary";

export default {
	components: { FormModalItinerary },
	data: function () {
		return {
			typeSubmit: 'save',
			showModalItinerary: false,
			highlight: {
				description_es: null,
				description_en: null,
				token: null
			},
			form: {
				es: {
					title: '',
					slug: '',
					description_basic: '',
					description_general: '',
				},
				en: {},
				itineraries: [],
				highlights: [],
				image: '',
				carousel: []
			},
			tableOptions2: {
				fields: [
					{
						key: 'description_es',
						label: 'Descripcion ES',
					},
					{
						key: 'description_en',
						label: 'Descripcion EN',
					},
					{
						key: 'action',
						label: 'Accion',
						sortable: false,
						class: 'text-center',
						thStyle: { width: "15%" },
					},
				]
			},
			tableOptions: {
				fields: [
					{
						key: 'image',
						label: 'Imagen'
					},
					{
						key: 'es.day',
						label: 'Dia'
					},
					{
						key: 'es.title',
						label: 'Titulo'
					},
					{
						key: 'es.description',
						label: 'Descripcion'
					},
					{
						key: 'action',
						label: 'Accion',
						sortable: false,
						class: 'text-center',
						thStyle: { width: "10%" },
					},
				]
			},
			type: '',
			dataEditItinerary: null,
			tripId: null
		};
	},
	computed: {
		listItineraries() {
			return this.form.itineraries.map((itinerary) => {
				return {
					...itinerary.es,
					image: itinerary.image ? itinerary.image : ''
				}
			});
		},
		...mapState("ForeignTrip", [
			"trip",
			"id"
		]),
		...mapGetters([
			"isLoading"
		]),
		getHeroImg() {

		}
	},
	methods: {
		...mapActions('ForeignTrip', [
			'addTrip',
			'getTrip',
			'editTrip',
			'cleanStateTrip'
		]),
		...mapMutations([
			'switchLoading',
		]),
		openModalItinerary(type) {
			this.type = type;
			this.$refs.formItinerary.showModal();
		},
		navigateToHome() {
			this.handleCleanForm();
			this.$router.push({ path: '/admin/lista-viajes' })
		},

		async handleCleanForm() {
			await this.cleanStateTrip();
			this.form = {
				es: {
					title: '',
					slug: '',
					description_basic: '',
					description_general: '',
				},
				en: {},
				itineraries: [],
				highlights: [],
				image: '',
				carousel: []
			};
		},
		sanitizeTitle(lang) {
			const title = this.form[lang].title;
			let sanitizedTitle = title
				.toLowerCase()
				.normalize("NFD")
				.replace(/[\u0300-\u036f]/g, "")
				.replace(/[^\w\s-]/g, "")
				.replace(/\s+/g, "-");
			this.form[lang].slug = sanitizedTitle;
		},
		/* functions load images */

		addCabeceraImg({target}) {
			const file = target.files[0]
			const fileReader = new FileReader()
			fileReader.addEventListener('load', () => {
				const imgUrl = fileReader.result
				const imgFile = file
				this.saveStorageImages(imgUrl, imgFile, this.form.id, 'cabecera')
				//this.form.image = imgUrl;
				target.value = ''
			})
			fileReader.readAsDataURL(file)
		},

		addImg({target}, level) {

			if ( level == 'cabecera' && this.form.image) {
				alert('Ya se ha seleccionado una imagen, si va a cambiarla elimine la actual');
				return;
			}

			const file = target.files[0]
			const fileReader = new FileReader()
			fileReader.addEventListener('load', () => {
				const imgUrl = fileReader.result
				const imgFile = file
				this.saveStorageImages(imgUrl, imgFile, this.form.id, level)
				target.value = ''
			})
			fileReader.readAsDataURL(file)
		},
		async deleteImg(urlImg, level) {

			const imgRef = storage.refFromURL(urlImg);
			const fileName = imgRef.name.split(".")[0];
			const parentFiles = await imgRef.parent.listAll();

			const references = parentFiles.items.filter(ref => {
				// Filtrar por nombre de archivo
				const isItem = ref.name.includes(fileName);
				return isItem;
			});

			await Promise.all(references.map(ref => ref.delete()));

			if(level == 'cabecera') {
				this.form.image = null;
			} else if (level == 'carousel') {
				const filterCarousel = this.form.carousel.filter((e) => { return e.url !== urlImg });
				this.form.carousel = filterCarousel;
			}

			//this.handleUpdateForm();
		},

		//levels => cabecera, carousel, itineraries
		//token => solo itinerarios tienen token
		async saveStorageImages(imgUrl = null, imgFile, key, level, token = null) {

			let path = 'trips_personalized';

			const extension = imgFile.name.slice(imgFile.name.lastIndexOf('.'))
			let imageStringPath;
			switch (level) {
				case 'cabecera':
					imageStringPath = `${path}/${key}/main${extension}`;
					break;
				case 'carousel':
					imageStringPath = `${path}/${key}/carousel/${Math.random().toString(36).substr(2)}${extension}`;
					break;
				case 'itineraries':
					imageStringPath = `${path}/${key}/itineraries/${Math.random().toString(36).substr(2)}${extension}`;
					break;
				default:
					break;
			}

			const upload = await storage.ref().child(imageStringPath);
			const storageResult = await upload.put(imgFile);
			if (level == 'cabecera') {
				this.form.image = await storageResult.ref.getDownloadURL();
			} else if (level == 'carousel') {
				this.form.carousel.push({ url: await storageResult.ref.getDownloadURL() });
			}
			//this.form.image = await storageResult.ref.getDownloadURL();
		},

		/* Highlight Init */

		handleAddHighlight() {
			if (!this.highlight.token) {
				if(this.highlight.description_es && this.highlight.description_en) {
					this.highlight.token = Math.random().toString(36).substr(2);
					this.form.highlights.push(this.highlight);
				} else {
					alert('No puede agregar valores vacios');
				}
			}
			this.highlight = {};
		},
		editHighlight(data) {
			this.highlight = data;
		},
		handleDeleteHighlight(token) {
			let filterHighlight = this.form.highlights.filter((e) => { return e.token !== token });
			this.form.highlights = filterHighlight;
		},

		/* Highlight End */

		handleAddItinerary(data) {
			this.form.itineraries.push(data);
		},
		handleEditItinerary(data) {
			this.dataEditItinerary = {};
			this.$refs.table2.refresh();
		},
		handleDeleteItinerary(token) {
			let filterItineraries = this.form.itineraries.filter((e) => { return e.token !== token });

			this.form.itineraries = filterItineraries;
		},
		editItinerary(data) {
			this.dataEditItinerary = data;
			this.openModalItinerary('edit');
		},
		async handleSubmitForm() {
			try {
				this.switchLoading(true)
				const response = await this.addTrip(this.form);
				const message = response ? 'Se agrego correctamente el viaje.' : 'Hubo un error agregando el viaje.';
				alert(message);
				this.switchLoading(false)

				if (response) {
					this.$router.push({ path: '/admin/lista-viajes' });
				}
			} catch (error) {
				this.switchLoading(false)
			}
		},
		async handleUpdateSubmitForm() {

			try {
				this.switchLoading(true)
				const response = await this.editTrip(this.form)
				const message = response ? 'Se actualizo correctamente el viaje.' : 'Hubo un error actualizando el viaje.';
				alert(message);
				this.switchLoading(false)
				if (response) {
					this.$router.push({ path: '/admin/lista-viajes' });
				}

			} catch (error) {
				this.switchLoading(false)
			}
		},
		cleanDataToEdit() {
			this.type = "";
			this.dataEditItinerary = null;
		},

		async onLoadDataTrip(tripId) {
			await this.getTrip(tripId);
			this.typeSubmit = 'update';
		}
	},
	created() {

		const tripId = this.$route.params.id;

		if (typeof tripId != 'undefined') {
			this.onLoadDataTrip(tripId);
		}

	},
	watch: {
		"trip": function (newVal, oldVal) {
			const tripId = this.$route.params.id;
			if (typeof tripId != 'undefined') {
				this.form = newVal;
				this.tripId = tripId;
			}
		}
	}
}
</script>

<style></style>