<template>
  <div id="Nosotros">
    <div id="banner">
      <div class="breadcrumbs d-flex align-items-center pl-sm-5">
        <router-link :to="{ name: 'Home' }" class="pr-0 d-inline"
          >Inicio</router-link
        >
        <span class="pl-1">> Nosotros</span>
      </div>
      <div class="capaOscura">
        <h1 class="font-weight-bold text-white m-0">{{ titulo }}</h1>
        <p class="text-white m-0 d-none">{{ p1 }}</p>
      </div>
    </div>
    <div id="QueEs">
      <h1>{{ quees }}</h1>
      <p>{{ p2 }}</p>
    </div>
    <div id="QueOfrecemos">
      <h1 class="w-100">{{ queofrecemos }}</h1>
      <p class="mb-5 w-75">{{ p3 }}</p>
      <b-row>
        <b-col cols="6" sm="3" class="mb-5 mb-sm-0">
          <img src="@/assets/img/Nosotros/copihue.svg" />
          <h3>{{ iconT1 }}</h3>
          <p>{{ iconP1 }}</p>
        </b-col>
        <b-col cols="6" sm="3">
          <img src="@/assets/img/Nosotros/kultrun.svg" />
          <h3>{{ iconT2 }}</h3>
          <p>{{ iconP2 }}</p>
        </b-col>
        <b-col cols="6" sm="3">
          <img src="@/assets/img/Nosotros/volcan.svg" />
          <h3>{{ iconT3 }}</h3>
          <p>{{ iconP3 }}</p>
        </b-col>
        <b-col cols="6" sm="3">
          <img src="@/assets/img/Nosotros/vino.svg" />
          <h3>{{ iconT4 }}</h3>
          <p>{{ iconP4 }}</p>
        </b-col>
      </b-row>
    </div>
    <div id="ComoPorqueLoHacemos">
      <h1>{{ comolohacemos }}</h1>
      <p class="mb-4">{{ p4 }}</p>
      <h1>{{ porque }}</h1>
      <p>{{ p5 }}</p>
    </div>
    <div id="QuienesSomos">
      <h1>{{ quienes }}</h1>
      <b-row>
        <b-col cols="12" sm="6">
          <div class="pablo"></div>
          <h3>PABLO SANHUEZA</h3>
          <p>{{ pablo }}</p>
        </b-col>
        <b-col cols="12" sm="6">
          <div class="carlos"></div>
          <h3>CARLOS SANHUEZA</h3>
          <p>{{ carlos }}</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import Breadcrumbs from "./../components/Breadcrumbs";

export default {
  name: "Nosotros",
  metaInfo() {
    return {
      title: "Nosotros",
      meta: [
        {
          vmid: "canonical",
          name: "canonical",
          content: "https://surreal.travel/Nosotros",
        },
      ],
    };
  },
  components: {
    Breadcrumbs,
  },
  data() {
    return {
      //
    };
  },
  computed: {
    ...mapGetters("Nosotros", [
      "titulo",
      "p1",
      "quees",
      "p2",
      "queofrecemos",
      "p3",
      "iconT1",
      "iconP1",
      "iconT2",
      "iconP2",
      "iconT3",
      "iconP3",
      "iconT4",
      "iconP4",
      "comolohacemos",
      "p4",
      "porque",
      "p5",
      "quienes",
      "pablo",
      "carlos",
    ]),
  },
};
</script>

<style lang="scss">
#banner {
  margin: 0;
  background: url("../assets/img/Nosotros.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 500px;

  @media screen and (max-width: 524px) {
    height: 325px;
    background: url("../assets/img/NosotrosMobile.png");
    background-position: center;
    background-size: cover;
  }

  .capaOscura {
    height: 464px;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: white;
    text-align: center;
    flex-flow: column wrap;

    @media screen and (max-width: 524px) {
      height: 325px;
    }

    h1 {
      font-size: 58px;
      width: 100%;

      @media screen and (max-width: 524px) {
        font-size: 35px;
      }
    }

    p {
      font-size: 22px;
      width: 65%;
      font-weight: normal;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: medium;
      }
    }
  }
}

#QueEs {
  @media screen and (max-width: 524px) {
    background: #f6f6f6 !important;
  }
}

#QueEs,
#QueOfrecemos,
#ComoPorqueLoHacemos,
#QuienesSomos {
  background: white;
  padding: 50px 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;

  @media screen and (max-width: 524px) {
    padding: 40px 40px;
    text-align: left;
  }

  h1 {
    margin-bottom: 30px;
    color: #3d99ff;
    font-size: 40px;

    @media screen and (max-width: 524px) {
      margin-bottom: 15px;

      font-size: 25px;
      text-align: center;
      font-weight: bold;
    }
  }

  p {
    color: #868686;
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: 524px) {
      font-size: 14px;
    }
  }

  img {
    height: 120px;
    margin-bottom: 10px;

    @media screen and (max-width: 524px) {
      height: 70px;
    }
  }
}

#QueOfrecemos {
  background: #3d99ff;
  height: auto;
  padding: 50px;

  @media screen and (max-width: 524px) {
    padding: 50px 40px;
  }

  h1,
  h3,
  p {
    color: white;

    @media screen and (max-width: 524px) {
      width: 100% !important;
    }
  }

  h3 {
    @media screen and (max-width: 524px) {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 9px;
    }
  }

  .row {
    @media screen and (max-width: 524px) {
      text-align: center;
    }

    p {
      @media screen and (max-width: 524px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

#QuienesSomos {
  background: #f6f6f6;

  h3 {
    @media screen and (max-width: 524px) {
      font-size: 18px;
      font-weight: bold;
      padding: 17px 0;
    }
  }

  @media screen and (max-width: 524px) {
    padding: 50px 80px;
    text-align: center;
  }

  p {
    @media screen and (max-width: 524px) {
      line-height: 22px;
      font-weight: medium;
    }
  }

  .carlos,
  .pablo {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto auto 15px auto;

    @media screen and (max-width: 524px) {
      width: 140px;
      height: 140px;
    }
  }

  .carlos {
    background-image: url("../assets/img/Carlos.png");

    @media screen and (max-width: 524px) {
      margin-top: 29px;
    }
  }

  .pablo {
    background-image: url("../assets/img/Pablo.png");
    background-position: left;
  }
}
</style>

<style lang="scss" scoped>
.breadcrumbs {
  background-color: #353535;
  color: #ffffff;
  height: 36px;
  width: 100%;
  padding: 8px 16px;

  @media (min-width: 524px) {
    font-size: 15px;
  }

  font-size: 9px;
  text-align: left !important;

  a {
    color: #ffffff;
    display: inline-block;
    padding: 0 5px;
    transition: color 300ms linear;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .router-link-active {
    background-color: inherit !important;
    color: white !important;
    font-weight: normal !important;
  }
}
</style>
