<template>
<div id="Footer">
  <div class="row m-auto">
    <div class="col-12 col-md-8 row m-auto contenedor">
      <div class="col-12 col-md-4 mb-3 mb-sm-0" v-show="!isMobile()">
        <h3>{{ Zonas }}</h3>
        <p>Norte y Desierto de Atacama</p>
        <p>Centro, Santiago y Valparaíso</p>
        <p>
          <router-link to="/zonas/sur/overview">Sur, Lagos y Volcanes</router-link>
        </p>
        <p>Patagonia</p>
      </div>
      <div class="col-12 col-md-2 offset-md-1 mb-3 mb-sm-0">
        <h3>{{ Empresa }}</h3>
        <p>
          <router-link to="/Nosotros">{{ Nosotros }}</router-link>
        </p>
        <p>
          <a href="#Contactanos">{{ Contacto }}</a>
        </p>
      </div>
      <div class="col-12 col-md-4 offset-md-1 mb-3 mb-sm-0">
        <h3>{{ Info }}</h3>
        <router-link to="/Terminos">
          <p>
            <a href="#">{{ terminos }}</a>
          </p>
        </router-link>
        <router-link to="/FAQ">
          <p>
            <a href="#">{{ faq }}</a>
          </p>
        </router-link>

        <router-link to="/Privacidad">
          <p>
            <a href="#">{{ privacidad }}</a>
          </p>
        </router-link>

        <!-- <router-link to="/admin">
          <p>
            <a href="#">Admin</a>
          </p>
          </router-link>-->
      </div>
    </div>
    <div class="right col-12 col-md-4 mb-sm-0" v-show="!isMobile()">
      <h3>{{ contactoTouch }}</h3>
      <div class="suscribete">
        <input type="text" :placeholder="placeholder" class="form-control" />
        <button class="btn">{{ suscribete }}</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */
import {
  mapGetters
} from "vuex";

export default {
  name: "Footer",
  computed: {
    ...mapGetters("Footer", [
      "Zonas",
      "Empresa",
      "Nosotros",
      "Contacto",
      "Info",
      "terminos",
      "contactoTouch",
      "faq",
      "privacidad",
      "placeholder",
      "suscribete",
    ]),
  },
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
  }
};
</script>

<style lang="scss" scoped>
#Footer {
  background: #3d99ff;
  color: white;
  padding: 50px 0px;

  @media screen and (max-width: 524px) {
    padding-bottom: 40px;
  }

  >.row {
    width: 90%;

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 17px;

      @media screen and (max-width: 524px) {
        text-align: center;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5rem;

      a {
        color: white;
        text-decoration-line: none;
      }
    }

    .contenedor {
      @media screen and (max-width: 524px) {
        text-align: center;
      }
    }

    .titulos {
      @media screen and (max-width: 524px) {
        margin-top: 20px;
      }
    }

    .right {
      .suscribete {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        @media screen and (max-width: 524px) {
          display: inline-block;
          width: 100%;
          margin-left: auto;
          margin-right: auto;
        }

        input {
          width: 60%;
          margin: 0;
          height: 45px;

          @media screen and (max-width: 524px) {
            width: 100%;
          }
        }

        button {
          width: 35%;
          height: 45px;

          color: white;
          border: white solid 1px;
          padding: 2px 5px;

          @media screen and (max-width: 524px) {
            display: block;
            margin: 20px auto;
            padding: 0;
            font-size: 12px;
            width: 60%;
          }
        }
      }
    }
  }
}
</style>
