<template>
  <div id="Contactanos">
    <div class="capaOscura">
      <h1 class="mb-sm-5">{{ Titulo }}</h1>
      <div class="formInfo">
        <div class="form d-none d-sm-block">
          <input
            type="text"
            class="form-control mb-2"
            :placeholder="Nombre"
            v-model="nombre"
          />
          <b-row>
            <b-col class="p-1"
              ><input
                type="text"
                class="form-control mb-sm-2"
                placeholder="Email"
                v-model="email"
              />
            </b-col>
            <b-col class="p-1"
              ><input
                type="text"
                class="form-control mb-sm-2"
                placeholder="Teléfono"
                v-model="telefono"
            /></b-col>
          </b-row>
          <input
            type="text"
            class="form-control mb-2"
            :placeholder="PlaceholderAsunto"
            v-model="asunto"
          />
          <b-form-textarea
            :placeholder="PlaceholderText"
            v-model="mensaje"
            rows="3"
            class="mb-2"
          ></b-form-textarea>
          <button :disabled="disabledBtn" class="btn" @click="enviarMensaje">{{ Enviar }}</button>
        </div>
        <div class="info">
          <div>
            <p class="text-white">{{ Parrafo }}</p>
            <div class="form d-block d-sm-none">
              <input
                type="text"
                class="form-control mb-2"
                :placeholder="Nombre"
                v-model="nombre"
              />
              <input
                type="text"
                class="form-control mb-sm-2"
                placeholder="Email"
                v-model="email"
              />
              <input
                type="text"
                class="form-control mb-sm-2"
                placeholder="Teléfono"
                v-model="telefono"
              />
              <input
                type="text"
                class="form-control mb-sm-2"
                :placeholder="PlaceholderAsunto"
                v-model="asunto"
              />
              <b-form-textarea
                :placeholder="PlaceholderText"
                v-model="mensaje"
                rows="3"
                class="form-control mb-sm-2"
              ></b-form-textarea>
              <button :disabled="disabledBtn" class="btn" @click="enviarMensaje">{{ Enviar }}</button>
            </div>
            <h6>
              +56952068914<br />
              <i><span>contacto@surreal.travel</span></i>
            </h6>
            <div class="redes d-flex">
              <a href=" https://www.facebook.com/surreal.travel.chile">
                <img src="@/assets/img/iconos/redes/facebook.svg" alt="" />
              </a>

              <a href="https://www.instagram.com/surreal.travel/">
                <img src="@/assets/img/iconos/redes/instagram.svg" alt="" />
              </a>
              <a href="https://cl.linkedin.com/company/surreal-travel/">
                <img src="@/assets/img/iconos/redes/linkedin.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { mapGetters } from "vuex";
import emailjs from "emailjs-com";

export default {
  data() {
    return {
      nombre: "",
      email: "",
      telefono: "569",
      asunto: "",
      mensaje: "",
      disabledBtn: false
    };
  },
  computed: {
    ...mapGetters("Contactanos", [
      "Titulo",
      "Parrafo",
      "PlaceholderAsunto",
      "PlaceholderText",
      "Enviar",
      "Nombre",
    ]),
  },
  methods: {
    async enviarMensaje() {
      this.disabledBtn = true;
      const mensaje = {
        nombre: this.nombre.trim(),
        email: this.email.trim(),
        telefono: this.telefono.trim(),
        asunto: this.asunto.trim(),
        mensaje: this.mensaje.trim(),
      };

      if (
        mensaje.nombre === "" ||
        mensaje.email === "" ||
        mensaje.asunto === "" ||
        mensaje.mensaje === ""
      ) {
        this.$bvToast.toast("Debes completar todos los campos", {
          title: `Alerta`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      if (mensaje.telefono == "569") mensaje.telefono = "";

      const correoValidado = this.validarCorreo(mensaje.email);
      if (!correoValidado) {
        this.$bvToast.toast("Ingresa un correo electrónico válido", {
          title: `Alerta`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      try {
        const serviceID = "default_service";
        const userID = "user_phHSRepn7EuELwmpwDkNi";
        const templateID = "nuevo_mensaje";
        const templateParams = {
          asunto: mensaje.asunto,
          nombre_cliente: mensaje.nombre,
          email_cliente: mensaje.email,
          telefono_cliente: mensaje.telefono,
          mensaje: mensaje.mensaje,
        };
        await emailjs.send(serviceID, templateID, templateParams, userID);
        console.log(templateParams);

        this.$bvToast.toast("Mensaje enviado", {
          title: `Alerta`,
          variant: "success",
          solid: true,
          toaster: "b-toaster-top-center",
        });

        this.nombre = "";
        this.email = "";
        this.telefono = "";
        this.asunto = "";
        this.mensaje = "";
      } catch (error) {
        console.log("Error al enviar correo electrónico con mensaje", error);
      }
      this.disabledBtn = false;

    },
    validarCorreo(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  },
};
</script>

<style lang="scss">
#Contactanos {
  background: url("../assets/img/contactanos/contactanos.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 650px;
  color: white;
  @media screen and (max-width: 524px) {
    height: 100%;
    text-align: center;
    width: 100%;
    margin: auto;
  }
  .capaOscura {
    padding: 50px 150px;
    background: rgba(0, 0, 0, 0.4);
    height: 650px;
    @media screen and (max-width: 524px) {
      height: 100%;
      padding: 30px 37px 48px;
    }
  }
  h1 {
    text-align: center;
    font-size: 48px;
    font-weight: bold;
    @media screen and (max-width: 524px) {
      font-size: 25px;
      line-height: 30px;
      margin-bottom: 16px;
    }
  }
  .formInfo {
    display: flex;
    width: 100%;
    div {
      padding: 10px;
      @media screen and (max-width: 524px) {
        padding: 0;
      }
    }
    @media screen and (max-width: 524px) {
      flex-flow: column-reverse;
      align-items: center;
    }
    .form {
      width: 50%;
      @media screen and (max-width: 524px) {
        width: 100%;
        margin-top: 20px;
        margin-bottom: 30px;
      }
      .form-control {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          color: #868686;
          margin-bottom: 9px;
        }
      }
      h6 {
        font-size: 25px;
        font-weight: 400;
        letter-spacing: 0.3px;
        @media screen and (max-width: 524px) {
          font-size: 19px;
        }
      }
      .redes {
        width: 50%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 524px) {
          justify-content: center;
          width: 100%;
          margin-top: 0px;
        }
        a img {
          @media screen and (max-width: 524px) {
            margin-left: 38px;
            margin-right: 38px;
          }
        }
      }
      input,
      select,
      button {
        height: 60px;
        border-radius: 6px;
        @media screen and (max-width: 524px) {
          height: 45px;
        }
      }
      textarea {
        height: 180px;
        border-radius: 6px;
      }
      button {
        background: #3d99ff;
        color: white;
        width: 100%;
        font-size: 18px;
        @media screen and (max-width: 524px) {
          font-size: 16px;
        }
      }
    }
    .info {
      width: 50%;
      margin-left: 7vw;
      display: flex;
      justify-content: center;
      @media screen and (max-width: 524px) {
        width: 100%;
        margin-left: 0;
      }
      p {
        font-size: 22px;
        font-weight: lighter;
        @media screen and (max-width: 524px) {
          font-size: 13px;
          font-weight: 400;
          line-height: 25px;
        }
      }
      h6 {
        font-size: 25px;
        font-weight: 400;
        letter-spacing: 0.3px;
        @media screen and (max-width: 524px) {
          font-size: 19px;
        }
        i {
          @media screen and (max-width: 524px) {
            margin-top: 10px;
            display: block;
          }
        }
      }
      .redes {
        width: 50%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 524px) {
          justify-content: center;
          width: 100%;
          margin-top: 21px;
        }
        a img {
          @media screen and (max-width: 524px) {
            margin-left: 38px;
            margin-right: 38px;
          }
        }
      }
    }
  }
}
</style>

