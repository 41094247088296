<template>
  <div id="Checkout">
    <div :class="{ esperarTBK: boolEsperaTBK, 'd-none': !boolEsperaTBK }">
      <img
        src="@/assets/img/logo.png"
        width="250"
        alt="SurRealLogo"
        id="LogoLoadingReserva"
      />
      <p>Espere un momento</p>
    </div>
    <div class="checkout" v-if="checkout">
      <div class="contain">
        <h1 class="titulo">Checkout</h1>
      </div>
    </div>
    <div class="algoPaso" v-if="algoPaso">
      <div class="contain">
        <img
          src="@/assets/img/iconos/xGrande.svg"
          class="d-block m-auto pb-4"
        />
        <h1 class="titulo">{{ algopaso }}</h1>
        <div class="w-100 text-center">
          <button class="btn volverCheckout m-auto" @click="showPagoAceptado">
            {{ volverpago }}
          </button>
        </div>
      </div>
    </div>

    <div class="pagoAceptado" v-if="pagoAceptado">
      <div class="contain">
        <img
          src="@/assets/img/iconos/checkGrande.svg"
          class="d-block m-auto pb-sm-5"
        />
        <h1 class="titulo">{{ reservap }}</h1>
        <!-- <p class="text-white m-0 text-center">
          {{ recuerda1 }}
          <span class="d-sm-none d-inline">{{ recuerda2 }}</span>
          <span class="d-sm-inline d-none">
            <br />
            {{ recuerda2 }}
          </span>
        </p> -->
      </div>
    </div>

    <Stepper v-if="checkout" />

    <div class="tuOrden container py-sm-5" v-if="checkout">
      <span
        class="azul font-weight-bold"
        :class="{ 'd-block': tuOrden, 'd-none': !tuOrden }"
        >{{ tuorden }}</span
      >

      <hr class="azul" :class="{ 'd-block': tuOrden, 'd-none': !tuOrden }" />

      <p v-if="reserva && !reserva.reservas[0]">Sin reservas</p>

      <div
        v-for="(reserva, index) in reserva.reservas"
        :key="index"
        class="reservas"
      >
        <table
          class="text-center my-3"
          :class="{
            /* 'd-block': tuOrden, 'd-none': !tuOrden */
          }"
        >
          <tr :class="{ hidden: index > 0 }">
            <th>{{ producto }}</th>
            <!-- <th class="d-none" style="minwidth: 350px">
              {{ detallesNom }}
            </th> -->
            <!-- <th style="width: 100px">{{ categoriaNom }}</th>
            <th style="width: 100px">{{ precioNom }}</th>
            <th style="width: 100px">{{ cantidadNom }}</th>
            <th style="width: 100px">{{ totalNom }}</th> -->
          </tr>
          <tr>
            <td>
              <div class="row lista-experiencias">
                <div
                  class="
                    col-1
                    d-sm-flex
                    align-items-center
                    X
                    h4
                    font-weight-bold
                    d-none
                  "
                  style="color: #ff0031; cursor: pointer"
                  @click="deleteReserva(index)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    height="20px"
                    id="Layer_1"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="20px"
                    xml:space="preserve"
                    fill="red"
                  >
                    <path
                      d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
                    />
                  </svg>
                </div>
                <router-link
                  :to="`/experiencias/${reserva.experienciaId}`"
                  tag="div"
                  class="col-3 col-sm-4 px-0 px-sm-2 flex"
                  style="cursor: pointer"
                >
                  <img
                    v-if="reserva && reserva.experienciaId"
                    :src="
                      getThumbnail({
                        contentUrl: experiencia({ id: reserva.experienciaId })
                          .carouselImgUrl,
                        size: 480,
                      })
                    "
                    width="228"
                    height="190"
                    alt
                  />
                </router-link>
                <div class="col-7 pl-0 pl-sm-2 text-left">
                  <router-link
                    :to="`/experiencias/${reserva.experienciaId}`"
                    tag="div"
                    class="p-2"
                    style="cursor: pointer"
                  >
                    <h2 class="titulos">
                      {{ language === 'es' ? experiencia({ id: reserva.experienciaId }).titulo : experiencia({ id: reserva.experienciaId }).ingles.titulo }}
                    </h2>
                    <p
                      v-if="experiencia({ id: reserva.experienciaId })"
                      class="m-0"
                    >
                      {{__get('date')}}:
                      {{
                        experiencia({ id: reserva.experienciaId }).modalidad ===
                          "rangos" ||
                        experiencia({ id: reserva.experienciaId }).modalidad ===
                          "rangosTemporada"
                          ? reserva.disponibilidad.start
                          : reserva.disponibilidad | toReadableDate(language)
                      }}
                    </p>
                  </router-link>
                  <p class="pl-sm-2" style="fontsize: 12px">
                    <strong>{{__get('dynamicRate')}}:</strong> {{__get('messageDynamicRate')}}
                  </p>
                </div>
                <div class="col-2 d-sm-none d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    height="20px"
                    id="Layer_1"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="20px"
                    xml:space="preserve"
                    @click="deleteReserva({ index })"
                  >
                    <path
                      d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
                    />
                  </svg>
                </div>
              </div>
            </td>
            <!-- <td class="d-none">
              <table>
                <tr>
                  <th>{{ categoriaNom }}</th>
                  <th>{{ precioNom }}</th>
                  <th>{{ cantidadNom }}</th>
                  <th>{{ totalNom }}</th>
                </tr>
                <tr>
                  <td>
                    Adultos
                  </td>
                  <td>
                    ${{
                      reserva.detalleViajeros.adultos.precio
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                  </td>
                  <td>{{ reserva.detalleViajeros.adultos.cantidad }}</td>
                  <td>
                    ${{
                      reserva.detalleViajeros.adultos.total
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                  </td>
                </tr>
                <template v-if="reserva.detalleViajeros.niños.length">
                  <tr
                    v-for="niño in reserva.detalleViajeros.niños"
                    :key="niño.nombre"
                  >
                    <td>{{ niño.nombre }}</td>
                    <td>
                      ${{
                        niño.precio
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                    </td>
                    <td>{{ niño.cantidad }}</td>
                    <td>
                      ${{
                        niño.total
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }}
                    </td>
                  </tr>
                </template>
              </table>
            </td> -->
            <!-- <td colspan="3">
              <h6
                v-if="
                  experiencia({ id: reserva.experienciaId }).oferta &&
                  precioOferta(
                    experiencia({ id: reserva.experienciaId }),
                    reserva
                  )
                "
                class="font-weight-bold"
              >
                ${{
                  precioOferta(
                    experiencia({ id: reserva.experienciaId }),
                    reserva
                  )
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP
              </h6>
              <h6 v-else class="font-weight-bold">
                ${{
                  precio(experiencia({ id: reserva.experienciaId }), reserva)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP
              </h6>
            </td> -->
            <!-- <td>
              <TablaPasajeros :precioAdulto="reserva.precioAdulto" :cantidadAdultos="reserva.detalleViajeros.adultos" />
            </td> -->

            <!-- <td>
              <ul>
                <li>Adultos: <br />{{ reserva.detalleViajeros.adultos }}</li>
                <template v-for="(niño, i) in reserva.detalleViajeros.niños">
                  <li v-if="niño.cantidad" :key="i">
                    {{ niño.nombre }}: <br />{{ niño.cantidad }}
                  </li>
                </template>
              </ul>
            </td> -->
            <!-- <td>
              <h6
                v-if="
                  experiencia({ id: reserva.experienciaId }).oferta &&
                  precioOferta(
                    experiencia({ id: reserva.experienciaId }),
                    reserva
                  )
                "
                class="font-weight-bold"
              >
                ${{
                  (
                    precioOferta(
                      experiencia({ id: reserva.experienciaId }),
                      reserva
                    ) * reserva.detalleViajeros.adultos
                  )
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP
              </h6>
              <h6 v-else class="font-weight-bold">
                ${{
                  (
                    precio(
                      experiencia({ id: reserva.experienciaId }),
                      reserva
                    ) * reserva.detalleViajeros.adultos
                  )
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP
              </h6>
            </td> -->
          </tr>
        </table>

        <div
          class="row container mb-3"
          :class="{ 'd-flex': tuOrden, 'd-none': !tuOrden }"
        >
          <div class="col-12 d-sm-none d-flex flex-column checkout-info">
            <div>
              <span>Fecha:</span>
              <span v-if="experiencia({ id: reserva.experienciaId })">{{
                experiencia({ id: reserva.experienciaId }).modalidad ===
                  "rangos" ||
                experiencia({ id: reserva.experienciaId }).modalidad ===
                  "rangosTemporada"
                  ? reserva.disponibilidad.start 
                  : reserva.disponibilidad | toReadableDate
              }}</span>
            </div>
            <div>
              <span>Hora Inicio:</span>
              <span>{{ reserva.horaInicio }}</span>
            </div>
            <div>
              <span>Idioma:</span>
              <span>{{ reserva.idioma }}</span>
            </div>
            <div class="d-none">
              <span>Duración:</span>
              <span
                >3 días
                {{
                  new Date(reserva.disponibilidad.end).getTime() -
                  new Date(reserva.disponibilidad.start).getTime()
                }}</span
              >
            </div>
            <div>
              <span>Viajeros:</span>
              <span>{{ reserva.cantidadViajeros }}</span>
            </div>
            <div>
              <span>Detalle viajeros:</span>
              <span
                >{{ reserva.detalleViajeros.adultos.cantidad }} adultos
                <span
                  v-if="reserva.detalleViajeros.niños.length"
                  :style="{ fontWeight: '500' }"
                  >,
                  {{
                    reserva.detalleViajeros.niños
                      .map((n) => `${n.cantidad} ${n.nombre}`)
                      .toLocaleString()
                  }}</span
                ></span
              >
            </div>
            <hr />
            <p class="pl-sm-2 tarifaDinamicaMsj">
              <strong>Tarifa dinámica:</strong> Los precios varían según las
              fechas escogidas.
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <hr class="w-100 d-none d-sm-block" />
        <div
          class="
            col-12 col-sm-6
            order-sm-2
            p-1
            d-flex
            justify-content-end
            checkout-total
          "
        >
          <div>
            <template v-if="reserva.reservas[0]">
              <div
                class="
                  d-flex
                  justify-content-between
                  w250
                  checkout-total__subtotal
                "
              >
                <span>
                  <div>{{__get('adult')}}:</div>
                  <div style="fontsize: 0.8rem">
                    ({{
                      reserva.reservas[0].detalleViajeros.adultos.cantidad
                    }}
                    x ${{
                      reserva.reservas[0].detalleViajeros.adultos.precio
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }})
                  </div>
                </span>
                <span class="font-weight-bold"
                  >${{
                    reserva.reservas[0].detalleViajeros.adultos.total
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                  }}
                  CLP</span
                >
              </div>
              <template v-if="reserva.reservas[0].detalleViajeros.niños.length">
                <div
                  v-for="niño in reserva.reservas[0].detalleViajeros.niños"
                  :key="niño.nombre"
                  class="
                    d-flex
                    justify-content-between
                    w250
                    checkout-total__subtotal
                    pt-1 pt-sm-2
                  "
                >
                  <span>
                    <div>{{ niño.nombre }}:</div>
                    <div style="fontsize: 0.8rem">
                      ({{ niño.cantidad }} x ${{
                        niño.precio
                          .toString()
                          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                      }})
                    </div>
                  </span>
                  <span v-if="SubtotalCheckout" class="font-weight-bold"
                    >${{
                      niño.total
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}
                    CLP</span
                  >
                </div>
              </template>
              <hr />
            </template>
            <div
              class="
                d-flex
                justify-content-between
                w250
                checkout-total__subtotal
              "
            >
              <span>SubTotal:</span>
              <span v-if="SubtotalCheckout" class="font-weight-bold"
                >${{
                  SubtotalCheckout.toString().replace(
                    /(\d)(?=(\d{3})+(?!\d))/g,
                    "$1."
                  )
                }}
                CLP</span
              >
            </div>
            <div
              v-if="descuento > 0"
              class="
                d-flex
                justify-content-between
                w250
                checkout-total__subtotal
              "
            >
              <span>Descuento:</span>
              <span v-if="descuento" class="font-weight-bold text-danger"
                >${{
                  descuento.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP</span
              >
            </div>

            <div
              class="d-flex justify-content-between w250 checkout-total__total"
            >
              <span>Total:</span>
              <span v-if="totalCheckout" class="font-weight-bold"
                >${{
                  totalCheckout
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP</span
              >
            </div>
            <div
              v-if="segundaCuota > 0"
              class="
                d-flex
                justify-content-between
                w250
                checkout-total__subtotal
              "
            >
              <span>{{__get('bookWith')}} ({{ porcentajeAbonoMayor }}%):</span>
              <span v-if="primeraCuota" class="font-weight-bold"
                >${{
                  primeraCuota
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP</span
              >
            </div>
          </div>
        </div>
        <div
          v-bind:class="{ desaparecer: !checkout }"
          class="
            col-12 col-sm-6
            order-sm-1
            p-sm-1 p-3
            cupon-mobile
            d-flex
            align-items-center
          "
        >
          <input
            v-model="cuponInput"
            type="text"
            class="form-control col col-sm-5"
            :placeholder="__get('applyCoupon')"
            maxlength="12"
          />
          <div class="cupon-mobile-btn d-sm-none d-block" @click="aplicarCupon">
            +
          </div>
          <div
            v-bind:class="{ desaparecer: !checkout }"
            class="col-2 col-sm-3 p-1 d-none d-sm-block"
          >
            <button class="cupon btn" @click="aplicarCupon">
              {{ aplicar }}
            </button>
          </div>
        </div>
      </div>
      <!-- Info Reserva por Confirmar -->
      <div
        class="my-5 mensajeAlerta alertaAbono"
        :class="{ 'd-none': reservaInmediata }"
        v-if="segundaCuota > 0"
      >
        <p class="mb-0">
          {{__get('bookPayingOnly')}} ${{
            primeraCuota.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
          }}, {{__get('namely')}} {{ porcentajeAbonoMayor }}% {{__get('ofTheTotal')}}.
        </p>
      </div>
      <!-- Info Reserva por Confirmar -->
      <div
        id="reservaPorConfirmar"
        class="mt-5 mensajeAlerta"
        :class="{ 'd-none': reservaInmediata }"
        v-if="checkout && reserva && reserva.reservas[0]"
      >
        <p><strong>{{__get('important')}}</strong></p>
        <p class="mb-0">
          {{__get('messageImportant')}}
        </p>
      </div>
      <!-- Info Reserva por Confirmar -->

      <!-- Información del Pago -->
      <div
        id="formulario"
        class="mt-sm-2 pt-sm-5"
        v-if="checkout && reserva && reserva.reservas[0]"
      >
        <span class="azul font-weight-bold">{{ pasajero }}</span>

        <hr
          class="azul"
          :class="{ 'd-block': !pagoBlock, 'd-none': pagoBlock }"
        />

        <div class="formulario row container">
          <div class="row col-12 col-sm-6 left d-block">
            <div class="col-12 row">
              <div class="col-12 col-sm-6 p-2">
                <div class>
                  <span>{{ nombre }}</span>
                  <span style="color: red">*</span>
                  <input
                    type="text"
                    v-model="cliente.nombre"
                    class="form-control"
                  />
                </div>
              </div>
              <div class="col-12 col-sm-6 p-2">
                <div class>
                  <span>{{ apellido }}</span>
                  <span style="color: red">*</span>
                  <input
                    type="text"
                    v-model="cliente.apellido"
                    class="form-control"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 p-2">
              <span>{{ email }}</span>
              <span style="color: red">*</span>
              <input
                type="text"
                v-model="cliente.correo"
                class="form-control"
              />
            </div>

            <div class="col-12 p-2">
              <span>{{ telefono }}</span>
              <span style="color: red">*</span>
              <input
                type="text"
                v-model="cliente.telefono"
                class="form-control"
              />
            </div>
            <div class="col-12 p-2">
              <span>{{ pais }}</span>
              <span style="color: red">*</span>
              <select name class="form-control" v-model="cliente.pais" id>
                <!-- <option value disabled>{{ seleccionapais }}</option> -->
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option selected value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
            </div>
            <div class="col-12 p-2 d-none">
              <span>{{ direccion }}</span>
              <span style="color: red">*</span>
              <input type="text" class="form-control" />
            </div>
            <div class="col-12 p-2">
              <span>{{ ciudad }}</span>
              <span style="color: red">*</span>
              <input
                type="text"
                v-model="cliente.ciudad"
                name
                id
                class="form-control"
              />
            </div>
          </div>
          <div class="col-12 col-sm-6 right">
            <div class="col-12 p-2">
              <span>{{ comentarios }}</span>
              <textarea
                type="text"
                name
                id
                class="form-control"
                v-model="CliComentarios"
              ></textarea>
            </div>

            <div class="col-12 p-2">
              <span>{{ sanitarias }}</span>
              <input
                type="text"
                name
                id
                class="form-control"
                v-model="CliVegano"
              />
            </div>
            <div class="col-12 p-2">
              <span>{{ hear }}</span>
              <select class="form-control" v-model="CliReferencia">
                <option value disabled selected>{{ selecciona }}</option>
                <option value="internet">Internet</option>
                <option value="Referencia">Referencia</option>
                <option value="Publicidad">Publicidad</option>
              </select>
            </div>
            <div class="col-12 col-sm-8">
              <span class="flex">
                <input
                  type="radio"
                  v-model="cliente.TerminosYCondiciones"
                  value="true"
                  style="width: 20px"
                />
                <span>
                  {{ terms1 }}
                  <a
                    href="#"
                    style="
                      color: #868686;
                      text-decoration: underline !important;
                    "
                    >{{ terms2 }}</a
                  >
                </span>
                <span style="color: red">*</span>
              </span>
            </div>
            <div class="col-12 flex py-sm-0 py-3 pb-sm-0">
              <span class="flex">
                <input
                  type="radio"
                  style="width: 20px"
                  value="true"
                  v-model="CliPromociones"
                />
                <span class="ml-2">{{ promociones }}</span>
              </span>
            </div>
          </div>
          <div
            class="col-12 col-sm-3 p-2"
            :class="{ 'd-none': !reservaInmediata }"
          >
            <button class="btn btn-block btn-primary ir-pago" @click="irAlPago">
              Ir al pago
            </button>
          </div>
          <div
            class="col-12 col-sm-3 p-2"
            :class="{ 'd-none': reservaInmediata }"
          >
            <button
              class="btn btn-block btn-primary ir-pago noInmediata"
              @click="solicitarReservacion"
            >
              {{__get('requestReservation')}}
            </button>
          </div>
        </div>
        <!-- Fin del formulario -->

        <div
          class="infoPago"
          v-if="pagar"
          :class="{ 'd-block': pagoBlock, 'd-none': !pagoBlock }"
        >
          <span class="azul font-weight-bold">{{ infopago }}</span>

          <hr class="azul" />
          <!-- <p class="azul text-center font-weight-lighter">{{ cargado }}</p>
          <hr class="azul" /> -->

          <div class="formPago row">
            <p class="w-100 text-right d-none">
              <a href="#">{{ mediospago }}</a>
            </p>

            <div class="col-12">
              <img
                class="d-block mx-auto mb-4 w-25 py-3"
                src="@/assets/img/MetodosPagos/transbank.png"
                alt
              />
            </div>

            <div class="col-12 text-center">
              <div
              >
                <button
                  class="
                    btn btn-primary btn-block
                    rounded-pill
                    text-uppercase
                    py-3
                    font-weight-bold
                    text-white
                  "
                  type="submit"
                  @click.prevent="solicitarReservacion()"
                >
                  Pague Aquí
                </button>
              </div>

              <!-- <div class="col-6 col-sm-3">
                <img class="d-block m-auto" src="@/assets/img/MetodosPagos/Paypal.png" alt />
              </div>
              <div class="col-6 col-sm-3">
                <img class="d-block m-auto" src="@/assets/img/MetodosPagos/MasterCard.png" alt />
              </div>
              <div class="col-6 col-sm-3">
                <img class="d-block m-auto" src="@/assets/img/MetodosPagos/Stripe.svg" alt />
              </div>
              <div class="col-6 col-sm-3">
                <img class="d-block m-auto" src="@/assets/img/MetodosPagos/Visa.svg" alt />
              </div>-->
            </div>
            <form
              name="myForm2"
              id="myForm2"
              :action="urlPay"
              method="post"
              ref="myForm2"
            >
              <input type="hidden" name="token_ws" :value="tokenInput" />
            </form>
            <div class="row d-none">
              <div class="col-12 col-sm-6 left infoTitular">
                <span class="h5 p-2">{{ infotitular }}</span>
                <div class="col-12 p-2">
                  <span>{{ nombre }}</span>

                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 p-2">
                  <span>{{ direccion }}</span>

                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 row">
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ ciudad }}</span>
                      <input type="text" name id class="form-control" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ postal }}</span>

                      <input type="text" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-12 p-2">
                  <span class="d-sm-none d-inline">País</span>
                  <select name class="form-control" id>
                    <option value disabled selected>
                      {{ seleccionapais }}
                    </option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">
                      Bosnia & Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option selected value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">
                      French Southern Ter
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">
                      Netherlands (Holland, Europe)
                    </option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">
                      Republic of Montenegro
                    </option>
                    <option value="Republic of Serbia">
                      Republic of Serbia
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">
                      St Pierre & Miquelon
                    </option>
                    <option value="St Vincent & Grenadines">
                      St Vincent & Grenadines
                    </option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">
                      Sao Tome & Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">
                      United Arab Emirates
                    </option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </option>
                    <option value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">
                      Wallis & Futana Is
                    </option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-6 right infoTarjeta">
                <span class="h5 p-2">{{ infocard }}</span>

                <div class="col-12 p-2">
                  <span>{{ creditcard }}</span>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 p-2">
                  <span>{{ nombre }}</span>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 row">
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ venc }}</span>
                      <input type="text" name id class="form-control" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ codecard }}</span>
                      <input type="text" name id class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <hr class="azul d-none" style="padding: 0.5px; background: #3d99ff" />

          <div class="row container terminos d-none">
            <div class="col-12 col-sm-8">
              <span class="flex">
                <input type="radio" style="width: 20px" />
                <span>
                  {{ terms1 }}
                  <a
                    href="#"
                    style="
                      color: #868686;
                      text-decoration: underline !important;
                    "
                    >{{ terms2 }}</a
                  >
                </span>
                <span style="color: red">*</span>
              </span>
            </div>
            <div class="col-12 col-sm-4">
              <button class="btn Procesar" @click="showalgoPaso">
                {{ procesar }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <form
      name="myForm2"
      id="myForm2"
      :action="urlPay"
      method="post"
      ref="myForm2"
    >
      <input type="hidden" name="token_ws" :value="tokenInput" />
    </form>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { mapGetters, mapActions, mapMutations } from "vuex";
import firebase from "firebase";
import axios from "axios";
import moment from "moment";
import Stepper from "@/components/Stepper";
import TablaPasajeros from "@/components/Experiencia/TablaPasajeros";
export default {
  name: "Checkout",
  metaInfo: {
    title: "Checkout",
  },
  components: { Stepper, TablaPasajeros },
  data() {
    return {
      cuponInput: "",
      cupon: null,
      cuponAplicado: false,
      checkout: true,
      algoPaso: false,
      pagoAceptado: false,
      tuOrden: true,
      pagoBlock: true,
      CliComentarios: "",
      CliVegano: "",
      CliReferencia: "",
      CliPromociones: "",
      cliente: {
        nombre: "",
        apellido: "",
        correo: "",
        telefono: "",
        pais: "Chile",
        ciudad: "",
        TerminosYCondiciones: "",
      },
      pagar: false,
      tokenInput: "",
      boolEsperaTBK: false,
      reservaPagada: false,
      now: new Date().getTime().toString(),
      urlPay: ''
    };
  },
  async created() {
    await this.getReservas();
  },
  computed: {
    ...mapGetters(["reserva", "getExperiencias"]),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapGetters("i18n", ["__get"]),
    ...mapGetters([
      "language",
    ]),
    ...mapGetters("Checkout", [
      "tuorden",
      "producto",
      "precioNom",
      "categoriaNom",
      "detallesNom",
      "cantidadNom",
      "totalNom",
      "fecha",
      "aplicar",
      "pasajero",
      "nombre",
      "apellido",
      "email",
      "telefono",
      "dhotel",
      "comentarios",
      "sanitarias",
      "hear",
      "direccion",
      "ciudad",
      "pais",
      "promociones",
      "selecciona",
      "seleccionapais",
      "infopago",
      "cargado",
      "mediospago",
      "infotitular",
      "postal",
      "infocard",
      "creditcard",
      "venc",
      "codecard",
      "terms1",
      "terms2",
      "procesar",
      "volverpago",
      "reservap",
      "recuerda1",
      "recuerda2",
    ]),
    SubtotalCheckout() {
      let total = 0;
      // this.reserva.reservas.forEach((reserva) => {
      //   const exp = this.experiencia({ id: reserva.experienciaId });
      //   if (!exp) return;
      //   if (exp.oferta && this.precioOferta(exp, reserva)) {
      //     total =
      //       total + reserva.cantidadViajeros * this.precioOferta(exp, reserva);
      //   } else {
      //     total = total + reserva.cantidadViajeros * this.precio(exp, reserva);
      //   }
      // });
      // return total;

      total = this.reserva.reservas[0] ? +this.reserva.reservas[0].total : null;
      return total ? total : 0;
    },
    reservaInmediata() {
      const reserva = this.reserva.reservas[0];
      if (!reserva) return null;
      const idExp = reserva.experienciaId;
      if (!idExp) return null;
      const experiencia = this.experiencia({ id: idExp });
      if (experiencia) return experiencia.reserva_inmediata;
      return null;
    },
    descuento() {
      return this.SubtotalCheckout - this.totalCheckout;
    },
    totalCheckout() {
      let total = this.SubtotalCheckout;
      let cupon = this.cuponAplicado;
      if (cupon) {
        let tipo_Descuento = this.cupon.tipo_Descuento;
        let cant = Number(this.cupon.cant);
        tipo_Descuento == "pesos"
          ? (total -= cant)
          : tipo_Descuento == "porcentual"
          ? (total = total * Number(`0.${100 - cant}`))
          : false;
      }

      return total;
    },
    porcentajeAbonoMayor() {
      const reservas = this.reserva.reservas;
      const experiencias = reservas.map((res) => {
        res.experiencia = this.experiencia({ id: res.experienciaId });
        return res;
      });
      const experienciasQuePermitenAbono = experiencias.filter((exp) => {
        const diasAnticipacion = +exp.experiencia.diasAnticipacion;
        const inicioExperiencia = exp.disponibilidad.start;
        const esAntesDeLosDiasDeAnticipacion = moment()
          .add(diasAnticipacion, "d")
          .isBefore(inicioExperiencia);
        const esAlMenosTresDiasAntesDeLaExperiencia = moment()
          .add(3, "d")
          .isBefore(inicioExperiencia);

        return (
          exp.experiencia.permiteAbono &&
          esAntesDeLosDiasDeAnticipacion &&
          esAlMenosTresDiasAntesDeLaExperiencia
        );
      });
      const porcentajeAbono = experienciasQuePermitenAbono.map((exp) =>
        Number(exp.experiencia.porcentajeAbono)
      );
      return porcentajeAbono.length ? Math.max(porcentajeAbono) : 100;
    },
    primeraCuota() {
      return Math.round((this.totalCheckout / 100) * this.porcentajeAbonoMayor);
    },
    segundaCuota() {
      return this.totalCheckout - this.primeraCuota;
    },
    experiencias() {
      const reservas = this.reserva.reservas;
      const experiencias = reservas.map((res) => {
        res.experiencia = this.experiencia({ id: res.experienciaId });
        return res;
      });

      return experiencias;
    },
  },
  methods: {
    aplicarCupon() {
      firebase
        .firestore()
        .collection("cupones")
        .doc(this.cuponInput || " ")
        .get()
        .then((doc) => {
          if (!doc.exists) {
            this.$bvToast.toast(
              "Este cupón no existe o dejó de estar vigente",
              {
                title: `Alerta`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-top-center",
              }
            );
          } else {
            this.cuponAplicado = true;
            this.$bvToast.toast("Cupón Aplicado con éxito", {
              title: `Alerta`,
              variant: "success",
              solid: true,
              toaster: "b-toaster-top-center",
            });
            this.cupon = doc.data();
          }
        })
        .catch((err) => {
          console.log("Error getting document", err);
        });
    },
    ...mapActions(["deleteReserva"]),
    ...mapMutations(["DeleteReserva"]),
    ...mapActions("reservaciones", ["getReservas", "solicitarReserva"]),
    experiencia({ id }) {
      return this.getExperiencias.find((experiencia) => experiencia.id === id);
    },
    precio(experiencia, reserva) {
      const { preciosConfig } = experiencia;
      const reservaConfig = preciosConfig.find((config) => {
        return (
          Number(config.cantidadPersonas) === Number(reserva.cantidadViajeros)
        );
      });
      if (!reservaConfig) {
        return experiencia.precio;
      }
      if (reservaConfig && reservaConfig[reserva.idioma]) {
        return reservaConfig[reserva.idioma];
      }
    },
    precioOferta(experiencia, reserva) {
      const { preciosConfig } = experiencia;
      const reservaConfig = preciosConfig.find((config) => {
        return (
          Number(config.cantidadPersonas) === Number(reserva.cantidadViajeros)
        );
      });
      if (!reservaConfig) {
        return experiencia.precioOferta;
      }
      if (experiencia.oferta) {
        if (reserva.idioma === "Español") return reservaConfig["EspañolOferta"];
        if (reserva.idioma === "Inglés") return reservaConfig["InglésOferta"];
      } else {
        if (reservaConfig && reservaConfig[reserva.idioma]) {
          return reservaConfig[reserva.idioma];
        }
      }
      return experiencia.precioOferta;
    },
    irAlPago() {
      let check = true;
      Object.values(this.cliente).forEach((x) => {
        !x ? (check = false) : false;
        console.log(check);
      });
      if (check) {
        this.tuOrden = false;
        this.pagoBlock = true;
        this.pagar = true;
        window.scrollTo(0, 901);
      } else {
        this.$bvToast.toast("Debes llenar todos los campos obligatorios (*)", {
          title: `Alerta`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
      }
    },
    // irAlPagoPorConfirmar() {
    //   let check = true;
    //   Object.values(this.cliente).forEach((x) => {
    //     !x ? (check = false) : false;
    //     console.log(check);
    //   });
    //   if (check) {
    //     this.tuOrden = false;
    //     this.pagoBlock = true;
    //     this.checkout = false;
    //     this.pagoAceptado = true;
    //     window.scrollTo(0, 0);
    //   } else {
    //     this.$bvToast.toast("Debes llenar todos los campos obligatorios (*)", {
    //       title: `Alerta`,
    //       variant: "danger",
    //       solid: true,
    //       toaster: "b-toaster-top-center",
    //     });
    //   }
    // },
    showalgoPaso() {
      this.checkout = false;
      this.algoPaso = true;
      this.tuOrden = true;
      window.scrollTo(0, 0);
    },
    showcheckout() {
      this.checkout = true;
      this.algoPaso = false;
    },
    showPagoAceptado() {
      this.checkout = false;
      this.algoPaso = false;
      this.pagoAceptado = true;
    },
    async solicitarReservacion() {
      const reserva = this.reserva.reservas[0];
      if (!reserva || !reserva.experienciaId) return;
      const reservaID = reserva.experienciaId;
      //Campos completados
      let check = true;
      Object.values(this.cliente).forEach((x) => {
        !x ? (check = false) : false;
      });
      if (!check) {
        this.$bvToast.toast("Debes llenar todos los campos obligatorios (*)", {
          title: `Alerta`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        return;
      }

      // Es Reserva Inmediata o Por Confirmar.
      const reservaInmediata = this.reservaInmediata;

      if (reservaInmediata) {
        // Añadir animación de espera de token.
        this.boolEsperaTBK = true;
        // Esperar que llegue el token de TBK.
        await this.getTokenTBK();
      }

      // Datos de la Experiencia
      const experiencia = this.experiencia({ id: reservaID }).titulo;
      const experienciaId = reservaID;
      const fechaRaw =
        this.experiencia({ id: reservaID }).modalidad === "rangos" ||
        this.experiencia({ id: reservaID }).modalidad === "rangosTemporada"
          ? reserva.disponibilidad.start
          : reserva.disponibilidad;
      const diasDeExperiencia =
        +this.experiencia({ id: reservaID }).cantidadDias - 1;

      const fecha = Vue.filter(`toReadableDate`)(fechaRaw);
      const fechaFin = Vue.filter(`toReadableDate`)(
        moment(fechaRaw).add(diasDeExperiencia, "d").format()
      );
      const viajeros = reserva.cantidadViajeros;
      const idioma = reserva.idioma;
      const horaInicio = reserva.horaInicio;
      const horaFin = reserva.horaFin;
      const total = this.totalCheckout
        .toString()
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      const idUnico = this.now;
      const fechaActual = Vue.filter(`toReadableDate`)(new Date());
      const token = this.tokenInput;
      const permiteAbono = this.porcentajeAbonoMayor != 100 ? true : false;
      const abono = {
        porcentajeAbono: this.porcentajeAbonoMayor,
        primeraCuota: this.primeraCuota
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
        segundaCuota: this.segundaCuota
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
      };
      const temporadas = reserva.temporadas;
      const detalleViajeros = reserva.detalleViajeros;

      // Normalizo algunos datos del formulario.
      let comentarios = this.CliComentarios;
      if (!comentarios) comentarios = "Sin comentarios";
      let vegano = this.CliVegano;
      if (!vegano) vegano = "Sin información";
      let referencia = this.CliReferencia;
      if (!referencia) referencia = "Sin información";
      let promociones = this.CliPromociones;
      if (!promociones) promociones = "Sin envío de promociones";

      const payload = {
        idUnico,
        nombre: this.cliente.nombre,
        apellido: this.cliente.apellido,
        telefono: this.cliente.telefono,
        ciudad: this.cliente.ciudad,
        pais: this.cliente.pais,
        correo: this.cliente.correo,
        comentarios,
        vegano,
        referencia,
        promociones,
        experiencia,
        experienciaId,
        fecha,
        fechaFin,
        fechaActual,
        viajeros,
        idioma,
        horaInicio,
        horaFin,
        total,
        reservaInmediata,
        token,
        permiteAbono,
        abono,
        temporadas,
        detalleViajeros,
      };

      this.pagoAceptado = true;
      this.checkout = false;
      window.scrollTo(0, 0);

      if (reservaInmediata) {
        await this.solicitarReserva(payload);
        // Submit de formulario de TBK para pasarela de pago.
        await document.forms["myForm2"].submit();
        // console.log('document.forms["myForm2"]', document.getElementById('myForm2'))
        // console.log('this.$refs.myForm2', this.$refs.myForm2)
        // document.getElementById('myForm2').submit()
        // this.$refs.submitButton.click();
        // this.$refs.myForm2.submit();
        // setTimeout(() => {
        //   this.boolEsperaTBK = false;
        // }, 1000);
      } else {
        this.DeleteReserva(0);
        await this.solicitarReserva(payload);
      }
    },
    async getTokenTBK() {
      const url =
        "https://us-central1-sur-real.cloudfunctions.net/listenTBK_State/getToken";
      const id = this.now;
      const totalCheckout = this.totalCheckout;
      const amount = totalCheckout.toString();

      if (!amount || !id || !totalCheckout) return;

      const getToken = await axios.get(url, {
        params: {
          amount,
          id,
        },
      });
      const tokenData = getToken.data.token;
      this.tokenInput = tokenData;
      this.urlPay = getToken.data.url
    },
  },
};
</script>

<style lang="scss">
#Checkout {
  .hidden {
    opacity: 0;
  }

  .titulos {
    font-weight: bold;
    color: #343434;
  }

  .lista-experiencias {
    @media (max-width: 524px) {
      padding: 0px 32px;
    }
  }

  color: #868686;

  .font-weight-bold {
    color: #343434;
  }

  .checkout {
    background: #3d99ff;
    padding: 90px;

    @media screen and (max-width: 524px) {
      padding: 35px 0;
      height: 100px;
    }

    .contain {
      h1 {
        @media screen and (max-width: 524px) {
          font-size: 25px;
          font-weight: bold;
          line-height: 28px;
          margin: 0 auto;
        }
      }
    }
  }

  .tuOrden {
    @media screen and (max-width: 524px) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .row {
      @media screen and (max-width: 524px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    & > span {
      @media screen and (max-width: 524px) {
        display: block;
        background-color: #ededed;
        color: #4a4a4a;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 12px 36px 10px;
      }
    }

    & > hr {
      padding: 0.5px;
      background: #3d99ff;

      @media screen and (max-width: 524px) {
        margin-top: 0;
        width: 100%;
        background: #ededed;
        border-top: #ededed 1px solid;
      }
    }

    & table {
      tr:nth-child(1) {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      img {
        @media screen and (max-width: 524px) {
          width: 69px;
          height: 76px;
        }
      }

      h2 {
        font-size: 25px;

        @media screen and (max-width: 524px) {
          font-size: 14px;
          line-height: 18px;
          color: #4a4a4a;
          text-align: left;
        }
      }

      .X {
        color: #ff0031;
        text-transform: uppercase;

        @media screen and (max-width: 524px) {
          color: #353535;
          font-size: 14px;
        }
      }

      tr:nth-child(2) td:nth-child(1) p {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      tr:nth-child(2) td:nth-child(n + 2) {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      .row-titulo {
        @media screen and (max-width: 524px) {
          justify-content: space-evenly;
        }
      }

      .col-titulo {
        @media screen and (max-width: 524px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    button.ir-pago {
      border-radius: 11px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      padding: 13px 0;
      margin-bottom: 20px;
    }
  }

  .cupon-mobile {
    position: relative;

    &-btn {
      position: absolute;
      right: 35px;
      top: 10px;
      font-size: 34px;
    }
  }

  .checkout-info {
    padding: 5px 20px;

    > div {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #4a4a4a;
        opacity: 0.9;
        line-height: 25px;
        text-align: left;

        & + span {
          font-weight: 500;
        }
      }
    }
  }

  .checkout-total {
    @media screen and (max-width: 524px) {
      padding-right: 20px !important;
    }

    &__subtotal {
      span:nth-child(1) {
        @media screen and (max-width: 524px) {
          font-size: 12px;
          font-weight: 400;
          line-height: 28px;
          color: #4a4a4a;
        }
      }

      span:nth-child(2) {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          font-weight: 400 !important;
          line-height: 28px;
          color: #4a4a4a;
        }
      }
    }

    &__total {
      span:nth-child(1) {
        @media screen and (max-width: 524px) {
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: #4a4a4a;
        }
      }

      span:nth-child(2) {
        @media screen and (max-width: 524px) {
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          color: #4a4a4a;
        }
      }
    }
  }

  .formPago {
    img {
      @media screen and (max-width: 524px) {
        width: 146px;
      }

      // cursor: pointer;
    }
  }

  .algoPaso {
    background: #ff4141;
    padding: 30px;

    @media screen and (max-width: 524px) {
      height: 290px;
    }

    img {
      @media screen and (max-width: 524px) {
        height: 100px;
        width: 100px;
      }
    }

    h1.titulo {
      color: white;
      font-size: 40px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 40px 24px;
      }
    }
  }

  .pagoAceptado {
    background: #3d99ff;
    padding: 30px;

    @media screen and (max-width: 524px) {
      height: 290px;
    }

    img {
      @media screen and (max-width: 524px) {
        height: 100px;
        width: 100px;
      }
    }

    h1.titulo {
      color: white;
      font-size: 40px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 60px 12px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      padding: 0 7px;
    }
  }

  .titulo {
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
  }

  .azul {
    color: #3d99ff;
  }

  input,
  select {
    border-radius: 6px;
    height: 50px;

    @media screen and (max-width: 524px) {
      height: 40px;
    }
  }

  .cupon,
  .Procesar {
    background: #3d99ff;
    border-radius: 6px;
    font-size: 14px;
    height: 50px;
    width: 170px;
    color: white;
  }

  .volverCheckout {
    background: transparent;
    border: groove white;
    border-radius: 6px !important;
    color: white;

    @media screen and (max-width: 524px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      border: solid 2px #ffffff;
      padding: 15px 36px;
    }
  }

  .left,
  .right {
    div {
      @media screen and (max-width: 524px) {
        height: auto;
      }
    }
  }

  .left {
    @media screen and (max-width: 524px) {
      padding-right: 14px !important;
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    div {
      @media screen and (max-width: 524px) {
        margin-left: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .right {
    @media screen and (max-width: 524px) {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    div {
      @media screen and (max-width: 524px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .desaparecer {
    opacity: 0;

    @media screen and (max-width: 524px) {
      display: none;
    }
  }
}
#reservaPorConfirmar {
  border: #3d99ff solid 1px;
  color: #ffffff;
  background-color: #3d99ff;
}

.mensajeAlerta {
  border-radius: 15px;
  display: flex;
  flex-flow: wrap column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
.alertaAbono {
  border: #353535 solid 1px;
  color: #fff;
  background-color: #353535;
}

#formulario {
  & > span {
    @media screen and (max-width: 524px) {
      display: block;
      background-color: #ededed;
      color: #4a4a4a !important;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding: 12px 36px 10px;
    }
  }

  & > hr {
    padding: 0.5px;
    background: #3d99ff;

    @media screen and (max-width: 524px) {
      margin-top: 0;
      width: 100%;
      background: #ededed;
      border-top: #ededed 1px solid;
    }
  }

  .formulario {
    @media screen and (max-width: 524px) {
      padding: 0 15px 0 35px;
    }

    span {
      @media screen and (max-width: 524px) {
        font-size: 14px;
      }
    }
  }

  .infoPago {
    & > span {
      @media screen and (max-width: 524px) {
        display: block;
        background-color: #ededed;
        color: #4a4a4a !important;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 12px 36px 10px;
      }
    }

    & > hr {
      padding: 0.5px;
      background: #3d99ff;

      @media screen and (max-width: 524px) {
        margin-top: 0;
        width: 100%;
        background: #ededed;
        border-top: #ededed 1px solid;
      }
    }

    & > p {
      font-size: 16px !important;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 20px;
        padding: 12px 36px;
      }
    }

    .formPago {
      @media screen and (max-width: 524px) {
        width: 100%;
        padding: 0 35px;
      }

      > p {
        @media screen and (max-width: 524px) {
          text-align: center !important;
          font-size: 12px;
          font-weight: 400;
          text-decoration: underline;
        }

        a {
          color: #3d99ff !important;
        }
      }

      > div:nth-child(1) {
        margin-left: -10px;
      }

      .infoTitular {
        @media screen and (max-width: 524px) {
          padding-left: 14px !important;
        }

        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }

      .infoTarjeta {
        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        @media screen and (max-width: 524px) {
          padding-left: 15px !important;
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }
    }

    .terminos {
      span {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          margin-left: 5px;
        }
      }

      .Procesar {
        background: white;
        border: solid 2px #3d99ff;
        color: #3d99ff;

        @media screen and (max-width: 524px) {
          font-size: 18px;
          font-weight: 400;
          background: #3d99ff;
          border: 0;
          border-radius: 11px;
          color: #ffffff;
          display: block;
          margin: 40px auto;
          width: 100%;
        }
      }
    }
  }
}
#LogoLoadingReserva {
  animation: titilar 1s infinite;
  animation-timing-function: linear;
}
@keyframes titilar {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.esperarTBK {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
  z-index: 99999999;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  p {
    color: #212529;
    margin-top: 2.5%;
    font-size: 1.35rem;
    font-weight: 500;
    font-style: italic;
  }
}
</style>

<style lang="scss" scoped>
#Checkout {
  .tarifaDinamicaMsj {
    font-size: 10px;
  }
  .w250 {
    width: 250px;
  }
}
@media (min-width: 860px) {
  #Checkout {
    .w250 {
      width: 350px;
    }
  }
}
</style>
