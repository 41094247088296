<template>
  <div id="viajes">
    <div>
      <div class="breadcrumbs d-flex align-items-center pl-sm-5">
        <router-link :to="{ name: 'Home' }" class="pr-0 d-inline"
          >{{ Inicio }}</router-link
        >
        <span class="pl-1">> {{ Viajes }}</span>
      </div>
    </div>
    <b-container class="text-center mt-4">
            <nav class="text-center justify-content-center d-flex">
                <ol class="cd-breadcrumb triangle">
                    <li :class="{ 'current': currentTab === 1 }"><a href="javascript:void(0)"><span
                                class="breadcrumb-number">1</span>{{ tamanogrupo  }}</a></li>
                    <li :class="{ 'current': currentTab === 2 }"><a href="javascript:void(0)"><span
                                class="breadcrumb-number">2</span>{{ fechasviaje }}</a></li>
                    <li :class="{ 'current': currentTab === 3 }"><a href="javascript:void(0)"><span
                                class="breadcrumb-number">3</span>{{ crearcuenta }}</a></li>
                    <li :class="{ 'current': currentTab === 4 }"><a href="javascript:void(0)"><span
                                class="breadcrumb-number">4</span>{{ detalleviaje }}</a></li>
                </ol>
            </nav>
    </b-container>

    <section class="mt-2 p-4">
        <div class="container h-100">
            <div v-if="currentTab === 1">
                <h1 class="text-center my-4">{{ comoviajaras }}</h1>
                <div class="options-container m-auto">
                    <div class="option" @click="changeTravel(1)">
                        <div class="card" :class="{ 'selected': cliente.travel === 1 }" style="cursor: pointer;">
                            <i class="fas fa-user-friends fa-3x m-auto"></i>
                            <span>{{ pareja }}</span>
                        </div>
                    </div>
                    <div class="option" @click="changeTravel(2)">
                        <div class="card" :class="{ 'selected': cliente.travel === 2 }" style="cursor: pointer;">
                            <i class="fas fa-user fa-3x m-auto"></i>
                            <span>{{ individual }}</span>
                        </div>
                    </div>
                    <div class="option" @click="changeTravel(3)">
                        <div class="card" :class="{ 'selected': cliente.travel === 3 }" style="cursor: pointer;">
                            <i class="fas fa-users fa-3x m-auto"></i>
                            <span>{{ familia }}</span>
                        </div>
                    </div>
                    <div class="option" @click="changeTravel(4)">
                        <div class="card" :class="{ 'selected': cliente.travel === 4 }" style="cursor: pointer;">
                            <i class="fas fa-users fa-3x m-auto"></i>
                            <span>{{ grupo }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-center" v-if="currentTab === 2">
                <h1 class="text-center my-4">{{ cuandoviajaras }}</h1>
                <div class="mx-auto mb-4" style="max-width: 500px">
                    <button class="btn btn-block btn-middle w-100"  @click="changeWillTravel(1)"  :class="{ 'selected-btn': cliente.willTravel === 1 }"> {{ fechaexacta }}</button>
                    <button class="btn btn-block btn-middle w-100"  @click="changeWillTravel(2)"  :class="{ 'selected-btn': cliente.willTravel === 2 }">{{ fechaaprox }}</button>
                    <button class="btn btn-block btn-middle w-100"  @click="changeWillTravel(3)"  :class="{ 'selected-btn': cliente.willTravel === 3 }"> {{ nofecha }}</button>
                    <div class="input-group date mt-1" v-if="cliente.willTravel == 1">
                      <vc-date-picker
                        ref="calendar"
                         @input="handleChangeFecha"
                         :value="cliente.fechaSeleccionada"
                        mode="range"
                        name="fechaSeleccionada"
                        is-inline
                      />
                    </div>
                    <div class="row mt-1" id="approximate-dates-picker" v-if="cliente.willTravel == 2">
                      <div class="col-sm-6">
                        <div class="form-group" style="max-width: 230px;">
                          <label>{{ messalida  }}</label>
                          <select class="form-control" :value="cliente.mesSeleccionada" name="mesSeleccionada" @input="handleChange" style="cursor: pointer">
                            <option value="">Choose month</option>
                            <option style="cursor: pointer" v-for="month in availableMonths" :key="month" :value="month">{{ month }}</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="form-group" style="margin-left: auto; margin-right: auto; max-width: 170px;">
                          <label>{{ aproxday }}</label>
                          <div id="select-approximate-duration-wrapper" class="d-flex justify-content-between align-items-center">
                            <button @click="decrementDuration" class="btn btn-outline-secondary px-3 p-0" type="button" data-behavior="minus">
                              <i class="fa fa-minus"></i>
                            </button>
                            <input @input="handleChange" type="number" :value="cliente.duration" name="duration" id="trip-duration" class="form-control text-center disable-spin-button" placeholder="0" max="365" data-hj-allow="true">
                            <button @click="incrementDuration" class="btn btn-outline-secondary px-3 p-0" type="button" data-behavior="plus">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-1 justify-content-center" id="approximate-dates-picker" v-if="cliente.willTravel == 3">
                      <div class="form-group" style="margin-left: auto; margin-right: auto; max-width: 200px;">
                          <label>{{ aproxday }}</label>
                          <div id="select-approximate-duration-wrapper" class="d-flex justify-content-between align-items-center">
                            <button @click="decrementDuration" class="btn btn-outline-secondary px-3 p-0" type="button" data-behavior="minus">
                              <i class="fa fa-minus"></i>
                            </button>
                            <input type="number" @input="handleChange" :value="cliente.duration" name="duration" id="trip-duration" class="form-control text-center disable-spin-button" placeholder="0" max="365" data-hj-allow="true">
                            <button @click="incrementDuration" class="btn btn-outline-secondary px-3 p-0" type="button" data-behavior="plus">
                              <i class="fa fa-plus"></i>
                            </button>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="currentTab === 3" class="">
                  <div class="formulario row container justify-content-center">
                    <div class="row col-12 col-sm-6 left d-block">
                      <div class="col-12 row">
                        <div class="col-12 col-sm-6 p-2">
                          <div class>
                            <span>{{ nombre }}</span>
                            <span style="color: red">*</span>
                            <input
                              type="text"
                              :value="cliente.nombre"
                              name="nombre"
                              @input="handleChange"
                              class="form-control"
                            />
                          </div>
                        </div>
                        <div class="col-12 col-sm-6 p-2">
                          <div class>
                            <span>{{ apellido }}</span>
                            <span style="color: red">*</span>
                            <input
                              type="text"
                              :value="cliente.apellido"
                              name="apellido"
                              class="form-control"
                              @input="handleChange"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-12 p-2">
                        <span>{{ email }}</span>
                        <span style="color: red">*</span>
                        <input
                          type="text"
                          :value="cliente.correo"
                          class="form-control" name="correo" @input="handleChange"
                        />
                      </div>

                      <div class="col-12 p-2">
                        <span>{{ telefono }}</span>
                        <span style="color: red">*</span>
                        <input
                          type="text"
                          :value="cliente.telefono"
                          name="telefono"
                          class="form-control" @input="handleChange"
                        />
                      </div>
                      <div class="col-12 p-2">
                        <span>{{ pais }}</span>
                        <span style="color: red">*</span>
                        <select class="form-control" @input="handleChange" name="pais" :value="cliente.pais" id>
                          <!-- <option value disabled>{{ seleccionapais }}</option> -->
                          <option value="Afganistan">Afghanistan</option>
                          <option value="Albania">Albania</option>
                          <option value="Algeria">Algeria</option>
                          <option value="American Samoa">American Samoa</option>
                          <option value="Andorra">Andorra</option>
                          <option value="Angola">Angola</option>
                          <option value="Anguilla">Anguilla</option>
                          <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                          <option value="Argentina">Argentina</option>
                          <option value="Armenia">Armenia</option>
                          <option value="Aruba">Aruba</option>
                          <option value="Australia">Australia</option>
                          <option value="Austria">Austria</option>
                          <option value="Azerbaijan">Azerbaijan</option>
                          <option value="Bahamas">Bahamas</option>
                          <option value="Bahrain">Bahrain</option>
                          <option value="Bangladesh">Bangladesh</option>
                          <option value="Barbados">Barbados</option>
                          <option value="Belarus">Belarus</option>
                          <option value="Belgium">Belgium</option>
                          <option value="Belize">Belize</option>
                          <option value="Benin">Benin</option>
                          <option value="Bermuda">Bermuda</option>
                          <option value="Bhutan">Bhutan</option>
                          <option value="Bolivia">Bolivia</option>
                          <option value="Bonaire">Bonaire</option>
                          <option value="Bosnia & Herzegovina">
                            Bosnia & Herzegovina
                          </option>
                          <option value="Botswana">Botswana</option>
                          <option value="Brazil">Brazil</option>
                          <option value="British Indian Ocean Ter">
                            British Indian Ocean Ter
                          </option>
                          <option value="Brunei">Brunei</option>
                          <option value="Bulgaria">Bulgaria</option>
                          <option value="Burkina Faso">Burkina Faso</option>
                          <option value="Burundi">Burundi</option>
                          <option value="Cambodia">Cambodia</option>
                          <option value="Cameroon">Cameroon</option>
                          <option value="Canada">Canada</option>
                          <option value="Canary Islands">Canary Islands</option>
                          <option value="Cape Verde">Cape Verde</option>
                          <option value="Cayman Islands">Cayman Islands</option>
                          <option value="Central African Republic">
                            Central African Republic
                          </option>
                          <option value="Chad">Chad</option>
                          <option value="Channel Islands">Channel Islands</option>
                          <option selected value="Chile">Chile</option>
                          <option value="China">China</option>
                          <option value="Christmas Island">Christmas Island</option>
                          <option value="Cocos Island">Cocos Island</option>
                          <option value="Colombia">Colombia</option>
                          <option value="Comoros">Comoros</option>
                          <option value="Congo">Congo</option>
                          <option value="Cook Islands">Cook Islands</option>
                          <option value="Costa Rica">Costa Rica</option>
                          <option value="Cote DIvoire">Cote DIvoire</option>
                          <option value="Croatia">Croatia</option>
                          <option value="Cuba">Cuba</option>
                          <option value="Curaco">Curacao</option>
                          <option value="Cyprus">Cyprus</option>
                          <option value="Czech Republic">Czech Republic</option>
                          <option value="Denmark">Denmark</option>
                          <option value="Djibouti">Djibouti</option>
                          <option value="Dominica">Dominica</option>
                          <option value="Dominican Republic">Dominican Republic</option>
                          <option value="East Timor">East Timor</option>
                          <option value="Ecuador">Ecuador</option>
                          <option value="Egypt">Egypt</option>
                          <option value="El Salvador">El Salvador</option>
                          <option value="Equatorial Guinea">Equatorial Guinea</option>
                          <option value="Eritrea">Eritrea</option>
                          <option value="Estonia">Estonia</option>
                          <option value="Ethiopia">Ethiopia</option>
                          <option value="Falkland Islands">Falkland Islands</option>
                          <option value="Faroe Islands">Faroe Islands</option>
                          <option value="Fiji">Fiji</option>
                          <option value="Finland">Finland</option>
                          <option value="France">France</option>
                          <option value="French Guiana">French Guiana</option>
                          <option value="French Polynesia">French Polynesia</option>
                          <option value="French Southern Ter">French Southern Ter</option>
                          <option value="Gabon">Gabon</option>
                          <option value="Gambia">Gambia</option>
                          <option value="Georgia">Georgia</option>
                          <option value="Germany">Germany</option>
                          <option value="Ghana">Ghana</option>
                          <option value="Gibraltar">Gibraltar</option>
                          <option value="Great Britain">Great Britain</option>
                          <option value="Greece">Greece</option>
                          <option value="Greenland">Greenland</option>
                          <option value="Grenada">Grenada</option>
                          <option value="Guadeloupe">Guadeloupe</option>
                          <option value="Guam">Guam</option>
                          <option value="Guatemala">Guatemala</option>
                          <option value="Guinea">Guinea</option>
                          <option value="Guyana">Guyana</option>
                          <option value="Haiti">Haiti</option>
                          <option value="Hawaii">Hawaii</option>
                          <option value="Honduras">Honduras</option>
                          <option value="Hong Kong">Hong Kong</option>
                          <option value="Hungary">Hungary</option>
                          <option value="Iceland">Iceland</option>
                          <option value="Indonesia">Indonesia</option>
                          <option value="India">India</option>
                          <option value="Iran">Iran</option>
                          <option value="Iraq">Iraq</option>
                          <option value="Ireland">Ireland</option>
                          <option value="Isle of Man">Isle of Man</option>
                          <option value="Israel">Israel</option>
                          <option value="Italy">Italy</option>
                          <option value="Jamaica">Jamaica</option>
                          <option value="Japan">Japan</option>
                          <option value="Jordan">Jordan</option>
                          <option value="Kazakhstan">Kazakhstan</option>
                          <option value="Kenya">Kenya</option>
                          <option value="Kiribati">Kiribati</option>
                          <option value="Korea North">Korea North</option>
                          <option value="Korea Sout">Korea South</option>
                          <option value="Kuwait">Kuwait</option>
                          <option value="Kyrgyzstan">Kyrgyzstan</option>
                          <option value="Laos">Laos</option>
                          <option value="Latvia">Latvia</option>
                          <option value="Lebanon">Lebanon</option>
                          <option value="Lesotho">Lesotho</option>
                          <option value="Liberia">Liberia</option>
                          <option value="Libya">Libya</option>
                          <option value="Liechtenstein">Liechtenstein</option>
                          <option value="Lithuania">Lithuania</option>
                          <option value="Luxembourg">Luxembourg</option>
                          <option value="Macau">Macau</option>
                          <option value="Macedonia">Macedonia</option>
                          <option value="Madagascar">Madagascar</option>
                          <option value="Malaysia">Malaysia</option>
                          <option value="Malawi">Malawi</option>
                          <option value="Maldives">Maldives</option>
                          <option value="Mali">Mali</option>
                          <option value="Malta">Malta</option>
                          <option value="Marshall Islands">Marshall Islands</option>
                          <option value="Martinique">Martinique</option>
                          <option value="Mauritania">Mauritania</option>
                          <option value="Mauritius">Mauritius</option>
                          <option value="Mayotte">Mayotte</option>
                          <option value="Mexico">Mexico</option>
                          <option value="Midway Islands">Midway Islands</option>
                          <option value="Moldova">Moldova</option>
                          <option value="Monaco">Monaco</option>
                          <option value="Mongolia">Mongolia</option>
                          <option value="Montserrat">Montserrat</option>
                          <option value="Morocco">Morocco</option>
                          <option value="Mozambique">Mozambique</option>
                          <option value="Myanmar">Myanmar</option>
                          <option value="Nambia">Nambia</option>
                          <option value="Nauru">Nauru</option>
                          <option value="Nepal">Nepal</option>
                          <option value="Netherland Antilles">Netherland Antilles</option>
                          <option value="Netherlands">
                            Netherlands (Holland, Europe)
                          </option>
                          <option value="Nevis">Nevis</option>
                          <option value="New Caledonia">New Caledonia</option>
                          <option value="New Zealand">New Zealand</option>
                          <option value="Nicaragua">Nicaragua</option>
                          <option value="Niger">Niger</option>
                          <option value="Nigeria">Nigeria</option>
                          <option value="Niue">Niue</option>
                          <option value="Norfolk Island">Norfolk Island</option>
                          <option value="Norway">Norway</option>
                          <option value="Oman">Oman</option>
                          <option value="Pakistan">Pakistan</option>
                          <option value="Palau Island">Palau Island</option>
                          <option value="Palestine">Palestine</option>
                          <option value="Panama">Panama</option>
                          <option value="Papua New Guinea">Papua New Guinea</option>
                          <option value="Paraguay">Paraguay</option>
                          <option value="Peru">Peru</option>
                          <option value="Phillipines">Philippines</option>
                          <option value="Pitcairn Island">Pitcairn Island</option>
                          <option value="Poland">Poland</option>
                          <option value="Portugal">Portugal</option>
                          <option value="Puerto Rico">Puerto Rico</option>
                          <option value="Qatar">Qatar</option>
                          <option value="Republic of Montenegro">
                            Republic of Montenegro
                          </option>
                          <option value="Republic of Serbia">Republic of Serbia</option>
                          <option value="Reunion">Reunion</option>
                          <option value="Romania">Romania</option>
                          <option value="Russia">Russia</option>
                          <option value="Rwanda">Rwanda</option>
                          <option value="St Barthelemy">St Barthelemy</option>
                          <option value="St Eustatius">St Eustatius</option>
                          <option value="St Helena">St Helena</option>
                          <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                          <option value="St Lucia">St Lucia</option>
                          <option value="St Maarten">St Maarten</option>
                          <option value="St Pierre & Miquelon">
                            St Pierre & Miquelon
                          </option>
                          <option value="St Vincent & Grenadines">
                            St Vincent & Grenadines
                          </option>
                          <option value="Saipan">Saipan</option>
                          <option value="Samoa">Samoa</option>
                          <option value="Samoa American">Samoa American</option>
                          <option value="San Marino">San Marino</option>
                          <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                          <option value="Saudi Arabia">Saudi Arabia</option>
                          <option value="Senegal">Senegal</option>
                          <option value="Seychelles">Seychelles</option>
                          <option value="Sierra Leone">Sierra Leone</option>
                          <option value="Singapore">Singapore</option>
                          <option value="Slovakia">Slovakia</option>
                          <option value="Slovenia">Slovenia</option>
                          <option value="Solomon Islands">Solomon Islands</option>
                          <option value="Somalia">Somalia</option>
                          <option value="South Africa">South Africa</option>
                          <option value="Spain">Spain</option>
                          <option value="Sri Lanka">Sri Lanka</option>
                          <option value="Sudan">Sudan</option>
                          <option value="Suriname">Suriname</option>
                          <option value="Swaziland">Swaziland</option>
                          <option value="Sweden">Sweden</option>
                          <option value="Switzerland">Switzerland</option>
                          <option value="Syria">Syria</option>
                          <option value="Tahiti">Tahiti</option>
                          <option value="Taiwan">Taiwan</option>
                          <option value="Tajikistan">Tajikistan</option>
                          <option value="Tanzania">Tanzania</option>
                          <option value="Thailand">Thailand</option>
                          <option value="Togo">Togo</option>
                          <option value="Tokelau">Tokelau</option>
                          <option value="Tonga">Tonga</option>
                          <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                          <option value="Tunisia">Tunisia</option>
                          <option value="Turkey">Turkey</option>
                          <option value="Turkmenistan">Turkmenistan</option>
                          <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                          <option value="Tuvalu">Tuvalu</option>
                          <option value="Uganda">Uganda</option>
                          <option value="United Kingdom">United Kingdom</option>
                          <option value="Ukraine">Ukraine</option>
                          <option value="United Arab Erimates">
                            United Arab Emirates
                          </option>
                          <option value="United States of America">
                            United States of America
                          </option>
                          <option value="Uraguay">Uruguay</option>
                          <option value="Uzbekistan">Uzbekistan</option>
                          <option value="Vanuatu">Vanuatu</option>
                          <option value="Vatican City State">Vatican City State</option>
                          <option value="Venezuela">Venezuela</option>
                          <option value="Vietnam">Vietnam</option>
                          <option value="Virgin Islands (Brit)">
                            Virgin Islands (Brit)
                          </option>
                          <option value="Virgin Islands (USA)">
                            Virgin Islands (USA)
                          </option>
                          <option value="Wake Island">Wake Island</option>
                          <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                          <option value="Yemen">Yemen</option>
                          <option value="Zaire">Zaire</option>
                          <option value="Zambia">Zambia</option>
                          <option value="Zimbabwe">Zimbabwe</option>
                        </select>
                      </div>
                      <div class="col-12 p-2">
                        <span>{{ ciudad }}</span>
                        <span style="color: red">*</span>
                        <input
                          type="text"
                          :value="cliente.ciudad"
                          name="ciudad"
                          id
                          class="form-control"
                          @input="handleChange"
                        />
                      </div>
                    </div>
                  </div>
            </div>

            <div v-if="currentTab === 4" class="text-center">
              <h2 class="text-center mb-2 pb-0">{{ describeviaje }}</h2>
              <span class="mb-2">{{ especialistalocal }}</span>
                <div class="mx-auto mb-4" style="max-width: 450px">
                    <input class="form-control" name="title" :placeholder="titulo" :value="cliente.title" @input="handleChange" />
                    <div class="form-group w-100">
                        <label class="form-label-control text-left">{{ tupresupuesto }}</label>
                        <div class="input-group">
                            <input class="form-control" type="number" id="presupuesto" name="presupuesto" @input="handleChange" :value="cliente.presupuesto" />
                            <div class="input-group-append">
                                <select class="form-control" id="moneda" name="moneda" @input="handleChange">
                                    <option value="CLP">Pesos Chilenos (CLP)</option>
                                    <option value="USD">Dólares Estadounidenses (USD)</option>
                                    <option value="EUR">Euros (EUR)</option>
                                </select>
                            </div>
                        </div>
                    </div>

                    <textarea :value="cliente.description" :placeholder="describeloquequieres" name="description" @input="handleChange" rows="6" class="form-control mt-2"></textarea>
                </div>
            </div>
        </div>
        <div class="button-container my-4">
            <button class="btn" @click="previousTab" :disabled="currentTab === 1" v-if="currentTab > 1">{{ anterior }}</button>
            <button class="btn" @click="nextTab" v-if="currentTab < 4">{{ siguiente }}</button>
            <button class="btn" v-else @click="submitUsuario">Guardar</button>
        </div>
    </section>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { mapGetters, mapActions, mapMutations   } from "vuex";
import Breadcrumbs from "./../components/Breadcrumbs";
import moment from "moment";

export default {
  name: "ReservaPersonalizada",
  metaInfo() {
    return {
      title: "ReservaPersonalizada"
    };
  },
  components: {
    Breadcrumbs,
  },
  data() {
    const today = new Date();
    const availableMonths = [];
    for (let i = 0; i <= 12; i++) {
      const date = new Date(today.getFullYear(), today.getMonth() + i, 1);
      const formattedMonth = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long'
      }).format(date);
      availableMonths.push(formattedMonth);
    }
    return {
      availableMonths,
      currentTab: 1
    };
  },
  computed: {
    ...mapGetters("usuarios",[
      ' '
    ]),
    ...mapGetters("Checkout", [
      "tuorden",
      "producto",
      "precioNom",
      "categoriaNom",
      "detallesNom",
      "cantidadNom",
      "totalNom",
      "fecha",
      "aplicar",
      "pasajero",
      "nombre",
      "apellido",
      "email",
      "telefono",
      "dhotel",
      "comentarios",
      "sanitarias",
      "hear",
      "direccion",
      "ciudad",
      "pais",
      "promociones",
      "selecciona",
      "seleccionapais",
      "infopago",
      "cargado",
      "mediospago",
      "infotitular",
      "postal",
      "infocard",
      "creditcard",
      "venc",
      "codecard",
      "terms1",
      "terms2",
      "procesar",
      "volverpago",
      "reservap",
      "recuerda1",
      "recuerda2",
      "tamanogrupo",
      "fechasviaje",
      "crearcuenta",
      "detalleviaje",
      "siguiente",
      "anterior",
      "pareja",
      "individual",
      "familia",
      "grupo",
      "comoviajaras",
      "cuandoviajaras",
      "fechaexacta",
      "fechaaprox",
      "nofecha",
      "describeviaje",
      "especialistalocal",
      "titulo",
      "tupresupuesto",
      "describeloquequieres",
      "Inicio",
      "Viajes",
      "messalida",
      "aproxday"
    ]),
  },
  created() {
    this.resetForm()
  },
  methods: {
      ...mapActions("usuarios",[
        'updateValue',
        'updateFecha',
        'resetForm',
        'switchLanguage',
        'updateUsuario',
      ]),
      ...mapMutations([
        'resetLanguage',
        'switchLoading',
      ]),
      ...mapMutations("usuarios",[
        'setClienteTravel',
        'setWillTravel',
        "setDecrementDuration",
        "setIncrementDuration",
        "setFechasRango"
      ]),
      incrementDuration() {
        this.setIncrementDuration();
      },
      async submitUsuario(value='create') {
        try {
          this.switchLoading(true)
          await this.updateUsuario(value)
          this.switchLoading(false)
        } catch (error) {
          this.switchLoading(false)
          console.log(error)
        }
      },
      decrementDuration() {
        if (this.cliente.duration > 1) { // Establece el límite mínimo
          this.setDecrementDuration();
        }
      },
        changeTab(tabNumber) {
            this.currentTab = tabNumber;
        },
        changeTravel(number) {
            this.setClienteTravel(number);
        },
        changeWillTravel(number) {
            this.setWillTravel(number);
        },
        previousTab() {
            if (this.currentTab > 1) {
                this.currentTab--;
            }
        },
        nextTab() {
          if (this.currentTab === 1) {
            // Validación para el primer tab (Group size)
            if (this.travel === 0) {
              // Verifica si no se ha seleccionado ningún tipo de viaje
              alert('Por favor, selecciona cómo viajarás.');
              return; // No permite avanzar al siguiente tab si la validación falla
            }
          } else if (this.currentTab === 2) {
            // Validación para el segundo tab (Travel dates)
            if (this.willTravel === 0) {
              // Verifica si no se ha seleccionado ninguna opción de fechas de viaje
              alert('Por favor, selecciona cuándo viajarás.');
              return; // No permite avanzar al siguiente tab si la validación falla
            }

            if (this.willTravel === 2 && !this.mesSeleccionada) {
              // Verifica si se ha seleccionado el mes cuando se tienen fechas aproximadas
              alert('Por favor, selecciona el mes de partida.');
              return; // No permite avanzar al siguiente tab si la validación falla
            }
          } else if (this.currentTab === 3) {
            // Validación para el tercer tab (Create account)
            if (!this.cliente.nombre || !this.cliente.apellido || !this.cliente.correo || !this.cliente.telefono || !this.cliente.ciudad) {
              // Verifica si los campos requeridos no están completos
              alert('Por favor, completa todos los campos requeridos.');
              return; // No permite avanzar al siguiente tab si la validación falla
            }
          }

          if (this.currentTab < 4) {
              this.currentTab++;
          }
        },

        handleChange(event, type = 'normal') {
          console.log(event)
          const { name, value } = event.target
          const level = 'usuarios'
          this.updateValue({ name, value, level, type })
        },
        handleChangeFecha(event, type = 'normal') {
          let start = Vue.filter("toReadableDate")(
            moment(event.start).format()
          );
          let end = Vue.filter("toReadableDate")(
            moment(event.end).format()
          );

          let payload = {
            start : start,
            end: end
          }
          this.setFechasRango(payload)
        }
  },
};
</script>

<style lang="scss">
#formulario {
  & > span {
    @media screen and (max-width: 524px) {
      display: block;
      background-color: #ededed;
      color: #4a4a4a !important;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding: 12px 36px 10px;
    }
  }

  & > hr {
    padding: 0.5px;
    background: #3d99ff;

    @media screen and (max-width: 524px) {
      margin-top: 0;
      width: 100%;
      background: #ededed;
      border-top: #ededed 1px solid;
    }
  }

  .formulario {
    @media screen and (max-width: 524px) {
      padding: 0 15px 0 35px;
    }

    span {
      @media screen and (max-width: 524px) {
        font-size: 14px;
      }
    }
  }

  .infoPago {
    & > span {
      @media screen and (max-width: 524px) {
        display: block;
        background-color: #ededed;
        color: #4a4a4a !important;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 12px 36px 10px;
      }
    }

    & > hr {
      padding: 0.5px;
      background: #3d99ff;

      @media screen and (max-width: 524px) {
        margin-top: 0;
        width: 100%;
        background: #ededed;
        border-top: #ededed 1px solid;
      }
    }

    & > p {
      font-size: 16px !important;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 20px;
        padding: 12px 36px;
      }
    }

    .formPago {
      @media screen and (max-width: 524px) {
        width: 100%;
        padding: 0 35px;
      }

      > p {
        @media screen and (max-width: 524px) {
          text-align: center !important;
          font-size: 12px;
          font-weight: 400;
          text-decoration: underline;
        }

        a {
          color: #3d99ff !important;
        }
      }

      > div:nth-child(1) {
        margin-left: -10px;
      }

      .infoTitular {
        @media screen and (max-width: 524px) {
          padding-left: 14px !important;
        }

        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }

      .infoTarjeta {
        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        @media screen and (max-width: 524px) {
          padding-left: 15px !important;
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }
    }

    .terminos {
      span {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          margin-left: 5px;
        }
      }

      .Procesar {
        background: white;
        border: solid 2px #3d99ff;
        color: #3d99ff;

        @media screen and (max-width: 524px) {
          font-size: 18px;
          font-weight: 400;
          background: #3d99ff;
          border: 0;
          border-radius: 11px;
          color: #ffffff;
          display: block;
          margin: 40px auto;
          width: 100%;
        }
      }
    }
  }
}

#viajes {
  margin: 0;
  min-height: 600px;

  // @media screen and (max-width: 524px) {
  //   height: auto; /* Ajustamos la altura en pantallas pequeñas */
  //   background: url("../assets/img/NosotrosMobile.png");
  //   background-position: center;
  //   background-size: cover;
  // }

  .capaOscura {
    height: auto;
    background: rgba(0, 0, 0, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    color: white;
    text-align: center;
    flex-flow: column wrap;

    @media screen and (max-width: 524px) {
      height: 325px;
    }

    h1 {
      font-size: 58px;
      width: 100%;

      @media screen and (max-width: 524px) {
        font-size: 35px;
      }
    }

    p {
      font-size: 22px;
      width: 65%;
      font-weight: normal;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: medium;
      }
    }
  }
}

#QueEs {
  @media screen and (max-width: 524px) {
    background: #f6f6f6 !important;
  }
}

#QueEs,
#QueOfrecemos,
#ComoPorqueLoHacemos,
#QuienesSomos {
  background: white;
  padding: 50px 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  text-align: center;

  @media screen and (max-width: 524px) {
    padding: 40px 40px;
    text-align: left;
  }

  h1 {
    margin-bottom: 30px;
    color: #3d99ff;
    font-size: 40px;

    @media screen and (max-width: 524px) {
      margin-bottom: 15px;

      font-size: 25px;
      text-align: center;
      font-weight: bold;
    }
  }

  p {
    color: #868686;
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: 524px) {
      font-size: 14px;
    }
  }

  img {
    height: 120px;
    margin-bottom: 10px;

    @media screen and (max-width: 524px) {
      height: 70px;
    }
  }
}

#QueOfrecemos {
  background: #3d99ff;
  height: auto;
  padding: 50px;

  @media screen and (max-width: 524px) {
    padding: 50px 40px;
  }

  h1,
  h3,
  p {
    color: white;

    @media screen and (max-width: 524px) {
      width: 100% !important;
    }
  }

  h3 {
    @media screen and (max-width: 524px) {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 9px;
    }
  }

  .row {
    @media screen and (max-width: 524px) {
      text-align: center;
    }

    p {
      @media screen and (max-width: 524px) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }
}

#QuienesSomos {
  background: #f6f6f6;

  h3 {
    @media screen and (max-width: 524px) {
      font-size: 18px;
      font-weight: bold;
      padding: 17px 0;
    }
  }

  @media screen and (max-width: 524px) {
    padding: 50px 80px;
    text-align: center;
  }

  p {
    @media screen and (max-width: 524px) {
      line-height: 22px;
      font-weight: medium;
    }
  }

  .carlos,
  .pablo {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin: auto auto 15px auto;

    @media screen and (max-width: 524px) {
      width: 140px;
      height: 140px;
    }
  }

  .carlos {
    background-image: url("../assets/img/Carlos.png");

    @media screen and (max-width: 524px) {
      margin-top: 29px;
    }
  }

  .pablo {
    background-image: url("../assets/img/Pablo.png");
    background-position: left;
  }
}
</style>

<style lang="scss" scoped>
.breadcrumbs {
  background-color: #353535;
  color: #ffffff;
  height: 36px;
  width: 100%;
  padding: 8px 16px;

  @media (min-width: 524px) {
    font-size: 15px;
  }

  font-size: 9px;
  text-align: left !important;

  a {
    color: #ffffff;
    display: inline-block;
    padding: 0 5px;
    transition: color 300ms linear;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .router-link-active {
    background-color: inherit !important;
    color: white !important;
    font-weight: normal !important;
  }
}
</style>

<style lang="scss" scoped>
.button-container {
    text-align: center;
    padding: 1rem
}

.breadcrumb-number {
    background-color: white;
    color: black;
    border-radius: 50%;
    padding: 5px 10px;
    margin-right: 8px;
    font-weight: bold;
}


.btn-block {
    display: block;
    width: 100%;
    display: inline-block;
    font-weight: 400;
    color: #212529;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.btn {
    border-radius: 5px;
    width: 150px;
    padding: 20px 20px;
    background-color: #fac207;
    color: black;
    border: none;
    cursor: pointer;
    margin: 0 5px;
    font-weight: 900;
}

.btn-middle{
  background-color: #959fa5;
}

.options-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
}

.selected {
    background: #3d99ff;
}
.selected-btn {
    background: #3d99ff;
}

.option {
    flex: 1;
}

.card {
    color: #000;
    text-align: center;
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
}

.card i {
    margin-bottom: 8px;
}
.card:hover{
    opacity: 0.5;
    background-color:#8dbef6;
}

.card span {
    font-size: 16px;
}

div.tab-content {
    border: none;
}

.btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

section {
    border-bottom: 1px solid #e6e6e6;
    padding: 4em 0;
}

section h2 {
    width: 90%;
    margin: 0 auto 2em;
    color: #2c3f4c;
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
}

@media only screen and (min-width: 1170px) {
    section {
        padding: 6em 0;
    }

    section h2 {
        margin: 0 auto 3em;
    }
}

header {
    background: #2c3f4c;
    position: relative;
}

header h1 {
    width: 90%;
    margin: 0 auto;
    text-align: center;
    padding: 4em 0;
    color: #ffffff;
    font-weight: bold;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    font-size: 2rem;
}

@media only screen and (min-width: 768px) {
    header h1 {
        font-size: 3rem;
    }
}
.cd-breadcrumb,
.cd-multi-steps {
    border-radius: .25em;
    font-size: 22px;
}

.cd-breadcrumb::after,
.cd-multi-steps::after {
    clear: both;
    content: "";
    display: table;
}

.cd-breadcrumb li,
.cd-multi-steps li {
    display: inline-block;
    float: left;
    margin: 0.5em 0;
}

.cd-breadcrumb li::after,
.cd-multi-steps li::after {
    display: inline-block;
    content: '\00bb';
    margin: 0 .6em;
    color: #959fa5;
}

.cd-breadcrumb li:last-of-type::after,
.cd-multi-steps li:last-of-type::after {
    display: none;
}

.cd-breadcrumb li>*,
.cd-multi-steps li>* {
    display: inline-block;
    font-size: 1.4rem;
    color: #2c3f4c;
}

.cd-breadcrumb li.current>*,
.cd-multi-steps li.current>* {
    color: #3D99FF;
}

.no-touch .cd-breadcrumb a:hover,
.no-touch .cd-multi-steps a:hover {
    color: #3D99FF;
}

.cd-breadcrumb.custom-separator li::after,
.cd-multi-steps.custom-separator li::after {
    content: '';
    height: 16px;
    width: 16px;
    background: url(https://codyhouse.co/demo/breadcrumbs-multi-steps-indicator/img/cd-custom-separator.svg) no-repeat center center;
    vertical-align: middle;
}
@media only screen and (min-width: 768px) {

    .cd-breadcrumb,
    .cd-multi-steps {
        padding: 0 1.2em;
    }

    .cd-breadcrumb li,
    .cd-multi-steps li {
        margin: 1.2em 0;
    }

    .cd-breadcrumb li::after,
    .cd-multi-steps li::after {
        margin: 0 1em;
    }

    .cd-breadcrumb li>*,
    .cd-multi-steps li>* {
        font-size: 1.6rem;
    }
}

@media only screen and (min-width: 768px) {
    .cd-breadcrumb.triangle {
        background-color: transparent;
        padding: 0;
    }

    .cd-breadcrumb.triangle li {
        position: relative;
        padding: 0;
        margin: 4px 4px 4px 0;
    }

    .cd-breadcrumb.triangle li:last-of-type {
        margin-right: 0;
    }

    .cd-breadcrumb.triangle li>* {
        position: relative;
        padding: 0.2em 0.2em 0.2em 0.8em;
        color: #2c3f4c;
        background-color: #edeff0;
        border-color: #edeff0;
    }

    .cd-breadcrumb.triangle li.current>* {
        color: #ffffff;
        background-color: #3D99FF;
        border-color: #3D99FF;
    }

    .cd-breadcrumb.triangle li:first-of-type>* {
        padding-left: 1.6em;
        border-radius: .25em 0 0 .25em;
    }

    .cd-breadcrumb.triangle li:last-of-type>* {
        padding-right: 1.6em;
        border-radius: 0 .25em .25em 0;
    }

    .no-touch .cd-breadcrumb.triangle a:hover {
        color: #ffffff;
        background-color: #2c3f4c;
        border-color: #2c3f4c;
    }

    .cd-breadcrumb.triangle li::after,
    .cd-breadcrumb.triangle li>*::after {
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        content: '';
        height: 0;
        width: 0;
        border: 24px solid transparent;
        border-right-width: 0;
        border-left-width: 20px;
    }

    .cd-breadcrumb.triangle li::after {
        z-index: 1;
        -webkit-transform: translateX(4px);
        -moz-transform: translateX(4px);
        -ms-transform: translateX(4px);
        -o-transform: translateX(4px);
        transform: translateX(4px);
        border-left-color: #ffffff;
        margin: 0;
    }

    .cd-breadcrumb.triangle li>*::after {
        z-index: 2;
        border-left-color: inherit;
    }

    .cd-breadcrumb.triangle li:last-of-type::after,
    .cd-breadcrumb.triangle li:last-of-type>*::after {
        display: none;
    }

    .cd-breadcrumb.triangle.custom-separator li::after {
        background-image: none;
    }

    .cd-breadcrumb.triangle.custom-icons li::after,
    .cd-breadcrumb.triangle.custom-icons li>*::after {
        border-top-width: 25px;
        border-bottom-width: 25px;
    }

    @-moz-document url-prefix() {

        .cd-breadcrumb.triangle li::after,
        .cd-breadcrumb.triangle li>*::after {
            border-left-style: dashed;
        }
    }
}
@media only screen and (min-width: 768px) {

    .no-touch .cd-breadcrumb.triangle.custom-icons li:first-of-type a:hover::before,
    .cd-breadcrumb.triangle.custom-icons li.current:first-of-type em::before,
    .no-touch .cd-multi-steps.text-center.custom-icons li:first-of-type a:hover::before,
    .cd-multi-steps.text-center.custom-icons li.current:first-of-type em::before {
        /* change custom icon using image sprites - hover effect or current item */
        background-position: 0 -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(2) a:hover::before,
    .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(2) em::before,
    .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(2) a:hover::before,
    .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(2) em::before {
        background-position: -20px -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(3) a:hover::before,
    .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(3) em::before,
    .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(3) a:hover::before,
    .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(3) em::before {
        background-position: -40px -40px;
    }

    .no-touch .cd-breadcrumb.triangle.custom-icons li:nth-of-type(4) a:hover::before,
    .cd-breadcrumb.triangle.custom-icons li.current:nth-of-type(4) em::before,
    .no-touch .cd-multi-steps.text-center.custom-icons li:nth-of-type(4) a:hover::before,
    .cd-multi-steps.text-center.custom-icons li.current:nth-of-type(4) em::before {
        background-position: -60px -40px;
    }
}
</style>
