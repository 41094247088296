<template>
  <div>
    <div id="Checkout">
      <div :class="{ esperarTBK: boolEsperaTBK, 'd-none': !boolEsperaTBK }">
        <img
          src="@/assets/img/logo.png"
          width="250"
          alt="SurRealLogo"
          id="LogoLoadingReserva"
        />
        <p>Espere un momento</p>
      </div>

      <div class="pagoAceptado" v-if="reserva && reserva.correo">
        <div class="contain">
          <img
            src="@/assets/img/iconos/checkGrande.svg"
            class="d-block m-auto pb-sm-4"
          />
          <h1 class="titulo">{{ titulo }}</h1>
          <p class="text-white m-0 text-center">
            Le será enviado un correo electrónico a:
            <strong>{{ reserva.correo }}</strong
            >.
            <span class="d-sm-none d-inline">Le será enviado un correo</span>
            <span class="d-sm-inline d-none">
              <br />
              con la información de su experiencia.
            </span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import Vue from "vue";
import { db, storage } from "../firebase.service";
import moment from "moment";
import emailjs from "emailjs-com";

export default {
  name: "PagoExitoso",
  metaInfo() {
    return {
      title: "Pago exitoso",
    };
  },
  data() {
    return {
      pagoAceptado: false,
      boolEsperaTBK: true,
      reservaInmediata: null,
    };
  },
  created() {
    this.getReservas();
  },
  computed: {
    ...mapState("reservaciones", [
      "reservaciones",
      "reservacionesPorConfirmar",
    ]),
    reserva() {
      const token = this.$route.params.id;
      if (!token) return;
      const reInmediata = this.reservaciones;
      const rePorConfirmar = this.reservacionesPorConfirmar;

      // Evaluar en Reserva Inmediata
      if (!reInmediata) return;
      const finderRI = reInmediata.find((el) => el.token === token);
      if (finderRI) {
        this.reservaInmediata = true;
        return finderRI;
      }
      // Evaluar en Reserva Por Confirmar
      if (!rePorConfirmar) return;
      const finderRPC = rePorConfirmar.find((el) => {
        return (
          el.token === token || (el.tokensPago && el.tokensPago.includes(token))
        );
      });
      if (finderRPC) {
        this.reservaInmediata = false;
        return finderRPC;
      }
    },
    titulo() {
      let titulo = "¡Su reserva ha sido pagada con éxito!";
      this.reserva.estado === "Pendiente de segundo pago"
        ? (titulo = "¡Su abono ha sido pagado con éxito!")
        : false;
      return titulo;
    },
  },
  watch: {
    reservaInmediata(inmediata) {
      inmediata
        ? this.pagoExitosoReInmediata()
        : this.pagoExitosoRePorConfirmar();
    },
  },
  methods: {
    ...mapActions("reservaciones", [
      "getReservas",
      "registrarVisitaEnPagoExitoso",
    ]),
    async pagoExitosoReInmediata() {
      this.pagoAceptado = true;
      this.boolEsperaTBK = false;
      await this.cambioDeEstado("reservas");
      await this.notificacionSurreal();
      await this.reservaInmediataReservaProcesada();
    },
    async pagoExitosoRePorConfirmar() {
      this.pagoAceptado = true;
      this.boolEsperaTBK = false;
      const estadoPrevio = JSON.parse(JSON.stringify(this.reserva.estado));
      const visitasPE = this.reserva.visitasPagoExitoso
        ? this.reserva.visitasPagoExitoso
        : [];
      const ultimaVisita = visitasPE[visitasPE.length - 1];
      const token = this.$route.params.id;

      //Verificacion para que no se repita token ni estado respecto a ultima visita
      if (
        visitasPE.length &&
        (ultimaVisita.estadoInicial === estadoPrevio ||
          ultimaVisita.token === token)
      ) {
        this.$bvToast.toast("Este token de pago ya fue usado", {
          title: `Alerta`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });

         setTimeout(() => {
          this.$router.push("/");
        }, 2000);
        return;
      }

      const { tokensPago } = this.reserva;

      const ultimoToken = tokensPago[tokensPago.length - 1];

      if (ultimoToken != token && tokensPago.includes(token)) {
        this.$bvToast.toast(
          "Este token de pago ya ha sido ingresado anteriormente",
          {
            title: `Alerta`,
            variant: "danger",
            solid: true,
            toaster: "b-toaster-top-center",
          }
        );

        setTimeout(() => {
          this.$router.push("/");
        }, 2000);
        return;
      } 
      // else if (tiempoUltimoToken) {
      //   if (
      //     !moment().isBetween(
      //       moment(tiempoUltimoToken),
      //       moment(tiempoUltimoToken).add(10, "minutes")
      //     )
      //   ) {
      //     this.$bvToast.toast(
      //       "Este token de pago ya ha sido ingresado anteriormente (tiempo excedido)",
      //       {
      //         title: `Alerta`,
      //         variant: "danger",
      //         solid: true,
      //         toaster: "b-toaster-top-center",
      //       }
      //     );
      //     setTimeout(() => {
      //       this.$router.push("/");
      //     }, 2000);
      //     return;
      //   }
      // }

      if (estadoPrevio === "Pagado") {
        this.$bvToast.toast("Esta reserva ya fue pagada", {
          title: `Alerta`,
          variant: "danger",
          solid: true,
          toaster: "b-toaster-top-center",
        });
        setTimeout(() => {
          this.$router.push("/");
        }, 2000);
        return;
      }

      await this.cambioDeEstado("reservasPorConfirmar");
      //Notificaciones
      if (!this.reserva.permiteAbono) {
        await this.notificacionSurreal();
        this.notificacionPagoExitoso();
      } else {
        if (estadoPrevio === "Pendiente de primer pago") {
          await this.notificacionPagoExitosoPrimerAbono();
          await this.notificacionSurrealPagoAbono("temp_abono_pagado");
        } else {
          await this.notificacionSurrealPagoAbono("temp_segundo_pago");
          this.notificacionPagoExitoso();
        }
      }

      await this.registrarVisitaEnPagoExitoso({
        reserva: this.reserva,
        path: this.$route.path,
        reservaInmediata: this.reservaInmediata,
        estado: estadoPrevio,
        token,
      });
    },
    async cambioDeEstado(tipoReserva) {
      const reserva = this.reserva;
      if (!reserva || !reserva.id) return;
      const id = reserva.id;

      try {
        const doc = db.collection(tipoReserva).doc(id);
        if (reserva.estado === "Pendiente de primer pago")
          await doc.update({ estado: "Pendiente de segundo pago" });
        else await doc.update({ estado: "Pagado" });
      } catch (error) {
        console.log(
          "Error en la actualización del estado a pagado en firebase: ",
          error
        );
      }
    },
    async notificacionSurreal() {
      const serviceID = process.env.SERVICE_ID_EJS || "default_service";
      const userID = process.env.USER_ID_EJS || "user_phHSRepn7EuELwmpwDkNi";

      const reserva = this.reserva;
      const reservaInmediata = this.reservaInmediata;

      if (
        !reserva ||
        !reserva.idUnico ||
        !reserva.correo ||
        !reserva.experiencia ||
        !reserva.fecha ||
        !reserva.nombre ||
        !reserva.viajeros ||
        !reserva.fecha ||
        !reserva.fechaActual ||
        !reserva.total ||
        reservaInmediata === undefined
      )
        return;
      let tipo_reserva = "";
      reservaInmediata
        ? (tipo_reserva = "Reserva Inmediata")
        : (tipo_reserva = "Reserva Por Confirmar");

      const id_reserva = reserva.idUnico;
      const email_reserva = reserva.correo;
      const experiencia_reserva = reserva.experiencia;
      const nombre_reserva = reserva.nombre;
      const viajeros_reserva = reserva.viajeros;
      const fecha_reserva = reserva.fecha;
      const fechaActual_reserva = reserva.fechaActual;
      const total_reserva = reserva.total;

      const templateID = "temp_pago_exitoso";

      const templateParams = {
        tipo_reserva,
        id_reserva,
        email_reserva,
        experiencia_reserva,
        nombre_reserva,
        viajeros_reserva,
        fecha_reserva,
        fechaActual_reserva,
        total_reserva,
      };

      try {
        await emailjs.send(serviceID, templateID, templateParams, userID);
      } catch (error) {
        console.log(
          "Error en envío de correo para notificar a Surreal de pago exitoso:",
          error
        );
      }
    },
    async notificacionPagoExitoso() {
      const serviceID = process.env.SERVICE_ID_EJS || "default_service";
      const userID = process.env.USER_ID_EJS || "user_phHSRepn7EuELwmpwDkNi";

      const reserva = this.reserva;
      const reservaInmediata = this.reservaInmediata;

      if (
        !reserva ||
        !reserva.idUnico ||
        !reserva.correo ||
        !reserva.experiencia ||
        !reserva.fecha ||
        !reserva.nombre ||
        !reserva.viajeros ||
        !reserva.fecha ||
        !reserva.fechaActual ||
        !reserva.total ||
        reservaInmediata === undefined
      )
        return;
      const templateID = "pago_exitoso";

      const templateParams = {
        user_name: reserva.nom,
        user_fullname: reserva.nombre,
        user_mail: reserva.correo,
        user_location: reserva.procedencia,
        user_phone: reserva.telefono,
        experience_name: reserva.experiencia,
        experience_date: reserva.fecha,
        experience_startHour: reserva.horaInicio,
        experience_endHour: reserva.horaFin,
        experience_lang: reserva.idioma,
        reservation_group: reserva.viajeros,
        reservation_total: reserva.total,
        reservation_ID: reserva.idUnico,
      };
      try {
        await emailjs.send(serviceID, templateID, templateParams, userID);
      } catch (error) {
        console.log(
          "Error en envío de correo para notificar a cliente de pago exitoso:",
          error
        );
      }
    },
    async notificacionSurrealPagoAbono(templateId) {
      const serviceID = process.env.SERVICE_ID_EJS || "default_service";
      const userID = process.env.USER_ID_EJS || "user_phHSRepn7EuELwmpwDkNi";

      const reserva = this.reserva;
      const reservaInmediata = this.reservaInmediata;

      if (
        !reserva ||
        !reserva.idUnico ||
        !reserva.correo ||
        !reserva.experiencia ||
        !reserva.fecha ||
        !reserva.nombre ||
        !reserva.viajeros ||
        !reserva.fecha ||
        !reserva.fechaActual ||
        !reserva.total ||
        reservaInmediata === undefined
      )
        return;
      const tipo_reserva = "Reserva Por Confirmar";
      const id_reserva = reserva.idUnico;
      const email_reserva = reserva.correo;
      const experiencia_reserva = reserva.experiencia;
      const nombre_reserva = reserva.nombre;
      const viajeros_reserva = reserva.viajeros;
      const fecha_reserva = reserva.fecha;
      const fechaActual_reserva = reserva.fechaActual;
      const total_reserva = reserva.total;
      const nombre_cliente = reserva.nombre;
      const porcentaje_abono = reserva.abono.porcentajeAbono;
      const primera_cuota = reserva.abono.primeraCuota;
      const segunda_cuota = reserva.abono.segundaCuota;

      const templateID = templateId;

      const templateParams = {
        tipo_reserva,
        id_reserva,
        email_reserva,
        experiencia_reserva,
        nombre_reserva,
        viajeros_reserva,
        fecha_reserva,
        fechaActual_reserva,
        total_reserva,
        nombre_cliente,
        porcentaje_abono,
        primera_cuota,
        segunda_cuota,
      };

      try {
        await emailjs.send(serviceID, templateID, templateParams, userID);
      } catch (error) {
        console.log(
          "Error en envío de correo para notificar a Surreal de pago exitoso de abono:",
          error
        );
      }
    },
    async reservaInmediataReservaProcesada() {
      const serviceID = process.env.SERVICE_ID_EJS || "default_service";
      const userID = process.env.USER_ID_EJS || "user_phHSRepn7EuELwmpwDkNi";

      const reserva = this.reserva;
      const reservaInmediata = this.reservaInmediata;

      if (
        !reserva ||
        !reserva.idUnico ||
        !reserva.correo ||
        !reserva.experiencia ||
        !reserva.fecha ||
        !reserva.nombre ||
        !reserva.viajeros ||
        !reserva.fecha ||
        !reserva.fechaActual ||
        !reserva.total ||
        reservaInmediata === undefined
      )
        return;

      const idUnico = reserva.idUnico;
      const nom = reserva.nombre;
      const correo = reserva.correo;
      const procedencia = reserva.ciudad + ", " + reserva.pais;
      const telefono = reserva.telefono;
      const viajeros = reserva.viajeros;
      const total = reserva.total;
      const fecha = reserva.fecha;
      const fechaActual = reserva.fechaActual;
      const idioma = reserva.idioma;
      const horaInicio = reserva.horaInicio;
      const horaFin = reserva.horaFin;
      const experiencia = reserva.experiencia;

      const templateID = "reserva_procesada_inmedi";

      const templateParams = {
        user_name: nom,
        user_fullname: nombre,
        user_mail: correo,
        user_location: procedencia,
        user_phone: telefono,
        experience_name: experiencia,
        experience_date: fecha,
        experience_startHour: horaInicio,
        experience_endHour: horaFin,
        experience_lang: idioma,
        reservation_group: viajeros,
        reservation_total: total,
        reservation_ID: idUnico,
      };

      try {
        await emailjs.send(serviceID, templateID, templateParams, userID);
      } catch (error) {
        console.log(
          "Error en envío de correo para notificar a Surreal de pago exitoso:",
          error
        );
      }
    },
    async notificacionPagoExitosoPrimerAbono() {
      try {
        const { reserva } = this;
        const linkPago = "https://surreal.travel/checkout2/" + reserva.idUnico;
        const fechaExperiencia = moment(
          Vue.filter("fechaToISOString")(reserva.fecha)
        );
        const fechaSegundoPago = fechaExperiencia
          .subtract(3, "d")
          .format("DD/MM/YYYY");

        const serviceID = process.env.SERVICE_ID_EJS || "default_service";
        const userID = process.env.USER_ID_EJS || "user_phHSRepn7EuELwmpwDkNi";
        const templateID = "reserva_segundo_pago";
        const templateParams = {
          user_name: reserva.nom,
          user_fullname: reserva.nombre,
          user_mail: reserva.correo,
          user_location: reserva.procedencia,
          user_phone: reserva.telefono,
          experience_name: reserva.experiencia,
          experience_date: reserva.fecha,
          experience_startHour: reserva.horaInicio,
          experience_endHour: reserva.horaFin,
          experience_lang: reserva.idioma,
          reservation_group: reserva.viajeros,
          reservation_percentage: reserva.abono.porcentajeAbono,
          reservation_first_payment: reserva.abono.primeraCuota,
          reservation_second_payment: reserva.abono.segundaCuota,
          reservation_total: reserva.total,
          reservation_total: reserva.total,
          reservation_ID: reserva.idUnico,
          reservation_comments: reserva.comentarios,
          reservation_vegan: reserva.vegano,
          reservation_link: linkPago,
          second_payment_date: fechaSegundoPago,
        };
        await emailjs.send(serviceID, templateID, templateParams, userID);
      } catch (error) {
        console.log("Error al enviar notificación: ", error);
      }
    },
  },
};
</script>

<style lang="scss">
#Checkout {
  .hidden {
    opacity: 0;
  }

  .titulos {
    font-weight: bold;
    color: #343434;
  }

  .lista-experiencias {
    @media (max-width: 524px) {
      padding: 0px 32px;
    }
  }

  color: #868686;
  position: relative;

  .font-weight-bold {
    color: #343434;
  }

  .checkout {
    background: #3d99ff;
    padding: 30px;

    @media screen and (max-width: 524px) {
      padding: 35px 0;
      height: 100px;
    }

    .contain {
      h1 {
        @media screen and (max-width: 524px) {
          font-size: 25px;
          font-weight: bold;
          line-height: 28px;
          margin: 0 auto;
        }
      }
    }
  }

  .tuOrden {
    @media screen and (max-width: 524px) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .row {
      @media screen and (max-width: 524px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    & > span {
      @media screen and (max-width: 524px) {
        display: block;
        background-color: #ededed;
        color: #4a4a4a;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 12px 36px 10px;
      }
    }

    & > hr {
      padding: 0.5px;
      background: #3d99ff;

      @media screen and (max-width: 524px) {
        margin-top: 0;
        width: 100%;
        background: #ededed;
        border-top: #ededed 1px solid;
      }
    }

    & table {
      tr:nth-child(1) {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      img {
        @media screen and (max-width: 524px) {
          width: 69px;
          height: 76px;
        }
      }

      h2 {
        font-size: 25px;

        @media screen and (max-width: 524px) {
          font-size: 14px;
          line-height: 18px;
          color: #4a4a4a;
          text-align: left;
        }
      }

      .X {
        color: #ff0031;
        text-transform: uppercase;

        @media screen and (max-width: 524px) {
          color: #353535;
          font-size: 14px;
        }
      }

      tr:nth-child(2) td:nth-child(1) p {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      tr:nth-child(2) td:nth-child(n + 2) {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      .row-titulo {
        @media screen and (max-width: 524px) {
          justify-content: space-evenly;
        }
      }

      .col-titulo {
        @media screen and (max-width: 524px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    button.ir-pago {
      border-radius: 11px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      padding: 13px 0;
      margin-bottom: 20px;
    }
  }

  .cupon-mobile {
    position: relative;

    &-btn {
      position: absolute;
      right: 35px;
      top: 10px;
      font-size: 34px;
    }
  }

  .checkout-info {
    padding: 5px 20px;

    > div {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #4a4a4a;
        opacity: 0.9;
        line-height: 25px;
        text-align: left;

        & + span {
          font-weight: 500;
        }
      }
    }
  }

  .checkout-total {
    @media screen and (max-width: 524px) {
      padding-right: 20px !important;
    }

    &__subtotal {
      span:nth-child(1) {
        @media screen and (max-width: 524px) {
          font-size: 12px;
          font-weight: 400;
          line-height: 28px;
          color: #4a4a4a;
        }
      }

      span:nth-child(2) {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          font-weight: 400 !important;
          line-height: 28px;
          color: #4a4a4a;
        }
      }
    }

    &__total {
      span:nth-child(1) {
        @media screen and (max-width: 524px) {
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: #4a4a4a;
        }
      }

      span:nth-child(2) {
        @media screen and (max-width: 524px) {
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          color: #4a4a4a;
        }
      }
    }
  }

  .w250 {
    width: 250px;
  }

  .formPago {
    img {
      @media screen and (max-width: 524px) {
        width: 146px;
      }

      // cursor: pointer;
    }
  }

  .algoPaso {
    background: #ff4141;
    padding: 30px;

    @media screen and (max-width: 524px) {
      height: 290px;
    }

    img {
      @media screen and (max-width: 524px) {
        height: 100px;
        width: 100px;
      }
    }

    h1.titulo {
      color: white;
      font-size: 40px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 40px 24px;
      }
    }
  }

  .pagoAceptado {
    background: #3d99ff;
    padding: 30px;

    @media screen and (max-width: 524px) {
      height: 290px;
    }

    img {
      @media screen and (max-width: 524px) {
        height: 100px;
        width: 100px;
      }
    }

    h1.titulo {
      color: white;
      font-size: 40px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 60px 12px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      padding: 0 7px;
    }
  }

  .titulo {
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
  }

  .azul {
    color: #3d99ff;
  }

  input,
  select {
    border-radius: 6px;
    height: 50px;

    @media screen and (max-width: 524px) {
      height: 40px;
    }
  }

  .cupon,
  .Procesar {
    background: #3d99ff;
    border-radius: 6px;
    font-size: 14px;
    height: 50px;
    width: 170px;
    color: white;
  }

  .volverCheckout {
    background: transparent;
    border: groove white;
    border-radius: 6px !important;
    color: white;

    @media screen and (max-width: 524px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      border: solid 2px #ffffff;
      padding: 15px 36px;
    }
  }

  .left,
  .right {
    div {
      @media screen and (max-width: 524px) {
        height: auto;
      }
    }
  }

  .left {
    @media screen and (max-width: 524px) {
      padding-right: 14px !important;
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    div {
      @media screen and (max-width: 524px) {
        margin-left: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .right {
    @media screen and (max-width: 524px) {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    div {
      @media screen and (max-width: 524px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .desaparecer {
    opacity: 0;

    @media screen and (max-width: 524px) {
      display: none;
    }
  }
}
#reservaPorConfirmar {
  border: #3d99ff solid 1px;
  border-radius: 15px;
  color: #ffffff;
  background-color: #3d99ff;
  display: flex;
  flex-flow: wrap column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
#formulario {
  & > span {
    @media screen and (max-width: 524px) {
      display: block;
      background-color: #ededed;
      color: #4a4a4a !important;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding: 12px 36px 10px;
    }
  }

  & > hr {
    padding: 0.5px;
    background: #3d99ff;

    @media screen and (max-width: 524px) {
      margin-top: 0;
      width: 100%;
      background: #ededed;
      border-top: #ededed 1px solid;
    }
  }

  .formulario {
    @media screen and (max-width: 524px) {
      padding: 0 15px 0 35px;
    }

    span {
      @media screen and (max-width: 524px) {
        font-size: 14px;
      }
    }
  }

  .infoPago {
    & > span {
      @media screen and (max-width: 524px) {
        display: block;
        background-color: #ededed;
        color: #4a4a4a !important;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 12px 36px 10px;
      }
    }

    & > hr {
      padding: 0.5px;
      background: #3d99ff;

      @media screen and (max-width: 524px) {
        margin-top: 0;
        width: 100%;
        background: #ededed;
        border-top: #ededed 1px solid;
      }
    }

    & > p {
      font-size: 16px !important;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 20px;
        padding: 12px 36px;
      }
    }

    .formPago {
      @media screen and (max-width: 524px) {
        width: 100%;
        padding: 0 35px;
      }

      > p {
        @media screen and (max-width: 524px) {
          text-align: center !important;
          font-size: 12px;
          font-weight: 400;
          text-decoration: underline;
        }

        a {
          color: #3d99ff !important;
        }
      }

      > div:nth-child(1) {
        margin-left: -10px;
      }

      .infoTitular {
        @media screen and (max-width: 524px) {
          padding-left: 14px !important;
        }

        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }

      .infoTarjeta {
        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        @media screen and (max-width: 524px) {
          padding-left: 15px !important;
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }
    }

    .terminos {
      span {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          margin-left: 5px;
        }
      }

      .Procesar {
        background: white;
        border: solid 2px #3d99ff;
        color: #3d99ff;

        @media screen and (max-width: 524px) {
          font-size: 18px;
          font-weight: 400;
          background: #3d99ff;
          border: 0;
          border-radius: 11px;
          color: #ffffff;
          display: block;
          margin: 40px auto;
          width: 100%;
        }
      }
    }
  }
}
#LogoLoadingReserva {
  animation: titilar 1s infinite;
  animation-timing-function: linear;
}
@keyframes titilar {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.esperarTBK {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
  z-index: 99999999;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  p {
    color: #212529;
    margin-top: 2.5%;
    font-size: 1.35rem;
    font-weight: 500;
    font-style: italic;
  }
}
</style>
