import firebase from 'firebase'
import 'firebase/firestore'

export default async function storageURLs (storageUpdateConfig, contenidoCulturaBorrador = null) {
  // Una variable booleana para chequear si fue activada alguna
  // de las actualizaciones en storage

  // Y un objeto inicial para guardar todos los updates que se van a aplicar
  const storageUpdates = {}
  let storageUpdateAvailable = false

  if (storageUpdateConfig.carouselImageUpdate) {
    const storageResult = await storageUpdateConfig.carouselImageUpdate
    const downloadURL = await storageResult.ref.getDownloadURL()
    storageUpdates.carouselImgUrl = downloadURL
    storageUpdateAvailable = true
  }

  if (storageUpdateConfig.localidadImageUpdate) {
    const storageResult = await storageUpdateConfig.localidadImageUpdate
    const downloadURL = await storageResult.ref.getDownloadURL()
    storageUpdates.localidadImgUrl = downloadURL
    storageUpdateAvailable = true
  }

  if (storageUpdateConfig.heroImgsUpdate && storageUpdateConfig.heroImgsUpdate.length > 0) {
    const storageResult = await Promise.all(storageUpdateConfig.heroImgsUpdate)
    const heroImgsMapping = storageResult.map((result) => result.ref.getDownloadURL())
    const downloadURLs = await Promise.all(heroImgsMapping)
    storageUpdates.heroImgUrls = firebase.firestore.FieldValue.arrayUnion(...downloadURLs)
    storageUpdateAvailable = true
  }

  if (storageUpdateConfig.contenidoCulturaUpdates && storageUpdateConfig.contenidoCulturaUpdates.length > 0) {
    const storageResult = await Promise.all(storageUpdateConfig.contenidoCulturaUpdates)
    const contenidoImgsMapping = storageResult.map((result) => result.ref.getDownloadURL())
    const downloadURLs = await Promise.all(contenidoImgsMapping)
    const newContenidoCultura = contenidoCulturaBorrador.map((contenido, index) => {
      const newContenido = {
        titulo: contenido.titulo,
        tituloIngles: contenido.tituloIngles,
        imgUrl: downloadURLs[index]
      }
      return newContenido
    })
    storageUpdates.contenidoCultura = firebase.firestore.FieldValue.arrayUnion(...newContenidoCultura)
    storageUpdateAvailable = true
  }

  return {
    storageUpdateAvailable,
    storageUpdates
  }
}
