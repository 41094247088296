<template>
  <div class="breadcrumbs">
    <!-- <b-container> -->
      <div class="d-flex align-items-center pl-sm-5" v-if="razon !== 'zonas'">
        <router-link :to="{ name: 'Home' }" class="pr-0 d-inline">{{
          home
        }}</router-link>
        <router-link
          v-if="zona"
          :to="zonaURL"
          class="pr-0 d-none d-sm-inline-block"
          >> {{ zona }}</router-link
        >
        <router-link v-if="destino" :to="destinoURL" class="destinoFinal"
          >> {{ destino }}</router-link
        >
        <a v-if="destino" href="#" class="pr-0 tituloFinal">{{
          titulo ? "> " + titulo : titulo
        }}</a>
      </div>
      <div class="d-flex align-items-center pl-sm-5" v-if="razon == 'zonas'">
        <router-link :to="{ name: 'Home' }" class="pr-0 d-inline">{{
          home
        }}</router-link>
        <router-link :to="zonaURL" class="pr-0 d-none d-sm-inline-block"
          >> {{ titulo }}</router-link
        >
        <!-- <router-link :to="'/' + zonaID" class="pr-0 d-none d-sm-inline-block"
          >> {{ titulo }}</router-link
        > -->
      </div>
    <!-- </b-container> -->
  </div>
</template>

<script>
/* eslint-disable */
import { mapState } from "vuex";

export default {
  name: "Breadcrumbs",
  props: ["titulo", "destino", "destinoID", "zona", "zonaID", "razon"],
  computed: {
    ...mapState(["language"]),
    home() {
      let lang = "";
      if (this.language === "es") {
        lang = "Inicio";
      } else {
        lang = "Home";
      }
      return lang;
    },
    destinoURL() {
      return `/destinos/${this.destinoID}/experiencias`;
    },
    zonaURL() {
      if (this.zona.toLowerCase().includes("norte"))
        return `/zonas/norte/overview`;
      if (this.zona.toLowerCase().includes("centro"))
        return `/zonas/centro/overview`;
      if (this.zona.toLowerCase().includes("sur")) return `/zonas/sur/overview`;
      if (this.zona.toLowerCase().includes("patagonia"))
        return `/zonas/patagonia/overview`;
    }
  }
};
</script>

<style lang="scss">
.breadcrumbs {
  background-color: #353535;
  color: #ffffff;
  height: 36px;
  width: 100%;
  padding: 8px 16px;

  @media (max-width: 524px) {
    padding: 11px 16px;

    .tituloFinal,
    .destinoFinal {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  @media (min-width: 524px) {
    font-size: 15px;
  }

  font-size: 10px;
  text-align: left !important;

  a {
    color: #ffffff;
    display: inline-block;
    padding: 0 5px;
    transition: color 300ms linear;

    &:hover {
      color: rgba(255, 255, 255, 0.5);
    }
  }

  .router-link-active {
    background-color: inherit !important;
    color: white !important;
    font-weight: normal !important;
  }
}
</style>
