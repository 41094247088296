<template>
	<div>
    <Hero-Section />
    <HowItWorks />
    <section class="section section-articles">
      <div class="container">
        <header class="header text-center" style="background-color: white;">
          <h2 class="header__title">Costa Rica Tours &amp; Itineraries</h2>
          <div class="header__subtitle">Get inspired by Costa Rica trips other travelers loved</div>
        </header>
        <!-- SET HERE PAGINATE ARTICLES CARDS -->
        <div class="row row-equal-height">
          <!-- FOR EACH LOOP SET 3 CONTENT IN ARTICLE CARD-->
          <div class="article-cards" v-for="(paginateTrip, index) in chunkTrips" :key="index">
            <article class="card card--article grid-item" v-for="(trip, key) in paginateTrip" :key="key">
              <a title="Best of Costa Rica in 7 Days: Explore Jungles, Volcanoes and Beaches" href="/c/best-of-costa-rica-tour-7-day-itinerary">
                <div style="position: relative; height: 200px">
                  <figure class="figure card__figure" style="height: 100%; width: 100%">
                    <img class="img-fluid card-img-top lazyloaded" data-src="https://cdn.kimkim.com/files/a/content_articles/featured_photos/2beb558e9245d762f6d5fd06e8bd55983f06b79a/medium-2edb4cb57e8c4bd9a93d19d2dd31a49a.jpg" alt="Storm clouds over Arenal volcano" src="https://cdn.kimkim.com/files/a/content_articles/featured_photos/2beb558e9245d762f6d5fd06e8bd55983f06b79a/medium-2edb4cb57e8c4bd9a93d19d2dd31a49a.jpg">
                  </figure>

                  <!--<div class="hero__bottom-right" style="right: 1.25rem; bottom: -11px;">
                    <div class="map-preview map-preview-xs" style="border-color: #fff">
                      <img data-src="https://cdn.kimkim.com/files/a/maps/6b51a2a0837ecabe3e13265d984f4a29c10d06e0/small-9d5019f7f2e6ffe10175f3b7adb83568.jpg" class=" lazyloaded" title="Map of Best of Costa Rica in 7 Days: Explore Jungles, Volcanoes and Beaches" alt="Map of Best of Costa Rica in 7 Days: Explore Jungles, Volcanoes and Beaches" src="https://cdn.kimkim.com/files/a/maps/6b51a2a0837ecabe3e13265d984f4a29c10d06e0/small-9d5019f7f2e6ffe10175f3b7adb83568.jpg">

                    </div>
                  </div>-->
                </div>
              </a>
              <div class="card-body" style="margin: 15px 15px;">
                <h5 class="card-title">
                  <a :title="trip.title" href="/c/best-of-costa-rica-tour-7-day-itinerary">{{trip.title}}</a>
                </h5>
                <p class="card-text card-text-sm text-muted">
                  {{trip.description_general}}
                </p>
              </div>
              <div style="margin: 15px; display: flex; align-items: center; justify-content: center;">
                <a
                  class="btn btn-secondary"
                  style="background-color: #FFAA01; border-color: #FFAA01;"
                >{{ language === "en" ? "See details" : "Ver detalles" }}</a>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
  import { mapState, mapActions, mapGetters } from "vuex";
	import HeroSection from "@/components/Hero-Section";
	import HowItWorks from "@/components/HowItWorks";

	export default {
		name: "ViajesPersonalizados",
		components: {
			HeroSection,
      HowItWorks
		},
    computed: {
      ...mapState("ForeignTrip", [
        "trips"
      ]),
      ...mapGetters([
        "language"
      ]),
      chunkTrips() {
        var trips = [];
        if (this.data.length > 0) {
          trips = this.chunk(this.data, 3);
        } else {
          trips.push({});
        }
        return trips;
      }
    },
		data() {
			return {
        data: [],
        lang: 'es'
			}
		},
    methods: {
      ...mapActions("ForeignTrip", [
        "getTrips",
      ]),
      loadTrips () {
        this.data = [];
        const trips = this.trips;
        const lang = this.$store.state.language;

        trips.forEach(elem => {
          const trip = elem[lang];
          this.data.push({
            id: elem.id,
            title: trip.title,
            description_general: trip.description_general,
            image: '',
            slug: trip.slug
          });
        });
      },
      chunk(arr, len) {
        let chunks = [],
          i = 0,
          n = arr.length;

        while (i < n) {
          chunks.push(arr.slice(i, (i += len)));
        }
        return chunks;
      }
    },
    watch: {
      '$store.state.language': function() {
        this.loadTrips();
      }
    },
    created() {
      this.getTrips();
    },
    mounted() {
      this.loadTrips();
    }

  }
</script>

<style>
  @import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,500i,700&display=swap");

  * {
    font-family: "Montserrat", sans-serif;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
  }
  .row-equal-height > div {
    display: flex;
  }

  article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block;
  }

  .article-cards {
    position: relative;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    height: auto !important;
  }

  .grid-item {
    grid-row: 1 / 3;
  }

  .card {
    margin-bottom: 1rem;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,0.125);
    border-radius: 0.25rem;
  }

  .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 1.25rem;
  }

  .card--article .card-title, .card--trip-plan-recommendation .card-title, .card--article_horizontal .card-title {
    font-size: 1.2rem;
  }

  .card-title {
    margin-bottom: 0.75rem;
  }

  .card .card-text-sm, .select-room-module .card-text-sm, .card .loc-cards .card-description, .select-room-module .loc-cards .card-description, .loc-cards .card .card-description, .loc-cards .select-room-module .card-description {
    font-size: 0.8rem;
    line-height: 1.4;
  }

  .card-text:last-child {
    margin-bottom: 0;
  }

  .text-muted, .help-text, .ai-chat-message .footer, .ai-chat-message .footer-links, .help, .controller-onboarding.action-next_steps .step-description, .section--about p, .section--testimonials blockquote cite, .section--pricing p, .trip-review-container .trip-review-subtitle, .responsive-text-field .feedback-hints, .table--facts tr>td:first-child, .reviewable-container .location, .media__subtitle, .key-locations .key-location:not(.selected)>div, form .help, .loc-cards .card-description, .loc-cards .card-duration div, .loc-cards .card-rating .rating-avg, .loc-cards .card-rating .rating-count, .loc-cards .card-body .location-info, .card--predefined-trip .media__meta, .article--content figure.image figcaption, .header__subtitle {
    color: #4F4F4F !important;
  }

  p {
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .card--article a,.card--trip-plan-recommendation a,.card--article_horizontal a{text-decoration:none}.card--article .card-img-overlay,.card--trip-plan-recommendation .card-img-overlay,.card--article_horizontal .card-img-overlay{left:auto;padding:1rem}.card--article .card-title,.card--trip-plan-recommendation .card-title,.card--article_horizontal .card-title{font-size:1.2rem}.card--article .card-title a,.card--trip-plan-recommendation .card-title a,.card--article_horizontal .card-title a{font-family:'Lato';font-weight:bold;color:#212529}.card--article .card-title a:hover,.card--trip-plan-recommendation .card-title a:hover,.card--article_horizontal .card-title a:hover{text-decoration:underline}.card--article .card-img-top,.card--trip-plan-recommendation .card-img-top,.card--article_horizontal .card-img-top{height:100%;width:100%;-o-object-fit:cover;object-fit:cover}@media (max-width: 767.98px){.card--article_horizontal .card-img,.card--trip-plan-recommendation_horizontal .card-img,.card--article_horizontal_horizontal .card-img{border-bottom-left-radius:0px;border-bottom-right-radius:0px}.card--article_horizontal .card-photo-header,.card--trip-plan-recommendation_horizontal .card-photo-header,.card--article_horizontal_horizontal .card-photo-header{height:200px}}
  .grid-item{width:32%}
  .row-equal-height>div{display:flex}
  .header{margin-bottom:1.5rem}@media (max-width: 991.98px){.header__title,.header__title--page,.header__title--sm{font-size:2rem}}.header__title--sm{font-size:2rem}.header__title--page{font-size:2.2rem}.header__title--aside{font-size:1rem}.header__subtitle{margin-top:0.25rem}
  @media (max-width: 991.98px){.grid .grid-sizer,.grid .grid-item{width:48%}}@media (max-width: 575.98px){.grid .grid-sizer,.grid .grid-item{width:99%}}
  .lazyloaded{opacity:1;transition:opacity 200ms}

</style>
