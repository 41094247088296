import router from '../../router/index'
import { db, storage } from '../../firebase.service'
import storageHandler from '../utilities/storageHandler'
import storageURLs from '../utilities/downloadURLs'

const zonas = db.collection('zonas')
const experiencias = db.collection('experiencias')
const destinosCollection = db.collection('destinos')

const state = () => ({
  id: null,
  titulo: null,
  subtitulo: null,
  carouselImgUrl: null,
  heroImgUrls: [],
  youtubeMedia: [],
  localidadImgUrl: null,
  resumen: null,
  destinos: [],
  contenidoCultura: [],
  info: {
    culturaGastronomia: null,
    verHacer: null,
    cuandoVisitar: null,
    dondeQuedarse: null,
    comoLlegar: null,
    consejos: []
  },
  ingles: {
    titulo: null,
    subtitulo: null,
    resumen: null,
    info: {
      culturaGastronomia: null,
      verHacer: null,
      cuandoVisitar: null,
      dondeQuedarse: null,
      comoLlegar: null,
      consejos: [],
      consejosToSave: []
    }
  },
  borrador: {
    youtubeMedia: []
  }
})

const getters = {
  zona (state) {
    return state
  }
}

const mutations = {
  setZonaById (state, payload) {
    const { id, zonas } = payload
    state.id = id
    // Actualizar si hay una id de zona en la ruta
    if (id && zonas.length > 0 && !payload.delete) {
      const zona = zonas.find((zona) => zona.id === id)
      if (zonas.length > 0 && !zona) {
        alert('La zona no existe dentro de la coleccion de zonas')
        router.push({ path: '/admin' })
      }
      if (zona) {
        const {
          titulo,
          subtitulo,
          carouselImgUrl,
          localidadImgUrl,
          heroImgUrls,
          contenidoCultura,
          resumen,
          destinos
        } = zona

        const {
          culturaGastronomia,
          verHacer,
          cuandoVisitar,
          dondeQuedarse,
          comoLlegar,
          consejos
        } = zona.info

        state.titulo = titulo
        state.subtitulo = subtitulo
        state.carouselImgUrl = carouselImgUrl
        state.localidadImgUrl = localidadImgUrl
        state.heroImgUrls = heroImgUrls
        state.contenidoCultura = contenidoCultura
        state.resumen = resumen
        state.destinos = destinos
        state.info.culturaGastronomia = culturaGastronomia
        state.info.verHacer = verHacer
        state.info.cuandoVisitar = cuandoVisitar
        state.info.dondeQuedarse = dondeQuedarse
        state.info.comoLlegar = comoLlegar
        state.info.consejos = consejos

        // Reset de contenido en ingles

        state.ingles.titulo = zona.ingles.titulo
        state.ingles.subtitulo = zona.ingles.subtitulo
        state.ingles.resumen = zona.ingles.resumen
        state.ingles.info.culturaGastronomia = zona.ingles.info.culturaGastronomia
        state.ingles.info.verHacer = zona.ingles.info.verHacer
        state.ingles.info.cuandoVisitar = zona.ingles.info.cuandoVisitar
        state.ingles.info.dondeQuedarse = zona.ingles.info.dondeQuedarse
        state.ingles.info.comoLlegar = zona.ingles.info.comoLlegar
        state.ingles.info.consejos = zona.ingles.info.consejos

        // Condicional temporal para propiedad de youtube media
        state.youtubeMedia = zona.youtubeMedia || []
      }
    }
  }
}

const actions = {
  setZonaId ({ commit, rootState }, payload) {
    const id = payload
    commit('setZonaById', { id, zonas: rootState.zonas, delete: rootState.delete })
  },
  async deleteZona ({ state, rootState, commit }, payload) {
    const confirmar = confirm('Esta seguro de eliminar esta zona y todos sus destinos ?')

    if (!confirmar) {
      return
    }

    const zonaElement = rootState.zonas.find((zona) => zona.id === payload.id)
    if (!zonaElement) {
      alert('La zona que desea eliminar no existe')
      return
    }
    const images = []

    if (zonaElement.carouselImgUrl) {
      images.push(zonaElement.carouselImgUrl)
    }

    if (zonaElement.localidadImgUrl) {
      images.push(zonaElement.localidadImgUrl)
    }

    if (zonaElement.heroImgUrls && zonaElement.heroImgUrls.length > 0) {
      images.push(...zonaElement.heroImgUrls)
    }

    if (zonaElement.contenidoCultura && zonaElement.contenidoCultura.length > 0) {
      const culturaImages = zonaElement.contenidoCultura.map((contenido) => contenido.imgUrl)
      images.push(...culturaImages)
    }

    const experienciasIds = []
    const destinosArray = rootState.destinos.filter((destino) => zonaElement.destinos.includes(destino.id))
    destinosArray.forEach((destino) => {
      experienciasIds.push(...destino.experiencias)

      if (destino.carouselImgUrl) {
        images.push(destino.carouselImgUrl)
      }
      if (destino.heroImgUrls && destino.heroImgUrls.length > 0) {
        images.push(...destino.heroImgUrls)
      }
      if (destino.localidadImgUrl) {
        images.push(destino.localidadImgUrl)
      }

      if (destino.contenidoCultura && destino.contenidoCultura.length > 0) {
        const culturaImages = destino.contenidoCultura.map((contenido) => contenido.imgUrl)
        images.push(...culturaImages)
      }
    })

    let experienciasArray = []
    if (experienciasIds.length > 0) {
      experienciasArray = rootState.experiencias.filter((experiencia) => experienciasIds.includes(experiencia.id))
      experienciasArray.forEach((experiencia) => {
        if (experiencia.carouselImgUrl) {
          images.push(experiencia.carouselImgUrl)
        }
        if (experiencia.heroImgUrls && experiencia.heroImgUrls.length > 0) {
          images.push(...experiencia.heroImgUrls)
        }
      })
    }

    try {
      if (images && images.length > 0) {
        const imagesToDelete = images.map((imgUrl) => storage.refFromURL(imgUrl))
        await Promise.all(imagesToDelete.map((imgRef) => imgRef.delete()))
      }
      if (experienciasIds.length > 0) {
        await Promise.all(experienciasIds.map((experienciaId) => experiencias.doc(experienciaId).delete()))
        await Promise.all(zonaElement.destinos.map((destinoId) => destinosCollection.doc(destinoId).delete()))
      }
      await zonas.doc(zonaElement.id).delete()
      router.push({ path: '/admin' })
      commit('resetBorrador', null, { root: true })
      alert('Zona eliminada exitosamente')
    } catch (error) {
      commit('resetBorrador', null, { root: true })
      console.log('Error al borrar zona: ', error)
    }
  },
  async updateZona ({ state, commit, rootState }, payload) {
    // Funcion que sirve tanto para crear como para actualizar zonas
    const zona = rootState.zonas.find((zona) => zona.id === state.id)
    if (payload === 'update' && !zona) {
      alert('La zona que intenta actualizar no esta registrada')
      return
    }
    // Variable inicial para almacenar el id del documento
    let key

    // Deconstructing del estado para preparar el update/create
    const {
      titulo,
      subtitulo,
      resumen,
      destinos,
      carouselImgUrl,
      localidadImgUrl,
      heroImgUrls,
      contenidoCultura,
      youtubeMedia
    } = state

    const {
      culturaGastronomia,
      verHacer,
      cuandoVisitar,
      dondeQuedarse,
      comoLlegar
    } = state.info

    // Preparacion de create
    const updates = {
      titulo,
      subtitulo,
      resumen,
      destinos,
      carouselImgUrl,
      localidadImgUrl,
      heroImgUrls,
      youtubeMedia: [...youtubeMedia, ...state.borrador.youtubeMedia],
      contenidoCultura,
      info: {
        culturaGastronomia,
        verHacer,
        cuandoVisitar,
        dondeQuedarse,
        comoLlegar,
        consejos: [...state.info.consejos, ...rootState.consejosToSave]
      },
      ingles: {
        titulo: state.ingles.titulo,
        subtitulo: state.ingles.subtitulo,
        resumen: state.ingles.resumen,
        info: {
          culturaGastronomia: state.ingles.info.culturaGastronomia,
          verHacer: state.ingles.info.verHacer,
          cuandoVisitar: state.ingles.info.cuandoVisitar,
          dondeQuedarse: state.ingles.info.dondeQuedarse,
          comoLlegar: state.ingles.info.comoLlegar,
          consejos: [...state.ingles.info.consejos, ...state.ingles.info.consejosToSave]
        }
      }
    }

    // En caso de crear una nueva zona primero se crea para tener su id
    // y asociar las imagenes con su respectivo id de zona en storage
    if (payload === 'create') {
      const createTask = await zonas.add(updates)
      key = createTask.id
    } else if (payload === 'update') {
      key = state.id
    }

    const storageUpdateConfig = storageHandler(storage, rootState, key, 'zona')
    const storageDownloadURLs = await storageURLs(storageUpdateConfig, rootState.contenidoCulturaBorrador)
    const { storageUpdateAvailable, storageUpdates } = storageDownloadURLs

    if (storageUpdateAvailable) {
      if (payload === 'update') {
        await zonas.doc(key).update(updates)
      }
      await zonas.doc(key).update(storageUpdates)
      alert(`Operacion en zona ${state.titulo} realizada exitosamente`)
      commit('resetBorrador')
      router.push({ path: '/admin' })
    } else {
      if (payload === 'update') {
        await zonas.doc(key).update(updates)
      }
      alert(`Operacion en zona ${state.titulo} realizada exitosamente`)
      commit('resetBorrador')
      router.push({ path: '/admin' })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
