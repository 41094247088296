/* eslint-disable */
export default {
  namespaced: true,

  state: {
    FooterESP: {
      zonas: "Zonas",
      empresa: "Empresa",
      nosotros: "Nosotros",
      contacto: "Contacto",
      info: "Información Útil",
      terminos: "Términos y condiciones",
      faq: "Preguntas frecuentes",
      privacidad: "Privacidad",
      contactoTouch: "Estemos en contacto",
      placeholder: "Ingresa tu Email",
      suscribete: "Suscríbete"
    },
    FooterENG: {
      zonas: "Zones",
      empresa: "Company",
      nosotros: "About us",
      contacto: "Contact us",
      info: "Useful Information",
      terminos: "Terms and conditions",
      faq: "FAQ",
      privacidad: "Privacy",
      contactoTouch: "Let’s keep in touch",
      placeholder: "Enter your Email",
      suscribete: "Subscribe"
    }
  },
  getters: {
    Zonas(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.zonas;
      } else if (rootState.language == "en") {
        return state.FooterENG.zonas;
      }
    },
    Empresa(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.empresa;
      } else if (rootState.language == "en") {
        return state.FooterENG.empresa;
      }
    },
    Nosotros(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.nosotros;
      } else if (rootState.language == "en") {
        return state.FooterENG.nosotros;
      }
    },
    Contacto(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.contacto;
      } else if (rootState.language == "en") {
        return state.FooterENG.contacto;
      }
    },
    Info(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.info;
      } else if (rootState.language == "en") {
        return state.FooterENG.info;
      }
    },
    terminos(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.terminos;
      } else if (rootState.language == "en") {
        return state.FooterENG.terminos;
      }
    },
    faq(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.faq;
      } else if (rootState.language == "en") {
        return state.FooterENG.faq;
      }
    },
    privacidad(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.privacidad;
      } else if (rootState.language == "en") {
        return state.FooterENG.privacidad;
      }
    },
    contactoTouch(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.contactoTouch;
      } else if (rootState.language == "en") {
        return state.FooterENG.contactoTouch;
      }
    },
    placeholder(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.placeholder;
      } else if (rootState.language == "en") {
        return state.FooterENG.placeholder;
      }
    },
    suscribete(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.FooterESP.suscribete;
      } else if (rootState.language == "en") {
        return state.FooterENG.suscribete;
      }
    }
  },
  mutations: {},
  actions: {}
};
