<template>
  <div>
    <b-carousel
      v-if="data && data.length > 0"
      indicators
      id="carousel"
      :interval="interval"
      :controls="data.length > 1"
      class="carousel"
      v-model="slideVisible"
    >
      <b-carousel-slide
        v-for="(contenidoArray, index) in data"
        :key="index"
        class="carousel__item"
      >
        <template v-slot:img>
          <div v-for="(contenido, index) in contenidoArray" :key="index">
            <template
              v-if="contenido.type !== 'youtube' && contenido.type !== 'mp4'"
            >
              <div
                class="carousel__images"
                :style="{
                  background: `url(${contenido.contentUrl}) no-repeat center center / cover`,
                }"
              ></div>
            </template>
            <template v-if="contenido.type === 'youtube'">
              <iframe
                id="iframe"
                class="carousel__images carousel__images--video"
                :src="contenido.contentUrl"
                frameborder="0"
                allowfullscreen="allowfullscreen"
              ></iframe>
            </template>
            <template v-if="contenido.type === 'mp4'">
              <video
                muted
                controls
                loop
                class="carousel__images carousel__images--video"
              >
                <source :src="contenido.contentUrl" type="video/mp4" />
              </video>
            </template>
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <div class="carousel__footer">
      <div class="carousel__footer__item py-2 d-flex">
        <div
          v-for="(contenidoArray, i) in data"
          :key="i"
          class="pr-1"
          :class="{
            selected: i === slideVisible,
          }"
          @click="slideVisible = i"
        >
          <div v-for="(contenido, i) in contenidoArray" :key="i">
            <template
              v-if="contenido.type !== 'youtube' && contenido.type !== 'mp4'"
            >
              <div
                class="carousel__footer__images"
                :style="{
                  background: `url(${contenido.contentUrl}) no-repeat center center / cover`,
                }"
              ></div>
            </template>
            <template
              v-if="contenido.type === 'youtube' || contenido.type === 'mp4'"
            >
              <div
                class="
                  carousel__footer__images carousel__footer__images--video
                  d-flex
                  align-items-center
                  justify-content-center
                "
              >
                <b-icon icon="play-btn"></b-icon>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CarouselImagenes",
  props: {
    data: {
      required: true,
      type: Array,
    },
    interval: {
      required: true,
      default: 6000,
    },
  },
  data() {
    return {
      slideVisible: 0,
    };
  },
};
</script>

<style lang="scss" scoped>
$heightCarouselSM: 60vw;
$heightCarouselMD: 50vw;
$heightCarouselLG: 35vw;
$heightCarouselXL: 35vw;
$heightMiniatura: 5vh;

#carousel,
.carousel {
  width: 100%;
  &__item {
    width: 100%;
    height: $heightCarouselSM;
    max-height: 700px;
    @media (min-width: 720px) {
      height: $heightCarouselMD;
    }
    @media (min-width: 960px) {
      height: $heightCarouselLG;
    }
    @media (min-width: 1140px) {
      height: $heightCarouselXL;
    }
  }
  &__images {
    width: 100%;
    object-fit: contain;
    height: $heightCarouselSM;
    @media (min-width: 720px) {
      height: $heightCarouselMD;
    }
    @media (min-width: 960px) {
      height: $heightCarouselLG;
    }
    @media (min-width: 1140px) {
      height: $heightCarouselXL;
    }
    &--video {
      object-fit: fill;
      height: $heightCarouselSM;
      @media (min-width: 720px) {
        height: $heightCarouselMD;
      }
      @media (min-width: 960px) {
        height: $heightCarouselLG;
      }
      @media (min-width: 1140px) {
        height: $heightCarouselXL;
      }
    }
  }
  &__footer {
    max-width: 100%;
    &__item {
      overflow-x: auto;
      .selected {
        filter: drop-shadow(0px 0px 3px #3d99ff);
        transform: scale(1.1);
        opacity: 0.4;
        transition: all 0.6s ease;
      }
    }
    &__images {
      width: $heightMiniatura;
      height: $heightMiniatura;
      object-fit: contain;
      &:hover {
        cursor: pointer;
      }

      &--video {
        height: $heightMiniatura;
        object-fit: fill;
        background-color: #000;
        color: #fff;
      }
    }
  }
}
</style>

<style lang="scss">
#carousel {
  .carousel-indicators {
    display: none;
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    margin: auto;
    height: 100px;
    width: 20%;
    padding-left: 7%;
    z-index: 1;
  }

  .carousel-control-prev {
    padding-left: 0%;
    padding-right: 7%;
  }

  .carousel-control-prev-icon {
    background: url("../../assets/img/iconos/experiencia__left.svg") !important;
    background-size: 100% 120% !important;
    height: 70px !important;
    width: 70px !important;
  }

  .carousel-control-next-icon {
    background: url("../../assets/img/iconos/experiencia__right.svg") !important;
    background-size: 100% 120% !important;
    height: 70px !important;
    width: 70px !important;
  }
}
</style>