/* eslint-disable */
export default {
  namespaced: true,

  state: {
    MapaESP: {
      tituloP1: "Tenemos las experiencias",
      tituloP2: "más auténticas del sur",
      tituloP3: "Y PRONTAMENTE",
      tituloP4: "DE TODO CHILE"
    },
    MapaENG: {
      tituloP1: "We have",
      tituloP2: "the most authentic",
      tituloP3: "experiences",
      tituloP4: "in Chile"
    }
  },
  getters: {
    TituloP1(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.MapaESP.tituloP1;
      } else if (rootState.language == "en") {
        return state.MapaENG.tituloP1;
      }
    },
    TituloP2(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.MapaESP.tituloP2;
      } else if (rootState.language == "en") {
        return state.MapaENG.tituloP2;
      }
    },
    TituloP3(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.MapaESP.tituloP3;
      } else if (rootState.language == "en") {
        return state.MapaENG.tituloP3;
      }
    },
    TituloP4(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.MapaESP.tituloP4;
      } else if (rootState.language == "en") {
        return state.MapaENG.tituloP4;
      }
    }
  },
  mutations: {},
  actions: {}
};
