<template>
  <div class="tabla py-5">
    <b-container class="h-100">
      <b-row>
        <b-col class="mt-1 mb-3">
          <h1 class="ancho text-center">
            Revisa a que comunas de Chile puedes viajar.
          </h1>
        </b-col>
      </b-row>
      <!-- <b-row>
        <b-col>
          <b-table class="ancho mb-4" striped :fields="fields" :items="items">
            <template v-slot:thead-top>
              <b-tr>
                <b-th colspan="4" class="text-center" variant="dark"
                  >¿Puedo Viajar?</b-th
                >
              </b-tr>
            </template>

            <template v-slot:cell(comuna)>
              <b-form-input
                class="anchoSelect m-auto"
                v-model="comunaTabla1"
                list="input-list"
              ></b-form-input>
              <b-form-datalist
                id="input-list"
                :options="comunas"
              ></b-form-datalist>
            </template>

            <template v-slot:cell(fase)>
              {{ faseData1 }}
            </template>

            <template v-slot:cell(estado)>
              {{ estadoData1 }}
            </template>

            <template v-slot:cell(puedesViajar)>
              {{ puedesViajarData1 }}
            </template>
          </b-table>
        </b-col>
      </b-row> -->
      <b-row class="mt-3">
        <b-col>
          <b-table class="ancho mb-2" striped :fields="fields2" :items="items">
            <template v-slot:thead-top>
              <b-tr>
                <b-th colspan="4" class="text-center" variant="dark"
                  >¿Dónde puedo viajar ahora?</b-th
                >
              </b-tr>
            </template>

            <template v-slot:cell(comuna)>
              <b-form-input
                class="anchoSelect m-auto"
                v-model="comunaTabla2"
                list="input-list"
              ></b-form-input>
              <b-form-datalist
                id="input-list"
                :options="comunas"
              ></b-form-datalist>
            </template>

            <template v-slot:cell(fase)>
              {{ faseData2 }}
            </template>

            <template v-slot:cell(estado)>
              {{ estadoData2 }}
            </template>

            <template v-slot:cell(puedesIr)>
              {{ puedesViajarData2 }}
            </template>
          </b-table>
        </b-col>
      </b-row>
      <b-row class="fuente">
        <b-col cols="12">
          <p class="mb-2">
            <em
              >Fuente:
              <a href="https://www.gob.cl/coronavirus/pasoapaso/"
                >https://www.gob.cl/coronavirus/pasoapaso/</a
              ></em
            >
          </p>
        </b-col>
        <b-col cols="12" class="mb-2">
          <p>
            <strong><em>Actualizado: {{ day }} - {{ month }} - {{ year }}</em></strong>
          </p>
        </b-col>
      </b-row>

      <b-row class="mb-5">
        <b-col cols="12 mb-3">
          <p class="ancho mb-1">
            >
            <a href="https://c19.cl" target="_blank" class="text-dark"
              >Solicita aquí tu pasaporte sanitario C19</a
            >
          </p>
          <!-- <p class="ancho">
            >
            <a
              href="https://comisariavirtual.cl"
              target="_blank"
              class="text-dark"
              >Solicita aquí tu permiso temporal en Comisaria Virtual</a
            >
          </p> -->
        </b-col>
      </b-row>

      <!-- <b-row class="my-3">
        <b-col class="text-center">
          <div @click="irInicio()" class="banner">
            <img
              src="@/assets/img/Hero-Section.png"
              width="300px"
              alt="Logo Surreal"
            />
          </div>
        </b-col>
      </b-row> -->
    </b-container>
    <NuestrosDestinos :destinos="siteDestinosByZona({ id: zona.id })" />
    <!-- <ExperienciasImperdibles :experiencias="experienciasImperdibles" /> -->
  </div>
</template>

<script>
import axios from "axios";
import NuestrosDestinos from "@/components/NuestrosDestinos";
// import ExperienciasImperdibles from "@/components/ExperienciasImperdibles";
import { mapGetters } from "vuex";

export default {
  name: "DondeViajar",
  metaInfo: {
    title: "¿A qué comuna puedo viajar?",
  },
  data() {
    return {
      id: "sur",
      comunas: [],
      comunaTabla1: "",
      comunaTabla2: "",
      data: [],
      faseTabla1: "",
      faseTabla2: "",
      estadoTabla1: "",
      estadoTabla2: "",
      items: [
        {
          isActive: false
        }
      ],
      fields: [
        {
          key: "comuna",
          label: "¿Dónde resides?",
          class: ["text-center", "ancho250"]
        },
        {
          key: "fase",
          label: "Fase",
          class: ["text-center", "ancho50"]
        },
        {
          key: "estado",
          label: "Estado",
          class: ["text-center", "ancho100"]
        },
        {
          key: "puedesViajar",
          label: "¿Puedes viajar?",
          class: "text-center"
        }
      ],
      fields2: [
        {
          key: "comuna",
          label: "¿Dónde vas?",
          class: ["text-center", "ancho250"]
        },
        {
          key: "fase",
          label: "Fase",
          class: ["text-center", "ancho50"]
        },
        {
          key: "estado",
          label: "Estado",
          class: ["text-center", "ancho100"]
        },
        {
          key: "puedesIr",
          label: "¿Puedes ir?",
          class: "text-center"
        }
      ]
    };
  },
  components: {
    // ExperienciasImperdibles,
    NuestrosDestinos
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters([
      "experienciasImperdibles",
      "getZonas",
      "siteDestinosByZona"
    ]),
    zona() {
      if (this.id && this.id.length > 0 && this.getZonas.length > 0) {
        if (this.id === "norte") {
          const zona = this.getZonas.find(zona =>
            zona.titulo.toLowerCase().includes("norte")
          );
          return zona;
        }
        if (this.id === "centro") {
          const zona = this.getZonas.find(zona =>
            zona.titulo.toLowerCase().includes("centro")
          );
          return zona;
        }
        if (this.id === "sur") {
          const zona = this.getZonas.find(zona =>
            zona.titulo.toLowerCase().includes("sur")
          );
          return zona;
        }
        if (this.id === "patagonia") {
          const zona = this.getZonas.find(zona =>
            zona.titulo.toLowerCase().includes("patagonia")
          );
          return zona;
        }
        const zona = this.getZonas.find(zona => zona.id === this.id);
        if (zona) {
          return zona;
        }
      }
      return null;
    },
    faseData1() {
      const comuna = this.comunaTabla1;
      const data = this.data;
      if (comuna && data) {
        const finder = data.find(el => el.comuna === comuna);
        return finder.fase;
      }
      return "-";
    },
    faseData2() {
      const comuna = this.comunaTabla2;
      const data = this.data;
      if (comuna && data) {
        const finder = data.find(el => el.comuna === comuna);
        return finder.fase;
      }
      return "-";
    },
    estadoData1() {
      const comuna = this.comunaTabla1;
      const data = this.data;
      if (comuna && data) {
        const finder = data.find(el => el.comuna === comuna);
        return finder.estado;
      }
      return "-";
    },
    estadoData2() {
      const comuna = this.comunaTabla2;
      const data = this.data;
      if (comuna && data) {
        const finder = data.find(el => el.comuna === comuna);
        return finder.estado;
      }
      return "-";
    },
    puedesViajarData1() {
      const fase = this.faseData1;
      const estado = this.estadoData1;
      if (fase !== "-" && estado !== "-") {
        if (fase > 2) return "Sí";
        return "No";
      }
      return "-";
    },
    puedesViajarData2() {
      const fase = this.faseData2;
      const estado = this.estadoData2;
      if (fase !== "-" && estado !== "-") {
        if (fase > 2) return "Sí";
        return "No";
      }
      return "-";
    },
    day() {
      const now = new Date();
      const day = now.getDate();
      return day;
    },
    month() {
      const now = new Date();
      const month = now.getMonth()
      return month + 1;
    },
    year() {
      const now = new Date();
      const year = now.getUTCFullYear();
      return year;
    }
  },
  methods: {
    async getData() {
      const url = "https://us-central1-apis-varias-mias.cloudfunctions.net/api";

      try {
        const query = await axios.get(url);

        query.data.forEach(el => {
          let data = {
            text: el.COMUNA,
            value: el.COMUNA
          };
          this.comunas.push(data);

          let dataTotal = {
            comuna: el.COMUNA,
            fase: el.Paso,
            estado: el.ESTADO
          };
          this.data.push(dataTotal);
        });
      } catch (error) {
        console.log("Error Axios getData:", error);
      }
    },
    irInicio() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.2;
  @media screen and (max-width: 524px) {
    font-size: 35px;
    line-height: 43px;
  }
}
.tabla {
  // height: 100vh;
  width: 100%;
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.ancho {
  @media screen and (min-width: 768px) {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
  }
}
.anchoSelect {
  @media screen and (min-width: 768px) {
    width: 250px;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
}
.ancho50 {
  width: 50px !important;
}
.ancho100 {
  width: 100px !important;
}
.ancho250 {
  width: 250px !important;
}
.fuente {
  font-size: 12px;
  text-align: center;
  a {
    color: gray;
    transition: 300ms linear all;
    &:hover {
      color: black;
    }
  }
}
.banner {
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;
  margin: auto;
  transition: 300ms linear all;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
  &::after {
    content: "Conoce aquí las experiencias más auténticas del sur de Chile que tenemos para ti";
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
</style>
