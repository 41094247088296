<template>
  <b-container id="Evaluaciones" class="py-3">
    <b-button :to="{ name: 'AdminHome' }" variant="success" class="mb-3">
      Volver a home de admin
    </b-button>
    <h1>Dashboard de evaluaciones</h1>

    <!-- Evaluaciones por aceptar -->
    <div v-if="evaluacionesPorAceptar.length">
      <h3>Evaluaciones por aceptar</h3>

      <b-row>
        <b-col cols="12">
          <div class="tablaEvaluaciones">
            <b-table
              show-empty
              :fields="titulos"
              :items="evaluacionesPorAceptar"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              empty-text="Aun no existen evaluaciones."
              empty-filtered-text="Sin registros que coincidan con la búsqueda..."
              head-variant="dark"
              table-variant="light"
              striped
              hover
              class="mb-5"
              responsive
            >
              <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template>
          
              <template v-slot:cell(fecha)="data">
                {{ data.item.fecha | toReadableDate }}
              </template>
          
              <template v-slot:cell(comentario)="data">
                <b-form-rating
                  :value="data.item.rating"
                  no-border
                  readonly
                  inline
                  variant="warning"
                  show-value
                  class="rating"
                ></b-form-rating>
                <p class="mb-1">
                  <b>{{ data.item.tituloComentario }}</b>
                </p>
                <p>{{ data.item.comentario }}</p>
              </template>
          
              <template v-slot:cell(acciones)="data">
                <b-row v-if="data.item">
                  <b-col class="p-1">
                    <b-button
                      pill
                      class="p-1"
                      size="sm"
                      block
                      variant="success"
                      @click="
                        cambiarEstadoDeEvaluacion({
                          id: data.item.id,
                          estado: 'Aceptada',
                        })
                      "
                      >Aceptar</b-button
                    >
                  </b-col>
                  <b-col class="p-1">
                    <b-button
                      pill
                      class="p-1"
                      size="sm"
                      block
                      variant="danger"
                      @click="
                        cambiarEstadoDeEvaluacion({
                          id: data.item.id,
                          estado: 'Rechazada',
                        })
                      "
                      >Rechazar</b-button
                    >
                  </b-col>
                  <b-col class="p-1">
                    <b-button
                      pill
                      class="p-1"
                      size="sm"
                      block
                      variant="primary"
                      @click="abrirModalEdicion(data.item)"
                      >Editar</b-button
                    >
                  </b-col>
                </b-row>
              </template>
            </b-table>
          </div>
        </b-col>
      </b-row>
    </div>

    <!-- Evaluaciones ya aceptadas -->

    <div>
      <h2>Evaluaciones publicadas</h2>
      <b-row>
        <b-col cols="12">
          <div class="tablaEvaluaciones">
            <b-table
              show-empty
              :fields="titulos"
              :items="evaluacionesAceptadas"
              :current-page="currentPage"
              :per-page="perPage"
              :filter="filter"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :sort-direction="sortDirection"
              empty-text="Aun no existen evaluaciones."
              empty-filtered-text="Sin registros que coincidan con la búsqueda..."
              head-variant="dark"
              table-variant="light"
              striped
              hover
              class="mb-5"
              responsive
              id="EvaluacionesAceptadas"
              :total-rows="evaluacionesAceptadas.length"
            >
              <!-- <template v-slot:cell(index)="data">
                {{ data.index + 1 }}
              </template> -->
          
              <template v-slot:cell(fecha)="data">
                {{ data.item.fecha | toReadableDate }}
              </template>
          
              <template v-slot:cell(comentario)="data">
                <b-form-rating
                  :value="data.item.rating"
                  no-border
                  readonly
                  inline
                  variant="warning"
                  show-value
                  class="rating"
                ></b-form-rating>
                <p class="mb-1">
                  <b>{{ data.item.tituloComentario }}</b>
                </p>
                <p>{{ data.item.comentario }}</p>
              </template>
          
              <template v-slot:cell(acciones)="data">
                <b-row v-if="data.item">
                  <b-col class="p-1">
                    <b-button
                      pill
                      class="p-1"
                      size="sm"
                      block
                      variant="secondary"
                      v-if="data.item.estado === 'Aceptada'"
                      @click="
                        cambiarEstadoDeEvaluacion({
                          id: data.item.id,
                          estado: 'Oculta',
                        })
                      "
                      >Ocultar</b-button
                    >
                    <b-button
                      pill
                      class="p-1"
                      size="sm"
                      block
                      variant="secondary"
                      v-if="data.item.estado === 'Oculta'"
                      @click="
                        cambiarEstadoDeEvaluacion({
                          id: data.item.id,
                          estado: 'Aceptada',
                        })
                      "
                      >Publicar</b-button
                    >
                  </b-col>
                  <b-col class="p-1">
                    <b-button
                      pill
                      class="p-1"
                      size="sm"
                      block
                      variant="primary"
                      @click="abrirModalEdicion(data.item)"
                      >Editar</b-button
                    >
                  </b-col>
                </b-row>
              </template>
            </b-table>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="fill"
              aria-controls="EvaluacionesAceptadas"
              class="my-0"
            ></b-pagination>
          </div>
        </b-col>
      </b-row>

      <!-- Modal -->
      <b-modal id="editarEvaluacion" title="Editar evaluacion">
        <b-container v-if="evaluacionAEditar">
          <h5>{{ evaluacionAEditar.experiencia }}</h5>
          <div class="d-flex align-items-center">
            <p class="m-0">{{ evaluacionAEditar.nombre }}</p>
            <b-form-rating
              :value="evaluacionAEditar.rating"
              no-border
              readonly
              variant="warning"
              show-value
              class="rating"
              inline
            ></b-form-rating>
          </div>
          <label class="pt-2" for="TituloComentario">Titulo comentario: </label
          ><b-form-input
            id="TituloComentario"
            class="mb-2"
            v-model="evaluacionAEditar.tituloComentario"
            placeholder="Título comentario"
          ></b-form-input>
          <label class="pt-2" for="Comentario">Comentario: </label
          ><b-form-textarea
            id="Comentario"
            class="mb-2"
            v-model="evaluacionAEditar.comentario"
            placeholder="Comentario"
            rows="4"
          ></b-form-textarea>

          <b-row>
            <b-col class="p-2">
              <b-button
                pill
                class="p-1"
                block
                @click="confirmarEdicion"
                variant="success"
                >Confirmar</b-button
              >
            </b-col>
            <b-col class="p-2">
              <b-button
                pill
                class="p-1"
                block
                @click="cancelarEdicion"
                variant="danger"
                >Cancelar</b-button
              >
            </b-col>
          </b-row>
        </b-container>
      </b-modal>
    </div>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  name: "Evaluaciones",
  created() {},
  data() {
    return {
      //Tabla
      filter: null,
      currentPage: 1,
      perPage: 10,
      sortBy: "fecha",
      sortDesc: true,
      sortDirection: "desc",
      titulos: [
        // { key: "index", label: "N°" },
        {
          key: "fecha",
          label: "Fecha de evaluacion",
          sortable: true,
          class: "text-center",
        },
        {
          key: "experiencia",
          label: "Experiencia",
          class: "text-center",
        },
        {
          key: "nombre",
          label: "Nombre cliente",
          class: "text-center",
        },
        // {
        //   key: "rating",
        //   label: "Rating",
        //   class: "text-center",
        // },
        {
          key: "comentario",
          label: "Evaluación",
          class: "text-left",
        },
        {
          key: "sugerencias",
          label: "Sugerencias",
          class: "text-left",
        },
        // Columna Acciones
        { key: "acciones", label: "Acción", class: "text-center" },
      ],
      //Edicion
      evaluacionAEditar: null,
    };
  },
  computed: {
    ...mapState("Evaluaciones", ["evaluaciones"]),
    evaluacionesPorAceptar() {
      return this.evaluaciones.filter((ev) => ev.estado === "Pendiente");
    },
    evaluacionesAceptadas() {
      return this.evaluaciones.filter(
        (ev) => ev.estado === "Aceptada" || ev.estado === "Oculta"
      );
    },
    totalRows(){
      return this.evaluacionesAceptadas.length;
    }
  },
  methods: {
    ...mapActions("Evaluaciones", ["cambiarEstado", "editarEvaluacion"]),
    async cambiarEstadoDeEvaluacion({ id, estado }) {
      const evaluacion = this.evaluaciones.find((e) => e.id === id);
      const confirmado = confirm(
        `Confirma cambio de estado de evaluación de ${evaluacion.nombre} a '${estado}'`
      );
      confirmado ? await this.cambiarEstado({ id, estado }) : false;
    },
    abrirModalEdicion(evaluacion) {
      this.evaluacionAEditar = JSON.parse(JSON.stringify(evaluacion));
      this.$bvModal.show("editarEvaluacion");
      this.$bvModal.show("editarEvaluacion");
    },
    async confirmarEdicion() {
      const confirmado = confirm(
        `Confirma edición de evaluación de ${this.evaluacionAEditar.nombre}?`
      );
      if (confirmado) {
        const editar = await this.editarEvaluacion(this.evaluacionAEditar);
        if (editar) this.$bvModal.hide("editarEvaluacion");
      } else this.cancelarEdicion();
    },
    cancelarEdicion() {
      this.$bvModal.hide("editarEvaluacion");
      this.evaluacionAEditar = null;
    },
  },
};
</script>

<style lang="scss" scoped>
#Evaluaciones {
  color: #fff;
  .tablaEvaluaciones {
    overflow-x: scroll;
    // max-width: 100vw;
    .b-rating {
      padding: 0;
      background-color: transparent;
    }
    .table-responsive {
      .b-table {
        display: none;
      }
    }
  }
}
#editarEvaluacion {
  display: none;
}
</style>

<style lang="scss">
.tablaEvaluaciones {
  .table-responsive {
    overflow-x: scroll;
    .b-table {
    }
  }
}
</style>