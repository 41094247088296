import firebase from 'firebase'
import 'firebase/firestore'
import 'firebase/storage'

// Firebase config
const firebaseConfig = {
  apiKey: "AIzaSyCJs4zHk0oJOMU4txEAzowuzSrzU5tGGQw",
  authDomain: "sur-real.firebaseapp.com",
  databaseURL: "https://sur-real.firebaseio.com",
  projectId: "sur-real",
  storageBucket: "sur-real.appspot.com",
  messagingSenderId: "72217807499",
  appId: "1:72217807499:web:c3c338c2e3d1d788abe28e",
  measurementId: "G-H7DXGG6N92",
};

firebase.initializeApp(firebaseConfig)

export const db = firebase.firestore()
export const storage = firebase.storage()
export default firebase

