var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"NuestrasExperiencias"}},[_c('h1',[_c('span',{staticClass:"ExpTitulo"},[_vm._v(_vm._s(_vm.experienciasNombre))]),_c('span',{staticClass:"ImpTitulo",staticStyle:{"color":"#ffaa01"}},[_vm._v(_vm._s(_vm.imperdibles))])]),_c('div',{staticClass:"experiencias"},[_c('b-row',{staticClass:"m-auto"},_vm._l((_vm.misExperiencias),function(experiencia,index){return _c('b-col',{key:index,staticClass:"mb-4",attrs:{"cols":"12","sm":"4"}},[_c('b-card',{style:([
            _vm.precioOferta(experiencia.id)
              ? { border: '1px solid #D43B32' }
              : false,
          ]),scopedSlots:_vm._u([{key:"footer",fn:function(){return [(_vm.precioOferta(experiencia.id))?_c('small',{staticClass:"text-muted"},[_c('p',{staticClass:"oferta-text",staticStyle:{"text-align":"left","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.__get('now'))+" "+_vm._s(_vm.__get('from'))+" CLP $"+_vm._s(_vm.precioOfertaMinimo(experiencia.id) .toString() .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") || "... ")+" "+_vm._s(_vm.__get('perPerson'))+" ")])]):_vm._e(),_c('small',{staticClass:"text-muted"},[_c('p',{class:{ 'oferta-notext': _vm.precioOferta(experiencia.id) },staticStyle:{"font-size":"14px","text-align":"left","margin-left":"10px"}},[_vm._v(" "+_vm._s(_vm.__get('from'))+" CLP $"+_vm._s(_vm.precioMinimo(experiencia.id) .toString() .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.") || "... ")+" "+_vm._s(_vm.__get('perPerson2'))+" ")])])]},proxy:true}],null,true)},[_c('b-card-text',[_c('div',{staticClass:"imagen",style:({
                'background-image': `url('${_vm.getThumbnail({
                  contentUrl: experiencia.carouselImgUrl,
                  size: 480,
                })}')`,
              })},[_c('div',{staticClass:"capaOscura",staticStyle:{"position":"relative"}},[_c('li',{staticStyle:{"position":"absolute","right":"0","left":"0","bottom":"0"}},[_c('router-link',{staticClass:"nuestras-experiencias__link nuestras-experiencias__link--ver-mas",attrs:{"to":`/experiencias/${experiencia.id}`}},[_vm._v(_vm._s(_vm.__get('seeDetails')))])],1),_c('div',{staticClass:"calificaciones px-3",class:{
                    'pt-1':
                      _vm.evaluacionesByExperiencia(experiencia.id).length > 0,
                    'pt-3':
                      _vm.evaluacionesByExperiencia(experiencia.id).length == 0,
                  }},[_c('div',{staticClass:"stars d-flex justify-content-between align-items-center"},[_c('div',[(_vm.duracion(experiencia.id).descripcion)?_c('p',{staticClass:"m-0 text-white font-weight-bold"},[_c('span',{staticStyle:{"background":"white","color":"black","padding":"1px 8px","border-radius":"6px"}},[_vm._v(_vm._s(_vm.duracion(experiencia.id).horas))]),_vm._v(" "+_vm._s(_vm.duracion(experiencia.id).descripcion + _vm.duracion(experiencia.id).duracionMinutos)+" ")]):_vm._e()]),(
                        _vm.evaluacionesByExperiencia(experiencia.id).length > 0
                      )?_c('div',{staticClass:"card_rating d-flex align-items-center"},[_c('b-form-rating',{staticClass:"rating p-0",attrs:{"value":_vm.promedioRates(experiencia.id),"no-border":"","readonly":"","inline":"","variant":"warning"}}),_c('p',{staticClass:"m-0 text-white font-weight-bold"},[_vm._v(" ("+_vm._s(_vm.evaluacionesByExperiencia(experiencia.id).length)+") ")])],1):_vm._e()]),(_vm.precioOferta(experiencia.id))?_c('div',{staticClass:"oferta-logo"},[_vm._v(" "+_vm._s(Math.round( ((_vm.precioMinimo(experiencia.id) - _vm.precioOfertaMinimo(experiencia.id)) / _vm.precioMinimo(experiencia.id)) * 100 ))+"% ")]):_vm._e(),_c('span',{staticClass:"d-none",staticStyle:{"color":"white"}},[_vm._v(" "+_vm._s(experiencia.visitas)+" "+_vm._s(_vm.language === "es" ? !(experiencia.visitas > 1) ? "visita" : "visitas" : " visits")+" ")])]),_c('img',{staticClass:"share d-none",attrs:{"src":require("@/assets/img/iconos/share.svg")}})])]),_c('p',{staticStyle:{"color":"#868686","text-align":"left","padding":"0px 10px","margin":"0","font-size":"13px"}},[_c('b-icon-geo-alt',{staticClass:"mt-4"}),_vm._v(" "+_vm._s(_vm.language === "es" ? experiencia.tituloDestino ? experiencia.tituloDestino : _vm.destinoByExperiencia(experiencia.id).titulo : experiencia.ingles.tituloDestino ? experiencia.ingles.tituloDestino : _vm.destinoByExperiencia(experiencia.id).ingles.titulo)+" ")],1),_c('h6',{staticClass:"text-left px-3 font-weight-bold",staticStyle:{"color":"#353535"}},[_vm._v(" "+_vm._s(_vm.language === "es" ? experiencia.titulo : experiencia.ingles.titulo)+" ")])])],1)],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }