/* eslint-disable */

export default {
  namespaced: true,

  state: {
    HeroSectionESP: {
      titulo: "Descubre el sur de Chile",
      subtitulo:
        "Experiencias auténticas que te conectarán con el sur de Chile a través de nuestra gente y su indómita naturaleza"
    },
    HeroSectionENG: {
      titulo: "Discover the real Chile",
      subtitulo:
        "Authentic experiences that will connect you with our people and its wild nature"
    }
  },
  getters: {
    HeroSectionTitulo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.HeroSectionESP.titulo;
      } else if (rootState.language == "en") {
        return state.HeroSectionENG.titulo;
      }
    },
    HeroSectionSubTitulo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.HeroSectionESP.subtitulo;
      } else if (rootState.language == "en") {
        return state.HeroSectionENG.subtitulo;
      }
    }
  }
};
