/* eslint-disable */
export default {
  namespaced: true,

  state: {
    NuestrasExperienciasESP: {
      nuestras: "Experiencias",
      experiencias: "Nuestras "
    },
    NuestrasExperienciasENG: {
      nuestras: "Unmissable",
      experiencias: "Experiences"
    }
  },
  getters: {
    imperdibles(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NuestrasExperienciasESP.nuestras;
      } else if (rootState.language == "en") {
        return state.NuestrasExperienciasENG.experiencias;
      }
    },
    experienciasNombre(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NuestrasExperienciasESP.experiencias;
      } else if (rootState.language == "en") {
        return state.NuestrasExperienciasENG.nuestras;
      }
    }
  },
  mutations: {},
  actions: {}
};
