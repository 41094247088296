import { db } from "../../firebase.service";
const solicitudes = db.collection("solicitudes");

const state = () => ({
  solicitudes: [],
  solicitud: {}
});

const getters = {
  getSolicitudes(state, payload) {
    return state.solicitudes;
  },

};

const mutations = {
  setSolicitudes(state, payload) {
    state.solicitudes = payload;
  },
  solicitudDelete(state, id) {
    const data = state.solicitudes;
    const solicitud = data.find((r) => r.id === id);

    if (solicitud) {
      state.solicitudes = state.solicitudes.filter((r) => r.id != id);
    }
  },
};

const actions = {
  async loadSolicitudes({ commit }) {
    solicitudes.onSnapshot((snapshot) => {
      const dataArray = [];
      snapshot.forEach((doc) => {
        dataArray.push({
          ...doc.data(),
          id: doc.id
        });
      });
      commit("setSolicitudes", dataArray);
    });
  },
  async eliminarSolicitud({ state, commit }, id) {
    const data = await state.solicitudes;
    const solicitud = data.find((r) => r.id === id);

    try {
      if (solicitud) {
        await solicitudes.doc(id).delete();
      }
      commit("solicitudDelete", id);
    } catch (error) {
      console.log(error);
    }
  },
  async markSeen({ state, commit }, _id) {
    const _s = await state.solicitudes;
    const id = _id;
    const findSolicitud = _s.find((r) => r.id === id);

    const editSolicitud = {...findSolicitud, revisado: "si"};

    try {
      if (findSolicitud) {
        await solicitudes.doc(id).update(editSolicitud);
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error al editar el viaje: ", error);
      return false;
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};