<template>
  <div id="ConoceDestino">
    <b-row class="iconos w-100 p-sm-5 py-5">
      <b-col cols="4" sm="2">
        <a href="#Cultura">
          <img src="@/assets/img/iconos/ConoceZona/gastronomia.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon1 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#QueVer">
          <img src="@/assets/img/iconos/ConoceZona/queHacer.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon2 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#CuandoVisitar">
          <img src="@/assets/img/iconos/ConoceZona/CuandoVisitar.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon3 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#DondeQuedarse">
          <img src="@/assets/img/iconos/ConoceZona/DondeQuedarse.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon4 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#ComoLlegar">
          <img src="@/assets/img/iconos/ConoceZona/ComoLlegar.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon5 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#ConsejosUtiles">
          <img src="@/assets/img/iconos/ConoceZona/ConsejosUtiles.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon6 }}</p>
        </a>
      </b-col>
    </b-row>
    <!-- Cultura y Gastronomía -->
    <div id="CulturaYGastronomia">
      <h1 class="mb-4 mayus" id="Cultura">{{ icon1 }}</h1>
      <b-carousel
        v-if="slideCulturaContenido && slideCulturaContenido.length > 0"
        id="carousel"
        :interval="6000"
        controls
      >
        <!-- Slides with custom text -->
        <b-carousel-slide
          style="height: 300px;"
          v-for="(contenidoArray, index) in slideCulturaContenido"
          :key="index"
        >
          <b-row>
            <b-col v-for="(contenido, index) in contenidoArray" :key="index">
              <div
                class="expImg"
                :style="{
                  background: `url(${contenido.imgUrl}) no-repeat center center / cover`
                }"
              >
                <div class="capaOscura">
                  <h3>
                    {{
                    language === "es"
                    ? contenido.titulo
                    : contenido.tituloIngles
                    }}
                  </h3>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-carousel-slide>
      </b-carousel>
      <p class="pre-wrap">{{ routeProps.culturaGastronomia }}</p>

      <h1 class="m-4 mayus" id="QueVer">{{ icon2 }}</h1>
      <p class="pre-wrap">{{ routeProps.verHacer }}</p>

      <h1 class="m-4 mayus" id="CuandoVisitar">{{ icon3 }}</h1>
      <p class="pre-wrap">{{ routeProps.cuandoVisitar }}</p>

      <h1 class="m-4 mayus" id="DondeQuedarse">{{ icon4 }}</h1>
      <p class="pre-wrap">{{ routeProps.dondeQuedarse }}</p>

      <h1 class="m-4 mayus" id="ComoLlegar">{{ icon5 }}</h1>
      <div class="row m-auto container">
        <div class="col-12 col-md-3">
          <img src="@/assets/img/zonas/Sur.png" class="w-75" />
        </div>
        <div class="col-12 col-md-9">
          <p class="pre-wrap mob">{{ routeProps.comoLlegar }}</p>
        </div>
      </div>

      <h1 class="m-4 mayus" id="ConsejosUtiles">{{ icon6 }}</h1>

      <ul>
        <li v-for="(consejo, index) in routeProps.consejos" :key="index">
          <p class="pre-wrap">* {{ consejo }}</p>
        </li>
      </ul>
      <br />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
export default {
  props: ["routeProps"],
  computed: {
    ...mapGetters(["language"]),
    slideCulturaContenido() {
      if (this.routeProps) {
        if (
          this.routeProps.contenidoCultura &&
          this.routeProps.contenidoCultura.length > 0
        ) {
          const contenidoArray = [];
          const loops = Math.ceil(this.routeProps.contenidoCultura.length / 3);

          for (let i = 0; i < loops; i++) {
            contenidoArray.push(
              this.routeProps.contenidoCultura.slice(i * 3, i * 3 + 3)
            );
          }
          return contenidoArray;
        }
      }
      return null;
    },
    ...mapGetters("ConoceDestinos", [
      "icon1",
      "icon2",
      "icon3",
      "icon4",
      "icon5",
      "icon6",
    ]),
  },
};
</script>

<style lang="scss">
#ConoceDestino {
  text-align: center;
  p {
    text-align: left;
    @media screen and (max-width: 524px) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  .iconos {
    color: #3d99ff;
    text-transform: uppercase;
    margin-bottom: 0;
    @media screen and (max-width: 524px) {
      margin-left: 0;
      padding: 0 10px;
    }
    a {
      color: #3d99ff;
      font-weight: bold;
      font-size: 16px;

      &:hover {
        text-decoration-line: none;
      }
    }

    img {
      padding-bottom: 15px;
      @media screen and (max-width: 524px) {
        width: 81px;
        height: 81px;
      }
    }
  }

  #CulturaYGastronomia {
    h1 {
      color: #3d99ff;
      font-size: 40px;
      padding-top: 15px;
      font-weight: bold;
      @media screen and (max-width: 524px) {
        font-size: 25px;
        line-height: 30px;
        padding-right: 40px;
        padding-left: 40px;
      }
    }

    p {
      color: #868686;
      width: 75%;
      margin: auto;
      @media screen and (max-width: 524px) {
        text-align: left;
        width: 80%;
        font-size: 14px;
        line-height: 28px;
        font-weight: 400;
        margin-left: 37px;
        margin-right: 37px;
      }
    }

    .mob {
      @media screen and (max-width: 524px) {
        margin: auto;
        padding: 0 !important;
        text-align: left;
        width: 100%;
      }
    }

    #carousel {
      .capaOscura {
        height: 260px;
        position: relative;
        z-index: 2;

        h3 {
          background: rgba(0, 0, 0, 0.5);
          margin: 0;
          text-align: left;
          padding: 10px;
          font-size: 17px;
        }
      }

      .expImg {
        margin: 0 10px 0 10px;
        border-radius: 10px;
      }
    }

    .col div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .carousel-control-next-icon {
      background: url("../../assets/img/iconos/right.svg") !important;
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    .carousel-control-prev-icon {
      background: url("../../assets/img/iconos/left.svg") !important;
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    .carousel-caption {
      width: 80%;
      margin-left: -5%;
      padding-bottom: 0;
    }
  }
}
.mayus {
  text-transform: uppercase;
}
</style>
