import firebase from 'firebase';
import reservaciones from '@/store/modules/reservaciones'
import emailjs from 'emailjs-com'

const db = firebase.firestore()
const evaluacionesCollection = db.collection('evaluaciones');

const serviceID = "default_service";
const userID = "user_phHSRepn7EuELwmpwDkNi";


export default {
    namespaced: true,
    state: {
        evaluaciones: []
    },
    mutations: {
        GET_EVALUACIONES(state, evaluaciones) {
            state.evaluaciones = evaluaciones;
        },
        CAMBIO_DE_ESTADO(state, payload) {
            state.evaluaciones.map(ev => {
                if (ev.id === payload.id) {
                    ev.estado = payload.estado
                    return ev
                }
                return ev;
            })
        },
        EDITAR_EVALUACION(state, evaluacion) {
            state.evaluaciones.map(ev => {
                if (ev.id === evaluacion.id) {
                    ev = evaluacion
                    return ev
                }
                return ev;
            })
        }
    },
    actions: {
        async getEvaluaciones({ commit }) {
            try {
                evaluacionesCollection.onSnapshot(snapshot => {
                    let dataArray = []
                    snapshot.forEach(doc => {
                        const data = {
                            data: doc.data()
                        }
                        data.data.id = doc.id

                        dataArray.push(data.data)
                    })
                    commit('GET_EVALUACIONES', dataArray)
                })

            } catch (error) {
                console.log('Error al cargar evaluaciones: ', error);
            }
        },
        async crearEvaluacion({ commit }, evaluacion) {
            try {
                //Creación de evaluacion
                await evaluacionesCollection.add(evaluacion)

                //Reserva marcada como evaluada
                const collection = evaluacion.reservaInmediata
                    ? db.collection("reservas")
                    : db.collection("reservasPorConfirmar");

                await collection.doc(evaluacion.reservaID).update({ evaluada: true });

                //Correo de notificacion a admin
                const templateID = 'notificacion_evaluacion'
                const templateParams = {
                    cliente_name: evaluacion.nombre,
                    experience_name: evaluacion.experiencia
                }

                await emailjs.send(serviceID, templateID, templateParams, userID);

                return { success: true }

            } catch (error) {
                console.log('error al crear evaluacion', error);
                return { success: false, error: error }
            }
        },
        async cambiarEstado({ commit }, payload) {
            try {
                await evaluacionesCollection.doc(payload.id).update({ estado: payload.estado })
                commit('CAMBIO_DE_ESTADO', payload)
            } catch (error) {
                alert('Error al cambiar estado de evaluacion: ', error)
            }
        },
        async editarEvaluacion({ commit }, evaluacion) {
            const evaluacionSinId = JSON.parse(JSON.stringify(evaluacion))
            delete evaluacionSinId.id

            try {
                await evaluacionesCollection.doc(evaluacion.id).update(evaluacionSinId)
                commit('EDITAR_EVALUACION', evaluacion)
                return true
            } catch (error) {
                alert('Error al editar evaluacion: ', error)
                return false
            }
        }
    },
    getters: {
        evaluacionesByExperiencia: (state) => (id) => {
            return state.evaluaciones.filter(ev => ev.experienciaID === id && ev.estado === 'Aceptada')

        },
        evaluacionesOrdenadasSegunNota: (state, getters) => (id) => {
            const evaluaciones = getters.evaluacionesByExperiencia(id)
            const suma = evaluaciones.reduce((acc, obj) => {
                let key = obj.rating
                if (!acc[key]) {
                    acc[key] = []
                }
                acc[key].push(obj)
                return acc
            }, {})

            return suma
        },
        promedioRates: (state, getters) => (id) => {
            const evaluaciones = getters.evaluacionesByExperiencia(id)
            let promedio = 0
            if (evaluaciones.length) {
                const soloNotas = evaluaciones.map(e => e.rating)
                const sumaDeNotas = soloNotas.reduce((acc, obj) => acc + obj)
                promedio = Math.round((sumaDeNotas / soloNotas.length) * 10) / 10
            }
            return promedio
        }
    },
}