<template>
	<div>
		<b-modal size="xl" ref="form" hide-footer :title="typeForm == 'add' ? 'Agregar Itinerario' : 'Editar Itinerario'">
			<div class="d-block text-center">
				<b-row>
					<b-col cols="6">
						<h4>Español</h4>
					</b-col>
					<b-col cols="6">
						<h4>Ingles</h4>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="6">
						<b-form-group id="input-group-1" label="Itinerario" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="Titulo itinerario" v-model="form.es.title" required></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Día" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="Dia itinerario" v-model="form.es.day" required></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Durante la noche" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="" v-model="form.es.overnight" required></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Descripcion" label-for="input-1">
							<b-form-textarea rows="6" v-model="form.es.description"></b-form-textarea>
						</b-form-group>
					</b-col>
					<b-col cols="6">
						<b-form-group id="input-group-1" label="Itinerario" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="Titulo itinerario" v-model="form.en.title" required></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Día" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="Dia itinerario" v-model="form.en.day" required></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Durante la noche" label-for="input-1">
							<b-form-input id="input-1" type="text" placeholder="" v-model="form.en.overnight" required></b-form-input>
						</b-form-group>
						<b-form-group id="input-group-1" label="Descripcion" label-for="input-1">
							<b-form-textarea rows="6" v-model="form.en.description"></b-form-textarea>
						</b-form-group>
					</b-col>
				</b-row>
				<div v-if="typeForm == 'edit' || tripId != null">
					<b-form-group id="input-group-1" label="Seleccione una imagen de cabecera" label-for="input-1"
						class="">
						<input class="form-control" accept="image/*,.mp4" name="img_hero" type="file" placeholder="Seleccione una imagen" @change="addImage">
					</b-form-group>
					<div class="edit-zona__img-container">
						<a
							v-if="form.image"
							href="javascript:(void)"
							@click.prevent="deleteImg()"					
							class="edit-zona__img-link">
							<img
							:src="form.image"
							alt="heading-img"
							class="edit-zona__img">
						</a>
					</div>
				</div>
				<div v-else>
					<span>Primero guarde el viaje y luego, se agregan las imagenes, esto por motivos de consistencia de datos</span>
				</div>
			</div>
			<b-row class="mt-5" v-if="typeForm == 'add'">
				<b-col cols="4">
					<b-button class="mt-2" variant="outline-danger" block @click="hideModal">Cerrar</b-button>
				</b-col>
				<b-col cols="4">
					<b-button class="mt-2" variant="outline-info" block @click="handleSubmit(true)">Guardar y agregar otro</b-button>
				</b-col>
				<b-col cols="4">
					<b-button class="mt-2" variant="outline-success" block @click="handleSubmit(false)">Guardar</b-button>
				</b-col>
			</b-row>
			<b-row class="mt-5" v-if="typeForm == 'edit'">
				<b-col cols="4">
					<b-button class="mt-2" variant="outline-danger" block @click="hideModal">Cerrar</b-button>
				</b-col>
				<b-col cols="4">
				</b-col>
				<b-col cols="4">
					<b-button class="mt-2" variant="outline-success" block @click="handleEditSubmit()">Actualizar</b-button>
				</b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import { storage } from "../../../../firebase.service";
export default {
	props: {
		typeForm :{
			type: String,
			default: 'add'
		},
		formEdit: {
			type: Object
		},
		tripId: {
			type: String
		}
	},
	data: () => ({
		form: {
			es: {},
			en: {},
			image: null,
			token: null
		},
		editing: false
	}),
	methods: {
		showModal() {
			this.$refs.form.show();
		},
		hideModal() {
			this.clearForm();
			this.$emit('handle-clean-form');
			this.$refs.form.hide();
		},
		handleSubmit(flag) {
			this.handleValidateToken();
			this.$emit('handle-add-itinerary', this.form);
			this.clearForm();

			if(!flag)
				this.hideModal();

		},
		handleEditSubmit() {
			this.$emit('handle-edit-itinerary', this.form);
			this.clearForm();
			this.hideModal();
		},
		clearForm() {
			this.form = {
				es: {},
				en: {},
				image: null,
				token: null
			}
		},
		handleValidateToken(){
			this.form.token = this.form.token == null ? Math.random().toString(36).substr(2) : this.form.token;

			console.log('token =>', this.form.token);
		},
		addImage({target}) {

			this.handleValidateToken();

			if(this.form.image) {
				alert('Ya se ha seleccionado una imagen, si va a cambiarla elimine la actual');
				return;
			}

			const file = target.files[0]
			const fileReader = new FileReader()
			fileReader.addEventListener('load', () => {
				const imgUrl = fileReader.result
				const imgFile = file
				this.handleStorageImg(imgUrl, imgFile, this.tripId, this.form.token)
				target.value = ''
			})
			fileReader.readAsDataURL(file)
		},
		async handleStorageImg(imgUrl, imgFile, key, token) {

			console.log('token =>' , token);
			let path = 'trips_personalized';
			const extension = imgFile.name.slice(imgFile.name.lastIndexOf('.'))
			let imageStringPath;

			imageStringPath = `${path}/${key}/itineraries/${token}${extension}`;

			console.log(imageStringPath);

			const upload = await storage.ref().child(imageStringPath);
			const storageResult = await upload.put(imgFile);

			this.form.image = await storageResult.ref.getDownloadURL();

		},
		async deleteImg() {

			const imgRef = storage.refFromURL(this.form.image);
			const fileName = imgRef.name.split(".")[0];
			const parentFiles = await imgRef.parent.listAll();

			const references = parentFiles.items.filter(ref => {
				// Filtrar por nombre de archivo
				const isItem = ref.name.includes(fileName);
				return isItem;
			});

			await Promise.all(references.map(ref => ref.delete()));

			this.form.image = null;
		
		}
	},
	watch: {
		formEdit(value) {
			if(this.typeForm == 'edit')
				this.form = this.formEdit;
		},
		"form.image" : {
			handler: function (val) {
				console.log('cambio');
			},
			deep: true
		}
	}
}
</script>