<template>
  <a :href="this.$route.path === '/' ? 'https://api.whatsapp.com/send?phone=56952068914&text=Tengo%20una%20consulta:%20Estoy%20en:%20' + 'https://surreal.travel' : 'https://api.whatsapp.com/send?phone=56952068914&text=Tengo%20una%20consulta:%20Estoy%20en:%20' + 'https://surreal.travel' + this.$route.path" target="_blank">
      <img
        class="whatsapp-icon"
        v-b-tooltip.hover title="¿Tienes una duda? ¡Contáctanos!"
        src="/img/icons/whatsapp60p_compressed.png"
        alt="whatsapp"
        :style="[$route.name === 'ExperienciaDetalle' ? {bottom: '7%'} : {bottom: '5%'} ]"
      />
    </a>
</template>

<script>
export default {
  name: "WhatsAppChat",
  data() {
    return {
      url: `https://api.whatsapp.com/send?phone=56952068914&text=Tengo%20una%20consulta:%20Estoy%20en:%20${window.location.href}`
    };
  },
}
</script>

<style lang="scss" scoped>
.whatsapp-icon {
  position: fixed;
  z-index: 99;
  right: 2%;
  // bottom: 5%;
  transition: 200ms all linear;
  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}
</style>