<template>
<div>
  <div @click="hamburguerToggle" class="all" :class="{ 'd-none': !hamburguer }"></div>
  <div :class="{ hamburguer: hamburguer, 'd-none': !hamburguer }">
    <div class="header">
      <svg @click="hamburguerToggle" width="14" height="14" viewBox="0 0 298.667 298.667">
        <g>
          <g>
            <polygon points="298.667,30.187 268.48,0 149.333,119.147 30.187,0 0,30.187 119.147,149.333 0,268.48 30.187,298.667 149.333,179.52 268.48,298.667 298.667,268.48 179.52,149.333" />
          </g>
        </g>
      </svg>
      <router-link to="/">
        <img @click="hamburguerToggle" src="@/assets/img/logo.png" alt="SurRealLogo" />
      </router-link>
    </div>
    <div class="links">
      <router-link :to="{ name: 'Nosotros' }">
        <span @click="hamburguerToggle">Nosotros</span>
      </router-link>
      <!-- <a href="#" @click="
            buscadorToggle();
            hamburguerToggle();
          ">
        destinos
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" fill="#353535" viewBox="0 0 24 24">
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </a> -->
      <a href="#">
        CLP
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" fill="#353535" viewBox="0 0 24 24">
          <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </a>
      <router-link :to="{ name: 'Checkout' }">
        <span @click="hamburguerToggle">Carro de Compras</span>
      </router-link>
      <a @click="hamburguerToggle" href="/#Contactanos">Contacto</a>
      <div class="lenguages">
          <div
          
          :class="{
            'menu-top__list-item': true,
            'menu-top__list-item--active': (language === 'en')
          }"
          @click="(language === 'en') ? null : switchLanguage(), hamburguerToggle()"
        >
          <img src="@/assets/img/iconos/eng.png" alt="ENG" class="mr-2" /> <span :style="language === 'en' ? 'color: #3D99FF; font-weight: 600;' : ''">EN</span>
        </div>
        <div
          style="margin-left: 15px;"
          :class="{
            'menu-top__list-item': true,
            'menu-top__list-item--active': (language === 'es')
          }"
          @click="(language === 'es') ? null : switchLanguage(), hamburguerToggle()"
        >
          <img src="@/assets/img/iconos/esp.svg" alt="ESP" class="mr-2"  /> <span :style="language === 'es' ? 'color: #3D99FF; font-weight: 600;' : ''">ES</span>
        </div>
      </div>
    </div>
    <div class="footer">
      <p>
        <a @click="hamburguerToggle" href="mailto:contacto@surreal.travel">contacto@surreal.travel</a>
      </p>
      <p class="redes">
        <a href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="12.66" height="23.63" viewBox="0 0 16.641 31.071">
            <path d="M38.441,17.478l.863-5.623h-5.4V8.205c0-1.538.754-3.038,3.17-3.038h2.453V.38A29.913,29.913,0,0,0,35.177,0c-4.443,0-7.348,2.693-7.348,7.569v4.286H22.89v5.623h4.939V31.071h6.079V17.478Z" transform="translate(-22.89)" />
          </svg>
        </a>
        <a href="https://www.instagram.com/surreal.travel/">
          <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24.94" height="24.94" viewBox="0 0 24 24">
            <path d="M7.8,2H16.2C19.4,2 22,4.6 22,7.8V16.2A5.8,5.8 0 0,1 16.2,22H7.8C4.6,22 2,19.4 2,16.2V7.8A5.8,5.8 0 0,1 7.8,2M7.6,4A3.6,3.6 0 0,0 4,7.6V16.4C4,18.39 5.61,20 7.6,20H16.4A3.6,3.6 0 0,0 20,16.4V7.6C20,5.61 18.39,4 16.4,4H7.6M17.25,5.5A1.25,1.25 0 0,1 18.5,6.75A1.25,1.25 0 0,1 17.25,8A1.25,1.25 0 0,1 16,6.75A1.25,1.25 0 0,1 17.25,5.5M12,7A5,5 0 0,1 17,12A5,5 0 0,1 12,17A5,5 0 0,1 7,12A5,5 0 0,1 12,7M12,9A3,3 0 0,0 9,12A3,3 0 0,0 12,15A3,3 0 0,0 15,12A3,3 0 0,0 12,9Z" />
          </svg>
        </a>
        <a href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width="23.63" height="23.63" viewBox="0 0 31.071 31.07">
            <path d="M6.955,31.08H.513V10.336H6.955ZM3.731,7.506A3.748,3.748,0,1,1,7.461,3.741,3.762,3.762,0,0,1,3.731,7.506ZM31.064,31.08H24.636v-10.1c0-2.407-.049-5.493-3.349-5.493-3.349,0-3.862,2.615-3.862,5.319V31.08H10.99V10.336h6.178v2.83h.09a6.769,6.769,0,0,1,6.095-3.35c6.519,0,7.718,4.293,7.718,9.869V31.08Z" transform="translate(0 -0.01)" />
          </svg>
        </a>
      </p>
    </div>
  </div>
</div>
</template>

<script>
/* eslint-disable */
import {
  mapState,
  mapMutations,
  mapActions,
  mapGetters
} from "vuex";
export default {
  name: "Nav",
  computed: {
    ...mapState(["hamburguer"]),
    ...mapGetters(["language"]),
  },
  methods: {
    ...mapActions(["switchLanguage"]),
    ...mapMutations(["hamburguerToggle", "buscadorToggle"]),
  },
};
</script>

<style scoped>
.lenguages{
  display: flex;
  align-items: flex-end;
}
</style>

<style lang="scss">
.hamburguer {
  position: fixed;
  height: 100vh;
  width: 75vw;
  background-color: #f6f6f6;
  left: 0;
  top: 0;
  z-index: 2000;
  opacity: 0.98;
  box-shadow: 0 3px 10px #00000029;
  padding: 0 22px;
  animation: menu 250ms;

  @keyframes menu {
    0% {
      left: -100%;
    }

    50% {
      left: -50%;
    }

    100% {
      left: 0;
    }
  }

  .header {
    width: 100%;
    background: none;

    svg {
      position: absolute;
      top: 27px;
      left: 27px;
    }

    a {
      display: inline-block;
      width: 100%;
      text-align: center;
    }

    img {
      width: 146px;
    }
  }

  .links,
  .footer {
    margin: 20px 0 20px 14px;
  }

  .links {
    a {
      font-size: 19px;
      font-weight: bold;
      color: #353535;
      line-height: 23pt;
      text-transform: uppercase;
      display: block;
      margin-bottom: 15px;

      &:hover {
        opacity: 0.9;
      }
    }
  }

  .footer {

    p,
    p a,
    p a {
      color: #353535;
    }

    >p {
      font-size: 18px;
      font-weight: medium;
      line-height: 33pt;
      margin-bottom: 10px;
    }

    a {
      display: inline-block;
      margin-right: 42px;
    }
  }
}

// Click fuera del nav para ocultar nuevamente
.all {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}
</style>
