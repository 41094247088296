<template>
  <div class="edit-zona">
    <div class="header">
      <div class="container container--flex-nav">
        <button
          class="edit-zona__btn edit-zona__btn--nav"
          @click="navigateFromDestino"><i class="fas fa-arrow-alt-circle-left"></i>Ir a zona</button>
        <div class="buttons">
          <!-- Switch para ingles/castellano -->
          <button
            v-if="language === 'es'"
            type="button"
            class="edit-zona__btn edit-zona__btn--primary"
            style="margin-right: 50px;"
            @click="switchLanguage">Agregar contenido en ingles</button>
          <button
            v-if="language === 'en'"
            type="button"
            class="edit-zona__btn edit-zona__btn--success"
            style="margin-right: 50px;"
            @click="switchLanguage">Volver al contenido en español</button>
          <div class="edit-zona__loading-button">
            <button
              v-if="id"
              type="button"
              class="edit-zona__btn edit-zona__btn--warning"
              @click="deleteRequest({ id, zonaId })"
              :disabled="isLoading">Eliminar Destino</button>
            <div v-if="isLoading" class="lds-dual-ring"></div>
          </div>
        </div>
      </div>
    </div>
    <h1 class="heading heading--1 heading--center">Gestion de destino {{ (language === 'en') ? '(Ingles)' : ''}}</h1>
    <h3 v-if="destino.titulo" class="heading heading--3 heading--center">{{destino.titulo}}</h3>
    <form
      @submit.enter.prevent="(id) ? submitRequest({ zonaId , id, action: 'update'}) : submitRequest({ zonaId , action: 'create'})"
      class="edit-zona__form-container">
      <div v-if="language === 'es'" class="edit-zona__container">
        <div v-if="id" class="edit-zona__experiencias">
          <h3 class="heading heading--3 heading--center">Experiencias</h3>
          <ul class="edit-zona__destinos-list">
            <router-link
              v-for="(experiencia, index) in getExperienciasByDestino"
              :key="index"
              tag="li"
              :to="`${id}/experiencias/${experiencia.id}`"
              class="edit-zona__experiencia"><button type="button" class="edit-zona__btn edit-zona__btn--link">{{ experiencia.titulo }}</button></router-link>
              <router-link
              tag="button"
              :to="`${id}/experiencias/registrar-experiencia`"
              class="edit-zona__experiencia"><button type="button" class="edit-zona__btn edit-zona__btn--success edit-zona__btn--link"><i class="fas fa-plus-circle"></i>Registrar experiencia</button></router-link>
          </ul>
        </div>
      </div>
      <div class="edit-zona__informacion edit-zona__informacion--1">
        <h3 class="heading heading--3 heading--center">Informacion principal</h3>
        <div class="edit-zona__container edit-zona__container--flex">
          <div class="edit-zona__side edit-zona__side--left">
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Titulo</label>
              <input
                class="edit-zona__input"
                type="text"
                name="titulo"
                id="titulo"
                :value="(language === 'es') ? destino.titulo : destino.ingles.titulo"
                @input="handleChange">
            </div>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Subtitulo</label>
              <input
                class="edit-zona__input"
                type="text"
                name="subtitulo"
                id="subtitulo"
                :value="(language === 'es') ? destino.subtitulo : destino.ingles.subtitulo"
                @input="handleChange">
            </div>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Resumen</label>
              <textarea
                class="edit-zona__textarea"
                name="resumen"
                id="resumen"
                cols="30"
                rows="10"
                :value="(language === 'es') ? destino.resumen: destino.ingles.resumen"
                @input="handleChange"></textarea>
            </div>

            <hr>

            <h3 class="heading heading--3 heading--center mt-4">Configuración SEO</h3>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Titulo:</label>
              <input
                class="edit-zona__input"
                type="text"
                name="seo_titulo"
                id="seo_titulo"
                :value="(language === 'es') ? destino.seo_titulo : destino.ingles.seo_titulo"
                @input="handleChange">
            </div>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Slug</label>
              <input
                class="edit-zona__input"
                type="text"
                name="slug"
                id="slug"
                :value="destino.slug"
                @input="handleChange">
            </div>

          </div>
          <div v-if="language === 'es'" class="edit-zona__side edit-zona__side--right">
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Imagen de previsualizacion</label>
              <input type="file" name="carousel-img" accept="image/*" @change="addImg($event, 'carousel')">
              <a
                href=""
                v-if="(destino.carouselImgUrl || zonaImgsToSave.carouselImgUrl)"
                @click.prevent="(destino.carouselImgUrl) ?
                  deleteImg({ type: 'carousel', level: 'destinos', url: destino.carouselImgUrl, id: id })
                  :
                  deleteUnsavedImg({ type: 'carousel' })"
                class="edit-zona__img-link">
                <img
                :src="(destino.carouselImgUrl) ? destino.carouselImgUrl : zonaImgsToSave.carouselImgUrl"
                alt="heading-img"
                class="edit-zona__img">
              </a>
            </div>

            <!-- Gestion de destinos | Gestion de imagenes para el hero section -->
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Imagenes para el hero</label>
              <input type="file" accept="image/*,.mp4" name="hero-imgs" @change="addHeroImg($event)">
              <div class="edit-zona__img-container">
                <a
                  href=""
                  class="edit-zona__img-container"
                  v-for="(content, index) in getHeroContentBorrador"
                  :key="index"
                  @click.prevent="deleteUnsavedImg({ type: 'hero', index })">
                  <template v-if="content.type !== 'video'">
                    <img
                      :src="content.contentUrl"
                      alt="hero-images"
                      class="edit-zona__img">
                  </template>
                  <template v-else>
                    <video autoplay muted loop class="edit-zona__img edit-zona__img--1">
                      <source :src="content.contentUrl">
                    </video>
                  </template>
                </a>
              </div>
              <div class="edit-zona__img-container">
                <a
                  href=""
                  class="edit-zona__img-container"
                  v-for="(content, index) in getHeroContent"
                  :key="index"
                  @click.prevent="deleteImg({
                    type: 'hero',
                    level: 'destinos',
                    url: content.contentUrl,
                    id: id
                  })">
                  <template v-if="content.type !== 'mp4'">
                    <img
                      :src="content.contentUrl"
                      alt="hero-images"
                      class="edit-zona__img">
                  </template>
                  <template v-else>
                      <video autoplay muted loop class="edit-zona__img edit-zona__img--1">
                        <source :src="content.contentUrl">
                      </video>
                  </template>
                </a>
              </div>
            </div>
            <!--  -->

            <!-- Informacion principal | Contenido de youtube (Omitido por ahora (v-if="false"))-->
            <div class="edit-zona__input-group" v-if="false">
              <h5 class="heading heading--5">Contenido de Youtube</h5>
              <div
                class="edit-zona__container--fluid"
                v-if="(destino.youtubeMedia.length > 0)">
                <ul class="edit-zona__list edit-zona__list--1">
                  <li
                    class="edit-zona__list-item"
                    v-for="(item, index) in destino.youtubeMedia"
                    :key="index">
                    <div class="edit-zona__list-content">
                      <div class="edit-zona__container--fluid">
                        <iframe class="edit-zona__youtube-iframe" :src="item" frameborder="0"></iframe>
                      </div>
                      <div class="edit-zona__buttons edit-zona__buttons--right">
                        <button
                          type="button"
                          @click="deleteYoutubeMedia({ type: 'destino', borrador: false, index })"
                          class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <!-- Gestion de informacion importante | items de la seccion youtubeMedia agregados al borrador -->
              <div class="edit-zona__container--fluid" v-if="(destino.borrador.youtubeMedia.length > 0)">

                <label class="edit-experiencia__label">Contenido por agregar</label>

                <ul class="edit-zona__list edit-zona__list--1">
                  <li
                    class="edit-zona__list-item"
                    v-for="(item, index) in destino.borrador.youtubeMedia"
                    :key="index">
                    <div class="edit-zona__list-content">
                      <a :href="item">Ir al video</a>
                      <div class="edit-zona__container">
                        <iframe class="edit-zona__youtube-iframe" :src="item" frameborder="0"></iframe>
                      </div>
                      <div class="edit-zona__buttons edit-zona__buttons--right">
                        <button
                          type="button"
                          @click="deleteYoutubeMedia({ type: 'destino', borrador: true, index })"
                          class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                      </div>
                    </div>
                  </li>
                </ul>

              </div>

              <!-- Gestion de informacion importante | input que se mostrara si se desea agregar un item en 'Incluye'-->
              <div class="edit-zona__input-group" v-if="youtubeMediaToAdd">
                <label class="edit-zona__label">Item</label>
                <input v-model="youtubeMedia" type="text" class="edit-zona__input">
              </div>
              <!--  -->
              <button
                v-if="!youtubeMediaToAdd"
                type="button"
                name="youtubeMedia"
                @click="addItem"
                class="edit-zona__btn edit-zona__btn--primary">Registrar nuevo item</button>
              <button
                v-else
                type="button"
                name="youtubeMedia"
                @click="addItem"
                class="edit-zona__btn edit-zona__btn--success">Agregar</button>

            </div>

            <!--  -->
          </div>
        </div>
      </div>
      <div class="edit-zona__informacion edit-zona__informacion--2">
        <h3 class="heading heading--3 heading--center">Informacion adicional</h3>
        <div class="edit-zona__container">
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Cultura y Gastronomia</label>
            <textarea
              class="edit-zona__textarea"
              name="culturaGastronomia"
              id="cultura-gastronomia"
              cols="30"
              rows="10"
              :value="(language === 'es') ? destino.info.culturaGastronomia : destino.ingles.info.culturaGastronomia"
              @input="handleChange($event, 'info')"></textarea>
          </div>

          <div class="edit-zona__gastronomia-content">
            <h3 class="heading heading--3 heading--center">Carousel de cultura y gastronomia</h3>

            <!-- Gestion de imagenes en cultura y agronomia | Imagenes ya agregadas en base de datos -->
            <div class="edit-zona__contenido-cultura" v-if="destino.contenidoCultura && destino.contenidoCultura.length > 0">
              <h3 class="heading heading--3 heading--center">Contenido ya agregado</h3>
              <div class="edit-zona__gestion-cultura edit-zona__gestion-cultura--agregar">

                <div
                  v-for="(contenido, index) in destino.contenidoCultura"
                  @click="deleteCulturaContent({
                    type: 'guardado',
                    index
                  })"
                  :key="index"
                  class="edit-zona__agregar-contenido">

                  <a href="" @click.prevent="" v-if="contenido.imgUrl" class="edit-zona__img-container edit-zona__img-container--cultura">
                    <img class="edit-zona__img edit-zona__img--cultura" :src="contenido.imgUrl" alt="contenido-cultura">
                  </a>

                  <div class="edit-zona__input-group">
                    <label class="edit-zona__label">Titulo</label>
                    <input class="edit-zona__input" :value="(language === 'es') ? contenido.titulo : contenido.tituloIngles" type="text" disabled>
                  </div>

                </div>
              </div>
            </div>

            <!-- Gestion de imagenes en cultura y agronomia | Imagenes para agregar -->
            <div class="edit-zona__contenido-cultura" v-if="contenidoCulturaBorrador.length > 0">
              <h3 class="heading heading--3 heading--center">Contenido para agregar</h3>
              <div class="edit-zona__gestion-cultura edit-zona__gestion-cultura--agregar">

                <div
                  v-for="(contenido, index) in contenidoCulturaBorrador"
                  @click="deleteCulturaContent({
                    type: 'borrador',
                    index
                  })"
                  :key="index"
                  class="edit-zona__agregar-contenido">

                  <a
                    href=""
                    @click.prevent=""
                    v-if="contenido.imgUrl"
                    disabled
                    class="edit-zona__img-container edit-zona__img-container--cultura">

                    <img class="edit-zona__img edit-zona__img--cultura" :src="contenido.imgUrl" alt="contenido-cultura">

                  </a>

                  <div class="edit-zona__input-group">
                    <label class="edit-zona__label">Titulo</label>
                    <input class="edit-zona__input" :value="contenido.titulo" type="text" disabled>
                  </div>

                </div>
              </div>
            </div>

            <!-- Gestion de imagenes en cultura y gastronomia | Formulario para agregar -->
            <div v-if="openEdit" class="edit-zona__culturaIMG edit-zona__culturaIMG--agregar">
              <div class="edit-zona__input-group">
                <label class="edit-zona__label">Titulo</label>
                <input class="edit-zona__input" style="width: 50%;" type="text" v-model="contenidoTitulo">
              </div>
              <div class="edit-zona__input-group">
                <label class="edit-zona__label">Titulo (Ingles)</label>
                <input class="edit-zona__input" style="width: 50%;" type="text" v-model="contenidoTituloIngles">
              </div>
              <div class="edit-zona__input-group">
                <label class="edit-zona__label">Imagen</label>
                <input type="file" accept="image/*" @change="addContenidoImg">
              </div>
              <a href="" v-if="contenidoImgUrl" @click.prevent="deleteContenidoImg">
                <img class="edit-zona__img edit-zona__img--cultura" :src="contenidoImgUrl" alt="agregar-cultura-contenido">
              </a>
            </div>
            <!-- Gestion de imagenes en cultura y gastronomia | boton para activar el formulario -->
            <button
              class="edit-zona__btn edit-zona__btn--primary"
              type="button"
              @click="openContenido"
              v-if="language === 'es' && !openEdit">Agregar nueva imagen</button>

            <button
              class="edit-zona__btn edit-zona__btn--success"
              type="button"
              @click="openContenido"
              v-if="language === 'es' && openEdit">Agregar</button>

          </div>

          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Ver y hacer</label>
            <textarea
              class="edit-zona__textarea"
              name="verHacer"
              id="ver-hacer"
              cols="30"
              rows="10"
              :value="(language === 'es') ? destino.info.verHacer : destino.ingles.info.verHacer"
              @input="handleChange($event, 'info')"></textarea>
          </div>
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Cuando visitar</label>
            <textarea
              class="edit-zona__textarea"
              name="cuandoVisitar"
              id="cuando-visitar"
              cols="30"
              rows="10"
              :value="(language === 'es') ? destino.info.cuandoVisitar : destino.ingles.info.cuandoVisitar"
              @input="handleChange($event, 'info')"></textarea>
          </div>
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Donde quedarse</label>
            <textarea
              class="edit-zona__textarea"
              name="dondeQuedarse"
              id="donde-quedarse"
              cols="30"
              rows="10"
              :value="(language === 'es') ? destino.info.dondeQuedarse : destino.ingles.info.dondeQuedarse"
              @input="handleChange($event, 'info')"></textarea>
          </div>
          <div class="edit-zona__input-group">
            <label class="edit-zona__label">Como llegar</label>
            <textarea
              class="edit-zona__textarea"
              name="comoLlegar"
              id="como-llegar"
              cols="30"
              rows="10"
              :value="(language === 'es') ? destino.info.comoLlegar : destino.ingles.info.comoLlegar"
              @input="handleChange($event, 'info')"></textarea>
          </div>

          <div v-if="language == 'es'" class="edit-zona__localizacion">
            <h3 class="heading heading--3">Orden</h3>
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Prioridad de destino</label>
              <input
                class="edit-zona__input"
                type="number"
                step="any"
                name="latitud"
                :value="destino.localizacion.latitud"
                @input="handleChange($event, 'localizacion')">
            </div>
            <!-- <div class="edit-zona__input-group">
              <label class="edit-zona__label">Longitud</label>
              <input
                class="edit-zona__input"
                type="number"
                step="any"
                name="longitud"
                :value="destino.localizacion.longitud"
                @input="handleChange($event, 'localizacion')">
            </div> -->
          </div>

          <div v-if="language == 'es'" class="edit-zona__localizacion-img">
            <!-- Informacion principal | Imagen para la localidad -->
            <div class="edit-zona__input-group">
              <label class="edit-zona__label">Imagen de localizacion</label>
              <input type="file" name="carousel-img" accept="image/*" @change="addImg($event, 'localidad')">
              <a
                href=""
                v-if="(destino.localidadImgUrl || zonaImgsToSave.localidadImgUrl)"
                @click.prevent="(destino.localidadImgUrl) ?
                  deleteImg({
                    type: 'localidad',
                    level: 'destinos',
                    url: destino.localidadImgUrl,
                    id: id
                  })
                  :
                  deleteUnsavedImg({ type: 'localidad' })"
                class="edit-zona__img-link">
                <img
                :src="(destino.localidadImgUrl) ? destino.localidadImgUrl : zonaImgsToSave.localidadImgUrl"
                alt="heading-img"
                class="edit-zona__img">
              </a>
            </div>
          </div>

          <h3 class="heading heading--3 heading--">Consejos</h3>
          <div class="edit-zona__input-group" v-if="(language === 'es' && destino.info.consejos.length > 0) || (language === 'en' && destino.ingles.info.consejos.length > 0)">
            <label class="edit-zona__label">Consejos ya agregados</label>

            <ul class="edit-zona__list edit-zona__list--1">
              <li
                v-for="(consejo, index) in (language === 'es') ? destino.info.consejos : destino.ingles.info.consejos"
                :key="index">
                <label class="edit-zona__label" v-if="language === 'es'">Consejo {{(destino.info.consejos.length > 1) ? index + 1 : ''}}</label>
                <label class="edit-zona__label" v-else>Consejo {{(destino.ingles.info.consejos.length > 1) ? index + 1 : ''}}</label>
                <div class="edit-zona__list-content">
                  <p class="paragraph">{{ consejo }}</p>
                  <div class="edit-zona__buttons edit-zona__buttons--right">
                    <button
                      type="button"
                      @click="borrarConsejo({ index, level: 'destino', type: 'guardados'})"
                      class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="edit-zona__input-group" v-if="(language === 'es' && consejosToSave.length > 0) || (language === 'en' && destino.ingles.info.consejosToSave.length > 0)">
            <label class="edit-zona__label">Consejos por agregar</label>
              <ul class="edit-zona__list edit-zona__list--1">
              <li
                v-for="(consejo, index) in (language === 'es') ? consejosToSave : destino.ingles.info.consejosToSave"
                :key="index">
                <label class="edit-zona__label" v-if="language === 'es'">Consejo {{(consejosToSave.length > 1) ? index + 1 : ''}}</label>
                <label class="edit-zona__label" v-else>Consejo {{(destino.ingles.info.consejosToSave.length > 1) ? index + 1 : ''}}</label>
                <div class="edit-zona__list-content">
                  <p class="paragraph">{{ consejo }}</p>
                  <div class="edit-zona__buttons edit-zona__buttons--right">
                    <button
                      type="button"
                    @click="borrarConsejo({ index, type: 'borrador', level: 'destino'})"
                      class="edit-zona__btn edit-zona__btn--warning edit-zona__btn--consejos">Eliminar</button>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div class="edit-zona__input-group" v-if="addConsejo">
            <label class="edit-zona__labe">Consejo</label>
            <input class="edit-zona__input" v-model="consejo" type="text">
          </div>

          <div>
            <button
              type="button"
              class="edit-zona__btn edit-zona__btn--primary"
              @click="activateConsejo">Agregar Consejo</button>
          </div>

        </div>
      </div>
      <div class="edit-zona__buttons">
        <div class="edit-zona__loading-button">
          <button
            class="edit-zona__btn edit-zona__btn--primary"
            :disabled="isLoading">Guardar</button>
          <div v-if="isLoading" class="lds-dual-ring"></div>
        </div>
        <button
          @click="cancelarDestino"
          class="edit-zona__btn edit-zona__btn--secondary"
          style="margin: 50px;"
          type="button"
          :disabled="isLoading">Cancelar</button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { storage } from '../../firebase.service'

export default {
  metaInfo: {
    title: "Editar destino",
  },
  data () {
    return {
      id: null,
      zonaId: null,
      addConsejo: false,
      consejo: null,
      isZonaUpdate: false,
      contenidoTitulo: null,
      contenidoTituloIngles: null,
      contenidoImg: null,
      contenidoImgUrl: null,
      openEdit: false,
      youtubeMedia: null,
      youtubeMediaToAdd: false
    }
  },
  created () {
    this.resetLanguage()
    const { name, params } = this.$route
    this.zonaId = params.id
    if (name === 'RegistrarDestino') {
      this.resetZona({ level: 'destino' })
    } else if (name === 'EditDestino') {
      this.zonaId = params.zonaId
      this.id = params.id
      this.setZonaId(this.zonaId)
      this.setDestinoId(this.id)
    }
    this.resetBorrador()
  },
  computed: {
    ...mapGetters([
      'destino',
      'zonaImgsToSave',
      'consejosToSave',
      'getExperienciasByDestino',
      'contenidoCulturaBorrador',
      'language',
      'isLoading',
      'delete'
    ]),
    getHeroContent () {
      if (this.destino && this.destino.heroImgUrls) {
        if (this.destino.heroImgUrls.length > 0) {
          const heroContentRef = this.destino.heroImgUrls.map((contentUrl) => {
            const { name } = storage.refFromURL(contentUrl)
            const nameParts = name.split('.')
            return {
              type: nameParts[1],
              contentUrl
            }
          })
          return heroContentRef
        }
      }
      return null
    },
    getHeroContentBorrador () {
      if (this.zonaImgsToSave.heroImgUrls && this.zonaImgsToSave.heroImgUrls.length > 0) {
        const heroContentRef = this.zonaImgsToSave.heroImgUrls.map((contentUrl) => {
          const extension = contentUrl.split(';')[0].split(':')[1].split('/')[0]
          return {
            type: extension,
            contentUrl
          }
        })
        return heroContentRef
      }
      return null
    }
  },
  methods: {
    ...mapActions([
      'setZonaId',
      'setDestinoId',
      'addZonaImg',
      'addZonaHeroImg',
      'updateZona',
      'submitDestino',
      'deleteImg',
      'addHeroImg',
      'updateValue',
      'deleteUnsavedImg',
      'submitConsejo',
      'borrarConsejo',
      'resetZona',
      'deleteFromCulturaSaved',
      'deleteDestino',
      'switchLanguage'
    ]),
    ...mapMutations([
      'addToCulturaBorrador',
      'deleteFromCulturaBorrador',
      'resetBorrador',
      'resetLanguage',
      'switchLoading',
      'switchDelete',
      'addYoutubeMedia',
      'deleteYoutubeMedia'
    ]),
    async submitRequest (value) {
      try {
        this.switchLoading(true)
        await this.submitDestino(value)
        this.switchLoading(false)
      } catch (error) {
        this.switchLoading(false)
        console.log(error)
      }
    },
    async deleteRequest (value) {
      try {
        this.switchLoading(true)
        this.switchDelete(true)
        await this.deleteDestino(value)
        this.switchLoading(false)
        this.switchDelete(false)
      } catch (error) {
        this.switchLoading(false)
        this.switchDelete(false)
        console.log(error)
      }
    },
    // Funcion para gestionar videos de youtube dentro de zonas
    //
    // addItem (event) {
    //   const { name } = event.target
    //   switch (name) {
    //     case 'youtubeMedia':
    //       if (name === 'youtubeMedia' && this.youtubeMediaToAdd) {
    //         if (this.youtubeMedia && this.youtubeMedia.length > 0) {
    //           const youtubePattern = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/gm
    //           if (this.youtubeMedia.match(youtubePattern)) {
    //             const formattedLink = this.youtubeMedia.replace('watch?v=', 'embed/')
    //             this.addYoutubeMedia({ type: 'destino', value: formattedLink })
    //             this.youtubeMedia = null
    //             this.youtubeMediaToAdd = false
    //           } else {
    //             alert('Ingrese un link de youtube valido')
    //           }
    //         }
    //         return
    //       }
    //       this.youtubeMediaToAdd = true
    //       break
    //     default:
    //       break
    //   }
    // },
    openContenido () {
      const isFilled = (this.contenidoImgUrl && this.contenidoImg && this.contenidoTitulo && this.contenidoTitulo.length > 0 && this.contenidoTituloIngles && this.contenidoTituloIngles.length > 0)
      if (isFilled) {
        const data = {
          titulo: this.contenidoTitulo,
          tituloIngles: this.contenidoTituloIngles,
          imgUrl: this.contenidoImgUrl,
          img: this.contenidoImg
        }
        this.addToCulturaBorrador(data)
        this.contenidoTitulo = null
        this.contenidoTituloIngles = null
        this.contenidoImg = null
        this.contenidoImgUrl = null
        this.openEdit = false
        return
      }

      if (!(this.contenidoCulturaBorrador.length > 0) || !this.openEdit) {
        this.openEdit = true
      }
    },
    deleteCulturaContent (request) {
      if (request.type === 'borrador') {
        this.deleteFromCulturaBorrador({ level: 'destino', index: request.index })
      } else if (request.type === 'guardado') {
        this.deleteFromCulturaSaved({ level: 'destino', index: request.index })
      }
    },
    deleteContenidoImg () {
      this.contenidoImg = null
      this.contenidoImgUrl = null
    },
    addContenidoImg (event) {
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        this.contenidoImgUrl = fileReader.result
        this.contenidoImg = files[0]
        event.target.value = ''
      })
      fileReader.readAsDataURL(files[0])
    },
    navigateFromDestino () {
      this.$router.push({ path: `/admin/zonas/${this.zonaId}` })
    },
    cancelarDestino () {
      this.$router.push({ path: '/admin' })
    },
    activateConsejo () {
      if (this.addConsejo) {
        if (this.consejo && this.consejo.length > 0) {
          this.submitConsejo({ consejo: this.consejo, level: 'destino' })
          this.consejo = null
        }
        return
      }
      this.addConsejo = true
    },
    addImg (event, type) {
      if (type === 'carousel' && this.destino.carouselImgUrl) {
        alert('Debe eliminar la imagen ya registrada antes de registrar una nueva')
        return
      }
      if (type === 'localidad' && this.destino.localidadImgUrl) {
        alert('Debe eliminar la imagen ya registrada antes de registrar una nueva')
        return
      }
      const files = event.target.files
      const fileReader = new FileReader()
      fileReader.addEventListener('load', () => {
        const imgUrl = fileReader.result
        const imgFile = files[0]
        this.addZonaImg({ imgUrl, imgFile, type })
        event.target.value = ''
      })
      fileReader.readAsDataURL(files[0])
    },
    handleChange (event, type = 'normal') {
      const { name, value } = event.target
      const level = 'destino'
      this.updateValue({ name, value, level, type })
    }
  }
}
</script>

<style lang="scss" scoped>
.fa-plus-circle {
  font-size: 1.6rem;
  margin-right: 10px;
}
.edit-zona {
  color: white;
  &__input-text {
    cursor: inherit;
  }
  &__textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;
  }
  &__experiencia {
    color: #333;
    box-shadow: 1px 1px 5px black;
    margin: 20px;
    border-radius: 5px;
    background: white;
    border: 1px solid black;
    display: inline-block;
    border: none;
  }
  &__img--cultura {
    margin: auto;
  }
  &__container {
    width: 90%;
    margin: auto;
  }
  &__form-container {
    padding-top: 35px;
  }
  &__label {
    margin: 0;
    font-size: 1.3rem;
  }
  &__container--flex {
    display: flex;
    justify-content: space-between;
  }
  &__side {
    width: 45%
  }
  &__informacion {
    padding: 50px 0;
  }
  &__buttons {
    width: 100%;
    text-align: center;
    &--right {
      text-align: right;
    }
  }
  &__btn {
    border-radius: 5px;
    margin: 10px 0;
    padding: 10px;
    font-weight: 500;
    border: none;
    &--warning {
      background: rgb(206, 64, 64);
      color: white;
    }
    &--success {
      color: white;
      background: rgb(12, 128, 12);
    }
    &--primary {
      color: white;
      background: rgb(55, 127, 221);
    }
    &--consejos {
      margin: 0 20px 0 0;
    }
    &--link {
      margin: 0;
      border: none;
      font-size: 1.3rem;
      font-weight: 500;
      padding: 10px 25px;
      transition: transform .25s ease;
      &:hover {
        transform: scale(1.03);
      }
    }
  }
  &__img {
    height: 150px;
    width: 180px;
    margin: 10px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px;
    &--1 {
      background: rgb(26, 26, 26);
    }
  }
  &__img-container {
    display: flex;
    flex-wrap: wrap;
  }
  &__img-container--cultura{
    justify-content: center;
    cursor: inherit;
  }
  &__experiencia {
    box-shadow: 1px 1px 5px black;
    border-radius: 5px;
    background: white;
    cursor: pointer;
    display: inline-block;
  }
  &__experiencia-link,
  &_experiencia-link:visited,
  &__experiencia-link:active {
    color: #333;
    font-size: 1.5rem;
    font-weight: 600;
  }
  &__localizacion {
    width: 500px;
  }
}

.heading {
  &--center {
    text-align: center;
  }
}

</style>
