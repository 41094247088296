<template>
<div id="Privacidad">
  <div class="banner">
    <div class="contain">
      <h1 class="titulo m-0">{{ titulo }}</h1>
    </div>
  </div>
  <div class="container container-body p-sm-5">
    <h1 class="azul mb-4">Políticas de Privacidad Surreal Travel</h1>
    <p>
      EL USO DE ESTE SITIO WEB ESTÁ SUJETO A NUESTRA POLÍTICA DE PRIVACIDAD. ESTA SE ENTIENDE COMO COMPRENDIDA Y ACEPTADA
      POR CUALQUIER USUARIO QUE REALICE UNA RESERVA A TRAVÉS DE NUESTRO SITIO WEB.
    </p>
    <ul class="px-5">
      <li class="gris">
        Solo te solicitaremos información personal necesaria para proveer los servicios que
        ofrecemos y registrar detalles sobre tus preferencias, considerando aquello que necesita el
        proveedor para la operación normal del tour y entregar la información requerida para
        ingreso a parques.
      </li>

      <li class="gris">
        Al entregarnos información personal accedes a que Surreal Travel pueda hacer uso de esta
        con fines de atención al cliente, comerciales, y estadísticos para uso interno.
      </li>

      <li class="gris">
        Surreal Travel se compromete a no entregar información a entes externos ni utilizar la
        información personal de los clientes para acciones no especificadas en los puntos anteriores.
      </li>

      <li class="gris">
        Al hacer una reserva en nuestro sitio web mediante tarjeta de crédito o débito, accederás al
        sitio web del medio de pago seleccionado, el que te solicitará datos de tu tarjeta y
        contraseñas en un ambiente seguro.
      </li>

      <li class="gris">
        Nunca te solicitaremos información de tu tarjeta de crédito o débito ni tampoco claves de
        ningún tipo vía telefónica ni mediante correo electrónico, chat online o WhatsApp.
      </li>

      <li class="gris">
        En todo momento, el cliente podrá modificar, rectificar o eliminar sus datos personales, lo
        que deberá ser solicitado al correo contacto@surreal.travel
      </li>

      <li class="gris">
        Nuestro sitio web utiliza cookies. Las cookies son archivos que contienen un identificador
        que se envía desde un servidor web a un navegador web y se almacena en el navegador. El
        navegador envía al servidor el identificador cada vez que solicita una página del servidor. Las
        cookies generalmente no contienen información de identificación personal, pero la
        información personal recopilada de usted a través de su uso de nuestro sitio puede
        almacenarse pasivamente y obtenerse de las cookies. En este sitio, las cookies simplemente
        se utilizarán para mejorar la funcionalidad y la conveniencia de uso. Por ejemplo, las cookies
        pueden almacenar sus credenciales de inicio de sesión para que no tenga que ingresarlas
        cada vez que regrese al sitio web o navegue a otra página. La mayoría de los navegadores
        web tienen una sección de Ayuda que le permite configurar sus notificaciones con respecto
        a las cookies y desactivarlas.
      </li>
    </ul>
  </div>
</div>
</template>

<style lang="scss">
#Privacidad {
  .banner {
    background: #3d99ff;
    padding: 30px;

    @media screen and (max-width: 524px) {
      padding: 20px 60px;
    }

    .titulo {
      color: white;
      font-size: 30px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 25px;
      }
    }
  }

  .container-body {
    @media screen and (max-width: 524px) {
      padding: 30px 36px;
    }
  }

  .azul {
    color: #3d99ff;
    font-size: 24px;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 22px;
    }
  }

  .gris {
    color: #868686;
    font-size: 16px;
    padding: 5px 0px;

    @media screen and (max-width: 524px) {
      font-size: 18px;
    }
  }

  p {
    color: #868686;
    font-size: 16px;
    line-height: 35px;

    @media screen and (max-width: 524px) {
      font-size: 14px;
      line-height: 28px;
    }
  }
}
</style>

<script>
/* eslint-disable */
import {
  mapGetters
} from "vuex";

export default {
  name: "Privacidad",
  metaInfo() {
    return {
      title: 'Políticas de privacidad',
    };
  },
  computed: {
    ...mapGetters("Privacidad", ["titulo"]),
  },
};
</script>
