<template>
  <div id="PorqueViajarConNosotros">
    <div class="capaAzul">
      <h1>{{ Titulo }}</h1>
      <b-row class="motivos">
        <b-col cols="6" md="3">
          <img src="@/assets/img/PorqueViajar/araucaria.svg" alt="" />
          <h2>{{ T1 }}</h2>
          <p>{{ P1 }}</p>
        </b-col>
        <b-col cols="6" md="3">
          <img
            src="@/assets/img/PorqueViajar/community.svg"
            alt=""
            style="padding: 10px"
          />
          <h2>{{ T2 }}</h2>
          <p>{{ P2 }}</p>
        </b-col>
        <b-col cols="6" md="3">
          <img src="@/assets/img/PorqueViajar/corona.svg" alt="" />
          <h2>{{ T3 }}</h2>
          <p>{{ P3 }}</p>
        </b-col>
        <b-col cols="6" md="3">
          <img src="@/assets/img/PorqueViajar/guarantee.svg" alt="" />
          <h2>{{ T4 }}</h2>
          <p>{{ P4 }}</p>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#PorqueViajarConNosotros {
  background: url("../assets/img/Hero-Section.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  text-align: center;
  .capaAzul {
    padding: 40px;
    background: rgba(56, 161, 255, 0.9);
    @media screen and (max-width: 524px) {
      padding: 40px 30px;
    }
    h1 {
      margin: auto;
      width: 500px;
      font-size: 48px;
      font-weight: bold;
      margin-bottom: 40px;
      @media screen and (max-width: 524px) {
        font-size: 25px;
        width: 100%;
      }
    }
    .motivos {
      div {
        padding: 0 30px 0 30px;
        @media screen and (max-width: 524px) {
          padding: 0 20px;
        }
        p {
          color: white;
          @media screen and (max-width: 524px) {
            font-size: 12px;
            line-height: 14px;
            margin-top: 12px;
          }
        }
      }
      img {
        height: 150px;
        @media screen and (max-width: 524px) {
          height: 80px;
          margin-bottom: 16px;
        }
      }
      h2 {
        font-size: 28px;
        width: 100%;
        margin: 0;
        font-weight: bold;
        @media screen and (max-width: 524px) {
          font-size: 14px;
        }
      }
    }
  }
}
</style>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';

export default {
  name: "PorqueViajar",
  computed: {
    ...mapGetters("PorqueViajar", ["Titulo", "T1", "T2", "T3", "T4", "P1", "P2", "P3", "P4"])
  },
}
</script>