<template>
<div id="app">
  <transition name="fade" mode="out-in">
    <router-view />
  </transition>
  <Contactanos />
  <Footer />
  <FooterBottom />
</div>
</template>

<script>
import Footer from "@/components/Footer";
import Contactanos from "@/components/Contactanos";
import FooterBottom from "@/components/FooterBottom";

export default {
  components: {
    Contactanos,
    Footer,
    FooterBottom,
  },
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,500i,700&display=swap");

body {
  font-family: "Montserrat", sans-serif !important;
}

html {
  scroll-behavior: smooth !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.redondo {
  border-radius: 6px !important;
}

.fade-enter-active {
  transition: all 0.15s ease;
}

.fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
  {
  opacity: 0;
}
</style>
