<template>
  <div id="Busqueda" class="container pt-sm-5">
    <div class="buscador mobile">
      <div style="position: relative">
        <div class="dropdownDestinos">
          <h5 class="d-none d-sm-block">
            {{ BuscadorDestinos }}
            <img src="@/assets/img/iconos/down.svg" alt="flechaAbajo" />
          </h5>
          <h5 class="d-block d-sm-none">
            <input
              v-model="busqueda"
              @keyup.enter="buscar"
              style="width: 90%"
              :placeholder="BuscadorDestinos"
              name
              id
            />
            <img src="@/assets/img/iconos/down.svg" alt />
          </h5>
          <div class="dropdownDestinosList">
            <ul>
              <router-link
                tag="li"
                v-for="(zona, index) in getSortedZonas"
                :to="`/zonas/${nameAsId({ id: zona.id })}/overview`"
                :key="index"
                class="text-center"
                :class="{
                  opcionesZonas: true,
                  Centro: zona.titulo.toLowerCase().includes('centro'),
                  Lagos: zona.titulo.toLowerCase().includes('lagos'),
                  Patagonia: zona.titulo.toLowerCase().includes('patagonia'),
                }"
                v-show="index == 2"
              >
                <!-- V-Show agregado para ocultar zonas que no sean sur  -->
                {{ zona.titulo }}
                <div
                  :class="{
                    subNorte: zona.titulo.toLowerCase().includes('norte'),
                    subCentro: zona.titulo.toLowerCase().includes('centro'),
                    subLagos: zona.titulo.toLowerCase().includes('lagos'),
                    subPatagonia: zona.titulo
                      .toLowerCase()
                      .includes('patagonia'),
                  }"
                >
                  <ul>
                    <router-link
                      tag="li"
                      v-for="(destino, index) in siteDestinosByZona({
                        id: zona.id,
                      })"
                      :key="index"
                      class
                      :to="`/destinos/${destino.id}/experiencias`"
                      >{{ destino.titulo }}</router-link
                    >
                  </ul>
                </div>
              </router-link>
            </ul>
          </div>
        </div>

        <b-form-input
          list="my-list-id"
          class="d-none d-sm-block"
          v-model="busqueda"
          @keyup.enter="buscar"
          :placeholder="BuscadorPlaceholder"
        ></b-form-input>

        <b-input-group-text
          class="lupa"
          style="position: absolute; top: 0; right: 0; height: 50px"
          @click="
            buscar();
            buscadorFalse();
          "
        >
          <img src="@/assets/img/iconos/lupaBlanca.svg" alt />
        </b-input-group-text>
      </div>
    </div>

    <h5 class="titulo mt-sm-4">Resultados</h5>
    <div class="resultados">
      <router-link
        tag="b-row"
        style="cursor: pointer"
        v-for="(item, index) in showByZona ? resultsByZona : searchResult"
        :to="
          item.hasOwnProperty('precio')
            ? `/experiencias/${item.id}`
            : `/destinos/${item.id}/experiencias`
        "
        :key="index"
        class="pb-4"
      >
        <b-col cols="4">
          <div
            :style="{
              background: `url('${getThumbnail({
                contentUrl: item.carouselImgUrl,
                size: 480,
              })}') no-repeat center center / cover`,
            }"
            class="imagen"
          ></div>
        </b-col>
        <b-col cols="12" sm="8">
          <div class="descripcion">
            <span>{{
              item.hasOwnProperty("precio") ? "Experiencia" : "Destino"
            }}</span>
            <h3>{{ item.titulo }}</h3>
            <p class="busqueda__paragraph">{{ item.resumen }}</p>
          </div>
        </b-col>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Buscador from "./../components/Buscador.vue";
export default {
   metaInfo() {
    return {
      title: `Buscar: ${this.$route.params.search}`,
    };
  },
  computed: {
    ...mapGetters([
      "getExperiencias",
      "getDestinos",
      "getZonas",
      "getSortedZonas",
      "siteDestinosByZona",
    ]),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapGetters("Buscador", ["BuscadorDestinos", "BuscadorPlaceholder"]),
    searchResult() {
      if (this.busqueda && this.busqueda.length > 0) {
        const elementsArray = [...this.getExperiencias, ...this.getDestinos];
        const resultados = elementsArray.filter((element) => {
          return (
            element.titulo &&
            element.titulo.toLowerCase().includes(this.busqueda.toLowerCase())
          );
        });
        return resultados;
      }
      return null;
    },
  },
  data() {
    return {
      busqueda: null,
      resultsByZona: [],
      showByZona: false,
    };
  },
  components: {
    Buscador,
  },
  watch: {
    busqueda() {
      this.showByZona = false;
    },
  },
  methods: {
    buscar(e) {
      if (this.busqueda && this.busqueda.length > 0) {
        this.$router.push({
          path: `/busqueda/${this.busqueda}`,
        });
      }
    },
    setResultsByZona({ id }) {
      this.showByZona = true;
      const destinosByZona = this.siteDestinosByZona({
        id,
      });
      let experienciaIds = [];
      destinosByZona.forEach((destino) => {
        experienciaIds = experienciaIds.concat(destino.experiencias);
      });
      const experienciasByZona = this.getExperiencias.filter((experiencia) =>
        experienciaIds.includes(experiencia.id)
      );
      this.resultsByZona = [...destinosByZona, ...experienciasByZona];
    },
    nameAsId({ id }) {
      if (this.getSortedZonas && this.getSortedZonas.length > 0) {
        const zona = this.getSortedZonas.find((zona) => zona.id === id);
        if (zona.titulo.toLowerCase().includes("norte")) {
          return "norte";
        }
        if (zona.titulo.toLowerCase().includes("centro")) {
          return "centro";
        }
        if (zona.titulo.toLowerCase().includes("sur")) {
          return "sur";
        }
        if (zona.titulo.toLowerCase().includes("patagonia")) {
          return "patagonia";
        }
        return id;
      }
    },
  },
  created() {
    const { search } = this.$route.params;
    if (search && search.length > 0) {
      this.busqueda = search;
    }
  },
  mounted() {
    document.getElementsByClassName("buscadorli")[0].style.zIndex = "-1";
  },
  beforeDestroy() {
    document.getElementsByClassName("buscadorli")[0].style.zIndex = "1";
  },
};
</script>

<style lang="scss">
.busqueda {
  &__paragraph {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

#Busqueda {
  padding: 50px;

  @media screen and (max-width: 524px) {
    padding: 30px 36px;
  }

  .buscador {
    width: 100%;
    max-width: 830px;
    margin: auto;

    input {
      height: 50px;
      border-radius: 0px 25px 25px 0px;
      width: 70%;
      margin-left: 30%;

      @media screen and (max-width: 524px) {
        height: 33px;
        margin-left: 3%;
      }

      &:focus {
        @media screen and (max-width: 524px) {
          margin-left: 0px;
        }

        outline: none;
        position: relative;
        z-index: 1;
        box-shadow: none;
      }
    }

    .lupa {
      position: absolute !important;
      top: 0;
      right: 0;
      height: 50px;
      background: #38a1ff 0% 0% no-repeat padding-box;
      margin-left: -110px;
      position: relative;
      z-index: 2;
      border: none;
      border-radius: 0px 25px 25px 0px;
      cursor: pointer;

      @media screen and (max-width: 524px) {
        height: 36px !important;
      }

      img {
        @media screen and (max-width: 524px) {
          height: 20px;
          width: 20px;
        }
      }

      @media screen and (max-width: 524px) {
        height: 36px;
        position: absolute;
        top: 0;
        right: 0;
        width: 15%;
      }
    }

    .dropdownDestinos {
      position: absolute;
      z-index: 12;
      width: 30%;
      height: 50px;
      background: white;
      border: 1px groove rgba(0, 0, 0, 0.185);
      border-radius: 25px 0 0 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #495057;
      font-weight: lighter;

      @media screen and (max-width: 524px) {
        height: 36px;
        width: 85%;
      }

      h5 {
        margin: 4px 0px 0px 0px;
        font-size: 16px;
        color: #495057;
        font-weight: 400;
        text-align: center;

        @media screen and (max-width: 524px) {
          font-size: 12px;
          width: 100%;
          position: absolute;
          top: 6px;

          input {
            border-radius: 25px 0 0 25px;
            position: absolute;
            top: -9.5px;
            left: 0;
            padding: 0px 20px;
            border: solid 0px gray;

            // &:focus {
            //   background: #38a1ff;
            //   color: white;
            //   border-radius: 25px 0 0 25px transparent;

            //   &::placeholder {
            //     color: white;
            //   }
            // }
          }
        }
      }

      &:hover {
        border-radius: 25px 0 0;
        background: #38a1ff;

        @media screen and (max-width: 524px) {
          font-size: 12px;
          border-radius: 25px 0 0 25px;
          background: white;
        }

        h5 {
          color: white;
          font-weight: 500;
          font-size: 17px;

          @media screen and (max-width: 524px) {
            font-size: 12px;
            text-align: left;
            margin-left: 20px;
          }

          img {
            display: none;
          }
        }

        .dropdownDestinosList {
          display: block;

          @media screen and (max-width: 524px) {
            display: none;
          }

          ul {
            border-radius: 0px 0px 0px 25px;
            overflow: hidden;

            @media screen and (max-width: 524px) {
              border-radius: 0;
            }

            li:nth-child(1):hover {
              .subNorte {
                display: block;
                height: auto;

                ul {
                  flex-direction: column;
                  height: auto;
                }
              }
            }

            li:nth-child(2):hover {
              .subCentro {
                display: block;
                height: auto;

                ul {
                  flex-direction: column;
                  height: auto;
                }
              }
            }

            li:nth-child(3):hover {
              .subLagos {
                display: block;
                height: auto;

                ul {
                  flex-direction: column;
                  height: auto;
                }
              }
            }

            li:nth-child(4):hover {
              .subPatagonia {
                display: block;
                height: auto;

                ul {
                  flex-direction: column;
                  height: auto;
                }
              }
            }

            li div {
              display: none;
            }

            li:hover {
              color: rgb(54, 54, 54);
            }
          }
        }
      }

      .dropdownDestinosList {
        display: none;
        position: absolute;
        top: 49px;
        width: 100%;
        transition: all 300ms;

        @media screen and (max-width: 524px) {
          top: 36px;
        }

        ul {
          list-style: none;
          border-radius: 25px;
          background: white;

          @media screen and (max-width: 524px) {
            border-radius: 18px;
            margin-bottom: 0;
            z-index: 11;
          }

          li {
            background: white;
            color: gray !important;
            cursor: pointer;
            padding: 3px;
            font-size: 16px;

            @media screen and (max-width: 524px) {
              font-size: 12px;
            }

            &:hover {
              color: white !important;
            }
          }

          .opcionesZonas {
            // Se agregó estas 2 lineas por ocultar zonas !== sur
            border: solid 1px #858585;
            border-radius: 0px 0px 0px 25px;
            // border-bottom: groove 1px #858585;
            font-weight: lighter;
            color: #495057;
            padding: 5px 0px;
            width: 100%;
            display: flex;
            justify-content: center;
            padding: 5px 0px;

            @media screen and (max-width: 524px) {
              justify-content: space-between;
              align-items: center;
              padding: 5px 10px 5px 20px;
              font-size: 12px;
              height: 40px;
            }

            &:hover {
              background: #38a1ff;
              color: white;
              font-weight: 500;

              @media screen and (max-width: 524px) {
                font-size: 12px;
              }

              div ul li {
                text-shadow: none;
              }
            }

            div {
              border-left: groove 1px;
            }

            div ul li {
              font-weight: lighter;
              width: 100%;
              display: flex;
              justify-content: center;
              border-bottom: groove 1px;
              padding: 5px;

              @media screen and (max-width: 524px) {
                border-bottom: solid 1px #38a1ff;
              }

              &:hover {
                background: #38a1ff;
                color: white;
                font-weight: 500;
              }
            }

            .subMenu,
            .subNorte,
            .subCentro,
            .subLagos,
            .subPatagonia {
              position: absolute;
              top: 0;
              left: 100%;
              width: 100%;
              border-radius: 0px 0px 25px 25px;
              overflow: hidden;

              @media screen and (max-width: 524px) {
                border-radius: 0 0 18px 18px;
                left: 0;
                top: 100%;
                animation: menus 200ms linear;

                @keyframes menus {
                  0% {
                    opacity: 0;
                    height: 0;
                  }

                  50% {
                    opacity: 50%;
                    height: 50%;
                  }

                  100% {
                    opacity: 100%;
                    height: 100%;
                  }
                }
              }

              li {
                padding: 5px;
                font-weight: 400;

                @media screen and (max-width: 524px) {
                  padding: 7.5px 5px;
                  color: #38a1ff;
                  background-color: #f8f8f8;
                  text-align: center;
                }
              }
            }
          }
        }
      }
    }
  }

  .resultados {
    padding: 30px 0px;

    .imagen {
      width: 300px;
      height: 200px;
      border-radius: 10px;
    }

    .row div:nth-child(2) {
      display: flex;
      align-items: center;
    }

    h3 {
      @media screen and (max-width: 524px) {
        font-size: 18px;
        line-height: 25px;
      }
    }

    span {
      @media screen and (max-width: 524px) {
        font-size: 14px;
        line-height: 20px;
        font-weight: regular;
      }
    }

    p {
      @media screen and (max-width: 524px) {
        font-size: 14px;
        line-height: 25px;
        color: #868686;
        font-weight: regular;
        margin-bottom: 6px;
      }
    }
  }
}

// extras
.titulo {
  color: #3d99ff;
  font-size: 40px;
  text-align: center;
  font-weight: bold;

  @media screen and (max-width: 524px) {
    font-size: 25px;
    line-height: 30px;
    margin-top: 65px;
    margin-bottom: 30px;
  }
}
</style>
