<template>
  <div id="Overview">
    <p class="pre-wrap d-block d-sm-none">
      <span :class="{bool: !collapseMore}">{{ routeProps }} <div class="ver-mas" v-if="!collapseMore" @click="VerMas">...Ver más</div></span>
    </p>
    <p class="pre-wrap d-none d-sm-block">
      {{ routeProps }}
    </p>
  </div>
</template>

<script>
export default {
  name: "Overview",
  props: ["routeProps"],
  data() {
    return {
      collapseMore: false
    };
  },
  methods: {
    VerMas() {
      this.collapseMore = !this.collapseMore;
    }
  },
};
</script>

<style lang="scss">
#Overview {
  p {
    color: #868686;
    width: 80%;
    margin: auto;
    padding: 50px 20px;
    overflow: hidden;
    @media screen and (max-width: 524px) {
      font-size: 14px;
      line-height: 28px;
      width: 100%;
      text-align: left;
      padding: 40px;
      height: auto;
    }
  }
  .bool {
    height: 6em;
    display: block;
    position: relative;
    .ver-mas {
      position: absolute;
      right: 0;
      top: 67%;
      font-weight: 500;
      color: #3D99FF;
      background-color: #fff;
      width: 100px;
    }
  }
  .pre-wrap {
    position: relative;
    &::after {
      @media screen and (max-width: 524px) {
        content: '';
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        right: 0;
        height: 40px;
        background-color: #fff;
      }
    }
  }
}
</style>
