/* eslint-disable */
export default {
    namespaced: true,

    state: {
        CheckoutESP: {
            tuorden: "Tu orden",
            producto: "Producto",
            categoria: 'Categoría',
            detalles: 'Detalles',
            precio: "Precio",
            cantidad: "Cantidad",
            total: "Total",
            fecha: "Fecha",
            aplicar: "Aplicar Cupón",
            pasajero: "Información viajer@ titular",
            promociones: "Me gustaría recibir información ocasional sobre promociones",
            selecciona: "Selecciona",
            seleccionapais: "Selecciona tu país de origen",
            nombre: "Nombre",
            apellido: "Apellido",
            email: "Correo Electrónico",
            comentarios: "Comentarios",
            direccion: "Dirección",
            dhotel: "Dirección del Hotel",
            telefono: "Teléfono",
            sanitarias: "“¿Eres vegetarian@ o vegan@?”",
            pais: "País",
            ciudad: "Ciudad",
            hear: "¿Cómo te enteraste de nosotros?",
            infopago: "Información de pago",
            cargado: "Tú dinero no será cargado hasta que la experiencia sea confirmada (en un plazo máximo de 48 horas)",
            mediospago: "Mostrar otros métodos de pago",
            infotitular: "Información del titular",
            postal: "Código Postal",
            infocard: "Información Tarjeta",
            creditcard: "Tarjeta de Crédito",
            venc: "Fecha Vencimiento",
            codecard: "CVV",
            terms1: " He leído los",
            terms2: "términos y condiciones",
            procesar: "Procesar Pago",
            algopaso: "Algo Pasó con tu pago",
            volverpago: "Volver al pago",
            reservap: "Tu reserva está siendo procesada",
            recuerda1: "Recuerda que el dinero será cargado una vez",
            recuerda2: "que la experiencia este confirmada.",
            siguiente: "Siguiente",
            anterior: "Anterior",
            tamanogrupo: "Grupo",
            fechasviaje: "Fechas del viaje",
            crearcuenta: "Crear cuenta",
            detalleviaje: "Detalles del viaje",
            pareja: "Pareja",
            individual: "Solitario",
            familia: "Familia",
            grupo: "Grupo",
            comoviajaras: "¿Cómo viajarás?",
            cuandoviajaras: "¿Cuándo viajarás?",
            fechaexacta: "Tengo mis fechas exactas de viaje.",
            fechaaprox: "Tengo fechas aproximadas",
            nofecha: "Aún no tengo mis fechas",
            describeviaje: "Describe tu viaje",
            especialistalocal: "Para ayudarnos a conectarlo con un especialista local en nuestra red",
            titulo: "Titulo",
            tupresupuesto: "¿Cuál es tu presupuesto?",
            describeloquequieres: "Describe el viaje que deseas",
            Inicio: "Inicio",
            Viajes: "Viajes",
            messalida: "Mes de salida",
            aproxday: "Aprox. días"
        },
        CheckoutENG: {
            tuorden: "Your cart",
            producto: "Experience(s)",
            categoria: 'Category',
            detalles: 'Details',
            precio: "Price",
            cantidad: "Travelers",
            total: "Total",
            fecha: "Date",
            aplicar: "Apply coupon",
            pasajero: "Traveler information",
            promociones: "I would like to receive occasional emails with promotions",
            selecciona: "Select",
            seleccionapais: "Select your country",
            nombre: "Name",
            apellido: "Surname",
            email: "e-mail",
            comentarios: "Comments",
            direccion: "Address",
            dhotel: "Address of your accommodation",
            telefono: "Phone",
            sanitarias: "Are you vegetarian or vegan?",
            pais: "Country",
            ciudad: "City",
            hear: "How did you hear about us?",
            infopago: "Payment information",
            cargado: "You will not be charged until the experience is confirmed (within a maximum period of 48 hours)",
            mediospago: "Show other payment methods",
            infotitular: "Holder information",
            postal: "Zip Code",
            infocard: "Card Information",
            creditcard: "Card number",
            venc: "Expiry date",
            codecard: "Card code (CVC)",
            terms1: " I’ve read and accept the",
            terms2: "terms & conditions",
            procesar: "Place order",
            algopaso: "Something went wrong",
            volverpago: "Back to checkout",
            reservap: "your booking is being processed",
            recuerda1: "Remember that you will not be charged",
            recuerda2: "until the experience is confirmed.",
            siguiente: "Next",
            anterior: "Back",
            tamanogrupo: "Group Size",
            fechasviaje: "Travel dates",
            crearcuenta: "Create account",
            detalleviaje: "Trip details",
            pareja: "Couple",
            individual: "Solo Traveler",
            familia: "Family",
            grupo: "Group",
            comoviajaras: "How will you be traveling?",
            cuandoviajaras: "When will you be traveling?",
            fechaexacta: "I have my exact travel dates",
            fechaaprox: "I have approximate dates",
            nofecha: "I don't have my dates yet",
            describeviaje: "Describe your trip",
            especialistalocal: "To help us match you with a local specialist in our network",
            titulo: "Title",
            tupresupuesto: "¿What is your budget??",
            describeloquequieres: "Describe the trip you want",
            Inicio: "Home",
            Viajes: "Trips",
            messalida: "Month of departure",
            aproxday: "Approx. days"
        }
    },
    getters: {
        tuorden(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.tuorden;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.tuorden;
            }
        },
        producto(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.producto;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.producto;
            }
        },
        precioNom(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.precio;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.precio;
            }
        },
        detallesNom(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.detalles;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.detalles;
            }
        },
        categoriaNom(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.categoria;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.categoria;
            }
        },
        cantidadNom(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.cantidad;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.cantidad;
            }
        },
        totalNom(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.total;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.total;
            }
        },
        fecha(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.fecha;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.fecha;
            }
        },
        aplicar(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.aplicar;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.aplicar;
            }
        },
        pasajero(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.pasajero;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.pasajero;
            }
        },
        nombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.nombre;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.nombre;
            }
        },
        apellido(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.apellido;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.apellido;
            }
        },
        email(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.email;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.email;
            }
        },
        comentarios(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.comentarios;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.comentarios;
            }
        },
        ciudad(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.ciudad;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.ciudad;
            }
        },
        direccion(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.direccion;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.direccion;
            }
        },
        pais(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.pais;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.pais;
            }
        },
        dhotel(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.dhotel;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.dhotel;
            }
        },
        sanitarias(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.sanitarias;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.sanitarias;
            }
        },
        telefono(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.telefono;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.telefono;
            }
        },
        hear(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.hear;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.hear;
            }
        },
        promociones(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.promociones;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.promociones;
            }
        },
        seleccionapais(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.seleccionapais;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.seleccionapais;
            }
        },
        selecciona(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.selecciona;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.selecciona;
            }
        },
        infopago(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.infopago;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.infopago;
            }
        },
        cargado(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.cargado;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.cargado;
            }
        },
        mediospago(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.mediospago;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.mediospago;
            }
        },
        infotitular(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.infotitular;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.infotitular;
            }
        },
        postal(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.postal;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.postal;
            }
        },
        infocard(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.infocard;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.infocard;
            }
        },
        creditcard(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.creditcard;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.creditcard;
            }
        },
        procesar(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.procesar;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.procesar;
            }
        },
        terms1(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.terms1;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.terms1;
            }
        },
        terms2(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.terms2;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.terms2;
            }
        },
        venc(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.venc;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.venc;
            }
        },
        codecard(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.codecard;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.codecard;
            }
        },
        volverpago(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.volverpago;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.volverpago;
            }
        },
        reservap(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.reservap;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.reservap;
            }
        },
        recuerda1(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.recuerda1;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.recuerda1;
            }
        },
        recuerda2(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.recuerda2;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.recuerda2;
            }
        },
        tamanogrupo(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.tamanogrupo;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.tamanogrupo;
            }
        },
        fechasviaje(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.fechasviaje;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.fechasviaje;
            }
        },
        crearcuenta(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.crearcuenta;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.crearcuenta;
            }
        },
        detalleviaje(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.detalleviaje;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.detalleviaje;
            }
        },
        siguiente(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.siguiente;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.siguiente;
            }
        },
        anterior(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.anterior;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.anterior;
            }
        },
        pareja(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.pareja;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.pareja;
            }
        },
        individual(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.individual;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.individual;
            }
        },
        familia(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.familia;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.familia;
            }
        },
        grupo(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.grupo;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.grupo;
            }
        },
        comoviajaras(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.comoviajaras;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.comoviajaras;
            }
        },
        cuandoviajaras(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.cuandoviajaras;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.cuandoviajaras;
            }
        },
        fechaexacta(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.fechaexacta;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.fechaexacta;
            }
        },
        fechaaprox(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.fechaaprox;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.fechaaprox;
            }
        },
        nofecha(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.nofecha;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.nofecha;
            }
        },
        describeviaje(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.describeviaje;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.describeviaje;
            }
        },
        especialistalocal(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.especialistalocal;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.especialistalocal;
            }
        },
        titulo(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.titulo;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.titulo;
            }
        },
        tupresupuesto(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.tupresupuesto;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.tupresupuesto;
            }
        },
        describeloquequieres(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.describeloquequieres;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.describeloquequieres;
            }
        },
        Inicio(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.Inicio;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.Inicio;
            }
        },
        Viajes(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.Viajes;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.Viajes;
            }
        },
        messalida(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.messalida;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.messalida;
            }
        },
        aproxday(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.CheckoutESP.aproxday;
            } else if (rootState.language == "en") {
                return state.CheckoutENG.aproxday;
            }
        },
    },
    mutations: {},
    actions: {}
};