import { db, storage } from "../../firebase.service"

const foreignTrips = db.collection('foreignTrips');

const state = () => ({
  trips: [],
  trip: {
    es: {},
    en: {},
    itineraries: [],
    highlights: [],
    image: '',
    carousel: []
  },
  id: null
});

const getters = {
  getTrips(state, trips) {
    state.trips = trips;
  },
  getTrip(state, trip) {
    state.trip = trip;
  }
};

const mutations = {
  setTrips(state, trips) {
    state.trips = trips;
  },
  setTrip(state, trip) {
    state.trip = trip;
  },
  tripDelete(state, id) {
    const trips = state.trips;
    const trip = trips.find((r) => r.id === id);

    if (trip) {
      state.viajes = state.trips.filter((r) => r.id != id);
    }
  },
  tripEdit(state, payload) {
    const { findTrip, trip } = payload;

    if (findTrip) {
      state.trips.map((r) => {
        if (r.id === findTrip.id) {
          r = findTrip;
        }
        return r;
      });
    }
  },
  resetForm(state) {
    state.trip = {
      es: {},
      en: {},
      itineraries: [],
      highlights: [],
      image: "",
    };
    state.id = null;
  }
};

const actions = {
  async getTrip({ state, commit }, id) {
    //commit('resetForm');
    const trip = foreignTrips.doc(id).get();
    trip
      .then((doc) => {
        state.id = id;
        commit("setTrip", { ...doc.data(), id: id });
      })
      .catch(function (error) {
        console.log("Error getting document:", error);
      });
  },
  async cleanStateTrip({state, commit}) {

    commit('resetForm');
    /* state.trip = {
      es: {},
      en: {},
      itineraries: [],
      highlights: [],
      image: '',
      carousel: []
    };
    state.id = null; */
  },
  async getTrips({ commit }) {
    foreignTrips.onSnapshot((snapshot) => {
      const arr = [];
      snapshot.forEach((e) => {
        arr.push({ ...e.data(), id: e.id });
      });
      commit("setTrips", arr);
    });
  },
  async addTrip({ state, commit }, payload) {
    try {
      await foreignTrips.add(payload);
      return true;
    } catch (error) {
      console.log("Error al agregar el viaje => ", error);
      return false;
    }
  },
  async deleteTrip({ state, commit }, id) {
    const trips = await state.trips;
    const trip = trips.find((r) => r.id === id);

    let images = [];

    if(trip.image.length > 0) {
      images.push(trip.image);
    }

    if (trip.itineraries.lenght > 0) {
      trip.itineraries.map((itinerary) => {
        images.push(itinerary.image);
      });
    }

    if (typeof trip.carousel != 'undefined') {
      if (trip.carousel.lenght > 0) {
        trip.itineraries.map((itinerary) => {
          images.push(itinerary.url);
        });
      }
    }
    
    if(trip.itineraries.lenght > 0) {
      trip.itineraries.map((itinerary) => {
        images.push(itinerary.image)
      })
    }

    try {
      if (trip) {
        const imagesToDelete = images.map((imgUrl) =>
          storage.refFromURL(imgUrl)
        );
        await Promise.all(imagesToDelete.map((imgRef) => imgRef.delete()));

        await foreignTrips.doc(id).delete();
      } 
      console.log("viaje eliminada", trip);
      commit("tripDelete", id);
    } catch (error) {
      console.log(error);
    }
  },
  async reloadTrip({ state, commit }, trip) { 
    const _t = await state.trips;
    const id = state.id;
    const findTrip = _t.find((r) => r.id === id);

    try {
      if (findTrip) {
        await foreignTrips.doc(id).update(trip);
        await commit("tripEdit", { trip, findTrip });
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error al editar el viaje: ", error);
      return false;
    }
  },
  async editTrip({ commit, state }, trip) {
    const _t = await state.trips;
    const id = state.id;
    const findTrip = _t.find((r) => r.id === id);

    try {
      if (findTrip) {
        await foreignTrips.doc(id).update(trip);
        await commit("tripEdit", { trip, findTrip });
        commit("resetForm");
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log("Error al editar el viaje: ", error);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};