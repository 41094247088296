/* eslint-disable */
export default {
  namespaced: true,

  state: {
    NosotrosESP: {
      titulo: "Nosotros",
      p1:
        "Somos puente entre viajeros chilenos y extranjeros e iniciativas locales que ofrezcan experiencias auténticas y sustentables, lejos del turismo masivo.",
      quees: "¿QUÉ ES SURREAL TRAVEL?",
      p2:
        "Somos una plataforma digital de viajes que busca ser un puente entre viajeros e iniciativas locales que ofrezcan experiencias auténticas y sustentables, que permitan a los viajeros conocer Chile desde una perspectiva local.",
      queofrecemos: "¿QUÉ OFRECEMOS?",
      p3:
        "Las mejores experiencias turísticas de cada destino en un solo lugar. Estas pueden ser:",
      iconT1: "Inmersión Cultural",
      iconP1:
        "Tours urbanos o rurales que muestren a los viajeros la cultura local desde una perspectiva genuina.",
      iconT2: "Pueblos originarios",
      iconP2:
        "Experiencias que muestren la hermosa cosmovisión de los pueblos indígenas, sin caer en la folclorización de ellos.",
      iconT3: "Naturaleza",
      iconP3:
        "Experiencias ecoamigables que permitan interactuar con la naturaleza a través de la aventura o un relajo en medio de ella.",
      iconT4: "Gastronomía y Enoturismo",
      iconP4:
        "Experiencias que pongan en valor la rica gastronomía y la extensa y valiosa tradición de vinos chilena.",
      comolohacemos: "¿CÓMO LO HACEMOS?",
      p4:
        "Trabajamos para encontrar las mejores experiencias de viaje, con anfitriones de comunidades y tour operadores locales, que han optado por usar el turismo como una fuente de ingreso responsable, aportando al desarrollo de los destinos y a proteger la biodiversidad de nuestro país. Así ponemos a disposición de los viajeros las experiencias más auténticas a lo largo de Chile en una plataforma única, donde encontrarán una oferta que antes era poco visible.",
      porque: "¿POR QUÉ LO HACEMOS?",
      p5:
        "Estamos convencidos de que el turismo sustentable es una herramienta para proteger la biodiversidad, ayuda a la economía de las comunidades locales y promueve la cultura del territorio. Además, creemos firmemente que los viajes tienen el poder de transformar vidas, fomentan la empatía e inspiran. Tal como nos sucedió a nosotros.",
      quienes: "¿QUIÉNES SOMOS?",
      pablo:
        "Durante 4 años viajando, trabajando y estudiando por distintas partes del mundo, tuve la oportunidad de visitar más de 30 países que me entregaron experiencias y aprendizajes que quiero volcar al turismo nacional. Soy Ingeniero Civil y Máster en Planificación y Gestión de Turismo de la Universitat de Girona (España).",
      carlos:
        "Viajero de toda la vida en búsqueda constante de nuevas aventuras y experiencias. Trabajé cuatro años como Ingeniero Comercial en el área financiera de la banca, la cual dejé para involucrarme en este proyecto con sentido al 100%, aportando desde mi experiencia a esta linda iniciativa que busca contribuir al turismo responsable de nuestro país."
    },
    NosotrosENG: {
      titulo: "About us",
      p1:
        "We are a bridge between Chilean and foreign travelers and local initiatives that offer authentic and sustainable experiences, far from mass tourism.",
      quees: "WHAT IS IT?",
      p2:
        "We are a bridge between local sustainable initiatives and travelers. We offer original and authentic life-lasting memories, as we want to share an alternative Chilean experience to explorers, connecting people and nature.",
      queofrecemos: "WHAT WE OFFER?",
      p3:
        "All our experiences are given by local communities or tour operators that want to share the richness of our nature and culture and, as well as being committed with sustainability. These experiences are mostly:",
      iconT1: "Cultural Inmersion",
      iconP1:
        "Urban or rural tours that show travelers the local culture from a genuine perspective.",
      iconT2: "Indigenous experience",
      iconP2:
        "Experiences that show the beautiful worldview of indigenous peoples, without falling into their folklore.",
      iconT3: "Nature",
      iconP3:
        "Eco-friendly experiences that allow you to interact with nature through adventure or a relaxation in the midst of it.",
      iconT4: "Gastronomy and Wine Tourism",
      iconP4:
        "Experiences that highlight the rich gastronomy and the extensive and valuable Chilean wine tradition.",
      comolohacemos: "HOW DO WE DO IT?",
      p4:
        "We work to find the best travel experiences provide by communities and local tour operators, who have chosen to use tourism as a source of responsible income, contributing to the development of urban and rural destinations and protecting the biodiversity of our country. In this way we will make available to travelers the most authentic experiences throughout Chile on a single platform, where they will find an offer that was previously inconspicuous.",
      porque: "WHY WE DO IT?",
      p5:
        "We are convinced that sustainable tourism is a tool to protect biodiversity, helps the economy of local communities and promotes the culture of the territory. Furthermore, we firmly believe that travel has the power to transform lives, foster empathy and inspire. As it happened to us.",
      quienes: "WHO WE ARE?",
      pablo:
        "For 4 years traveling, working and studying in different parts of the world, I had the opportunity to visit more than 30 countries that gave me experiences and learning that I want to focus on national tourism. I am a Civil Engineer and Master in Tourism Planning and Management from the University of Girona (Spain).",
      carlos:
        "Lifelong traveler in constant search of new adventures and experiences. I worked four years as a Commercial Engineer in the financial area of banking, which I left to get involved in this project with 100% sense, contributing from my experience to this beautiful initiative that seeks to contribute to responsible tourism in our country."
    }
  },
  getters: {
    titulo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.titulo;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.titulo;
      }
    },
    p1(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.p1;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.p1;
      }
    },
    quees(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.quees;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.quees;
      }
    },
    p2(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.p2;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.p2;
      }
    },
    queofrecemos(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.queofrecemos;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.queofrecemos;
      }
    },
    p3(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.p3;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.p3;
      }
    },
    iconT1(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconT1;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconT1;
      }
    },
    iconP1(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconP1;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconP1;
      }
    },
    iconT2(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconT2;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconT2;
      }
    },
    iconP2(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconP2;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconP2;
      }
    },
    iconT3(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconT3;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconT3;
      }
    },
    iconP3(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconP3;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconP3;
      }
    },
    iconT4(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconT4;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconT4;
      }
    },
    iconP4(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.iconP4;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.iconP4;
      }
    },
    comolohacemos(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.comolohacemos;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.comolohacemos;
      }
    },
    p4(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.p4;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.p4;
      }
    },
    porque(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.porque;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.porque;
      }
    },
    p5(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.p5;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.p5;
      }
    },
    quienes(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.quienes;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.quienes;
      }
    },
    pablo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.pablo;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.pablo;
      }
    },
    carlos(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NosotrosESP.carlos;
      } else if (rootState.language == "en") {
        return state.NosotrosENG.carlos;
      }
    }
  },
  mutations: {},
  actions: {}
};
