<template>
<div id="Terminos">
  <div class="banner">
    <div class="contain">
      <h1 class="titulo m-0">{{ titulo }}</h1>
    </div>
  </div>
  <div class="container container-body p-sm-5">
    <p>
      SurrealTravel SpA otorga todos sus servicios a sus usuarios y clientes a través del sitio web
      (http://sureal.travel) de acuerdo con los siguientes términos y condiciones. Nos
      reservamos el derecho de cambiar o modificar estos términos cuando así lo elijamos.
      Cuando modifiquemos los términos, publicaremos la fecha del último cambio en la parte
      superior de la página. Consideramos el uso de este sitio como aceptación de nuestros
      términos y condiciones. Depende de usted suspender el uso del sitio si no está de acuerdo
      con ellos.
      Lo invitamos a leer la política de privacidad, ya que su uso del sitio indica que también
      acepta sus términos. Esto es particularmente cierto si tiene la intención de realizar una
      compra, suscribirse al boletín o proporcionarnos información personal.
    </p>

    <h1 class="azul mb-4">Acceso y uso de nuestro servicio</h1>
    <h2 class="gris">Nuestro servicio</h2>
    <p>
      Surreal Travel vincula a los usuarios con información relacionada con viajes dentro de
      Chile y les permite reservar actividades de forma personal y colectiva a través del sitio web
      de forma en línea. Surreal no gestiona ni opera estas actividades, estrictamente actúa
      como una plataforma a través del cual el cliente puede descubrir actividades operados por
      especialistas turísticos locales y reservarlos en línea.
    </p>
    <h2 class="gris">Registro al comprar</h2>
    <p>
      Ciertos procesos de servicios de Surreal Travel requieren otorgar información personal
      para el registro. Al registrarse, acepta proporcionar información completa sobre usted y/o
      aquellos a quienes representa. El envío de esta información está sujeto a nuestra política
      de privacidad. Quien compra debe ser mayor de edad legal, es decir, tener 18 años o más
      para poder usar los servicios de Surreal Travel.
    </p>
    <h2 class="gris">Cambios al servicio</h2>
    <p>
      Surreal Travel se reserva el derecho de cambiar, modificar, eliminar, temporal o
      permanentemente la actividad (o cualquier parte del mismo) sin previo aviso, salvo que la
      actividad haya sido contratada y la actividad requiera alguna modificación, esta será
      informada oportunamente al cliente.
    </p>
    <h2 class="gris">Servicio móvil</h2>
    <p>
      Los Servicios de Surreal Travel están disponibles a través de un dispositivo móvil. En un
      dispositivo móvil, puede navegar a través de la página web, enviar contenido e
      información personal, registrarse en los servicios y realizar compras. Cuando accede a
      nuestros Servicios a través de su dispositivo móvil, pueden aplicarse los cargos por servicio
      de su proveedor, las tarifas de datos y otras tarifas. No somos responsables de estos
      honorarios. Ciertas características de nuestro sitio pueden estar prohibidas, alteradas o no
      disponibles a través de su proveedor. No todas las funciones funcionan en todos los
      dispositivos móviles.
    </p>
    <h1 class="azul mb-4">Condiciones de Uso</h1>

    <br />
    <h2 class="gris">Tarifa</h2>

    <br />
    <p>
      Al momento de realizar una compra en Surreal Travel, verificaremos su
      compra, pero la compra solo se completa cuando su método de pago se
      aprueba correctamente. Nos reservamos el derecho de cobrar tarifas
      adicionales. Cuando otorga información de pago para realizar una compra,
      acepta pagar todas las tarifas de acuerdo con estos términos. Cuando
      otorga voluntariamente información de pago y / o personal a Surreal
      Travel a través del uso de nuestros servicios, reconoce que representa
      con precisión y está autorizado a utilizar el instrumento de pago.
      Además, acepta informar a Surreal Travel cuando cambie la información de
      la cuenta (como la dirección de facturación, la información de
      contacto).
      <br />Debe reclamar cualquier cargo equivocado en Surreal Travel
      dentro de los 60 días, como máximo, posteriores a su compra. Si
      cancelamos o suspendemos su cuenta por incumplimiento de los términos y
      condiciones, no tiene derecho a un reembolso de ningún tipo.
    </p>
    <br />
    <h2 class="gris">Cancelaciones</h2>
    <br />
    <p>
      Todas las reservas de actividades tienen derecho a cancelación, a menos que se indique lo
      contrario en la pestaña de información importante de la actividad turística. Si se solicita
      reembolso con un máximo de 3 días (72 horas) previos al inicio de la actividad se
      devolverá la totalidad del pago, 2 días (48 horas) previos al inicio de la actividad se
      reembolsará el 50% del monto pagado. En el caso de que el aviso de cancelación sea en
      menos de 48 horas no se devolverá el dinero. Para todas las devoluciones se descontarán
      los gastos de transacción.
      <br />Todas las cancelaciones deben ser comunicadas a través de reservas@surreal.travel
    </p>
    <br />
    <h2 class="gris">Reprogramaciones</h2>
    <br />
    <p>
      Todas las reservas de actividades tienen derecho a reprogramación, a menos que se
      indique lo contrario en la pestaña de información importante de la actividad turística. Se
      puede solicitar una reprogramación con 2 días (48 horas) de anticipación como máximo.
      Todas las reprogramaciones deben ser comunicadas a través de reservas@surreal.travel
    </p>
    <h2 class="gris">Cancelación de la actividad por fuerza mayor</h2>
    <br />
    <p>
      Si por condiciones climáticas u otra fuerza mayor comprobable que impida el desarrollo de
      la actividad se respetaran las políticas internas del operador turístico.
      En caso de reprogramar la actividad o devolución de dinero esto será gestionado
      internamente con el tour operador para hacer efectiva la resolución.
    </p>

    <h2 class="gris">Indemnización y liberación</h2>
    <br />
    <p>
      Usted acepta indemnizar, liberar y eximir a Surreal Travel y sus afiliados y sus empleados,
      funcionarios, directores, socios y agentes de cualquier daño, pérdida y gasto, incluidos los
      honorarios razonables de abogados, reclamos, derechos y acciones de cualquier tipo.
      Lesiones (incluida la muerte) derivadas del uso del Servicio, cualquier Contenido del
      usuario, su violación de estos Términos y condiciones o su violación de los derechos de
      otro.
    </p>

    <h2 class="gris">Contacto</h2>
    <br />
    <p>
      En caso de preguntas, comentarios, sugerencias u otro, puedes contactarnos a través de
      contacto@surreal.travel. Con gusto atenderemos tu requerimiento :)
    </p>
  </div>
</div>
</template>

<style lang="scss">
#Terminos {
  .banner {
    background: #3d99ff;
    padding: 30px;

    @media screen and (max-width: 524px) {
      padding: 20px 60px;
    }

    .titulo {
      color: white;
      font-size: 30px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 25px;
      }
    }
  }

  .container-body {
    @media screen and (max-width: 524px) {
      padding: 30px 36px;
    }
  }

  .azul {
    color: #3d99ff;
    font-size: 24px;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 22px;
    }
  }

  .gris {
    color: #868686;
    font-size: 20px;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 18px;
    }
  }

  p {
    color: #868686;
    font-size: 16px;
    line-height: 35px;

    @media screen and (max-width: 524px) {
      font-size: 14px;
      line-height: 28px;
    }
  }
}
</style>

<script>
/* eslint-disable */
import {
  mapGetters
} from "vuex";

export default {
  name: "TerminosYCondiciones",
  metaInfo() {
    return {
      title: 'Términos y condiciones',
    };
  },
  computed: {
    ...mapGetters("Terminos", ["titulo"]),
  },
};
</script>
