import router from "../../router/index";
import { db, storage } from "../../firebase.service";
import storageHandler from "../utilities/storageHandler";
import storageURLs from "../utilities/downloadURLItinerario";

const viajes = db.collection("viajes");

const state = () => ({
    id: null,
    titulo: null,
    resumen: null,
    viajes: [],
    itinerarioDiaSaved: [],
    itinerariosDias: [],
    carouselImgUrl: null,
    carouselImg: null,
    destacado: [],
    viajeImgsToSave: {
        carouselImg: null,
        carouselImgUrl: null
    },
    ingles: {
        titulo: null,
        resumen: null,
        destacado: [],
        itinerariosDias:[],
        experienciaBorrador: {
            destacado: [],
        },
    },
    experienciaBorrador: {
        destacado: [],
    },
});

const getters = {
    viaje(state) {
        return state;
    },
    viajeImgsToSave(state) {
        return state.viajeImgsToSave;
    },
    setViajes(state, payload) {
        state.viajes = payload;
    },
    itinerariosDias(state) {
        return state.itinerariosDias;
    },
    experienciaBorrador(state) {
        return state.experienciaBorrador;
    },
};

const mutations = {
    submitItem(state, payload) {
        console.log('PAYLOAD:', payload);
        const { level, name, value, language } = payload;
        if (language === "en") {
          if (payload.level) {
            state.ingles.experienciaBorrador[level][name].push(value);
            return;
          }
          state.ingles.experienciaBorrador[name].push(value);
          return;
        }
    
        if (payload.level) {
          state.experienciaBorrador[level][name].push(value);
          return;
        }
        
        state.experienciaBorrador[name].push(value);
    },
    borrarItem(state, payload) {
        const check = confirm("Esta seguro de borrar el elemento?");
        if (!check) {
          return;
        }
        // En caso de ser el lenguaje ingles
        if (payload.language === "en") {
          if (payload.borrador) {
            if (payload.level) {
              state.ingles.experienciaBorrador[payload.level][payload.name].splice(
                payload.index,
                1
              );
              return;
            }
            state.ingles.experienciaBorrador[payload.name].splice(payload.index, 1);
            return;
          }
          if (payload.level) {
            state.ingles[payload.level][payload.name].splice(payload.index, 1);
            return;
          }
          state.ingles[payload.name].splice(payload.index, 1);
    
          return;
        }
        // En caso de ser el lenguaje castellano
        if (payload.borrador) {
          if (payload.level) {
            state.experienciaBorrador[payload.level][payload.name].splice(
              payload.index,
              1
            );
            return;
          }
          state.experienciaBorrador[payload.name].splice(payload.index, 1);
          return;
        }
        if (payload.level) {
          state[payload.level][payload.name].splice(payload.index, 1);
          return;
        }
        state[payload.name].splice(payload.index, 1);
    },
    setViajeById(state, payload) {
        const { id, viajes } = payload;
        state.id = id;
        // Actualizar si hay una id de viaje en la ruta
        if (id && viajes.length > 0 && !payload.delete) {
            const viaje = viajes.find(viaje => viaje.id === id);
            if (viajes.length > 0 && !viaje) {
                alert("La viaje no existe dentro de la coleccion de viajes");
                router.push({ path: "/admin" });
            }
            if (viaje) {
                const {
                    titulo,
                    carouselImgUrl,
                    carouselImg,
                    resumen,
                    ItinerarioDia,
                    destacado,
                } = viaje;
                state.titulo = titulo;
                state.carouselImgUrl = carouselImgUrl;
                state.carouselImg = carouselImg;
                state.resumen = resumen;
                state.itinerariosDias = ItinerarioDia;
                state.destacado = destacado;
                // Reset de contenido en ingles
                state.ingles.titulo = viaje.ingles.titulo;
                state.ingles.resumen = viaje.ingles.resumen;
            }
        }
    },

    setViajes(state, payload) {
        state.viajes = payload;
    },
    resetViaje(state, payload) {
        const { level } = payload;

        // Reset para el estado de Vuex
        state[`tripId`] = null;
        state.viajeImgsToSave.carouselImg = null;
        state.viajeImgsToSave.carouselImgUrl = null;
        state.id = null;
        state.titulo = null;
        state.carouselImgUrl = null;
        state.resumen = null;
        state.destacado = [];
        state.experienciaBorrador.destacado = [];

        // Resets para idioma ingles
        state.ingles.titulo = null;
        state.ingles.resumen = null;
        state.ingles.destacado = [];

        if (state.itinerarioDiaSaved) {
            state.itinerarioDiaSaved = [];
        }

    },
    resetItinerarioDiaBorrador(state) {
        // Reset para el estado de las imagenes y consejos que quieran guardarse
        state.viajeImgsToSave.carouselImg = null;
        state.viajeImgsToSave.carouselImgUrl = null;
        state.itinerariosDias = [];
        state.destacado = [];
    },
    addToItinerariosDias(state, payload) {
        state.itinerariosDias.push(payload);
    },
    deleteFromItinerariosDias(state, payload) {
        state.itinerariosDias.splice(payload, 1);
    },
    setViajeImg(state, payload) {
        if (payload.type === "carousel") {
            state.viajeImgsToSave.carouselImg = payload.imgFile;
            state.viajeImgsToSave.carouselImgUrl = payload.imgUrl;
        }
    },
};

const actions = {
    borrarItem({ rootState, commit }, payload) {
        payload.language = rootState.language;
        commit("borrarItem", payload);
    },
    setViajeId({ commit, rootState }, payload) {
        const id = payload;
        commit("setViajeById", {
            id,
            viajes: rootState.trip.viajes,
            delete: rootState.delete
        });
    },
    submitItem({ commit, rootState }, payload) {
        payload.language = rootState.language;
        commit("submitItem", payload);
    },
    async deleteFromItinerarioSaved({ state }, payload) {
        const imgUrl =
            state[payload.level].itinerarioDiaSaved[payload.index].imgUrl;
        // Funcion para eliminar imagenes previamente almacenadas en storage
        if (!confirm("Estas seguro que deseas eliminar esta imagen?")) {
            return;
        }

        const imgRef = storage.refFromURL(imgUrl);

        try {
            await imgRef.delete();
            const newArray = state[payload.level].itinerarioDiaSaved.filter(
                (contenido, index) => index !== payload.index
            );
            await reference
                .doc(state[payload.level].id)
                .update({ itinerarioDiaSaved: newArray });
            alert("Contenido eliminado exitosamente");
        } catch (error) {
            console.log(
                "Error inesperado en borrar contenido de cultura y gastronomia: ",
                error
            );
        }
    },
    addViajeImg({ commit }, payload) {
        commit("setViajeImg", payload);
    },
    async getViajes({ commit }) {
        viajes.onSnapshot(snapshot => {
            const dataArray = [];
            snapshot.forEach(doc => {
                dataArray.push({...doc.data(), id: doc.id });
            });
            commit("setViajes", dataArray);
        });
    },
    async deleteViaje({ state, rootState, commit }, payload) {
        const confirmar = confirm(
            "Esta seguro de eliminar esta viaje y todos sus destinos ?"
        );

        if (!confirmar) {
            return;
        }

        const viajeElement = rootState.viajes.find(viaje => viaje.id === payload.id);
        if (!viajeElement) {
            alert("La viaje que desea eliminar no existe");
            return;
        }
        const images = [];

        if (viajeElement.carouselImgUrl) {
            images.push(viajeElement.carouselImgUrl);
        }

        if (
            viajeElement.itinerariosDias &&
            viajeElement.itinerariosDias.length > 0
        ) {
            const itinerariosImages = viajeElement.itinerariosDias.map(
                contenido => contenido.imgUrl
            );
            images.push(...itinerariosImages);
        }

        try {
            if (images && images.length > 0) {
                const imagesToDelete = images.map(imgUrl => storage.refFromURL(imgUrl));
                await Promise.all(imagesToDelete.map(imgRef => imgRef.delete()));
            }

            await viajes.doc(viajeElement.id).delete();
            router.push({ path: "/admin" });
            commit("resetBorrador", null, { root: true });
            alert("Viaje eliminada exitosamente");
        } catch (error) {
            commit("resetBorrador", null, { root: true });
            console.log("Error al borrar viaje: ", error);
        }
    },
    resetViaje({ commit }, payload) {
        commit("resetViaje", payload);
    },
    async updateViaje({ state, commit, rootState }, payload) {
        // Funcion que sirve tanto para crear como para actualizar viajes
        const viaje = rootState.trip.viajes.find(viaje => viaje.id === state.id);
        if (payload === "update" && !viaje) {
            alert("La viaje que intenta actualizar no esta registrada");
            return;
        }
        // Variable inicial para almacenar el id del documento
        let key;

        // Deconstructing del estado para preparar el update/create
        const {
            titulo,
            resumen,
            carouselImgUrl,
            destacado
        } = state;

        // Preparacion de create
        const updates = {
            titulo,
            resumen,
            carouselImgUrl,
            destacado: [...destacado, ...state.experienciaBorrador.destacado],
            ingles: {
                titulo: state.ingles.titulo,
                resumen: state.ingles.resumen,
                destacado: [
                    ...state.ingles.destacado,
                    ...state.ingles.experienciaBorrador.destacado
                ],
            }
        };

        // En caso de crear una nueva viaje primero se crea para tener su id
        // y asociar las imagenes con su respectivo id de viaje en storage
        if (payload === "create") {
            const createTask = await viajes.add(updates);
            key = createTask.id;
        } else if (payload === "update") {
            key = state.id;
        }
        const storageUpdateConfig = storageHandler(storage, rootState, key, "trip");
        const storageDownloadURLs = await storageURLs(
            storageUpdateConfig,
            state.itinerariosDias
        );
        const { storageUpdateAvailable, storageUpdates } = storageDownloadURLs;

        if (storageUpdateAvailable) {
            if (payload === "update") {
                await viajes.doc(key).update(updates);
            }
            await viajes.doc(key).update(storageUpdates);
            alert(`Operacion en viaje ${state.titulo} realizada exitosamente`);
            commit("resetBorrador");
            router.push({ path: "/admin" });
        } else {
            if (payload === "update") {
                await viajes.doc(key).update(updates);
            }
            alert(`Operacion en viaje ${state.titulo} realizada exitosamente`);
            commit("resetBorrador");
            router.push({ path: "/admin" });
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};