import { db, storage } from "../../firebase.service";
import Vue from "vue";
import emailjs from "emailjs-com";
import moment from 'moment';

// Data para EmailJS: (Agregar a .env)
const serviceID = "default_service";
const userID = "user_phHSRepn7EuELwmpwDkNi";

const reservasInmediatasCollection = db.collection("reservas");
const reservasPorConfirmarCollection = db.collection("reservasPorConfirmar");

const state = {
  reservaciones: [],
  reservacionesPorConfirmar: []
};

const getters = {};

const mutations = {
  setReservas(state, payload) {
    state.reservaciones = payload;
  },
  setReservasPorConfirmar(state, payload) {
    state.reservacionesPorConfirmar = payload;
  },
  AceptarReserva(state, payload) {
    const reservas = state.reservaciones;
    const id = payload;
    const finder = reservas.find(el => el.id === id);
    finder.estado = "Aceptado";
  },
  EsperandoPagoReservaPorConfirmar(state, payload) {
    const reservas = state.reservacionesPorConfirmar;
    const id = payload;
    const finder = reservas.find(el => el.id === id);
    finder.estado = "Pendiente de Pago";
  },
  EsperandoPrimerPagoReservaPorConfirmar(state, payload) {
    const reservas = state.reservacionesPorConfirmar;
    const id = payload;
    const finder = reservas.find(el => el.id === id);
    finder.estado = "Pendiente de primer pago";
  },
  EsperandoSegundoPagoReservaPorConfirmar(state, payload) {
    const reservas = state.reservacionesPorConfirmar;
    const id = payload;
    const finder = reservas.find(el => el.id === id);
    finder.estado = "Pendiente de segundo pago";
  },
  ReservaPagada(state, payload) {
    const reservas = state.reservacionesPorConfirmar;
    const id = payload;
    const finder = reservas.find(el => el.id === id);
    finder.estado = "Pagado";
  },
  RechazarReserva(state, payload) {
    const reservas = state.reservaciones;
    const id = payload;
    const finder = reservas.find(el => el.id === id);
    finder.estado = "Rechazado";
  },
  setTokenRePorConfirmar(state, payload) {
    const rePorConfirmar = state.reservacionesPorConfirmar;
    const reserva = payload;

    if (!rePorConfirmar || !reserva || !reserva.id || !reserva.token) return;

    const id = payload.id;
    const token = payload.token;
    const tokensPago = payload.tokensPago;

    const finder = rePorConfirmar.find(el => el.id === id);
    finder.token = token;
    finder.tokensPago = tokensPago;
  },
  reservaNotificada(state, id) {
    const reservas = state.reservacionesPorConfirmar;
    const finder = reservas.find(el => el.id === id);
    finder.notificadoSegundoPago = true;
  },
  notificadaFeedback(state, id) {
    const reservas = state.reservacionesPorConfirmar;
    const finder = reservas.find(el => el.id === id);
    if (finder) {
      finder.notificadoFeedback = true;
    } else {
      const reservasInmediatas = state.reservaciones;
      const finder = reservasInmediatas.find(el => el.id === id);
      finder.notificadoFeedback = true;
    }
  },
  ReservaEliminada(state, id) {
    const reservasInmediatas = state.reservaciones;
    const esInmediata = reservasInmediatas.find(r => r.id === id);

    if (esInmediata) {
      state.reservaciones = state.reservaciones.filter(r => r.id != id)
    } else state.reservacionesPorConfirmar = state.reservacionesPorConfirmar.filter(r => r.id != id)
  },
  NuevaReserva(state, reserva) {
    state.reservacionesPorConfirmar.push(reserva)
  },
  ReservaEditada(state, payload) {
    const { reserva, esInmediata } = payload;

    if (esInmediata) {
      state.reservaciones.map(r => {
        if (r.id === reserva.id) {
          r = reserva
        }
        return r
      })

    } else {
      state.reservacionesPorConfirmar.map(r => {
        if (r.id === reserva.id) {
          r = reserva
        }
        return r
      })
    }
  }
};

const actions = {
  async getReservas({ commit }) {
    // commit("setAnimation", null, { root: true });

    // const queryReservas = await db.collection("reservas").get();
    reservasInmediatasCollection.onSnapshot(snapshot => {
      const dataArray = [];
      snapshot.forEach(doc => {
        dataArray.push({ ...doc.data(), id: doc.id });
      });
      commit("setReservas", dataArray);
    });

    // const queryReservasPorConfirmar = await db.collection("reservasPorConfirmar").get();
    reservasPorConfirmarCollection.onSnapshot(snapshot => {
      const dataArray = [];
      snapshot.forEach(doc => {
        dataArray.push({ ...doc.data(), id: doc.id });
      });
      commit("setReservasPorConfirmar", dataArray);
    });
  },
  async eliminarReservacion({ state, commit }, id) {
    const reservasInmediatas = await state.reservaciones;
    const esInmediata = reservasInmediatas.find(r => r.id === id);

    try {
      if (esInmediata) await reservasInmediatasCollection.doc(id).delete();
      else await reservasPorConfirmarCollection.doc(id).delete();

      console.log('reserva eliminada', esInmediata)
      commit('ReservaEliminada', id)
    } catch (error) {
      console.log(error)
    }
  },
  async nuevaReservaPersonalizada({ commit }, reserva) {
    try {
      let subirReserva = await reservasPorConfirmarCollection.add(reserva);
      const idReservaNueva = subirReserva.id
      reserva.id = idReservaNueva
      await commit('NuevaReserva', reserva)

      return true
    } catch (error) {
      console.log('Error al crear reserva personalizada: ', error);
      return false
    }
  },
  async editarReserva({ commit, state }, reserva) {
    const reservasInmediatas = await state.reservaciones;
    const id = reserva.id;
    const esInmediata = reservasInmediatas.find(r => r.id === id);

    try {
      if (esInmediata) await reservasInmediatasCollection.doc(id).update(reserva);
      else await reservasPorConfirmarCollection.doc(id).update(reserva);

      await commit('ReservaEditada', { reserva, esInmediata });
      return true;
    } catch (error) {
      console.log('Error al editar reserva: ', error)
      return false;
    }
  },
  async solicitarReserva({ commit }, payload) {
    if (!payload) return;

    const idUnico = payload.idUnico;
    const nom = payload.nombre;
    const nombre = payload.nombre + " " + payload.apellido;
    const correo = payload.correo;
    const procedencia = payload.ciudad + ", " + payload.pais;
    const telefono = payload.telefono;
    const viajeros = payload.viajeros;
    const total = payload.total;
    const fecha = payload.fecha;
    const fechaFin = payload.fechaFin;
    const fechaActual = payload.fechaActual;
    const idioma = payload.idioma;
    const horaInicio = payload.horaInicio;
    const horaFin = payload.horaFin;
    const experiencia = payload.experiencia;
    const experienciaId = payload.experienciaId;
    const comentarios = payload.comentarios;
    const vegano = payload.vegano;
    const referencia = payload.referencia;
    const promociones = payload.promociones;
    const token = payload.token;
    const estado = "Pendiente";
    const permiteAbono = payload.permiteAbono;
    const abono = payload.abono;
    const reservaInmediata = payload.reservaInmediata;
    const temporadas = payload.temporadas;
    const detalleViajeros = payload.detalleViajeros;


    const data = {
      idUnico,
      nom,
      nombre,
      correo,
      procedencia,
      telefono,
      viajeros,
      total,
      fecha,
      fechaFin,
      fechaActual,
      comentarios,
      vegano,
      referencia,
      promociones,
      idioma,
      horaInicio,
      horaFin,
      experiencia,
      experienciaId,
      estado,
      token,
      permiteAbono,
      abono,
      temporadas,
      detalleViajeros,

    };

    
    const detalleViajerosAdultosString = [`${detalleViajeros.adultos.cantidad} adulto`]
    const detalleViajerosNiñosString = detalleViajeros.niños ? detalleViajeros.niños.map(n => `${n.cantidad} ${n.nombre}`) : []
    const detalleViajerosString = [...detalleViajerosAdultosString , ...detalleViajerosNiñosString].toLocaleString();


    try {
      reservaInmediata
        ? await db.collection("reservas").add(data)
        : await db.collection("reservasPorConfirmar").add(data);
    } catch (error) {
      console.log("Error en la carga de Reservación en Firestore", error);
    }

    // Correo de Reserva Por Confirmar está siendo Procesada.
    const tempIDInmediata = "reserva_procesada_inmedi";

    // Correo de Reserva Por Confirmar está siendo Procesada.
    const tempIDPorProcesar = "temp_reserva_procesada";
    const templateParams = {
      user_name: nom,
      user_fullname: nombre,
      user_mail: correo,
      user_location: procedencia,
      user_phone: telefono,
      experience_name: experiencia,
      experience_date: fecha,
      experience_endDate: fechaFin,
      experience_startHour: horaInicio,
      experience_endHour: horaFin,
      experience_lang: idioma,
      reservation_group: viajeros,
      reservation_detailsgroup: detalleViajerosString,
      reservation_total: total,
      reservation_ID: idUnico,
      reservation_comments: comentarios,
      reservation_vegan: vegano
    };
    // Data para Email a Surreal de Notificación:
    const tempIDPorProcesar2 = "temp_res_conf_surreal";
    const templateParams2 = {
      user_fullname: nombre,
      user_mail: correo,
      experience_name: experiencia,
      experience_date: fecha,
      reservation_group: viajeros,
      reservation_detailsgroup: detalleViajerosString,
      reservation_total: total,
      reservation_ID: idUnico,
      fechaActual_reserva: fechaActual
    };

    try {
      !reservaInmediata
        ? (await emailjs.send(
          serviceID,
          tempIDPorProcesar,
          templateParams,
          userID
        ))
        &&
        (await emailjs.send(
          serviceID,
          tempIDPorProcesar2,
          templateParams2,
          userID
        ))
        : false;
    } catch (error) {
      console.log("Error en envío de correo de Reserva a Procesar:", error);
    }
  },
  async aceptarReserva({ commit, state }, payload) {
    const id = payload;
    // Cambio en Firestore:
    try {
      await db
        .collection("reservas")
        .doc(id)
        .update({
          estado: "Aceptado"
        });
    } catch (error) {
      console.log("El error en Actualizar estado Aceptado es:", error);
    }

    // Cambio en el estado local:
    commit("AceptarReserva", id);

    // Correo de Aceptación.
    const reservas = state.reservaciones;
    const finder = await reservas.find(el => el.id === id);

    const templateID = "temp_reserva_confirmada";
    const templateParams = {
      user_name: finder.nom,
      user_fullname: finder.nombre,
      user_mail: finder.correo,
      user_location: finder.procedencia,
      user_phone: finder.telefono,
      experience_name: finder.experiencia,
      experience_date: finder.fecha,
      experience_startHour: finder.horaInicio,
      experience_endHour: finder.horaFin,
      experience_lang: finder.idioma,
      reservation_group: finder.viajeros,
      reservation_total: finder.total,
      reservation_ID: finder.idUnico,
      reservation_comments: finder.comentarios,
      reservation_vegan: finder.vegano,
      voucherPDF
    };

    try {
      await emailjs.send(serviceID, templateID, templateParams, userID);
    } catch (error) {
      console.log("Error en envío de correo de Aceptación:", error);
    }
  },
  async aceptarReservaPorConfirmar({ commit, state }, payload) {
    const reserva = payload;
    const id = reserva.id;
    // Cambio en Firestore:
    try {
      if (reserva.permiteAbono) {
        reserva.estado === "Pendiente"
          ? await db
            .collection("reservasPorConfirmar")
            .doc(id)
            .update({
              estado: "Pendiente de primer pago"
            })
          : await db
            .collection("reservasPorConfirmar")
            .doc(id)
            .update({
              estado: "Pendiente de segundo pago"
            });
      } else {
        await db
          .collection("reservasPorConfirmar")
          .doc(id)
          .update({
            estado: "Pendiente de Pago"
          });
      }
    } catch (error) {
      console.log("El error en Actualizar estado Aceptado es:", error);
    }

    // Cambio en el estado local:
    !reserva.permiteAbono
      ? commit("EsperandoPagoReservaPorConfirmar", id)
      : reserva.estado === 'Pendiente'
        ? commit("EsperandoPrimerPagoReservaPorConfirmar", id)
        : commit("EsperandoSegundoPagoReservaPorConfirmar", id)

    // Correo de Aceptación.
    const reservas = state.reservacionesPorConfirmar;
    const finder = await reservas.find(el => el.id === id);
    const linkPago = "https://surreal.travel/checkout2/" + finder.idUnico;

    try {
      //Permite abono
      if (finder.permiteAbono) {
        console.log('Estado: ', finder.estado)
        //Primer pago
        if (finder.estado === "Pendiente de primer pago") {
          const templateID = "reserva_primer_pago";
          const templateParams = {
            user_name: finder.nom,
            user_fullname: finder.nombre,
            user_mail: finder.correo,
            user_location: finder.procedencia,
            user_phone: finder.telefono,
            experience_name: finder.experiencia,
            experience_date: finder.fecha,
            experience_startHour: finder.horaInicio,
            experience_endHour: finder.horaFin,
            experience_lang: finder.idioma,
            reservation_group: finder.viajeros,
            reservation_percentage: finder.abono.porcentajeAbono,
            reservation_first_payment: finder.abono.primeraCuota,
            reservation_second_payment: finder.abono.segundaCuota,
            reservation_total: finder.total,
            reservation_total: finder.total,
            reservation_ID: finder.idUnico,
            reservation_comments: finder.comentarios,
            reservation_vegan: finder.vegano,
            reservation_link: linkPago
          };
          await emailjs.send(serviceID, templateID, templateParams, userID);
        }
        // segundo pago
        else if (finder.estado === 'Pendiente de segundo pago') {
          const templateID = "reserva_segundo_pago";
          const templateParams = {
            user_name: finder.nom,
            user_fullname: finder.nombre,
            user_mail: finder.correo,
            user_location: finder.procedencia,
            user_phone: finder.telefono,
            experience_name: finder.experiencia,
            experience_date: finder.fecha,
            experience_startHour: finder.horaInicio,
            experience_endHour: finder.horaFin,
            experience_lang: finder.idioma,
            reservation_group: finder.viajeros,
            reservation_percentage: finder.abono.porcentajeAbono,
            reservation_first_payment: finder.abono.primeraCuota,
            reservation_second_payment: finder.abono.segundaCuota,
            reservation_total: finder.total,
            reservation_total: finder.total,
            reservation_ID: finder.idUnico,
            reservation_comments: finder.comentarios,
            reservation_vegan: finder.vegano,
            reservation_link: linkPago
          };
          await emailjs.send(serviceID, templateID, templateParams, userID);
        }
      }
      //No permite abono
      else {
        const templateID = "reserva_lista_pago";
        const templateParams = {
          user_name: finder.nom,
          user_fullname: finder.nombre,
          user_mail: finder.correo,
          user_location: finder.procedencia,
          user_phone: finder.telefono,
          experience_name: finder.experiencia,
          experience_date: finder.fecha,
          experience_startHour: finder.horaInicio,
          experience_endHour: finder.horaFin,
          experience_lang: finder.idioma,
          reservation_group: finder.viajeros,
          reservation_total: finder.total,
          reservation_ID: finder.idUnico,
          reservation_comments: finder.comentarios,
          reservation_vegan: finder.vegano,
          reservation_link: linkPago
        };
        await emailjs.send(serviceID, templateID, templateParams, userID);
      }
    } catch (error) {
      console.log("Error en envío de correo de Aceptación:", error);
    }
  },
  async rechazarReserva({ commit, state }, payload) {
    const id = payload;
    // Cambio en Firestore:
    try {
      await db
        .collection("reservas")
        .doc(id)
        .update({
          estado: "Rechazado"
        });
    } catch (error) {
      console.log("El error en Actualizar estado Rechazado es:", error);
    }

    // Cambio en el estado local:
    commit("RechazarReserva", id);

    // Correo de Rechazo.
    const reservas = state.reservaciones;
    const finder = await reservas.find(el => el.id === id);

    const templateID = "temp_reserva_no_disp";
    const templateParams = {
      user_name: finder.nom,
      user_mail: finder.correo
    };

    try {
      await emailjs.send(serviceID, templateID, templateParams, userID);
    } catch (error) {
      console.log("Error en envío de correo de Rechazo:", error);
    }
  },
  async rechazarReservaPorConfirmar({ commit, state }, payload) {
    const id = payload;
    // Cambio en Firestore:
    try {
      await db
        .collection("reservasPorConfirmar")
        .doc(id)
        .update({
          estado: "Rechazado"
        });
    } catch (error) {
      console.log("El error en Actualizar estado Rechazado es:", error);
    }

    // Cambio en el estado local:
    commit("RechazarReserva", id);

    // Correo de Rechazo.
    const reservas = state.reservaciones;
    const finder = await reservas.find(el => el.id === id);

    const templateID = "temp_reserva_no_disp";
    const templateParams = {
      user_name: finder.nom,
      user_mail: finder.correo
    };

    try {
      await emailjs.send(serviceID, templateID, templateParams, userID);
    } catch (error) {
      console.log("Error en envío de correo de Rechazo:", error);
    }
  },
  async tokenRePorConfirmar({ commit }, { id, token, tokensPago, reserva }) {
    console.log({ id, token, tokensPago, reserva });
    // Actualizar token en Firebase
    if (!id || !token || !reserva) return false;

    let tokensPagoActualizado = [...tokensPago, token]


    const tiempoUltimoToken = moment().format();

    try {
      await db
        .collection("reservasPorConfirmar")
        .doc(id)
        .update({
          token,
          tokensPago: tokensPagoActualizado,
          tiempoUltimoToken
        });
    } catch (error) {
      console.log("El error en Actualizar estado Aceptado es:", error);
    }
    // Actualizar token en Vuex
    await commit("setTokenRePorConfirmar", reserva);
  },
  async enviarRecordatorioSegundoPago({ state, commit }) {
    const reservasPorConfirmar = await state.reservacionesPorConfirmar;
    if (reservasPorConfirmar === []) return;
    const reservasPendientesDeSegundoPagoNoNotificadas = reservasPorConfirmar.filter(res => res.estado === 'Pendiente de segundo pago' && !res.notificadoSegundoPago);

    reservasPendientesDeSegundoPagoNoNotificadas.forEach(async r => {
      const diasPrevios = 3;
      const fechaExperiencia = moment(Vue.filter("fechaToISOString")(r.fecha));
      const fechaHoyMasDiasPrevios = moment().add(diasPrevios, 'd');

      if (fechaHoyMasDiasPrevios.isSameOrAfter(fechaExperiencia) && moment().isBefore(fechaExperiencia)) {
        // Correo
        try {
          const linkPago = "https://surreal.travel/checkout2/" + r.idUnico;
          const templateID = "recordatorio_segundopago";
          const templateParams = {
            user_name: r.nom,
            user_fullname: r.nombre,
            user_mail: r.correo,
            user_location: r.procedencia,
            user_phone: r.telefono,
            experience_name: r.experiencia,
            experience_date: r.fecha,
            experience_startHour: r.horaInicio,
            experience_endHour: r.horaFin,
            experience_lang: r.idioma,
            reservation_group: r.viajeros,
            reservation_first_payment: r.abono.primeraCuota,
            reservation_second_payment: r.abono.segundaCuota,
            reservation_total: r.total,
            reservation_ID: r.idUnico,
            reservation_comments: r.comentarios,
            reservation_vegan: r.vegano,
            reservation_link: linkPago
          };
          await emailjs.send(serviceID, templateID, templateParams, userID);
        } catch (error) {
          console.log('Error al enviar correo electrónico de notificación de segundo pago: ', error);
        }

        // NotificadoSegundoPago -firebase
        try {
          await db
            .collection("reservasPorConfirmar")
            .doc(r.id)
            .update({
              notificadoSegundoPago: true
            });


          commit('reservaNotificada', r.id)
        } catch (error) {
          console.log("Error al marcar reserva como notificada de segundo pago: ", error);
        }

        console.log('notificado: ', r.correo)
      }
      else return;
    })

  },
  async marcarComoPagado({ commit }, reserva) {
    const id = reserva.id;
    const estadoPrevio = JSON.parse(JSON.stringify(reserva.estado))

    //Cambio de estado
    try {
      const doc = db.collection('reservasPorConfirmar').doc(id);

      if (reserva.permiteAbono && reserva.estado === "Pendiente de primer pago") {
        await doc.update({ estado: "Pendiente de segundo pago" });

        commit('EsperandoSegundoPagoReservaPorConfirmar', id)
      }
      else {
        await doc.update({ estado: "Pagado" });
        commit('ReservaPagada', id)
      }
    } catch (error) {
      console.log(
        "Error en la actualización del estado a pagado en firebase: ",
        error
      );
    }

    // Correos electrónicos
    try {
      if (!reserva.permiteAbono || estadoPrevio === 'Pendiente de segundo pago') {
        const templateID = "pago_exitoso";
        const templateParams = {
          user_name: reserva.nom,
          user_fullname: reserva.nombre,
          user_mail: reserva.correo,
          user_location: reserva.procedencia,
          user_phone: reserva.telefono,
          experience_name: reserva.experiencia,
          experience_date: reserva.fecha,
          experience_startHour: reserva.horaInicio,
          experience_endHour: reserva.horaFin,
          experience_lang: reserva.idioma,
          reservation_group: reserva.viajeros,
          reservation_total: reserva.total,
          reservation_ID: reserva.idUnico,
        };
        await emailjs.send(serviceID, templateID, templateParams, userID);
      } else if (estadoPrevio === 'Pendiente de primer pago') {
        const linkPago = "https://surreal.travel/checkout2/" + reserva.idUnico;
        const fechaExperiencia = moment(reserva.fecha.split(', ')[1].split(' de ').join(' '));
        const fechaSegundoPago = fechaExperiencia.subtract(3, 'd').format('DD/MM/YYYY')

        const templateID = "reserva_segundo_pago";
        const templateParams = {
          user_name: reserva.nom,
          user_fullname: reserva.nombre,
          user_mail: reserva.correo,
          user_location: reserva.procedencia,
          user_phone: reserva.telefono,
          experience_name: reserva.experiencia,
          experience_date: reserva.fecha,
          experience_startHour: reserva.horaInicio,
          experience_endHour: reserva.horaFin,
          experience_lang: reserva.idioma,
          reservation_group: reserva.viajeros,
          reservation_percentage: reserva.abono.porcentajeAbono,
          reservation_first_payment: reserva.abono.primeraCuota,
          reservation_second_payment: reserva.abono.segundaCuota,
          reservation_total: reserva.total,
          reservation_total: reserva.total,
          reservation_ID: reserva.idUnico,
          reservation_comments: reserva.comentarios,
          reservation_vegan: reserva.vegano,
          reservation_link: linkPago,
          second_payment_date: fechaSegundoPago
        };
        await emailjs.send(serviceID, templateID, templateParams, userID);
      }
      alert(`Cambio de estado exitoso: Correo electrónico enviado a ${reserva.correo}`)

    } catch (error) {
      console.log('Error al enviar correo electrónico', error);
    }
  },
  async enviarCorreoSolicitandoFeedback({ state, commit, rootState }) {
    const reservasInmediatas = await state.reservaciones
    const reservasPorConfirmar = await state.reservacionesPorConfirmar
    const todasLasReservas = await reservasInmediatas.concat(reservasPorConfirmar);
    const experiencias = await rootState.experiencias;
    //Filtro horario
    const ahora = moment()
    const horaInicioNotificaciones = moment().hour(7).minute(0).second(0);
    const horaFinNotificaciones = moment().hour(23).minute(0).second(0);
    const horaEntreRango = ahora.isBetween(horaInicioNotificaciones, horaFinNotificaciones)
    if (!horaEntreRango) return;

    //Reservas a notificar
    const reservasPagadas = todasLasReservas.filter(r => {
      const fechaExperiencia = moment(Vue.filter("fechaToISOString")(r.fecha));
      const horaFin = r.horaFin;
      let fechaFin = r.fechaFin ? moment(Vue.filter("fechaToISOString")(r.fechaFin, horaFin)) : false;

      //Calculo de fecha de vuelta en caso de que no exista fechaFin
      if (!fechaFin) {
        const experiencia = experiencias.find((exp) =>
          exp.titulo.includes(r.experiencia)
        );
        const cantidadDias = experiencia ? experiencia.cantidadDias : 1;

        fechaFin =
          cantidadDias > 1
            ? moment(`${moment(moment(Vue.filter("fechaToISOString")(r.fecha, horaFin))).add(cantidadDias - 1, "d")}`)
            : moment(moment(Vue.filter("fechaToISOString")(r.fecha, horaFin)))
      }

      //Condiciones
      const fechasValidas = fechaExperiencia.isValid() && fechaFin.isValid();
      const reservaNoEvaluadaNiNotificadaDeEvaluacion = !r.evaluada && !r.notificadoFeedback;
      const fechaReservaPostFechaDeterminada = fechaExperiencia.isAfter(moment('1 Julio 2021'))

      return fechasValidas && reservaNoEvaluadaNiNotificadaDeEvaluacion && r.estado === 'Pagado' && moment().isAfter(fechaFin, 'hour') && fechaReservaPostFechaDeterminada
    })


    reservasPagadas.forEach(async reserva => {
      const esInmediata = reservasInmediatas.find(r => r.id === reserva.id);

      //CorreoElectrónico
      try {
        const templateID = 'evaluacion_experiencia'
        const templateParams = {
          user_name: reserva.nombre,
          experience_name: reserva.experiencia,
          evaluation_link: `https://surreal.travel/evaluacion/${reserva.idUnico}`,
          user_mail: reserva.correo
        }
        await emailjs.send(serviceID, templateID, templateParams, userID);

      } catch (error) {
        console.log('Error al enviar correo de notificacion', error);
      }

      //Firebase
      try {
        if (esInmediata) await reservasInmediatasCollection.doc(reserva.id).update({ notificadoFeedback: true });
        else await reservasPorConfirmarCollection.doc(reserva.id).update({ notificadoFeedback: true });

        commit('notificadaFeedback', reserva.id);
      } catch (error) {
        console.log('Error al marcar reserva como notificada:', error);
      }

      console.log(reserva.id + ' ok');
    });
  },
  async registrarVisitaEnPagoExitoso({ commit }, { reserva, reservaInmediata, path, estado , token }) {
    try {
      const { id, } = reserva;
      const nuevaVisita = {
        path,
        date: moment().format(),
        estadoInicial: estado,
        token
      }
      const  visitasAnteriores = reserva.visitasPagoExitoso ? reserva.visitasPagoExitoso : []
      const visitasPagoExitoso = [...visitasAnteriores , nuevaVisita]


      reservaInmediata
        ? await reservasInmediatasCollection.doc(id).update({visitasPagoExitoso})
        : await reservasPorConfirmarCollection.doc(id).update({visitasPagoExitoso})
    } catch (error) {
      console.log('error al registrar visita en vista de pago exitoso');
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
