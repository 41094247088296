export default function storageHandler(storage, state, key, level) {
    // (IMPORTANTE!) Variables para chequear a cuales secciones de imagenes
    // se les esta haciendo update (principal e imagenes de hero)
    const storageConfig = {
        carouselImageUpdate: null,
        localidadImageUpdate: null,
        heroImgsUpdate: [],
        contenidoCulturaUpdates: []
    }

    // La imagenes que se agreguen para el update seran guardadas en un objeto
    // (state.zonaImgsToSave | contenidoCulturaBorrador) que servira como borrador, las propiedades de imagenes
    // en el estado de zona (state.zona) no se modificaran para mostrar en la plantilla las
    // imagenes que ya han sido previamente guardadas

    // Deconstructing y creacion de promesa de la imagen principal
    var { carouselImg, carouselImgUrl } = state.trip.viajeImgsToSave
    if (carouselImg && carouselImgUrl) {
        const extension = carouselImg.name.slice(carouselImg.name.lastIndexOf('.'))
        let carouselImgUploadString
        switch (level) {
            case 'trip':
                carouselImgUploadString = `viajes/${key}/main${extension}`
                break
            default:
                break
        }
        const carouselImgUploadPointer = storage.ref().child(carouselImgUploadString)
        const carouselImgUploadTask = carouselImgUploadPointer.put(carouselImg)
            // Almacenar promesa en taskArray
        storageConfig.carouselImageUpdate = carouselImgUploadTask
    }

    var { carouselImg, carouselImgUrl } = state.zonaImgsToSave
    if (carouselImg && carouselImgUrl) {
        const extension = carouselImg.name.slice(carouselImg.name.lastIndexOf('.'))
        let carouselImgUploadString
        switch (level) {
            case 'zona':
                carouselImgUploadString = `zonas/${key}/main${extension}`
                break

            case 'destino':
                carouselImgUploadString = `zonas/${key.zonaId}/destinos/${key.destinoId}/main${extension}`
                break

            case 'experiencia':
                carouselImgUploadString = `zonas/${key.zonaId}/destinos/${key.destinoId}/experiencias/${key.experienciaId}/main${extension}`
                break
            default:
                break
        }
        const carouselImgUploadPointer = storage.ref().child(carouselImgUploadString)
        const carouselImgUploadTask = carouselImgUploadPointer.put(carouselImg)
            // Almacenar promesa en taskArray
        storageConfig.carouselImageUpdate = carouselImgUploadTask
    }

    // Deconstructing y creacion de promesa de la imagen principal
    const { localidadImg, localidadImgUrl } = state.zonaImgsToSave
    if (localidadImg && localidadImgUrl) {
        const extension = localidadImg.name.slice(localidadImg.name.lastIndexOf('.'))
        let localidadImgUploadString
        switch (level) {
            case 'zona':
                localidadImgUploadString = `zonas/${key}/localidad${extension}`
                break

            case 'destino':
                localidadImgUploadString = `zonas/${key.zonaId}/destinos/${key.destinoId}/localidad${extension}`
                break

            case 'experiencia':
                localidadImgUploadString = `zonas/${key.zonaId}/destinos/${key.destinoId}/experiencias/${key.experienciaId}/localidad${extension}`
                break
            default:
                break
        }
        const localidadImgUploadPointer = storage.ref().child(localidadImgUploadString)
        const localidadImgUploadTask = localidadImgUploadPointer.put(localidadImg)
            // Almacenar promesa en taskArray
        storageConfig.localidadImageUpdate = localidadImgUploadTask
    }

    // Deconstructing y creacion de promera de imagenes que se mostraran en el hero
    const { heroImgs, heroImgUrls } = state.zonaImgsToSave
    if ((heroImgs.length > 0 && heroImgUrls.length > 0) && (heroImgs.length === heroImgUrls.length)) {
        // Array map para almacenar las promesas de las imagenes de hero
        const heroImagesUploadHandler = heroImgs.map((img, index) => {
                const newFileName = Math.floor(Math.random() * 100000000)
                const extension = img.name.slice(img.name.lastIndexOf('.'))

                let heroUploadString
                switch (level) {
                    case 'zona':
                        heroUploadString = `zonas/${key}/hero/${newFileName + extension}`
                        break

                    case 'destino':
                        heroUploadString = `zonas/${key.zonaId}/destinos/${key.destinoId}/hero/${newFileName + extension}`
                        break

                    case 'experiencia':
                        heroUploadString = `zonas/${key.zonaId}/destinos/${key.destinoId}/experiencias/${key.experienciaId}/hero/${newFileName + extension}`
                        break
                    default:
                        break
                }

                const heroUploadPointer = storage.ref().child(heroUploadString)
                const heroUploadTask = heroUploadPointer.put(img)
                return heroUploadTask
            })
            // Almacenar promesas en taskArray
        storageConfig.heroImgsUpdate = heroImagesUploadHandler
    }

    if (state.contenidoCulturaBorrador) {
        const { contenidoCulturaBorrador } = state
        const culturaImagesUploadHandler = contenidoCulturaBorrador.map((contenido) => {
            const img = contenido.img
            const newFileName = Math.floor(Math.random() * 100000000)
            const extension = img.name.slice(img.name.lastIndexOf('.'))

            let contenidoUploadString

            switch (level) {
                case 'zona':
                    contenidoUploadString = `zonas/${key}/cultura-slideshow/${newFileName + extension}`
                    break

                case 'destino':
                    contenidoUploadString = `zonas/${key.zonaId}/destinos/${key.destinoId}/cultura-slideshow/${newFileName + extension}`
                    break

                default:
                    break
            }

            const contenidoUploadPointer = storage.ref().child(contenidoUploadString)
            const contenidoUploadTask = contenidoUploadPointer.put(img)
            return contenidoUploadTask
        })

        storageConfig.contenidoCulturaUpdates = culturaImagesUploadHandler
    }

    if (state.trip.itinerariosDias) {
        const { itinerariosDias } = state.trip
        const itinerarioImagesUploadHandler = itinerariosDias.map((contenido) => {
            if(contenido.img){
                const img = contenido.img
                const newFileName = Math.floor(Math.random() * 100000000)
                const extension = img.name.slice(img.name.lastIndexOf('.'))

                let contenidoUploadString

                switch (level) {
                    case 'trip':
                        contenidoUploadString = `viajes/${key}/dias-itinerarios/${newFileName + extension}`
                        break

                    default:
                        break
                }

                const contenidoUploadPointer = storage.ref().child(contenidoUploadString)
                const contenidoUploadTask = contenidoUploadPointer.put(img)
                return contenidoUploadTask
            }
            
        })
        storageConfig.itinerarioDiasUpdates = itinerarioImagesUploadHandler
    }

    return storageConfig
}