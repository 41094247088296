<template>
	<b-container fluid id="NewReserva">
		<b-container class="py-5">
			<div class="text-center d-flex align-items-center mb-4">
				<b-button :to="{ name: 'AdminHome' }" variant="success" class="mr-1">Ir a Home de admin</b-button>
			</div>
			<h2>Crear reserva personalizada</h2>
			<b-row>
				<b-col cols="12">
					<h3 class="mb-0 mt-2">Datos experiencia:</h3>
					<b-row>
						<b-col>
							<label for="NewExperiencia">Titulo Experiencia :</label>
							<b-form-input list="Experiencias" id="NewExperiencia" v-model="experiencia" />
						</b-col>
						<b-col cols="3">
							<label for="NewIdioma">Idioma:</label>
							<select name="idioma" id="NewIdioma" v-model="idioma" class="form-control">
								<option value="Español">Español</option>
								<option value="Inglés">Inglés</option>
							</select>
						</b-col>
						<b-col cols="3">
							<label for="NewIdioma">Moneda:</label>
							<select name="idioma" id="NewIdioma" v-model="moneda" class="form-control">
								<option value="CLP">Pesos Chilenos (CLP)</option>
								<option value="USD">Dólares Estadounidenses (USD)</option>
								<option value="EUR">Euros (EUR)</option>
							</select>
						</b-col>
					</b-row>
				</b-col>
				<b-col cols="12">
					<b-row>
						<b-col cols="12" lg="5">
							<label>Fecha:</label>
							<vc-date-picker v-model="fechaReserva" is-range mode="range" :min-date="new Date()" />
						</b-col>
						<b-col cols="12" lg="7">
							<b-row align-v="end">
								<b-col cols="6" md="4">
									<label for="NewCantidadAdultos">Cantidad adultos: </label>
									<b-form-spinbutton v-model="detalleViajeros.adultos.cantidad"
										id="NewCantidadAdultos" @input="calculoTotal" @keyup="calculoTotal" />
								</b-col>
								<b-col cols="6" md="4">
									<label for="NewPrecioAdultos">Precio:</label>
									<b-form-input id="NewPrecioAdultos" v-model="detalleViajeros.adultos.precio"
										@input="calculoTotal" :min="0" />
								</b-col>
								<b-col cols="12" md="4">
									<label for="NewTotalAdultos">Total:</label>
									<b-form-input id="NewTotalAdultos" v-model="detalleViajeros.adultos.total"
										disabled />
								</b-col>
								<hr>
							</b-row>

							<template>
								<b-row align-v="end">
									<b-col cols="6" md="4">
										<label :for="`NewCantidad`">Cantidad Niños</label>

										<b-form-spinbutton v-model="ninos.cantidad" :id="`NewCantidad`" :min="0"
											@input="loadNinos()" />
									</b-col>
									<hr />
								</b-row>
								<b-row v-for="(niño, i) of detalleViajeros.niños" :key="`${i}`" align-v="end">
									<b-col cols="6" md="3">
										<!-- <input type="hidden" 
																				v-model="detalleViajeros.niños[i].edad" 
																				:value="`Niñ@ edad: ${detalleViajeros.niños[i].edad} ${detalleViajeros.niños[i].cantidad}`"> -->
										<label for=""> Edad</label>
										<select class="form-control" v-model="detalleViajeros.niños[i].edad">
											<option :value="edad" v-for="(edad, key) in years" :key="key">{{ edad }}</option>
										</select>
									</b-col>
									<b-col cols="6" md="3">
										<label :for="`NewCantidad${niño.nombre}${i}`">Cantidad {{ niño.nombre }} :
										</label>

										<b-form-spinbutton v-model="detalleViajeros.niños[i].cantidad"
											:id="`NewCantidad${niño.nombre}${i}`" :min="0" @input="calculoTotal" />
									</b-col>
									<b-col cols="6" md="3">
										<label :for="`NewPrecio${niño.nombre}${i}`">Precio:</label>

										<b-form-input v-model="detalleViajeros.niños[i].precio"
											:id="`NewPrecio${niño.nombre}${i}`" :min="0" @change="calculoTotal"
											@keyup="calculoTotal" />
									</b-col>
									<b-col cols="12" md="3">
										<label :for="`NewTotal${niño.nombre}${i}`">Total:</label>

										<b-form-input v-model="detalleViajeros.niños[i].total"
											:id="`NewTotal${niño.nombre}${i}`" :min="0" disabled />
									</b-col>
									<hr />
								</b-row>
							</template>

							<label :for="`CantidadViajeros`">Cantidad viajeros:</label>
							<b-form-input v-model="viajeros" :id="`CantidadViajeros`" disabled />
						</b-col>

						<b-col cols="12">
							<h3 class="mb-0 mt-2">Pago:</h3>
							<label for="NewTotal">Total:</label>
							<b-form-input id="NewTotal" v-model="total" type="number" min="0" disabled />
						</b-col>

						<b-col cols="12">
							<b-form-checkbox v-model="permiteAbono" size="lg">¿Permite pago con
								abono?:</b-form-checkbox>
						</b-col>
						<b-col cols="12" lg="4" v-if="permiteAbono">
							<label for="NewPorcentajeAbono">Porcentaje de abono:</label>
							<b-form-input type="number" min="0" v-model="porcentajeAbono" max="100" />
						</b-col>
						<b-col cols="6" lg="4" v-if="permiteAbono">
							<label for="NewPrimeraCuota">Primera cuota:</label>
							<b-form-input id="NewPrimeraCuota" type="number" :value="cuotas.primeraCuota" min="0"
								disabled />
						</b-col>
						<b-col cols="6" lg="4" v-if="permiteAbono">
							<label for="NewSegundaCuota">Segunda cuota:</label>
							<b-form-input id="NewSegundaCuota" type="number" :value="cuotas.segundaCuota" min="0"
								disabled />
						</b-col>
						<b-col cols="12">
							<h3 class="mb-0 mt-2">Datos cliente:</h3>
						</b-col>
						<b-col cols="12" lg="4">
							<label for="NewNombre">Nombre:</label>
							<b-form-input id="NewNombre" v-model="nombre" />
						</b-col>
						<b-col cols="12" lg="4">
							<label for="NewCorreo">Email:</label>
							<b-form-input id="NewCorreo" v-model="email" />
						</b-col>
						<b-col cols="12" lg="4">
							<label for="NewTelefono">Teléfono:</label>
							<b-form-input id="NewTelefono" v-model="telefono" />
						</b-col>
						<b-col cols="12">
							<label for="NewProcedencia">Ciudad de origen:</label>
							<b-form-input id="NewProcedencia" v-model="procedencia" />
						</b-col>
						<b-col cols="12">
							<label for="NewPais">País:</label>
							<select id="NewPais" name class="form-control" v-model="pais">
								<option :value="country" v-for="(country, index) in countries" :key="index">
									{{ country }}
								</option>
							</select>
						</b-col>
						<b-col cols="12">
							<label for="NewComentarios">Comentarios:</label>
							<b-form-textarea id="NewComentarios" v-model="comentarios" />
						</b-col>
						<b-col cols="12">
							<label for="NewVegano">Vegano/Vegetariano?:</label>
							<b-form-textarea id="NewVegano" v-model="vegano" />
						</b-col>

						<b-col cols="12" class="mt-2 mb-4">
							<b-button variant="primary" block @click="crearReserva()">Crear reserva</b-button>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-container>
	</b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import moment from "moment";
import dataCountries from "../../utils/jsonCountries.json";
export default {
	name: "crearReservaViaje",
	data: () => ({
		calendarMode: "range",
		countries: [],
		experiencia: "",
		fechaReserva: null,
		viajeros: 1,
		ninos: { precio: 0, cantidad: 0 },
		experiencia: "",
		detalleViajeros: {
			adultos: {
				cantidad: 1,
				precio: 0,
				total: 0
			},
			niños: []
		},
		idioma: "Español",
		permiteAbono: false,
		porcentajeAbono: 100,
		primeraCuota: 0,
		segundaCuota: 0,
		total: 0,
		//Datos cliente
		nombre: "",
		email: "",
		telefono: "",
		procedencia: "",
		pais: "Chile",
		comentarios: "",
		vegano: "",
		moneda: ""
	}),
	computed: {
		cuotas() {
			const primeraCuota = Math.round(
				(this.total / 100) * this.porcentajeAbono
			);
			const segundaCuota = this.total - primeraCuota;

			return {
				primeraCuota: primeraCuota
					.toString()
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
				segundaCuota: segundaCuota
					.toString()
					.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
			};
		},
		years() {
			let arr = ["< 1"];
			for (let index = 1; index < 18; index++) {
				arr.push(index);
			}
			return arr;
		},
		formatDetalleNinos() {
			const { niños } = this.detalleViajeros;

			return niños.map((_n) => {

				const { cantidad, total, edad, precio } = _n;

				return {
					cantidad: cantidad,
					total: total,
					precio: precio,
					nombre: `Niñ@ Edad: ${_n.edad} Precio: ${_n.precio}`
				}
			})
		}
	},
	created() {
		this.loadCountries();
	},
	methods: {
		...mapActions("reservaciones", ["nuevaReservaPersonalizada"]),
		async crearReserva() {
			console.log('entra aqui')

			const { adultos } = this.detalleViajeros;

			let detalleViajeros = {
				adultos: adultos,
				niños: this.formatDetalleNinos
			};

			const reserva = {
				abono: {
					porcentajeAbono: this.permiteAbono ? this.porcentajeAbono : 100,
					primeraCuota: this.permiteAbono
						? this.cuotas.primeraCuota
						: this.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
					segundaCuota: this.permiteAbono ? this.cuotas.segundaCuota : "0",
				},
				comentarios:
					this.comentarios.trim() != ""
						? this.comentarios.trim()
						: "Sin comentarios",
				correo: this.email,
				estado: "Pendiente",
				experiencia: this.experiencia,
				experienciaId: null,
				fecha: Vue.filter("toReadableDate")(
					this.calendarMode === "range"
						? this.fechaReserva.start
						: this.fechaReserva
				),
				fechaActual: Vue.filter("toReadableDate")(moment()),
				fechaFin: Vue.filter("toReadableDate")(
					this.calendarMode === "range"
						? this.fechaReserva.end
						: this.fechaReserva
				),
				horaInicio: "00:00",
				horaFin: "23:59",
				idUnico: new Date().getTime().toString(),
				idioma: this.idioma,
				nom: this.nombre.trim().split(" ")[0],
				nombre: this.nombre.trim(),
				permiteAbono: this.permiteAbono,
				procedencia: `${this.procedencia.trim()}, ${this.pais}`,
				promociones: "Sin envío de promociones",
				referencia: "Sin información",
				telefono: this.telefono,
				token: "",
				total: this.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
				vegano:
					this.vegano.trim() != "" ? this.vegano.trim() : "Sin información",
				viajeros: +this.viajeros,
				detalleViajeros: detalleViajeros,
				moneda: this.moneda
			};

			// console.log(reserva);
			const crear = await this.nuevaReservaPersonalizada(reserva);
			if (!crear) return;
			alert("Reserva creada");
			this.$router.push({name:"ReservasPorConfirmar"});
			/*this.resetForm();*/
		},
		async loadCountries() {
			const { countries } = await dataCountries;
			this.countries = countries.map((c) => {
				return c.es_name;
			});
		},
		loadNinos() {
			let { cantidad } = this.ninos;
			let arr = [];
			for (var i = 0; i < cantidad; i++) {
				arr.push({
					nombre: "",
					edad: "< 1",
					cantidad: 1,
					precio: 0
				});
			}
			this.detalleViajeros.niños = arr;
		},
		calculoTotal() {
			let { adultos, niños } = this.detalleViajeros;
			let total = 0;

			adultos.cantidad = +adultos.cantidad;
			adultos.precio = +adultos.precio;
			adultos.total = adultos.cantidad * adultos.precio;
			total += adultos.total;

			if (niños) {
				let totalNiños = 0;
				niños.forEach((niño) => {
					niño.cantidad = +niño.cantidad;
					niño.precio = +niño.precio;
					niño.total = niño.precio * niño.cantidad;
					totalNiños += niño.total;
				});

				total += totalNiños;
			}
			this.total = total;
			this.viajerosAuto();
		},
		viajerosAuto() {
			let total = 0;
			if (this.detalleViajeros) {
				total = +this.detalleViajeros.adultos.cantidad;
				total = total;
				if (this.detalleViajeros.niños) {
					this.detalleViajeros.niños.forEach((niño) => {
						total += +niño.cantidad;
					});
				}
			}
			this.viajeros = total;
		},
		resetForm() {
			this.experiencia = "";
			this.fechaReserva = null;
			this.viajeros = 1;
			this.idioma = "Español";
			this.permiteAbono = false;
			this.porcentajeAbono = 100;
			this.primeraCuota = 0;
			this.segundaCuota = 0;
			this.total = 0;
			this.nombre = "";
			this.email = "";
			this.telefono = "";
			this.procedencia = "";
			this.pais = "Chile";
			this.comentarios = "";
			this.vegano = "";
			this.ninos = { precio: 0, cantidad: 0 }
		},
	}
}
</script>

<style lang="scss" scoped>
#NewReserva {
	color: #fff;

	label {
		padding-top: 1rem;
	}
}

hr {
	background: #fff;
	width: 100%;
}
</style>