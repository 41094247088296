var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"ExperienciasImperdibles"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile()),expression:"isMobile()"}]},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.getDestTitle)}}),_vm._l((_vm.getDestinos
        .filter(d => d.heroImgUrls && d.heroImgUrls.length > 0)
        .map(des => {
          const order =
            des.localizacion &&
            des.localizacion.latitud &&
            parseInt(des.localizacion.latitud)
              ? parseInt(des.localizacion.latitud)
              : 999;
          return { ...des, order };
        })
        .sort((des1, des2) =>
          des1.order > des2.order ? 1 : des1.order < des2.order ? -1 : 0
        )),function(dest,index){return _c('b-card',{key:index,attrs:{"overlay":"","img-src":dest.carouselImgUrl,"img-height":"120","img-alt":"img"},on:{"click":function($event){return _vm.sendDest(dest.id)}}},[_c('div',{staticClass:"center-all blackCapa"},[_c('p',{staticClass:"child"},[_vm._v(_vm._s(_vm.language === "en" && dest.ingles.titulo ? dest.ingles.titulo : dest.titulo))])])])}),_c('br'),_c('h1',{domProps:{"innerHTML":_vm._s(_vm.getExpTitle)}}),_c('div',{staticClass:"swiper swiper-mobile"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.slideExperiencias
            ? _vm.slideExperiencias
                .map(slex => slex[0])
                .map(slex => {
                  const order =
                    slex.stock && parseInt(slex.stock)
                      ? parseInt(slex.stock)
                      : 999;
                  return { ...slex, order };
                })
                .sort((des1, des2) =>
                  des1.order > des2.order
                    ? 1
                    : des1.order < des2.order
                    ? -1
                    : 0
                )
            : []),function(exp,index){return _c('div',{key:index,staticClass:"swiper-slide swiper-width-m"},[_c('div',{staticStyle:{"border-left":"1px solid #858585","border-right":"1px solid #858585"}},[(
                _vm.evaluacionesByExperiencia(exp.id).length > 0 &&
                _vm.promedioRates(exp.id) > 0
                  ? true
                  : false
              )?_c('b-form-rating',{staticStyle:{"position":"absolute","background":"none"},attrs:{"value":_vm.evaluacionesByExperiencia(exp.id).length > 0
                  ? _vm.promedioRates(exp.id)
                  : exp.puntaje,"no-border":"","readonly":"","inline":"","variant":"warning"}}):_vm._e(),_c('b-card-img',{attrs:{"src":exp.carouselImgUrl,"height":"230","width":"260"}}),_c('b-card-text',{staticClass:"text-left",staticStyle:{"margin":"20px"}},[_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v(_vm._s(_vm.language === "en" && exp.ingles.tituloDestino ? exp.ingles.tituloDestino : exp.tituloDestino))])]),_c('b-card-text',{staticClass:"text-left",staticStyle:{"margin":"20px"}},[_c('p',{staticStyle:{"font-size":"14px","font-weight":"bold","color":"black"}},[_vm._v(" "+_vm._s(_vm.language === "en" && exp.ingles.titulo ? exp.ingles.titulo : exp.titulo)+" ")])]),_c('b-card-text',{staticClass:"text-left",staticStyle:{"margin":"20px"}},[_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.language === "en" ? "From" : "Desde")+" "),_c('strong',[_vm._v("$"+_vm._s((exp.precioMasBajo ? exp.precioMasBajo : _vm.precioMasBajo(exp) ) .toString() .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")))]),_vm._v(" | "+_vm._s(exp.cantidadDias)+" "+_vm._s(_vm.language === "en" ? "days" : "días")+" ")])]),_c('b-card-text',{staticStyle:{"margin":"20px"}},[_c('p',{staticClass:"text-justify",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.language === "en" && exp.ingles.resumen ? exp.ingles.resumen.length > 120 ? exp.ingles.resumen.substring(0, 120) : exp.ingles.resumen : exp.resumen.length > 120 ? exp.resumen.substring(0, 120) : exp.resumen)+"... ")])]),_c('b-button',{staticStyle:{"background-color":"#FFAA01","border-color":"#FFAA01"},on:{"click":function($event){return _vm.sendExp(exp.id)}}},[_vm._v("Ver detalles")])],1)])}),0)])],2),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isMobile()),expression:"!isMobile()"}]},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.getDestTitle)}}),_c('div',{staticClass:"wrapper center-all"},_vm._l(([..._vm.getDestinos]
          .filter(d => d.heroImgUrls && d.heroImgUrls.length > 0)
          .map(des => {
            const order =
              des.localizacion &&
              des.localizacion.latitud &&
              parseInt(des.localizacion.latitud)
                ? parseInt(des.localizacion.latitud)
                : 999;
            return { ...des, order };
          })
          .sort((des1, des2) =>
            des1.order > des2.order ? 1 : des1.order < des2.order ? -1 : 0
          )),function(dest,index){return _c('b-card',{key:index,staticStyle:{"width":"20rem"},attrs:{"overlay":"","img-src":dest.carouselImgUrl,"img-height":"200","img-alt":"img"},on:{"click":function($event){return _vm.sendDest(dest.id)}}},[_c('div',{staticClass:"center-all blackCapa-desktop"},[_c('p',{staticClass:"child"},[_vm._v(" "+_vm._s(_vm.language === "en" && dest.ingles.titulo ? dest.ingles.titulo : dest.titulo)+" ")])])])}),1),_c('h1',{staticStyle:{"margin":"30px"},domProps:{"innerHTML":_vm._s(_vm.getExpTitle)}}),_c('div',{staticClass:"swiper swiper-desk"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.slideExperiencias
            ? _vm.slideExperiencias
                .reduce((acum, slex) => {
                  slex.forEach(s => acum.push(s));
                  return acum;
                }, [])
                .map(slex => {
                  const order =
                    slex.stock && parseInt(slex.stock)
                      ? parseInt(slex.stock)
                      : 999;
                  return { ...slex, order };
                })
                .sort((des1, des2) =>
                  des1.order > des2.order
                    ? 1
                    : des1.order < des2.order
                    ? -1
                    : 0
                )
            : []),function(exp,index){return _c('div',{key:index,staticClass:"swiper-slide swiper-width-d"},[_c('div',{staticStyle:{"border-left":"1px solid #858585","border-right":"1px solid #858585"}},[(
                _vm.evaluacionesByExperiencia(exp.id).length > 0 &&
                _vm.promedioRates(exp.id) > 0
                  ? true
                  : false
              )?_c('b-form-rating',{staticStyle:{"position":"absolute","background":"none"},attrs:{"value":_vm.evaluacionesByExperiencia(exp.id).length > 0
                  ? _vm.promedioRates(exp.id)
                  : exp.puntaje,"no-border":"","readonly":"","inline":"","variant":"warning"}}):_vm._e(),_c('b-card-img',{attrs:{"src":exp.carouselImgUrl,"height":"230","width":"300"}}),_c('b-card-text',{staticClass:"text-left",staticStyle:{"margin":"20px"}},[_c('p',{staticStyle:{"font-size":"10px"}},[_vm._v(" "+_vm._s(_vm.language === "en" && exp.ingles.tituloDestino ? exp.ingles.tituloDestino : exp.tituloDestino)+" ")])]),_c('b-card-text',{staticClass:"text-left",staticStyle:{"margin":"20px"}},[_c('p',{staticStyle:{"font-size":"14px","font-weight":"bold","color":"black"}},[_vm._v(" "+_vm._s(_vm.language === "en" && exp.ingles.titulo ? exp.ingles.titulo : exp.titulo)+" ")])]),_c('b-card-text',{staticClass:"text-left",staticStyle:{"margin":"20px"}},[_c('p',{staticStyle:{"font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.language === "en" ? "From" : "Desde")+" "),_c('strong',[_vm._v("$"+_vm._s((exp.precioMasBajo ? exp.precioMasBajo : _vm.precioMasBajo(exp) ) .toString() .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")))]),_vm._v(" | "+_vm._s(exp.cantidadDias)+" "+_vm._s(_vm.language === "en" ? "days" : "días")+" ")])]),_c('b-card-text',{staticStyle:{"margin":"20px"}},[_c('p',{staticClass:"text-justify",staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.language === "en" && exp.ingles.resumen ? exp.ingles.resumen.length > 120 ? exp.ingles.resumen.substring(0, 120) : exp.ingles.resumen : exp.resumen.length > 120 ? exp.resumen.substring(0, 120) : exp.resumen)+"... ")])]),_c('a',{staticClass:"btn btn-secondary",staticStyle:{"background-color":"#FFAA01","border-color":"#FFAA01"},on:{"click":function($event){return _vm.sendExp(exp.id)}}},[_vm._v(_vm._s(_vm.language === "en" ? "See details" : "Ver detalles"))]),_c('p')],1)])}),0),_c('div',{staticClass:"swiper-button-next"}),_c('div',{staticClass:"swiper-button-prev"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }