/* eslint-disable */

export default {
  namespaced: true,

  state: {
    PorqueViajarESP: {
      titulo: "¿Por qué viajar con nosotros?",
      T1: "Experiencias auténticas",
      T2: "Impacto en la comunidad",
      T3: "Seguridad",
      T4: "Garantía SURREAL",
      P1: "Recolectamos experiencias únicas, lejos del turismo masivo.",
      P2:
        "Todas nuestras experiencias están dadas por los mejores tour operadores LOCALES.",
      P3: "Nuestros tour operadores se rigen bajo los protocolos COVID-19.",
      P4: "Reserva bajo una plataforma segura."
    },
    PorqueViajarENG: {
      titulo: "Why travel with us?",
      T1: "Authentic experiences",
      T2: "Positive impact",
      T3: "Safety",
      T4: "Surreal guarantee",
      P1: "We have experiences far from mass tourism.",
      P2: "All our experiences are given by local tour operators.",
      P3: "All our activities comply with COVID-19 protocols.",
      P4: "Securely book your experience."
    }
  },
  getters: {
    Titulo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.titulo;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.titulo;
      }
    },
    T1(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.T1;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.T1;
      }
    },
    T2(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.T2;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.T2;
      }
    },
    T3(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.T3;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.T3;
      }
    },
    T4(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.T4;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.T4;
      }
    },
    P1(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.P1;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.P1;
      }
    },
    P2(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.P2;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.P2;
      }
    },
    P3(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.P3;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.P3;
      }
    },
    P4(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PorqueViajarESP.P4;
      } else if (rootState.language == "en") {
        return state.PorqueViajarENG.P4;
      }
    }
  },
  mutations: {},
  actions: {}
};
