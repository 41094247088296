<template>
  <div>
    <section class="section section--how">
      <div class="container text-center text-sm-left">
        <div class="row justify-content-center">
          <div class="col-8 offset-xs-2 col-sm-12 offset-sm-0">
            <div class="row">
              <div class="col-12 col-lg-2">
                <h5 class="small-caps">How it works</h5>
              </div>
              <div class="col-12 col-lg-10">
                <div class="row">
                  <div class="col-sm-4">
                    <div class="step">
                      <!--<img src="/assets/step_1_icon-7f95fcdf5f9318fb0a009c48c2e5cf30313a042f51c65dc6b9bdab7b28059d4e.png" alt="Chat with specialist icon">-->
                      <span>Step 1</span>
                    </div>
                    <h6>Tell us what kind of trip you want</h6>
                    <div class="text-muted">Complete our quick travel quiz</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="step">
                      <!--<img src="/assets/step_2_icon-ba4f0426f3c3841d4796c187b44c504f747b72f5e4eed97d29cbb74053c317cb.png" alt="Chat icons">-->
                      <span>Step 2</span>
                    </div>
                    <h6>Chat with a local travel specialist</h6>
                    <div class="text-muted">Work out the details together</div>
                  </div>
                  <div class="col-sm-4">
                    <div class="step">
                      <!--<img src="/assets/step_3_icon-ba24bb4a6921318402ed76e3788b37e86bdeb59a062451dc9203a6c833036628.png" alt="Credit card icon">-->
                      <span>Step 3</span>
                    </div>
                    <h6>Securely book your trip</h6>
                    <div class="text-muted">100% satisfaction guaranteed</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <hr class="m-0">
  </div>
</template>

<script>
    export default {
        name: "HowItWorks.vue"
    }
</script>

<style scoped>
  .section--how{padding:1rem 0 !important;font-size:0.8rem}
  .section--how h5{letter-spacing:0.1rem;color:#6c757d;font-size:1.1rem;font-weight:600}
  @media (min-width: 992px){.section--how h5{height:72px;line-height:79px}}
  .section--how .col-md-2{margin-top:-2px;padding-top:0}
  .section--how .step{color:#ffc20e}
  .section--how .step img{height:25px;margin-right:5px}
  .section--how .step span{font-size:0.8rem;font-weight:600;position:relative;top:7px;text-transform:uppercase}
  .section--how .step__arrow{position:absolute;right:3rem;top:40%;width:25px}
  .section--how h6{margin:0.75rem 0 0.3rem}
</style>
