/* eslint-disable */
export default {
  namespaced: true,

  state: {
    MainMenuESP: {
      contacto: "Contacto",
      nosotros: "Nosotros",
      viajes: "Viajes"
    },
    MainMenuENG: {
      contacto: "Contact us",
      nosotros: "About us",
      viajes: "Trip"
    }
  },
  getters: {
    MainMenuNosotros(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.MainMenuESP.nosotros;
      } else if (rootState.language == "en") {
        return state.MainMenuENG.nosotros;
      }
    },
    MainMenuViaje(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.MainMenuESP.viajes;
      } else if (rootState.language == "en") {
        return state.MainMenuENG.viajes;
      }
    },
    MainMenuContacto(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.MainMenuESP.contacto;
      } else if (rootState.language == "en") {
        return state.MainMenuENG.contacto;
      }
    }
  }
};
