<template>
  <div id="app">
    <LangMobile />
    <DestinosMobile />
    <BuscadorNav />
    <Nav />
    <div class="loadingSpinner" v-if="loading">
      <img
        src="@/assets/img/logo.png"
        width="250"
        alt="SurRealLogo"
        id="LogoLoading"
      />
    </div>
    <div class="menu-downspace"></div>
    <Menu />
    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <WhatsAppChat />
  </div>
</template>

<script>
/* eslint-disable */
import Menu from "@/components/Menu";
import Footer from "@/components/Footer";
import Contactanos from "@/components/Contactanos";
import FooterBottom from "@/components/FooterBottom";
import Nav from "@/components/Nav";
import LangMobile from "@/components/LangMobile";
import DestinosMobile from "@/components/DestinosMobile";
import BuscadorNav from "@/components/BuscadorNav";
import WhatsAppChat from "@/components/WhatsappChat";

import { mapState, mapActions } from "vuex";

export default {
  name: "App",
  metaInfo() {
    return {
      titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} - Surreal Travel` : "Surreal Travel";
      },
      meta: [
        {
          vmid: "description",
          name: "description",
          content:
            "SurReal es una plataforma digital de viajes que busca ser un puente entre viajeros e iniciativas locales que ofrezcan experiencias auténticas y sustentables, que permitan a los viajeros conocer Chile desde una perspectiva local.",
        },
        {
          vmid: "author",
          name: "author",
          content: ["Sebastián Segura", "Brian Habib", "Gonzalo Fleming"],
        },
        {
          vmid: "keywords",
          name: "keywords",
          content: [
            "viajes",
            "turismo",
            "experiencias",
            "aventura",
            "Sur de Chile",
            "Chile",
            "Escapadas",
          ],
        },
      ],
    };
  },
  computed: {
    ...mapState(["loading"]),
    path() {
      return this.$route.path;
    },
  },
  components: {
    Menu,
    Contactanos,
    Footer,
    FooterBottom,
    Nav,
    LangMobile,
    BuscadorNav,
    WhatsAppChat,
    DestinosMobile
  },
  methods: {
    ...mapActions([
      "zonasQuery",
      "destinosQuery",
      "experienciasQuery",
      "experienciasImperdiblesQuery",
      "getAnimation",
    ]),
    ...mapActions("reservaciones", [
      "getReservas",
      "enviarRecordatorioSegundoPago",
      'enviarCorreoSolicitandoFeedback'
    ]),
    ...mapActions("Evaluaciones", ["getEvaluaciones"]),
  },
  created() {
    this.zonasQuery(),
    this.destinosQuery(),
    this.experienciasQuery(),
    this.experienciasImperdiblesQuery(),
    this.getAnimation(),
    this.getReservas();
    this.getEvaluaciones();
    // setTimeout(this.enviarRecordatorioSegundoPago , 10000)
    // setTimeout(this.enviarCorreoSolicitandoFeedback , 10000)
  },
  mounted() {
    let menuTopStyle = document.getElementById("MenuTop").style;
    window.addEventListener("scroll", () => {
      if (window.pageYOffset >= 200) {
        document.getElementById("MenuTop").classList.remove("d-sm-block");
      } else {
        document.getElementById("MenuTop").classList.add("d-sm-block");
      }
    });
  },
};
</script>

<style lang="scss">
#LogoLoading {
  animation: titilar 2s infinite;
  animation-timing-function: linear;
}

@keyframes titilar {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.menu-downspace {
  width: 100%;
  height: 120px;

  @media screen and (max-width: 524px) {
    height: 60px;
  }
}

@import url("https://fonts.googleapis.com/css?family=Montserrat:300,400,500,500i,700&display=swap");

.loadingSpinner {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
  z-index: 5000;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;

  p {
    color: #212529;
    margin-top: 2.5%;
    font-size: 1.35rem;
    font-weight: 500;
    font-style: italic;
    font-family: sans-serif;
  }
}

body {
  font-family: "Montserrat", sans-serif !important;
  position: relative;
}

html {
  scroll-behavior: smooth !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none !important;
}

.flex {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.redondo {
  border-radius: 6px !important;
}

.fade-enter-active {
  transition: all 0.15s ease;
}

.fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.pre-wrap {
  white-space: pre-wrap;
}
</style>
