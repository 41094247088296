/* eslint-disable */
export default {
    namespaced: true,

    state: {
        ExperienciaESP: {
            cancelacion: 'POLITICA DE CANCELACIÓN',
            contactanos: "Contáctanos",
            debesTraer: 'DEBES TRAER',
            descripcion: "ITINERARIO",
            descubre: "Descubre más",
            destinos: "Destino",
            duracion: "Duración",
            idiomas: "Idioma",
            idioma: "Elige tu idioma",
            info: "INCLUYE",
            inicio: "Hora inicio",
            mas: "de esta zona",
            mapa: "MAPA",
            modalidad: "Modalidad",
            opiniones: 'OPINIONES',
            precio: "Por persona",
            resumen: "RESUMEN",
            reservar: "Reservar",
            ubicacion: "Ubicación",
            viajeros: "Viajeros (adultos)",
            niños: 'Niñ@s',
            breveitin: "Breve itinerario",
            durantenoche: "Durante la noche",
            chatespec: "Chatea con un especialista"
        },
        ExperienciaENG: {
            cancelacion: 'CANCELLATION POLICY',
            contactanos: "Contact us",
            debesTraer: 'MUST BRING',
            descripcion: "DESCRIPTION",
            descubre: "Discover more",
            destinos: "Destination",
            duracion: "Duration",
            idiomas: "Available Languages",
            idioma: "Language",
            info: "INCLUDES",
            inicio: "Starting at",
            mas: "about this destination",
            mapa: "MAP",
            modalidad: "Trip Type",
            opiniones: 'REVIEWS',
            precio: "Per person",
            resumen: "RESUME",
            reservar: "Book now",
            ubicacion: "Map",
            viajeros: "Travelers",
            niños: 'Children',
            breveitin: "Brief itinerary",
            durantenoche: "Overnigth",
            chatespec: "Chat with a specialist"
        }
    },
    getters: {
        destinosNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.destinos;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.destinos;
            }
        },
        duracionNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.duracion;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.duracion;
            }
        },
        idiomasNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.idiomas;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.idiomas;
            }
        },
        modalidadNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.modalidad;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.modalidad;
            }
        },
        resumenNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.resumen;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.resumen;
            }
        },
        descripcionNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.descripcion;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.descripcion;
            }
        },
        infoNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.info;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.info;
            }
        },
        precioNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.precio;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.precio;
            }
        },
        inicioNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.inicio;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.inicio;
            }
        },
        idiomaNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.idioma;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.idioma;
            }
        },
        viajerosNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.viajeros;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.viajeros;
            }
        },
        reservarNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.reservar;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.reservar;
            }
        },
        contactanosNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.contactanos;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.contactanos;
            }
        },
        ubicacionNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.ubicacion;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.ubicacion;
            }
        },
        descubreNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.descubre;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.descubre;
            }
        },
        masNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.mas;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.mas;
            }
        },
        opinionesNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.opiniones;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.opiniones;
            }
        },
        debesTraerNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.debesTraer;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.debesTraer;
            }
        },
        cancelacionNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.cancelacion;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.cancelacion;
            }
        },
        mapaNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.mapa;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.mapa;
            }
        },
        niñosNombre(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.niños;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.niños;
            }
        },
        breveitin(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.breveitin;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.breveitin;
            }
        },
        durantenoche(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.durantenoche;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.durantenoche;
            }
        },
        chatespec(state, getters, rootState) {
            if (rootState.language == "es") {
                return state.ExperienciaESP.chatespec;
            } else if (rootState.language == "en") {
                return state.ExperienciaENG.chatespec;
            }
        }
    },
    mutations: {},
    actions: {}
};