import { db } from "../../firebase.service";

const usuarios = db.collection("usuarios");

const state = () => ({
	usuarios: [],
});

const getters = {
	setUsuarios(state, payload) {
		state.usuarios = payload;
	},
};

const mutations = {
	setUsuarios(state, payload) {
		state.usuarios = payload;
	},
};

const actions = {
	async getUsuarios({ commit }) {
		usuarios.onSnapshot((snapshot) => {
			const dataArray = [];
			snapshot.forEach((doc) => {
				dataArray.push({ ...doc.data(), id: doc.id });
			});
			commit("setUsuarios", dataArray);
		});
	},
};

export default {
	namespaced: true,
	state,
	getters,
	mutations,
	actions,
};
