var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.data && _vm.data.length > 0)?_c('b-carousel',{staticClass:"carousel",attrs:{"indicators":"","id":"carousel","interval":_vm.interval,"controls":_vm.data.length > 1},model:{value:(_vm.slideVisible),callback:function ($$v) {_vm.slideVisible=$$v},expression:"slideVisible"}},_vm._l((_vm.data),function(contenidoArray,index){return _c('b-carousel-slide',{key:index,staticClass:"carousel__item",scopedSlots:_vm._u([{key:"img",fn:function(){return _vm._l((contenidoArray),function(contenido,index){return _c('div',{key:index},[(contenido.type !== 'youtube' && contenido.type !== 'mp4')?[_c('div',{staticClass:"carousel__images",style:({
                background: `url(${contenido.contentUrl}) no-repeat center center / cover`,
              })})]:_vm._e(),(contenido.type === 'youtube')?[_c('iframe',{staticClass:"carousel__images carousel__images--video",attrs:{"id":"iframe","src":contenido.contentUrl,"frameborder":"0","allowfullscreen":"allowfullscreen"}})]:_vm._e(),(contenido.type === 'mp4')?[_c('video',{staticClass:"carousel__images carousel__images--video",attrs:{"muted":"","controls":"","loop":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":contenido.contentUrl,"type":"video/mp4"}})])]:_vm._e()],2)})},proxy:true}],null,true)})}),1):_vm._e(),_c('div',{staticClass:"carousel__footer"},[_c('div',{staticClass:"carousel__footer__item py-2 d-flex"},_vm._l((_vm.data),function(contenidoArray,i){return _c('div',{key:i,staticClass:"pr-1",class:{
          selected: i === _vm.slideVisible,
        },on:{"click":function($event){_vm.slideVisible = i}}},_vm._l((contenidoArray),function(contenido,i){return _c('div',{key:i},[(contenido.type !== 'youtube' && contenido.type !== 'mp4')?[_c('div',{staticClass:"carousel__footer__images",style:({
                background: `url(${contenido.contentUrl}) no-repeat center center / cover`,
              })})]:_vm._e(),(contenido.type === 'youtube' || contenido.type === 'mp4')?[_c('div',{staticClass:"carousel__footer__images carousel__footer__images--video d-flex align-items-center justify-content-center"},[_c('b-icon',{attrs:{"icon":"play-btn"}})],1)]:_vm._e()],2)}),0)}),0)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }