<template>
  <div id="Checkout">
    <div :class="{ esperarTBK: boolEsperaTBK, 'd-none': !boolEsperaTBK }">
      <img
        src="@/assets/img/logo.png"
        width="250"
        alt="SurRealLogo"
        id="LogoLoadingReserva"
      />
      <p>Espere un momento</p>
    </div>
    <div class="checkout" v-if="checkout">
      <div class="contain">
        <h1 class="titulo">Checkout</h1>
      </div>
    </div>
    <div class="algoPaso" v-if="algoPaso">
      <div class="contain">
        <img
          src="@/assets/img/iconos/xGrande.svg"
          class="d-block m-auto pb-4"
        />
        <h1 class="titulo">{{ algopaso }}</h1>
        <div class="w-100 text-center">
          <button class="btn volverCheckout m-auto" @click="showPagoAceptado">
            {{ volverpago }}
          </button>
        </div>
      </div>
    </div>

    <div class="pagoAceptado" v-if="pagoAceptado">
      <div class="contain">
        <img
          src="@/assets/img/iconos/checkGrande.svg"
          class="d-block m-auto pb-sm-4"
        />
        <h1 class="titulo">{{ reservap }}</h1>
        <p class="text-white m-0 text-center">
          {{ recuerda1 }}
          <span class="d-sm-none d-inline">{{ recuerda2 }}</span>
          <span class="d-sm-inline d-none">
            <br />
            {{ recuerda2 }}
          </span>
        </p>
      </div>
    </div>
    <div class="text-center my-5" v-if="!reserva">
      <img
        src="@/assets/img/logo.png"
        width="250"
        alt="SurRealLogo"
        id="LogoLoadingReserva"
      />
      <p><em>Cargando datos</em></p>
    </div>

    <div class="tuOrden container pt-sm-2 pb-sm-5" v-if="checkout && reserva">
      <div class="py-sm-3">
        <Stepper />
      </div>
      <span
        class="azul font-weight-bold"
        :class="{ 'd-block': tuOrden, 'd-none': !tuOrden }"
        >{{ tuorden }}</span
      >

      <hr class="azul" :class="{ 'd-block': tuOrden, 'd-none': !tuOrden }" />
      <div
        v-if="reservaPagada"
        class="d-flex justify-content-center align-items-center my-5 py-5"
      >
        <h2>Su reservación ya ha sido pagada.</h2>
      </div>

      <div class="reservas" v-if="!reservaPagada">
        <table
          class="text-center my-3"
          :class="{ 'd-block': tuOrden, 'd-none': !tuOrden }"
        >
          <tr>
            <th>{{ producto }}</th>
            <!-- <th style="width: 100px">{{ precioNom }}</th>
            <th style="width: 100px">{{ cantidadNom }}</th>
            <th style="width: 100px">{{ totalNom }}</th> -->
          </tr>
          <tr>
            <td>
              <div class="row lista-experiencias">
                <router-link
                  to="#"
                  tag="div"
                  class="col-3 col-sm-4 px-0 px-sm-2 flex"
                  style="cursor: pointer"
                >
                  <img
                    :src="
                      getThumbnail({
                        contentUrl: imgExp.carouselImgUrl,
                        size: 480,
                      })
                    "
                    width="228"
                    height="190"
                    alt
                  />
                </router-link>
                <div class="col-7 pl-0 pl-sm-2 flex text-left">
                  <router-link
                    to="#"
                    tag="div"
                    class="p-2"
                    style="cursor: pointer"
                  >
                    <h2 class="titulos">
                      {{ reserva.experiencia }}
                    </h2>
                    <p>Fecha: {{ reserva.fecha }}</p>
                  </router-link>
                </div>
                <!-- <div class="d-sm-none col-2 d-flex align-items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    height="20px"
                    id="Layer_1"
                    style="enable-background: new 0 0 512 512"
                    version="1.1"
                    viewBox="0 0 512 512"
                    width="20px"
                    xml:space="preserve"
                    @click="deleteReserva({ index })"
                  >
                    <path
                      d="M443.6,387.1L312.4,255.4l131.5-130c5.4-5.4,5.4-14.2,0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4  L256,197.8L124.9,68.3c-2.6-2.6-6.1-4-9.8-4c-3.7,0-7.2,1.5-9.8,4L68,105.9c-5.4,5.4-5.4,14.2,0,19.6l131.5,130L68.4,387.1  c-2.6,2.6-4.1,6.1-4.1,9.8c0,3.7,1.4,7.2,4.1,9.8l37.4,37.6c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1L256,313.1l130.7,131.1  c2.7,2.7,6.2,4.1,9.8,4.1c3.5,0,7.1-1.3,9.8-4.1l37.4-37.6c2.6-2.6,4.1-6.1,4.1-9.8C447.7,393.2,446.2,389.7,443.6,387.1z"
                    />
                  </svg>
                </div> -->
              </div>
            </td>
            <!-- <td>
              <h6 class="font-weight-bold">
                $
                {{
                  (Number(reserva.total * 1000) / reserva.viajeros)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                }}
                CLP
              </h6>
            </td>
            <td>{{ reserva.viajeros }}</td>
            <td>
              <h6 class="font-weight-bold">$ {{ reserva.total }} CLP</h6>
            </td> -->
          </tr>
        </table>

        <div
          class="row container mb-3"
          :class="{ 'd-flex': tuOrden, 'd-none': !tuOrden }"
        >
          <div class="col-12 d-sm-none d-flex flex-column checkout-info">
            <div>
              <span>Fecha:</span>
              <span> {{ reserva.fecha }} </span>
            </div>
            <div>
              <span>Hora Inicio:</span>
              <span>{{ reserva.horaInicio }}</span>
            </div>
            <div>
              <span>Idioma:</span>
              <span>{{ reserva.idioma }} </span>
            </div>
            <div class="d-none">
              <span>Duración:</span>
              <span>3 días</span>
            </div>
            <div>
              <span>Viajeros:</span>
              <span>{{ reserva.viajeros }}</span>
            </div>
            <div v-if="reserva.detalleViajeros">
              <span>Detalle viajeros:</span>
              <span>
                <span v-if="reserva.detalleViajeros"
                  >{{ reserva.detalleViajeros.adultos.cantidad }} adultos</span
                >
                <span
                  v-if="reserva.detalleViajeros.niños && reserva.detalleViajeros.niños.length"
                  :style="{ fontWeight: '500' }"
                  >,
                  {{
                    reserva.detalleViajeros.niños
                      .map((n) => `${n.cantidad} ${n.nombre}`)
                      .toLocaleString()
                  }}</span
                ></span
              >
            </div>
            <hr />
          </div>
        </div>
      </div>

      <div class="row" v-if="!reservaPagada">
        <hr class="w-100 d-none d-sm-block" />
        <div
          v-bind:class="{ desaparecer: !checkout }"
          class="
            col-12 col-sm-6
            p-sm-1 p-3
            cupon-mobile
            d-flex
            align-items-center
          "
        ></div>
        <div
          class="col-12 col-sm-6 p-1 d-flex justify-content-end checkout-total"
        >
          <div>
            <div v-if="reserva && reserva.detalleViajeros">
              <div
                class="
                  d-flex
                  justify-content-between
                  w250
                  checkout-total__subtotal
                "
                style="fontsize: 14px"
              >
                <span>
                  <div>Adulto:</div>
                  <div style="fontsize: 0.8rem">
                    ({{ reserva.detalleViajeros.adultos.cantidad }} x ${{
                      reserva.detalleViajeros.adultos.precio
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }})
                  </div>
                </span>
                <span class="font-weight-bold"
                  >${{
                    reserva.detalleViajeros.adultos.total
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                  }}
                  CLP</span
                >
              </div>
            </div>
            <div v-else>
              <div
                class="
                  d-flex
                  justify-content-between
                  w250
                  checkout-total__subtotal
                "
                style="fontsize: 14px"
              >
                <span>
                  <div>Adultos:</div>
                  <div style="fontsize: 0.8rem">
                    ({{ reserva.viajeros }} x ${{
                      (reserva.total.replaceAll(".", "") / reserva.viajeros)
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }})
                  </div>
                </span>
                <span class="font-weight-bold"> ${{ reserva.total }} CLP </span>
              </div>
            </div>

            <template
              v-if="reserva.detalleViajeros && reserva.detalleViajeros.niños"
            >
              <div
                v-for="niño in reserva.detalleViajeros.niños"
                :key="niño.nombre"
                class="
                  d-flex
                  justify-content-between
                  w250
                  checkout-total__subtotal
                  pt-1 pt-sm-2
                "
                style="fontsize: 14px"
              >
                <span>
                  <div>{{ niño.nombre }}:</div>
                  <div style="fontsize: 0.8rem">
                    ({{ niño.cantidad }} x ${{
                      niño.precio
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }})
                  </div>
                </span>
                <span class="font-weight-bold"
                  >${{
                    niño.total
                      .toString()
                      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                  }}
                  CLP</span
                >
              </div>
            </template>
            <hr />
            <div
              class="
                d-flex
                justify-content-between
                w250
                checkout-total__subtotal
              "
            >
              <span>SubTotal:</span>
              <span class="font-weight-bold">$ {{ reserva.total }} CLP</span>
            </div>
            <div
              v-if="reserva.estado === 'Pendiente de primer pago'"
              class="
                d-flex
                justify-content-between
                w250
                checkout-total__subtotal
              "
            >
              <span>Abono:</span>
              <span class="font-weight-bold"
                >$ {{ reserva.abono.primeraCuota }} CLP</span
              >
            </div>
            <div v-if="reserva.estado === 'Pendiente de segundo pago'">
              <div
                class="
                  d-flex
                  justify-content-between
                  w250
                  checkout-total__subtotal
                "
              >
                <span>Pagado:</span>
                <span class="font-weight-bold"
                  >$ {{ reserva.abono.primeraCuota }} CLP</span
                >
              </div>
              <div
                class="
                  d-flex
                  justify-content-between
                  w250
                  checkout-total__subtotal
                "
              >
                <span>Monto restante:</span>
                <span class="font-weight-bold"
                  >$ {{ reserva.abono.segundaCuota }} CLP</span
                >
              </div>
            </div>
            <div
              class="d-flex justify-content-between w250 checkout-total__total"
            >
              <span>Total:</span>
              <span class="font-weight-bold">$ {{ reserva.total }} CLP</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Mensaje sobre abono o segundo pago -->
      <div
        class="avisoEstadoPago my-2 p-4 text-center"
        v-if="reserva.estado === 'Pendiente de primer pago'"
      >
        <h4>
          Reserva pagando sólo el {{ reserva.abono.porcentajeAbono }}% de tu
          total, es decir, ${{ reserva.abono.primeraCuota }}.
        </h4>
      </div>
      <div
        class="avisoEstadoPago my-2 p-4 text-center"
        v-if="reserva.estado === 'Pendiente de segundo pago'"
      >
        <h4>
          Ya abonaste ${{ reserva.abono.primeraCuota }} ({{
            reserva.abono.porcentajeAbono
          }}%), ahora pagarás el monto restante de ${{
            reserva.abono.segundaCuota
          }}.
        </h4>
      </div>

      <!-- Información del Pago -->
      <div
        id="formulario"
        class="mt-sm-2 pt-sm-5"
        v-if="checkout && !reservaPagada"
      >
        <div
          class="infoPago"
          v-if="pagar"
          :class="{ 'd-block': pagoBlock, 'd-none': !pagoBlock }"
        >
          <span class="azul font-weight-bold">{{ infopago }}</span>
          <!-- <span class="azul font-weight-bold"></span> -->

          <hr
            class="azul"
            :class="{ 'd-block': tuOrden, 'd-none': !tuOrden }"
          />

          <div class="formPago">
            <b-row>
              <b-col
                cols="12"
                md="6"
                class="flex-column justify-content-between"
              >
                <p class="w-100 text-right d-none">
                  <a href="#">{{ mediospago }}</a>
                </p>
                <div>
                  <img
                    class="d-block mx-auto mb-4 w-25 py-3 imgWebpay"
                    src="@/assets/img/MetodosPagos/transbank.png"
                    alt
                  />
                  <button
                    class="
                      btn btn-primary btn-block
                      rounded-pill
                      text-uppercase
                      py-3
                      font-weight-bold
                      text-white
                      my-2
                    "
                    type="submit"
                    @click.prevent="pagarReserva"
                  >
                    Pague aquí
                  </button>
                </div>
                <!-- action="https://webpay3g.transbank.cl:443/webpayserver/initTransaction" -->
                <div class="col-12 text-center">
                  <form
                    name="myForm3"
                    id="myForm3"
                    :action="urlPay"
                    method="post"
                  >
                    <input type="hidden" name="token_ws" :value="tokenInput" />
                  </form>
                </div>
              </b-col>
              <b-col
                cols="12"
                md="6"
                class="
                  flex-column
                  justify-content-between
                  mt-4 mt-md-0
                  mb-3 mb-md-0
                "
                :class="{ 'd-flex': !mostrarDatosTransferencia }"
              >
                <div
                  class="
                    title_transferencia
                    d-flex
                    justify-content-center
                    align-items-center
                  "
                >
                  <h5 class="text-center">Paga con Transferencia bancaria</h5>
                </div>
                <button
                  class="
                    btn btn-primary btn-block
                    rounded-pill
                    text-uppercase
                    py-3
                    font-weight-bold
                    text-white
                    my-2
                  "
                  @click="
                    mostrarDatosTransferencia = !mostrarDatosTransferencia
                  "
                >
                  {{ mostrarDatosTransferencia ? "Ocultar" : "Mostrar" }} datos
                </button>
              </b-col>
              <b-col
                cols="12"
                md="6"
                offset-md="6"
                v-if="mostrarDatosTransferencia"
              >
                <p><b>Nombre:</b> SurrealTravel SpA</p>
                <p><b>RUT:</b> 77.122.079-7</p>
                <p><b>Tipo de cuenta:</b> Cuenta corriente</p>
                <p><b>N° Cuenta:</b> 00-801-20224-08</p>
                <p><b>Banco:</b> Banco de Chile</p>
                <p><b>Correo:</b> reservas@surreal.travel</p>
                <p><b>Monto a pagar:</b> ${{ montoAPagar }}</p>
                <br />
                <h6>
                  No olvides enviar tu comprobante de pago al correo
                  <b>reservas@surreal.travel</b>
                </h6>
              </b-col>
            </b-row>

            <div class="row d-none">
              <div class="col-12 col-sm-6 left infoTitular">
                <span class="h5 p-2">{{ infotitular }}</span>
                <div class="col-12 p-2">
                  <span>{{ nombre }}</span>

                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 p-2">
                  <span>{{ direccion }}</span>

                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 row">
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ ciudad }}</span>
                      <input type="text" name id class="form-control" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ postal }}</span>

                      <input type="text" class="form-control" />
                    </div>
                  </div>
                </div>
                <div class="col-12 p-2">
                  <span class="d-sm-none d-inline">País</span>
                  <select name class="form-control" id>
                    <option value disabled selected>
                      {{ seleccionapais }}
                    </option>
                    <option value="Afganistan">Afghanistan</option>
                    <option value="Albania">Albania</option>
                    <option value="Algeria">Algeria</option>
                    <option value="American Samoa">American Samoa</option>
                    <option value="Andorra">Andorra</option>
                    <option value="Angola">Angola</option>
                    <option value="Anguilla">Anguilla</option>
                    <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                    <option value="Argentina">Argentina</option>
                    <option value="Armenia">Armenia</option>
                    <option value="Aruba">Aruba</option>
                    <option value="Australia">Australia</option>
                    <option value="Austria">Austria</option>
                    <option value="Azerbaijan">Azerbaijan</option>
                    <option value="Bahamas">Bahamas</option>
                    <option value="Bahrain">Bahrain</option>
                    <option value="Bangladesh">Bangladesh</option>
                    <option value="Barbados">Barbados</option>
                    <option value="Belarus">Belarus</option>
                    <option value="Belgium">Belgium</option>
                    <option value="Belize">Belize</option>
                    <option value="Benin">Benin</option>
                    <option value="Bermuda">Bermuda</option>
                    <option value="Bhutan">Bhutan</option>
                    <option value="Bolivia">Bolivia</option>
                    <option value="Bonaire">Bonaire</option>
                    <option value="Bosnia & Herzegovina">
                      Bosnia & Herzegovina
                    </option>
                    <option value="Botswana">Botswana</option>
                    <option value="Brazil">Brazil</option>
                    <option value="British Indian Ocean Ter">
                      British Indian Ocean Ter
                    </option>
                    <option value="Brunei">Brunei</option>
                    <option value="Bulgaria">Bulgaria</option>
                    <option value="Burkina Faso">Burkina Faso</option>
                    <option value="Burundi">Burundi</option>
                    <option value="Cambodia">Cambodia</option>
                    <option value="Cameroon">Cameroon</option>
                    <option value="Canada">Canada</option>
                    <option value="Canary Islands">Canary Islands</option>
                    <option value="Cape Verde">Cape Verde</option>
                    <option value="Cayman Islands">Cayman Islands</option>
                    <option value="Central African Republic">
                      Central African Republic
                    </option>
                    <option value="Chad">Chad</option>
                    <option value="Channel Islands">Channel Islands</option>
                    <option selected value="Chile">Chile</option>
                    <option value="China">China</option>
                    <option value="Christmas Island">Christmas Island</option>
                    <option value="Cocos Island">Cocos Island</option>
                    <option value="Colombia">Colombia</option>
                    <option value="Comoros">Comoros</option>
                    <option value="Congo">Congo</option>
                    <option value="Cook Islands">Cook Islands</option>
                    <option value="Costa Rica">Costa Rica</option>
                    <option value="Cote DIvoire">Cote DIvoire</option>
                    <option value="Croatia">Croatia</option>
                    <option value="Cuba">Cuba</option>
                    <option value="Curaco">Curacao</option>
                    <option value="Cyprus">Cyprus</option>
                    <option value="Czech Republic">Czech Republic</option>
                    <option value="Denmark">Denmark</option>
                    <option value="Djibouti">Djibouti</option>
                    <option value="Dominica">Dominica</option>
                    <option value="Dominican Republic">
                      Dominican Republic
                    </option>
                    <option value="East Timor">East Timor</option>
                    <option value="Ecuador">Ecuador</option>
                    <option value="Egypt">Egypt</option>
                    <option value="El Salvador">El Salvador</option>
                    <option value="Equatorial Guinea">Equatorial Guinea</option>
                    <option value="Eritrea">Eritrea</option>
                    <option value="Estonia">Estonia</option>
                    <option value="Ethiopia">Ethiopia</option>
                    <option value="Falkland Islands">Falkland Islands</option>
                    <option value="Faroe Islands">Faroe Islands</option>
                    <option value="Fiji">Fiji</option>
                    <option value="Finland">Finland</option>
                    <option value="France">France</option>
                    <option value="French Guiana">French Guiana</option>
                    <option value="French Polynesia">French Polynesia</option>
                    <option value="French Southern Ter">
                      French Southern Ter
                    </option>
                    <option value="Gabon">Gabon</option>
                    <option value="Gambia">Gambia</option>
                    <option value="Georgia">Georgia</option>
                    <option value="Germany">Germany</option>
                    <option value="Ghana">Ghana</option>
                    <option value="Gibraltar">Gibraltar</option>
                    <option value="Great Britain">Great Britain</option>
                    <option value="Greece">Greece</option>
                    <option value="Greenland">Greenland</option>
                    <option value="Grenada">Grenada</option>
                    <option value="Guadeloupe">Guadeloupe</option>
                    <option value="Guam">Guam</option>
                    <option value="Guatemala">Guatemala</option>
                    <option value="Guinea">Guinea</option>
                    <option value="Guyana">Guyana</option>
                    <option value="Haiti">Haiti</option>
                    <option value="Hawaii">Hawaii</option>
                    <option value="Honduras">Honduras</option>
                    <option value="Hong Kong">Hong Kong</option>
                    <option value="Hungary">Hungary</option>
                    <option value="Iceland">Iceland</option>
                    <option value="Indonesia">Indonesia</option>
                    <option value="India">India</option>
                    <option value="Iran">Iran</option>
                    <option value="Iraq">Iraq</option>
                    <option value="Ireland">Ireland</option>
                    <option value="Isle of Man">Isle of Man</option>
                    <option value="Israel">Israel</option>
                    <option value="Italy">Italy</option>
                    <option value="Jamaica">Jamaica</option>
                    <option value="Japan">Japan</option>
                    <option value="Jordan">Jordan</option>
                    <option value="Kazakhstan">Kazakhstan</option>
                    <option value="Kenya">Kenya</option>
                    <option value="Kiribati">Kiribati</option>
                    <option value="Korea North">Korea North</option>
                    <option value="Korea Sout">Korea South</option>
                    <option value="Kuwait">Kuwait</option>
                    <option value="Kyrgyzstan">Kyrgyzstan</option>
                    <option value="Laos">Laos</option>
                    <option value="Latvia">Latvia</option>
                    <option value="Lebanon">Lebanon</option>
                    <option value="Lesotho">Lesotho</option>
                    <option value="Liberia">Liberia</option>
                    <option value="Libya">Libya</option>
                    <option value="Liechtenstein">Liechtenstein</option>
                    <option value="Lithuania">Lithuania</option>
                    <option value="Luxembourg">Luxembourg</option>
                    <option value="Macau">Macau</option>
                    <option value="Macedonia">Macedonia</option>
                    <option value="Madagascar">Madagascar</option>
                    <option value="Malaysia">Malaysia</option>
                    <option value="Malawi">Malawi</option>
                    <option value="Maldives">Maldives</option>
                    <option value="Mali">Mali</option>
                    <option value="Malta">Malta</option>
                    <option value="Marshall Islands">Marshall Islands</option>
                    <option value="Martinique">Martinique</option>
                    <option value="Mauritania">Mauritania</option>
                    <option value="Mauritius">Mauritius</option>
                    <option value="Mayotte">Mayotte</option>
                    <option value="Mexico">Mexico</option>
                    <option value="Midway Islands">Midway Islands</option>
                    <option value="Moldova">Moldova</option>
                    <option value="Monaco">Monaco</option>
                    <option value="Mongolia">Mongolia</option>
                    <option value="Montserrat">Montserrat</option>
                    <option value="Morocco">Morocco</option>
                    <option value="Mozambique">Mozambique</option>
                    <option value="Myanmar">Myanmar</option>
                    <option value="Nambia">Nambia</option>
                    <option value="Nauru">Nauru</option>
                    <option value="Nepal">Nepal</option>
                    <option value="Netherland Antilles">
                      Netherland Antilles
                    </option>
                    <option value="Netherlands">
                      Netherlands (Holland, Europe)
                    </option>
                    <option value="Nevis">Nevis</option>
                    <option value="New Caledonia">New Caledonia</option>
                    <option value="New Zealand">New Zealand</option>
                    <option value="Nicaragua">Nicaragua</option>
                    <option value="Niger">Niger</option>
                    <option value="Nigeria">Nigeria</option>
                    <option value="Niue">Niue</option>
                    <option value="Norfolk Island">Norfolk Island</option>
                    <option value="Norway">Norway</option>
                    <option value="Oman">Oman</option>
                    <option value="Pakistan">Pakistan</option>
                    <option value="Palau Island">Palau Island</option>
                    <option value="Palestine">Palestine</option>
                    <option value="Panama">Panama</option>
                    <option value="Papua New Guinea">Papua New Guinea</option>
                    <option value="Paraguay">Paraguay</option>
                    <option value="Peru">Peru</option>
                    <option value="Phillipines">Philippines</option>
                    <option value="Pitcairn Island">Pitcairn Island</option>
                    <option value="Poland">Poland</option>
                    <option value="Portugal">Portugal</option>
                    <option value="Puerto Rico">Puerto Rico</option>
                    <option value="Qatar">Qatar</option>
                    <option value="Republic of Montenegro">
                      Republic of Montenegro
                    </option>
                    <option value="Republic of Serbia">
                      Republic of Serbia
                    </option>
                    <option value="Reunion">Reunion</option>
                    <option value="Romania">Romania</option>
                    <option value="Russia">Russia</option>
                    <option value="Rwanda">Rwanda</option>
                    <option value="St Barthelemy">St Barthelemy</option>
                    <option value="St Eustatius">St Eustatius</option>
                    <option value="St Helena">St Helena</option>
                    <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                    <option value="St Lucia">St Lucia</option>
                    <option value="St Maarten">St Maarten</option>
                    <option value="St Pierre & Miquelon">
                      St Pierre & Miquelon
                    </option>
                    <option value="St Vincent & Grenadines">
                      St Vincent & Grenadines
                    </option>
                    <option value="Saipan">Saipan</option>
                    <option value="Samoa">Samoa</option>
                    <option value="Samoa American">Samoa American</option>
                    <option value="San Marino">San Marino</option>
                    <option value="Sao Tome & Principe">
                      Sao Tome & Principe
                    </option>
                    <option value="Saudi Arabia">Saudi Arabia</option>
                    <option value="Senegal">Senegal</option>
                    <option value="Seychelles">Seychelles</option>
                    <option value="Sierra Leone">Sierra Leone</option>
                    <option value="Singapore">Singapore</option>
                    <option value="Slovakia">Slovakia</option>
                    <option value="Slovenia">Slovenia</option>
                    <option value="Solomon Islands">Solomon Islands</option>
                    <option value="Somalia">Somalia</option>
                    <option value="South Africa">South Africa</option>
                    <option value="Spain">Spain</option>
                    <option value="Sri Lanka">Sri Lanka</option>
                    <option value="Sudan">Sudan</option>
                    <option value="Suriname">Suriname</option>
                    <option value="Swaziland">Swaziland</option>
                    <option value="Sweden">Sweden</option>
                    <option value="Switzerland">Switzerland</option>
                    <option value="Syria">Syria</option>
                    <option value="Tahiti">Tahiti</option>
                    <option value="Taiwan">Taiwan</option>
                    <option value="Tajikistan">Tajikistan</option>
                    <option value="Tanzania">Tanzania</option>
                    <option value="Thailand">Thailand</option>
                    <option value="Togo">Togo</option>
                    <option value="Tokelau">Tokelau</option>
                    <option value="Tonga">Tonga</option>
                    <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                    <option value="Tunisia">Tunisia</option>
                    <option value="Turkey">Turkey</option>
                    <option value="Turkmenistan">Turkmenistan</option>
                    <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                    <option value="Tuvalu">Tuvalu</option>
                    <option value="Uganda">Uganda</option>
                    <option value="United Kingdom">United Kingdom</option>
                    <option value="Ukraine">Ukraine</option>
                    <option value="United Arab Erimates">
                      United Arab Emirates
                    </option>
                    <option value="United States of America">
                      United States of America
                    </option>
                    <option value="Uraguay">Uruguay</option>
                    <option value="Uzbekistan">Uzbekistan</option>
                    <option value="Vanuatu">Vanuatu</option>
                    <option value="Vatican City State">
                      Vatican City State
                    </option>
                    <option value="Venezuela">Venezuela</option>
                    <option value="Vietnam">Vietnam</option>
                    <option value="Virgin Islands (Brit)">
                      Virgin Islands (Brit)
                    </option>
                    <option value="Virgin Islands (USA)">
                      Virgin Islands (USA)
                    </option>
                    <option value="Wake Island">Wake Island</option>
                    <option value="Wallis & Futana Is">
                      Wallis & Futana Is
                    </option>
                    <option value="Yemen">Yemen</option>
                    <option value="Zaire">Zaire</option>
                    <option value="Zambia">Zambia</option>
                    <option value="Zimbabwe">Zimbabwe</option>
                  </select>
                </div>
              </div>
              <div class="col-12 col-sm-6 right infoTarjeta">
                <span class="h5 p-2">{{ infocard }}</span>

                <div class="col-12 p-2">
                  <span>{{ creditcard }}</span>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 p-2">
                  <span>{{ nombre }}</span>
                  <input type="text" class="form-control" />
                </div>
                <div class="col-12 row">
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ venc }}</span>
                      <input type="text" name id class="form-control" />
                    </div>
                  </div>
                  <div class="col-12 col-sm-6 p-2">
                    <div class>
                      <span>{{ codecard }}</span>
                      <input type="text" name id class="form-control" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Vue from "vue";
import { mapState, mapGetters, mapActions, mapMutations } from "vuex";
import firebase from "firebase";
import { db, storage } from "../firebase.service";
import axios from "axios";
import Stepper from "@/components/Stepper";

export default {
  name: "CheckoutResPorConfirmar",
  metaInfo: {
    title: "Pago",
  },
  components: { Stepper },
  data() {
    return {
      checkout: true,
      algoPaso: false,
      pagoAceptado: false,
      tuOrden: true,
      pagoBlock: true,
      pagar: true,
      tokenInput: "",
      boolEsperaTBK: false,
      reservaPagada: false,
      mostrarDatosTransferencia: false,
      urlPay: ''
    };
  },
  created() {
    this.getReservas();
  },
  computed: {
    ...mapGetters(["getExperiencias"]),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapState("reservaciones", ["reservacionesPorConfirmar"]),
    ...mapGetters("Checkout", [
      "tuorden",
      "producto",
      "precioNom",
      "cantidadNom",
      "totalNom",
      "fecha",
      "aplicar",
      "pasajero",
      "nombre",
      "apellido",
      "email",
      "telefono",
      "dhotel",
      "comentarios",
      "sanitarias",
      "hear",
      "direccion",
      "ciudad",
      "pais",
      "promociones",
      "selecciona",
      "seleccionapais",
      "infopago",
      "cargado",
      "mediospago",
      "infotitular",
      "postal",
      "infocard",
      "creditcard",
      "venc",
      "codecard",
      "terms1",
      "terms2",
      "procesar",
      "volverpago",
      "reservap",
      "recuerda1",
      "recuerda2",
    ]),
    reserva() {
      const id = this.$route.params.id;
      if (!id) return false;
      const finder = this.reservacionesPorConfirmar.find(
        (el) => el.idUnico === id
      );
      if (!finder) return false;
      if (finder.estado === "Pagado") this.reservaPagada = true;
      return finder;
    },
    imgExp() {
      const titulo = this.reserva.experiencia;
      if (!titulo) return false;
      const exp = this.getExperiencias.find(
        (experiencia) => experiencia.titulo === titulo
      );
      if (!exp) return false;
      return exp;
    },
    montoAPagar() {
      const estado = this.reserva.estado;
      const abono = this.reserva.abono;
      let amount = 0;
      if (estado === "Pendiente de primer pago")
        amount = abono.primeraCuota.replace(".", "");
      else if (estado === "Pendiente de segundo pago")
        amount = abono.segundaCuota.replace(".", "");
      else amount = this.reserva.total.replace(".", "");

      return amount.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
  },
  methods: {
    ...mapActions("reservaciones", [
      "getReservas",
      "solicitarReserva",
      "tokenRePorConfirmar",
    ]),
    showalgoPaso() {
      this.checkout = false;
      this.algoPaso = true;
      this.tuOrden = true;
      window.scrollTo(0, 0);
    },
    showPagoAceptado() {
      this.checkout = false;
      this.algoPaso = false;
      this.pagoAceptado = true;
    },
    experiencia({ id }) {
      return this.getExperiencias.find((experiencia) => experiencia.id === id);
    },
    async pagarReserva() {
      // Añadir animación de espera de token.
      this.boolEsperaTBK = true;

      // Solicitar Token a TBK
      await this.getTokenTBK();
      // Payload para actualizar token en Firebase y Vuex.
      const id = this.reserva.id;
      const token = this.tokenInput;
      const tokensPago = this.reserva.tokensPago ? this.reserva.tokensPago : [];

      if (!id || !token) return;

      const payload = {
        id,
        token,
        tokensPago,
        reserva: this.reserva,
      };
      await this.tokenRePorConfirmar(payload);
      // Hacer submit del formulario
      await document.forms["myForm3"].submit();
      // Quitar animación de espera de pasarela de pago.
      setTimeout(() => {
        this.boolEsperaTBK = false;
      }, 1000);
    },
    async getTokenTBK() {
      const url = 
        // "https://surrealtravel-api.herokuapp.com/getToken"
        // "http://127.0.0.1:5001/transbank-7f756/us-central1/listenTBK_State/getToken";
        "https://us-central1-sur-real.cloudfunctions.net/listenTBK_State/getToken";
      const estado = this.reserva.estado;
      const abono = this.reserva.abono;
      let amount = 0;
      if (estado === "Pendiente de primer pago")
        amount = abono.primeraCuota.replaceAll(".", "");
      else if (estado === "Pendiente de segundo pago")
        amount = abono.segundaCuota.replaceAll(".", "");
      else amount = this.reserva.total.replaceAll(".", "");

      const id = this.$route.params.id;

      if (!amount || !id) return;

      const getToken = await axios.get(url, {
        params: {
          amount,
          id,
        },
      });
      const tokenData = getToken.data.token;
      this.tokenInput = tokenData;

      this.urlPay = getToken.data.url
      // console.log(tokenData, this.tokenInput);
      // return tokenData;
    },
  },
};
</script>

<style lang="scss">
#Checkout {
  .hidden {
    opacity: 0;
  }

  .titulos {
    font-weight: bold;
    color: #343434;
  }

  .lista-experiencias {
    @media (max-width: 524px) {
      padding: 0px 32px;
    }
  }

  color: #868686;
  position: relative;

  .font-weight-bold {
    color: #343434;
  }

  .checkout {
    background: #3d99ff;
    padding: 30px;

    @media screen and (max-width: 524px) {
      padding: 35px 0;
      height: 100px;
    }

    .contain {
      h1 {
        @media screen and (max-width: 524px) {
          font-size: 25px;
          font-weight: bold;
          line-height: 28px;
          margin: 0 auto;
        }
      }
    }
  }

  .tuOrden {
    @media screen and (max-width: 524px) {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .row {
      @media screen and (max-width: 524px) {
        margin-left: 0;
        margin-right: 0;
      }
    }

    & > span {
      @media screen and (max-width: 524px) {
        display: block;
        background-color: #ededed;
        color: #4a4a4a;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 12px 36px 10px;
      }
    }

    & > hr {
      padding: 0.5px;
      background: #3d99ff;

      @media screen and (max-width: 524px) {
        margin-top: 0;
        width: 100%;
        background: #ededed;
        border-top: #ededed 1px solid;
      }
    }

    & table {
      tr:nth-child(1) {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      img {
        @media screen and (max-width: 524px) {
          width: 69px;
          height: 76px;
        }
      }

      h2 {
        font-size: 25px;

        @media screen and (max-width: 524px) {
          font-size: 14px;
          line-height: 18px;
          color: #4a4a4a;
          text-align: left;
        }
      }

      .X {
        color: #ff0031;
        text-transform: uppercase;

        @media screen and (max-width: 524px) {
          color: #353535;
          font-size: 14px;
        }
      }

      tr:nth-child(2) td:nth-child(1) p {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      tr:nth-child(2) td:nth-child(n + 2) {
        @media screen and (max-width: 524px) {
          display: none;
        }
      }

      .row-titulo {
        @media screen and (max-width: 524px) {
          justify-content: space-evenly;
        }
      }

      .col-titulo {
        @media screen and (max-width: 524px) {
          padding-left: 0;
          padding-right: 0;
        }
      }
    }

    button.ir-pago {
      border-radius: 11px;
      font-size: 18px;
      line-height: 22px;
      font-weight: 400;
      padding: 13px 0;
      margin-bottom: 20px;
    }
  }

  .cupon-mobile {
    position: relative;

    &-btn {
      position: absolute;
      right: 35px;
      top: 10px;
      font-size: 34px;
    }
  }

  .checkout-info {
    padding: 5px 20px;

    > div {
      display: flex;
      flex-flow: row;
      justify-content: space-between;

      span {
        font-size: 12px;
        font-weight: 400;
        color: #4a4a4a;
        opacity: 0.9;
        line-height: 25px;
        text-align: left;

        & + span {
          font-weight: 500;
        }
      }
    }
  }

  .checkout-total {
    @media screen and (max-width: 524px) {
      padding-right: 20px !important;
    }

    &__subtotal {
      span:nth-child(1) {
        @media screen and (max-width: 524px) {
          font-size: 12px;
          font-weight: 400;
          line-height: 28px;
          color: #4a4a4a;
        }
      }

      span:nth-child(2) {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          font-weight: 400 !important;
          line-height: 28px;
          color: #4a4a4a;
        }
      }
    }

    &__total {
      span:nth-child(1) {
        @media screen and (max-width: 524px) {
          font-size: 16px;
          font-weight: 400;
          line-height: 28px;
          color: #4a4a4a;
        }
      }

      span:nth-child(2) {
        @media screen and (max-width: 524px) {
          font-size: 16px;
          font-weight: 700;
          line-height: 28px;
          color: #4a4a4a;
        }
      }
    }
  }

  .w250 {
    width: 250px;
  }

  .formPago {
    img {
      @media screen and (max-width: 524px) {
        width: 146px;
      }

      // cursor: pointer;
    }
    .imgWebpay,
    .title_transferencia {
      height: 100px;
      vertical-align: middle;
      box-sizing: border-box;
    }
  }

  .algoPaso {
    background: #ff4141;
    padding: 30px;

    @media screen and (max-width: 524px) {
      height: 290px;
    }

    img {
      @media screen and (max-width: 524px) {
        height: 100px;
        width: 100px;
      }
    }

    h1.titulo {
      color: white;
      font-size: 40px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 40px 24px;
      }
    }
  }

  .pagoAceptado {
    background: #3d99ff;
    padding: 30px;

    @media screen and (max-width: 524px) {
      height: 290px;
    }

    img {
      @media screen and (max-width: 524px) {
        height: 100px;
        width: 100px;
      }
    }

    h1.titulo {
      color: white;
      font-size: 40px;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
        margin-bottom: 0;
        padding: 0 60px 12px;
      }
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      padding: 0 7px;
    }
  }

  .titulo {
    color: white;
    font-size: 40px;
    text-align: center;
    font-weight: bold;
  }

  .azul {
    color: #3d99ff;
  }

  input,
  select {
    border-radius: 6px;
    height: 50px;

    @media screen and (max-width: 524px) {
      height: 40px;
    }
  }

  .cupon,
  .Procesar {
    background: #3d99ff;
    border-radius: 6px;
    font-size: 14px;
    height: 50px;
    width: 170px;
    color: white;
  }

  .volverCheckout {
    background: transparent;
    border: groove white;
    border-radius: 6px !important;
    color: white;

    @media screen and (max-width: 524px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      border: solid 2px #ffffff;
      padding: 15px 36px;
    }
  }

  .left,
  .right {
    div {
      @media screen and (max-width: 524px) {
        height: auto;
      }
    }
  }

  .left {
    @media screen and (max-width: 524px) {
      padding-right: 14px !important;
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    div {
      @media screen and (max-width: 524px) {
        margin-left: 0 !important;
        padding-left: 0 !important;
        padding-right: 0 !important;
      }
    }
  }

  .right {
    @media screen and (max-width: 524px) {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    div {
      @media screen and (max-width: 524px) {
        padding-left: 0 !important;
        padding-right: 0 !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
      }
    }
  }

  .desaparecer {
    opacity: 0;

    @media screen and (max-width: 524px) {
      display: none;
    }
  }
}
#reservaPorConfirmar {
  border: #3d99ff solid 1px;
  border-radius: 15px;
  color: #ffffff;
  background-color: #3d99ff;
  display: flex;
  flex-flow: wrap column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}
#formulario {
  & > span {
    @media screen and (max-width: 524px) {
      display: block;
      background-color: #ededed;
      color: #4a4a4a !important;
      font-size: 14px;
      line-height: 18px;
      text-align: left;
      padding: 12px 36px 10px;
    }
  }

  & > hr {
    padding: 0.5px;
    background: #3d99ff;

    @media screen and (max-width: 524px) {
      margin-top: 0;
      width: 100%;
      background: #ededed;
      border-top: #ededed 1px solid;
    }
  }

  .formulario {
    @media screen and (max-width: 524px) {
      padding: 0 15px 0 35px;
    }

    span {
      @media screen and (max-width: 524px) {
        font-size: 14px;
      }
    }
  }

  .infoPago {
    & > span {
      @media screen and (max-width: 524px) {
        display: block;
        background-color: #ededed;
        color: #4a4a4a !important;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        padding: 12px 36px 10px;
      }
    }

    & > hr {
      padding: 0.5px;
      background: #3d99ff;

      @media screen and (max-width: 524px) {
        margin-top: 0;
        width: 100%;
        background: #ededed;
        border-top: #ededed 1px solid;
      }
    }

    & > p {
      font-size: 16px !important;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: 400 !important;
        line-height: 20px;
        padding: 12px 36px;
      }
    }

    .formPago {
      @media screen and (max-width: 524px) {
        width: 100%;
        padding: 0 35px;
      }

      > p {
        @media screen and (max-width: 524px) {
          text-align: center !important;
          font-size: 12px;
          font-weight: 400;
          text-decoration: underline;
        }

        a {
          color: #3d99ff !important;
        }
      }

      > div:nth-child(1) {
        margin-left: -10px;
      }

      .infoTitular {
        @media screen and (max-width: 524px) {
          padding-left: 14px !important;
        }

        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }

      .infoTarjeta {
        & > span {
          @media screen and (max-width: 524px) {
            display: none;
          }
        }

        @media screen and (max-width: 524px) {
          padding-left: 15px !important;
        }

        span {
          @media screen and (max-width: 524px) {
            font-size: 14px;
          }
        }
      }
    }

    .terminos {
      span {
        @media screen and (max-width: 524px) {
          font-size: 14px;
          margin-left: 5px;
        }
      }

      .Procesar {
        background: white;
        border: solid 2px #3d99ff;
        color: #3d99ff;

        @media screen and (max-width: 524px) {
          font-size: 18px;
          font-weight: 400;
          background: #3d99ff;
          border: 0;
          border-radius: 11px;
          color: #ffffff;
          display: block;
          margin: 40px auto;
          width: 100%;
        }
      }
    }
  }
}
#LogoLoadingReserva {
  animation: titilar 1s infinite;
  animation-timing-function: linear;
}
@keyframes titilar {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
.esperarTBK {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: #ffffff;
  z-index: 99999999;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  opacity: 0.9;
  p {
    color: #212529;
    margin-top: 2.5%;
    font-size: 1.35rem;
    font-weight: 500;
    font-style: italic;
  }
}
.avisoEstadoPago {
  background-color: #353535;
  border-radius: 15px;
  color: #fff;
  h4 {
    font-size: 16px;
  }
  @media (min-width: 524px) {
    h4 {
      font-size: 20px;
    }
  }
}
</style>
