<template>
  <vc-date-picker
    ref="calendar"
    :mode="selectMode"
    :attributes="attributes"
    is-inline
    :min-date="minDate"
    :value="reserva.disponibilidad"
    @dayclick="singleClickSelect"
    :disabled-dates="disabledDates"
    id="CalendarioComponente"
    :locale="language"
  >
  </vc-date-picker>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
export default {
  data() {
    return {
      range: null,
      value: "",
      selectMode: "range",
      calendar: null,
      minDate: new Date(),
      calendarMoved: false,
    };
  },
  props: ["experiencia"],
  computed: {
    ...mapGetters(["reserva", "language"]),
    attributes() {
      return [
        {
          key: "today",
          highlight: true,
          dates: this.reserva.disponibilidad,
        },
      ];
    },
    fueraDeTemporadaDates() {
      // Funcion que retorna los rangos de fechas que deben desabilitarse
      const disabledDates = [];
      if (
        this.experiencia.disponibilidades &&
        this.experiencia.disponibilidades.length > 0
      ) {
        const sortedDisponibilidades = [...this.experiencia.disponibilidades];

        sortedDisponibilidades.sort(
          (a, b) => new Date(a.start).getTime() - new Date(b.start).getTime()
        );

        let lastEnd;
        let lastStart;
        const dayToMiliseconds = 1000 * 60 * 60 * 24; // Conversion de dia a milisegundos

        sortedDisponibilidades.forEach((disponibilidad, index) => {
          if (index === 0) {
            disabledDates.push({
              end: new Date(
                new Date(disponibilidad.start).getTime() - 1 * dayToMiliseconds
              ),
            });
            lastEnd = new Date(disponibilidad.end);
            lastStart = new Date(disponibilidad.start);
          } else if (lastEnd && lastStart) {
            const dayDifference =
              new Date(disponibilidad.start).getTime() - lastStart.getTime();

            if (
              dayDifference / dayToMiliseconds >
              this.experiencia.cantidadDias - 1
            ) {
              disabledDates.push({
                start: new Date(lastEnd.getTime() + 1 * dayToMiliseconds),
                end: new Date(
                  new Date(disponibilidad.start).getTime() -
                    1 * dayToMiliseconds
                ),
              });
            }
            lastStart = new Date(disponibilidad.start);
            lastEnd = new Date(disponibilidad.end);
          }
        });

        disabledDates.push({
          start: new Date(lastEnd.getTime() + 1 * dayToMiliseconds),
        });
      }

      return disabledDates;
    },
    disabledDates() {
      if (
        this.experiencia.modalidad === "rangos" ||
        this.experiencia.modalidad === "rangosTemporada"
      ) {
        const disabledDates = [...this.fueraDeTemporadaDates];
        if (disabledDates.length > 0 && this.calendar && !this.calendarMoved) {
          const { end } = disabledDates[0];
          const startDay = new Date(end.getTime() + 1 * (1000 * 60 * 60 * 24));
          if (startDay.getTime() > new Date().getTime()) {
            this.moveToMonth(startDay);
          }
        }

        return disabledDates;
      }
      if (this.experiencia.modalidad === "fechaExclusiva") {
        const disabledDates = [
          {
            start: null,
            end: new Date(
              new Date(this.experiencia.fechaExclusiva).getTime() -
                1 * (1000 * 60 * 60 * 24)
            ),
          },
          {
            start: new Date(
              new Date(this.experiencia.fechaExclusiva).getTime() +
                1 * (1000 * 60 * 60 * 24)
            ),
            end: null,
          },
        ];
        this.reservaHandleChange({
          name: "disponibilidad",
          value: new Date(this.experiencia.fechaExclusiva),
        });
        if (
          this.experiencia.fechaExclusiva &&
          this.calendar &&
          !this.calendarMoved
        ) {
          const { fechaExclusiva } = this.experiencia;
          const date = new Date(fechaExclusiva);
          if (date.getTime() > new Date().getTime()) {
            this.moveToMonth(date);
          }
        }
        return disabledDates;
      }
      if (this.experiencia.modalidad === "diasSemana") {
        const dias = [0, 1, 2, 3, 4, 5, 6];
        const disabledDaysArray = dias.filter(
          (dia) => !this.experiencia.disponibilidad.includes(dia)
        );
        const disableDatesArray = disabledDaysArray.map((day) => day + 1);
        const disabledWeekDays = {
          weekdays: [...disableDatesArray],
        };

        const disabledDates = [{ ...disabledWeekDays }];
        if (
          this.experiencia.disponibilidades &&
          this.experiencia.disponibilidades.length > 0
        ) {
          if (
            this.fueraDeTemporadaDates.length > 0 &&
            this.calendar &&
            !this.calendarMoved
          ) {
            const { end } = this.fueraDeTemporadaDates[0];
            const startDay = new Date(
              end.getTime() + 1 * (1000 * 60 * 60 * 24)
            );
            if (startDay.getTime() > new Date().getTime()) {
              this.moveToMonth(startDay);
            }
          }
          disabledDates.push(...this.fueraDeTemporadaDates);
        }
        return disabledDates;
      }
      return null;
    },
  },
  methods: {
    ...mapActions(["reservaHandleChange"]),
    ...mapMutations(["resetReserva"]),
    dateClass(ymd, date) {
      const day = date.getDate();
      return day >= 27 && day <= 30 ? "table-info" : "";
    },
    singleClickSelect(value) {
      // Toma el primer click en rangos de dias
      this.handleDate(value.date);
      this.calendar.dragValue = null;
    },
    moveToMonth(date) {
      const { calendar } = this.calendar.$refs;
      calendar.move(date).then(() => {
        // console.log('Navegacion en calendario ejecutada')
      });
      this.calendarMoved = true;
    },
    handleDate(value) {
      let start;
      let end;
      if (value.start && value.end) {
        start = value.start;
        end = value.end;
      } else {
        start = value;
        end = value;
      }

      if (this.experiencia.modalidad === "rangos") {
        const startToNumber = new Date(start).getTime();
        const endToNumber = new Date(end).getTime();
        const disponibilidad = this.experiencia.disponibilidades.find(
          (disponibilidad) => {
            return (
              startToNumber >= new Date(disponibilidad.start).getTime() &&
              new Date(disponibilidad.end).getTime() >= endToNumber
            );
          }
        );
        if (disponibilidad) {
          this.range = disponibilidad;
          const disponibilidadDate = {
            start: new Date(disponibilidad.start),
            end: new Date(disponibilidad.end),
          };
          this.reservaHandleChange({
            name: "disponibilidad",
            value: disponibilidadDate,
          });
        }
      }

      if (this.experiencia.modalidad === "rangosTemporada") {
        const startToNumber = new Date(start).getTime();
        const endToNumber = new Date(end).getTime();
        const disponibilidad = this.experiencia.disponibilidades.find(
          (disponibilidad) => {
            if (
              startToNumber >= new Date(disponibilidad.start).getTime() &&
              new Date(disponibilidad.end).getTime() >= endToNumber
            ) {
              const min = new Date(start).getTime();
              const { cantidadDias } = this.experiencia;
              // Si la cantidad de dias es cero, no restar nada para calcular el rango de seleccion.
              const dias = cantidadDias > 0 ? cantidadDias - 1 : cantidadDias;
              const max = new Date(min + dias * (1000 * 60 * 60 * 24));
              const overLimit =
                max.getTime() > new Date(disponibilidad.end).getTime();
              return !overLimit;
            }
            return false;
          }
        );
        if (disponibilidad) {
          this.range = disponibilidad;
          const { cantidadDias } = this.experiencia;
          const disponibilidadDate = this.calculateRange(start, +cantidadDias);
          this.reservaHandleChange({
            name: "disponibilidad",
            value: disponibilidadDate,
          });
        }
      }

      if (this.experiencia.modalidad === "diasSemana") {
        const startDay = new Date(start).getDay();
        const endDay = new Date(end).getDay();
        if (!isNaN(startDay) || !isNaN(endDay)) {
          if (startDay === endDay) {
            // La propiedad "disponibilidad" almacena las disponibilidades referentes a los dias de semana
            if (this.experiencia.disponibilidad.includes(startDay)) {
              const { cantidadDias } = this.experiencia;
              if (+cantidadDias > 1) {
                const disponibilidadDate = this.calculateRange(
                  start,
                  +cantidadDias
                );
                this.reservaHandleChange({
                  name: "disponibilidad",
                  value: disponibilidadDate,
                });
              } else {
                this.reservaHandleChange({
                  name: "disponibilidad",
                  value: new Date(start),
                });
              }
            }
          } else {
            this.reservaHandleChange({ name: "disponibilidad", value: [] });
          }
        }
      }
    },
    calculateRange(start, end) {
      const min = new Date(start).getTime();
      const dias = end - 1;
      const limit = min + dias * (1000 * 60 * 60 * 24);
      return {
        start,
        end: new Date(limit),
      };
    },
  },
  mounted() {
    this.calendar = this.$refs.calendar;
  },
  created() {
    if (this.experiencia.modalidad === "fechaExclusiva") {
      if (
        new Date(this.experiencia.fechaExclusiva).getTime() >
        new Date().getTime()
      ) {
        this.reservaHandleChange({
          name: "disponibilidad",
          value: new Date(this.experiencia.fechaExclusiva),
        });
      }
    }
    if (this.experiencia && this.experiencia.cantidadDias !== null) {
      if (!(this.experiencia.cantidadDias > 1)) {
        this.selectMode = "single";
      }
    }
    if (
      this.experiencia.diasAnticipacion &&
      this.experiencia.diasAnticipacion > 0
    ) {
      const present = new Date().getTime() + 1 * (1000 * 60 * 60 * 24);
      const diasAnticipacion = this.experiencia.diasAnticipacion;
      const anticipacionRange =
        present + diasAnticipacion * (1000 * 60 * 60 * 24);
      this.minDate = new Date(anticipacionRange);
    }
  },
  updated() {
    if (this.experiencia && this.experiencia.cantidadDias !== null) {
      if (this.experiencia.cantidadDias > 1) {
        this.selectMode = "range";
      } else {
        this.selectMode = "single";
      }
    }
  },
  beforeDestroy() {
    this.resetReserva();
  },
};
</script>

<style lang="scss" >
header.mb-1 {
  display: none;
}
.b-calendar-inner,
.b-calendar,
.vc-container {
  width: 100% !important;
}
footer {
  display: none !important;
}
button[title="Current month"],
button[title="Previous year"],
button[title="Next year"] {
  display: none;
}

button[title="Previous month"] {
  text-align: left;
  background-color: transparent !important;
  color: black !important;
}
button[title="Next month"] {
  text-align: right;
  background-color: transparent !important;
  color: black !important;
}

#CalendarioComponente{
  font-family: monospace;
}
</style>
