<template>
  <b-container fluid id="NewReserva">
    <b-container class="py-5">
      <div class="text-center d-flex align-items-center mb-4">
        <b-button :to="{ name: 'AdminHome' }" variant="success" class="mr-1"
          >Ir a Home de admin</b-button
        >
        <b-button
          :to="{ name: 'ReservasPorConfirmar' }"
          variant="success"
          class="ml-1"
          >Ver reservas por confirmar</b-button
        >
      </div>
      <h2>Crear reserva personalizada</h2>
      <b-row>
        <b-col cols="12">
          <h3 class="mb-0 mt-2">Datos experiencia:</h3>
          <h3 v-if="!experienciaData">Selecciona una experiencia</h3>
          <b-row>
            <b-col :cols="experienciaData ? '6' : '12'">
              <label for="NewExperiencia">Experiencia :</label>
              <b-form-input
                list="Experiencias"
                id="NewExperiencia"
                v-model="experiencia"
                :disabled="!!experienciaData"
              />

              <datalist id="Experiencias">
                <option
                  v-for="experiencia in experiencias"
                  :key="experiencia.id"
                  :value="experiencia.titulo"
                />
              </datalist>

              <b-button
                block
                @click="getExperienciaData"
                v-if="!experienciaData"
                :disabled="disableBtnAddExperiencia"
                class="mt-3"
                >Seleccionar experiencia</b-button
              >
            </b-col>
            <b-col cols="6" v-if="experienciaData">
              <label for="NewIdioma">Idioma:</label>
              <select
                name="idioma"
                id="NewIdioma"
                v-model="idioma"
                class="form-control"
              >
                <option value="Español">Español</option>
                <option value="Inglés">Inglés</option>
              </select>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row v-if="experienciaData">
            <b-col cols="12" lg="5">
              <label>Fecha:</label>
              <vc-date-picker
                :mode="calendarMode"
                is-range
                v-model="fechaReserva"
                :min-date="new Date()"
              />
            </b-col>
            <b-col cols="12" lg="7">
              <b-row align-v="end" >
                <b-col cols="6" md="4">
                  <label for="NewCantidadAdultos">Cantidad adultos: </label>
                  <b-form-spinbutton
                    v-model="detalleViajeros.adultos.cantidad"
                    id="NewCantidadAdultos"
                    :min="+experienciaData.capacidadMinima"
                    @input="calculoTotal"
                  />
                </b-col>
                <b-col cols="6" md="4">
                  <label for="NewPrecioAdultos">Precio:</label>
                  <b-form-input
                    v-model="detalleViajeros.adultos.precio"
                    id="NewPrecioAdultos"
                    :min="0"
                    @change="calculoTotal"
                    @keyup="calculoTotal"
                  />
                </b-col>
                <b-col cols="12" md="4">
                  <label for="NewTotalAdultos">Total:</label>
                  <b-form-input
                    v-model="detalleViajeros.adultos.total"
                    id="NewTotalAdultos"
                    disabled
                  />
                </b-col>
                <hr>
              </b-row>

              <template v-if="experienciaData.precioDiferenciadoNiños">
                <b-row
                  v-for="(niño, i) of detalleViajeros.niños"
                  :key="`${niño.nombre}${i}`"
                  align-v="end"
                >
                  <b-col cols="6" md="4">
                    <label :for="`NewCantidad${niño.nombre}${i}`"
                      >Cantidad {{ niño.nombre }}
                      <p>({{ niño.edadMinima }} - {{ niño.edadMaxima }}):</p>
                    </label>

                    <b-form-spinbutton
                      v-model="detalleViajeros.niños[i].cantidad"
                      :id="`NewCantidad${niño.nombre}${i}`"
                      :min="0"
                      @input="calculoTotal"
                    />
                  </b-col>
                  <b-col cols="6" md="4">
                    <label :for="`NewPrecio${niño.nombre}${i}`">Precio:</label>

                    <b-form-input
                      v-model="detalleViajeros.niños[i].precio"
                      :id="`NewPrecio${niño.nombre}${i}`"
                      :min="0"
                      @change="calculoTotal"
                      @keyup="calculoTotal"
                    />
                  </b-col>
                  <b-col cols="12" md="4">
                    <label :for="`NewTotal${niño.nombre}${i}`">Total:</label>

                    <b-form-input
                      v-model="detalleViajeros.niños[i].total"
                      :id="`NewTotal${niño.nombre}${i}`"
                      :min="0"
                      disabled
                    />
                  </b-col>
                  <hr />
                </b-row>
              </template>

              <label :for="`CantidadViajeros`">Cantidad viajeros:</label>

              <b-form-input
                v-model="viajeros"
                :id="`CantidadViajeros`"
                disabled
              />
            </b-col>

            <b-col cols="12">
              <h3 class="mb-0 mt-2">Pago:</h3>
              <label for="NewTotal">Total:</label>
              <b-form-input
                id="NewTotal"
                v-model="total"
                type="number"
                min="0"
                disabled
              />
            </b-col>
            <b-col cols="12">
              <b-form-checkbox v-model="permiteAbono" size="lg"
                >¿Permite pago con abono?:</b-form-checkbox
              >
            </b-col>

            <b-col cols="12" lg="4" v-if="permiteAbono">
              <label for="NewPorcentajeAbono">Porcentaje de abono:</label>
              <b-form-input
                id="NewPorcentajeAbono"
                v-model="porcentajeAbono"
                type="number"
                min="0"
                max="100"
              />
            </b-col>
            <b-col cols="6" lg="4" v-if="permiteAbono">
              <label for="NewPrimeraCuota">Primera cuota:</label>
              <b-form-input
                id="NewPrimeraCuota"
                type="number"
                min="0"
                :value="cuotas.primeraCuota"
                disabled
              />
            </b-col>
            <b-col cols="6" lg="4" v-if="permiteAbono">
              <label for="NewSegundaCuota">Segunda cuota:</label>
              <b-form-input
                id="NewSegundaCuota"
                type="number"
                min="0"
                :value="cuotas.segundaCuota"
                disabled
              />
            </b-col>
            <b-col cols="12">
              <h3 class="mb-0 mt-2">Datos cliente:</h3>
            </b-col>
            <b-col cols="12" lg="4">
              <label for="NewNombre">Nombre:</label>
              <b-form-input id="NewNombre" v-model="nombre" />
            </b-col>
            <b-col cols="12" lg="4">
              <label for="NewCorreo">Email:</label>
              <b-form-input id="NewCorreo" v-model="email" />
            </b-col>
            <b-col cols="12" lg="4">
              <label for="NewTelefono">Teléfono:</label>
              <b-form-input id="NewTelefono" v-model="telefono" />
            </b-col>
            <b-col cols="12">
              <label for="NewProcedencia">Ciudad de origen:</label>
              <b-form-input id="NewProcedencia" v-model="procedencia" />
            </b-col>
            <b-col cols="12">
              <label for="NewPais">País:</label>
              <select id="NewPais" name class="form-control" v-model="pais">
                <option value="Afganistan">Afghanistan</option>
                <option value="Albania">Albania</option>
                <option value="Algeria">Algeria</option>
                <option value="American Samoa">American Samoa</option>
                <option value="Andorra">Andorra</option>
                <option value="Angola">Angola</option>
                <option value="Anguilla">Anguilla</option>
                <option value="Antigua & Barbuda">Antigua & Barbuda</option>
                <option value="Argentina">Argentina</option>
                <option value="Armenia">Armenia</option>
                <option value="Aruba">Aruba</option>
                <option value="Australia">Australia</option>
                <option value="Austria">Austria</option>
                <option value="Azerbaijan">Azerbaijan</option>
                <option value="Bahamas">Bahamas</option>
                <option value="Bahrain">Bahrain</option>
                <option value="Bangladesh">Bangladesh</option>
                <option value="Barbados">Barbados</option>
                <option value="Belarus">Belarus</option>
                <option value="Belgium">Belgium</option>
                <option value="Belize">Belize</option>
                <option value="Benin">Benin</option>
                <option value="Bermuda">Bermuda</option>
                <option value="Bhutan">Bhutan</option>
                <option value="Bolivia">Bolivia</option>
                <option value="Bonaire">Bonaire</option>
                <option value="Bosnia & Herzegovina">
                  Bosnia & Herzegovina
                </option>
                <option value="Botswana">Botswana</option>
                <option value="Brazil">Brazil</option>
                <option value="British Indian Ocean Ter">
                  British Indian Ocean Ter
                </option>
                <option value="Brunei">Brunei</option>
                <option value="Bulgaria">Bulgaria</option>
                <option value="Burkina Faso">Burkina Faso</option>
                <option value="Burundi">Burundi</option>
                <option value="Cambodia">Cambodia</option>
                <option value="Cameroon">Cameroon</option>
                <option value="Canada">Canada</option>
                <option value="Canary Islands">Canary Islands</option>
                <option value="Cape Verde">Cape Verde</option>
                <option value="Cayman Islands">Cayman Islands</option>
                <option value="Central African Republic">
                  Central African Republic
                </option>
                <option value="Chad">Chad</option>
                <option value="Channel Islands">Channel Islands</option>
                <option selected value="Chile">Chile</option>
                <option value="China">China</option>
                <option value="Christmas Island">Christmas Island</option>
                <option value="Cocos Island">Cocos Island</option>
                <option value="Colombia">Colombia</option>
                <option value="Comoros">Comoros</option>
                <option value="Congo">Congo</option>
                <option value="Cook Islands">Cook Islands</option>
                <option value="Costa Rica">Costa Rica</option>
                <option value="Cote DIvoire">Cote DIvoire</option>
                <option value="Croatia">Croatia</option>
                <option value="Cuba">Cuba</option>
                <option value="Curaco">Curacao</option>
                <option value="Cyprus">Cyprus</option>
                <option value="Czech Republic">Czech Republic</option>
                <option value="Denmark">Denmark</option>
                <option value="Djibouti">Djibouti</option>
                <option value="Dominica">Dominica</option>
                <option value="Dominican Republic">Dominican Republic</option>
                <option value="East Timor">East Timor</option>
                <option value="Ecuador">Ecuador</option>
                <option value="Egypt">Egypt</option>
                <option value="El Salvador">El Salvador</option>
                <option value="Equatorial Guinea">Equatorial Guinea</option>
                <option value="Eritrea">Eritrea</option>
                <option value="Estonia">Estonia</option>
                <option value="Ethiopia">Ethiopia</option>
                <option value="Falkland Islands">Falkland Islands</option>
                <option value="Faroe Islands">Faroe Islands</option>
                <option value="Fiji">Fiji</option>
                <option value="Finland">Finland</option>
                <option value="France">France</option>
                <option value="French Guiana">French Guiana</option>
                <option value="French Polynesia">French Polynesia</option>
                <option value="French Southern Ter">French Southern Ter</option>
                <option value="Gabon">Gabon</option>
                <option value="Gambia">Gambia</option>
                <option value="Georgia">Georgia</option>
                <option value="Germany">Germany</option>
                <option value="Ghana">Ghana</option>
                <option value="Gibraltar">Gibraltar</option>
                <option value="Great Britain">Great Britain</option>
                <option value="Greece">Greece</option>
                <option value="Greenland">Greenland</option>
                <option value="Grenada">Grenada</option>
                <option value="Guadeloupe">Guadeloupe</option>
                <option value="Guam">Guam</option>
                <option value="Guatemala">Guatemala</option>
                <option value="Guinea">Guinea</option>
                <option value="Guyana">Guyana</option>
                <option value="Haiti">Haiti</option>
                <option value="Hawaii">Hawaii</option>
                <option value="Honduras">Honduras</option>
                <option value="Hong Kong">Hong Kong</option>
                <option value="Hungary">Hungary</option>
                <option value="Iceland">Iceland</option>
                <option value="Indonesia">Indonesia</option>
                <option value="India">India</option>
                <option value="Iran">Iran</option>
                <option value="Iraq">Iraq</option>
                <option value="Ireland">Ireland</option>
                <option value="Isle of Man">Isle of Man</option>
                <option value="Israel">Israel</option>
                <option value="Italy">Italy</option>
                <option value="Jamaica">Jamaica</option>
                <option value="Japan">Japan</option>
                <option value="Jordan">Jordan</option>
                <option value="Kazakhstan">Kazakhstan</option>
                <option value="Kenya">Kenya</option>
                <option value="Kiribati">Kiribati</option>
                <option value="Korea North">Korea North</option>
                <option value="Korea Sout">Korea South</option>
                <option value="Kuwait">Kuwait</option>
                <option value="Kyrgyzstan">Kyrgyzstan</option>
                <option value="Laos">Laos</option>
                <option value="Latvia">Latvia</option>
                <option value="Lebanon">Lebanon</option>
                <option value="Lesotho">Lesotho</option>
                <option value="Liberia">Liberia</option>
                <option value="Libya">Libya</option>
                <option value="Liechtenstein">Liechtenstein</option>
                <option value="Lithuania">Lithuania</option>
                <option value="Luxembourg">Luxembourg</option>
                <option value="Macau">Macau</option>
                <option value="Macedonia">Macedonia</option>
                <option value="Madagascar">Madagascar</option>
                <option value="Malaysia">Malaysia</option>
                <option value="Malawi">Malawi</option>
                <option value="Maldives">Maldives</option>
                <option value="Mali">Mali</option>
                <option value="Malta">Malta</option>
                <option value="Marshall Islands">Marshall Islands</option>
                <option value="Martinique">Martinique</option>
                <option value="Mauritania">Mauritania</option>
                <option value="Mauritius">Mauritius</option>
                <option value="Mayotte">Mayotte</option>
                <option value="Mexico">Mexico</option>
                <option value="Midway Islands">Midway Islands</option>
                <option value="Moldova">Moldova</option>
                <option value="Monaco">Monaco</option>
                <option value="Mongolia">Mongolia</option>
                <option value="Montserrat">Montserrat</option>
                <option value="Morocco">Morocco</option>
                <option value="Mozambique">Mozambique</option>
                <option value="Myanmar">Myanmar</option>
                <option value="Nambia">Nambia</option>
                <option value="Nauru">Nauru</option>
                <option value="Nepal">Nepal</option>
                <option value="Netherland Antilles">Netherland Antilles</option>
                <option value="Netherlands">
                  Netherlands (Holland, Europe)
                </option>
                <option value="Nevis">Nevis</option>
                <option value="New Caledonia">New Caledonia</option>
                <option value="New Zealand">New Zealand</option>
                <option value="Nicaragua">Nicaragua</option>
                <option value="Niger">Niger</option>
                <option value="Nigeria">Nigeria</option>
                <option value="Niue">Niue</option>
                <option value="Norfolk Island">Norfolk Island</option>
                <option value="Norway">Norway</option>
                <option value="Oman">Oman</option>
                <option value="Pakistan">Pakistan</option>
                <option value="Palau Island">Palau Island</option>
                <option value="Palestine">Palestine</option>
                <option value="Panama">Panama</option>
                <option value="Papua New Guinea">Papua New Guinea</option>
                <option value="Paraguay">Paraguay</option>
                <option value="Peru">Peru</option>
                <option value="Phillipines">Philippines</option>
                <option value="Pitcairn Island">Pitcairn Island</option>
                <option value="Poland">Poland</option>
                <option value="Portugal">Portugal</option>
                <option value="Puerto Rico">Puerto Rico</option>
                <option value="Qatar">Qatar</option>
                <option value="Republic of Montenegro">
                  Republic of Montenegro
                </option>
                <option value="Republic of Serbia">Republic of Serbia</option>
                <option value="Reunion">Reunion</option>
                <option value="Romania">Romania</option>
                <option value="Russia">Russia</option>
                <option value="Rwanda">Rwanda</option>
                <option value="St Barthelemy">St Barthelemy</option>
                <option value="St Eustatius">St Eustatius</option>
                <option value="St Helena">St Helena</option>
                <option value="St Kitts-Nevis">St Kitts-Nevis</option>
                <option value="St Lucia">St Lucia</option>
                <option value="St Maarten">St Maarten</option>
                <option value="St Pierre & Miquelon">
                  St Pierre & Miquelon
                </option>
                <option value="St Vincent & Grenadines">
                  St Vincent & Grenadines
                </option>
                <option value="Saipan">Saipan</option>
                <option value="Samoa">Samoa</option>
                <option value="Samoa American">Samoa American</option>
                <option value="San Marino">San Marino</option>
                <option value="Sao Tome & Principe">Sao Tome & Principe</option>
                <option value="Saudi Arabia">Saudi Arabia</option>
                <option value="Senegal">Senegal</option>
                <option value="Seychelles">Seychelles</option>
                <option value="Sierra Leone">Sierra Leone</option>
                <option value="Singapore">Singapore</option>
                <option value="Slovakia">Slovakia</option>
                <option value="Slovenia">Slovenia</option>
                <option value="Solomon Islands">Solomon Islands</option>
                <option value="Somalia">Somalia</option>
                <option value="South Africa">South Africa</option>
                <option value="Spain">Spain</option>
                <option value="Sri Lanka">Sri Lanka</option>
                <option value="Sudan">Sudan</option>
                <option value="Suriname">Suriname</option>
                <option value="Swaziland">Swaziland</option>
                <option value="Sweden">Sweden</option>
                <option value="Switzerland">Switzerland</option>
                <option value="Syria">Syria</option>
                <option value="Tahiti">Tahiti</option>
                <option value="Taiwan">Taiwan</option>
                <option value="Tajikistan">Tajikistan</option>
                <option value="Tanzania">Tanzania</option>
                <option value="Thailand">Thailand</option>
                <option value="Togo">Togo</option>
                <option value="Tokelau">Tokelau</option>
                <option value="Tonga">Tonga</option>
                <option value="Trinidad & Tobago">Trinidad & Tobago</option>
                <option value="Tunisia">Tunisia</option>
                <option value="Turkey">Turkey</option>
                <option value="Turkmenistan">Turkmenistan</option>
                <option value="Turks & Caicos Is">Turks & Caicos Is</option>
                <option value="Tuvalu">Tuvalu</option>
                <option value="Uganda">Uganda</option>
                <option value="United Kingdom">United Kingdom</option>
                <option value="Ukraine">Ukraine</option>
                <option value="United Arab Erimates">
                  United Arab Emirates
                </option>
                <option value="United States of America">
                  United States of America
                </option>
                <option value="Uraguay">Uruguay</option>
                <option value="Uzbekistan">Uzbekistan</option>
                <option value="Vanuatu">Vanuatu</option>
                <option value="Vatican City State">Vatican City State</option>
                <option value="Venezuela">Venezuela</option>
                <option value="Vietnam">Vietnam</option>
                <option value="Virgin Islands (Brit)">
                  Virgin Islands (Brit)
                </option>
                <option value="Virgin Islands (USA)">
                  Virgin Islands (USA)
                </option>
                <option value="Wake Island">Wake Island</option>
                <option value="Wallis & Futana Is">Wallis & Futana Is</option>
                <option value="Yemen">Yemen</option>
                <option value="Zaire">Zaire</option>
                <option value="Zambia">Zambia</option>
                <option value="Zimbabwe">Zimbabwe</option>
              </select>
            </b-col>
            <b-col cols="12">
              <label for="NewComentarios">Comentarios:</label>
              <b-form-textarea id="NewComentarios" v-model="comentarios" />
            </b-col>
            <b-col cols="12">
              <label for="NewVegano">Vegano/Vegetariano?:</label>
              <b-form-textarea id="NewVegano" v-model="vegano" />
            </b-col>

            <b-col cols="12" class="mt-2 mb-4">
              <b-button
                variant="primary"
                block
                @click="crearReserva"
                :disabled="disableBtnCrearReserva"
                >Crear reserva</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Vue from "vue";
import moment from "moment";

export default {
  name: "NewReserva",
  created() {},
  data() {
    return {
      //Form
      //Exp
      experiencia: "",
      fechaReserva: null,
      viajeros: 1,
      detalleViajeros: {
        adultos: {},
      },
      idioma: "Español",
      permiteAbono: false,
      porcentajeAbono: 100,
      primeraCuota: 0,
      segundaCuota: 0,
      total: 0,
      //Datos cliente
      nombre: "",
      email: "",
      telefono: "",
      procedencia: "",
      pais: "Chile",
      comentarios: "",
      vegano: "",
      ////
      experienciaData: null,
    };
  },
  computed: {
    ...mapState(["experiencias"]),
    cuotas() {
      const primeraCuota = Math.round(
        (this.total / 100) * this.porcentajeAbono
      );
      const segundaCuota = this.total - primeraCuota;

      return {
        primeraCuota: primeraCuota
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
        segundaCuota: segundaCuota
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
      };
    },
    disableBtnAddExperiencia() {
      const experiencia = this.experiencias.filter(
        (exp) => exp.titulo === this.experiencia
      );
      return experiencia.length ? false : true;
    },
    disableBtnCrearReserva() {
      if (
        this.fechaReserva === null ||
        !this.experienciaData ||
        this.total == 0
      )
        return true;
      else return false;
    },
    calendarMode() {
      let mode = "range";
      if (this.experienciaData.cantidadDias === 1) mode = "single";

      return mode;
    },
  },
  methods: {
    ...mapActions("reservaciones", ["nuevaReservaPersonalizada"]),
    async crearReserva() {
      const reserva = {
        abono: {
          porcentajeAbono: this.permiteAbono ? this.porcentajeAbono : 100,
          primeraCuota: this.permiteAbono
            ? this.cuotas.primeraCuota
            : this.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
          segundaCuota: this.permiteAbono ? this.cuotas.segundaCuota : "0",
        },
        comentarios:
          this.comentarios.trim() != ""
            ? this.comentarios.trim()
            : "Sin comentarios",
        correo: this.email,
        estado: "Pendiente",
        experiencia: this.experiencia,
        experienciaId: this.experienciaData.id,
        fecha: Vue.filter("toReadableDate")(
          this.calendarMode === "range"
            ? this.fechaReserva.start
            : this.fechaReserva
        ),
        fechaActual: Vue.filter("toReadableDate")(moment()),
        fechaFin: Vue.filter("toReadableDate")(
          this.calendarMode === "range"
            ? this.fechaReserva.end
            : this.fechaReserva
        ),
        horaFin: this.experienciaData.horaFin ?? "00:00:00",
        horaInicio: this.experienciaData.horaInicio ?? "00:00:00",
        idUnico: new Date().getTime().toString(),
        idioma: this.idioma,
        nom: this.nombre.trim().split(" ")[0],
        nombre: this.nombre.trim(),
        permiteAbono: this.permiteAbono,
        procedencia: `${this.procedencia.trim()}, ${this.pais}`,
        promociones: "Sin envío de promociones",
        referencia: "Sin información",
        telefono: this.telefono,
        token: "",
        total: this.total.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1."),
        vegano:
          this.vegano.trim() != "" ? this.vegano.trim() : "Sin información",
        viajeros: +this.viajeros,
        detalleViajeros: this.detalleViajeros,
      };

      // console.log(reserva);
      const crear = await this.nuevaReservaPersonalizada(reserva);
      if (!crear) return;

      alert("Reserva creada");
      this.resetForm();
    },
    async getExperienciaData() {
      this.experienciaData = await this.experiencias.find(
        (exp) => exp.titulo === this.experiencia
      );

      this.permiteAbono = this.experienciaData.permiteAbono ? true : false;
      this.porcentajeAbono = this.experienciaData.permiteAbono
        ? this.experienciaData.porcentajeAbono
        : 100;
      this.detalleViajeros.adultos = {
        cantidad: +this.experienciaData.capacidadMinima || 1,
        precio: 0,
        total: 0,
      };

      if (this.experienciaData.precioDiferenciadoNiños) {
        this.detalleViajeros.niños = [...this.experienciaData.precioNiños];
        this.detalleViajeros.niños.map((niño) => {
          niño.cantidad = 0;
          niño.total = 0;

          return niño;
        });
      }

      if (this.experienciaData.precioMasBajo) {
        this.detalleViajeros.adultos.precio =
          this.experienciaData.precioMasBajo;
      }
      this.viajerosAuto();
    },
    resetForm() {
      this.experiencia = "";
      this.fechaReserva = null;
      this.viajeros = 1;
      this.idioma = "Español";
      this.permiteAbono = false;
      this.porcentajeAbono = 100;
      this.primeraCuota = 0;
      this.segundaCuota = 0;
      this.total = 0;
      this.nombre = "";
      this.email = "";
      this.telefono = "";
      this.procedencia = "";
      this.pais = "Chile";
      this.comentarios = "";
      this.vegano = "";
      this.experienciaData = null;
    },
    calculoTotal() {
      let { adultos, niños } = this.detalleViajeros;

      let total = 0;

      adultos.cantidad = +adultos.cantidad;
      adultos.precio = +adultos.precio;
      adultos.total = adultos.cantidad * adultos.precio;
      total += adultos.total;

      if (niños) {
        let totalNiños = 0;
        niños.forEach((niño) => {
          niño.cantidad = +niño.cantidad;
          niño.precio = +niño.precio;
          niño.total = niño.precio * niño.cantidad;
          totalNiños += niño.total;
        });

        total += totalNiños;
      }
      this.total = total;
      this.viajerosAuto();
    },
    viajerosAuto() {
      let total = 0;
      if (this.detalleViajeros) {
        total = +this.detalleViajeros.adultos.cantidad;
        if (this.detalleViajeros.niños) {
          this.detalleViajeros.niños.forEach((niño) => {
            total += +niño.cantidad;
          });
        }
      }
      this.viajeros = total;
    },
  },
};
</script>

<style lang="scss" scoped>
#NewReserva {
  color: #fff;
  label {
    padding-top: 1rem;
  }
}

hr {
  background: #fff;
  width: 100%;
}
</style>
