/* eslint-disable */
export default {
  namespaced: true,

  state: {
    TerminosESP: {
      titulo: "Términos y condiciones"
    },
    TerminosENG: {
      titulo: "Terms and conditions"
    }
  },
  getters: {
    titulo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.TerminosESP.titulo;
      } else if (rootState.language == "en") {
        return state.TerminosENG.titulo;
      }
    }
  },
  mutations: {},
  actions: {}
};
