<template>
  <div id="Conoce">
    <b-row class="iconos w-100 p-sm-5">
      <b-col cols="4" sm="2">
        <a href="#Cultura">
          <img src="@/assets/img/iconos/ConoceZona/gastronomia.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon1 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#QueVer">
          <img src="@/assets/img/iconos/ConoceZona/queHacer.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon2 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#CuandoVisitar">
          <img src="@/assets/img/iconos/ConoceZona/CuandoVisitar.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon3 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#DondeQuedarse">
          <img src="@/assets/img/iconos/ConoceZona/DondeQuedarse.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon4 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#ComoLlegar">
          <img src="@/assets/img/iconos/ConoceZona/ComoLlegar.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon5 }}</p>
        </a>
      </b-col>
      <b-col cols="4" sm="2">
        <a href="#ConsejosUtiles">
          <img src="@/assets/img/iconos/ConoceZona/ConsejosUtiles.svg" alt />
          <p class="text-center" style="color: #3d99ff">{{ icon6 }}</p>
        </a>
      </b-col>
    </b-row>
    <!-- Cultura y Gastronomía -->
    <div id="CulturaYGastronomia">
      <h1 class="mb-4" id="Cultura">CULTURA Y GASTRONOMÍA</h1>

      <!-- Cultura y gastronomia (Carousel) -->
      <b-carousel
        v-if="slideCulturaContenido && slideCulturaContenido.length > 0"
        id="carousel-2"
        :interval="6000"
        :controls="slideCulturaContenido.length > 1"
      >
        <b-carousel-slide
          v-for="(contenidoArray, index) in slideCulturaContenido"
          :key="index"
          style="height: 300px;"
        >
          <b-row>
            <b-col
              v-for="(contenido, index) in contenidoArray"
              :key="index"
              class="col-12 col-sm-6 col-md-4"
            >
              <div
                class="expImg"
                :style="{
                  background: `url(${getThumbnail({
                    contentUrl: contenido.imgUrl,
                    size: 480
                  })}) no-repeat center center / cover`
                }"
              >
                <div class="capaOscura">
                  <h3>
                    {{
                    language === "es"
                    ? contenido.titulo
                    : contenido.tituloIngles
                    }}
                  </h3>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-carousel-slide>
      </b-carousel>

      <!-- Cultura y gastronomia (texto) -->
      <p class="pre-wrap">{{ routeProps.culturaGastronomia }}</p>

      <!-- Que ver y hacer -->
      <h1 class="m-4" id="QueVer">QUÉ VER Y HACER</h1>
      <p v-if="routeProps.verHacer" class="pre-wrap">{{ routeProps.verHacer }}</p>

      <!-- Cuando Vistar -->
      <h1 class="m-4" id="CuandoVisitar">CUANDO VISITAR</h1>
      <p class="pre-wrap">{{ routeProps.cuandoVisitar }}</p>

      <!-- Donde Quedarse -->
      <h1 class="m-4" id="DondeQuedarse">DÓNDE QUEDARSE</h1>
      <p class="pre-wrap">{{ routeProps.dondeQuedarse }}</p>
      <br />

      <!-- Como Llegar -->
      <h1 class="m-4" id="ComoLlegar">CÓMO LLEGAR</h1>
      <div class="row m-auto container">
        <div class="col-12 col-md-3">
          <img :src="routeProps.localidadImgUrl" class="w-75" />
        </div>
        <div class="col-12 col-md-9">
          <p class="pre-wrap">{{ routeProps.comoLlegar }}</p>
        </div>
      </div>

      <!-- Consejos Utiles -->
      <h1 class="m-4" id="ConsejosUtiles">CONSEJOS ÚTILES</h1>
      <!-- Consejos Utiles | Lista -->
      <ul>
        <li v-for="(consejo, index) in routeProps.consejos" :key="index">
          <p class="pre-wrap">* {{ consejo }}</p>
        </li>
      </ul>
      <br />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  name: "ConoceZona",
  props: ["routeProps"],
  computed: {
    ...mapGetters(["language"]),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapGetters("ConoceZona", [
      "icon1",
      "icon2",
      "icon3",
      "icon4",
      "icon5",
      "icon6",
    ]),
    slideCulturaContenido() {
      if (this.routeProps) {
        if (
          this.routeProps.contenidoCultura &&
          this.routeProps.contenidoCultura.length > 0
        ) {
          // Cambiar la cantidad de elementos ingresados en array dependiendiendo del viewport width
          const vw = Math.max(
            document.documentElement.clientWidth || 0,
            window.innerWidth || 0
          );
          let elements = 1;

          if (vw >= 768) {
            elements = 3;
          } else if (vw >= 576) {
            elements = 2;
          }
          const contenidoArray = [];
          const loops = Math.ceil(
            this.routeProps.contenidoCultura.length / elements
          );

          for (let i = 0; i < loops; i++) {
            contenidoArray.push(
              this.routeProps.contenidoCultura.slice(
                i * elements,
                i * elements + elements
              )
            );
          }
          return contenidoArray;
        }
      }
      return null;
    },
  },
};
</script>

<style lang="scss">
#Conoce {
  .row {
    @media screen and (max-width: 524px) {
      padding: 30px;
      margin: auto;
    }
  }
  p {
    text-align: left;
    @media screen and (max-width: 524px) {
      font-size: 12px;
      font-weight: bold;
      line-height: 15px;
    }
  }

  .iconos {
    color: #3d99ff;
    text-transform: uppercase;
    margin-bottom: 0;

    a {
      color: #3d99ff;
      font-weight: bold;
      font-size: 16px;

      &:hover {
        text-decoration-line: none;
      }
    }

    img {
      padding-bottom: 15px;
      @media screen and (max-width: 524px) {
        width: 81px;
        height: 81px;
      }
    }
  }

  #CulturaYGastronomia {
    h1 {
      color: #3d99ff;
      font-size: 40px;
      padding-top: 15px;
      font-weight: bold;
      @media screen and (max-width: 524px) {
        font-size: 25px;
        padding: 30px 50px;
      }
    }
    .row {
      @media screen and (max-width: 524px) {
        padding: 30px 0;
        margin: auto;
      }
    }
    p {
      color: #868686;
      width: 75%;
      margin: auto;
      @media screen and (max-width: 524px) {
        width: 100%;
        padding-left: 37px;
        padding-right: 37px;
        font-size: 14px;
        font-weight: 400;
        line-height: 28px;
      }
    }

    #carousel-2 {
      @media screen and (max-width: 524px) {
        margin-bottom: 30px;
      }
      .carousel-item {
        height: 300px;
        @media screen and (max-width: 524px) {
          height: 250px;
        }
      }
      .carousel-caption {
        width: 80%;
        margin-left: -5%;
        padding-bottom: 0;
        @media screen and (max-width: 524px) {
          left: 15% !important;
        }
      }
      .row {
        @media screen and (max-width: 524px) {
          padding: 30px 0;
          margin: auto;
        }
      }
      .capaOscura {
        height: 260px;
        position: relative;
        z-index: 2;
        @media screen and (max-width: 524px) {
          height: 210px;
        }

        h3 {
          background: rgba(0, 0, 0, 0.5);
          margin: 0;
          text-align: left;
          padding: 10px;
          font-size: 17px;
          border-radius: 0 0 10px 10px;
        }
      }

      .expImg {
        margin: 0 10px 0 10px;
        border-radius: 10px 10px 0 0;
        @media screen and (max-width: 524px) {
          margin: 0;
          border-radius: 10px;
        }
      }
    }
    p.text-carousel-below {
      @media screen and (max-width: 524px) {
        font-size: 14px;
        line-height: 28px;
        font-weight: regular;
      }
    }
    .w100 {
      @media screen and (max-width: 524px) {
        width: 95%;
      }
    }
    .col-12 div,
    .col-4 div {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }

    .carousel-control-next-icon {
      background: url("../assets/img/iconos/right.svg") !important;
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    .carousel-control-prev-icon {
      background: url("../assets/img/iconos/left.svg") !important;
      background-size: 100% 120% !important;
      height: 45px !important;
      width: 45px !important;
      position: relative;
      top: -20px;
    }

    .carousel-caption {
      width: 80%;
      margin-left: -5%;
      padding-bottom: 0;
    }
  }
}
</style>
