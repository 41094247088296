<template>
  <div id="Buscador">
    <div class="buscador">
      <div style="position: relative;">
        <div class="dropdownDestinos">
          <h5 class="d-none d-sm-block">
            {{ BuscadorDestinos }}
            <img src="@/assets/img/iconos/down.svg" alt="flechaAbajo" />
          </h5>
          <h5 class="d-block d-sm-none" @click="dropdownShow()">
            <input
              v-model="busqueda"
              @keyup.enter="buscar"
              style="width:100%"
              placeholder="Busca tu destino o experiencia"
              ref="inputBuscador"
              name
              :class="{ inputPlaceholder: dropdownZonas }"
            />
            <img
              src="@/assets/img/iconos/down.svg"
              alt="flechaAbajo"
              class="flechaAbajoMobile"
            />
          </h5>

          <!-- Vista Desktop -->
          <div class="dropdownDestinosList">
            <ul>
              <router-link
                tag="li"
                v-for="(zona, index) in getSortedZonas"
                :to="`/zonas/${nameAsId({ id: zona.id })}/overview`"
                :key="index"
                class="text-center"
                :class="{
                  opcionesZonas: true,
                  Centro: zona.titulo.toLowerCase().includes('centro'),
                  Lagos: zona.titulo.toLowerCase().includes('lagos'),
                  Patagonia: zona.titulo.toLowerCase().includes('patagonia')
                }"
                v-show="index == 2 || index == 3"
              >
                <!-- Se agreggo el v-show en este router-link por petición del cliente de no querer ver ninguna zona por ahora que no sea Sur -->
                {{ zona.titulo }}
                <div
                  :class="{
                    subNorte: zona.titulo.toLowerCase().includes('norte'),
                    subCentro: zona.titulo.toLowerCase().includes('centro'),
                    subLagos: zona.titulo.toLowerCase().includes('lagos'),
                    subPatagonia: zona.titulo
                      .toLowerCase()
                      .includes('patagonia')
                  }"
                >
                  <ul>
                    <router-link
                      tag="li"
                      v-for="(destino, index) in siteDestinosByZona({
                        id: zona.id
                      })"
                      :key="index"
                      class
                      :to="`/destinos/${destino.id}/experiencias`"
                      >{{ destino.titulo }}</router-link
                    >
                  </ul>
                </div>
              </router-link>
            </ul>
          </div>

          <!-- Vista Mobile -->
          <div
            role="tablist"
            class="d-sm-none acordeonZonas"
            :class="{ 'd-none': !dropdownZonas }"
          >
            <b-card
              no-body
              class="mb-0"
              v-for="(zona, index) in getSortedZonas"
              :key="index"
              v-show="index == 2 || index == 3"
            >
              <b-card-header header-tag="header" class="p-0" role="tab">
                <b-button
                  block
                  v-b-toggle="'acc_' + index"
                  class="btnZonas flex justify-content-between"
                >
                  <span>{{ zona.titulo }}</span>
                  <img src="@/assets/img/iconos/down.svg" alt="flechaAbajo" />
                </b-button>
              </b-card-header>
              <b-collapse
                :id="`acc_${index}`"
                accordion="my-accordion"
                role="tabpanel"
                @click="buscadorFalse()"
              >
                <b-card-body class="px-2" @click="buscadorFalse()">
                  <router-link
                    v-for="(destino, index) in siteDestinosByZona({
                      id: zona.id
                    })"
                    :key="index"
                    class="destinos"
                    :to="`/destinos/${destino.id}/experiencias`"
                  >
                    <span @click="buscadorFalse()">{{ destino.titulo }}</span>
                  </router-link>
                </b-card-body>
              </b-collapse>
            </b-card>
          </div>
        </div>

        <b-form-input
          list="my-list-id"
          class="d-none d-sm-block"
          v-model="busqueda"
          @keyup.enter="buscar"
          :placeholder="BuscadorPlaceholder"
        ></b-form-input>

        <b-input-group-text
          class="lupa"
          style="position: absolute; top: 0; right: 0; height:50px"
          @click="
            buscadorFalse();
            closeDropDownZonas();
            buscar();
          "
        >
          <img src="@/assets/img/iconos/lupaBlanca.svg" alt />
        </b-input-group-text>
      </div>
    </div>
    <div class="capa" @click="dropdownFalse()"></div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations, mapState } from "vuex";

export default {
  data() {
    return {
      busqueda: null
    };
  },
  computed: {
    ...mapGetters(["getSortedZonas", "siteDestinosByZona"]),
    ...mapGetters("Buscador", ["BuscadorDestinos", "BuscadorPlaceholder"]),
    ...mapState(["dropdownZonas"])
  },
  methods: {
    ...mapMutations(["buscadorFalse", "dropDownZonas", "closeDropDownZonas"]),
    buscar(e) {
      if (this.busqueda && this.busqueda.length > 0) {
        this.$router.push({
          path: `/busqueda/${this.busqueda}`
        });
      }
    },
    nameAsId({ id }) {
      if (this.getSortedZonas && this.getSortedZonas.length > 0) {
        const zona = this.getSortedZonas.find(zona => zona.id === id);
        if (zona.titulo.toLowerCase().includes("norte")) {
          return "norte";
        }
        if (zona.titulo.toLowerCase().includes("centro")) {
          return "centro";
        }
        if (zona.titulo.toLowerCase().includes("sur")) {
          return "sur";
        }
        if (zona.titulo.toLowerCase().includes("patagonia")) {
          return "patagonia";
        }
        return id;
      }
    },
    dropdownShow() {
      this.dropDownZonas();

      // const input = document.getElementById("inputBuscador");
      // background: #38a1ff;
      // color: white;
      // let hola = this.$refs.inputBuscador;
      // hola.style.background = "#38a1ff";
      // hola.style.color = "white";
      // hola.style.placeholder.color = "red";
    },
    dropdownFalse() {
      this.closeDropDownZonas();
    }
  },
  props: ["height"]
};
</script>

<style lang="scss" scoped>
#Buscador {
  .inputPlaceholder {
    background: #38a1ff;
    color: white;

    &::placeholder {
      color: white !important;
    }
  }

  .buscador {
    width: 100%;
    max-width: 700px;
    margin: auto;

    input {
      height: 50px;
      border-radius: 0px 25px 25px 0px;
      width: 70%;
      margin-left: 30%;
      border: none !important;
      position: relative;
      z-index: 1;

      @media screen and (max-width: 524px) {
        margin-left: 0px;
        outline: none;
        height: 36px;
      }

      &:focus {
        position: relative;
        z-index: 1;
        box-shadow: none;
      }
    }

    .lupa {
      position: absolute !important;
      top: 0;
      right: 0;
      height: 50px;
      background: #38a1ff 0% 0% no-repeat padding-box;
      margin-left: -110px;
      position: relative;
      z-index: 2;
      border: none;
      border-radius: 0px 25px 25px 0px;
      cursor: pointer;

      @media screen and (max-width: 524px) {
        height: 36px !important;
      }

      img {
        @media screen and (max-width: 524px) {
          height: 20px;
          width: 20px;
        }
      }

      @media screen and (max-width: 524px) {
        height: 36px;
        position: absolute;
        top: 0;
        right: 0;
        width: 15%;
      }
    }

    .dropdownDestinos {
      position: absolute;
      z-index: 12;
      width: 30%;
      height: 50px;
      background: white;
      border-right: groove rgba(183, 183, 183, 0.3) 0.5px;
      border-radius: 25px 0px 0px 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #495057;
      font-weight: lighter;

      @media screen and (max-width: 524px) {
        height: 36px;
        width: 85%;
      }

      h5 {
        margin: 0;
        font-size: 16px;
        color: #495057;
        font-weight: 400;

        @media screen and (max-width: 524px) {
          font-size: 12px;
          width: 100%;
          position: absolute;
          top: 6px;

          input {
            border-radius: 25px 0 0 25px;
            position: absolute;
            top: -6px;
            left: 0;
            padding: 0px 20px 0px 15px;

            &:focus {
              background: #38a1ff;
              color: white;
              outline: none;
              border-radius: 25px 0 0 25px transparent;
            }

            // &::placeholder {
            //   color: white;
            // }
          }
        }

        .flechaAbajoMobile {
          position: absolute;
          z-index: 1;
          right: 25px;
        }
      }

      &:hover {
        border-radius: 25px 0 0;
        background: #38a1ff;

        @media screen and (max-width: 524px) {
          font-size: 12px;
        }

        h5 {
          color: white;
          font-weight: 500;
          font-size: 17px;

          @media screen and (max-width: 524px) {
            font-size: 12px;
            text-align: left;
          }

          img {
            display: none;
          }
        }

        .dropdownDestinosList {
          @media screen and (min-width: 524px) {
            display: block;

            ul {
              border-radius: 0px 0px 0px 25px;
              overflow: hidden;

              @media screen and (max-width: 524px) {
                border-radius: 0;
              }

              li:nth-child(1):hover {
                .subNorte {
                  display: block;
                  height: auto;

                  ul {
                    flex-direction: column;
                    height: auto;
                  }
                }
              }

              li:nth-child(2):hover {
                .subCentro {
                  display: block;
                  height: auto;

                  ul {
                    flex-direction: column;
                    height: auto;
                  }
                }
              }

              li:nth-child(3):hover {
                .subLagos {
                  display: block;
                  height: auto;

                  ul {
                    flex-direction: column;
                    height: auto;
                  }
                }
              }

              li:nth-child(4):hover {
                .subPatagonia {
                  display: block;
                  height: auto;

                  ul {
                    flex-direction: column;
                    height: auto;
                  }
                }
              }

              li div {
                display: none;
              }

              li:hover {
                color: rgb(54, 54, 54);
              }
            }
          }
        }
      }

      .dropdownDestinosList {
        display: none;
        position: absolute;
        top: 50px;
        width: 100.5%;
        transition: all 300ms;

        @media screen and (max-width: 524px) {
          top: 35px;
        }

        ul {
          list-style: none;
          border-radius: 25px;
          background: white;

          // @media screen and (max-width: 524px) {
          //   border-radius: 18px;
          //   margin-bottom: 0;
          //   z-index: 11;
          // }
          li {
            background: white;
            color: gray !important;
            cursor: pointer;
            padding: 3px;
            font-size: 16px;

            @media screen and (max-width: 524px) {
              font-size: 12px;
            }

            &:hover {
              color: white !important;
            }
          }

          .opcionesZonas {
            // border-bottom: groove 1px #858585;
            font-weight: lighter;
            color: #495057;
            padding: 5px 0px;
            width: 100%;
            display: flex;
            justify-content: center;

            // @media screen and (max-width: 524px) {
            //   justify-content: space-between;
            //   align-items: center;
            //   padding: 5px 10px 5px 20px;
            //   font-size: 12px;
            //   height: 40px;
            // }
            &:hover {
              background: #38a1ff;
              color: white;
              font-weight: 500;

              @media screen and (max-width: 524px) {
                font-size: 12px;
              }

              div ul li {
                text-shadow: none;
              }
            }

            div {
              border-left: groove 1px;
            }

            div ul li {
              font-weight: lighter;
              width: 100%;
              display: flex;
              justify-content: center;
              border-bottom: groove 1px;
              padding: 5px 0px;

              @media screen and (max-width: 524px) {
                // border-bottom: solid 1px #38a1ff;
              }

              &:hover {
                background: #38a1ff;
                color: white;
                font-weight: 500;
              }
            }

            @media screen and (min-width: 524px) {
              .subMenu,
              .subNorte,
              .subCentro,
              .subLagos,
              .subPatagonia {
                position: absolute;
                top: 0;
                left: 100%;
                width: 100%;
                border-radius: 0px 0px 25px 25px;
                overflow: hidden;

                // @media screen and (max-width: 524px) {
                //   border-radius: 0 0 18px 18px;
                //   left: 0;
                //   top: 100%;
                //   animation: menus 200ms linear;
                //   @keyframes menus {
                //     0% {
                //       opacity: 0;
                //       height: 0;
                //     }
                //     50% {
                //       opacity: 50%;
                //       height: 50%;
                //     }
                //     100% {
                //       opacity: 100%;
                //       height: 100%;
                //     }
                //   }
                // }
                li {
                  // padding: 0px 2px;
                  font-weight: 400;
                  height: 60px;
                  // @media screen and (max-width: 524px) {
                  //   padding: 7.5px 5px;
                  //   color: #38a1ff;
                  //   background-color: #f8f8f8;
                  //   text-align: center;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }

  .capa {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 98vw;
    height: 100vh;
  }

  .acordeonZonas {
    position: absolute;
    width: 100%;
    top: 102%;
    background-color: white;
    border-radius: 0 0 12px 12px;
    overflow: hidden;

    .btnZonas {
      background-color: white;
      border-radius: 25px;
      color: #858585;
      border: none;
      font-size: 12px;
      font-weight: 500;
      text-align: left;
      line-height: 33px;
      box-shadow: none;
      padding-left: 16px;

      &:focus {
        background-color: #38a1ff;
        color: white;
        border-radius: 0px;
      }
    }

    .card {
      border: none;
      border-bottom: 1px solid rgba(0, 0, 0, 0.125) !important;
    }

    .card-header {
      background-color: white;
      border: none;
      height: 43px;
    }

    .destinos {
      line-height: 27px;
      text-align: left;
      display: inline-block;
      color: #858585;
      font-weight: 500;
      border-bottom: 1px solid #38a1ff !important;
      width: 90%;
      padding-left: 14px;
    }
  }
}
</style>
