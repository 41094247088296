<template>
  <div id="Tour" v-if="viaje" ref="vistaExperiencia">
    <Breadcrumbs
      v-if="viaje"
      razon="experiencia"
      :titulo="viaje.titulo"
    />

    <div class="contain">
      <b-row class="m-0">
        <!-- header -->
        <b-col cols="12" class="px-3 hero-section__titulo text-left">
          <h1
            class="hero-section__heading font-weight-bold"
            v-if="viaje.titulo"
          >
            {{
              language === "es" ? viaje.titulo : viaje.ingles.titulo
            }}
          </h1>
          <div
            class="d-flex align-items-center justify-content-start"
            v-if="evaluacionesByExperiencia(id).length"
          >
            <b-form-rating
              :value="promedio"
              no-border
              readonly
              inline
              variant="warning"
              class="rating"
            ></b-form-rating>
            <h5 class="m-0 pr-2">{{ promedio }}</h5>
            <span style="fontsize: 13px">
              ({{ evaluacionesByExperiencia(id).length }})
            </span>
          </div>
        </b-col>
        <!-- main -->
        <b-col cols="12" lg="8">
          <div class="pt-3 pt-lg-0">
            <!-- <CarouselIMG
              class=""
              :data="getHeroContent"
              :interval="carouselInterval"
            /> -->
            <div
                class="carousel__images"
                :style="{
                  background: `url(${viaje.carouselImgUrl}) no-repeat center center / cover`,
                  width: '730px',height: '380px'
                }"
            ></div>
          </div>

          <!-- RESUMEN -->
          <div class="m-0" no-body ref="resumen" id="AccResumen">
            <div>
              <b-container class="p-3 " id="tuti">
                <span>
                  <p class="pre-wrap">
                    {{
                      language === "es"
                        ? viaje.resumen
                        : viaje.ingles.resumen
                        ? viaje.ingles.resumen
                        : viaje.resumen
                    }}
                  </p>

                  <h5 style="color: #3d99ff; font-weight: bold">
                    {{__get('highlights')}}
                  </h5>
                  <ul class="experiencia__list">
                    <li
                      v-for="(destacado, index) in language === 'es'
                        ? viaje.destacado
                        : viaje.ingles.destacado"
                      :key="index"
                      class="
                        experiencia__list-item
                        d-sm-flex
                        align-items-start
                        pb-2
                      "
                    >
                      <img
                        class="mr-2"
                        src="@/assets/img/iconos/checkPequeño.svg"
                        width="20"
                        alt
                      />
                      <span>{{ destacado }}</span>
                    </li>
                  </ul>
                </span>
              </b-container>
            </div>
          </div>

          <!-- ITINERARIO -->
          <div class="m-0 mt-4" no-body ref="descripcion" id="AccItinerario">
            <div
              header-tag="header"
              class="p-1 p-lg-0"
              role="tab"
            >
              <h4
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  font-weight-bold
                  px-2 px-lg-4
                "
              >
                <span> {{ breveitin }} </span>
              </h4>
            </div>

            <table class="table table-sm">
              <thead>
                <tr>
                  <th>{{__get('day')}}</th>
                  <th>{{__get('highlights')}}</th>
                  <th>{{ durantenoche }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(dia, index) in viaje.itinerariosDias" :key="index">
                  <td>{{__get('day')}}
                      {{
                         index + 1
                      }}</td>
                  <td>{{ language == "es" ? dia.titulo  : dia.tituloIngles }}</td>
                  <td>{{ language == "es" ? dia.noche  : dia.overnigth }}</td>
                </tr>
              </tbody>
            </table>
          </div>


          <div class="m-0 mt-4" no-body ref="descripcion" id="AccItinerario">
            <div
              header-tag="header"
              class="p-1 p-lg-0"
              role="tab"
            >
              <h4
                class="
                  d-flex
                  align-items-center
                  justify-content-between
                  font-weight-bold
                  px-2 px-lg-4
                "
              >
                <span> {{ descripcionNombre }} </span>
              </h4>
            </div>
              <b-container class="p-3">
                <span>

                  <!-- <p class="pre-wrap">
                    {{
                      language === "es"
                        ? viaje.descripcion.resumen
                        : viaje.ingles.descripcion.resumen
                        ? viaje.ingles.descripcion.resumen
                        : viaje.descripcion.resumen
                    }}
                  </p> -->
                  <br />
                  <!-- language === 'es'
                      ? viaje.descripcion.dias
                      : viaje.ingles.descripcion.dias" -->
                  <div
                    v-for="(dia, index) in viaje.itinerariosDias" :key="index"
                  >
                  <div
                    class="carousel__images"
                    :style="{
                      background: `url(${dia.imgUrl}) no-repeat center center / cover`,
                      width: '700px',height: '320px'
                    }"
                ></div>
                    <h5
                      :key="index"
                      style="color: #3d99ff; font-weight: bold"
                    >
                      {{__get('day')}}
                      {{
                         index + 1
                      }}
                    </h5>

                    <p class="pre-wrap">{{ dia.descripcion }}</p>
                  </div>
                </span>
              </b-container>
          </div>

          <!-- MAPA -->
          <div
            class="m-0"
            no-body
            ref="infoNombre"
            id="AccMapa"
            v-if="viaje.googleMapsUrl"
          >
                <div
                  header-tag="header"
                  class="p-1 p-lg-0"
                  role="tab"
                  @click="carouselFocus('AccMapa')"
                >
                  <h4
                    class="
                      d-flex
                      align-items-center
                      justify-content-between
                      font-weight-bold
                      px-2 px-lg-4
                    "
                    block
                  >
                    <span>{{ mapaNombre }}</span>
                  </h4>
                </div>
                  <div>
                    <span>
                      <b-container class="p-3">
                        <h5
                          class="mb-2"
                          style="
                            color: #3d99ff;
                            font-weight: bold;
                            font-size: 20px;
                          "
                        >
                          {{ ubicacionNombre }}
                        </h5>
                        <iframe
                          :src="viaje.googleMapsUrl"
                          frameborder="0"
                          style="border: 0"
                          allowfullscreen
                          aria-hidden="false"
                          tabindex="0"
                          class="experiencia__iframe"
                        ></iframe>
                      </b-container>
                    </span>
                  </div>
          </div>
        </b-col>

        <b-col cols="12" lg="4" class="calendario">
          <div class="card-header row px-2">
              <div class="col-12">
                <div class="my-3">
                  <h3 class="calendario__titulo mt-2">{{ __get('onlineResrve') }}</h3>
                  <h6>
                    {{ chatespec }}
                  </h6>
                </div>
              </div>
            </div>
        </b-col>
      </b-row>
    </div>
    <BtnReservaAhora />
  </div>
</template>

<script>
/* eslint-disable */
import Calendario from "@/components/Calendario.vue";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import { storage } from "../firebase.service";
import Breadcrumbs from "./../components/Breadcrumbs";
import CarouselIMG from "./../components/Experiencia/CarouselIMG";
import TablaPasajeros from "./../components/Experiencia/TablaPasajeros";
import BtnReservaAhora from "./../components/Experiencia/BtnReservaAhora";
import moment from "moment";
import Vue from "vue";

export default {
  name: "Tour",
  metaInfo() {
    return {
      title: this.experiencia ? this.experiencia.titulo : "",
      meta: [
        {
          vmid: "description",
          name: "description",
          content: this.experiencia ? this.experiencia.resumen : "",
        },
      ],
      // link: [
      //   {
      //     rel: "canonical",
      //     href: `https://surreal.travel/destinos/${this.destinoByExperiencia.id}/experiencias`,
      //   },
      // ],
    };
  },
  components: {
    Calendario,
    Breadcrumbs,
    CarouselIMG,
    TablaPasajeros,
    BtnReservaAhora,
  },
  data() {
    return {
      id: null,
      idioma: "",
      carouselInterval: 6000,
      zona: "",
      zonaID: "",
      reviewsPerPage: 3,
      lastFocus: "",
      adultosCantidad: 0,
      niñosCantidad: 0,
      preciosDiferenciados: null,
      fechasSeleccionadas: [],
      temporadasFechasSeleccionadas: [],
      totalReserva: 0,
      adultosPrecioMinimo: 0,
      detalleViajeros: {},
      precioAdultosPorDia: 0,
    };
  },
  created() {
    const id = this.$route.params.id;
    if (!id) return;
    if (id) this.id = id;
    this.setViajeId(this.$route.params.id)

  },
  updated() {
    const id = this.$route.params.id;
    if (!id) return;
    if (id) this.id = id;
  },
  methods: {
    ...mapActions(["submitReserva", "reservaHandleChange"]),
    ...mapActions("trip",['setViajeId']),
    submitRequest() {
      const payload = {
        toast: this.$bvToast,
        detalleViajeros: this.detalleViajeros,
        precioAdulto: this.precioAdultoPorPersona,
        total: this.totalReserva,
        temporadas: [...this.temporadasEnPrecioFinal],
      };

      this.submitReserva(payload);
    },
    handleChange(event) {
      const { name, value } = event.target;
      this.reservaHandleChange({
        name,
        value,
      });
    },
    precio(experiencia, reserva) {
      if (
        experiencia &&
        experiencia.preciosConfig &&
        experiencia.preciosConfig.length > 0
      ) {
        const { preciosConfig } = experiencia;
        const reservaConfig = preciosConfig.find((config) => {
          return (
            Number(config.cantidadPersonas) === Number(reserva.cantidadAdultos)
          );
        });

        if (reservaConfig && reservaConfig[reserva.idioma]) {
          return reservaConfig[reserva.idioma];
        }
      }
      return experiencia.precio;
    },
    precioOferta(experiencia, reserva) {
      if (
        experiencia &&
        experiencia.preciosConfig &&
        experiencia.preciosConfig.length > 0
      ) {
        const { preciosConfig } = experiencia;
        const reservaConfig = preciosConfig.find((config) => {
          return (
            Number(config.cantidadPersonas) === Number(reserva.cantidadAdultos)
          );
        });
        if (experiencia.oferta) {
          if (reserva.idioma === "Español")
            return reservaConfig["EspañolOferta"];
          if (reserva.idioma === "Inglés") return reservaConfig["InglésOferta"];
        } else {
          if (reservaConfig && reservaConfig[reserva.idioma]) {
            return reservaConfig[reserva.idioma];
          }
        }
      }
      if (!experiencia.oferta) return experiencia.precio;
      return experiencia.precioOferta;
    },
    horaFormat(value) {
      if (value && value.length > 0) {
        const time = value.split(":");
        const hours = time[0];
        const minutes = time[1];
        let stage;
        if (hours >= 12) {
          stage = "PM";
        } else {
          stage = "AM";
        }
        return `${hours % 12}:${minutes} ${stage}`;
      }
      return null;
    },
    precioMinimoPlan() {
      if (
        this.experiencia &&
        this.experiencia.preciosConfig &&
        this.experiencia.preciosConfig.length > 0 &&
        this.reserva.cantidadAdultos === null &&
        this.reserva.idioma === null
      ) {
        let cantidadAdultos;
        let idioma, precio, idiomas;

        this.experiencia.preciosConfig.forEach((config) => {
          // console.log("ESTOY EN preciosConfig", config);
          const newConfig = { ...config };
          delete newConfig.cantidadPersonas;
          // Incluir idiomas oferta a eliminar
          delete newConfig.InglésOferta;
          delete newConfig.EspañolOferta;
          let keysNewConfig = Object.keys(newConfig);
          idiomas = keysNewConfig;
          keysNewConfig.forEach((language) => {
            if (!precio) {
              precio = newConfig[language];
              cantidadAdultos = config.cantidadPersonas;
              idioma = language;
            }
            if (Number(newConfig[language]) < Number(precio)) {
              cantidadAdultos = config.cantidadPersonas;
              idioma = language;
              precio = newConfig[language];
              // console.log("ESTOY EN preciosConfig", precio);
            }
          });
        });

        this.reservaHandleChange({
          name: "idioma",
          value: idiomas.includes("Español") ? "Español" : idioma,
        });
        // this.reservaHandleChange({
        //   name: "cantidadAdultos",
        //   value: cantidadAdultos,
        // });
        this.adultosPrecioMinimo = cantidadAdultos || 1;
      }
    },
    carouselFocus(id) {
      let elmnt;
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      vw >= 768
        ? (elmnt = document.getElementById(id))
        : (elmnt = document.getElementById("Accordion"));
      elmnt.scrollIntoView();
      // document.scrollTo(0, 250);
    },
    nombreCliente(nombre) {
      const arrayNombre = nombre.split(" ").filter((n) => n.trim() != "");
      const primerNombre = arrayNombre[0];
      const primeraLetraDeSegundaPalabra = arrayNombre[1].slice(0, 1);

      return `${primerNombre} ${primeraLetraDeSegundaPalabra}`;
    },
    valueProgress(key) {
      let cantidad = 0;
      if (this.evaluacionesByRating[key]) {
        cantidad = this.evaluacionesByRating[key].length;
      }
      return cantidad;
    },
    verMasReviews() {
      if (this.reviewsPerPage < this.evaluacionesByExperiencia(this.id).length)
        this.reviewsPerPage = this.reviewsPerPage + 3;
    },
    verMenosReviews() {
      // if(this.reviewsPerPage > 3) this.reviewsPerPage = this.reviewsPerPage - 3;
      this.reviewsPerPage = 3;
    },
    fechasReserva() {
      const { reserva, experiencia } = this;
      if (!reserva.disponibilidad || !experiencia) return;

      //Fechas seleccionadas reserva

      let {
        disponibilidad: { end: fechaFin, start: fechaInicio },
      } = reserva;

      fechaInicio = moment(fechaInicio);
      fechaFin = moment(fechaFin);

      const reservaFechas = [];

      if (!fechaInicio || !fechaFin) return;
      else {
        // for (let f = fechaInicio; f.isSameOrBefore(fechaFin); f.add(1, "d")) {
        for (
          let f = fechaInicio;
          !reservaFechas.length || f.isBefore(fechaFin);
          f.add(1, "d")
        ) {
          reservaFechas.push(f.format("YYYY-MM-DD"));
        }

        this.fechasSeleccionadas = reservaFechas;
      }
    },
    temporadaPorDia() {
      const { fechasSeleccionadas, experiencia } = this;
      if (!fechasSeleccionadas || !experiencia) return;
      //Fechas temporadas experiencia

      const { temporadas } = experiencia;
      if (!temporadas) {
        this.temporadasFechasSeleccionadas = Array(
          fechasSeleccionadas.length
        ).fill({ temporada: "Normal" });
        return;
      }

      const fechasTemporadas = temporadas.fechas.map((f) => {
        return {
          fecha: moment(f.id),
          temporada: f.temporada,
        };
      });

      //funcion para verificar si alguna de las fechas
      // seleccionadas tiene alguna temporada asignada
      const buscarFechaEnTemporadas = (fechaABuscar) => {
        return fechasTemporadas.find((f) =>
          moment(f.fecha).isSame(moment(fechaABuscar), "d")
        );
      };

      const temporadasPorFecha = [];

      fechasSeleccionadas.forEach((fecha) => {
        temporadasPorFecha.push(buscarFechaEnTemporadas(fecha));
      });

      this.temporadasFechasSeleccionadas = temporadasPorFecha;
    },
    precioTotal() {
      const { experiencia, reserva } = this;
      const { cantidadAdultos, cantidadNiños } = reserva;

      let total = 0;

      if (!this.precioPorDia.length) return total * cantidadAdultos;

      if (this.precioPorDiaOferta) {
        total =
          this.precioPorDiaOferta &&
          +this.precioPorDiaOferta.map((p) => p.precio).reduce((a, b) => a + b);
      } else {
        total =
          this.precioPorDia &&
          +this.precioPorDia.map((p) => p.precio).reduce((a, b) => a + b);
      }
      let totalNiños = 0;

      if (experiencia.precioDiferenciadoNiños && cantidadNiños) {
        const precioPorCadaCategoriaNiño = cantidadNiños.map(
          (niño) => niño.precio * niño.cantidad
        );
        totalNiños = precioPorCadaCategoriaNiño.reduce((a, b) => a + b);
      }

      // const cantidadDias = experiencia.cantidadDias
      //   ? +experiencia.cantidadDias
      //   : this.fechasSeleccionadas.length;
      const cantidadDias = this.fechasSeleccionadas.length;

      const precioAdulto = Math.round(+total / cantidadDias);

      const totalFinal = Math.round(
        precioAdulto * +cantidadAdultos + totalNiños
      );

      this.totalReserva = totalFinal;
      this.detalleViajeros = {
        adultos: {
          precio: precioAdulto,
          cantidad: +cantidadAdultos,
          total: precioAdulto * +cantidadAdultos,
        },
        niños: [],
      };
      this.precioAdultosPorDia = precioAdulto;

      if (experiencia.precioDiferenciadoNiños && cantidadNiños) {
        this.detalleViajeros.niños = cantidadNiños
          .filter((n) => n.cantidad)
          .map((n) => {
            n.total = n.cantidad * n.precio;
            return n;
          });
      }
      return totalFinal;
    },
  },
  computed: {
    ...mapGetters([
      "getExperiencias",
      "getDestinos",
      "getZonas",
      "reserva",
      "siteDestinosByZona",
      "language",
      "precioMinimo",
      "precioOfertaMinimo",
      "cantidadNiñosSumados",
    ]),
    ...mapGetters("trip",['viaje']),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapGetters("ExperienciaLang", [
      "destinosNombre",
      "duracionNombre",
      "idiomasNombre",
      "modalidadNombre",
      "resumenNombre",
      "descripcionNombre",
      "infoNombre",
      "precioNombre",
      "inicioNombre",
      "viajerosNombre",
      "reservarNombre",
      "contactanosNombre",
      "ubicacionNombre",
      "descubreNombre",
      "masNombre",
      "opinionesNombre",
      "debesTraerNombre",
      "cancelacionNombre",
      "mapaNombre",
      "niñosNombre",
      "breveitin",
      "durantenoche",
      "chatespec"
    ]),
    ...mapGetters("Evaluaciones", [
      "evaluacionesByExperiencia",
      "evaluacionesOrdenadasSegunNota",
      "promedioRates",
    ]),
    ...mapState("Evaluaciones", ["evaluaciones"]),
    ...mapState("reservaciones", [
      "reservaciones",
      "reservacionesPorConfirmar",
    ]),
    ...mapGetters("i18n", ["__get"]),
    duracion() {
      let duracion;
      if (!(this.experiencia.cantidadDias > 1)) {
        let start;
        let end;
        if (this.reserva.horaInicio && this.reserva.horaFin) {
          const { horaInicio, horaFin } = this.reserva;
          start = horaInicio;
          end = horaFin;
        } else if (
          this.experiencia.rangosHoras.length > 0 &&
          this.reserva.rangoHorasIndex !== null
        ) {
          start =
            this.experiencia.rangosHoras[this.reserva.rangoHorasIndex].start;
          end = this.experiencia.rangosHoras[this.reserva.rangoHorasIndex].end;
        }
        const startDate = new Date("01/01/2020 " + start + ":00");
        let endDate = new Date("01/01/2020 " + end + ":00");
        const startTime = startDate.getTime();
        let endTime = endDate.getTime();
        if (endTime - startTime < 0) {
          endDate = new Date("01/02/2020 " + end + ":00");
          endTime = endDate.getTime();
        }
        // Diferencia de tiempo en minutos
        const timeDifference = Math.abs(endTime - startTime) / (1000 * 60);
        const horasDifference = (timeDifference - (timeDifference % 60)) / 60;
        const minutosDifference = timeDifference % 60;

        // Variables con contenido textual
        const horas =
          horasDifference > 0
            ? horasDifference > 1
              ? `${horasDifference} Horas`
              : `${horasDifference} Hora`
            : "";
        const minutos =
          minutosDifference > 0
            ? minutosDifference > 1
              ? ` ${minutosDifference} Minutos`
              : ` ${minutosDifference} Minuto`
            : "";
        if (horas === 0 && minutos === 0) {
          return "24 horas";
        }
        return horas + minutos;
      } else {
        duracion = `${this.experiencia.cantidadDias} ${
          this.language === "es"
            ? this.experiencia.cantidadDias > 0
              ? " dias"
              : " dia"
            : "days"
        }`;
        return duracion;
      }
    },
    idiomas() {
      if (this.id && this.experiencia) {
        const idiomas = this.experiencia.idiomas;
        const texto = idiomas.map((idioma, index) => ` ${idioma}`);
        const textoSinOferta = texto.filter((fil) => !fil.includes("Oferta"));
        return textoSinOferta.toString();
      }
      return null;
    },
    idiomasSelect() {
      if (this.id && this.experiencia) {
        const idiomas = this.experiencia.idiomas;
        const texto = idiomas.filter((fil) => !fil.includes("Oferta"));
        return texto;
      }
      return null;
    },
    experiencia() {
      const id = this.$route.params.id;
      if (!id) return;
      const exp = this.getExperiencias.find(
        (experiencia) => experiencia.id === id
      );
      if (!exp) return;

      return exp;
    },
    reservaInmediata() {
      if (this.id && this.experiencia)
        return this.experiencia.reserva_inmediata;
      return null;
    },
    capacidadList() {
      const min = this.experiencia.capacidadMinima || 1;
      const limit = this.experiencia.capacidad;
      const dataArray = [];
      for (let i = min - 1; i < limit; i++) {
        dataArray.push(i + 1);
      }
      return dataArray;
    },
    experienciasPorZona() {
      if (!this.id) return;
      const destino = this.getDestinos.find((destino) =>
        destino.experiencias.includes(this.id)
      );
      if (!destino) return;
      const zona = this.getZonas.find((zona) =>
        zona.destinos.includes(destino.id)
      );
      if (!zona || !zona.id || !zona.titulo) return;
      this.zona = zona.titulo;
      this.zonaID = zona.id;
      const destinos = this.siteDestinosByZona({
        id: zona.id,
      });
      if (!(destinos && destinos.length > 0)) return;
      let zonaExperiencias = [];
      destinos.forEach((destino) => {
        zonaExperiencias = zonaExperiencias.concat(destino.experiencias);
      });
      const experiencias = this.getExperiencias.filter((experiencia) =>
        zonaExperiencias.includes(experiencia.id)
      );
      if (experiencias.length > 0) return experiencias;
      return null;
    },
    slideExperiencias() {
      // Cambiar la cantidad de elementos ingresados en array dependiendiendo del viewport width
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      let elements = 1;

      if (vw >= 768) {
        elements = 3;
      } else if (vw >= 576) {
        elements = 2;
      }

      if (this.experienciasPorZona.length > 0) {
        const contenidoArray = [];
        const loops = Math.ceil(this.experienciasPorZona.length / elements);
        for (let i = 0; i < loops; i++) {
          contenidoArray.push(
            this.experienciasPorZona.slice(
              i * elements,
              i * elements + elements
            )
          );
        }
        return contenidoArray;
      }
      return null;
    },
    evaluacionesDeExperiencia() {
      const id = this.$route.params.id;
      const ordenadasPorFecha = this.evaluacionesByExperiencia(id).sort(
        (a, b) => {
          if (moment(a.fecha).isAfter(b.fecha)) return -1;
          else return 1;
        }
      );
      return ordenadasPorFecha.slice(0, this.reviewsPerPage);
    },
    evaluacionesByRating() {
      const id = this.$route.params.id;
      return this.evaluacionesOrdenadasSegunNota(id);
    },
    promedio() {
      const id = this.$route.params.id;
      return this.promedioRates(id);
    },
    precioAdultoPorPersona() {
      const { experiencia, reserva } = this;
      let precio = 0;
      if (this.precioOferta(experiencia, reserva)) {
        precio = this.precioOferta(experiencia, reserva);
        // .toString()
        // .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
      }

      return +precio;
    },
    precioMasBajo() {
      let precioBajo = 0;
      if (this.experiencia) {
        const precioMinimo = this.precioMinimo(this.experiencia.id);
        const precioOfertaMinimo = this.precioOfertaMinimo(this.experiencia.id);
        precioBajo =
          precioOfertaMinimo && precioOfertaMinimo < precioMinimo
            ? precioOfertaMinimo
            : precioMinimo;
      }
      return precioBajo;
    },
    precioPorDia() {
      const { cantidadAdultos, idioma } = this.reserva;
      const { precioFijoTemporadas, preciosConfig } = this.experiencia;

      const precios = [];

      if (preciosConfig.length) {
        this.fechasSeleccionadas.forEach((fecha, i) => {
          //Comparando cantidad de personas ingresadas con precios config
          const reservaConfig = preciosConfig.find((config) => {
            return +config.cantidadPersonas === +cantidadAdultos;
          });

          // Idioma
          if (reservaConfig && reservaConfig[idioma]) {
            let dataDia = {
              precio: +reservaConfig[idioma],
              temporada: "Normal",
              fecha,
            };

            //
            if (
              this.temporadasFechasSeleccionadas[i] &&
              this.temporadasFechasSeleccionadas[i].temporada
            ) {
              const precioConTemporada =
                +reservaConfig[
                  `${idioma}_${this.temporadasFechasSeleccionadas[i].temporada}`
                ];

              dataDia.precio = isNaN(precioConTemporada)
                ? dataDia.precio
                : precioConTemporada;
              dataDia.temporada =
                this.temporadasFechasSeleccionadas[i].temporada;
            }
            precios.push(dataDia);
          }
        });
      } else if (
        this.experiencia.precio ||
        (precioFijoTemporadas && precioFijoTemporadas[0].precio)
      ) {
        this.fechasSeleccionadas.forEach((fecha, i) => {
          const temporadaDeFecha = this.temporadasFechasSeleccionadas[i]
            ? this.temporadasFechasSeleccionadas[i].temporada
            : null;

          let precioTemporada;
          if (temporadaDeFecha != "Normal") {
            precioTemporada = precioFijoTemporadas.find(
              (p) => p.temporada === temporadaDeFecha
            );
          }

          const dataDia = {
            precio: precioTemporada
              ? precioTemporada.precio
              : +this.experiencia.precio,
            temporada: precioTemporada ? temporadaDeFecha : "Normal",
            fecha,
          };

          precios.push(dataDia);
        });
      }
      return precios;
    },
    precioPorDiaOferta() {
      const { oferta, precioOferta, preciosConfig } = this.experiencia;
      const { disponibilidad, cantidadAdultos, idioma } = this.reserva;

      if (oferta && disponibilidad.start && idioma) {
        let conPrecioOferta;
        //Precio fijo
        if (+precioOferta) {
          conPrecioOferta = this.precioPorDia.map((p) => {
            p.precio = +precioOferta;
            return p;
          });
        } else {
          //Precio config
          const reservaConfig = preciosConfig.find((config) => {
            return +config.cantidadPersonas === +cantidadAdultos;
          });

          conPrecioOferta = this.precioPorDia.map((p) => {
            if (p.temporada === "Normal" && reservaConfig[`${idioma}Oferta`]) {
              p.precio = +reservaConfig[`${idioma}Oferta`];
              return p;
            } else if (reservaConfig[`${idioma}Oferta_${p.temporada}`]) {
              p.precio = +reservaConfig[`${idioma}Oferta_${p.temporada}`];
              return p;
            }
            return p;
          });
        }

        return conPrecioOferta ? conPrecioOferta : null;
      } else return null;
    },
    temporadasEnPrecioFinal() {
      const temporadas = this.temporadasFechasSeleccionadas.map((temp) => {
        if (!temp) {
          temp = "Normal";
          return temp;
        } else return temp.temporada;
      });

      return new Set(temporadas);
    },
  },
};
</script>

<style lang="scss">
.experiencia {
  &__iframe {
    width: 100%;
    height: 550px;
  }

  &__list {
    list-style-type: none;
  }
}

#Tour {
  .preciosOferta {
    display: flex;
    align-items: center;
    @media screen and (max-width: 1440px) {
      align-items: flex-start;
      flex-flow: column wrap;
    }
    @media screen and (max-width: 524px) {
      flex-flow: column wrap;
    }
    .oferta-text-exp,
    .oferta-text-exp span {
      color: #d43b32 !important;
      font-weight: 900 !important;
    }
    .oferta-notext-exp {
      color: #868686 !important;
      text-decoration: line-through;
      font-size: 1.25em;
      @media screen and (max-width: 524px) {
        font-size: 1em !important;
      }
      span {
        margin-top: 10px;
        @media screen and (max-width: 524px) {
          margin-top: 0;
        }
      }
    }
  }
  .total {
    font-weight: 300;
    @media (max-width: 524px) {
      font-size: 16px;
    }
  }

  .hero-section {
    width: 100%;

    color: #000;
    text-align: left;
    position: relative;

    @media (max-width: 524px) {
      position: absolute;
      z-index: 1;
    }

    &__titulo {
      @media (max-width: 524px) {
        // background: rgba(61, 153, 255, 0.6);
        // text-shadow: 0 0 5px #00000050;
        padding: 15px 0;
      }

      // background: #3d99ff;
      padding: 20px 0;
      // color: white;
    }

    &__heading {
      text-align: left;
      margin: 0;

      @media (max-width: 524px) {
        font-size: 20px !important;
        padding: 0px 15px;
        font-weight: bold;
      }

      font-size: 2.2rem !important;
    }
    &__heading {
      font-size: 40px;
    }
    &__paragraph {
      font-size: 24px;
      font-weight: lighter;
      width: 70%;
      margin: 0 auto;
    }
  }

  .detalles {
    background: #353535;
    padding: 15px;
    color: #fff;
    h5 {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    @media (min-width: 576px) {
      h5 {
        font-size: 20px;
      }
      span {
        font-size: 16px;
      }
    }
  }

  .contain {
    padding: 20px 12vw;

    @media screen and (max-width: 524px) {
      padding: 0;
    }

    .calendario {
      // padding: 0px 30px;
      &__float {
        background: #f6f6f6;
        // position: sticky;
        // top: 100px;
        * {
          font-family: inherit;
        }
      }
      &__titulo {
        font-size: 20px;
        text-align: center;
        font-weight: 700;
        @media (min-width: 975px) {
          font-size: 24px;
        }
      }
      &__precioMasBajo {
          b {
            font-size: 17px;
          }
      }
      &__titulo-paso {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 1em;
        div {
          background: #3d99ff;
          border-radius: 50%;
          color: #fff;
          height: 16px;
          padding: 10px;
          width: 16px;
          margin-right: 8px;
        }
      }

      @media screen and (max-width: 524px) {
        padding: 23px 21px 23px 22px;
      }

      h1 {
        font-weight: 900;
        line-height: 0.8;

        @media screen and (max-width: 524px) {
          font-size: 25px;
          line-height: 30px;
          text-align: center;
        }

        span {
          color: #868686;
          font-size: 24px;
          font-weight: normal;
          display: inline-block;

          @media screen and (max-width: 524px) {
            font-size: 13px;
            font-weight: regular;
            line-height: 16px;
          }
        }
      }

      h6 {
        font-size: 0.775rem;
      }
    }
  }

  .hora {
    // background: #3d99ff;
    border-radius: 6px;
    // color: white;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  select,
  .hora {
    height: 40px;
  }

  .redondo11 {
    border-radius: 11px;
  }

  .reservar,
  .contactanos {
    color: white;
    height: 50px;
    font-size: 18px;
  }

  .reservar {
    background: #ffaa01;
  }

  .reservarInmediata {
    background: #3d99ff;
  }

  .contactanos {
    background: #353535;
  }

  .ubicacion {
    padding: 0 20px 50px 20px;

    @media screen and (max-width: 524px) {
      padding: 0;
      color: white;

      h1 {
        width: 100%;
      }
    }

    h1 {
      color: #3d99ff;
      text-align: center;
      font-weight: bold;

      @media screen and (max-width: 524px) {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        background-color: #3d99ff;
        color: #ffffff;
        padding: 17px 0 15px;
        margin-bottom: 0;
        margin-top: 0;
        text-transform: uppercase;
      }
    }

    img {
      width: 75%;
      margin: auto;
      display: block;

      @media screen and (max-width: 524px) {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 400px;
      }
    }
  }
}
</style>

<style lang="scss">
#MasDeEstaZona {
  color: black;
  text-align: center;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  padding: 33.5px;

  @media screen and (max-width: 524px) {
    padding: 30px 0;
  }

  h1 {
    color: #343434;
    font-size: 48px;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 25px;
      line-height: 28px;
      padding: 0 80px;
      margin-bottom: 20px;
    }

    span {
      color: #ffaa01;
    }
  }

  #carousel {
    a {
      text-decoration: none;
      color: inherit;
    }
    .col > a > div {
      > h3 {
        position: absolute;
        bottom: 0;
        width: calc(100% - 50px);
        font-size: 20px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.3);
        margin: 0;
        text-align: left;
        border-radius: 0 0 10px 10px;
      }

      cursor: pointer;
      overflow: hidden;

      &:hover {
        .capaOscura {
          top: 0px;
        }

        h3 {
          z-index: -10;
        }
      }
    }

    .capaOscura {
      transition: all 300ms;
      height: 250px;
      position: relative;
      top: 250px;
      z-index: 2;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px;

      .calificaciones {
        display: flex;
        font-weight: lighter;

        .stars {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
        }
      }
    }

    .expImg {
      margin: 0 10px 0 10px;
      border-radius: 10px;
    }
  }
}

.col div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .calificaciones {
    text-align: right;
    padding: 5px;
    position: absolute;
    top: -250px;
    right: 0;
  }

  .descripcion {
    text-align: left;
    padding: 5px;

    h3 {
      font-weight: bold;
      font-size: 20px;
    }

    p,
    span {
      font-size: 14px;
      font-weight: lighter;
    }
  }
}

.carousel-caption {
  width: 80%;
  margin-left: -5%;
  padding-bottom: 0;
}
</style>

<style lang="scss">
.experiencia {
  &__select {
    &--hora-inicio {
      // background: #3d99ff;
      // color: white;
      // width: 70%;
      border-radius: 5px;
      // border: none;
      min-width: 90px;
      overflow: visible;
      margin: auto;
    }
  }
}

#Tour {
  .nav-item {
    width: 33.33%;
    border-right: groove 1px #00000021;

    &:last-child {
      border-right: none !important;
    }

    color: black !important;

    @media screen and (max-width: 524px) {
      height: 50px;
    }

    &:last-child {
      border-right: none !important;
    }

    a {
      border: none !important;
    }

    .active {
      color: white !important;
      font-weight: 700 !important;
    }
  }
}

.b-calendar-nav {
  position: relative;
  top: 36px;
}

.nav-tabs li {
  font-size: 16px;
  text-align: center;
  background: #f6f6f6;

  a {
    color: #343434;
    font-weight: 400;
  }
}

.tab-content {
  margin: 0 !important;
  border: solid rgb(61, 153, 255) !important;
  padding: 30px !important;
  color: #868686;
  height: 539px;
  overflow-y: scroll;
  text-align: justify;
  font-size: 15px;

  span p {
    @media screen and (max-width: 524px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 25px;
      color: #868686;
    }
  }

  @media screen and (max-width: 524px) {
    border: none !important;
    text-align: left;
    padding: 15px 35px !important;
  }

  &::-webkit-scrollbar {
    width: 8px;
    /* Tamaño del scroll en vertical */
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 15px;
  }

  &::-webkit-scrollbar-thumb:hover {
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
  }

  &::-webkit-scrollbar-thumb:active {
    background-color: #3d99ff;
  }
}

.nav-link {
  padding: 1.5rem 0px !important;
}

.form-control {
  &:focus {
    -webkit-box-shadow: 0;
    box-shadow: 0;
  }
}
</style>

<style lang="scss" scoped>
@media (max-width: 767px) {
  p {
    font-size: 0.875rem;
  }
  ul {
    li {
      font-size: 0.875rem;
    }
  }
}

.accordion {
  .not-collapsed .flechaCollapse {
    transform: rotateX(180deg);
  }

  .btn {
    background: #fff;
    color: #000;
    min-height: 40px;
    .flechaCollapse {
      color: #3d99ff;
      transition: transform 0.6s ease;
    }
    &:focus {
      background: #fff;

      box-shadow: 0;
    }
    &:active {
      background: #fff !important;

      box-shadow: 0;
    }
    &:hover {
      // background: #FFF;

      background: #ddd;
    }
    .b-rating-value {
      color: #000;
    }
  }
  .b-card .b-card-body {
    min-height: 100px !important;
  }
}
.rating {
  background-color: transparent;
  border: 0;
  color: #fff;
  &:focus {
    border: 0;
    background-color: transparent !important;
    box-shadow: 0;
    -webkit-box-shadow: 0;
    color: inherit;
  }
  &:active {
    border: 0;
    color: inherit;
  }
}
.btnVerMas {
  min-height: initial;
}
</style>

