<template>
  <div id="ExperienciasImperdibles">
    <div v-show="isMobile()">
      <h1 v-html="getDestTitle"></h1>

      <b-card
        overlay
        :img-src="dest.carouselImgUrl"
        img-height="120"
        img-alt="img"
        v-for="(dest, index) in getDestinos
          .filter(d => d.heroImgUrls && d.heroImgUrls.length > 0)
          .map(des => {
            const order =
              des.localizacion &&
              des.localizacion.latitud &&
              parseInt(des.localizacion.latitud)
                ? parseInt(des.localizacion.latitud)
                : 999;
            return { ...des, order };
          })
          .sort((des1, des2) =>
            des1.order > des2.order ? 1 : des1.order < des2.order ? -1 : 0
          )"
        :key="index"
        @click="sendDest(dest.id)"
      >
        <div class="center-all blackCapa">
          <p class="child">{{
                language === "en" && dest.ingles.titulo
                  ? dest.ingles.titulo
                  : dest.titulo
              }}</p>
        </div>
      </b-card>

      <br />

      <h1 v-html="getExpTitle"></h1>

      <div class="swiper swiper-mobile">
        <!-- Additional required wrapper -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div
            class="swiper-slide swiper-width-m"
            v-for="(exp, index) in slideExperiencias
              ? slideExperiencias
                  .map(slex => slex[0])
                  .map(slex => {
                    const order =
                      slex.stock && parseInt(slex.stock)
                        ? parseInt(slex.stock)
                        : 999;
                    return { ...slex, order };
                  })
                  .sort((des1, des2) =>
                    des1.order > des2.order
                      ? 1
                      : des1.order < des2.order
                      ? -1
                      : 0
                  )
              : []"
            :key="index"
          >
            <!-- 21.4em -->
            <div
              style="border-left: 1px solid #858585; border-right: 1px solid #858585;"
            >
              <b-form-rating
                v-if="
                  evaluacionesByExperiencia(exp.id).length > 0 &&
                  promedioRates(exp.id) > 0
                    ? true
                    : false
                "
                :value="
                  evaluacionesByExperiencia(exp.id).length > 0
                    ? promedioRates(exp.id)
                    : exp.puntaje
                "
                no-border
                readonly
                inline
                variant="warning"
                style="position: absolute; background: none;"
              ></b-form-rating>
              <b-card-img :src="exp.carouselImgUrl" height="230" width="260" />
              <!-- <b-card-header :header-html="htmlTemp(exp.carouselImgUrl)"/> -->
              <b-card-text class="text-left" style="margin: 20px;">
                <p style="font-size: 10px;">{{
                    language === "en" && exp.ingles.tituloDestino
                      ? exp.ingles.tituloDestino
                      : exp.tituloDestino
                  }}</p>
              </b-card-text>

              <b-card-text class="text-left" style="margin: 20px;">
                <p style="font-size: 14px; font-weight: bold; color: black;">
                  {{
                    language === "en" && exp.ingles.titulo
                      ? exp.ingles.titulo
                      : exp.titulo
                  }}
                </p>
              </b-card-text>

              <b-card-text class="text-left" style="margin: 20px;">
                <p style="font-size: 12px;">
                  {{ language === "en" ? "From" : "Desde" }}
                  <strong
                    >${{
                      (exp.precioMasBajo
                        ? exp.precioMasBajo
                        : precioMasBajo(exp)
                      )
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}</strong
                  >
                  | {{ exp.cantidadDias }} {{ language === "en" ? "days" : "días" }}
                </p>
              </b-card-text>

              <b-card-text style="margin: 20px;">
                <p class="text-justify" style="font-size: 13px;">
                  {{
                    language === "en" && exp.ingles.resumen
                      ? exp.ingles.resumen.length > 120
                        ? exp.ingles.resumen.substring(0, 120)
                        : exp.ingles.resumen
                      : exp.resumen.length > 120
                      ? exp.resumen.substring(0, 120)
                      : exp.resumen
                  }}...
                </p>
              </b-card-text>

              <b-button
                @click="sendExp(exp.id)"
                style="background-color: #FFAA01; border-color: #FFAA01;"
                >Ver detalles</b-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-show="!isMobile()">
      <h1 v-html="getDestTitle"></h1>
      <!-- Vista Escritorio -->

      <div class="wrapper center-all">
        <b-card
          overlay
          :img-src="dest.carouselImgUrl"
          img-height="200"
          style="width: 20rem;"
          img-alt="img"
          v-for="(dest, index) in [...getDestinos]
            .filter(d => d.heroImgUrls && d.heroImgUrls.length > 0)
            .map(des => {
              const order =
                des.localizacion &&
                des.localizacion.latitud &&
                parseInt(des.localizacion.latitud)
                  ? parseInt(des.localizacion.latitud)
                  : 999;
              return { ...des, order };
            })
            .sort((des1, des2) =>
              des1.order > des2.order ? 1 : des1.order < des2.order ? -1 : 0
            )"
          :key="index"
          @click="sendDest(dest.id)"
        >
          <div class="center-all blackCapa-desktop">
            <p class="child">
              {{
                language === "en" && dest.ingles.titulo
                  ? dest.ingles.titulo
                  : dest.titulo
              }}
            </p>
          </div>
        </b-card>
      </div>

      <!-- <b-carousel
        class="d-none d-sm-block"
        id="carousel"
        :interval="6000"
        controls
      >
        <b-carousel-slide
          class="c-slide"
          v-for="(contenido, index) in slideExperiencias"
          :key="index"
        >
          <b-row>
            <b-col
              v-for="(experiencia, index) in contenido"
              :key="index"
              class="col-12 col-sm-6 col-md-4"
            >
              <router-link :to="`/experiencias/${experiencia.id}`">
                <div
                  class="expImg"
                  :style="{
                    background: `url('${getThumbnail({
                      contentUrl: experiencia.carouselImgUrl,
                      size: 480
                    })}') no-repeat center center / cover`
                  }"
                >
                  <h3>
                    {{
                      language === "es"
                        ? experiencia.titulo
                        : experiencia.ingles.titulo
                    }}
                  </h3>
                  <div class="capaOscura">
                    <div class="calificaciones">
                      <div
                        v-if="evaluacionesByExperiencia(experiencia.id).length"
                        class="d-flex flex-row align-items-center"
                      >
                        <b-form-rating
                          :value="promedioRates(experiencia.id)"
                          no-border
                          readonly
                          inline
                          variant="warning"
                          class="rating"
                        ></b-form-rating>
                        <p class="m-0">
                          ({{
                            evaluacionesByExperiencia(experiencia.id).length
                          }})
                        </p>
                      </div>
                    </div>
                    <div class="descripcion">
                      <h3>
                        {{
                          language === "es"
                            ? experiencia.titulo
                            : experiencia.ingles.titulo
                        }}
                      </h3>
                      <span>
                        {{
                          language === "es"
                            ? experiencia.resumen
                            : experiencia.ingles.resumen
                        }}
                      </span>
                    </div>
                  </div>
                </div>
              </router-link>
            </b-col>
          </b-row>
        </b-carousel-slide>
      </b-carousel> -->

      <h1 v-html="getExpTitle" style="margin: 30px;"></h1>

      <div class="swiper swiper-desk">
        <!-- Additional required wrapper  style="margin-left: 100px;" -->
        <div class="swiper-wrapper">
          <!-- Slides -->
          <div
            class="swiper-slide swiper-width-d"
            v-for="(exp, index) in slideExperiencias
              ? slideExperiencias
                  .reduce((acum, slex) => {
                    slex.forEach(s => acum.push(s));
                    return acum;
                  }, [])
                  .map(slex => {
                    const order =
                      slex.stock && parseInt(slex.stock)
                        ? parseInt(slex.stock)
                        : 999;
                    return { ...slex, order };
                  })
                  .sort((des1, des2) =>
                    des1.order > des2.order
                      ? 1
                      : des1.order < des2.order
                      ? -1
                      : 0
                  )
              : []"
            :key="index"
          >
            <!-- 21.4em -->
            <div
              style="border-left: 1px solid #858585; border-right: 1px solid #858585;"
            >
              <b-form-rating
                v-if="
                  evaluacionesByExperiencia(exp.id).length > 0 &&
                  promedioRates(exp.id) > 0
                    ? true
                    : false
                "
                :value="
                  evaluacionesByExperiencia(exp.id).length > 0
                    ? promedioRates(exp.id)
                    : exp.puntaje
                "
                no-border
                readonly
                inline
                variant="warning"
                style="position: absolute; background: none;"
              ></b-form-rating>
              <b-card-img :src="exp.carouselImgUrl" height="230" width="300" />
              <!-- <b-card-header :header-html="htmlTemp(exp.carouselImgUrl)"/> -->
              <b-card-text class="text-left" style="margin: 20px;">
                <p style="font-size: 10px;">
                  {{
                    language === "en" && exp.ingles.tituloDestino
                      ? exp.ingles.tituloDestino
                      : exp.tituloDestino
                  }}
                </p>
              </b-card-text>

              <b-card-text class="text-left" style="margin: 20px;">
                <p style="font-size: 14px; font-weight: bold; color: black;">
                  {{
                    language === "en" && exp.ingles.titulo
                      ? exp.ingles.titulo
                      : exp.titulo
                  }}
                </p>
              </b-card-text>

              <b-card-text class="text-left" style="margin: 20px;">
                <p style="font-size: 12px;">
                  {{ language === "en" ? "From" : "Desde" }}
                  <strong
                    >${{
                      (exp.precioMasBajo
                        ? exp.precioMasBajo
                        : precioMasBajo(exp)
                      )
                        .toString()
                        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")
                    }}</strong
                  >
                  | {{ exp.cantidadDias }} {{ language === "en" ? "days" : "días" }}
                </p>
              </b-card-text>

              <b-card-text style="margin: 20px;">
                <p class="text-justify" style="font-size: 13px;">
                  {{
                    language === "en" && exp.ingles.resumen
                      ? exp.ingles.resumen.length > 120
                        ? exp.ingles.resumen.substring(0, 120)
                        : exp.ingles.resumen
                      : exp.resumen.length > 120
                      ? exp.resumen.substring(0, 120)
                      : exp.resumen
                  }}...
                </p>
              </b-card-text>

              <a
                class="btn btn-secondary"
                @click="sendExp(exp.id)"
                style="background-color: #FFAA01; border-color: #FFAA01;"
                >{{ language === "en" ? "See details" : "Ver detalles" }}</a
              >
              <!-- <p
                @click="sendExp(exp.id)"
                style="background-color: #FFAA01; border-color: #FFAA01;"
              >
                Ver detalles
              </p> -->
              <p></p>
            </div>
          </div>
        </div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";
import { Carousel, Slide } from "vue-carousel";
// import VueSlickCarousel from "vue-slick-carousel";
// import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
export default {
  props: ["experiencias"],
  name: "ExperienciasImperdibles",
  mounted() {
    const swiper = new Swiper(".swiper-mobile", {
      // Optional parameters
      slidesPerView: "auto",
      spaceBetween: 20,
      centeredSlides: "auto",

      // If we need pagination
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },

      // And if we need scrollbar
      scrollbar: {
        el: ".swiper-scrollbar"
      }
    });
    const swiperDesk = new Swiper(".swiper-desk", {
      // Optional parameters
      slidesPerView: "auto",
      spaceBetween: 30,
      // centeredSlides: "auto",

      // If we need pagination
      // pagination: {
      //   el: ".swiper-pagination",
      //   clickable: true
      // },

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      }

      // And if we need scrollbar
      // scrollbar: {
      //   el: ".swiper-scrollbar"
      // }
    });
  },
  data() {
    return {};
  },
  components: {
    Carousel,
    Slide
  },
  methods: {
    precioMasBajo(experiencia) {
      // console.log('title', experiencia.titulo)
      let precioBajo = 0;
      const precioMinimo = this.precioMinimo(experiencia.id);
      // console.log('precioMinimo', precioMinimo)
      const precioOfertaMinimo = this.precioOfertaMinimo(experiencia.id);
      // console.log('precioOfertaMinimo', precioOfertaMinimo)
      precioBajo =
        precioOfertaMinimo && precioOfertaMinimo < precioMinimo
          ? precioOfertaMinimo
          : precioMinimo;
      return precioBajo;
    },
    sendDest(destId) {
      this.$router.push(`/destinos/${destId}/experiencias`);
    },
    sendExp(expId) {
      console.log("expId", expId);
      this.$router.push(`/experiencias/${expId}`);
    },
    htmlTemp(src) {
      return `<img src="${src}" alt="image" style="width: 200px; height: 230px">`;
    },
    ExperienciaGo() {
      this.$router.push("/Experiencia");
    },
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  },
  computed: {
    ...mapGetters([
      "language",
      "getExperiencias",
      "getDestinos",
      "precioMinimo",
      "precioOfertaMinimo"
    ]),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapGetters("ExpImperdibles", ["ExpImperdiblesExp", "ExpImperdiblesImp"]),
    ...mapGetters("Evaluaciones", [
      "evaluacionesByExperiencia",
      "promedioRates"
    ]),
    ...mapGetters("i18n", ["getDestTitle", "getExpTitle"]),

    slideExperiencias() {
      // Cambiar la cantidad de elementos ingresados en array dependiendiendo del viewport width
      const vw = Math.max(
        document.documentElement.clientWidth || 0,
        window.innerWidth || 0
      );
      let elements = 1;

      if (vw >= 768) {
        elements = 3;
      } else if (vw >= 576) {
        elements = 2;
      }

      if (this.experiencias) {
        const experiencias = this.getExperiencias.filter(experiencia =>
          this.experiencias.includes(experiencia.id)
        );
        if (experiencias.length > 0) {
          const contenidoArray = [];
          const loops = Math.ceil(experiencias.length / elements);

          for (let i = 0; i < loops; i++) {
            contenidoArray.push(
              experiencias.slice(i * elements, i * elements + elements)
            );
          }
          return contenidoArray;
        }
      }
      return null;
    }
  }
};
</script>

<style scoped>
/* .swiper {
  width: 360px;
  height: 600px;
} */

.wrapper {
  /* display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px; */
  display: flex;
  flex-wrap: wrap;
  /* grid-auto-rows: 100px; */
}

.blackCapa {
  background-color: rgba(0, 0, 0, 0.35);
  height: 120px;
}
.blackCapa-desktop {
  background-color: rgba(0, 0, 0, 0.35);
  height: 200px;
  cursor: pointer;
}
.center-all {
  display: flex;
  justify-content: center;
  align-items: center;
}
.child {
  /* width: 50px;
  height: 50px; */
  /* background-color: #3D99FF; */
  margin-top: 1.5em;
  font-size: 19px;
  font-weight: bold;
  color: #fefbf6;
  line-height: 30px;
  font-weight: 600;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  /* font-size: 18px; */
  /* background: rgba(255, 255, 255, 0); */

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-width-m {
  width: 260px;
}
.swiper-width-d {
  width: 300px;
}
</style>

<style lang="scss">
#ExperienciasImperdibles {
  color: black;
  text-align: center;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  padding: 33.5px;

  @media screen and (max-width: 524px) {
    padding: 28px 20px;
  }

  h1 {
    color: #343434;
    font-size: 48px;
    font-weight: bold;

    span {
      color: #ffaa01;
    }

    @media screen and (max-width: 524px) {
      font-size: 25px;
      margin: 0 30px 30px;
    }
  }

  .carousel-control-next-icon {
    background: url("../assets/img/iconos/right.svg") !important;
    background-size: 100% 120% !important;
    height: 45px !important;
    width: 45px !important;
    position: relative;
    top: -20px;
  }

  .carousel-control-prev-icon {
    background: url("../assets/img/iconos/left.svg") !important;
    background-size: 100% 120% !important;
    height: 45px !important;
    width: 45px !important;
    position: relative;
    top: -20px;
  }

  .carousel-caption {
    width: 80%;
    margin-left: -5%;
    padding-bottom: 0;
  }

  #carousel {
    a {
      text-decoration: none;
      color: inherit;
    }
    .c-slide {
      height: 300px;

      @media screen and (max-width: 524px) {
        height: 270px;
      }
    }

    .col > a > div {
      > h3 {
        position: absolute;
        bottom: 0;
        width: calc(100% - 50px);
        font-size: 20px;
        padding: 10px;
        background: rgba(0, 0, 0, 0.3);
        margin: 0;
        text-align: left;
        border-radius: 0 0 10px 10px;
        @media screen and (max-width: 524px) {
          font-size: 14px;
          font-weight: bold;
        }
      }

      cursor: pointer;
      overflow: hidden;

      &:hover {
        .capaOscura {
          top: 0px;
        }

        h3 {
          z-index: -10;
        }
      }
    }

    .capaOscura {
      transition: all 300ms;
      height: 250px;
      position: relative;
      top: 250px;
      z-index: 2;
      background: rgba(0, 0, 0, 0.5);
      padding: 10px;

      .calificaciones {
        display: flex;
        font-weight: lighter;
      }
    }

    .expImg {
      margin: 0 10px 0 10px;
      border-radius: 10px;
    }
  }
  .rating {
    background-color: transparent;
    border: 0;
    color: #fff;
    &:focus {
      border: 0;
      background-color: transparent !important;
      box-shadow: 0;
      -webkit-box-shadow: 0;
      color: inherit;
    }
    &:active {
      border: 0;
      color: inherit;
    }
  }
}

.col div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .calificaciones {
    text-align: right;
    padding: 5px;
    position: absolute;
    top: -250px;
    right: 0;
  }

  .descripcion {
    text-align: left;
    padding: 5px;

    h3 {
      font-weight: bold;
      font-size: 20px;
    }

    p,
    span {
      font-size: 14px;
      font-weight: lighter;
    }
  }
}
</style>