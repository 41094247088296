<template>
  <div class="container-fluid">
    <div class="mt-3 mb1 px-4">
      <b-button :to="{ name: 'AdminHome' }" variant="success">
        Volver a home de admin
      </b-button>
    </div>
    <b-row class="mt-5 mb-1 px-4">
      <b-col class="text-center text-white" cols="12" md="9">
        <h1>Lista Usuarios</h1>
      </b-col>
    </b-row>
    <b-row class="p-4">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="my-2">
            <b-form-group label="Filtrar por palabra" label-cols-sm="6" label-align-sm="right" label-size="md"
              label-for="filterInput" class="mb-1 text-white">
              <b-input-group size="md">
                <b-form-input v-model="filter" type="search" id="filterInput" class="w-100"
                  placeholder="Ingresa un término..."></b-form-input>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <b-table show-empty :fields="reservasTitulos" :items="usuarios" :current-page="currentPage" :per-page="perPage"
          :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :filter="filter"
          empty-text="Aun no existen usuarios." empty-filtered-text="Sin registros que coincidan con la búsqueda..."
          head-variant="dark" table-variant="light" striped hover @filtered="onFiltered">
          <template v-slot:cell(index)="data">
            {{ data.index + 1 }}
          </template>
        </b-table>
        <b-row>
          <b-col cols="12" class="my-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" per-page="5" align="fill" size="md"
              class="my-0"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from "vuex";

export default {
  name: "ListUsuarios",
  metaInfo: {
    title: "Lista de Usuarios",
  },
  data() {
    return {
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 7,
      sortBy: "fechaActual",
      sortDesc: true,
      sortDirection: "desc",
      reservasTitulos: [
        { key: "index", label: "N°" },
        {
          key: "nombre",
          label: "Nombre",
          sortable: true,
          class: "text-center"
        },
        {
          key: "apellido",
          label: "Apellido",
          sortable: true,
          class: "text-center"
        },
        {
          key: "correo",
          label: "Correo",
          sortable: true,
          class: "text-center"
        },
        {
          key: "telefono",
          label: "Telefono",
          sortable: true,
          class: "text-center"
        },
        {
          key: "pais",
          label: "Pais",
          sortable: true,
          class: "text-center"
        },
        {
          key: "ciudad",
          label: "Ciudad",
          sortable: true,
          class: "text-center"
        },
        // Columna Acciones
        /* { 
          key: "acciones", 
          label: "Acción", 
          class: "text-center"
        } */
      ]
    };
  },
  created() {
    this.getUsuarios();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.usuarios.length;
  },
  computed: {
    ...mapState("usuarios", ["usuarios"])
  },
  methods: {
    ...mapActions("usuarios", [
      "getUsuarios",
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    }
  }
};
</script>

<style></style>
