var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"MenuTop"}},[_c('b-row',{staticClass:"menu-top"},[_c('b-container',[_c('ul',{staticClass:"menu-top__list"},[_c('li',{class:{
            'menu-top__list-item': true,
            'menu-top__list-item--active': (_vm.language === 'es')
          },on:{"click":function($event){(_vm.language === 'es') ? null : _vm.switchLanguage()}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/iconos/esp.svg"),"alt":"ESP"}}),_vm._v(" ES ")]),_c('li',{class:{
            'menu-top__list-item': true,
            'menu-top__list-item--active': (_vm.language === 'en')
          },on:{"click":function($event){(_vm.language === 'en') ? null : _vm.switchLanguage()}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/iconos/eng.png"),"alt":"ENG"}}),_vm._v(" EN ")]),_c('li',{staticClass:"menu-top__list-item"},[_vm._v(" CLP "),_c('svg',{staticClass:"menu-top__svg",attrs:{"viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"currentColor","d":"M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"}})])]),_c('li',{staticClass:"menu-top__list-item menu-top__list-item--2"},[_c('router-link',{attrs:{"to":{ name: 'Checkout' }}},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"src":require("@/assets/img/iconos/carrito.svg"),"alt":""}})])],1),_c('li',{staticClass:"menu-top__list-item menu-top__list-item--2"},[_vm._v("0")])])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }