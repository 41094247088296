/* eslint-disable */
export default {
  namespaced: true,

  state: {
    DestinosESP: {
      experiencias: "EXPERIENCIAS",
      conoce: "CONOCE MÁS SOBRE EL DESTINO"
    },
    DestinosENG: {
      experiencias: "EXPERIENCES",
      conoce: "KNOW ABOUT THIS DESTINATION"
    }
  },
  getters: {
    experienciasNombre(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.DestinosESP.experiencias;
      } else if (rootState.language == "en") {
        return state.DestinosENG.experiencias;
      }
    },
    conoce(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.DestinosESP.conoce;
      } else if (rootState.language == "en") {
        return state.DestinosENG.conoce;
      }
    }
  },
  mutations: {},
  actions: {}
};
