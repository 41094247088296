<template>
  <div id="FooterBottom">
    <router-link to="/">
      <img src="@/assets/img/logo.png" alt="SurRealLogo" />
    </router-link>
    <img src="@/assets/img/logo_corfo.png" alt="Logo corfo">
    <span
      >SurReal Travel SpA 2020. <span>Todos los derechos reservados</span></span
    >
  </div>
</template>

<style lang="scss" >
#FooterBottom {
  background: #f6f6f6;
  height: fit-content;
  // height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    width: 230px;
  }
  span {
    color: #3d99ff;
  }
  @media screen and (max-width: 900px) {
    height: 330px;
    flex-flow: column;
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;

    img {
      margin-bottom: -15px;
    }

    span {
      font-size: 12px;
      text-align: center;

      span {
        display: inline-block;
        padding-top: 3px;
      }
    }
  }
}
</style>

<script>
export default {
  name: "FooterBottom",
};
</script>
