import firebase from 'firebase'
import 'firebase/firestore'

export default async function storageURLs (storageUpdateConfig, itinerariosDias = null) {
  // Una variable booleana para chequear si fue activada alguna
  // de las actualizaciones en storage

  // Y un objeto inicial para guardar todos los updates que se van a aplicar
  const storageUpdates = {}
  let storageUpdateAvailable = false



  if (storageUpdateConfig.carouselImageUpdate) {
    const storageResult = await storageUpdateConfig.carouselImageUpdate
    const downloadURL = await storageResult.ref.getDownloadURL()
    storageUpdates.carouselImgUrl = downloadURL
    storageUpdateAvailable = true
  }
  
  if (storageUpdateConfig.itinerarioDiasUpdates && storageUpdateConfig.itinerarioDiasUpdates.length > 0) {
    const storageResult = await Promise.all(storageUpdateConfig.itinerarioDiasUpdates)

    const contenidoImgsMapping = storageResult.map((result) => (result != undefined) ? result.ref.getDownloadURL() : "")
    console.log(contenidoImgsMapping)

    const downloadURLs = await Promise.all(contenidoImgsMapping)
    const newItinerarioDia = itinerariosDias.map((contenido, index) => {
      if(contenido.img){
        const newContenido = {
          titulo: contenido.titulo,
          tituloIngles: contenido.tituloIngles,
          descripcion: contenido.descripcion,
          description: contenido.description,
          noche: contenido.noche,
          overnigth: contenido.overnigth,
          imgUrl: downloadURLs[index]
        }
        return newContenido
      }
    })
    const tieneUndefined = newItinerarioDia.some((elemento) => elemento === undefined);

    if(!tieneUndefined){
      storageUpdates.ItinerarioDia = firebase.firestore.FieldValue.arrayUnion(...newItinerarioDia)
      storageUpdateAvailable = true
    }
  }

  return {
    storageUpdateAvailable,
    storageUpdates
  }
}
