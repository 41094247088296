<template>
  <div class="container-fluid">
    <div class="mt-3 mb1 px-4">
      <b-button :to="{ name: 'AdminHome' }" variant="success">
        Volver a home de admin
      </b-button>
    </div>
    <b-row class="mt-5 mb-1 px-4">
      <b-col class="text-center text-white" cols="12" md="9">
        <h1>Reservaciones Por Confirmar</h1>
      </b-col>
      <b-col cols="12" md="3">
        <b-button :to="{ name: 'CalendarioReservas' }" variant="success">
          Ver calendario
        </b-button>
      </b-col>
    </b-row>
    <b-row class="p-4">
      <b-col cols="12">
        <b-row>
          <b-col cols="12" class="my-2">
            <b-form-group label="Filtrar por palabra" label-cols-sm="6" label-align-sm="right" label-size="md"
              label-for="filterInput" class="mb-1 text-white">
              <b-input-group size="md">
                <b-form-input v-model="filter" type="search" id="filterInput" class="w-100"
                  placeholder="Ingresa un término..."></b-form-input>
                <!-- <b-input-group-append>
                      <b-button :disabled="!filter" @click="filter = ''"
                        >Limpiar</b-button
                      >
                    </b-input-group-append> -->
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <div class="tabla">
          <b-table show-empty :fields="reservasTitulos" :items="reservacionesPorConfirmar" :current-page="currentPage"
            :per-page="perPage" :filter="filter" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection" empty-text="Aun no existen reservaciones."
            empty-filtered-text="Sin registros que coincidan con la búsqueda..." head-variant="dark"
            table-variant="light" striped hover @filtered="onFiltered">
            <template v-slot:cell(index)="data">
              {{ data.index + 1 }}
            </template>
            <template v-slot:cell(acciones)="data">
              <div class="container-fluid">
                <b-row>
                  <b-col cols="12" v-if="data.item.estado === 'Pendiente'" class="
                      text-center
                      d-flex
                      align-items-center
                      justify-content-around
                      pb-3
                    ">
                    <b-icon @click="aceptarReservacion(data.item)" class="iconHover" icon="check-circle-fill"
                      variant="success" font-scale="2" />
                    <b-icon @click="denegarReservacion(data.item.id)" class="iconHover" icon="x-circle-fill"
                      variant="danger" font-scale="2" />
                  </b-col>
                  <b-col cols="12">
                    <b-dropdown id="dropdownAcciones" split variant="primary" split-variant="outline-light"
                      @click="data.toggleDetails">
                      <template #button-content>
                        <b-icon class="iconHover" icon="card-text" variant="primary" font-scale="1.5" />
                      </template>

                      <b-dropdown-item-button @click="marcarReservaComoPagada(data.item)" v-if="
                          data.item.estado === 'Pendiente de Pago' ||
                          data.item.estado === 'Pendiente de primer pago' ||
                          data.item.estado === 'Pendiente de segundo pago'
                        ">
                        <b-icon class="iconHover" icon="bag-check" variant="primary" font-scale="1.5" />
                        <span>Pagar</span>
                      </b-dropdown-item-button>

                      <b-dropdown-item-button @click="editarReserva(data.item)">
                        <b-icon class="iconHover" icon="pencil" variant="primary" font-scale="1.5" />
                        <span>Editar</span>
                      </b-dropdown-item-button>

                      <b-dropdown-item-button variant="danger" @click="eliminarReserva(data.item.id)">
                        <b-icon class="iconHover" icon="trash" variant="danger" font-scale="1.5" />
                        <span>Eliminar</span>
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </b-col>
                </b-row>
              </div>
            </template>
            <template v-slot:row-details="data">
              <b-card class="ml-2 pl-4 pt-2">
                <ul style="line-height: 2">
                  <li>
                    <strong>Procedencia:</strong> {{ data.item.procedencia }}
                  </li>
                  <li>
                    <strong>N° de Viajeros:</strong> {{ data.item.viajeros }}
                  </li>
                  <li v-if="data.item.detalleViajeros">
                    <strong>Detalle viajeros:</strong>
                    <ul class="ml-4">
                      <li>
                        <strong class="pr-2">Adulto:</strong>{{ data.item.detalleViajeros.adultos.cantidad }}
                      </li>
                      <template v-if="data.item.detalleViajeros.niños">
                        <li v-for="niño in data.item.detalleViajeros.niños" :key="niño.nombre">
                          <strong class="pr-2">{{ niño.nombre }}:</strong> {{ niño.cantidad }}
                        </li>
                      </template>
                    </ul>
                  </li>
                  <li v-if="data.item.permiteAbono">
                    <strong>Abona con:</strong>
                    {{ data.item.abono.porcentajeAbono }}%
                  </li>
                  <li v-if="data.item.permiteAbono">
                    <strong>Primera cuota:</strong> ${{
                    data.item.abono.primeraCuota
                    }}
                  </li>
                  <li v-if="data.item.permiteAbono">
                    <strong>Segunda cuota:</strong> ${{
                    data.item.abono.segundaCuota
                    }}
                  </li>
                  <li><strong>Valor Total:</strong> {{ data.item.total }}</li>
                  <li v-if="data.item.temporadas">
                    <strong>Temporadas en reserva:</strong>
                    <span v-for="(temporada , i) of data.item.temporadas" :key="temporada">
                      {{temporada}} <span
                        v-if="data.item.temporadas.length > 1 && i < data.item.temporadas.length - 1">,</span>
                    </span>
                  </li>
                  <li><strong>Idioma:</strong> {{ data.item.idioma }}</li>
                  <li>
                    <strong>Fecha de Inicio:</strong> {{ data.item.fecha }}
                  </li>
                  <li v-if="data.item.fechaFin">
                    <strong>Fecha de Término:</strong> {{ data.item.fechaFin }}
                  </li>
                  <li>
                    <strong>Hora de Inicio:</strong>
                    {{ data.item.horaInicio }}
                  </li>
                  <li>
                    <strong>Hora de Término:</strong> {{ data.item.horaFin }}
                  </li>
                  <li>
                    <strong>Comentario:</strong> {{ data.item.comentarios }}
                  </li>
                  <li>
                    <strong>¿Vegano o Vegetariano?:</strong>
                    {{ data.item.vegano }}
                  </li>
                  <li>
                    <strong>¿Quiere recibir promociones?:</strong>
                    {{ data.item.promociones }}
                  </li>
                  <li>
                    <strong>¿Es Referido?:</strong> {{ data.item.referencia }}
                  </li>
                </ul>
              </b-card>
            </template>
          </b-table>
        </div>
        <b-row>
          <b-col cols="12" class="my-0">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="fill" size="md"
              class="my-0"></b-pagination>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

    <b-modal id="EditarReserva" size="xl" title="Editar reserva">
      <EditarReservaForm :reserva="reservaAEditar" />
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EditarReservaForm from "@/components/Admin/EditarReservaForm";

export default {
  name: "ReservasPorConfirmar",
  components: {
    EditarReservaForm,
  },
  metaInfo: {
    title: "Reservas por confirmar",
  },
  data() {
    return {
      filter: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 7,
      sortBy: "idUnico",
      sortDesc: true,
      sortDirection: "desc",
      reservasTitulos: [
        { key: "index", label: "N°" },
        {
          key: "fechaActual",
          label: "Fecha de Reserva",
          sortable: true,
          class: "text-center",
        },
        {
          key: "idUnico",
          label: "ID de Reserva",
          sortable: true,
          class: "text-center",
        },
        {
          key: "experiencia",
          label: "Experiencia",
          sortable: true,
          class: "text-center",
        },
        {
          key: "nombre",
          label: "Nombre",
          sortable: true,
          class: "text-center",
        },
        {
          key: "correo",
          label: "Correo Electrónico",
          sortable: true,
          class: "text-center",
        },
        { key: "telefono", label: "Teléfono", class: "text-center" },
        {
          key: "estado",
          label: "Estado",
          sortable: true,
          class: "text-center",
        },
        // Columna Acciones
        { key: "acciones", label: "Acción", class: "text-center" },
      ],
      reservaAEditar: null,
    };
  },
  created() {
    this.getReservas();
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.reservacionesPorConfirmar.length;
  },
  computed: {
    ...mapState("reservaciones", ["reservacionesPorConfirmar"]),
  },
  methods: {
    ...mapActions("reservaciones", [
      "getReservas",
      "aceptarReservaPorConfirmar",
      "rechazarReservaPorConfirmar",
      "marcarComoPagado",
      "eliminarReservacion",
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    aceptarReservacion(index) {
      this.aceptarReservaPorConfirmar(index);
    },
    denegarReservacion(index) {
      this.rechazarReservaPorConfirmar(index);
    },
    marcarReservaComoPagada(reserva) {
      const estado = reserva.estado;
      const idReserva = reserva.idUnico;

      let textoPregunta = "";
      if (
        estado == "Pendiente de Pago" ||
        estado == "Pendiente de segundo pago"
      )
        textoPregunta = `¿Desea marcar la reserva [${idReserva}] como "Pagada"?`;
      else if (estado == "Pendiente de primer pago")
        textoPregunta = `¿Desea marcar la reserva [${idReserva}] como "Pendiente de segundo pago"?`;

      let confirmacion = confirm(textoPregunta);

      confirmacion ? this.marcarComoPagado(reserva) : false;
    },
    editarReserva(reserva) {
      this.reservaAEditar = JSON.parse(JSON.stringify(reserva));
      this.$bvModal.show("EditarReserva");
    },
    eliminarReserva(id) {
      const confirmarEliminacion = confirm("¿Desea eliminar la reserva?");
      if (!confirmarEliminacion) return;
      const reConfirmarEliminacion = confirm(
        "Esta opción es irreversible, ¿Estás seguro que deseas eliminar la reserva?"
      );
      if (!reConfirmarEliminacion) return;

      this.eliminarReservacion(id);
    },
  },
};
</script>

<style lang="scss" scoped>
.tabla {
  overflow-x: scroll;
}
</style>
