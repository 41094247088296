import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";

//Vue meta import
import VueMeta from 'vue-meta'

// Bootstrap imports
import { BootstrapVue, BootstrapVueIcons } from "bootstrap-vue"; // importar componentes
import "bootstrap/dist/css/bootstrap.css"; // incluir los estilos de Bootstrap
import "bootstrap-vue/dist/bootstrap-vue.css"; // Bootstrap vue
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// Leaflet imports
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// import BootstrapVue from 'bootstrap-vue'
import VCalendar from "v-calendar";
import Calendar from "v-calendar/lib/components/calendar.umd";
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import i18n from "./i18n";

//Moment
import moment from 'moment'

//editor

//import Vue2Editor from "vue2-editor";

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

// Register components in your 'main.js'
Vue.component("calendar", Calendar);
Vue.component("date-picker", DatePicker);
//Vue.component("vue-editor", Vue2Editor);
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
  componentPrefix: "vc" // Use <vc-calendar /> instead of <v-calendar />
});

Vue.config.productionTip = false;
Vue.config.devtools = true; //DEBUG

Vue.filter("toReadableDate", function (value, iso) {
  if(iso === 'en'){
    const dias = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ];
    const meses = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ];
    const dateValue = new Date(value);
    const diaName = dias[dateValue.getDay()];
    const diaMesName = dateValue.getDate();
    const mesName = meses[dateValue.getMonth()];
    const año = dateValue.getFullYear();
    const diaString = `${diaName}, ${mesName} ${diaMesName}, ${año}`;
    return diaString;
  }else {
    const dias = [
      "Domingo",
      "Lunes",
      "Martes",
      "Miercoles",
      "Jueves",
      "Viernes",
      "Sabado"
    ];
    const meses = [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ];
    const dateValue = new Date(value);
    const diaName = dias[dateValue.getDay()];
    const diaMesName = dateValue.getDate();
    const mesName = meses[dateValue.getMonth()];
    const año = dateValue.getFullYear();
    const diaString = `${diaName}, ${diaMesName} de ${mesName} de ${año}`;
    return diaString;
  }
});

Vue.filter('fechaToISOString', (stringFecha, hora) => {
  if (!stringFecha) return;
  let fechaDeconstruida = stringFecha.toLowerCase()
    .split(", ")[1]
    .split(" de ");

  if (fechaDeconstruida[1] === "enero") fechaDeconstruida[1] = "january";
  else if (fechaDeconstruida[1] === "enero") fechaDeconstruida[1] = "january";
  else if (fechaDeconstruida[1] === "febrero")
    fechaDeconstruida[1] = "february";
  else if (fechaDeconstruida[1] === "marzo") fechaDeconstruida[1] = "march";
  else if (fechaDeconstruida[1] === "abril") fechaDeconstruida[1] = "april";
  else if (fechaDeconstruida[1] === "mayo") fechaDeconstruida[1] = "may";
  else if (fechaDeconstruida[1] === "junio") fechaDeconstruida[1] = "june";
  else if (fechaDeconstruida[1] === "julio") fechaDeconstruida[1] = "july";
  else if (fechaDeconstruida[1] === "agosto") fechaDeconstruida[1] = "august";
  else if (fechaDeconstruida[1] === "septiembre")
    fechaDeconstruida[1] = "september";
  else if (fechaDeconstruida[1] === "octubre")
    fechaDeconstruida[1] = "october";
  else if (fechaDeconstruida[1] === "noviembre")
    fechaDeconstruida[1] = "november";
  else if (fechaDeconstruida[1] === "diciembre")
    fechaDeconstruida[1] = "december";

  const fechaFinal = hora
    ? `${fechaDeconstruida.join(" ")} ${hora}`
    : fechaDeconstruida.join(" ");

  return moment(fechaFinal).format();
})

Vue.use(VueMeta);
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
