<template>
  <div id="Tours">
    <h1>
      <span class="ExpTitulo">{{ tour }}</span>
    </h1>
    <h3>
      <span style="color: #ffaa01" class="ImpTitulo">{{ inspiredTitle }}</span>
    </h3>
    <div class="tours">
      <b-row class="m-auto">
        <b-col
          v-for="(tour, index) in tours"
          :key="index"
          cols="12"
          sm="4"
          class="mb-4"
        >
          <b-card>
            <b-card-text>
              <div
                class="imagen"
                :style="{
                  'background-image': `url('${getThumbnail({
                    contentUrl: tour.carouselImgUrl,
                    size: 480,
                  })}')`,
                }"
              >
                <div class="capaOscura" style="position: relative">
                  <!-- EN CASO DE MOSTRAR LA IMAGEN DEL MAPA -->
                  <!-- <li style="position: absolute; right: 0; left: 0; bottom: 0">
                    <router-link
                      :to="`/tours/${tour.id}`"
                      class="
                        nuestras-tours__link
                        nuestras-tours__link--ver-mas
                      "
                      >{{__get('seeDetails')}}</router-link
                    >
                  </li> -->


                  <img
                    src="@/assets/img/iconos/share.svg"
                    class="share d-none"
                  />
                </div>
              </div>
              
              <h6
                class="text-left mt-1 px-3"
                style="color: #ffaa01"
              >
                <router-link
                      :to="`/tours/${tour.id}`"
                     
                      >                {{
                            language === "es"
                              ? tour.titulo
                              : tour.ingles.titulo
                          }}
                  </router-link>
              </h6>

              <b-card-text class="small"><p>{{
                            language === "es"
                              ? tour.resumen
                              : tour.ingles.resumen
                          }}</p></b-card-text>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from "vuex";

export default {
  name: "Tours",
  props: ["tours"],
  // data() {
  //   return {
  //     num: 6,
  //   };
  // },
  computed: {
    ...mapGetters([
      "getDestinos",
      "language",
      "reserva",
    ]),
    ...mapGetters("media", ["getThumbnail"]),
    ...mapGetters("Tours", [
      "tour",
      "inspiredTitle",
    ]),
    ...mapGetters("Evaluaciones", [
      "promedioRates",
      "evaluacionesByExperiencia",
    ]),
    ...mapGetters("i18n", ["__get"]),
    toursLongitud() {
      return this.tours.length;
    },
  },
  methods: {
    // Navegacion programatica para tours
    navigateToExperiencia(event, experiencia) {
      event.preventDefault();
      this.$router.push({
        path: `/tours/${experiencia.id}`,
      });
    },
  },
};
</script>

<style lang="scss">
.nuestras-tours {
  &__link {
    &--ver-mas,
    &--ver-mas:visited,
    &--ver-mas:active,
    &--ver-mas:hover {
      background: #ffaa01;
      width: 100px;
      font-weight: 500;
      border-radius: 17px;
      color: #353535;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      margin: auto;

      @media screen and (max-width: 524px) {
        font-size: 11px;
        font-weight: 700;
        padding: 6px 0;
      }
    }
  }
}

#Tours {
  color: transparent;
  text-align: center;
  padding: 33.5px;

  @media screen and (max-width: 524px) {
    padding: 20px 36px;
  }

  .btn-cargar {
    @media screen and (max-width: 524px) {
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
      font-size: 13px;
      line-height: 40px;
      color: #fff;
      border-radius: 24px;
      background-color: #3d99ff;
      width: 129px;
      height: 42px;
      margin: auto;
    }
  }

  .text-cargar {
    @media screen and (max-width: 524px) {
      text-align: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 28px;
      color: #868686;
      margin-bottom: 23px;
    }
  }

  .tours .row {
    width: 85%;

    @media screen and (max-width: 524px) {
      width: 100%;
    }

    .col-12 {
      @media screen and (max-width: 524px) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .tours .text-muted p {
    color: #ffaa01;
    margin: 0;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 16px;
    }
  }

  h1 {
    color: #343434;
    font-size: 48px;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 25px;
      margin: 0 20px 20px;
    }

    p {
      color: #ffaa01;
    }
  }

  .calificaciones {
    // padding: 10px;
    text-align: right;
    font-weight: lighter;

    .stars {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      div > p {
        font-size: 12px;

        @media screen and (max-width: 524px) {
          font-size: 10px;
        }
      }
    }

    > span {
      @media screen and (max-width: 524px) {
        font-size: 14px;
      }
    }
  }

  .share {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  .imagen {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 200px;

    @media screen and (max-width: 524px) {
      border-radius: 10px 10px 0 0;
    }
  }

  .capaOscura {
    background: rgba(0, 0, 0, 0.25);
    height: 200px;
    position: relative;
    @media screen and (max-width: 524px) {
      border-radius: 10px 10px 0 0;
    }

    h6 {
      background: #ffaa01;
      width: 100px;
      border-radius: 17px;
      padding: 5px;
      color: #353535;
      position: absolute;
      bottom: -15px;
      left: 0;
      right: 0;
      margin: auto;

      @media screen and (max-width: 524px) {
        font-size: 11px;
        font-weight: 700;
      }
    }
  }
}

.card-body {
  padding: 0 !important;
  min-height: 360px !important;
  @media screen and (max-width: 524px) {
    min-height: 1px;
  }
}

.card {
  margin: 5px;
  border-radius: none;
  position: relative;
  @media screen and (max-width: 524px) {
    border-radius: 10px !important;
    margin: 0 0 35px;
  }

  .card-text > p {
    color: #868686;
    text-align: left;
    padding: 0px 10px;
    margin: 0;

    @media screen and (max-width: 524px) {
      font-size: 11px;
      font-weight: 500;
      line-height: 20px;
      margin-bottom: 10px;
    }
  }

  .card-text h6 {
    @media screen and (max-width: 524px) {
      font-size: 18px;
      margin-top: 11px;
      margin-bottom: 8px;
    }
  }
}
.card-footer {
  background: transparent !important;
  padding: 5px 1px !important;
}
.capaOscura .oferta-logo {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 22px;
  font-weight: 700;
  bottom: 12%;
  right: 10px;
  width: 4rem;
  height: 4rem;
  background: linear-gradient(to bottom, #d3362d 0%, #e57368 100%);
  filter: drop-shadow(0 0.5rem 0.3em rgba(0, 0, 0, 0.5));
  transform: translate3d(0, 0, 0);
  &:after {
    content: "";
    width: 0;
    height: 0;
    border-right: 2rem solid transparent;
    border-left: 2rem solid transparent;
    border-top: 1rem solid #e57368;
    position: absolute;
    top: 4rem;
    left: 0;
  }
}
.oferta-text {
  color: #d43b32 !important;
  font-size: 1.2em;
}
.oferta-notext {
  color: #868686 !important;
  text-decoration: line-through;
}

.card_rating {
  .rating,
  .form-control {
    color: #fff;
    background: transparent;
    &:focus,
    &:active {
      color: #fff;
      box-shadow: 0;
      background: transparent;
    }
  }
}
</style>
