<template>
  <a href="#ReservaAhora" class="d-lg-none">
    <div id="ReservaAhora">
      <b-icon icon="calendar3" class="mr-2" />
      <p class="m-0">Reserva ahora</p>
    </div>
  </a>
</template>

<script>
export default {
  name: "BotonReservaAhora",
};
</script>

<style lang="scss" scoped>
#ReservaAhora {
  align-items: center;
  background: #3d99ff;
  bottom: 0;
  border-radius: .25em .25em 0 0;
  box-shadow: 0 0 10px #00000050;
  color: #fff;
  display: flex;
  height: 50px;
  justify-content: center;
  position: fixed;
  width: 100%;
  z-index: 10;

}
</style>