<template>
  <b-container
    class="stepper d-flex justify-content-around align-content-center py-4 pb-md-1"
  >
    <div
      class="d-flex justify-content-center align-items-center"
      :class="{ active: $route.name === 'Checkout' }"
    >
      <div
        class="stepper__dot d-flex justify-content-center align-items-center"
      >
        <p>1</p>
      </div>
      <h3 class="p-1 p-md-3">{{__get('bookingoOfRequest')}}</h3>
    </div>

    <div class="d-flex justify-content-center align-items-center disabled">
      <div
        class="stepper__dot d-flex justify-content-center align-items-center"
      >
        <p>2</p>
      </div>
      <h3 class="p-1 p-md-3">{{__get('confirmation')}}</h3>
    </div>

    <div
      class="d-flex justify-content-center align-items-center disabled"
      :class="{ active: $route.name === 'CheckoutResPorConfirmar' }"
    >
      <div
        class="stepper__dot d-flex justify-content-center align-items-center"
      >
        <p>3</p>
      </div>
      <h3 class="p-1 p-md-3">{{__get('payment')}}</h3>
    </div>
  </b-container>
</template>

<script>

import { mapGetters } from "vuex";

export default {
  name: "Stepper",
  computed: {...mapGetters([
      "language",
    ]),
    ...mapGetters("i18n", ["__get"]),
  }
};
</script>

<style lang="scss" scoped>
.stepper {
  div {
    opacity: 0.8;
  }
  h3 {
    font-size: 12px;
    margin: 0;
  }
  &__dot {
    border-radius: 50%;
    background: #868686;
    height: 1rem;
    width: 1rem;
    p {
      margin: 0;
      color: #fff;
      font-size: 0.5rem;
    }
  }
  .active {
    color: #3d99ff;
    opacity: 1;
    div {
      background: #3d99ff;
    }
    h3 {
      font-weight: 700;
    }
  }
}

@media (min-width: 960px) {
  .stepper {
    h3 {
      font-size: 16px;
    }
    &__dot {
      height: 1.5rem;
      width: 1.5rem;
      p {
        font-size: 1rem;
      }
    }
  }
}
</style>