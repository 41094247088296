/* eslint-disable */
export default {
  namespaced: true,

  state: {
    ZonasESP: {
      resumen: "RESUMEN",
      mapa: "MAPA",
      conoce: "CONOCE MÁS"
    },
    ZonasENG: {
      resumen: "OVERVIEW",
      mapa: "MAP",
      conoce: "KNOW ABOUT THIS ZONE"
    }
  },
  getters: {
    resumen(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ZonasESP.resumen;
      } else if (rootState.language == "en") {
        return state.ZonasENG.resumen;
      }
    },
    mapa(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ZonasESP.mapa;
      } else if (rootState.language == "en") {
        return state.ZonasENG.mapa;
      }
    },
    conoce(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.ZonasESP.conoce;
      } else if (rootState.language == "en") {
        return state.ZonasENG.conoce;
      }
    }
  },
  mutations: {},
  actions: {}
};
