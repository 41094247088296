var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.viaje)?_c('div',{ref:"vistaExperiencia",attrs:{"id":"Tour"}},[(_vm.viaje)?_c('Breadcrumbs',{attrs:{"razon":"experiencia","titulo":_vm.viaje.titulo}}):_vm._e(),_c('div',{staticClass:"contain"},[_c('b-row',{staticClass:"m-0"},[_c('b-col',{staticClass:"px-3 hero-section__titulo text-left",attrs:{"cols":"12"}},[(_vm.viaje.titulo)?_c('h1',{staticClass:"hero-section__heading font-weight-bold"},[_vm._v(" "+_vm._s(_vm.language === "es" ? _vm.viaje.titulo : _vm.viaje.ingles.titulo)+" ")]):_vm._e(),(_vm.evaluacionesByExperiencia(_vm.id).length)?_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('b-form-rating',{staticClass:"rating",attrs:{"value":_vm.promedio,"no-border":"","readonly":"","inline":"","variant":"warning"}}),_c('h5',{staticClass:"m-0 pr-2"},[_vm._v(_vm._s(_vm.promedio))]),_c('span',{staticStyle:{"fontsize":"13px"}},[_vm._v(" ("+_vm._s(_vm.evaluacionesByExperiencia(_vm.id).length)+") ")])],1):_vm._e()]),_c('b-col',{attrs:{"cols":"12","lg":"8"}},[_c('div',{staticClass:"pt-3 pt-lg-0"},[_c('div',{staticClass:"carousel__images",style:({
                background: `url(${_vm.viaje.carouselImgUrl}) no-repeat center center / cover`,
                width: '730px',height: '380px'
              })})]),_c('div',{ref:"resumen",staticClass:"m-0",attrs:{"no-body":"","id":"AccResumen"}},[_c('div',[_c('b-container',{staticClass:"p-3",attrs:{"id":"tuti"}},[_c('span',[_c('p',{staticClass:"pre-wrap"},[_vm._v(" "+_vm._s(_vm.language === "es" ? _vm.viaje.resumen : _vm.viaje.ingles.resumen ? _vm.viaje.ingles.resumen : _vm.viaje.resumen)+" ")]),_c('h5',{staticStyle:{"color":"#3d99ff","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.__get('highlights'))+" ")]),_c('ul',{staticClass:"experiencia__list"},_vm._l((_vm.language === 'es'
                      ? _vm.viaje.destacado
                      : _vm.viaje.ingles.destacado),function(destacado,index){return _c('li',{key:index,staticClass:"experiencia__list-item d-sm-flex align-items-start pb-2"},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/assets/img/iconos/checkPequeño.svg"),"width":"20","alt":""}}),_c('span',[_vm._v(_vm._s(destacado))])])}),0)])])],1)]),_c('div',{ref:"descripcion",staticClass:"m-0 mt-4",attrs:{"no-body":"","id":"AccItinerario"}},[_c('div',{staticClass:"p-1 p-lg-0",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"d-flex align-items-center justify-content-between font-weight-bold px-2 px-lg-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.breveitin)+" ")])])]),_c('table',{staticClass:"table table-sm"},[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.__get('day')))]),_c('th',[_vm._v(_vm._s(_vm.__get('highlights')))]),_c('th',[_vm._v(_vm._s(_vm.durantenoche))])])]),_c('tbody',_vm._l((_vm.viaje.itinerariosDias),function(dia,index){return _c('tr',{key:index},[_c('td',[_vm._v(_vm._s(_vm.__get('day'))+" "+_vm._s(index + 1))]),_c('td',[_vm._v(_vm._s(_vm.language == "es" ? dia.titulo : dia.tituloIngles))]),_c('td',[_vm._v(_vm._s(_vm.language == "es" ? dia.noche : dia.overnigth))])])}),0)])]),_c('div',{ref:"descripcion",staticClass:"m-0 mt-4",attrs:{"no-body":"","id":"AccItinerario"}},[_c('div',{staticClass:"p-1 p-lg-0",attrs:{"header-tag":"header","role":"tab"}},[_c('h4',{staticClass:"d-flex align-items-center justify-content-between font-weight-bold px-2 px-lg-4"},[_c('span',[_vm._v(" "+_vm._s(_vm.descripcionNombre)+" ")])])]),_c('b-container',{staticClass:"p-3"},[_c('span',[_c('br'),_vm._l((_vm.viaje.itinerariosDias),function(dia,index){return _c('div',{key:index},[_c('div',{staticClass:"carousel__images",style:({
                    background: `url(${dia.imgUrl}) no-repeat center center / cover`,
                    width: '700px',height: '320px'
                  })}),_c('h5',{key:index,staticStyle:{"color":"#3d99ff","font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.__get('day'))+" "+_vm._s(index + 1)+" ")]),_c('p',{staticClass:"pre-wrap"},[_vm._v(_vm._s(dia.descripcion))])])})],2)])],1),(_vm.viaje.googleMapsUrl)?_c('div',{ref:"infoNombre",staticClass:"m-0",attrs:{"no-body":"","id":"AccMapa"}},[_c('div',{staticClass:"p-1 p-lg-0",attrs:{"header-tag":"header","role":"tab"},on:{"click":function($event){return _vm.carouselFocus('AccMapa')}}},[_c('h4',{staticClass:"d-flex align-items-center justify-content-between font-weight-bold px-2 px-lg-4",attrs:{"block":""}},[_c('span',[_vm._v(_vm._s(_vm.mapaNombre))])])]),_c('div',[_c('span',[_c('b-container',{staticClass:"p-3"},[_c('h5',{staticClass:"mb-2",staticStyle:{"color":"#3d99ff","font-weight":"bold","font-size":"20px"}},[_vm._v(" "+_vm._s(_vm.ubicacionNombre)+" ")]),_c('iframe',{staticClass:"experiencia__iframe",staticStyle:{"border":"0"},attrs:{"src":_vm.viaje.googleMapsUrl,"frameborder":"0","allowfullscreen":"","aria-hidden":"false","tabindex":"0"}})])],1)])]):_vm._e()]),_c('b-col',{staticClass:"calendario",attrs:{"cols":"12","lg":"4"}},[_c('div',{staticClass:"card-header row px-2"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"my-3"},[_c('h3',{staticClass:"calendario__titulo mt-2"},[_vm._v(_vm._s(_vm.__get('onlineResrve')))]),_c('h6',[_vm._v(" "+_vm._s(_vm.chatespec)+" ")])])])])])],1)],1),_c('BtnReservaAhora')],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }