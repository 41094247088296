/* eslint-disable */
export default {
  namespaced: true,

  state: {
    NuestrosDestinosESP: {
      nuestros: "Nuestros",
      destinos: "Destinos"
    },
    NuestrosDestinosENG: {
      nuestros: "Our",
      destinos: "Destinations"
    }
  },
  getters: {
    nuestros(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NuestrosDestinosESP.nuestros;
      } else if (rootState.language == "en") {
        return state.NuestrosDestinosENG.nuestros;
      }
    },
    destinosNombre(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.NuestrosDestinosESP.destinos;
      } else if (rootState.language == "en") {
        return state.NuestrosDestinosENG.destinos;
      }
    }
  },
  mutations: {},
  actions: {}
};
