/* eslint-disable */
export default {
  namespaced: true,

  state: {
    PrivacidadESP: {
      titulo: "Privacidad"
    },
    PrivacidadENG: {
      titulo: "Privacy"
    }
  },
  getters: {
    titulo(state, getters, rootState) {
      if (rootState.language == "es") {
        return state.PrivacidadESP.titulo;
      } else if (rootState.language == "en") {
        return state.PrivacidadENG.titulo;
      }
    }
  },
  mutations: {},
  actions: {}
};
