/* eslint-disable */
export default {
  namespaced: true,

  state: {
    homeTitle: {
      es: "Vive las mejores <strong>escapadas</strong> de naturaleza",
      en: "Live the <strong>best</strong> nature trips"
    },
    step: {
      es: "Paso",
      en: "Step"
    },
    comoFunciona: {
      es: "Cómo funciona",
      en: "How it works"
    },
    cuentaViaje: {
      es: "Cuéntanos qué tipo de viaje quieres",
      en: "Tell us what kind of trip you want"
    },
    completaCuestionario: {
      es: "Complete nuestro cuestionario rápido sobre viajes",
      en: "Complete our quick travel quiz"
    },
    chatEspecialista: {
      es: "Charle con un especialista en viajes local",
      en: "Chat with a local travel specialist"
    },
    resolverDetalles: {
      es: "Resolver los detalles juntos",
      en: "Work out the details together"
    },
    reservaSegura: {
      es: "Reserva tu viaje de forma segura",
      en: "Securely book your trip"
    },
    garantiaSast: {
      es: "100% de garantía de satisfacción",
      en: "100% satisfaction guaranteed"
    },
    landingTitle: {
      es: "Viajes personalizados <h5> para viajeros independientes </h5>",
      en: "Personalized trips <h5>for independent travelers </h5>"
    },
    destTitle: {
      es: 'Nuestros <span style="color: #3D99FF">Destinos</span>',
      en: 'Our <span style="color: #3D99FF">Destinations</span>'
    },
    expTitle: {
      es: 'Escapadas <span style="color: #3D99FF">Destacadas</span>',
      en: 'Getaways <span style="color: #3D99FF">Featured</span>'
    },
    destSingleTitle: {
      es: 'DESTINOS',
      en: 'DESTINATIONS'
    },
    onlineResrve: {
      es: 'Reserva Online',
      en: 'Book now'
    },
    from: {
      es: 'Desde',
      en: 'From'
    },
    perPerson: {
      es: 'por persona',
      en: 'per adult'
    },
    dateSelect: {
      es: 'Selecciona fecha',
      en: 'Select date'
    },
    hour: {
      es: 'Hora e idioma',
      en: 'Schedule and language'
    },
    numberOfPassengers: {
      es: 'Selecciona la cantidad de viajer@s',
      en: 'Number of passengers'
    },
    messagePassangers: {
      es: 'Esta experiencia no cuenta con precio diferenciado para niños',
      en: 'This experience does not have a differentiated price for children'
    },
    limitPassangers: {
      es: 'Límite máximo de viajeros alcanzado',
      en: 'Maximum limit of travelers reached'
    },
    bookingRequest: {
      es: 'Solicitar reserva',
      en: 'Booking request'
    },
    messageBooking: {
      es: 'En menos de 48 horas confirmaremos tu reserva',
      en: 'In less than 48 hours we will confirm your booking'
    },
    messageContact: {
      es: '¿Te gustaría personalizar tu experiencia?',
      en: 'Would you like to personalize your experience?'
    },
    highlights: {
      es: 'Destacado',
      en: 'Highlights'
    },
    day: {
      es: 'Día',
      en: 'Day'
    },
    included: {
      es: 'Incluye',
      en: 'Included'
    },
    notIncluded: {
      es: 'No incluye',
      en: 'Not included'
    },
    mustBbring: {
      es: 'Debes traer',
      en: 'Must bring'
    },
    cancellationPolicy: {
      es: 'Información importante',
      en: 'Cancellation policy'
    },
    bookingoOfRequest: {
      es: 'Solicitud de reserva',
      en: 'Booking request'
    },
    confirmation: {
      es: 'Confirmación',
      en: 'Confirmation'
    },
    payment: {
      es: 'Pago',
      en: 'Payment'
    },
    date: {
      es: 'Fecha',
      en: 'Date'
    },
    dynamicRate: {
      es: 'Tarifa dinámica',
      en: 'Dynamic rate'
    },
    messageDynamicRate: {
      es: 'Los precios varían según las fechas escogidas',
      en: 'Prices vary according to the chosen dates'
    },
    adult: {
      es: 'Adulto',
      en: 'Adult'
    },
    important: {
      es: '¡IMPORTANTE!',
      en: 'IMPORTANT!'
    },
    messageImportant: {
      es: 'En un plazo MÁXIMO de 48 horas confirmaremos tu reserva',
      en: 'In a MAXIMUM period of 48 hours we will confirm your reservation'
    },
    requestReservation: {
      es: 'Solicitar Reservación',
      en: 'Request Reservation'
    },
    applyCoupon: {
      es: 'Aplicar cupón',
      en: 'Apply coupon'
    },
    seeDetails: {
      es: 'Ver más',
      en: 'See details'
    },
    now: {
      es: 'Ahora',
      en: 'Now'
    },
    perPerson2: {
      es: 'por persona',
      en: 'per person'
    },
    rating: {
      es: 'Puntuaciones',
      en: 'Rating'
    },
    review: {
      es: 'Opiniones de viajeros',
      en: 'Reviews'
    },
    seeMore: {
      es: 'Ver más',
      en: 'See more'
    },
    seeLess: {
      es: 'Ver menos',
      en: 'See less'
    },
    bookWith: {
      es: 'Reserva con',
      en: 'Book with'
    },
    bookPayingOnly: {
      es: 'Puedes reservar pagando sólo',
      en: 'You can book paying only'
    },
    namely: {
      es: 'es decir, el',
      en: 'that is, the'
    },
    ofTheTotal: {
      es: 'del total',
      en: 'of the total'
    },
  },
  getters: {
    getHomeTitle(state, getters, rootState) {
      return rootState.language == "es"
        ? state.homeTitle.es
        : state.homeTitle.en;
    },
    getLandingTitle(state, getters, rootState) {
      return rootState.language == "es"
        ? state.landingTitle.es
        : state.landingTitle.en;
    },
    getDestTitle(state, getters, rootState) {
      return rootState.language == "es"
        ? state.destTitle.es
        : state.destTitle.en;
    },
    getExpTitle(state, getters, rootState) {
      return rootState.language == "es"
        ? state.expTitle.es
        : state.expTitle.en;
    },
    __get: (state, getters, rootState) => (name) => {
      return rootState.language == "es"
        ? state[name].es
        :state[name].en;
    }
  },
  mutations: {},
  actions: {}
};
