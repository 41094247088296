<template>
  <div id="FAQS">
    <div class="banner flex">
      <h1 class="titulo m-0">{{ faq }}</h1>
    </div>
    <div class="container p-4 p-sm-5" role="tablist">
      <h1 class="azul ml-lg-5">Para el viajero</h1>
      <ul>
        <li>
          <h4 v-b-toggle.accordion-1>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span>¿Cómo puedo reservar en línea?</span>
          </h4>
          <b-collapse
            id="accordion-1"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              ¡Nuestro trabajo es facilitar la programación de tu próximo viaje!
              <br />Una vez que elijas la actividad que desees reservar, debes
              seleccionar en el calendario la fecha en que quieras realizarlo,
              el número de personas correspondientes y el idioma. Completar la
              información requerida de quien será el viajer@ titular. Es
              importante que en caso de que la actividad incluya snack o alguna
              comida, señales si alguien del grupo es vegetarian@, vegan@ o si
              presenta alguna restricción alimenticia. Revisa atentamente la
              información de la actividad, de igual manera te la enviaremos a tu
              e- mail para que no se te olvide ningún detalle y puedas disfrutar
              tranquilamente. Una vez que selecciones el método de pago, serás
              redirigid@ al sitio correspondiente para completar la transacción.
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-2>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span
              >¿Con cuánta anticipación debo realizar la reserva de un
              servicio?</span
            >
          </h4>
          <b-collapse
            id="accordion-2"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              El calendario te indicará a partir de cuándo la actividad está
              disponible. En general, se requiere mínimo 2 días de anticipación
              con el fin de preparar todo lo necesario para llevar a cabo la
              experiencia.
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-3>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span>¿Cómo puedo conocer la disponibilidad de un servicio?</span>
          </h4>
          <b-collapse
            id="accordion-3"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              En cada experiencia encontrarás la información del horario y
              fechas disponibles en que podrás reservar. Esta estará siempre
              sujeta a la confirmación del tour operador, quien cuenta con 48
              horas como plazo máximo para confirmar. Cabe señalar que mientras
              el tour operador no confirme, no se te hará ningún cargo.
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-4>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span
              >¿Todos los servicios incluyen traslado desde el lugar de
              hospedaje?</span
            >
          </h4>
          <b-collapse
            id="accordion-4"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              En el apartado “Información Importante” de cada experiencia se
              detalla si está incluido el servicio de transporte.
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-5>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span>¿Puedo reprogramar o anular una reserva?</span>
          </h4>
          <b-collapse
            id="accordion-5"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              Sí, nuestros términos y condiciones para realizar una anulación o
              reprogramar una experiencia son flexibles, sobre todo tomando en
              cuenta el contexto actual.
              <br />Puedes reprogramar una actividad sin costo hasta 48 horas
              antes de la hora del inicio de la actividad.
              <b></b>
              Si quieres cancelar o anular una actividad. Nuestras políticas son
              las siguientes:
            </p>
            <ul>
              <li>
                <p class="font-weight-normal pl-5 ml-4">
                  - Hasta 3 días de anticipación se puede anular, y devolveremos
                  el 100% de lo pagado, exceptuando los costos de transacción.
                </p>
              </li>
              <li>
                <p class="font-weight-normal pl-5 ml-4">
                  - 2 días de anticipación, se devolverá el 50% de lo pagado,
                  exceptuando los costos de transacción.
                </p>
              </li>
              <li>
                <p class="font-weight-normal pl-5 ml-4">
                  - 1 día de anticipación, no se realiza devolución del dinero.
                </p>
              </li>
            </ul>
            <p class="font-weight-normal pl-4 ml-3">
              Las cancelaciones o anulaciones deben ser vía mail a
              reservas@surreal.travel
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-6>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span
              >¿Puedo realizar una reserva de forma presencial o vía
              telefónica?</span
            >
          </h4>
          <b-collapse
            id="accordion-6"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              No. Por ahora, las reservas se realizarán solo de forma online.
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-7>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span
              >¿Como puedo saber en qué estado se encuentra mi reserva?</span
            >
          </h4>
          <b-collapse
            id="accordion-7"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              Surreal Travel tiene un plazo de 48 horas para comunicarte si la
              reserva ha sido confirmada, por lo tanto, si dentro de estas 48
              horas aún no has sido notificad@, significa que la reserva está
              siendo procesada.
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-8>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span>¿Qué métodos de pago aceptan?</span>
          </h4>
          <b-collapse
            id="accordion-8"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              Tarjeta de débito emitida en Chile y tarjeta de crédito.
            </p>
          </b-collapse>
        </li>
        <li>
          <h4 v-b-toggle.accordion-9>
            <img src="@/assets/img/iconos/+.svg" alt />
            <span>¿Se puede pagar en cuotas?</span>
          </h4>
          <b-collapse
            id="accordion-9"
            visible
            accordion="my-accordion"
            role="tabpanel"
          >
            <p class="font-weight-normal pl-4 ml-3">
              Dependerá de las opciones que entregue tu banco.
            </p>
          </b-collapse>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
ul > li {
  padding: 5px 0px;
}

.banner {
  background: #3d99ff;
  padding: 30px;

  .titulo {
    color: white;
    font-size: 30px;
    text-align: center;
    font-weight: bold;

    @media screen and (max-width: 524px) {
      font-size: 25px;
      width: 160px;
    }
  }
}

h4 {
  font-size: 20px;

  @media screen and (max-width: 524px) {
    font-size: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      width: 88%;
    }
  }
}

.azul {
  color: #3d99ff;
  font-size: 22px;
  font-weight: bold;
}

.gris {
  color: #868686;
  font-size: 20px;
  font-weight: bold;
}

p {
  color: #868686;
  font-size: 16px;
  line-height: 35px;

  @media screen and (max-width: 524px) {
    font-size: 13px;
    line-height: 25px;
  }
}

li {
  color: #868686;
  font-size: 22px;
  font-weight: 500;
}

ul {
  list-style: none;
}
</style>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FAQS",
  metaInfo() {
    return {
      title: 'Preguntas frecuentes',
    };
  },
  computed: {
    ...mapGetters("FAQ", ["faq"]),
  },
};
</script>
