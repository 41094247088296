<template>
  <div class="admin">
    <div class="ovelay" v-if="!authenticated">
    <Auth @loggedIn="getLoginInfo" />
  </div>
    <router-view v-if="authenticated" />
  </div>
</template>

<script>
import Auth from "./admin/Auth";
export default {
  metaInfo: {
    title: "Admin",
  },
  components: {
    Auth
  },
  data() {
    return {
      authenticated: true
    };
  },
  methods: {
    getLoginInfo() {
      this.authenticated = true;
    }
  }
};
</script>

<style>
.admin {
  background: #639bfa;
  min-height: 100vh;
  overflow-x: hidden;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: white;
  z-index: 200;
}

/* CSS Loader styles y Custom CSS */
.lds-dual-ring {
  display: inline-block;
  width: 80px;
  height: 80px;
  position: relative;
  transform: scale(0.55);
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
