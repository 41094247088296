<template>
<div id="Cupones" class="p-5 d-flex justify-content-center align-items-center">
  <div class="text-white text-center">
    <h1>Generación de cupones</h1>
    <h3>Seleccione tipo de descuento</h3>

    <div class="d-flex justify-content-around">
      <div>
        <input type="radio" name="tipo_Descuento" @click="tipo_Descuento = 'porcentual'" />Porcentual
      </div>
      <div>
        <input type="radio" name="tipo_Descuento" @click="tipo_Descuento = 'pesos'" /> Cantidad de pesos
      </div>
    </div>
    <div v-show="tipo_Descuento == 'porcentual'">
      Porciento del descuento total
      <input type="number" min="0" max="100" v-model="cant" class="d-block m-auto" /> %
    </div>
    <div v-show="tipo_Descuento == 'pesos'">
      Cantidad de pesos a descontar
      <input type="number" min="0" max="100" v-model="cant" class="d-block m-auto" />
    </div>
    <br />

    <button class="btn btn-warning d-block m-auto" @click="generarCupon">Generar código para cupón</button>
    <div class="resp py-5 h1">
      {{resp}}
      <br />
      <span class="text-warning">{{cupon}}</span>
    </div>

    <hr />Cupones disponibles
    <table class="table">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Código</th>
          <th scope="col">Tipo de descuento</th>
          <th scope="col">Cantidad</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(cupon,i) in cupones" :key="i">
          <th scope="row">{{i+1}}</th>
          <td>{{cupon.cupon}}</td>
          <td>{{cupon.tipo_Descuento}}</td>
          <td>{{cupon.cant.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}}</td>
          <td>
            <button class="btn btn-danger btn-sm" @click="eliminar(cupon.cupon)">Eliminar</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
</template>

<script>
import {
  v4 as uuidv4
} from "uuid";
import firebase from "firebase";
export default {
  name: "Cupones",
  metaInfo: {
    title: "Generador de cupones",
  },
  data() {
    return {
      cant: "",
      tipo_Descuento: null,
      resp: "",
      cupon: "",
      cupones: [],
    };
  },
  methods: {
    generarCupon() {
      this.cupon = uuidv4().slice(24, 36);
      this.resp = "Cupón generado con éxito ";
      firebase.firestore().collection("cupones").doc(this.cupon).set({
        cupon: this.cupon,
        tipo_Descuento: this.tipo_Descuento,
        cant: this.cant,
        usado: false,
      });
    },
    eliminar(c) {
      firebase.firestore().collection("cupones").doc(c).delete();
    },
  },
  mounted() {
    firebase
      .firestore()
      .collection("cupones")
      .onSnapshot(
        (querySnapshot) => {
          let cupones = [];
          querySnapshot.forEach((c) => {
            cupones.push(c.data());
          });
          this.cupones = cupones;
          // ...
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
  },
};
</script>
